import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: 'design-card-title',
    host: {
        'class': 'design-card-title'
    }
})
export class DesignCardTitleDirective {

    
    constructor() { }

}
