import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Product } from "@applogic/model";
import { from, Observable } from "rxjs";
import { ProductService } from "../services/product.service";

@Injectable({
    providedIn: 'root'
})
export class ProductsResolverService  {

    constructor(private productService: ProductService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product[]> {

        return from(this.productService.getProducts());
    }
}