<div class="app-dialog-title"
     mat-dialog-title
     *ngIf="showTitleSection"
     [ngClass]="{'app-dialog-warning-icon': icon=='warning'}"
     mat-dialog-draggable-title
     [isDraggable]="isDraggable"
     [cacheKey]="draggableCacheKey">
    <div class="icon-container"
         *ngIf="icon || svgIcon">
        <mat-icon [color]="iconColor"
                  [svgIcon]="svgIcon">{{icon}}</mat-icon>

    </div>
    <ng-template *ngIf="leftTitleTemplate"
                 [ngTemplateOutlet]="leftTitleTemplate"></ng-template>

    <app-label typeScale="headline-medium"
               weight="600"
               type="dynamic"
               [maxFontSize]="28"><ng-content select="app-dialog-title,.app-dialog-title,[appDialogTitle],[dialog-title]"></ng-content></app-label>

    <ng-template *ngIf="rightTitleTemplate"
                 [ngTemplateOutlet]="rightTitleTemplate"></ng-template>

    <span class="spacer"></span>
    <button (click)="onCloseButtonClick()"
            class="close-button "
            *ngIf="showCloseButton"
            mat-icon-button>
        <mat-icon>
            clear
        </mat-icon>
    </button>
</div>

<mat-divider *ngIf="showTitleSection"></mat-divider>

<ng-template *ngIf="belowHeaderTemplate"
             [ngTemplateOutlet]="belowHeaderTemplate"></ng-template>

<ng-content select="app-dialog-header,.app-dialog-header,[appDialogHeader],[dialog-header]"></ng-content>

<mat-dialog-content class="app-dialog-content-container">
    <ng-content select="app-dialog-message,.app-dialog-message,[appDialogMessage],[dialog-message]"></ng-content>
    <ng-content select="app-dialog-content,.app-dialog-content,[appDialogContent],[dialog-content]"></ng-content>
</mat-dialog-content>

<mat-dialog-actions class="app-dialog-actions child-fullwidth-distribution"
                    *ngIf="showActionsSection"
                    [ngClass]="{'hidden-field': !actionsSectionRef.children.length}"
                    #actionsSectionRef>
    <ng-content select="app-dialog-action,.app-dialog-action,[appDialogAction],[dialog-action]"></ng-content>
</mat-dialog-actions>

<app-error-message-area [error]="errorObject"
                        [isAdmin]="adminSection"
                        [noPadding]="true"></app-error-message-area>

<ng-content select="app-dialog-footer,.app-dialog-footer,[appDialogFooter],[dialog-footer]"></ng-content>