import { ArrayMember, ID, Member, Model, Required } from "@uon/model";


/*
    This class represents a User with Stripe.

    A User may have multiple StripeUser (one per currency). A user in Stripe is a "customer".
    This class could have been called StripeCustomer but it will add confusion with the existing Stripe.Customer class.
*/
@Model()
export class StripeUser {

    @ID() 
    id: string;

    @Member() 
    customerId: string;

    @Member() 
    userId: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    currency: string;
}