import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ProductService } from 'src/app/services/product.service';
import { LanguageService } from 'src/app/services/language.service';
import { SSORedirectQuery } from '@applogic/model';
import { Model } from '@uon/model';

@Component({
    selector: 'app-sso-redirect-page',
    templateUrl: './sso-redirect-page.component.html',
    styleUrls: ['./sso-redirect-page.component.scss']
})
export class SsoRedirectPageComponent {

    errorObject: any;
    success: boolean = false;

    productTitle: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        protected productService: ProductService,
        private languageService: LanguageService) { }

    ngOnInit(): void {


        this.productService.loadProductsOnce().then((products) => {
            this.route.queryParams.subscribe(params => {
                try {

                    const obj: any = JSON.parse(JSON.stringify(params));
                    obj.redirectUri = window.location.href;
                    if(!obj.lang) {
                        obj.lang = this.languageService.currentLanguageCode;
                    }
                    this.authService.SSORedirect(Model.New(SSORedirectQuery, {
                        state: obj.state,
                        code: obj.code,
                        lang: obj.lang
                    })).subscribe({
                        next: (res) => {
                            console.log("SSO Redirect Response: " + JSON.stringify(res))
                            this.productTitle = this.productService.getGameName(res.productCode);

                            this.success = true;
                            if (res.platform == "WebGLPlayer") {
                                window.close();
                            }
                            else if( (res.platform == "Android") || (res.platform == "IPhonePlayer") ) {
                                window.location.href = res.productCode.toLowerCase() + "app://oauth";
                                window.close();
                            }
                            else {
                                window.close();
                            }
                        },
                        error: (ex) => {
                            this.errorObject = ex;
                        }
                    });
                }
                catch (ex) {
                    this.errorObject = ex;
                }
            });
        }, (ex) => {
            this.errorObject = ex;
        });

    }

}
