import { ID, Member, Model } from "@uon/model";


export enum ContentAssetType {
    Image = "image"
}

export enum ContentAssetCategory {
    Email = "email"
}

@Model()
export class ContentAsset {
    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    type: ContentAssetType;

    @Member()
    category: ContentAssetCategory;

    @Member()
    name: string;

    @Member()
    menuPath: string;
    
    @Member()
    filename: string;
}