import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-stripe-logo',
    templateUrl: './stripe-logo.component.html',
    styleUrls: ['./stripe-logo.component.scss']
})
export class StripeLogoComponent implements OnInit {

    @Input()
    logoStyle: string = "style1";
    
    constructor() { }

    ngOnInit(): void {
    }

}
