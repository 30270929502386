import { Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EqolColumnInfo, EqolCategoryInfo, SearchList, EqolColumns, EqolCategories, EqolColumnsDict, StringUtils, RoutesServer, ApiRoutes, CommonQueryResponse, Word } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import { ApiDirectoryService } from '../services/api-directory.service';

const SEARCH_LIST_SERIALIZER = new JsonSerializer(SearchList);

const WORD_SERIALIZER = new JsonSerializer(Word);


@Injectable({
    providedIn: 'root'
})
export class EqolService {

    private columns : Array<EqolColumnInfo> = [];
    private categories : Array<EqolCategoryInfo> = [];
    private columnsDict : Map<string, EqolColumnInfo> = new Map<string, EqolColumnInfo>();

    constructor(private dirService: ApiDirectoryService) {
        this.columns = EqolColumns;
        this.categories = EqolCategories;
        this.columnsDict = EqolColumnsDict;
    }

    getCategories() : Array<EqolCategoryInfo> {
        return this.categories;
    }

    getColumns() : Readonly<Array<EqolColumnInfo>> {
        return this.columns;
    }

    getColumn(key :string) : EqolColumnInfo {
        return this.columnsDict.get(key);
    }

    getColumnLabel(key :string) : string {
        return this.columnsDict.get(key).label;
    }

    searchWords(b64Filters: string, skip: number, limit: number, sort: string) {
        b64Filters = encodeURIComponent(b64Filters);
        
        if(sort) {
            sort = this.convertDatabaseSortKey(sort);
        }

        return this.get(ApiRoutes.Eqol, `/search/${b64Filters}`, { skip, limit, sort }).pipe(
            map((r: any) => {

                let response = new CommonQueryResponse<Word>();
                response.count = r.count;
                response.result = r.result.map(s => WORD_SERIALIZER.deserialize(s, true));

                return response;
            })
        );
    }

    export(b64Filters: string, columns: any[], raw: boolean, bookType: string, sort: string) {

        b64Filters = encodeURIComponent(b64Filters);

        if(sort) {
            sort = this.convertDatabaseSortKey(sort);
        }
        
        let parameters: any = { sort };
        if(columns && columns.length) {
            parameters.columns = columns.join(",");
        }

        if(raw) {
            parameters.raw = true;
        }

        if(bookType) {
            parameters.bookType = bookType;
        }

        this.dirService.observeRoute(RoutesServer.Api, ApiRoutes.Eqol, `/export/search/${b64Filters}` + this.getQuery(parameters)).subscribe((routeUrl) => {
            window.open(routeUrl);
        });
    }

    getSearchlist() {
        return this.get(ApiRoutes.Searchlist, ``)
            .pipe(
                map((r: any) => {

                    let response = new CommonQueryResponse<SearchList>();
                    response.count = r.count;
                    response.result = r.result.map(s => SEARCH_LIST_SERIALIZER.deserialize(s, true));

                    return response;
                })
            );
    }

    deleteSearchItem(searchlistid) {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Searchlist, `/${searchlistid}`, { withCredentials: true });
    }

    saveSearchlist(data: any) {
        return this.post(data, ApiRoutes.Searchlist).pipe(
            map((r: any) => {
                return SEARCH_LIST_SERIALIZER.deserialize(r, true);
            })
        );
    }

    post(data: any, route: ApiRoutes): Observable<any> {
        data['withCredentials'] = true;
        return this.dirService.serverPost(RoutesServer.Api, route, ``, data, { withCredentials: true }).pipe(
            map((response) => {
                return response
            }),
            catchError(this.handleError.bind(this))
        );
    }

    getQuery(query?: { [key: string]: any }): string {
        let str = ''
        if (query) {
            str = '?';
            for (let key in query) {
                if (query[key] !== undefined) {
                    str += key + '=' + query[key] + '&';
                }
            }
        }

        return str;
    }

    get(route: ApiRoutes, path: string, query?: { [key: string]: any }): Observable<any> {

        let str = this.getQuery(query);

        return this.dirService.serverGet(RoutesServer.Api, route, path + str, { withCredentials: true }).pipe(
            map((response) => {
                return response
            }),
            catchError(this.handleError.bind(this))
        );

    }

    /**
      * Handles http errors
      * @param error 
      */
     private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {

            // handle unauthorized
            if (error.status === 401) {

                return throwError(error);
            }
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };


    /**
     * Convert the sort key to the database sort key.
     * @param sortKey Key to convert.
     * @returns Returns the converted key. 
     */
    private convertDatabaseSortKey(sortKey: string) : string {
        let sortPrefix = "";
        if(sortKey.startsWith("-")) {
            sortPrefix = "-";
            sortKey = sortKey.substr(1);
        }
        else if(sortKey.startsWith("+")) {
            sortPrefix = "+";
            sortKey = sortKey.substr(1);
        }

        EqolColumns.forEach((c: EqolColumnInfo) => {
            if(c.key == sortKey) {
                
                sortKey = c.databaseField;
            }
        });

        sortKey = sortPrefix + sortKey;

        return sortKey;
    }


    getOpString(op: string) {
        return OP_STRINGS_FR[op] || op;
    }

     getNumberString(v: string) {

        return NUMBER_STRINGS_FR[v];
    }

     getGenderString(v: string) {

        return GENDER_STRINGS_FR[v];
    }

    getCgramString(v: string) {
        return CGRAM_STRINGS_FR[v];
    }

    getCgramSubString(v: string) {
        return CGRAM_SUB_STRINGS_FR[v];
    }

    getFrequencyString(v: string): string {
        return FREQ_STRINGS_FR[v];
    }
}



var OP_STRINGS_FR = {

    "sw": "Commencent par",
    "ew": "Se terminent par",
    "c": "Contiennent",
    "ex": "Mot Exact",

    "!sw": "Ne commencent pas par",
    "!ew": "Ne se terminent pas par",
    "!c": "Ne contiennent pas",

    "in": "Est un(e) ",
    "nin": "N'est pas un(e) ",
    "in_nodet": "Est ",
    "nin_nodet": "N'est pas ",

    "lte": "Plus petit ou égal à",
    "gte": "Plus grand ou égal à",
    "eq": "Égal à",
};

var GENDER_STRINGS_FR = {
    "f": "féminin",
    "m": "masculin"
};

var NUMBER_STRINGS_FR = {
    "s": "singulier",
    "p": "pluriel"
}

var CGRAM_STRINGS_FR = {
    "ver": "Verbe",
    "adj": "Adjectif",
    "adv": "Adverbe",
    "nom": "Nom",
    "pre": "Préposition",
    "pro": "Pronom",
    "det": "Déterminant",
    "aux": "Auxiliaire",
    "ono": "Onomatopée",
    "interjection": "Interjection",
    "con": "Conjonction",
    "nc": "Nom Commun",
    "np": "Nom Propre"

}

var CGRAM_SUB_STRINGS_FR = {
    "adj": {
        "ADJ:dem": "Adjectif démonstratif",
        "ADJ:ind": "Adjectif indéfini",
        "ADJ:int": "Adjectif interrogatif",
        "ADJ:num": "Adjectif numérique",
        "ADJ:pos": "Adjectif possessif"
    },
    "pro": {
        "PRO:dem": "Pronom démonstratif",
        "PRO:ind": "Pronom indéfini",
        "PRO:int": "Pronom interrogatif",
        "PRO:per": "Pronom personnel",
        "PRO:pos": "Pronom possessif",
        "PRO:rel": "Pronom relatif"
    },
    "det": {
        "art:def": "Article défini",
        "art:ind": "Article indéfini"
    },
    "con": {
        "conj": "Conjonction"
    },
    "nom": {
        "np": "Nom Propre",
        "nc": "Nom Commun"
    }
}

var FREQ_STRINGS_FR = {

    'veryrare': 'Très rare',
    'rare': 'Rare',
    'frequent': 'Fréquent',
    'veryfrequent': 'Très fréquent',
    'na': 'Non présent'
}