import { Component, Input } from '@angular/core';
import { Organization } from '@applogic/model';
import { CardRowData } from 'src/app/core/common-list/display-mode/common-list-card-display-mode/common-list-card-display-mode.component';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-organization-card',
    templateUrl: './organization-card.component.html',
    styleUrl: './organization-card.component.scss'
})
export class OrganizationCardComponent {

    ApplogicUtils = ApplogicUtils;

    @Input()
    data: CardRowData<Organization>;

    getRouterLink() {
        const org = this.data?.row;
        if(org) {
            return ApplogicUtils.getOrganizationRouterPath(org).join("/");
        }
    }
}
