<ng-container [ngSwitch]="column.type || row[column.rowTypeKey]">
    <div *ngSwitchCase="'text'"
         class="fieldtype-text-element"
         [ngStyle]="column.elementStyles"
         [matTooltip]="getRowValue(column, row)">{{getRowValue(column, row)}}</div>
    <app-label *ngSwitchCase="'label'"
         class="fieldtype-label-element"
         [ngStyle]="column.elementStyles"
         [matTooltip]="getRowValue(column, row)"
         [type]="column.parameters?.labelType || 'normal'">{{getRowValue(column, row)}}</app-label>
    <app-common-list-language-code-renderer *ngSwitchCase="'language-code'"
                                         class="fieldtype-language-code-element"
                                         [ngStyle]="column.elementStyles"
                                         [list]="list"
                                         [parent]="parent"
                                         [column]="column"
                                         [row]="row"></app-common-list-language-code-renderer>
    <div *ngSwitchCase="'id'"
         class="fieldtype-id-element"
         [ngStyle]="column.elementStyles">{{getRowValue(column, row)}}</div>
    <div *ngSwitchCase="'no'"
         class="fieldtype-no-element"
         [ngStyle]="column.elementStyles">{{getRowValue(column, row)}}</div>
    <div *ngSwitchCase="'multi-lines-text'"
         class="fieldtype-multi-lines-text-element"
         [ngStyle]="column.elementStyles">{{getRowValue(column, row)}}</div>
    <span *ngSwitchCase="'html'"
          class="fieldtype-html-element"
          [innerHtml]="getRowValue(column, row) | noSanitize"
          [ngStyle]="column.elementStyles"></span>
    <span *ngSwitchCase="'date'"
          class="fieldtype-date-element"
          [ngStyle]="column.elementStyles"
          [matTooltip]="column.parameters?.tooltipFormat ? (getRowValue(column, row) | date:column.parameters?.tooltipFormat) : ''">{{getRowValue(column, row) | date:column.parameters?.format}}</span>
    <span *ngSwitchCase="'user'"
          class="fieldtype-user-element"
          [ngStyle]="column.elementStyles">{{list.getUserName(row, getRowValue(column, row))}}</span>
    <span *ngSwitchCase="'custom'"
          class="fieldtype-custom-element"
          [ngStyle]="column.elementStyles">{{list.getCustomLabel(column.key, getRowValue(column, row), row)}}</span>
    <span *ngSwitchCase="'custom-html'"
          class="fieldtype-custom-html-element"
          [innerHtml]="list.getCustomLabel(column.key, getRowValue(column, row), row) | noSanitize"
          [ngStyle]="column.elementStyles"></span>
    <span *ngSwitchCase="'boolean'"
          class="fieldtype-boolean-element"
          [ngStyle]="column.elementStyles">{{getBoolValue(column, row)}}</span>
    <app-common-list-audio-cell-renderer *ngSwitchCase="'audio'"
                                         class="fieldtype-audio-element"
                                         [ngStyle]="column.elementStyles"
                                         [list]="list"
                                         [parent]="parent"
                                         [column]="column"
                                         [row]="row"></app-common-list-audio-cell-renderer>
    <span *ngSwitchCase="'image'"
          class="fieldtype-image-element"
          [ngStyle]="column.elementStyles">
        <div [ngSwitch]="list.hasImage(column.key, getRowValue(column, row), row)">
            <a *ngSwitchCase="true"
               [href]="list.getImageUrl(column.key, getRowValue(column, row), row)"
               target="_blank">
                <img [src]="list.getImageThumbUrl(column.key, getRowValue(column, row), row)" />
            </a>
            <span *ngSwitchCase="false">
                no image
            </span>
        </div>
    </span>
    <span *ngSwitchCase="'textarea-readonly'"><textarea type="text"
                  class="field-textarea fieldtype-textarea-element"
                  value="{{getRowValue(column, row)}}"
                  [ngStyle]="column.elementStyles"
                  readonly>
</textarea></span>
    <span *ngSwitchCase="'action'"
          class="fieldtype-action-element"
          style="display: flex; flex-direction: row; align-items: center;"
          [ngStyle]="column.elementStyles">
        <div *ngIf="parent.templatesOptions?.actionTemplate">
            <ng-template [ngTemplateOutlet]="parent.templatesOptions?.actionTemplate"
                         [ngTemplateOutletContext]="{$implicit: row}"></ng-template>
        </div>
        <button mat-icon-button
                *ngIf="list.canShowAction(row)"
                [matMenuTriggerFor]="parent.actionMenu"
                [matMenuTriggerData]="{row: row}"
                (click)="list.onMenuClick(row, $event)"
                (menuOpened)="parent.menuActionOpened(column, row)">
            <mat-icon>more_vert</mat-icon>
        </button>
    </span>
    <span *ngSwitchCase="'selection'"
          class="fieldtype-selection-element"
          [ngStyle]="column.elementStyles">
        <ng-container [ngSwitch]="list.multiSelect">
            <mat-checkbox (change)="list.onSelectedChanged(column, row, $event.checked)"
                          [checked]="list.selection.isSelected(row)"
                          [disabled]="list.isCellDisabled(column, row)"
                          *ngSwitchCase="true">
            </mat-checkbox>
            <app-radio-select-button [value]="row.id || row"
                                     [group]="column.parameters.radioButtonGroup || column"
                                     [checked]="list.selection.isSelected(row)"
                                     [canDeselect]="column.parameters.canDeselect || false"
                                     (onCheck)="list.onSelectedChanged(column, row, true)"
                                     *ngSwitchCase="false"></app-radio-select-button>
        </ng-container>
    </span>
    <span *ngSwitchCase="'checkbox'"
          class="fieldtype-checkbox-element"
          [ngStyle]="column.elementStyles">
        <mat-checkbox (change)="list.onCheckboxChanged(column, row, $event.checked)"
                      [checked]="getRowValue(column, row)">
        </mat-checkbox>
    </span>
    <span *ngSwitchCase="'radio-button'"
          class="fieldtype-radio-button-element"
          [ngStyle]="column.elementStyles">
        <ng-container *ngIf="list.getFormControl(column, row) as control">
            <app-radio-select-button [value]="row.id || row"
                                     [group]="column.parameters.radioButtonGroup"
                                     [checked]="!!getRowValue(column, row)"
                                     [canDeselect]="column.parameters.canDeselect || false"
                                     [formControl1]="control"
                                     (change)="list.onRadiobuttonChanged(column, row, $event.checked, $event)"></app-radio-select-button>
        </ng-container>
    </span>
    <span *ngSwitchCase="'dictionary'"
          class="fieldtype-dictionary-element"
          [ngStyle]="column.elementStyles">
        <div *ngFor="let dimKey of getKeys(getRowValue(column, row))">
            {{dimKey}}={{getRowValue(column, row)[dimKey]}}
        </div>
    </span>

    <ng-template *ngSwitchCase="'template'"
                 [ngTemplateOutlet]="parent.templatesOptions?.templates[column.templateCellIdx]"
                 [ngTemplateOutletContext]="{$implicit: {row, column, index}}"></ng-template>

    <span *ngSwitchCase="'currency'"
          class="fieldtype-currency-element"
          [ngStyle]="column.elementStyles">
        {{getRowValue(column, row) | apCurrency:getCurrencyValue(column, row)}}
    </span>

    <ng-container *ngSwitchCase="'activated'"
                  class="fieldtype-activated-element"
                  [ngStyle]="column.elementStyles"
                  [ngSwitch]="getRowValue(column, row)">
        <small *ngSwitchCase="true"
               class="activated-text"
               i18n="@@common-enabled">Enabled</small>
        <small *ngSwitchCase="false"
               class="deactivated-text"
               i18n="@@common-disabled">Disabled</small>
    </ng-container>

    <span *ngSwitchCase="'void'"
          class="fieldtype-void-element"
          [ngStyle]="column.elementStyles">

    </span>

    <span *ngSwitchCase="'form-control-input'"
          class="fieldtype-form-control-input-element"
          [ngStyle]="column.elementStyles">
        <ng-container *ngIf="list.getFormControl(column, row) as control">

            <!-- This is to have the mat-form-field centered. -->
            <mat-error class="form-error-message"
                       style="visibility: hidden;">
               
            </mat-error>

            <mat-form-field floatLabel="never"
            class="small-input"
                            subscriptSizing="dynamic"
                            style="width: 100%">
                <input matInput
                       [placeholder]="column.parameters?.inputPlaceholder || ''"
                       [formControl]="control"
                       autocomplete="off">
                <!-- <mat-error *ngIf="control.invalid" class="form-error-message">
              {{ formService.getFieldErrorMessage(control, column.formControlValidatorType) }}
          </mat-error> -->
            </mat-form-field>
            <mat-error *ngIf="control.invalid"
                       class="form-error-message">
                {{ parent.formService.getFieldErrorMessage(control, column.formControlValidatorType) }}
            </mat-error>
            <mat-error *ngIf="!control.invalid"
                       class="form-error-message">
                
            </mat-error>

        </ng-container>
    </span>

    <span *ngSwitchCase="'merge-columns'"
          class="fieldtype-merge-columns-element"
          [ngStyle]="column.elementStyles">
        <div *ngFor="let subColumn of column.options.mergeColumns">
            <app-common-list-cell-renderer [parent]="parent"
                                           [list]="list"
                                           [column]="subColumn"
                                           [row]="row"></app-common-list-cell-renderer>
        </div>
    </span>

    <span *ngSwitchDefault
          class="fieldtype-default-element"
          [ngStyle]="column.elementStyles">
        {{getRowValue(column, row)}}
    </span>
</ng-container>