import { trigger, style, state, transition, animate } from '@angular/animations';


export const COLLAPSE_HEIGHT = trigger('collapseHeight', [
    transition(':enter', [
        style({
            height: 0,
            opacity: 0,
            overflow: 'hidden'
        }),
        animate("0.25s linear", style({
            height: '*',
            opacity: 1,
        }))
    ]),
    transition(':leave', [
        style({
            height: '*',
            opacity: 1,
            overflow: 'hidden'
        }),
        animate("0.25s linear", style({
            height: 0,
            opacity: 0,
        }))
    ])
]);


export const FADE_IN_COLLAPSE_OUT = trigger('fadeInCollapseOut', [
    transition(':enter', [
        style({
            opacity: 0,
        }),
        animate("0.25s linear", style({
            opacity: 1,
        }))
    ]),
    transition(':leave', [
        style({
            height: '*',
            opacity: 1,
            overflow: 'hidden'
        }),
        animate("0.5s ease-out", style({
            height: 0,
            opacity: 0,
        }))
    ])
])