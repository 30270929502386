import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToolbarContentComponent } from './toolbar-content/toolbar-content.component';
import { RouterModule} from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTreeModule } from '@angular/material/tree';

import { LogoComponent } from './logo/logo.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageFooterComponent } from './language-footer/language-footer.component';
import { GeneralExplanationCardComponent } from './general-explanation-card/general-explanation-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { FooterComponent } from '../shared/footer/footer.component';
import { ToolbarLanguageComponent } from './toolbar-language/toolbar-language.component';
import { SelectCountriesOptionComponent } from './select-countries-option/select-countries-option.component';
import { ErrorPopUpMessageDialogComponent } from './error-pop-up-message-dialog/error-pop-up-message-dialog.component';
import { MessageBoxDialogComponent } from './message-box-dialog/message-box-dialog.component';
import { NoSanitizePipe } from './no-sanitize.pipe';
import { SubscriptionValidityLabelComponent } from './subscription-validity-label/subscription-validity-label.component';
import { QuantityInputComponent } from './quantity-input/quantity-input.component';
import { ErrorMessageAreaComponent } from './error-message-area/error-message-area.component';
import { Dummy2Component } from './dummy2/dummy2.component';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog/pdf-viewer-dialog.component';
import { UrlViewerDialogComponent } from './url-viewer/url-viewer-dialog.component';
import { DevSiteWarningComponent } from './dev-site-warning/dev-site-warning.component';
import { HtmlViewerDialogComponent } from './html-viewer-dialog/html-viewer-dialog.component';
import { SimpleEditableListComponent } from './simple-editable-list/simple-editable-list.component';
import { CommonListColumnsDialogComponent } from './common-list/common-list-columns-dialog/common-list-columns-dialog.component';
import { ExportTypeSelectDialogComponent } from './export-type-select-dialog/export-type-select-dialog.component';
import { CommonListRendererComponent } from './common-list/common-list-renderer/common-list-renderer.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CdkTableModule } from '@angular/cdk/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ApCurrencyPipe } from './apcurrency.pipe';
import { ApCurrencySymbolPipe } from './apcurrency-codesymbol.pipe';
import { ApLocalizePipe } from './aplocalize.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { CommonListSearchInputComponent } from './common-list/common-list-search-input/common-list-search-input.component';
import { SelectStatesOptionComponent } from './select-states-option/select-states-option.component';
import { OrganizationRoleChipComponent } from './organization-role-chip/organization-role-chip.component';
import { SimpleChartComponent } from './simple-chart/simple-chart.component';
import { NgModelChangeDebouncedDirective } from './ng-model-change-debounced.directive';
import { RadioSelectButtonComponent } from './radio-select-button/radio-select-button.component';
import { CommonListCellRendererComponent } from './common-list/common-list-cell-renderer/common-list-cell-renderer.component';
import { SimpleChartDialogComponent } from './simple-chart-dialog/simple-chart-dialog.component';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { DialogComponent } from './dialog/dialog.component';
import { DialogTitleDirective } from './dialog/directives/dialog-title.directive';
import { DialogContentDirective } from './dialog/directives/dialog-content.directive';
import { DialogActionDirective } from './dialog/directives/dialog-action.directive';
import { DialogMessageDirective } from './dialog/directives/dialog-message.directive';
import { ThemeSwitchComponent } from './theme-switch/theme-switch.component';
import { HelpIconComponent } from './help-icon/help-icon.component';
import { LanguageButtonComponent } from './language-button/language-button.component';
import { MatListModule } from '@angular/material/list';
import { RelativeTimePipe } from './relative-time.pipe';
import { DesignCardComponent } from './design-card/design-card.component';
import { RippleOnHoverDirective } from './ripple-on-hover.directive';
import { DesignCardHeaderDirective } from './design-card/directives/design-card-header.directive';
import { DesignCardTitleDirective } from './design-card/directives/design-card-title.directive';
import { DesignCardSubtitleDirective } from './design-card/directives/design-card-subtitle.directive';
import { DesignCardFooterDirective } from './design-card/directives/design-card-footer.directive';
import { DesignCardContentDirective } from './design-card/directives/design-card-content.directive';
import { DesignCardImageDirective } from './design-card/directives/design-card-image.directive';
import { ShapeComponent } from './shape/shape.component';
import { BackgroundColorRoleDirective } from './directives/background-color-role.directive';
import { ColorRoleDirective } from './directives/color-role.directive';
import { CommonListCardDisplayModeComponent } from './common-list/display-mode/common-list-card-display-mode/common-list-card-display-mode.component';
import { CommonListDefaultDisplayModeComponent } from './common-list/display-mode/common-list-default-display-mode/common-list-default-display-mode.component';
import { CommonListAudioCellRendererComponent } from './common-list/common-list-cell-renderer/renderers/common-list-audio-cell-renderer/common-list-audio-cell-renderer.component';
import { CommonListRendererTemplatesComponent } from './common-list/common-list-renderer-templates/common-list-renderer-templates.component';
import { CommonListDisplayModeSelectComponent } from './common-list/controls/common-list-display-mode-select/common-list-display-mode-select.component';
import { CardTemplateDirective } from './common-list/common-list-renderer-templates/directives/card-template.directive';
import { CommonListToolbarComponent } from './common-list/controls/common-list-toolbar/common-list-toolbar.component';
import { CommonListRendererOptionsComponent } from './common-list/common-list-renderer-options/common-list-renderer-options.component';
import { CommonListToolbarOptionDirective } from './common-list/controls/common-list-toolbar/directives/common-list-toolbar-option.directive';
import { CommonListCardElementRendererComponent } from './common-list/controls/common-list-card-element-renderer/common-list-card-element-renderer.component';
import { CommonListPaginatorComponent } from './common-list/controls/common-list-paginator/common-list-paginator.component';
import { FormBuilderRendererComponent } from './form-builder/form-builder-renderer/form-builder-renderer.component';
import { DynamicFontSizeDirective } from './directives/dynamic-font-size.directive';
import { ContainerScalerComponent } from './container-scaler/container-scaler.component';
import { LabelComponent } from './label/label.component';
import { ProgressStepComponent } from './progress-step/progress-step.component';
import { DialogFooterDirective } from './dialog/directives/dialog-footer.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogHeaderDirective } from './dialog/directives/dialog-header.directive';
import { CustomFormHeadingTextFieldComponent } from './form-builder/fields/custom-form-heading-text-field/custom-form-heading-text-field.component';
import { CustomFormInputFieldComponent } from './form-builder/fields/custom-form-input-field/custom-form-input-field.component';
import { CustomFormSelectFieldComponent } from './form-builder/fields/custom-form-select-field/custom-form-select-field.component';
import { CustomFormTextAreaFieldComponent } from './form-builder/fields/custom-form-text-area-field/custom-form-text-area-field.component';
import { CustomFormRightSelectFieldComponent } from './form-builder/fields/custom-form-right-select-field/custom-form-right-select-field.component';
import { CustomFormRadioSelectFieldComponent } from './form-builder/fields/custom-form-radio-select-field/custom-form-radio-select-field.component';
import { CustomFormToggleFieldComponent } from './form-builder/fields/custom-form-toggle-field/custom-form-toggle-field.component';
import { FilterTyperPipe } from './filter-type.pipe';
import { TypeScaleDirective } from './directives/type-scale.directive';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { DesignCardSelectComponent } from './design-card/components/design-card-select/design-card-select.component';
import { CommonListActionItemComponent } from './common-list/controls/common-list-action-item/common-list-action-item.component';
import { CommonListActionToolbarComponent } from './common-list/controls/common-list-action-toolbar/common-list-action-toolbar.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TemplateOutletDirective } from './directives/template-outlet.directive';
import { ListCountIndicatorComponent } from './list-count-indicator/list-count-indicator.component';
import { SubtitleDirective } from './directives/text/subtitle.directive';
import { TitleDirective } from './directives/text/title.directive';
import { RadioSelectCardButtonComponent } from './radio-select-card-button/radio-select-card-button.component';
import { DialogStepperComponent } from './dialog/components/dialog-stepper/dialog-stepper.component';
import { CommonListShowColumnsButtonComponent } from './common-list/controls/common-list-show-columns-button/common-list-show-columns-button.component';
import { AlignCheckDirective } from './directives/align-check.directive';
import { AlignWithCheckDirective } from './directives/align-with-check.directive';
import { JsonViewerDialogComponent } from './json-viewer-dialog/json-viewer-dialog.component';
import { SimpleChartTabsDialogComponent } from './simple-chart-tabs-dialog/simple-chart-tabs-dialog.component';
import { CommonListLanguageCodeRendererComponent } from './common-list/common-list-cell-renderer/renderers/common-list-language-code-renderer/common-list-language-code-renderer.component';
// import { DummyComponent } from './dummy/dummy.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogDraggableTitleDirective } from './directives/dialog-draggable-title.directive';


const MATERIAL_MODULES = [
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatStepperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatToolbarModule,
    MatRadioModule,
    MatRippleModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatMenuModule,
    MatGridListModule,
    MatTreeModule,
    DragDropModule,
];

const CORE_DIRECTIVES = [

    AlignCheckDirective,
    AlignWithCheckDirective,
    
    BackgroundColorRoleDirective,
    ColorRoleDirective,
    TypeScaleDirective,
    
    DesignCardTitleDirective,
    DesignCardSubtitleDirective,
    DesignCardHeaderDirective,
    DesignCardFooterDirective,
    DesignCardContentDirective,
    DesignCardImageDirective,

    DialogActionDirective,
    DialogContentDirective,
    DialogFooterDirective,
    DialogHeaderDirective,
    DialogMessageDirective,
    DialogTitleDirective,

    DynamicFontSizeDirective,

    CommonListToolbarOptionDirective,
    CardTemplateDirective,

    ListCountIndicatorComponent,

    TemplateOutletDirective,

    TitleDirective,
    SubtitleDirective,

    DialogDraggableTitleDirective,

];

const CORE_PIPES = [
    ApCurrencyPipe,
    ApCurrencySymbolPipe,
    ApLocalizePipe,
    FilterTyperPipe,
    NoSanitizePipe,
    RelativeTimePipe,
    SafeHtmlPipe,
];

const CORE_INTERNAL_COMPONENTS = [
    CustomFormHeadingTextFieldComponent,
    CustomFormInputFieldComponent,
    CustomFormRadioSelectFieldComponent,
    CustomFormSelectFieldComponent,
    CustomFormRightSelectFieldComponent,
    CustomFormTextAreaFieldComponent,
    CustomFormToggleFieldComponent,
    CommonListAudioCellRendererComponent,
    CommonListLanguageCodeRendererComponent,
];

const CORE_EXPORTS_COMPONENTS = [
    CommonListActionItemComponent,
    CommonListActionToolbarComponent,
    CommonListCardElementRendererComponent,
    CommonListDisplayModeSelectComponent,
    CommonListPaginatorComponent,
    CommonListToolbarComponent,
    CommonListRendererComponent,
    CommonListRendererOptionsComponent,
    CommonListRendererTemplatesComponent,
    CommonListSearchInputComponent,
    CommonListShowColumnsButtonComponent,
    ContainerScalerComponent,
    DesignCardSelectComponent,
    DialogComponent,
    DialogStepperComponent,
    FormBuilderRendererComponent,
    JsonViewerDialogComponent,
    LabelComponent,
    ProgressStepComponent,
    RadioGroupComponent,
    RadioSelectCardButtonComponent,
    ShapeComponent,
    SimpleChartComponent,
    SimpleChartDialogComponent,
    SimpleChartTabsDialogComponent,
    ThemeSwitchComponent,
];

@NgModule({
    declarations: [
        FooterComponent,
        ToolbarContentComponent,
        LogoComponent,
        LanguageFooterComponent,
        GeneralExplanationCardComponent,
        DesignCardComponent,
        ToolbarLanguageComponent,
        SelectCountriesOptionComponent,
        ErrorPopUpMessageDialogComponent,
        MessageBoxDialogComponent,
        SubscriptionValidityLabelComponent,
        QuantityInputComponent,
        ErrorMessageAreaComponent,
        Dummy2Component,
        PdfViewerDialogComponent,
        UrlViewerDialogComponent,
        DevSiteWarningComponent,
        HtmlViewerDialogComponent,
        SimpleEditableListComponent,
        CommonListColumnsDialogComponent,
        ExportTypeSelectDialogComponent,
        SelectStatesOptionComponent,
        OrganizationRoleChipComponent,
        NgModelChangeDebouncedDirective,
        RadioSelectButtonComponent,
        CommonListCellRendererComponent,
        CommonListDefaultDisplayModeComponent,
        CommonListCardDisplayModeComponent,
        //        DummyComponent
        HelpIconComponent,
        LanguageButtonComponent,
        RippleOnHoverDirective,
        ...CORE_DIRECTIVES,
        ...CORE_PIPES,
        ...CORE_INTERNAL_COMPONENTS,
        ...CORE_EXPORTS_COMPONENTS,
    ],
    imports: [
        RouterModule,
        CommonModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        // Dependencies for the CommonListRenderer:
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        CdkTableModule,
        MatDatepickerModule,
        MatTabsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        TooltipModule,
        MatListModule,
        MatSlideToggleModule,
        NgbPopoverModule,
        NgxMatSelectSearchModule,
        ...MATERIAL_MODULES
    ],
    exports: [
        ToolbarContentComponent,
        ToolbarLanguageComponent,
        LogoComponent,
        LanguageFooterComponent,
        GeneralExplanationCardComponent,
        DesignCardComponent,
        FooterComponent,
        SelectCountriesOptionComponent,
        ScrollingModule,
        FormsModule,
        SubscriptionValidityLabelComponent,
        QuantityInputComponent,
        ErrorMessageAreaComponent,
        PdfViewerDialogComponent,
        DevSiteWarningComponent,
        SimpleEditableListComponent,
        SelectStatesOptionComponent,
        OrganizationRoleChipComponent,
        NgModelChangeDebouncedDirective,
        RadioSelectButtonComponent,
        ...MATERIAL_MODULES,
        HelpIconComponent,
        LanguageButtonComponent,
        RippleOnHoverDirective,
        ...CORE_DIRECTIVES,
        ...CORE_PIPES,
        ...CORE_EXPORTS_COMPONENTS,
    ],
    providers: [CurrencyPipe]
})
export class CoreModule { }
