export interface CommonContext {
    actionType?: string;

    // choose one or both depending on your use case
    // also, you can use `unknown` as property type, as TypeScript promoted type, 
    // but it will generate errors if you iterate over it
    [x: string]: unknown;
    [x: number]: unknown;
}

export interface LanguageContext extends CommonContext {
    lang?: string;
}