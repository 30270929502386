<app-page type="centered">
    <app-page-content class="app-form-container flex-column flex-default-gap">
        <app-section-title-header class="full-width" section="step">
            <app-title i18n="@@unlogged-educational-institutions-header">Purchase section for educational institutions</app-title>
            <app-subtitle i18n="@@unlogged-educational-institutions--subtitle">Please select best option to start with:</app-subtitle>
        </app-section-title-header>

        <app-radio-group class="inst-option-list app-options-highlight"
                         key="inst-option"
                         (selectedValueChanged)="onOptionChanged($event)">
            <app-institutional-welcome-option-card [option]="Option.Trial">
                <div i18n="@@unlogged-get-a-free-trial-header"
                     title>Get a free trial</div>
                <div i18n="@@unlogged-get-a-free-trial-description"
                     content>
                    Try our games and their management module before buying.
                    The free trial requires to log in or to create an account.
                </div>
            </app-institutional-welcome-option-card>

            <app-institutional-welcome-option-card [option]="Option.RequestQuote">
                <div i18n="@@unlogged-request-a-quote-header"
                     title>Request a quote</div>
                <div i18n="@@unlogged-get-a-free-trial-description"
                     content>
                    Ask for a quote for the products you need.
                    The quote can be obtained without having to log in or create an account.
                </div>
            </app-institutional-welcome-option-card>

            <app-institutional-welcome-option-card [option]="Option.OrderProduct">
                <div i18n="@@unlogged-order-product-heading"
                     title>Order products</div>
                <div i18n="@@unlogged-order-product-description"
                     content>
                    Buy one or more of our products. Purchase requires logging in,
                    or creating an organization account if you don’t have one.
                </div>
            </app-institutional-welcome-option-card>
        </app-radio-group>

        <div class="app-form-actions">
            <span class="spacer"></span>
            <button mat-flat-button
                    color="primary"
                    [disabled]="!selectedOption"
                    (click)="next()">
                <span i18n="@@common-next"> Next </span>
            </button>
        </div>

        <app-footer></app-footer>
    </app-page-content>
</app-page>