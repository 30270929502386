<ng-content select="*"></ng-content>
<div #buttonContainer
     class="button-container">
    <button *ngFor="let item of items"
            mat-flat-button
            (click)="onItemClick(item, $event)"
            [color]="item.color"
            [disabled]="item.disabled">
        {{item.label}}
    </button>
</div>

<!-- The sub menu of all other buttons that can fit the previous buttonContainer. Simply hidden if all butons can be displayed because using ngIf will impact the avalaible space of the buttonContainer and create glitch. -->
<button mat-icon-button
        [hidden]="overflowIndex == -1"
        [matMenuTriggerFor]="moreIcon">
    <mat-icon class="my-icon">more_vert</mat-icon>
</button>

<mat-menu #moreIcon="matMenu"
          overlapTrigger="true"
          xPosition="before">
    <button mat-menu-item
            (click)="onItemClick(item, $event)"
            *ngFor="let item of overflowItems"
            [disabled]="item.disabled">
        <mat-icon *ngIf="item.icon"
                  [color]="item.iconColor">{{item.icon}}</mat-icon>
        <span>{{item.label}}</span>
    </button>
</mat-menu>