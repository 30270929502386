<app-design-card class="inst-welcome-option-card"
                 [clickable]="true"
                 (click)="cardSelect.setChecked()">
    <design-card-select groupKey="inst-option" [value]="option" #cardSelect />
    <design-card-title>
        <app-label type="dynamic"
                   [maxFontSize]="20">
            <ng-content select="[title]"></ng-content>
        </app-label>
    </design-card-title>
    <design-card-content class="secondary-text">
        <ng-content select="[content]"></ng-content>
    </design-card-content>
</app-design-card>