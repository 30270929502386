import { Model, ID, Member, ArrayMember } from "@uon/model";
import { Invoice } from "./invoice.model";
import { Organization } from "../org/organization.model";
import { User } from "../user/user.model";
import { CurrencyAmount } from "../common/currency.model";
import { SubscriptionPlan, SubscriptionType } from "./subscription.model";


@Model()
export class SubscriptionPrice {

    @ID()
    id: string;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    productCode: string;

    @Member()
    type: SubscriptionType;

    @Member()
    plan: SubscriptionPlan;

    @Member()
    amount: CurrencyAmount;

    @Member()
    stripeId: string;

    @Member()
    stripeProductId: string;

    @Member()
    version: string;
}