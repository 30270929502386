<div [attr.id]="'sub-' + sub?.subNo" class="list-item subscription-item">
    <div class="subscription-no">{{sub.subNo}}</div>
    <div class="grants-block">

        <div class="sub-grant" *ngFor="let g of sub.grants">

            <img [attr.src]="productService.getGameIcon(g.productShortCode)" alt="">
            <div class="grant-info-outer-block">
                <div class="grant-info-block">
                    <strong class="top-heading">{{ productService.getGameName(g.productShortCode) }}</strong>
                </div>
                <div class="grant-info-block">
                    <div class="info-subscription" *ngIf="sub.user">
                        <p i18n="@@personal-subscription-licenses"><strong>{{g.seats}}</strong> personal license</p>
                        <app-subscription-validity-label [subscription]="sub" [showNumber]="false"></app-subscription-validity-label>
                    </div>
                    <div class="info-subscription" *ngIf="sub.organization">
                        <p i18n="@@org-subscription-licenses"><strong>{{g.seats }}</strong>
                            licenses, provided by <strong>{{sub.organization.name}}</strong>
                        </p>
                        <app-subscription-validity-label [subscription]="sub" [showNumber]="false"></app-subscription-validity-label>
                    </div>
                </div>
            </div>
        </div>
        <div class="grant-info-outer-block">
            <div class="grant-info-block">
                <span class="spacer"></span>
            
                <span *ngFor="let state of [ApplogicUtils.getSubscriptionState(sub)]" [ngClass]="'chip ' + state.className">
                    {{state.label}}
                </span>
            </div>
            <div class="grant-info-block" *ngIf="cardMenu && canManage">
                <span class="spacer"></span>
                <div>
                    <app-checklist-popover [key]="'sub-' + sub?.subNo + '-change-mode-popover'" checklistItem="custom" placement="left-top">
                        <p i18n="@@common-click-here" popupcontent>Click Here</p>
                    </app-checklist-popover>
                </div>
                <button mat-icon-button (click)="setCurrent(sub)" [matMenuTriggerFor]="cardMenu.menu"
                    [matMenuTriggerData]="{subscription: sub}"
                    *ngIf="( (sub.type == 'organization') || ((sub?.renewOption != undefined) && (sub?.renewOption != 'Do Nothing')) ) && !checkSubscriptionExpire(sub.expiresOn)">
                    <mat-icon>
                        more_vert
                    </mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>