<div class="header">

    <mat-icon>info</mat-icon>

    <h2>
        <span i18n="@@onboarding-section-help-title">
            Onboarding
        </span>
    </h2>

    <span class="spacer">
    </span>


</div>

<div class="app-property-list-container">
    {{ onboardingUser?.state?.key }}
</div>


<!--

<span class="spacer">
    {{ onboardingUser?.state?.key }}
</span>

-->