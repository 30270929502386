<div class="product-order-card-container">
    <app-design-card [class]="getStyleClass()"
                     [clickable]="false"
                     [width]="100"
                     widthUnits="%"
                     [height]="getCardHeight()"
                     [sizeXSmall]="{width: 100, height: layout == Layout.Onboarding ? 95 : 154}">
        <design-card-image>
            <img class="product-image"
                 [src]="_imageUrl" />
        </design-card-image>
        <design-card-title>
            <div appDynamicFontSize
                 [maxFontSize]="20"
                 style="width: 100%; height: 100px; overflow: hidden;">{{_productLabel}}</div>
        </design-card-title>
        <design-card-subtitle [ngSwitch]="layout"
                              class="secondary-text">
            <div *ngSwitchCase="Layout.Onboarding">
                <div *ngIf="months > 0"
                     i18n="@@onboarding-subscription-form--delegate-step--tokens-for-months">{{p.seats}} tokens for {{months}} months</div>
                <div *ngIf="months == 0"
                     i18n="@@onboarding-subscription-form--delegate-step--tokens">{{p.seats}} tokens</div>
            </div>
            <ng-container *ngSwitchDefault>
                @switch(_productCode) {
                    @case('mmo') {
                        <span i18n="@@product-order-card--mmo-short-description">A fun and engaging education game, designed to help preschool and early elementary children ...</span>
                    }
                    @case('msm') {
                        <span i18n="@@product-order-card--msm-short-description">A fun and engaging education game, designed to help preschool and early elementary children ...</span>
                    }
                }
            </ng-container>
        </design-card-subtitle>
        <design-card-content>
            <ng-container class="product-card-content">
                <ng-template [ngTemplateOutlet]="priceContainerTemplate"></ng-template>
                <app-quantity-input *ngIf="layout == Layout.Selection"
                                    type="number"
                                    class="quantity-input"
                                    [(quantityModel)]="p.seats"
                                    (onQuantityModelChanged)="onQuantityChanged($event)">
                </app-quantity-input>
            </ng-container>
        </design-card-content>
        <design-card-header>
            @switch(_productCode) {
                @case('mmo') {
                    <div class="product-tag" i18n="@@product-order-card--mmo-tag1">Preschool-Elementary</div>
                    <div class="product-tag" i18n="@@product-order-card--mmo-tag2">Ages 5 to 9</div>
                }
                @case('msm') {
                    <div class="product-tag" i18n="@@product-order-card--msm-tag1">Grades 1 to 6</div>
                    <div class="product-tag" i18n="@@product-order-card--msm-tag2">Ages 7 to 12</div>
                }
            }
            <div class="help-icon"
                 *ngIf="layout == Layout.Onboarding">
                <!-- <app-help-icon text="todo"
                               placement="left"></app-help-icon> -->
            </div>
        </design-card-header>
        <design-card-footer *ngIf="layout == Layout.Summary">
            <app-product-store-links [product]="_currentProduct"
                                     section="product-order-card"
                                     style="width: 100%"></app-product-store-links>
        </design-card-footer>
    </app-design-card>
</div>
<ng-template #priceContainerTemplate>
    <div class="price-container">
        @switch(layout) {
            @case(Layout.Summary) {
                @if(_subscriptionType == SubscriptionType.individual) {
                    <span class="secondary-text"
                          i18n="@@common-user-count">{ p.seats, plural, =0 {No users} =1 {1 user} other { {{p.seats}} users } }</span>
                    <div class="price-label">{{pricePerMonth | apCurrency:_currency}} / <span i18n="@@common-month">month</span></div>
                }
                @else if(_subscriptionType == SubscriptionType.organization) {
                    <span class="secondary-text"
                          i18n="@@classroom-student-count">{ p.seats, plural, =0 {No students} =1 {1 student} other { {{p.seats}} students } }</span>
                    <div class="price-label">
                        <span>{{ totalPrice | apCurrency:_currency}}</span>
                        <span>&nbsp;/&nbsp;</span>
                        @if(_months == 1) {
                            <span i18n="@@common-month">month</span>
                        }
                        @else if(_months < 12)
                        {
                            <span>{{_months}}&nbsp;</span>
                            <span i18n="@@common-months">months</span>
                        }
                        @else if(_months == 12) {
                            <span i18n="@@common-year">year</span>
                        }
                    </div>
                }
            }
            @case(Layout.Selection) {
                @if(_subscriptionType == SubscriptionType.individual) {
                    <span class="price-label">{{pricePerMonth | apCurrency:_currency}}</span>
                    <span class="price-details secondary-text"
                        i18n="@@products-order-card--user-per-months-short">user /mo.</span>
                }
                @else if(_subscriptionType == SubscriptionType.organization) {
                    <span class="price-label">{{ price | apCurrency:_currency}}</span>
                    <div class="price-details secondary-text">
                        <span i18n="@@common-user-short">user</span>
                        <span>&nbsp;/&nbsp;</span>
                        @if(_months == 1) {
                        <span i18n="@@common-month">month</span>
                        }
                        @else if(_months < 12)
                        {
                        <span>{{_months}}&nbsp;</span>
                            <span i18n="@@common-months">months</span>
                            }
                            @else if(_months == 12) {
                            <span i18n="@@common-year">year</span>
                            }
                    </div>
                }
            }
        }

    </div>
</ng-template>