import { Component, Injector, Input, OnInit } from '@angular/core';
import { CommonQueryFilter, Organization, User, UserRole } from '@applogic/model';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { OrganizationService } from '../../organization/organization.service';
import { CommonColumnType, CommonListColumn } from 'src/app/core/common-list/common-list-column';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { CreateOrUpdateOrganizationDialogComponent } from '../create-or-update-organization-dialog/create-or-update-organization-dialog.component';
import { Router } from '@angular/router';
import { CommonListDisplayMode } from 'src/app/core/common-list/common-list-renderer/common-list-renderer.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-organization-list',
    templateUrl: './organization-list.component.html',
    styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent extends CommonListComponent<Organization> implements OnInit {

    CommonListDisplayMode = CommonListDisplayMode;

    @Input()
    userOrgs: Organization[];

    // If specified, add a column to display the role of this user.
    @Input()
    userRole: User;

    @Input()
    mode: 'selection'|'list' = 'selection';

    protected isSiteAdmin: boolean = false;
    protected showAll: boolean = false;
    
    constructor(
        private injector: Injector,
        private authService: AuthService,
        private organizationService: OrganizationService,
        private router: Router) {
        super(injector);

        this.alwaysShowSelected = true;
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 20];

        this.rendererOptions.showSearchBar = true;
        this.rendererOptions.autoHideSearchBar = true;
        this.supportCompactMode = true;

        if(authService.user.roles) {
            this.isSiteAdmin = authService.user.roles.indexOf(UserRole.Admin) != -1;
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initColumns();
        this.refreshItems(); 
    }

    initColumns() {
        this.initPersistentKey('applogic/organization/v3-' + this.mode);

        const isListMode = this.mode == 'list';

        this.setOptions({
            showSelectColumnsTable: this.compactRow
        });

        if(this.userRole) {
            this.addColumn("id", "id", true, false, CommonColumnType.ID);
        }

        this.addColumn($localize`:@@common-name:Name`, "name", true, true, CommonColumnType.Text);

        if(!this.compactRow && (this.mode == 'list')) {
            this.addColumn($localize`:@@common-type:Type`, "type", true, true, CommonColumnType.Custom, {
                isSortable: false,
                fixedWidth: "160px"
            });

            this.addColumn($localize`:@@common-members:Members`, "_memberCount", true, true, CommonColumnType.Template, {
                templateCellIdx: 1,
                isSortable: this.preload,
                fixedWidth: "65px"
            });
        }

        this.addColumn($localize`:@@common-your-role:Your role`, "role", true, true, CommonColumnType.Template, {
            isSortable: false,
            fixedWidth: "60px"
            
        });

        // this.addColumn($localize`:@@common-user-role:User Role`, "userRole", false, true, CommonColumnType.Template).canSort = false;
        if(this.userRole) {
            this.addColumn(this.userRole.fullName, "userRole", false, true, CommonColumnType.Template, {
                isSortable: false,
                fixedWidth: "60px"
            });
        }

        this.addColumn($localize`:@@common-created-on:Created On`, "createdOn", true, isListMode && !this.compactRow, CommonColumnType.Date).parameters.format = "mediumDate";
        this.addColumn($localize`:@@common-updated-on:Updated On`, "updatedOn", true, isListMode && !this.compactRow, CommonColumnType.Date).parameters.format = "mediumDate";

        // this.addColumn("", "actions", false, true, CommonColumnType.Action);
        this.updateColumns(true);
    }
    
    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;
        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });

        if(this.isSiteAdmin && this.showAll) {
            queryFilter.other.all = true;
        }
        else {
            queryFilter.other.all = false;
        }

        this.organizationService.searchOrgs(queryFilter).subscribe({
            next: (res) => {
                this.setResponse(res);
            }, error: (err) => {
                this.errorObject = err;
                console.error(" Error ", err.error.message);
            }
        });
    }

    getRole(row: Organization, column: CommonListColumn) {
        if(column.key == "userRole") {
            if(this.userRole) {
                const member = row.staffMembers.find(m => m.user.id == this.userRole.id);
                if(member) {
                    return member.role;
                }
            }
        }
        else if(column.key == "role") {
            const member = row.staffMembers.find(m => m.user.id == this.authService.user.id);
            if(member) {
                return member.role;
            }
        }
    }

    getCustomLabel(key: string, val: any, row: Organization) {
        if(key == "type") {
            return ApplogicUtils.getOrganizationTypeLabel(row.type, row.typeOther);
        }

        return key;
    }

    onCompactModeChanged() {
        this.initColumns();
    }

    openNewOrgDialog() {
        CreateOrUpdateOrganizationDialogComponent.createDialog(this.dialogService, this.dialog).show().then((org) => {
            if(org) {
                this.setSelected(org);
                this.refreshItems();
            }
        });
    }

    onRowClick(item: Organization, event: any) {
        if(this.mode == 'list') {
            event.stopPropagation();
            ApplogicUtils.navigateToOrganization(this.router, item);
        }
        else {
            super.onRowClick(item, event);
        }
    }

    onShowAll(event: MatCheckboxChange) {
        if(this.showAll != event.checked) {
            this.showAll = event.checked;
            this.refreshItems();
        }
    }
}
