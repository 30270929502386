import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewContainerRef } from '@angular/core';
import { ChecklistTrialClassroom, ChecklistTrialOrganization, ChecklistType, Product, SubscriptionAllocationMode } from '@applogic/model';
import { OnboardingService } from '../onboarding.service';
import { LocalStorageService } from "src/app/services/local-storage.service";
import { FADE_IN_OUT, OPACITY_IN_OUT } from "src/app/animations/fade";
import { ProductService } from 'src/app/services/product.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChecklistPopoverComponent } from '../checklist-popover/checklist-popover.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteEventService } from 'src/app/event/site-event.service';
import { SiteEvents } from 'src/app/event/site-events';
import { SubscriptionAllocationModeChangedEvent } from 'src/app/event/events/subscription-allocation-mode-changed.event';
import { SiteEventsBinder } from 'src/app/event/site-events-binder';
import { InviteUserSentEvent } from 'src/app/event/events/invite-user-sent.event';
import { AllocateSeatsEvent } from 'src/app/event/events/allocate-seats.event';
import { Model } from '@uon/model';
import { MatTab } from '@angular/material/tabs';


export interface ChecklistItem {
    name: string;
    refName?: string;
    isChecked(): boolean;
    label: string;
}

type ChecklistTypes = ChecklistTrialClassroom | ChecklistTrialOrganization;

@Component({
    selector: 'app-checklist-tab',
    templateUrl: './checklist-tab.component.html',
    styleUrls: ['./checklist-tab.component.scss'],
    providers: [{ provide: MatTab, useExisting: ChecklistTabComponent }],
    animations: [FADE_IN_OUT, OPACITY_IN_OUT],
})
export class ChecklistTabComponent extends MatTab implements OnInit, OnDestroy {

    checklistType: ChecklistType;

    // The classroomId for the ChecklistType.Classroom or the orgId for the ChecklistType.Organization
    @Input()
    elementId: string;

    @Input()
    orgId: string;

    _checklist: ChecklistTypes;

    @Input()
    set checklist(checklist: ChecklistTypes) {
        if (this._checklist != checklist) {
            this._checklist = checklist;

            const previousChecklistType = this.checklistType;

            if (this._checklist instanceof ChecklistTrialClassroom) {
                this.checklistType = ChecklistType.Classroom;
                let classroomTrial: ChecklistTrialClassroom = checklist as ChecklistTrialClassroom;
                if (!classroomTrial.tryProducts) classroomTrial.tryProducts = {};
                this.checklistClassroom = classroomTrial;
                this.checklistOrganization = undefined;
            }
            else if (this._checklist instanceof ChecklistTrialOrganization) {
                this.checklistType = ChecklistType.Organization;
                let orgTrial: ChecklistTrialOrganization = checklist as ChecklistTrialOrganization;
                this.checklistClassroom = undefined;
                this.checklistOrganization = orgTrial;
                this.itemsToHide['allocateTokens'] = this.checklistOrganization.allocationMode == SubscriptionAllocationMode.Automatic;
            }
            else {
                console.error("## Wrong checklist instance");
            }

            if(previousChecklistType != this.checklistType) {
                this.initItems();
            }

            // this.isVisible = true;

            this.checklistChange.emit(checklist);
        }
    }

    get checklist() {
        return this._checklist;
    }

    @Output()
    checklistChange: EventEmitter<ChecklistTypes> = new EventEmitter<ChecklistTypes>();

    checklistClassroom: ChecklistTrialClassroom;
    checklistOrganization: ChecklistTrialOrganization;

    products: Product[] = [];

    items: ChecklistItem[] = [];

    autoStart: boolean = true;

    itemsToHide: { [key: string]: boolean } = {};

    protected _isVisible: boolean = true;

    @Input()
    set isVisible(val: boolean) {
        if (this._isVisible != val) {
            this.isVisibleChange.emit(val);
            this._isVisible = val;
        }
    }

    get isVisible() {
        return this._isVisible;
    }

    @Output()
    isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private eventsBinder: SiteEventsBinder;

    constructor(@Optional() private scrollable: CdkScrollable,
        readonly elementRef: ElementRef<HTMLElement>, private localStorageService: LocalStorageService,
        private onboardingService: OnboardingService,
        private productService: ProductService,
        private router: Router,
        private route: ActivatedRoute,
        private eventService: SiteEventService, _viewContainerRef: ViewContainerRef) {
        super(_viewContainerRef, undefined);
        // super(elementRef);
        // this.isShortcutVisible = false;
        // this.isVisible = false;
        // this.isRightCard = true;

        this.eventsBinder = new SiteEventsBinder(this.eventService);
        this.eventsBinder.addCallback(SiteEvents.Subscription.Events.AllocationModeChanged, this.onEvent.bind(this));
        this.eventsBinder.addCallback(SiteEvents.Organization.Events.InviteUser, this.onEvent.bind(this));
        this.eventsBinder.addCallback(SiteEvents.Organization.Events.AllocateSeats, this.onEvent.bind(this));
        this.eventsBinder.register();
    }

    ngOnInit(): void {
        let scrollTo = this.route.snapshot.queryParamMap.get("scrollTo");
        if (scrollTo) this.autoStart = false;

        super.ngOnInit();
    }

    ngOnDestroy(): void {
        this.eventsBinder.release();
    }

    initItems() {
        if (this.checklistType == ChecklistType.Classroom) {
            this.initItemsClassroom();
        }
        else if (this.checklistType == ChecklistType.Organization) {
            this.initItemsOrganization();
        }
    }

    initItemsClassroom() {
        let thisptr = this;

        this.items.push({
            name: 'inviteStudent',
            isChecked: function (): boolean { return thisptr?.checklistClassroom?.inviteStudent; },
            label: $localize`:@@onboarding-checklist-item-invite-students:Invite my students`
        });

        this.productService.getProducts().then((products) => {
            this.products = products;
            this.products.forEach((p) => {
                let product = p;
                this.items.push({
                    name: 'tryProducts' + product.shortCode,
                    isChecked: function (): boolean {
                        let tryProducts = thisptr?.checklistClassroom?.tryProducts;
                        if (tryProducts && tryProducts[product.shortCode]) {
                            return true;
                        }

                        return false;
                    },
                    label: $localize`:@@onboarding-checklist-item-try-products:Try ${product.currentLocalizedShortName}`
                });
            });

            this.items.push({
                name: 'followProgress',
                isChecked: function (): boolean { return thisptr?.checklistClassroom?.followProgress; },
                label: $localize`:@@onboarding-checklist-item-follow-students-progress:Monitor students progress`
            });

            this.items.push({
                name: 'subscribed',
                isChecked: function (): boolean { return thisptr?.checklistClassroom?.subscribed; },
                label: $localize`:@@onboarding-checklist-item-subscribed:Subscribe my class`
            });

            this.clickNextChecklistItem();
        });
    }

    initItemsOrganization() {
        let thisptr = this;

        this.items.push({
            name: 'setTokenMode',
            refName: 'sub-{subNo}-change-mode-popover',
            isChecked: function (): boolean { return thisptr?.checklistOrganization?.setTokenMode; },
            label: $localize`:@@onboarding-checklist-org-item-set-token-mode:Set allocation mode`
        });

        this.items.push({
            name: 'inviteMembers',
            isChecked: function (): boolean { return thisptr?.checklistOrganization?.inviteMembers; },
            label: $localize`:@@onboarding-checklist-org-item-invite-members:Invite Members`
        });

        this.items.push({
            name: 'allocateTokens',
            refName: 'sub-{subNo}-allocate-tokens',
            isChecked: function (): boolean { return thisptr?.checklistOrganization?.allocateTokens; },
            label: $localize`:@@onboarding-checklist-org-item-allocate-tokens:Allocate Tokens`
        });

        this.items.push({
            name: 'createQuotation',
            isChecked: function (): boolean { return thisptr?.checklistOrganization?.createQuotation; },
            label: $localize`:@@onboarding-checklist-org-item-create-quotation:Create a quotation`
        });

        this.items.push({
            name: 'createInvoice',
            isChecked: function (): boolean { return thisptr?.checklistOrganization?.createInvoice; },
            label: $localize`:@@onboarding-checklist-org-item-create-invoice:Create an invoice`
        });

        this.items.push({
            name: 'payInvoice',
            isChecked: function (): boolean { return thisptr?.checklistOrganization?.payInvoice; },
            label: $localize`:@@onboarding-checklist-org-item-pay-invoice:Pay an invoice`
        });

        this.clickNextChecklistItem();
    }

    private clickNextChecklistItem() {
        for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];
            if (!item.isChecked() && !this.itemsToHide[item.name]) {
                if (this.autoStart) {
                    setTimeout(() => {
                        this.onChecklistClick(item, true);
                    }, 1000);
                }

                break;
            }
        };
    }

    public updateChecklist(checklist: any) {
        this.onboardingService.updateChecklist(this.elementId, this.checklistType, checklist).then((checklist) => {
            this.checklist = checklist;
        }).catch((ex) => {
            console.error(ex);
        });
    }

    onChecklistClick(item: ChecklistItem, init: boolean = false) {

        if (!init) {
            if (this.checklistType == ChecklistType.Classroom) {
                if (item.name == "subscribed") {
                    this.router.navigate(['/order/subscribe-classroom'])
                    return;
                }

            }
            else if (this.checklistType == ChecklistType.Organization) {
                if (item.name == "createQuotation") {
                    this.router.navigate(['/order/request-quotation'])
                    return;
                }
                else if (item.name == "createInvoice") {
                    this.router.navigate(['/subscriptions'], { queryParams: { scrollTo: "quo-create-invoice", popoverAfterScroll: "quo-create-invoice-popover" } })
                    return;
                }
                else if (item.name == "payInvoice") {

                    this.router.navigate(['/subscriptions'], { queryParams: { scrollTo: "inv-pay-now", popoverAfterScroll: "inv-pay-now-popover" } })
                    return;
                }
            }
        }


        let popover: ChecklistPopoverComponent;

        if (item.refName) {
            let key: string = item.refName;
            if (this.checklistOrganization) {
                key = key.replace("{subNo}", this.checklistOrganization.subNo);
            }

            popover = ChecklistPopoverComponent.popoverList.find(c => (c.checklistType == this.checklistType) && (c.key == key));
        }
        else {
            popover = ChecklistPopoverComponent.popoverList.find(c => (c.checklistType == this.checklistType) && (item.name.indexOf(c.checklistItem) != -1))
                ;
        }

        if (popover) {
            ChecklistPopoverComponent.popoverList.forEach((p) => {
                p.close();
            });
            popover.parent = this;
            const bounds = popover.elementRef.nativeElement.getBoundingClientRect();

            if (this.scrollable) {
                this.scrollable.scrollTo({ behavior: 'smooth', top: bounds.top - 80 + this.scrollable.measureScrollOffset('top') });
            }

            popover.open();
        }
    }

    followProgressUnderstood() {
        let checklist = Model.New(ChecklistTrialClassroom, {});
        checklist.followProgress = true;
        this.updateChecklist(checklist);
    }

    onStudentAdd() {
        if (!(this.checklist as ChecklistTrialClassroom)?.inviteStudent) {
            let checklist = Model.New(ChecklistTrialClassroom, {
                inviteStudent: true
            });
            this.updateChecklist(checklist);
        }
    }

    protected async onEvent(eventCategory: string, eventName: string, eventData: any) {
        if (eventName == SiteEvents.Subscription.Events.AllocationModeChanged) {
            if (this.checklistType == ChecklistType.Organization) {
                let event = eventData as SubscriptionAllocationModeChangedEvent;
                if (event.subscriptionId == this.checklistOrganization.subscriptionId) {
                    this.checklistOrganization.allocationMode = event.allocationMode;
                    this.itemsToHide['allocateTokens'] = this.checklistOrganization.allocationMode == SubscriptionAllocationMode.Automatic;
                    if (this.checklistOrganization) {
                        this.checklistOrganization.setTokenMode = true;
                    }
                }
            }
        }
        else if (eventName == SiteEvents.Organization.Events.InviteUser) {
            if (this.checklistType == ChecklistType.Organization) {
                let event = eventData as InviteUserSentEvent;
                if (event.orgId == this.checklistOrganization.orgId) {
                    this.checklistOrganization.inviteMembers = true;
                }
            }
        }
        else if (eventName == SiteEvents.Organization.Events.AllocateSeats) {
            if (this.checklistType == ChecklistType.Organization) {
                let event = eventData as AllocateSeatsEvent;
                if (event.subscriptionId == this.checklistOrganization.subscriptionId) {
                    this.checklistOrganization.allocateTokens = true;
                }
            }
        }
    }
}

