import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { map } from 'rxjs/operators';
import { GameProgressSelectableColumn } from "./game-progress-selectable-column";
const LOCAL_STORAGE_GAME_ACTIVITY_KEY_FR = "game/activities/fr";
const LOCAL_STORAGE_GAME_ACTIVITY_KEY_EN = "game/activities/en";
const LOCAL_STORAGE_GAME_WORDLIST_KEY_MSM_FR = "game/wordlist/msm/fr";
const LOCAL_STORAGE_GAME_WORDLIST_KEY_MSM_EN = "game/wordlist/msm/en";
@Pipe({
    name: "colKey",
    pure: true,
})
export class ColKeyPipe implements PipeTransform {
    constructor() { }

    transform(column: GameProgressSelectableColumn): any {
        return column.key;
    }
}
