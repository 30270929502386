import { CdkScrollable } from "@angular/cdk/scrolling";
import { AbstractControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { StripeError } from "@stripe/stripe-js";

/**
 * An utility class for the Angular site.
 */
export class StripeUtils {

    public static filterAndLocalizeStripeError(err: any) {
        if(err.error?.context?.type == "StripeCardError") {
            return this.localizeStripeError(err.error?.context.raw as StripeError);
        }
        else if(err.error?.context?.type == "i") {
            return this.localizeStripeError(err.error?.context.raw as StripeError);
        }
        else if(err.error?.type == "StripeCardError") {
            return this.localizeStripeError(err.error.raw as StripeError);
        }
        else if(err.error?.type == "i") {
            return this.localizeStripeError(err.error.raw as StripeError);
        }
        else if(err.error?.type == "StripeInvalidRequestError") {
            console.log("Raw: " + JSON.stringify(err.error));
            const result: any = {};
            result.message = err.error.raw.message;
            result.errorEventId = "stripe_invalid_" + err.error.raw.requestId;
            result.stripeError = err.error.raw;
    
            return result;
        }
        else if(err.error?.context?.type == "StripeInvalidRequestError") {
            console.log("Raw: " + JSON.stringify(err.error));
            const result: any = {};
            result.message = err.error.raw.message;
            result.errorEventId = "stripe_invalid_" + err.error.raw.requestId;
            result.stripeError = err.error.raw;
    
            return result;
        }
        
        return err;
    }

    /**
     * Localize a Stripe error.
     * 
     * List of errors:
     *     https://stripe.com/docs/error-codes
     *     
     * @param {StripeError} error The Stripe error.
     * 
     * @returns {any} Return the error object to be used with the ErrorMessageAreaComponent.
     */
    public static localizeStripeError(error: StripeError) {
        const result: any = {};

        // We could localize the following message:
        // https://stackoverflow.com/questions/23437439/non-english-texts-in-stripe-possible
        // But since the user enter informations on the Stripe form, it shouldn't happen here.
        // So better let it fallback to the default "internal error"
        // with the event id corresponding to the Stripe error code.

        result.message = error.message;
        result.errorEventId = "stripe_" + error.code;
        result.stripeError = error;

        return result;
    }
}
