import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClassroomResponse, Product, Student } from '@applogic/model';
import { PrintService } from 'src/app/services/print.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-student-list-print-page',
    templateUrl: './student-list-print-page.component.html',
    styleUrls: ['./student-list-print-page.component.scss']
})
export class StudentListPrintPageComponent implements OnInit {

    studentsColum1: Student[] = [];
    studentsColum2: Student[] = [];
    students: {student1: Student, student2: Student}[] = [];

    products: Product[] = [];

    constructor(private productService: ProductService, private route: ActivatedRoute, private printService: PrintService) {
        
        let classroomResponse: ClassroomResponse = this.route.snapshot.data.classroom;
        classroomResponse.allocateSeatsToStudents();

        const students = classroomResponse.classroom.students;
        students.reverse();

        for(const student of students) {
            if(this.studentsColum1.length > this.studentsColum2.length) {
                this.studentsColum2.push(student);
            }
            else {
                this.studentsColum1.push(student);
            }
        }

        const maxLength = this.studentsColum1.length > this.studentsColum2.length ? this.studentsColum1.length  : this.studentsColum2.length ;

        for(let i=0; i<maxLength; i++) {

            let student1: Student;
            let student2: Student;

            if(i <this.studentsColum1.length) {
                student1 = this.studentsColum1[i];
            }
            if(i <this.studentsColum2.length) {
                student2 = this.studentsColum2[i];
            }

            this.students.push({student1, student2});
        }

        const testMode = route.snapshot.queryParamMap.get('testMode');

        
        this.productService.getProducts().then((products) => {
            this.products = products;
            if(testMode != 'true') {
                setTimeout(() => {
                    this.printService.onDataReady();
                }, 2000); // waiting 2 seconds to make sure images will be loaded.
            }
        });
        
    }

    ngOnInit(): void {
        
    }

    getProductImageUrl(code: string) {
        code = code.toUpperCase();
        let product = this.products.find(p => p.shortCode == code);
        return product ? product.imageUrl : "";
    }

}
