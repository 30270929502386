
import { ID, Member, Model } from "@uon/model";
import { User } from "../user/user.model";


@Model()
export class LegacyPlatformPurchase {

    @ID()
    id: string;
    
    // the user associated with this installation, if any
    @Member()
    user: User;

    // The unique identifier for the purchase.
    @Member()
    purchaseId: string;

    // The purchase type (IAP, PaidApp)
    @Member()
    type: string;

    // The store name (AppleAppStore, GooglePlay, etc...)
    @Member()
    store: string;

    // The product Id as described on the store
    @Member()
    productId: string;

    // The raw receipt provided by the platform
    @Member()
    receipt: object;

    // The purchase receipt (decoded)
    @Member()
    decodedReceipt: object;

    // The device id of the device when this purchase was registered.
    @Member()
    deviceId: string;

    // The installation id of the installation used when was registered.
    @Member()
    installId: string;

    @Member()
    createdOn: Date;

}
