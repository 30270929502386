import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
// import { CardTemplateDirective } from './directives/card-template.directive';

@Component({
    selector: 'renderer-templates',
    templateUrl: './common-list-renderer-templates.component.html',
    styleUrl: './common-list-renderer-templates.component.scss'
})
export class CommonListRendererTemplatesComponent<T> {

    @Input()
    templates: TemplateRef<any>[];

    @Input()
    templateColumns: TemplateRef<any>[];

    @Input()
    actionTemplate: TemplateRef<any>;

    @Input()
    actionMenuTemplate: TemplateRef<any>;

    @Input()
    emptyTemplate: TemplateRef<any>;

    @Input()
    noResultTemplate: TemplateRef<any>;

    @Input()
    cardTemplate: TemplateRef<{row: T}>;

    @Input()
    cardListClass: string;

}
