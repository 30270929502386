import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Classroom, ClassroomHomework, ClassroomHomeworkStudent, Student } from '@applogic/model';
import { HomeworkListComponent } from 'src/app/homework/homework-list/homework-list.component';
import { HomeworkService } from 'src/app/homework/homework.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface AssignStudentHomeworksDialogData {
    classroom: Classroom,
    student: Student
}

@Component({
    selector: 'app-assign-student-homeworks-dialog',
    templateUrl: './assign-student-homeworks-dialog.component.html',
    styleUrls: ['./assign-student-homeworks-dialog.component.scss']
})
export class AssignStudentHomeworksDialogComponent implements OnInit {
    errorObject: any;
    
    classroom: Classroom;
    student: Student;

    constructor(@Inject(MAT_DIALOG_DATA) public data: AssignStudentHomeworksDialogData,
        private mdDialogRef: MatDialogRef<AssignStudentHomeworksDialogComponent, void>,
        private homeworkService: HomeworkService) {
        this.classroom = this.data.classroom;
        this.student = this.data.student;
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: AssignStudentHomeworksDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<AssignStudentHomeworksDialogComponent, AssignStudentHomeworksDialogData>(dialogService, dialog, AssignStudentHomeworksDialogComponent, {
            data,
            width: "50%",
            maxWidth: "80%",
            minWidth: "50%",
            autoFocus: false, // We don't want the first radio button to be gray.
            panelClass: 'makeItMiddle'
        }, config);
    }

    assignHomeworks(list: HomeworkListComponent) {
        let homeworksToRemove: string[] = [];
        let homeworksToAdd: string[] = [];

        // Check was homeworks selection was added or removed.
        list.filteredHomeworks.forEach((homework) => {
            if(!homework.classroomShared) {
                let isSelected: boolean = list.selection.isSelected(homework);
                let isIncluded: boolean = homework.students ? homework.students.find(s => s.studentId == this.student.id) != undefined : false;

                if(isSelected!= isIncluded)
                {
                    if(isSelected) {
                        homeworksToAdd.push(homework.id);
                    } else {
                        homeworksToRemove.push(homework.id);
                    }
                }
                
            }
        });

        this.homeworkService.updateStudentHomeworks(this.classroom.id, this.student.id, homeworksToAdd, homeworksToRemove).subscribe(() => {

            const updatedHomeworks: ClassroomHomework[] = [];

            // Update homeworks selections.
            list.filteredHomeworks.forEach((homework) => {
                if(!homework.classroomShared) {
                    let isSelected: boolean = list.selection.isSelected(homework);
                    let isIncluded: boolean = homework.students ? homework.students.find(s => s.studentId == this.student.id) != undefined : false;
    
                    if(isSelected != isIncluded)
                    {
                        if(isSelected) {
                            let chs = new ClassroomHomeworkStudent();
                            chs.addedOn = new Date();
                            chs.studentId = this.student.id;
                            homework.students.push(chs);
                        } else {
                            let idx = homework.students.findIndex(s => s.studentId == this.student.id);
                            if(idx != -1)
                            {
                                homework.students.splice(idx,1)  
                            }
                        }
                    }
                    
                }
            });

            this.mdDialogRef.close();

        }, (err) => {
            this.errorObject = err;
        });


        // list.selection
    }
}
