import { ID, Member, Model } from "@uon/model";

export enum EmailTemplateSource {
    Default = "default",
    S3 = "s3",
    Mailchimp = "mailchimp",            // Generate a email template from a mailchimp template.
    MailchimpEmail = "mailchimp-email", // Use the mailchimp template system directly.
    Database = "database"
}

export enum EmailTemplateDst {
    Default = "default",
    SES = "ses",
    Mailchimp = "mailchimp-html",            // Send the email using mailchimp.
    MailchimpEmail = "mailchimp-email",     // Use the mailchimp template system directly.
    Memory = "memory"                        // Simply return the subject and html rendered.
}

export enum EmailCampaignUsersFilter {
    AllUsers = "all-users",
    AllRegistered = "all-registered",
    NewsletterSubscribedRegistered = "newsletter-subscribed-registered",
    NewsletterSubscribed = "newsletter-subscribed",
    NewsletterSubscribedAndYearActive = "newsletter-subscribed-year-active",
    SiteAdmins = "site-admins"
}

export enum EmailCampaignStatus {
    NotStarted = "not-started",
    InProgress = "in-progress",
    Stopping = "stopping",
    Stopped = "stopped",
    Completed = "completed"
}

@Model()
export class EmailCampaign {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    status: EmailCampaignStatus;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    name: string;

    @Member()
    templateKey: string;

    @Member()
    usersFilter: EmailCampaignUsersFilter;
}
