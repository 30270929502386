import { Model, ID, Member } from "@uon/model";
import { LegacyLicense } from "./license.model";
import { User } from "../user/user.model";



@Model()
export class InstallationToken {

    @Member()
    tokenId: string;

    @Member()
    tokenDate: Date;

    @Member()
    expireDate: Date;

    @Member()
    licensed: boolean;

    @Member()
    owned: boolean;

    @Member()
    validated: boolean = true;

}

@Model()
export class LegacyDeviceInstallation {

    @ID()
    id: string;

    @Member()
    token: InstallationToken;

    /**
     * the user associated with this installation, if any
     */
    @Member()
    user: User;

    /**
     * the license associated with this installation
     */
    @Member()
    license: LegacyLicense;

    /**
     * the last IP address used by the client
     */
    @Member()
    clientIp: string;

    /**
     * generated id for an installation
     */
    @Member()
    installId: string;

    /**
     * The platform on which the installation occured
     */
    @Member()
    platform: string;

    /**
     * 3-letter product code subject to this installation
     */
    @Member()
    productCode: string;

    /**
     * the device id associated with the installation
     */
    @Member()
    deviceId: string;

    /**
     * The operation system on which the installation took place
     */
    @Member()
    deviceOS: string;

    /**
     * The name of the device
     */
    @Member()
    deviceName: string;

    /**
     * The device model
     */
    @Member()
    deviceModel: string;

    /**
     * The last time the user made any requests to the api from the app
     */
    @Member()
    lastVisitDate: Date;

    /**
     * Extra user data
     */
    @Member()
    userData: object;


    @Member()
    createdOn: Date;


}