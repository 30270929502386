<app-dialog icon="school"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@select-classroom-dialog--title">Select a classroom</app-dialog-title>
    <app-dialog-content>
        <app-classrooms-list [orgId]="data.orgId"
                             [excludeClassroomId]="data.classroomIdToExclude"
                             [(selectedItem)]="selectedClassroom"></app-classrooms-list>
    </app-dialog-content>
    <button mat-stroked-button
            matDialogClose
            appDialogAction
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="accent"
            [disabled]="!selectedClassroom"
            (click)="select()">
        <span i18n="@@common-select">Select</span>
    </button>
</app-dialog>