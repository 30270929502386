import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

interface RadioButtonGroup {
    selectedValue?: any;
    buttons: Set<any>;
    valueChanged: EventEmitter<any>;
}

@Component({
    selector: 'app-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrl: './radio-group.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class RadioGroupComponent {

    private static groups: { [groupKey: string]: RadioButtonGroup } = {};

    @HostBinding('class') get getClasses() {
        return "flex-row flex-default-gap flex-wrap app-options-highlight";
    }
    
    public static GetGroup(groupKey: string, forceCreate: boolean = true) {
        let group = RadioGroupComponent.groups[groupKey];
        if (!group && forceCreate) {
            group = {
                buttons: new Set<any>(),
                valueChanged: new EventEmitter<any>()
            };

            RadioGroupComponent.groups[groupKey] = group;
        }
        return group;
    }

    /**
     * The the current selected value for a group of radio buttons.
     * 
     * @param groupKey The group key.
     * @param value The selected value.
     * @param triggerEvent Whether we want the "onCheck" event to be triggered. Normally, this is called from the code and we don't want the event to be triggered like if someone clicked on a radio button.
     */
    public static SetSelectedValue(groupKey: string, value: any, triggerEvent: boolean = false) {
        let group = RadioGroupComponent.GetGroup(groupKey);
        const hasChanged = group.selectedValue != value;

        if (hasChanged) {
            group.selectedValue = value;
            group.buttons.forEach((button) => {
                button.setChecked(button.value == value, triggerEvent);
            });
            group.valueChanged.emit(value);
        }
    }

    public static AddButton(groupKey: string, button: any) {
        let group = RadioGroupComponent.GetGroup(groupKey);

        group.buttons.add(button);

        return group;
    }

    public static RemoveButton(groupKey: string, button: any) {
        let group = RadioGroupComponent.GetGroup(groupKey, false);
        if (group) {
            group.buttons.delete(button);
            if (group.buttons.size == 0) {
                delete RadioGroupComponent.groups[groupKey];
            }
        }
    }

    @Input()
    key: string;

    @Input()
    set value(v: any) {
        RadioGroupComponent.SetSelectedValue(this.key, v);
    }

    @Output()
    selectedValueChanged = new EventEmitter<any>();

    private _subscription: Subscription;

    ngOnInit() {
        let group = RadioGroupComponent.GetGroup(this.key);
        this._subscription = group.valueChanged.subscribe((v) => {
            this.selectedValueChanged.emit(v);
        });
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

}
