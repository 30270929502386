<app-dialog icon="people"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@assign-tokens-dialog-title">Assign Tokens</app-dialog-title>
    <app-dialog-content>
        <app-assign-tokens-panel [student]="student"
                                 [userAllocations]="userAllocations"></app-assign-tokens-panel>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogActioncolor="accent"
            [disabled]="!tokensPanel?.atLeastOneChecked || (tokensPanel?.processing > 0)"
            (click)="assignTokens()">
        <span i18n="@@assign-tokens-button">Assign</span>
    </button>
</app-dialog>