import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from './language.service';

/**
 * Manage the list of countries.
 * 
 * Source: https://github.com/stefangabos/world_countries
 * 
 * The .json and .png files are copied to the S3 bucket.
 */
@Injectable({
    providedIn: 'root'
})
export class CountryService {

    countryList: any;

    constructor(private httpClient: HttpClient,
        private languageService: LanguageService) { }

    getCountryList(): Observable<any> {
        return this.httpClient.get(`https://appligogiques-web.s3.amazonaws.com/countries/countries_${this.languageService.currentLanguageCode}.json`);
    }

    getCountryCodeByName(selectedCountryValue: any) {
        if(!this.countryList) return selectedCountryValue;

        const FOUND = this.countryList.find(item => item.name.toLowerCase() === selectedCountryValue.toLowerCase() ||
            item.alpha2.toLowerCase() === selectedCountryValue.toLowerCase());
        return FOUND ? FOUND.alpha2 : selectedCountryValue;
    }

    getCountryName(countryCode: any) {
        if(!this.countryList) return countryCode;

        const FOUND = this.countryList.find(item => item.name.toLowerCase() === countryCode.toLowerCase() ||
            item.alpha2.toLowerCase() === countryCode.toLowerCase());
        return FOUND ? FOUND.name : countryCode;
    }

    refreshCountryList() {
        return new Promise((resolve, rejects) => {
            if(this.countryList) {
                resolve(this.countryList);
            } else {
                this.getCountryList().subscribe((countryList) => {
                    this.countryList = countryList;
                    resolve(countryList);
                }, (err) => {
                    rejects(err);
                });
            }
        });
    }
}
