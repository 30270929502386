<div class="sidebar-container">

    <div class="toolbar-ext">
        <app-logo></app-logo>
    </div>


    <!-- <div class="org-context"
         *ngIf="account.currentOrg">
        <label>Managing</label>
        <div class="current-org"
             #currentOrg>
            <mat-icon>
                business
            </mat-icon>
            <span class="name"
                  [routerLink]="['', 'organizations', account.currentOrg.id]">
                {{ account.currentOrg.name }}
            </span>
            <button mat-icon-button
                    (click)="openOrgSelect()"
                    *ngIf="account.currentProfile.orgs.length > 1">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
        </div>
    </div> -->

    <!-- <ng-template #orgSelectTemplate>

        <div class="org-select mat-elevation-z12"
             @collapseHeight>
            <div class="org-name-block"
                 *ngFor="let o of account.otherOrgs">
                <mat-icon (click)="selectOrg(o)"> business </mat-icon>

                <div class="org-overflow"
                     (click)="selectOrg(o)"
                     [id]="o?.id">{{o?.name}}
                </div>

                <div class="org-info"
                     *ngIf="isShowTooltip(o)"
                     [matTooltip]="o?.name">
                    ?
                </div>

            </div>
        </div>

    </ng-template> -->

    <!-- <div class="header">
        <div class="title">
            {{ account.currentOrg.name }}
        </div>

        <div class="actions">
            <button mat-button [routerLink]="['', 'organizations', account.currentOrg.id]">Manage</button>
            <span class="spacer"></span>

            <button mat-button disabled>Change</button>
        </div>


    </div>-->

    <div class="menus">

        <div class="main-nav-buttons">


            <div class="group">
                <button mat-button
                        routerLink="/"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-home"></mat-icon>
                    <span i18n="@@main-menu-label-home">Home</span>
                </button>

                <button mat-button
                        routerLink="/subscriptions"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-subscription"></mat-icon>
                    <span i18n="@@main-menu-label-subscriptions">Subscriptions</span>
                </button>

                <button mat-button
                        routerLink="/games"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-game"></mat-icon>
                    <span i18n="@@common-games">Games</span>
                </button>

                <button mat-button
                        routerLink="/organizations"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-organization"></mat-icon>
                    <span i18n="@@main-menu-label-my-organizations">Organizations</span>
                </button>

                <ng-container *ngIf="account.currentProfile?.orgs.length > 0">
                    <button mat-button
                            routerLink="/classrooms"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon svgIcon="menu-class"></mat-icon>
                        <span i18n="@@main-menu-label-classrooms">Classrooms</span>
                    </button>

                </ng-container>

                <button mat-button
                        routerLink="/billing"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-billing"></mat-icon>
                    <span i18n="@@billing-page--title">Billing</span>
                </button>
                <button mat-button
                        routerLink="/account"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-account"></mat-icon>
                    <span i18n="@@main-menu-label-account"> Account </span>
                </button>

                <button mat-button
                        *ngIf="language.currentLanguageCode == 'fr'"
                        routerLink="/eqol"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon svgIcon="menu-eqol"></mat-icon>
                    <span i18n="@@common-SQOOL">SQOOL</span>
                </button>
            </div>

            <!--  <mat-divider></mat-divider>
            <div class="group">
                <button mat-button
                        routerLink="/knowledgebase"
                        [routerLinkActive]="'mat-primary'"
                        [routerLinkActiveOptions]="{exact: true}">
                    <mat-icon>help</mat-icon>
                    <span i18n="@@main-menu-label-help">Help</span>
                </button>
            </div>-->

            <ng-container *ngIf="!_testMode && account.isAdministrator">
                <mat-divider></mat-divider>
                <div class="group group-admin">
                    <button mat-button
                            routerLink="/administration/users"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>people</mat-icon>
                        <span i18n="@@main-menu-label-manage-users">Manage Users</span>
                    </button>

                    <button mat-button
                            routerLink="/administration/organizations"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>domain</mat-icon>
                        <span i18n="@@main-menu-label-manage-orgs">Organizations</span>
                    </button>
                    <button mat-button
                            routerLink="/administration/quotation"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>card_travel</mat-icon>
                        <span i18n="@@main-menu-label-manage-quotation">Quotation</span>
                    </button>
                    <button mat-button
                            routerLink="/administration/invoice"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>note</mat-icon>
                        <span i18n="@@main-menu-label-manage-invoice">Invoice</span>
                    </button>
                    <button mat-button
                            routerLink="/administration/subscriptions-management"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>card_membership</mat-icon>
                        <span i18n="@@main-menu-label-manage-subscriptions">Subscriptions</span>
                    </button>

                    <button mat-button
                            routerLink="/administration/promocode"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>local_activity</mat-icon>
                        <span i18n="@@main-menu-label-manage-promo-code">PromoCodes</span>
                    </button>
                    <button mat-button
                            routerLink="/administration/suggestions"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>mode_comment</mat-icon>
                        <span i18n="@@main-menu-label-manage-suggestions">Suggestions</span>
                    </button>
                    <button mat-button
                            routerLink="/administration/prices"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>monetization_on</mat-icon>
                        <span i18n="@@admin-prices-management-title">Prices Management</span>
                    </button>

                    <mat-divider></mat-divider>
                    <button style="pointer-events: none !important;"
                            mat-button>
                        <mat-icon>favorite</mat-icon>
                        <span i18n="@@main-menu-label-manage-onboarding">Onboarding</span>
                    </button>
                    <div class="subgroup">
                        <button mat-button
                                routerLink="/administration/onboarding-management/users"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon>people</mat-icon>
                            <span i18n="@@common-onboarding-states">States</span>
                        </button>
                        <button mat-button
                                routerLink="/administration/onboarding-management/forms"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon>event_note</mat-icon>
                            <span i18n="@@common-onboarding-forms">Forms</span>
                        </button>
                    </div>

                    <mat-divider></mat-divider>
                    <button style="pointer-events: none !important;"
                            mat-button>
                        <mat-icon>email</mat-icon>
                        <span class="middle-title"
                              i18n="@@common-email"> Email </span>
                    </button>
                    <div class="subgroup">
                        <button mat-button
                                routerLink="/administration/email"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon>email</mat-icon>
                            <span i18n="@@main-menu-label-manage-email-templates">Email Templates</span>
                        </button>
                        <button mat-button
                                routerLink="/administration/email-assets"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon>collections</mat-icon>
                            <span i18n="@@admin-email-assets-manager-heading">Email Assets</span>
                        </button>
                        <button mat-button
                                routerLink="/administration/email-campaigns"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon svgIcon="email-campaign"></mat-icon>
                            <span i18n="@@admin-email-campaign-manager-heading">Email Campaigns</span>
                        </button>
                        <button mat-button
                                routerLink="/administration/emaildelivery"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon>email</mat-icon>
                            <span i18n="@@admin-email-delivery-manager-heading">Email Delivery</span>
                        </button>
                    </div>

                    <!-- <button mat-button
                            routerLink="/administration/content"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>article</mat-icon>
                        <span i18n="@@main-menu-label-manage-orgs">Manage Content</span>
                    </button> -->
                </div>
            </ng-container>

            <div class="group group-admin"
                 *ngIf="!_testMode && (account.isAdministrator || account.isWordListEditor || account.isTranslator)">
                <mat-divider></mat-divider>
                <ng-container *ngIf="account.isAdministrator || account.isWordListEditor">
                    <button mat-button
                            routerLink="/wordlist/manage"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>local_activity</mat-icon>
                        <span i18n="@@main-menu-label-manage-word-lists">Word Lists</span>
                    </button>
                </ng-container>

                <ng-container *ngIf="account.isAdministrator || account.isWordListEditor">
                    <button mat-button
                            routerLink="/wordlist/words"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>local_activity</mat-icon>
                        <span i18n="@@main-menu-label-manage-words-list">Words List</span>
                    </button>
                </ng-container>

                <ng-container *ngIf="account.isAdministrator || account.isTranslator">
                    <mat-divider></mat-divider>
                    <div class="group">
                        <button mat-button
                                routerLink="/translation"
                                [routerLinkActive]="'mat-primary'"
                                [routerLinkActiveOptions]="{exact: true}">
                            <mat-icon>translate</mat-icon>
                            <span>Translation Tools</span>
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="account.isAdministrator">
                    <button mat-button
                            routerLink="/developer/home"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>local_activity</mat-icon>
                        <span i18n="@@main-menu-label-developer-page">Developer</span>
                    </button>
                </ng-container>

                <ng-container *ngIf="account.isAdministrator">
                    <button mat-button
                            routerLink="/administration/activities"
                            [routerLinkActive]="'mat-primary'"
                            [routerLinkActiveOptions]="{exact: true}">
                        <mat-icon>local_activity</mat-icon>
                        <span i18n="@@admin-user-activities-manager-heading">User Activities</span>
                    </button>
                </ng-container>
            </div>


        </div>
    </div>

    <app-upgrade-now-section></app-upgrade-now-section>
</div>