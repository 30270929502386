import { Directive, ElementRef, Input, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[alignWithCheck]'
})
export class AlignWithCheckDirective implements AfterViewInit, OnDestroy {
    @Input('alignWithCheck') set referenceElement(value: string | ElementRef) {
        if (typeof value === 'string') {
            this.referenceElementId = value;
        } else if (value instanceof ElementRef) {
            this.referenceElementRef = value;
        }
    }

    private referenceElementId: string;
    private referenceElementRef: ElementRef;
    private resizeSubscription: Subscription;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngAfterViewInit() {
        this.initializeReferenceElement();
        if (this.referenceElementRef) {
            this.checkAlignment();

            this.resizeSubscription = fromEvent(window, 'resize')
                .pipe(debounceTime(100))
                .subscribe(() => {
                    this.checkAlignment();
                });
        } else {
            console.error(`Reference element not found.`);
        }
    }

    ngOnDestroy() {
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    private initializeReferenceElement() {
        if (this.referenceElementId) {
            const element = document.getElementById(this.referenceElementId);
            if (element) {
                this.referenceElementRef = new ElementRef(element);
            } else {
                console.error(`Element with id ${this.referenceElementId} not found.`);
            }
        }
    }

    private checkAlignment() {
        const targetElement = this.el.nativeElement;
        const referenceElement = this.referenceElementRef.nativeElement;
        const referenceElementTop = referenceElement.offsetTop;

        if (targetElement.offsetTop !== referenceElementTop) {
            this.renderer.addClass(targetElement, 'not-aligned');
        } else {
            this.renderer.removeClass(targetElement, 'not-aligned');
        }
    }
}
