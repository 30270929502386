import { Component } from '@angular/core';
import { CommonListAbstractCellRendererComponent } from '../common-list-abstract-cell-renderer/common-list-abstract-cell-renderer.component';


interface AudioListData {
    hasAudioDict: { [key: string]: boolean };
    updatedAudio: number;
    updatingAudio: number;
    updatingAudioDict: { [key: string]: boolean };
}

@Component({
    selector: 'app-common-list-audio-cell-renderer',
    templateUrl: './common-list-audio-cell-renderer.component.html',
    styleUrl: './common-list-audio-cell-renderer.component.scss'
})
export class CommonListAudioCellRendererComponent extends CommonListAbstractCellRendererComponent {

    protected getListData() {
        let audioData: AudioListData = this.parent.listData.audioData;
        if(!audioData) {
            audioData = {
                hasAudioDict: {},
                updatedAudio: 0,
                updatingAudio: 0,
                updatingAudioDict: {}
            };

            this.parent.listData.audioData = audioData;
        }

        return audioData;
    }

    playAudio(key: string, val: any, row: any): void {
        if (!this.parent._options.autoCheckAudio) {
            this.checkAudio(key, val, row, false, 0);
        }
        this.list.playAudio(key, val, row);
    }

    /**
     * Check if the audio exists.
     * 
     * It also cache the result to avoid multiple query.
     * 
     * @param {string} key The field key.
     * @param {any} val The field value.
     * @param {any} row The field row.
     * @param {boolean} useCacheOrTrue If true, only use the cached result or return true if it was not cached yet.
     * @param {number} n Simply to force angular to render the value again.
     */
    public checkAudio(key: string, val: any, row: any, useCacheOrTrue: boolean, n: number): boolean {
        const data = this.getListData();

        const url = this.list.getAudioUrl(key, val, row);

        if (data.hasAudioDict[url] != undefined) {
            return data.hasAudioDict[url];
        }

        if (data.updatingAudio >= 5) {
            return true;
        }

        if (data.updatingAudioDict[url]) {
            return true;
        }

        if (useCacheOrTrue) {
            return true;
        }

        console.log("Check if audio exists at url: " + url);

        data.updatingAudioDict[url] = true;
        data.updatingAudio++;

        this.list.checkAudio(key, val, row).then((result) => {
            data.hasAudioDict[url] = result;
            data.updatingAudio--;
            delete data.updatingAudioDict[url];
            data.updatedAudio++;
        }, (ex) => {
            console.error("Ex: " + JSON.stringify(ex));
            data.hasAudioDict[url] = false;
            data.updatingAudio--;
            delete data.updatingAudioDict[url];
            data.updatedAudio++;
        });
    }
}
