<div class="tell-us-form">
    <app-form-builder-renderer [form]="billingForm"
                               [templates]="[orgSelectTemplate, addressNameTemplate]"></app-form-builder-renderer>
</div>

<ng-template #orgSelectTemplate
             let-data>
    <app-organization-selection-form-control [control]="data.field?.control"
                                             (orgChanged)="onOrganizationChanged($event)"></app-organization-selection-form-control>
</ng-template>

<ng-template #addressNameTemplate
             let-data>
    <app-address-name-input [label]="data.field?.label"
                            [control]="data.field?.control"
                            [hidden]="!!organizationId"
                            [(name)]="organizationName"
                            [appearance]="data.field?.data.appearance"
                            [floatLabel]="data.field?.data.floatLabel"
                            [subscriptSizing]="data.field?.data.subscriptSizing"
                            (onAddressSelect)="onAddressSelect($event)"></app-address-name-input>
</ng-template>