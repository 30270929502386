import { Component, Input } from '@angular/core';
import { CommonListRendererComponent } from '../../common-list-renderer/common-list-renderer.component';
import { ICommonList } from '../../common-list.interface';


@Component({
    selector: 'app-common-list-paginator',
    templateUrl: './common-list-paginator.component.html',
    styleUrl: './common-list-paginator.component.scss'
})
export class CommonListPaginatorComponent {
    
    _list: ICommonList;

    @Input()
    set list(l: ICommonList) {
        this._list = l;
    }

    get list() {
        return this._list;
    }

    _parent: CommonListRendererComponent;

    @Input()
    set parent(p: CommonListRendererComponent) {
        this._parent = p;
    }

    get parent() {
        return this._parent;
    }


}
