import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OnboardingService } from "./onboarding.service";
import { CoreModule } from '../core/core.module';
import { OnboardingHelpTabComponent } from './onboarding-help-tab/onboarding-help-tab.component';
import { ChecklistTabComponent } from './checklist-tab/checklist-tab.component';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ChecklistPopoverComponent } from './checklist-popover/checklist-popover.component';
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [OnboardingHelpTabComponent, ChecklistTabComponent, ChecklistPopoverComponent],
    imports: [
        CommonModule,
        CoreModule,
        MatIconModule,
        MatButtonModule,
        NgbPopoverModule
    ],
    exports: [
        OnboardingHelpTabComponent,
        ChecklistTabComponent,
        ChecklistPopoverComponent
    ],
    providers: [OnboardingService]
})
export class OnboardingUiModule { }
