import { Directive, HostListener, Input } from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { Subscription, fromEvent, takeUntil } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Directive({
    selector: '[mat-dialog-draggable-title]'
})
export class DialogDraggableTitleDirective {

    private _subscription: Subscription;

    mouseStart: Position;

    mouseDelta: Position;

    offset: Position;

    @Input('isDraggable')
    isDraggable: boolean;

    @Input('cacheKey')
    cacheKey: boolean;

    private _hasMoved: boolean = false;

    constructor(
        private matDialogRef: MatDialogRef<any>,
        private container: MatDialogContainer,
        private ls: LocalStorageService
    ) {
        this.matDialogRef.beforeClosed().subscribe(() => {
            this.savePosition();
        });
    }

    ngOnInit() {
        if (!this.isDraggable) {
            return;
        }

        setTimeout(() => {
            this.loadPosition();
        })
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (!this.isDraggable) {
            return;
        }

        this._hasMoved = false;

        if (this.offset == undefined) {
            this.offset = this._getOffset();
        }

        this.mouseStart = { x: event.pageX, y: event.pageY };

        const mouseup$ = fromEvent(document, 'mouseup');
        this._subscription = mouseup$.subscribe(() => this.onMouseup());

        const mousemove$ = fromEvent(document, 'mousemove')
            .pipe(takeUntil(mouseup$))
            .subscribe((e: MouseEvent) => this.onMouseMove(e));

        this._subscription.add(mousemove$);
    }

    onMouseMove(event: MouseEvent) {
        this._hasMoved = true;
        this.mouseDelta = { x: (event.pageX - this.mouseStart.x), y: (event.pageY - this.mouseStart.y) };

        this._updatePosition(this.offset.y + this.mouseDelta.y, this.offset.x + this.mouseDelta.x);
    }

    onMouseup() {
        if (!this._hasMoved) {
            return;
        }


        if (this.mouseDelta.y == 0) {
            return;
        }

        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = undefined;
        }

        if (this.mouseDelta) {
            this.offset.x += this.mouseDelta.x;
            this.offset.y += this.mouseDelta.y;
        }

        console.log("## New Pos: " + this.offset.x + ", " + this.offset.y);
    }

    private _updatePosition(top: number, left: number) {
        this.matDialogRef.updatePosition({
            top: top + 'px',
            left: left + 'px'
        });
    }

    private _getOffset(): Position {
        const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
        return {
            x: box.left + pageXOffset,
            y: box.top + pageYOffset
        };
    }

    private loadPosition() {
        if (!this.cacheKey) {
            return;
        }

        let x: number = this.ls.get(this.cacheKey + "/x");
        let y: number = this.ls.get(this.cacheKey + "/y");

        const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
        const offset = this.getOffsetToMakeVisible(box, {x, y});

        if( (offset.left != 0) || (offset.top != 0) ) {
            x += offset.left;
            y += offset.top;
        }
        

        if (x != undefined) {
            this._updatePosition(y, x);
        }
    }

    private savePosition() {
        if (!this.cacheKey) {
            return;
        }

        if (this.cacheKey) {
            this.ls.set(this.cacheKey + "/x", this.offset.x);
            this.ls.set(this.cacheKey + "/y", this.offset.y);

            // console.log("## Save Pos: " + this.offset.x + ", " + this.offset.y);
        }
    }

    private getOffsetToMakeVisible(rect: DOMRect, pos: Position): {top: number, left: number} {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        let offsetX = 0;
        let offsetY = 0;

        let width = rect.width;
        let height = rect.height;

        let top = pos.y;
        let bottom = top + height;
        let left = pos.x;
        let right = pos.x + width;

        if (top < 0) {
            offsetY = -top;
        } else if (bottom > windowHeight) {
            offsetY = windowHeight - bottom;
        }

        if (left < 0) {
            offsetX = -left;
        } else if (right > windowWidth) {
            offsetX = windowWidth - right;
        }

        return {
            top: offsetY,
            left: offsetX
        };
    }
}


export interface Position {
    x: number;
    y: number;
}