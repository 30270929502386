import { Component } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-language-button',
    templateUrl: './language-button.component.html',
    styleUrl: './language-button.component.scss'
})
export class LanguageButtonComponent {

    constructor(readonly languageService: LanguageService) {

    }

    onLanguageClick(langCode: string) {
        this.languageService.changeLanguage(langCode);
    }
}
