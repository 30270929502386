import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganizationRole } from '@applogic/model';
import { OrganizationService } from 'src/app/organization/organization.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface ChangeMemberRoleDialogData {
    orgId: string,
    userId?: string,
    inviteId?: string,
    currentRole: OrganizationRole,
    isInviteRole?: boolean,
    isAdmin?: boolean
}

@Component({
    selector: 'app-change-member-role-dialog',
    templateUrl: './change-member-role-dialog.component.html',
    styleUrl: './change-member-role-dialog.component.scss'
})
export class ChangeMemberRoleDialogComponent {
    ApplogicUtils = ApplogicUtils;

    OrganizationRole = OrganizationRole;

    OrganizationRoles = Object.values(OrganizationRole);

    errorObject: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ChangeMemberRoleDialogData,
        private mdDialogRef: MatDialogRef<ChangeMemberRoleDialogComponent, OrganizationRole>,
        private orgService: OrganizationService
    ) {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: ChangeMemberRoleDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<ChangeMemberRoleDialogComponent, ChangeMemberRoleDialogData, OrganizationRole>(dialogService, dialog, ChangeMemberRoleDialogComponent, {
            data,
            maxWidth: "600px",
        }, config);
    }

    changeRole(role: OrganizationRole) {
        this.errorObject = undefined;

        if (this.data.isInviteRole) {
            this.orgService.changeInviteRole(role, this.data.orgId, this.data.inviteId).subscribe({
                next: (res) => {
                    this.mdDialogRef.close(role);
                },
                error: (ex) => {
                    this.errorObject = ex;
                }
            });
        }
        else {
            this.orgService.changeMemberRole(role, this.data.orgId, this.data.userId).subscribe({
                next: (res: any) => {
                    if (res && res[0] == 'error') {
                        location.reload();
                    }
                    else {
                        this.mdDialogRef.close(role);
                    }
                },
                error: (ex) => {
                    this.errorObject = ex;
                }
            });
        }
    }
}
