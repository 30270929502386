<div class="controls-container">
    <div class="palette-line">
        <mat-select [(ngModel)]="currentPreset"
                    (selectionChange)="onPresetChanged($event)">
            <mat-option [value]="preset"
                        *ngFor="let preset of presets">{{preset}}</mat-option>
        </mat-select>
        <button mat-icon-button
                color="accent"
                (click)="onCopyClick()">
            <mat-icon svgIcon="content-copy"></mat-icon>
        </button>
    </div>
    <div *ngFor="let pal of palettes"
         class="palette-line">
        <app-dynamic-material-color-selector [palette]="pal"></app-dynamic-material-color-selector>
        <span>{{pal}}</span>
    </div>
</div>