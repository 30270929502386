<div [formGroup]="formGroup" class="flex-column flex-default-gap">

    <span i18n="@@common-communication-settings"
          *ngIf="showTitle"> Communication Settings </span>

    <div>
        <mat-slide-toggle *ngIf="!useCheckbox"
                          formControlName="opt"
                          (change)="onFormChanged()">
            <span i18n="@@email-preferences-optin">Receive emails</span>
        </mat-slide-toggle>
        <mat-checkbox *ngIf="useCheckbox"
                      formControlName="opt"
                      (change)="onFormChanged()">
            <span i18n="@@email-preferences-optin">Receive emails</span>
        </mat-checkbox>
    </div>

    <div>
        <mat-slide-toggle *ngIf="!useCheckbox"
                          class="user-email-prefs-checkbox"
                          formControlName="newsletters"
                          (change)="onFormChanged()">
            <span i18n="@@email-preferences-newsletters">Newsletters</span>
        </mat-slide-toggle>
        <mat-checkbox *ngIf="useCheckbox"
                      class="user-email-prefs-checkbox"
                      (change)="onFormChanged()"
                      [class.mat-checkbox-disabled]="!formGroup.get('opt').value"
                      formControlName="newsletters">
            <span i18n="@@email-preferences-newsletters">Newsletters</span>
        </mat-checkbox>
    </div>

    <div>
        <mat-slide-toggle *ngIf="!useCheckbox"
                          class="user-email-prefs-checkbox"
                          formControlName="gettingStarted"
                          (change)="onFormChanged()">
            <span i18n="@@email-preferences-gettingstarted">Getting Started</span>
        </mat-slide-toggle>
        <mat-checkbox *ngIf="useCheckbox"
                      class="user-email-prefs-checkbox"
                      (change)="onFormChanged()"
                      [class.mat-checkbox-disabled]="!formGroup.get('opt').value"
                      formControlName="gettingStarted">
            <span i18n="@@email-preferences-gettingstarted">Getting Started</span>
        </mat-checkbox>
    </div>
    <br />

</div>