<app-page i18n-title="@@common-help"
          title="Help">
    <app-page-content class="overview-page-container">

        <div class="overview-section-links">
            <div *ngIf="!videos.length"
                 class="card card-shadow remove-box-shadow">
                <p i18n="@@common-coming-soon">Coming soon</p>
            </div>

            <div *ngFor="let video of videos"
                 class="card card-shadow remove-box-shadow"
                 (click)="showVideo(video.id)">
                <p>
                    <img src="https://i.ytimg.com/vi/{{video.id}}/mqdefault.jpg" />
                </p>
                <div>
                    <h1>{{video.label}}</h1>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </app-page-content>
</app-page>


<ng-template #showVideoDialog
             let-data>

    <mat-dialog-content>
        <div class="videoWrapper">
            <!--    <youtube-player [width]="400" [height]="400"  videoId="data && data.id"></youtube-player> -->
            <iframe [src]="data.url"
                    frameborder="0"
                    allow="autoplay"
                    allowfullscreen></iframe>

            <div class="closebuttonSection">
                <button class="closebutton"
                        mat-icon-button
                        color="black"
                        (click)="closeVideo()">
                    <mat-icon>cancel</mat-icon>
                </button>

            </div>



        </div>
    </mat-dialog-content>


</ng-template>