import { Model, ID, Member, ArrayMember } from "@uon/model";

import { TranslatedValue } from '../common/translated.model';


@Model()
export class KnowledgebaseCategory {


    @ID()
    id: string;

    @Member()
    slug: string;

    @Member()
    title: string;

    @Member()
    icon: string;

    @Member()
    description: string;

    @ArrayMember(TranslatedValue)
    slugTranslations: TranslatedValue<string>[];

    @ArrayMember(TranslatedValue)
    titleTranslations: TranslatedValue<string>[];

    @ArrayMember(TranslatedValue)
    descriptionTranslations: TranslatedValue<string>[];


}


@Model()
export class KnowledgebaseArticle {

    @ID()
    id: string;

    @Member()
    categoryId: string;

    @Member()
    parentId: string;

    @Member()
    slug: string;

    @Member()
    title: string;

    @Member()
    content: string;

    @Member()
    order: number;

    @Member()
    icon: string;

    @ArrayMember(TranslatedValue)
    slugTranslations: TranslatedValue<string>[];

    @ArrayMember(TranslatedValue)
    titleTranslations: TranslatedValue<string>[];

    @ArrayMember(TranslatedValue)
    contentTranslation: TranslatedValue<string>[];


    @Member()
    updatedOn: Date;

    


}