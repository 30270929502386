import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Currency, PaymentMethod, Subscription } from '@applogic/model';
import { AuthService } from 'src/app/auth/auth.service';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { StripeUtils } from 'src/app/services/stripe-utils';
import { OrderService } from '../../order/order.service';
import { StripePaymentPanelComponent } from '../stripe-payment-panel/stripe-payment-panel.component';
import { Subscription as RxSubscription } from 'rxjs';

@Component({
    selector: 'app-payment-method-select-panel',
    templateUrl: './payment-method-select-panel.component.html',
    styleUrls: ['./payment-method-select-panel.component.scss']
})
export class PaymentMethodSelectPanelComponent implements OnInit {

    private _paymentTarget: Subscription | Currency;

    @Input()
    set paymentTarget(val: Subscription | Currency) {
        if (this._paymentTarget != val) {
            this._paymentTarget = val;

            setTimeout(() => {
                this.updatePaymentMethods();
            });
        }
    }

    get paymentTarget() {
        return this._paymentTarget;
    }

    @Input()
    public currency: Currency;

    _paymentMethodId: string;

    @Input()
    set paymentMethodId(val: string) {
        if (this._paymentMethodId != val) {
            this._paymentMethodId = val;
            this.paymentMethodIdChange.emit(val);
        }
    }

    get paymentMethodId() {
        return this._paymentMethodId;
    }

    @Output()
    paymentMethodIdChange: EventEmitter<string> = new EventEmitter<string>();

    public clientSecret: string;
    errorObject: any;


    currentMethodId: string;

    paymentMethods: PaymentMethod[] = [];

    @ViewChild(StripePaymentPanelComponent)
    paymentPanel: StripePaymentPanelComponent;

    resultPaymentMethod: string;

    private _userChangeSubscription: RxSubscription;

    constructor(
        readonly orderService: OrderService,
        readonly loading: LoadingService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this._userChangeSubscription = this.authService.onUserChange.subscribe((user) => {
            if(user) {
                this.updatePaymentMethods();
            }
        });
        if (this.paymentTarget) {
            // To avoid the NG0100 error.
            setTimeout(() => {
                this.updatePaymentMethods();
            });
        }
    }

    ngOnDestroy() {
        if(this._userChangeSubscription) {
            this._userChangeSubscription.unsubscribe();
        }
    }

    setSelectedPayementMethodId(id: string) {
        return new Promise<boolean>((resolve, rejects) => {
            if (id != this.paymentMethodId) {

                this.paymentMethodId = id;

                if (id == "NEW") {
                    this.createPaymentIntent().then((succeed) => {
                        resolve(succeed);
                    }).catch((err) => {
                        this.errorObject = StripeUtils.filterAndLocalizeStripeError(err);
                        this.loading.stopLoading();
                        rejects(err);
                    });
                }

            }
            resolve(true);
        })
    }

    createPaymentIntent() {
        this.errorObject = undefined;
        this.loading.startLoading();
        return new Promise<boolean>((resolve, rejects) => {
            this.orderService.initAddPaymentMethod(this.paymentTarget).subscribe((response) => {
                this.loading.stopLoading();
                this.clientSecret = response.secret;
                resolve(true);
            }, (err) => {
                resolve(false);
                this.loading.stopLoading();
                this.errorObject = err;
            });
        });
    }

    onPaymentMethodChanged(event: any) {
        const paymentMethodId: string = event.value;

        if (paymentMethodId) {
            this.setSelectedPayementMethodId(paymentMethodId);
        }
    }

    selectOrCreate(subscription: Subscription, stripeSubscriptionId: string) {
        this.errorObject = undefined;
        this.loading.startLoading();
        return new Promise<boolean>((resolve, rejects) => {
            if (this.paymentMethodId == 'NEW') {
                this.paymentPanel.confirmSetup().then((paymentMethodId) => {

                    if (paymentMethodId) {
                        if (subscription || stripeSubscriptionId) {
                            const obs = stripeSubscriptionId ? this.orderService.setPaymentMethod(paymentMethodId, stripeSubscriptionId) : this.orderService.setPaymentMethodForSubscription(paymentMethodId, subscription.id);

                            obs.subscribe(() => {
                                this.resultPaymentMethod = this.paymentMethodId;
                                this.loading.stopLoading();
                                resolve(true);
                            }, (err) => {
                                // Update the payment methods list to select the new created payment method.
                                this.updatePaymentMethods(paymentMethodId);
                                this.loading.stopLoading();
                                resolve(false);
                                this.errorObject = StripeUtils.filterAndLocalizeStripeError(err);
                            });
                        }
                        else {
                            this.resultPaymentMethod = paymentMethodId;
                            this.loading.stopLoading();
                            resolve(true);
                        }
                    }
                    else {
                        this.loading.stopLoading();
                        resolve(false);
                    }
                }, (err) => {
                    this.errorObject = StripeUtils.filterAndLocalizeStripeError(err);
                    resolve(false);
                    this.loading.stopLoading();
                });
            }
            else {
                if (subscription || stripeSubscriptionId) {
                    const obs = stripeSubscriptionId ? this.orderService.setPaymentMethod(this.paymentMethodId, stripeSubscriptionId) : this.orderService.setPaymentMethodForSubscription(this.paymentMethodId, subscription.id);

                    obs.subscribe(() => {
                        this.resultPaymentMethod = this.paymentMethodId;
                        this.loading.stopLoading();
                        resolve(true);
                    }, (err) => {
                        this.loading.stopLoading();
                        resolve(false);
                        this.errorObject = StripeUtils.filterAndLocalizeStripeError(err);
                    });
                }
                else {
                    this.resultPaymentMethod = this.paymentMethodId;
                    this.loading.stopLoading();
                    resolve(true);
                }
            }
        });
    }

    public updatePaymentMethods(defaultPaymentMethodId?: string) {
        this.errorObject = undefined;
        if(!this.authService.isLoggedIn()) {
            return;
        }

        this.loading.startLoading();

        this.orderService.getPaymentMethods(this.paymentTarget).subscribe((response) => {
            this.paymentMethods = response.paymentMethods;

            const startingPaymentMethods: string[] = [];

            if(defaultPaymentMethodId) startingPaymentMethods.push(defaultPaymentMethodId);
            if(response.defaultPayementMethod) startingPaymentMethods.push(response.defaultPayementMethod);
            if (this.paymentMethods.length > 0) {
                startingPaymentMethods.push(this.paymentMethods[0].id);
            }

            this.currentMethodId = response.defaultPayementMethod;

            let selectedPaymentMethodId: string;

            for(let i=0; i<startingPaymentMethods.length; i++) {
                const selectedPaymentMethod = this.paymentMethods.find(a => a.id == startingPaymentMethods[i])
                if (selectedPaymentMethod) {
                    selectedPaymentMethodId = selectedPaymentMethod.id;
                    break;
                }
            }
            
            if (!selectedPaymentMethodId) {
                selectedPaymentMethodId = "NEW";
            }

            this.setSelectedPayementMethodId(selectedPaymentMethodId).then((succeed) => {
                this.loading.stopLoading();
            }, (err) => {
                this.errorObject = err;
                this.loading.stopLoading();
            });
        }, (err) => {
            this.loading.stopLoading();
            this.errorObject = err;
        });
    }
}
