<div class="error-message-area-container"
     [ngClass]="{'no-padding': noPadding}"
     *ngIf="_error">
    <div class="error-container error-box"
         [ngClass]="{'error-container': messageType == 'error', 'error-box': true, 'warning-container': messageType == 'warning', 'no-margin': noPadding}">
        <div *ngIf="!isAdmin && !userMessage && errorEventId"
             class="error-message"
             i18n="@@common-unknown-error-message-with-event-id">
            An error occured. Please try again or contact the support at <a href="mailto:info@appligogiques.com">info&#64;appligogiques.com</a> and mention the error
            event
            id {{errorEventId}}
        </div>

        <div *ngIf="!isAdmin && !userMessage && !errorEventId"
             class="error-message"
             i18n="@@common-unknown-error-message">
            An error occured. Please try again or contact the support at <a href="mailto:info@appligogiques.com">info&#64;appligogiques.com</a>.
        </div>

        <div *ngIf="userMessage"
             class="error-message"
             [innerHtml]="userMessage">
        </div>

        <div *ngIf="isAdmin && errorMessage"
             class="error-message">
            The following error details can only be seen because you are in the admin section:<br />
            {{ errorMessage }}
            <p *ngIf="errorSubMessage">{{ errorSubMessage }}</p>
        </div>

        <div *ngIf="!isAdmin && !isProd && errorMessage"
             class="error-message-debug secondary-text">
            The following error can only be seen from a non production server:<br />
            {{ errorMessage }}
            <p *ngIf="errorSubMessage">{{ errorSubMessage }}</p>
        </div>
    </div>
</div>