import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Product, SubAllocationsResponse, Subscription, SubscriptionAllocation, UserAllocationsResponse } from '@applogic/model';
import { Subscription as RxSubscription } from 'rxjs';
import { CommonColumnHeaderType, CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProductService } from 'src/app/services/product.service';
import { SeatService } from 'src/app/services/seat.service';


interface ListItem {
    sub: Subscription;
    subAlloc: SubAllocationsResponse;
    alloc: SubscriptionAllocation;
}

@Component({
    selector: 'app-user-seats-allocation-panel',
    templateUrl: './user-seats-allocation-panel.component.html',
    styleUrls: ['./user-seats-allocation-panel.component.scss']
})
export class UserSeatsAllocationPanelComponent extends CommonListComponent<ListItem> implements OnInit, OnDestroy {

    @Input()
    userId: string;

    @Input()
    orgId: string;

    public userAllocs: UserAllocationsResponse;

    userAllocsObservable: RxSubscription;

    totalTokens: number;

    protected _isVisible: boolean = true;
    
    @Input()
    set isVisible(val: boolean) {
        if (this._isVisible != val) {
            this.isVisibleChange.emit(val);
            this._isVisible = val;
        }
    }

    get isVisible() {
        return this._isVisible;
    }

    @Output()
    isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    protected _products: Product[] = [];

    constructor(injector: Injector, readonly elementRef: ElementRef<HTMLElement>,
        private localStorageService: LocalStorageService,
        private seatService: SeatService,
        private errorDialogService: HandleErrorDialogService,
        private productService: ProductService) {
        super(injector);
        this.doInitialRefresh = false;
        this.productService.getProducts().then((products) => {
            this._products = products;
            this.initColumns();
        });

        this.rendererOptions.autoHidePagination = true;
    }

    ngOnInit(): void {
        this.initColumns();

        this.userAllocsObservable = this.seatService.userAllocationsRetrieved.subscribe((o) => {
            if( (o.userId == this.userId) && (o.orgId == this.orgId) ) {
                this.userAllocs = o.allocations;
                this.isVisible = o.allocations.subscriptions.length > 0;

                let totalTokens: number = 0;
                for(const sub of o.allocations.subscriptions) {          
                    for (const key of Object.keys(sub.totalSeats)) {
                        const seats = sub.freeSeats[key];
                        if (!isNaN(seats)) {
                            totalTokens += seats;
                        }
                    }
                }
                this.totalTokens = totalTokens;

                this.refreshItems();
            }
        });

        this.seatService.getUserAllocations(this.userId, this.orgId).subscribe();
    }

    ngOnDestroy(): void {
        if(this.userAllocsObservable) {
            this.userAllocsObservable.unsubscribe();
            this.userAllocsObservable = undefined;
        }
    }

    initColumns() {
        this.initPersistentKey('applogic/seat/user-seats-allocation');

        this.addColumn($localize`:@@common-number:No`, "sub.subNo", true, true, CommonColumnType.NO, {
            
        });

        this.addColumn("", "subAlloc", true, true, CommonColumnType.Template, {
            
        });

        for (const product of this._products) {
            const columnKey =  product.shortCode;
            this.addColumn(product.shortCode, columnKey, true, true, CommonColumnType.Custom, {
                isSortable: false,
                fixedWidth: "45px",
                cellStyles: {
                    'text-align': "center"
                },
                columnHeaderType: CommonColumnHeaderType.Template
            }).parameters = {
                productCode: product.shortCode.toLowerCase()
            };
        }

        // this.addColumn("", "actions", true, true, CommonColumnType.Action);
        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        const allocations: ListItem[] = [];

        for(const subAlloc of this.userAllocs.subscriptions) {
            for(const alloc of subAlloc.allocations) {
                allocations.push({
                    sub: subAlloc.subscription,
                    subAlloc,
                    alloc
                });
            }
        }

        this.setPreloadItems(allocations);
    }

    getCustomLabel(key: string, val: any, row: ListItem) {

        const product = this._products.find(p => p.shortCode == key);

        if(product) {
            return row.alloc.freeSeats[product.shortCode.toLowerCase()]?.toString();
        }
        
        return super.getCustomLabel(key, val, row);
    }
}
