import { Injectable, Output, EventEmitter } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { Classroom, Student, Organization, StudentGameData, ClassroomStudentInvite, User, SearchList, ClassroomResponse, ApiRoutes, RoutesServer, ClassroomStudentsImportResponse, CommonQueryResponse, CommonQueryFilter, CommonCountResponse } from "@applogic/model";
import { JsonSerializer, Model } from "@uon/model";
import { map, catchError } from "rxjs/operators";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HandleErrorDialogService } from '../services/handle-error-dialog.service';
import { AnalyticsService } from "../services/analytics.service";
import { ApiDirectoryService } from "../services/api-directory.service";
import { LanguageService } from "../services/language.service";
const GAMEDATA_SERIALIZER = new JsonSerializer(StudentGameData);
const STUDENT_SERIALIZER = new JsonSerializer(Student);
const USER_SERIALIZER = new JsonSerializer(User);
const SERIALIZER = new JsonSerializer(Classroom);
const CLASSROOM_RESPONSE_SERIALIZER = new JsonSerializer(ClassroomResponse);
const EQOLLIST_SERIALIZER = new JsonSerializer(SearchList);
const INVITATION_SERIALIZER = new JsonSerializer(ClassroomStudentInvite);
const CLASSROOM_STUDENTS_IMPORT_RESPONSE_SERIALIZE = new JsonSerializer(ClassroomStudentsImportResponse);

@Injectable({
    providedIn: "root",
})
export class ClassroomService {
    @Output() isRefreshValue: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private errorDialogService: HandleErrorDialogService,
        private analyticsService: AnalyticsService,
        private dirService: ApiDirectoryService,
        private languageService: LanguageService) {

    }

    listClassrooms(orgId = ""): Observable<Classroom[]> {
        const ENDPOINT_PARAMS = orgId ? "?orgId=" + orgId : "";
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/classrooms` + ENDPOINT_PARAMS, { withCredentials: true }).pipe(
            map((r: any[]) => r.map((v) => SERIALIZER.deserialize(v, true))),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    countClassrooms(filter: CommonQueryFilter, orgId?: string) {
        if(orgId) {
            filter.other.orgId = orgId;
        }
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `${str}/count`, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonCountResponse();
                response.count = r.count;
                return response;
            })
        );
    }

    searchClassrooms(filter: CommonQueryFilter, orgId?: string) {
        if(orgId) {
            filter.other.orgId = orgId;
        }
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `${str}`, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<Classroom>();
                response.count = r.count;
                response.result = r.result.map(s => SERIALIZER.deserialize(s, true));
                return response;
            })
        );
    }

    getClassroom(id: string): Observable<ClassroomResponse> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `/${id}`, { withCredentials: true })
            .pipe(map((r: any) => CLASSROOM_RESPONSE_SERIALIZER.deserialize(r, true)),
                catchError(err => {
                    const STATUS_CODE = err?.error?.code;
                    if (STATUS_CODE === 403 || STATUS_CODE === 404) {
                        let message: string = "";
                        if (STATUS_CODE === 403) {
                            message = $localize`:@@classroom-details-access-error-message:You don't have access to this classroom`;
                        } else if (STATUS_CODE === 404) {
                            message = $localize`:@@classroom-details-delete-error-message:This classroom no longer exists.`;
                        }
                        this.errorDialogService.openErrorDialog(message, err, true);
                    }

                    return throwError(err)
                }
                ));
    }

    getClassroomCollaborators(id: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/collaborators`, { withCredentials: true })
            .pipe(
                map((r: any[]) => r.map((u) => USER_SERIALIZER.deserialize(u, true)))
            );
    }
    getClassroomSentInviteCollaborators(id: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/sentCollaboratorInvitations`, {
                withCredentials: true,
            })
            .pipe(
                map((r: any[]) => r.map((u) => USER_SERIALIZER.deserialize(u, true)))
            );
    }

    updateClassroomCollaborators(id: string, add: User[], remove: User[]) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/collaborators`,
            { add: add.map((u) => u.id), remove: remove.map((u) => u.id) },
            { withCredentials: true }
        );
    }
    sendCollaboratorsInvite(id: string, emails: string[]) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/inviteCollaborator`,
            { emails },
            { withCredentials: true }
        );
    }

    resendCollaboratorsInvite(id: string, inviteId: string) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/inviteCollaborator/${inviteId}`,
            {},
            { withCredentials: true }
        );
    }
    removeCollaboratorsInvite(id: string, inviteId: string) {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/inviteCollaborator/${inviteId}`,
            { withCredentials: true }
        );
    }

    removeCollaborator(id: string, userId: string) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/removeCollaborator/${userId}`,
            {},
            { withCredentials: true }
        );
    }
    changeOwnerCollaborator(id: string, userId: string) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Classroom, `/${id}/changeClassroomOwner/${userId}`,
            {},
            { withCredentials: true }
        );
    }

    createClassroom(org: Organization, classroom: Classroom) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Classroom, `/${org.id}`, classroom, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    this.analyticsService.sendEvent("classroom_create", {
                        'classroom_name': classroom.name
                    });
                    return SERIALIZER.deserialize(r, true)
                }),
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    createStudentInvitation(classroom: Classroom, note: string = "") {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/student-invite`,
                { note },
                { withCredentials: true }
            )
            .pipe(
                map((r: any) => {
                    const inv = INVITATION_SERIALIZER.deserialize(r, true);
                    classroom.studentInvitation = inv;
                    return inv;
                }),
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    deleteClassroom(classroom: Classroom) {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}`, {
            withCredentials: true,
        });
    }

    createStudent(classroom: Classroom, student: Student) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/student`, student, {
                withCredentials: true,
            })
            .pipe(
                map((r: any) => {
                    r.isSelected = false;
                    const stu = STUDENT_SERIALIZER.deserialize(r, true);
                    classroom.students.unshift(stu);
                    return stu;
                }), catchError((err) => {
                    err.userMessage = this.localizeErrorMessage(err.error.message);
                    return throwError(err);
                }));
    }

    patchStudent(classroom: Classroom, student: Student) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/student`, student, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .pipe(
                map((r: any) => {
                    const stu = classroom.students.find((s) => s.id === r.id);
                    Model.Assign(stu, r);
                    return stu;
                }), catchError((err) => {
                    err.userMessage = this.localizeErrorMessage(err.error.message);
                    return throwError(err);
                }));
    }

    deleteStudents(classroom: Classroom, studentIds: string[]) {
        const data = {
            "studentIds": studentIds
        }
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/students`, data, { withCredentials: true });
    }

    getStudentGamedata(classroom: Classroom, student: Student) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/student/${student.id}`, {
                withCredentials: true,
            })
            .pipe(
                map((r: any[]) =>
                    r.map((v) => GAMEDATA_SERIALIZER.deserialize(v, true))
                ),
                catchError((err) => {
                    return of(<StudentGameData[]>[]);
                })
            );
    }

    /** Sorting Data Ascending order*/
    sortingData(gameList: any) {
        const gameListData = gameList.sort(function (a, b) {
            var x = a.pos < b.pos ? -1 : 1;
            return x;
        });
        return gameListData;
    }



    localizeErrorMessage(errorMessage: any) {
        if (errorMessage !== '' && errorMessage === 'Profile name already taken') {
            return $localize`:@@form-field-username-already-exists:Profile name already taken`;
        } else if (errorMessage !== 'Profile name already taken') {
            return $localize`:@@form-field-internal-error:Internal error. Please try again. If the problem persists, please contact us via email <a href="mailto:info@appligogiques.com">info@appligogiques.com</a>.`;
        } else {
            return errorMessage;
        }
    }

    getEqolLists(classroom: Classroom): Observable<SearchList[]> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/eqolLists`, { withCredentials: true }).pipe(
            map((r: any[]) => r.map((v) => EQOLLIST_SERIALIZER.deserialize(v, true))),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    exportStudentsList(classroomId: string, bookType: string) {
        this.dirService.observeRoute(RoutesServer.Api, ApiRoutes.Classroom, `/${classroomId}/students/export?bookType=${bookType}&lang=${this.languageService.currentLanguageCode}`).subscribe((routeUrl) => {
            window.open(routeUrl);
        });
    }

    importStudents(classroom: Classroom, students: Student[]) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Classroom, `/${classroom.id}/students/import`, {students}, {
            withCredentials: true,
        })
        .pipe(
            map((r: any) => {
                r.isSelected = false;
                const stu = CLASSROOM_STUDENTS_IMPORT_RESPONSE_SERIALIZE.deserialize(r, true);
                for(const s of stu.students) {
                    classroom.students.unshift(s);
                }
                return stu;
            }), catchError((err) => {
                err.userMessage = this.localizeErrorMessage(err.error.message);
                return throwError(err);
            }));
    }

    
    transferStudents(students_id_list: string[], sourceClassroomId, destClassroomId: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Classroom, `/${sourceClassroomId}/transfer-students/${destClassroomId}`,
            { students: students_id_list },
            { withCredentials: true }
        );
    }

}

@Injectable({
    providedIn: "root",
})
export class ClassroomListResolverService  {
    constructor(private classrooms: ClassroomService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Classroom[]> {
        return this.classrooms.listClassrooms();
    }
}

@Injectable({
    providedIn: "root",
})
export class ClassroomResolverService  {
    constructor(private classrooms: ClassroomService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<ClassroomResponse> {
        const id = route.params.id;
        return this.classrooms.getClassroom(id);
    }
}
