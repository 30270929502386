import { Component, Input } from '@angular/core';
import { CommonListRendererComponent } from '../../common-list-renderer/common-list-renderer.component';

@Component({
    selector: 'app-common-list-show-columns-button',
    templateUrl: './common-list-show-columns-button.component.html',
    styleUrl: './common-list-show-columns-button.component.scss'
})
export class CommonListShowColumnsButtonComponent {

    @Input()
    renderer: CommonListRendererComponent;
}
