import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonQueryFilter, SubscriptionInvoice, SubscriptionInvoiceState, SubscriptionType } from '@applogic/model';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SubscriptionInvoiceEditDialogComponent } from '../subscription-invoice-edit-dialog/subscription-invoice-edit-dialog.component';
import { Router } from '@angular/router';
import { PdfViewerDialogComponent } from 'src/app/core/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { AngularUtils } from 'src/app/services/angular-utils';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { ChecklistPopoverComponent } from 'src/app/onboarding-ui/checklist-popover/checklist-popover.component';
import { ProductService } from 'src/app/services/product.service';
import { Subscription } from 'rxjs';
import { ApplogicUtils } from 'src/app/services/applogic-utils';

@Component({
    selector: 'app-subscription-invoice-list',
    templateUrl: './subscription-invoice-list.component.html',
    styleUrls: ['./subscription-invoice-list.component.scss']
})
export class SubscriptionInvoiceListComponent extends CommonListComponent<SubscriptionInvoice> implements OnInit, OnDestroy {
    ApplogicUtils = ApplogicUtils;
    SubscriptionInvoiceState = SubscriptionInvoiceState;

    public InvoiceState = SubscriptionInvoiceState;

    protected _subscriptionType: SubscriptionType;

	@Input()
    set subscriptionType(val: SubscriptionType) {
        if(val != this._subscriptionType) {
            this._subscriptionType = val;
            this.refreshItems();
            this.subscriptionTypeChange.emit(val);
        }
    }

    get subscriptionType() {
      return this._subscriptionType;
    }

    @Output()
    subscriptionTypeChange: EventEmitter<SubscriptionType> = new EventEmitter<SubscriptionType>();

    invoiceIdForOnboarding: string;
    
    onCreateInvoiceSubscription: Subscription;
    
    constructor(
        private injector: Injector,
        private router: Router,
        private subscriptionService: SubscriptionService,
        private productService: ProductService
    ) {
        super(injector);

        // Set default values
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 25, 50];
        this.rendererOptions.showSelectColumnsActionHeader = false;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.onCreateInvoiceSubscription = this.subscriptionService.onCreateInvoice.subscribe((invoice) => {
            this.refreshItems();
        });
        
        this.productService.getCurrencies().subscribe((response) => {
            this.initColumns();
            this.refreshItems();
        });
    }

    ngOnDestroy() {
        if (this.onCreateInvoiceSubscription) {
            this.onCreateInvoiceSubscription.unsubscribe();
            this.onCreateInvoiceSubscription = undefined;
        }
    }

    initColumns() {
        this.initPersistentKey('applogic/account/invoices/v2-')

        this.addColumn($localize`:@@invoice-table-invoiceNo-label: Invoice no. `, "invoiceNo", true, true, CommonColumnType.NO);

        this.addColumn($localize`:@@quotation-table-quoteNo-label: Quote no. `, "quotation.quotationNo", true, false);

        this.addColumn($localize`:@@quotation-table-amount: Amount `, "priceTable.total", true, true, CommonColumnType.Currency).parameters.currencyKeys = "priceTable.currency";

        this.addColumn($localize`:@@common-organization:Organization`, "organization.name", true, true, CommonColumnType.Text);
        this.addColumn($localize`:@@common-created-by: Created by `, "user.fullName", true, false, CommonColumnType.Text);

        this.addColumn($localize`:@@quotation-table-date: Date `, "createdOn", true, true, CommonColumnType.Date, {
            fixedWidth: "110px",
            data: {
                format: "mediumDate"
            }
        });

        this.addColumn($localize`:@@invoice-table-state-heading:State`, "state", true, true, CommonColumnType.Template, {
            fixedWidth: "55px"
        });

        this.addColumn($localize`:@@common-manager-table-update-date-heading:Update Date`, "updatedOn", true, false, CommonColumnType.Date, {
            fixedWidth: "110px",
            data: {
                format: "mediumDate"
            }
        });

        this.addColumn("", "actions", false, true, CommonColumnType.Action);
        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;

        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });
        
        queryFilter.other = {
            all: false,
            details: true
        };

        if (this.subscriptionType) {
            queryFilter.other.subscriptionType = this.subscriptionType;
        }

        this.subscriptionService.getInvoices(queryFilter).subscribe((res) => {

            for (let i = 0; i < res.result.length; i++) {
                if (res.result[i].state == SubscriptionInvoiceState.Due) {
                    this.invoiceIdForOnboarding = res.result[i].id;
                    break;
                }
            }

            this.setResponse(res);

        }, (err) => {
            this.errorObject = err;
            console.error(" Error ", err.error.message);
        });
    }

    onShowInvoice(invoice: SubscriptionInvoice) {
        this.subscriptionService.getSubscriptionInvoicePdf(invoice.invoiceNo).then((res: any) => {
            const title = $localize`:@@common-subscription-invoice-no:Invoice ${invoice.invoiceNo}`;
            PdfViewerDialogComponent.createDialog(this.dialogService, this.dialog, {
                title,
                pdfUrl: res.pdfurl
            }).show();
        }).catch((error) => {
            console.error(error);
        });
    }

    onDownloadInvoice(invoice: SubscriptionInvoice) {
        this.subscriptionService.getSubscriptionInvoicePdf(invoice.invoiceNo).then((res: any) => {
            AngularUtils.downloadBase64File(res.pdfurl, invoice.invoiceNo + ".pdf", 'application/pdf');
        }).catch((error) => {
            console.error(error);
        });
    }

    onPayInvoiceClick(invoice: SubscriptionInvoice) {
        if(invoice.stripeInvoiceId) {
            this.subscriptionService.getStripeHostedUrl(invoice).subscribe((url) => {
                window.open(url, '_stripe');
            });
        } else {
            this.router.navigate(['/order/organization'], {
                queryParams: {
                    invoice_id: invoice.id
                }
            });
        }
    }

    onModifyInvoiceClick(invoice: SubscriptionInvoice) {
        SubscriptionInvoiceEditDialogComponent.createDialog(this.dialogService, this.dialog, {invoice}).show().then((invoice) => {

        });
    }

    onMenuClick(item: any, event: any) {
        super.onMenuClick(item, event);
        ChecklistPopoverComponent.closeAll();
    }
}
