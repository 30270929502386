import { Component, Injector, Input } from '@angular/core';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { MSMGameProgressUtils } from '../msm-game-progress.utils';
import { GameProgressListInfo } from '@applogic/model';
import { ProgressionTableService } from '../../progression-table.service';
import { MSMActivitiesTableRow, MSMChip } from '../msm-game-progress.model';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';


@Component({
    selector: 'app-msm-activity-list',
    templateUrl: './msm-activity-list.component.html',
    styleUrls: ['../../base-game-progress/base-game-progress.component.scss', './msm-activity-list.component.scss']
})
export class MsmActivityListComponent extends CommonListComponent<MSMActivitiesTableRow> {
    MSMGameProgressUtils = MSMGameProgressUtils;
    
    appWordLists: GameProgressListInfo[] = [];
    homeworkWordLists: GameProgressListInfo[] = [];

    langCode: string = "";

    @Input()
    selectedSeason: number = undefined;

    currentChip: MSMChip;
    
    constructor(injector: Injector, private progressTableService: ProgressionTableService) {
        super(injector);

        this.pageSizeOptions = [5, 10, 25, 50, 100];
        this.pageSize = 5;
        this.pageSizeDefault = 5;

        this.doInitialRefresh = false;

        this.rendererOptions.tableContainerClasses = ['game-progress-list', 'mat-elevation-z8'];
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    setAppWordLists(appWordLists: GameProgressListInfo[], homeworkWordLists: GameProgressListInfo[], langCode: string) {
        console.log("setActivities ## " + langCode);
        this.appWordLists = appWordLists;
        this.homeworkWordLists = homeworkWordLists;
        this.langCode = langCode;

        this.initColumns();
    }

    setProgressData(rows: MSMActivitiesTableRow[], selectedSeason: number) {
        this.selectedSeason = selectedSeason;
        this.setPreloadItems(rows);
    }

    initColumns() {
        this.initPersistentKey("");

        this.addColumn($localize`:@@common-student-name: Student’s name `, "student_name", false, true, CommonColumnType.Text, {
            fixedWidth: "200px"
        });

        for(let i=0; i<this.appWordLists.length; i++) {
            const wordlist = this.appWordLists[i];

            for(let j=0; j<wordlist.level.length; j++) {
                const level = wordlist.level[j];

                this.addColumn(wordlist.name, wordlist._id + "_" + level, true, true, CommonColumnType.Template, {
                    isSortable: false,
                    fixedWidth: "150px"
                });
            }
        }

        for(let i=0; i<this.homeworkWordLists.length; i++) {
            const wordlist = this.homeworkWordLists[i];
    
            this.addColumn(wordlist.name, wordlist._id + "_homework", true, true, CommonColumnType.Template, {
                isSortable: false,
                fixedWidth: "150px"
            });
        }

        this.updateColumnsSelection(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        
    }

    updateColumnsSelection(init: boolean = false) {
        this.progressTableService.loadColumnsState2('MSM', this.langCode, this.columns);

        if(init) {
            this.updateColumns(init);
        }
        else {
            this.updateSelectedColumns();
        }
    }

    setCurrentChip(chip: MSMChip) {     
        setTimeout(() => {
            this.currentChip = chip;
        }, 200);
    }
}
