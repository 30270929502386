import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { CoreModule } from '../core/core.module';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';


@NgModule({
    declarations: [OverviewComponent],
    imports: [
        CommonModule,
        CoreModule,
        AuthUiModule,
        YouTubePlayerModule,
        DashboardUiModule,
    ]
})
export class HelpModule { }
