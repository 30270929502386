<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[invoiceStatusTemplate]"
                        [emptyTemplate]="emptySection"
                        [actionTemplate]="actionsTemplate"></renderer-templates>
    <button mat-menu-item (click)="onShowInvoice(selectedMenuActionItem)" menuitems>
        <mat-icon>
            receipt
        </mat-icon>
        <span i18n="@@common-show-invoice">Show invoice</span>
    </button>

    <button mat-menu-item (click)="onDownloadInvoice(selectedMenuActionItem)" menuitems>
        <mat-icon>
            cloud_download
        </mat-icon>
        <span i18n="@@common-download-invoice">Download invoice</span>
    </button>

    <button mat-menu-item *ngIf="selectedMenuActionItem && (selectedMenuActionItem.state != InvoiceState.Paid)" (click)="onPayInvoiceClick(selectedMenuActionItem)" menuitems>
        <mat-icon>
            create
        </mat-icon>
        <span i18n="@@common-pay-now">Pay now</span>
    </button>


    <!-- [AEP6-424] TODO -->
    <!--
    <button *ngIf="selectedMenuActionItem && !selectedMenuActionItem.stripeInvoiceId" mat-menu-item (click)="onModifyInvoiceClick(selectedMenuActionItem)" menuitems>
        <mat-icon>
            edit
        </mat-icon>
        <span i18n="@@common-modify">Modify</span>
    </button>
    -->
  

</app-common-list-renderer>

<ng-template #emptySection>
    <span i18n="@@subscription-invoice-list-empty">You don't have invoices yet.</span>
</ng-template>

<ng-template #actionsTemplate let-row>
    <div [attr.id]="'inv-pay-now'" *ngIf="row.id == invoiceIdForOnboarding" style="width: 0px">
        <app-checklist-popover [key]="'inv-pay-now-popover'" checklistItem="custom" placement="left-top">
            <p popupcontent i18n="@@common-click-here" popupcontent>Click Here</p>
        </app-checklist-popover>
    </div>
</ng-template>


<ng-template #invoiceStatusTemplate let-data>
    <span [ngClass]="{'invoice_state_due':  + data.row.state == SubscriptionInvoiceState.Due, 'invoite_state_paid':  + data.row.state == SubscriptionInvoiceState.Paid, 'invoite_state_pending_payment':  + data.row.state == SubscriptionInvoiceState.PendingPayment, 'invoite_state_cancelled':  + data.row.state == SubscriptionInvoiceState.Cancelled}"> ● </span>
    <span>{{ApplogicUtils.subscriptionInvoiceStateToLabel(data.row.state)}}</span>
</ng-template>