<div class="theme-controls">
    <div class="color-picker-wrapper">
        <div class="color-picker-overflow">
            <input matInput
                   id="color-input"
                   type="color"
                   [(ngModel)]="color"
                   (ngModelChange)="onColorChange($event)" />
        </div>
    </div>
</div>