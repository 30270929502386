import { DOCUMENT } from '@angular/common';
import { EventEmitter, Injectable, inject } from '@angular/core';
import {
    TonalPalette,
    argbFromHex,
    hexFromArgb,
    themeFromSourceColor,
} from '@material/material-color-utilities';
import { LocalStorageService } from '../services/local-storage.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DEFAULT_COLOR } from './dynamic-material-color-selector/dynamic-material-color-selector.component';
import { ThemeService } from '../services/theme.service';


const DYNAMIC_COLOR_KEY = 'applogic/material_color/';

type WithStylesheet = typeof globalThis & {
    [stylesheetName: string]: CSSStyleSheet | undefined;
};


type colorsFromPaletteConfig = {
    primary: { hex: string; tone: number }[];
    secondary: { hex: string; tone: number }[];
    tertiary: { hex: string; tone: number }[];
    neutral: { hex: string; tone: number }[];
    neutralVariant: { hex: string; tone: number }[];
    error: { hex: string; tone: number }[];
};

export enum DynamicMaterialColorPalette {
    Primary = "primary",
    Secondary = "secondary",
    Tertiary = "tertiary",
    Neutral = "neutral",
    NeutralVariant = "neutral-variant",
    Error = "error",
}


@Injectable({
    providedIn: 'root'
})
export class DynamicMaterialColorService {

    #document = inject(DOCUMENT);

    isDark: boolean = false;

    private _preset: string = "preset1";

    set preset(name: string) {
        if (this._preset != name) {
            this._preset = name;
            this.ls.set(DYNAMIC_COLOR_KEY + "selected-preset", this.preset);
            this.onPresetChanged.emit(name);
        }
        this.updatePalettes();
    }

    get preset() {
        return this._preset;
    }

    onPresetChanged = new EventEmitter<string>();

    constructor(private ls: LocalStorageService, private clipboard: Clipboard, private themeService: ThemeService) {
        this.preset = ls.get(DYNAMIC_COLOR_KEY + "selected-preset") || "preset1";

        this.themeService.onThemeChanged.subscribe((theme) => {
            this.updateTheme();
        });

        this.updateTheme();
    }

    private updateTheme() {
        let isDark: boolean;
        if(this.themeService.theme.indexOf("dark-") != -1) {
            isDark = true;
        }
        else {
            isDark = false;
        }

        this.isDark = isDark;
        this.updatePalettes();
    }

    private updatePalettes() {
        for (const palette of Object.values(DynamicMaterialColorPalette)) {
            const color = this.getColor(palette);
            if (color) {
                this.themeFromSelectedColor(color, palette);
            }
        }
    }

    getColor(palette: DynamicMaterialColorPalette) {
        let color = this.ls.get(DYNAMIC_COLOR_KEY + this.preset + "/" + palette + this.getDarkPrefix());

        if (!color) {
            color = this.ls.get(DYNAMIC_COLOR_KEY + palette + this.getDarkPrefix());
        }

        if (color == undefined) {
            return DEFAULT_COLOR;
        }

        return color;
    }

    getDarkPrefix() {
        return "";
        // return (this.isDark ? "-light" : "-dark");
    }


    themeFromSelectedColor(color: string, palette: DynamicMaterialColorPalette): void {
        this.ls.set(DYNAMIC_COLOR_KEY + this.preset + "/" + palette + this.getDarkPrefix(), color);

        const colors = this.getColors(color, palette);

        // Then we will apply the colors to the DOM :root element
        this.createCustomProperties(colors, 'p', palette);
    }

    private getColors(color: string, palette: DynamicMaterialColorPalette): colorsFromPaletteConfig {

        // All calculations are made using numbers
        // we need HEX strings for use @material-utilitis-color apis
        const theme = themeFromSourceColor(
            argbFromHex(color)
        );

        // ngular material tones
        let tones: number[] = [0, 10, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 95, 98, 99, 100];
        const extraNeutralTones = [4, 6, 12, 17, 22, 24, 87, 92, 94, 96];

        let currentTones: number[];
        
        // A colors Dictionary 
        const colors = Object.entries(theme.palettes).reduce(
            (acc: any, curr: [string, TonalPalette]) => {
                if(curr[0] == "neutral") {
                    currentTones = [...tones, ...extraNeutralTones];
                }
                else if(palette == DynamicMaterialColorPalette.Neutral) {
                    currentTones = [...tones, ...extraNeutralTones];
                }
                else {
                    currentTones = tones;
                }

                const hexColors = currentTones.map((tone) => ({
                    tone, hex:
                        hexFromArgb(curr[1].tone(tone)),
                }));

                return { ...acc, [curr[0]]: hexColors };
            }, {});

        return colors;
    }

    createCustomProperties(
        colorsFromPaletteConfig: colorsFromPaletteConfig,
        paletteKey: 'p' | 't',
        palette: DynamicMaterialColorPalette
    ) {

        const paletteInfo = this.convertColorsToPalette(colorsFromPaletteConfig, palette);

        this.applyThemeString(paletteInfo.styleString, 'angular-material-theme-' + palette);
    }

    convertColorsToPalette(colorsFromPaletteConfig: colorsFromPaletteConfig,
        palette: DynamicMaterialColorPalette) {
        let selector = ".dynamic-material-color"; // ":root,:host"
        let styleString = selector + '{';
        let outputPalette: {[key: string]: string|any} = {};

        const paletteKey = "p";

        for (const [key, p] of Object.entries(colorsFromPaletteConfig)) {
            p.forEach(({ hex, tone }) => {

                switch (palette) {
                    case DynamicMaterialColorPalette.Primary:
                        if (key === 'primary') {
                            styleString += `--dynamic-primary-palette-${key}-${tone}:${hex};`;
                            outputPalette[tone] = hex;
                        }
                        break;

                    case DynamicMaterialColorPalette.Secondary:
                        if (key === 'primary') {
                            styleString += `--dynamic-primary-palette-${paletteKey}-secondary-${tone}:${hex};`;
                            outputPalette[tone] = hex;
                        }
                        break;

                    case DynamicMaterialColorPalette.Tertiary:
                        if (key === 'primary') {
                            styleString += `--dynamic-primary-palette-tertiary-primary-${tone}:${hex};`;
                            outputPalette[tone] = hex;
                        }
                        else {
                            if(key != "tertiary") {
                                let k = key;
                                if(k == "neutralVariant") {
                                    k = "neutral-variant";
                                }
                                if(!outputPalette[k]) {
                                    outputPalette[k] = {};
                                }
                                outputPalette[k][tone] = hex;
                            }
                        }
                        break;

                    case DynamicMaterialColorPalette.Neutral:
                        if (key === 'neutral') {
                            styleString += `--dynamic-primary-palette-${paletteKey}-neutral-${tone}:${hex};`;
                            outputPalette[tone] = hex;
                        }
                        break;

                    case DynamicMaterialColorPalette.NeutralVariant:
                        if (key === 'neutralVariant') {
                            styleString += `--dynamic-primary-palette-${paletteKey}-neutral-variant-${tone}:${hex};`;
                            outputPalette[tone] = hex;
                        }
                        break;

                    case DynamicMaterialColorPalette.Error:
                        if (key === 'error') {
                            styleString += `--dynamic-primary-palette-error-${tone}:${hex};`;
                            outputPalette[tone] = hex;
                        }
                        break;
                }
            });
        }

        styleString += '}';

        return {
            styleString,
            palette: outputPalette
        }
    }

    applyThemeString(
        themeString: string,
        ssName = 'angular-material-theme') {
        let sheet = (globalThis as WithStylesheet)[ssName];

        if (!sheet) {
            sheet = new CSSStyleSheet();
            (globalThis as WithStylesheet)[ssName] = sheet;
            this.#document.adoptedStyleSheets.push(sheet);
        }
        sheet.replaceSync(themeString);
    }

    copyCssPalette() {
        let finalPalettes: any = {
            primary: {},
            tertiary: {}
        };

        for (const palette of Object.values(DynamicMaterialColorPalette)) {
            const color = this.getColor(palette);

            const colors = this.getColors(color, palette);

            if(palette == DynamicMaterialColorPalette.Primary) {
                console.log("## colors: " + JSON.stringify(colors));
            }

            const paletteInfo = this.convertColorsToPalette(colors, palette);

            if(palette == DynamicMaterialColorPalette.Primary) {
                Object.assign(finalPalettes.primary, paletteInfo.palette);
            }
            else if(palette == DynamicMaterialColorPalette.Tertiary) {
                Object.assign(finalPalettes.tertiary, paletteInfo.palette);
            }
            else {
                finalPalettes.primary[palette] = paletteInfo.palette;
            }
        }
        
        // this.clipboard.copy(JSON.stringify(finalPalettes));
        this.clipboard.copy("$test-palette-primary: (\n" + this.convertObjectToScssMap(finalPalettes.primary, "    ") + "\n);\n$test-palette-tertiary: (\n" + this.convertObjectToScssMap(finalPalettes.tertiary, "    ") + "\n);");
    }

    convertObjectToScssMap(obj: any, level: string = ""): string {
        const convertValue = (value: any): string => {
            if (typeof value === 'object' && !Array.isArray(value)) {
                return `(\n${this.convertObjectToScssMap(value, level + "    ")}\n${level})`;
            } else if (Array.isArray(value)) {
                return `(${value.map(item => convertValue(item)).join(', ')})`;
            } else if (typeof value === 'string') {
                if (value.startsWith("#")) {
                    return value;
                }
                return `'${value}'`;
            } else {
                return value.toString();
            }
        };

        return Object.entries(obj)
            .map(([key, value]) => `${level}${key}: ${convertValue(value)}`)
            .join(',\n');
    }
}