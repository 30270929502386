<div *ngFor="let subAlloc of subAllocations">
    <div class="list-item" *ngFor="let alloc of subAlloc.allocations">
        <div class="subscription-info-block">
            <div class="subscription-info-line">
                <app-subscription-validity-label class="subscription-info-line-label" [subscription]="subAlloc.subscription">
                </app-subscription-validity-label>
                <span class="spacer"></span>
                <div *ngFor="let g of subAlloc.subscription.grants">
                    <div class="right-align" *ngIf="g.productShortCode.toLowerCase() as productCode">
                        <app-seat-token [allocation]="alloc" [displayType]="'free-only'" [productCode]="productCode">
                        </app-seat-token>

                        <mat-checkbox *ngIf="multiSelect && checkboxMode" class="checkbox-button" (change)="onCheckedChanged($event.checked, alloc, productCode)" [checked]="multiChecked[productCode] && multiChecked[productCode][alloc.id]" [disabled]="disabledProducts[productCode] || !alloc.freeSeats[productCode]">
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!multiSelect && checkboxMode" class="checkbox-button" (change)="onCheckedChanged($event.checked, alloc, productCode)" [checked]="checked[productCode] == alloc.id" [disabled]="disabledProducts[productCode] || !alloc.freeSeats[productCode]">
                        </mat-checkbox>
                        <mat-radio-button *ngIf="!multiSelect && !checkboxMode" class="radio-button" [name]="productCode"
                            (change)="onCheckedChanged($event.checked, alloc, productCode)"
                            [checked]="checked[productCode] == alloc.id"
                            [disabled]="disabledProducts[productCode] || !alloc.freeSeats[productCode]">
                        </mat-radio-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>