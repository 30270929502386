<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[cellTemplate]"
                        [templateColumns]="[]"></renderer-templates>
</app-common-list-renderer>


<ng-template #cellTemplate
             let-data>
    <div class="progress-table-date-cell"
         *ngIf="data.row as element">
        <span *ngFor="let chip of MSMGameProgressUtils.getDateChips(element.dates, data.column, selectedSeason); ;let i=index;"
              class="game-msm-array"
              [ngClass]="{'set-width-msm-game-date1': data.column.key != 'student_name'}"
              (mouseenter)="setCurrentChip(chip)"
              (click)="setCurrentChip(chip)">
            <div class="showgame-array"
                 [style.borderColor]="chip.color_code"
                 [ngStyle]="{'background-color': chip.completedlevelssublevel ? chip.color_code : '#ffffff', 
                               'color': chip.completedlevelssublevel ? '#ffffff' : '#000000'}"
                 [ngClass]="{'win-true': chip.isPlay === true} "
                 [ngbPopover]="popOver"
                 popoverClass="app-popover--plain-tooltip"
                 (mousewheel)="p.close(false)"
                 #p="ngbPopover">
                <span class="show-number">{{chip.name}}</span>
            </div>
        </span>
    </div>
</ng-template>

<ng-template #popOver>
    <app-msm-tooltip context='date'
                     [chip]="currentChip"></app-msm-tooltip>
</ng-template>