import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-toolbar-user-avatar',
    templateUrl: './toolbar-user-avatar.component.html',
    styleUrls: ['./toolbar-user-avatar.component.scss']
})
export class ToolbarUserAvatarComponent implements OnInit {

    constructor(public auth: AuthService, private router: Router) { }

    ngOnInit(): void {
    }


    logout() {

        this.auth.logout().subscribe(() => {
            this.router.navigate(['', 'login']);
        })
    }

    myAccount() {
        this.router.navigate(['/account']);
    }

}
