<div matDialogContent>
    <div class="error-container"
         *ngIf="inviteCollaboratorError">
        {{ inviteCollaboratorError }}
    </div>

    <div>
        <mat-form-field style="width: 100%"
                        [appearance]="'outline'">
            <mat-label i18n="@@common-search">
                Search
            </mat-label>

            <mat-icon matPrefix>search</mat-icon>

            <input type="text"
                   matInput
                   #userSearchInput
                   (input)="filterOrgUsers(userSearchInput.value)"
                   tabindex="0">

            <mat-icon matSuffix
                      style="cursor: pointer;"
                      *ngIf="userSearchInput.value"
                      (click)="userSearchInput.value = ''; filterOrgUsers('')">
                clear
            </mat-icon>

        </mat-form-field>

    </div>

    <div class="app-property-list-container">

        <div *ngIf="filteredOrganizationUsers.length != 0">
            <div class="list-item"
                 *ngFor="let u of filteredOrganizationUsers">

                <mat-checkbox *ngIf="!singleUserSelect"
                              style="margin-right: 16px"
                              [checked]="isInCollaboratorList(u)"
                              [value]="u"
                              (change)="onCollaboratorChecked($event)">
                </mat-checkbox>
                <mat-radio-button *ngIf="singleUserSelect"
                                  style="margin-right: 16px"
                                  [checked]="isInCollaboratorList(u)"
                                  [value]="u"
                                  (change)="onCollaboratorChecked($event)"></mat-radio-button>

                <div class="avatar">
                    <app-user-avatar [user]="u">
                    </app-user-avatar>
                </div>

                <div class="student-info">
                    <span>
                        {{ u.fullName }}
                        <span class="chip"
                              *ngIf="isUserOwner(u.id)"
                              i18n="@@common-owner">
                            Owner
                        </span>
                    </span>

                    <small class="secondary-text">
                        {{ u.email }}
                    </small>

                </div>

            </div>
        </div>

        <div *ngIf="filteredOrganizationUsers.length == 0">
            <p class="empty-list-message"
               i18n="@@classroom-collab-dialog-data-not-found">Search is not Found</p>
        </div>


    </div>

</div>