import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Currency, Subscription } from '@applogic/model';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { PaymentMethodSelectPanelComponent } from '../payment-method-select-panel/payment-method-select-panel.component';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface PaymentMethodSelectDialogData {
    paymentTarget: Subscription|Currency,
    stripeSubscriptionId?: string
}

@Component({
    selector: 'app-payment-method-select-dialog',
    templateUrl: './payment-method-select-dialog.component.html',
    styleUrls: ['./payment-method-select-dialog.component.scss']
  })
  export class PaymentMethodSelectDialogComponent implements OnInit {

    paymentTarget: Subscription|Currency;

    paymentMethodId: string;

    @ViewChild(PaymentMethodSelectPanelComponent)
    paymentSelectPanel: PaymentMethodSelectPanelComponent;

    stripeSubscriptionId: string;

    succeed: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: PaymentMethodSelectDialogData,
        private mdDialogRef: MatDialogRef<PaymentMethodSelectDialogComponent, string>,
        readonly loading: LoadingService) {
        this.paymentTarget = data.paymentTarget;
        this.stripeSubscriptionId = data.stripeSubscriptionId;
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: PaymentMethodSelectDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<PaymentMethodSelectDialogComponent, PaymentMethodSelectDialogData, string>(dialogService, dialog, PaymentMethodSelectDialogComponent, {
            data,
            maxWidth: "100vw"
        });
    }

    selectOrCreate() {
        let subscription: Subscription;

        if(this.paymentTarget instanceof Subscription) {
            subscription = this.paymentTarget as Subscription;
        }

        this.paymentSelectPanel.selectOrCreate(subscription, this.stripeSubscriptionId).then((success) => {
            if(success) {
                this.succeed = true;
                this.mdDialogRef.close(this.paymentSelectPanel?.resultPaymentMethod);
            }
        });
    }

}
