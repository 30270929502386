<app-dialog icon="people">
    <app-dialog-title i18n="@@classroom-export-students-dialog-title">Export Students List</app-dialog-title>
    <app-dialog-content>
        <div class="export-form">
            <span i18n="@@classroom-export-students-dialog-export-format"
                  tabindex="0">Save the list in format:</span>
            <br /><br />
            <span class="export-buttons">
                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('csv')"
                        matDialogClose>
                    <span>
                        CSV
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('xlsx')"
                        matDialogClose>
                    <span>
                        XLSX
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('ods')"
                        matDialogClose>
                    <span>
                        ODS
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('html')"
                        matDialogClose>
                    <span>
                        HTML
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('json')"
                        matDialogClose>
                    <span>
                        JSON
                    </span>
                </button>
            </span>
        </div>
    </app-dialog-content>
</app-dialog>