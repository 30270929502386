import { GameProgressListInfo } from "@applogic/model";
import { MMOChip } from "./mmo-game-progress.model";
import { BaseGameProgressUtils } from "../base-game-progress/base-game-progress.utils";
import { GameProgressSelectableColumn } from "../game-progress-selectable-column";


export class MMOGameProgressUtils extends BaseGameProgressUtils {

    /**  Get chips for the "DATES" progress table */
    public static getDateChips(gameChips: { gameNo: number, chips: MMOChip[] }, selectedSeason: number): MMOChip[] {
        let sortedData = MMOGameProgressUtils.sortingData(gameChips.chips) as MMOChip[];

        if (selectedSeason) {
            sortedData = MMOGameProgressUtils.filterChipsBySeason(sortedData, selectedSeason);
        }

        return sortedData;
    }

    public static sortingData(gameList: GameProgressListInfo[]|MMOChip[]) {
        const gameListData = gameList.sort(function (a, b) {
            if(a.subtype != b.subtype) {
                return a.subtype < b.subtype ? -1 : 1;
            }

            let x = a.season < b.season ? -1 : 1;

            if( (x == 0) || (x == undefined) ) {
                return a.pos < b.pos ? -1 : 1;
            }
            return x;
        });
        return gameListData;
    }

    public static getActivityChips(gameChips: {[gameNo: string]: MMOChip[]}, column: GameProgressSelectableColumn, selectedSeason: number): MMOChip[] {
        let chips = gameChips?.[column.key];

        if(!chips) {
            return [];
        }

        if(selectedSeason) {
            chips = this.filterChipsBySeason(chips, selectedSeason);
        }

        return chips;
    }
}