

import { Model, ID, Member } from '@uon/model';
import { User } from '../user/user.model';
import { Organization, OrganizationRole } from './organization.model';

@Model()
export class ClassroomStudentInvite {

    @ID()
    id: string;

    @Member()
    classroomId: string;

    @Member()
    createdBy: User;

    @Member()
    token: string;

    @Member()
    note: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    expiresOn: Date;

}


@Model()
export class OrganizationInvite {

    @ID()
    id: string;

    @Member()
    organization: Organization;

    @Member()
    invitedBy: User;

    @Member()
    token: string;

    @Member()
    note: string;

    @Member()
    email: string;

    @Member()   
    fullName: string;

    @Member()
    role: OrganizationRole;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    expiresOn: Date;

}

@Model()
export class ClassroomCollaboratorInvite {

    @ID()
    id: string;

    @Member()
    classroomId: string;

    @Member()
    invitedBy: User;

    @Member()
    invited: User;

    @Member()
    token: string;

    @Member()
    note?: string;

    @Member()
    email: string;

    @Member()   
    fullName: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    expiresOn: Date;

}