import {AbstractControl, AsyncValidatorFn} from '@angular/forms';
import { delay, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { StudentService } from '../services/student.service';
import { of } from 'rxjs';

export function profileNotUsedValidator(studentService: StudentService):AsyncValidatorFn  {
    return (control: AbstractControl) => {
        if (!control.valueChanges) { // || control.pristine) {
            return of(null);
        }

        if (control.value === '' || control.value === null || control.value === undefined) {
            return of(null);
        } 
        
        const studentService1 = studentService;

        return of(control.value).pipe(
            delay(500),
            distinctUntilChanged(),
            switchMap((value: string) => {
                return studentService1.doesUsernameExists(value);
            }),
            map((result: boolean) => {
                if(result) {
                    return {
                        profileExists: true,
                        localizedMessage: $localize`:@@form-field-username-already-exists:Profile name already taken`
                    }
                }

                return null;
            })
        ).pipe(first());
   }
}