import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { SubscriptionInvoice, SubscriptionType } from '@applogic/model';
import { Observable, Subject } from 'rxjs';
import { SelectableProduct } from 'src/app/order/base-payment-form/selectable-product';
import { ProductsSelectComponent } from 'src/app/product/products-select/products-select.component';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface SubscriptionInvoiceEditDialogData {
    invoice: SubscriptionInvoice;
}

@Component({
    selector: 'app-subscription-invoice-edit-dialog',
    templateUrl: './subscription-invoice-edit-dialog.component.html',
    styleUrls: ['./subscription-invoice-edit-dialog.component.scss']
})
export class SubscriptionInvoiceEditDialogComponent implements OnInit {

    private subject: Subject<SubscriptionInvoice>;
    invoice: SubscriptionInvoice;

    @ViewChild(ProductsSelectComponent, { static: false })
    productsSelect: ProductsSelectComponent;
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private mdDialogRef: MatDialogRef<SubscriptionInvoiceEditDialogComponent>) {
        this.subject = this.data.subject;
        this.invoice = this.data.invoice;
    }

    ngOnInit(): void {
        
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: SubscriptionInvoiceEditDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<SubscriptionInvoiceEditDialogComponent, SubscriptionInvoiceEditDialogData, SubscriptionInvoice>(dialogService, dialog, SubscriptionInvoiceEditDialogComponent, {
            data
        }, config);
    }

    onProductSelectionReady() {
        this.productsSelect.subscriptionPlan = this.invoice.subscriptionPlan;

        this.productsSelect.resetSelection();

        for(let i=0; i<this.invoice.priceTable.products.length; i++) {
            let product = this.invoice.priceTable.products[i];

            this.productsSelect.setSeats(product.productCode, product.seats, undefined);
        }
    }

    // Save the modifications.
    save() {

    }

    onProductsUpdate(products: SelectableProduct[]) {
        /*
        this.subscriptionPlan = this.productsSelect.subscriptionPlan;
        this.products = products;
        this.calculateTotalAmount();
        */
    }
}
