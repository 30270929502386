import { OrganizationTitle } from "../org/organization.model";
import { Address } from "../common/address.model";
import { SubscriptionPlan, SubscriptionType } from "../subscription/subscription.model";
import { SelectedProduct } from "./selected-product";
import { Quotation } from "../quotation/quotation.model";
import { SubscriptionInvoice } from "../subscription/subscription-invoice.model";
import { ArrayMember, Member, Model } from "@uon/model";
import { Utils } from "../utils/utils";


@Model()
export class TableProduct{

    @Member()
    productCode: string;

    @Member()
    seats: number;

    @Member()
    unitPrice: number;

    @Member()
    totalPrice: number;

    @Member()
    priceId: string;

    @Member()
    priceVersion: string;

    @Member()
    stripePriceId: string;
}

@Model()
export class TableTaxAmount {

    @Member()
    name: string;

    @Member()
    no: string;

    @Member()
    rate: number;

    @Member()
    amount: number;

    @Member()
    taxId: string;

    @Member()
    stripeTaxId: string;

    // Denormalized text of the current tax code name for the selected language.
    _taxCodeName: string;
}

@Model()
export class TableDiscount {

    @Member()
    promoCodeId: string;
    
    @Member()
    code: string;

    // Percent integer.
    @Member()
    rate: number;

    @Member()
    amount: number;

    @Member()
    stripeCouponId: string;
}


/**
 * Represent a price table.
 * 
 * In a price table, the following informations need to be displayed:
 * 
 * For each item:
 *  - name
 *  - qty
 *  - unit price
 *  - total price
 * 
 * And then:
 *  - subtotal (without taxes)
 *  - taxes
 *  - total (with taxes)
 */
 @Model()
export class PriceTable {

    @ArrayMember(TableProduct)
    products: TableProduct[];

    @ArrayMember(TableTaxAmount)
    taxes: TableTaxAmount[];

    @ArrayMember(TableDiscount)
    discounts: TableDiscount[];

    @Member()
    currency: string;

    @Member()
    subtotal: number;

    @Member()
    total: number;

    getTaxAmount(): number {
        let taxAmount: number;

        if(this.taxes) {
            this.taxes.forEach((tax) => {
                if(!taxAmount) {
                    taxAmount = tax.amount;
                } else {
                    taxAmount += tax.amount;
                }
            });
        }

        return taxAmount;
    }
}