import { Model, Member, Required } from "@uon/model";
import { InvitationCodeQuery } from "./auth.model";
import { UserServiceType } from "../user/service-type.model";


@Model()
export class LoginGoogleQuery {

    @Member({
        validators: [
            Required()
        ]
    })
    token: any;

    @Member()
    context: any;

}

@Model()
export class LoginSSOQuery {


    @Member({
        validators: [
            Required()
        ]
    })
    token: any;

    @Member({
        validators: [
            Required()
        ]
    })
    serviceType: UserServiceType;

    @Member()
    clientId: string;

    @Member()
    context: any;
}