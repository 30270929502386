<div [class]="'responsive-table app-common-list--mat-table-container ' + options.tableContainerClasses.join(' ')"
     [ngStyle]="options?.tableContainerStyles || {}"
     *ngIf="!(templatesOptions?.emptyTemplate || templatesOptions?.noResultTemplate) || (list.dataSource.data.length > 0)">
    <mat-table #table
               [dataSource]="list.dataSource"
               [matSortDirection]="list.sortDirection"
               matSort>
        <!-- All columns  -->
        <ng-container *ngFor="let column of list.columns"
                      cdkColumnDef="{{column.columnKey}}">
            <mat-header-cell *cdkHeaderCellDef
                             class="common-header-row fieldtype-{{column.type}}-column fieldkey-{{column.csskey}}-column fieldtype-{{column.type}}-row fieldkey-{{column.csskey}}-row"
                             [ngClass]="{'last-column': list.lastVisibleColumn == column, 'last-row': list.lastVisibleColumn == column, 'compact-mode': list.compactRow}"
                             [ngStyle]="column.headerStyles"
                             style="position: relative"
                             [tooltip]="column.options?.tooltip"
                             [options]="{display: !!column.options?.tooltip}">
                <span *ngIf="options.showSelectColumnsActionHeader && column.type == 'action'">
                    <button mat-icon-button
                            (click)="list.openSelectColumnsDialog()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </span>

                <ng-container [ngSwitch]="column.headerType">
                    <span *ngSwitchCase="'selection'">
                        <mat-checkbox class="selected-all"
                                      (change)="$event ? list.selectionToggle($event) : null"
                                      [checked]="list.selection.hasValue() && list.isAllSelected()"
                                      *ngIf="list.multiSelect && list.dataSource?.data">{{column.label}}
                        </mat-checkbox>
                    </span>
                    <span *ngSwitchCase="'template'">
                        <ng-template [ngTemplateOutlet]="templatesOptions?.templateColumns[column.templateColumnIdx]"
                                     [ngTemplateOutletContext]="{$implicit: {column}}"></ng-template>
                    </span>
                    <span *ngSwitchCase="'language-code'">
                        <mat-icon>language</mat-icon>
                    </span>
                    <ng-container *ngSwitchDefault>
                        <span *ngIf="!column.canSort">{{column.label}}</span>
                        <span *ngIf="column.canSort"
                              mat-sort-header>{{column.label}}</span>
                    </ng-container>
                </ng-container>

                <span *ngIf="options.showSelectColumnsTable && list.lastVisibleColumn == column">
                    <button mat-icon-button
                            (click)="list.openSelectColumnsDialog()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </span>
            </mat-header-cell>

            <mat-cell *cdkCellDef="let row; let i = index"
                      class="common-list-row common-list-cell fieldtype-{{column.type || row[column.rowTypeKey]}}-cell fieldkey-{{column.csskey}}-cell fieldtype-{{column.type || row[column.rowTypeKey]}}-row fieldkey-{{column.csskey}}-row"
                      [ngClass]="{'last-cell': list.lastVisibleColumn == column, 'last-row': list.lastVisibleColumn == column, 'compact-mode': list.compactRow}"
                      [ngStyle]="column.rowStyles">
                <app-common-list-cell-renderer [list]="list"
                                               [column]="column"
                                               [row]="row"
                                               [parent]="parent"
                                               [index]="i"></app-common-list-cell-renderer>
            </mat-cell>

            <mat-footer-cell *cdkFooterCellDef
                             class="common-footer-row fieldtype-{{column.type}}-footer fieldkey-{{column.csskey}}-footer fieldtype-{{column.type}}-row fieldkey-{{column.csskey}}-row"
                             [ngClass]="{'last-footer': list.lastVisibleColumn == column, 'last-row': list.lastVisibleColumn == column, 'compact-mode': list.compactRow}"
                             [ngStyle]="column.footerStyles"
                             style="position: relative">
                <ng-container [ngSwitch]="column.footerType">
                    <span *ngSwitchCase="'selection'">
                        <mat-checkbox class="selected-all"
                                      (change)="$event ? list.selectionToggle($event) : null"
                                      [checked]="list.selection.hasValue() && list.isAllSelected()"
                                      *ngIf="list.dataSource?.data">{{column.label}}
                        </mat-checkbox>
                    </span>
                    <span *ngSwitchCase="'template'">
                        <ng-template [ngTemplateOutlet]="templatesOptions?.templateColumns[column.templateFooterIdx]"
                                     [ngTemplateOutletContext]="{$implicit: {column}}"></ng-template>
                    </span>
                    <ng-container *ngSwitchDefault>
                        <div style="text-align: right;">
                            {{column.footerLabel}}
                        </div>
                    </ng-container>
                </ng-container>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="list.displayedColumns; sticky: options.stickyHeader"
                        [ngClass]="{'hidden-field': !options.showColumnHeader}"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: list.displayedColumns;"
                 [ngClass]="{'row-highlight': list.rowHighlight && list.selectedItemId && (list.selectedItemId == row.id)}"
                 (click)="list.onRowClick(row, $event)"></mat-row>
        <mat-footer-row *cdkFooterRowDef="list.displayedColumns; sticky: options.stickyFooter"
                        [ngClass]="{'hidden-field': !options.showColumnFooter}"></mat-footer-row>

    </mat-table>

</div>
<div class="empty-list-message"
     *ngIf="templatesOptions?.emptyTemplate && list.wasInitialized && !list.hasCriterion && (list.dataSource.data.length == 0)">
    <ng-template [ngTemplateOutlet]="templatesOptions?.emptyTemplate"></ng-template>
</div>

<div class="no-result-message"
     *ngIf="templatesOptions?.noResultTemplate && list.wasInitialized && list.hasCriterion && (list.dataSource.data.length == 0)">
    <ng-template [ngTemplateOutlet]="templatesOptions?.noResultTemplate"></ng-template>
</div>

<app-common-list-paginator [list]="list" [parent]="parent"></app-common-list-paginator>