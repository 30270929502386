import { Component, Input } from '@angular/core';
import { CommonListColumn } from '../../../common-list-column';
import { CommonListRendererComponent } from '../../../common-list-renderer/common-list-renderer.component';
import { ICommonList } from '../../../common-list.interface';

@Component({
    template: ''
})
export abstract class CommonListAbstractCellRendererComponent {

    @Input()
    parent: CommonListRendererComponent;

    @Input()
    list: ICommonList;

    @Input()
    column: CommonListColumn;

    @Input()
    row: any;

    @Input()
    index: number;

    getRowValue(column: CommonListColumn, row: any, keys?: string[]) {
        return this.list.getRowValue(column, row, keys);
    }

    getBoolValue(column: CommonListColumn, row: any) {
        let v = this.list.getRowValue(column, row);

        if (v == undefined) {
            v = column.parameters?.defaultValue;
        }

        if (v === true) {
            return "true";
        }
        else if (v === false) {
            return "false";
        }

        return "";
    }
}
