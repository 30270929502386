import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { LanguageService } from './language.service';
import { JsonSerializer } from '@uon/model';
import * as AsyncLock from "async-lock";
import { LocalStorageService } from './local-storage.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SharedList } from './shared-list';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { A } from '@angular/cdk/keycodes';

/**
 * Service to allocated shared list.
 * 
 * It help when a page use multiple components that can use the same list.
 * Don't forget to unsubscribe to observer when a list is not used anymore.
 */
@Injectable({
    providedIn: 'root'
})
export class SharedListService {

    private sharedListsGlobal: {[key: string]: any} = {};
    private sharedLists: {[key: string]: any} = {};

    constructor(private router: Router) {
        this.router.events.subscribe((event: any) => {

            if (event instanceof NavigationStart) {
                this.sharedLists = {};
            }

            if (event instanceof NavigationEnd) {
                // Check for list that aren't used anymore.
                for(var key of Object.keys(this.sharedListsGlobal)) {
                    this.updateList(key, true);
                }
            }
        });
    }

    /**
     * Allocate a shared list.
     * 
     * @param {string} key The unique shared list key.
     * @param {any} createCallback Async callback method to call for creating the initial list.
     * @param {boolean} global If true, the shared list won't be cleared when changing page.
     *                         Then make sure to release an unused shared list.
     */
     public allocate<T>(key: string, createCallback: any, updateCallback: (list: T[]) => void, global: boolean): Subscription {
        let o: BehaviorSubject<T[]>;
        
        let sharedLists: {[key: string]: any};
        if(global) {
            sharedLists = this.sharedListsGlobal;
        } else {
            sharedLists = this.sharedLists;
        }

        let sharedList: SharedList<T> = sharedLists[key];
        if(!sharedList) {
            sharedList = new SharedList<T>();
            sharedLists[key] = sharedList;
            sharedList.subscribers = [];

            createCallback().then((list) => {
                sharedList.setList(list);
            });
        }

        let subscription = sharedList.getObserver().subscribe((list) => {
            updateCallback(list)
        });
        sharedList.subscribers.push(subscription);
        
        return subscription;
    }

    /**
     * Update a list to unallocated unused list.
     * 
     * @param {string} key The unique shared list key.
     * @param {boolean} global If true, the shared list won't be cleared when changing page.
     *                         Then make sure to release an unused shared list.
     */
    public updateList(key: string, global: boolean): void {
        let sharedLists: {[key: string]: any};
        if(global) {
            sharedLists = this.sharedListsGlobal;
        } else {
            sharedLists = this.sharedLists;
        }

        let result = sharedLists[key];
        if(result) {
            let count = result.subscribers.filter(s => !s.closed).length;

            if(count == 0) {
                delete sharedLists[key];
            }
        }
    }

}
