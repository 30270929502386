import { Component } from '@angular/core';
import { DynamicMaterialColorPalette, DynamicMaterialColorService } from '../dynamic-material-color.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-dynamic-material-colors-selector',
    templateUrl: './dynamic-material-colors-selector.component.html',
    styleUrl: './dynamic-material-colors-selector.component.scss'
})
export class DynamicMaterialColorsSelectorComponent {

    palettes = Object.values(DynamicMaterialColorPalette);
    
    presets: string[] = ["preset1", "preset2", "preset3"];
    currentPreset: string;

    constructor(protected dynamicMaterialColorService: DynamicMaterialColorService) {
        this.currentPreset = dynamicMaterialColorService.preset;
    }
    
    onCopyClick() {
        this.dynamicMaterialColorService.copyCssPalette();
    }

    onPresetChanged(event: MatSelectChange) {
        this.dynamicMaterialColorService.preset = event.value;
    }
}
