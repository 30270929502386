import { Model, Member, ID } from "@uon/model";

@Model()
export class OnboardingFormType {

    @ID() 
    id: string;

    @Member() 
    key: string;

    @Member()
    route: string;

}