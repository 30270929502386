<div class="profile-count" [ngClass]="{'profile-count-flat': (style == 'flat')}">
    <button mat-flat-button
            class="left-button"
            *ngIf="showControls"
            (click)="decreaseQuantity()"
            [ngClass]="{'enable-button': canDecrease, 'disable-button': !canDecrease}"
            [disabled]="!canDecrease">
        <mat-icon>remove</mat-icon>
    </button>
    <div class="mat-form-field-container">
        <mat-form-field class="vertical-count"
                        *ngIf="type=='counters'"
                        appearance="outline"
                        floatLabel="always"
                        subscriptSizing="dynamic"
                        class="seat-count-input">
            <input type="text"
                   name="{{counterKey}}_quantity"
                   matInput
                   autocomplete="disabled"
                   #textInput1
                   [(ngModel)]="quantity[counterKey]"
                   (keydown)="keyDown($event, textInput1)"
                   (keypress)="keyPress($event, textInput1)"
                   (keyup)="keyUp($event, textInput1)"
                   (input)="onInput($event, textInput1)"
                   (change)="onChangeQuantityNumber()"
                   [ngStyle]="{'text-align':center ? 'center' : undefined }"
                   required>
        </mat-form-field>

        <mat-form-field class="vertical-count"
                        style="align-self: center; margin-bottom: 0px;"
                        *ngIf="type=='number'"
                        appearance="outline"
                        subscriptSizing="dynamic"
                        floatLabel="always"
                        class="seat-count-input">

            <input type="text"
                   name="{{counterKey}}_quantity"
                   matInput
                   autocomplete="disabled"
                   #textInput2
                   [(ngModel)]="quantityModel"
                   (ngModelChange)="onChangeQuantityNumber()"
                   (keydown)="keyDown($event, textInput2)"
                   (keypress)="keyPress($event, textInput2)"
                   (keyup)="keyUp($event, textInput2)"
                   (input)="onInput($event, textInput2)"
                   [ngStyle]="{'text-align':center ? 'center' : undefined }"
                   required>


        </mat-form-field>
    </div>
    <button mat-flat-button
            class="right-button"
            [ngClass]="{'enable-button': canIncrease, 'disable-button': !canIncrease}"
            *ngIf="showControls"
            [disabled]="!canIncrease"
            style="margin-right: unset;"
            (click)="increaseQuantity()">
        <mat-icon>add</mat-icon>
    </button>
    <span id="textWidthCalculator"
          class="textWidthCalculator"
          style="visibility: hidden; white-space: pre; position: absolute; top: 0;"></span>
</div>