import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryService } from 'src/app/services/country.service';
import { UntypedFormControl } from '@angular/forms';
import { Subscription as RxSubscription } from 'rxjs';
import { SubscriptSizing, FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
@Component({
  selector: 'app-select-countries-option',
  templateUrl: './select-countries-option.component.html',
  styleUrls: ['./select-countries-option.component.scss']
})
export class SelectCountriesOptionComponent implements OnInit, OnChanges, OnDestroy {

    @Input() selectFormControl: UntypedFormControl;

    @Input() showIcon: boolean = true;

    @Input() isRequired: boolean = true;

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    floatLabel: FloatLabelType = 'always';

    @Input()
    subscriptSizing: SubscriptSizing = 'fixed';

    countriesFilterList:Array<any> = [];

    countriesData :any = [];

    selectedCountry:any = "";

    // The subscription to the selectFormControl.valueChanges.
    valueChangesSubscription: RxSubscription;

    @Output() public onChangeSelectedCountry:EventEmitter<any> = new EventEmitter();

    constructor( 
        public httpClient :HttpClient,
        public countryService:CountryService) {}

    ngOnInit(): void {
        this.countryService.refreshCountryList().then((countryList) => {
            this.countriesData =  countryList;
            this.showSelectedData();
            this.filterOrgCountry("");
        });

    }

    ngOnDestroy() {
        if(this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
            this.valueChangesSubscription = undefined;
        }
    }

    ngOnChanges(changes:SimpleChanges){
        // When the Input FormControl change.
        if(changes.selectFormControl){
            // Unsubscribe from the previous FormControl valueChanges.
            if(this.valueChangesSubscription) {
                this.valueChangesSubscription.unsubscribe();
                this.valueChangesSubscription = undefined;
            }

            
            if(this.selectFormControl) {
                // Register the new FormControl valueChanges.
                this.valueChangesSubscription = this.selectFormControl.valueChanges.subscribe(value => {
                    this.selectedCountry = value;
                    this.showSelectedData();
                });

                this.selectedCountry = this.selectFormControl.value;
                this.showSelectedData();
            }
        }
    }

    /**
     * FILTER THE DATA ON SEARCHING...
    * @param search 
    */

    filterOrgCountry(search: string) {
        if (this.countriesData.length != 0) {
            if (search == "") {
                this.countriesFilterList = this.countriesData;
                return;
            }
            this.countriesFilterList =  this.countriesData.filter((u) => {
                return u.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
        }
    }

    /**
     * SET THE VALUE INTO SELECT
    */
    showSelectedData(){
        if(this.selectFormControl && this.selectFormControl.value){
            this.selectedCountry = this.countryService.
             getCountryCodeByName(this.selectFormControl.value)
        }
    }

    /**
     * RETURN THE SELECTED VALUE 
    * @param event 
    */
    onChangeSelectedValue(event:any){
        this.onChangeSelectedCountry.emit(event)
    }

}