import { Directive } from '@angular/core';

@Directive({
    selector: 'app-title,[appTitle]',
    host: {
        'class': 'app-title'
    }
})
export class TitleDirective {

    constructor() { }

}
