
/**
 * Convert a locale string date to date.
 * 
 * When calling new Date("2023-04-20"), the date returned is in UTC and may become locally 2023-04-19 for a UTC-5 timezone.
 * 
 * @param format String format. Same format as the constructors for a Date.
 * 
 * @returns Returns a date.
 */
export function LocaleDateStringToDate(format: string) {
    let utc_date = new Date(format);
    return new Date(
        utc_date.getUTCFullYear(),
        utc_date.getUTCMonth(),
        utc_date.getUTCDate(),
        utc_date.getUTCHours(),
        utc_date.getUTCMinutes(),
        utc_date.getUTCSeconds(),
        utc_date.getUTCMilliseconds()
    );
}

/**
 * Compare two date ignoring time.
 * 
 * @param date1 First date
 * @param date2 Second date
 * 
 * @returns 0  if date1 == date2
 *         -1  if date1 < date2
 *          1  if date1 > date2
 */
export function CompareDate(date1: Date, date2: Date, utc: boolean = false): -1|0|1 {

    const year1 = utc ? date1.getUTCFullYear() : date1.getFullYear();
    const year2 = utc ? date2.getUTCFullYear() : date2.getFullYear();

    if (year1 < year2) {
        return -1;
    }
    else if (year1 > year2) {
        return 1;
    }
    else {
        const month1 = utc ? date1.getUTCMonth() : date1.getMonth();
        const month2 = utc ? date2.getUTCMonth() : date2.getMonth();

        if (month1 < month2) {
            return -1;
        }
        else if (month1 > month2) {
            return 1;
        }
        else {
            const day1 = utc ? date1.getUTCDate() : date1.getDate();
            const day2 = utc ? date2.getUTCDate() : date2.getDate();

            if (day1 < day2) {
                return -1;
            }
            else if(day1 > day2) {
                return 1
            }
            return 0;
        }
    }
}