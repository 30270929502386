import { Component, OnInit } from '@angular/core';
import { Organization, OrganizationInvite, RegisterUserForm, User } from '@applogic/model';
import { InviteService } from '../invite.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { AuthService } from 'src/app/auth/auth.service';
import { COLLAPSE_HEIGHT } from 'src/app/animations/collapse';
import { FADE_IN_OUT } from 'src/app/animations/fade';
import { FormService } from 'src/app/services/form.service';
import { UntypedFormGroup } from '@angular/forms';
import { Model } from '@uon/model';

enum Step {
    Login = 'login',
    Register = 'register',
    Accept = 'accept',
    Complete = 'complete'
}

@Component({
    selector: 'app-staff-member-invite-page',
    templateUrl: './staff-member-invite-page.component.html',
    styleUrls: ['./staff-member-invite-page.component.scss'],
    animations: [FADE_IN_OUT, COLLAPSE_HEIGHT]
})
export class StaffMemberInvitePageComponent implements OnInit {

    Step = Step;

    summaryError: boolean = false;
    invite: OrganizationInvite;

    errorObject: any;

    inviteEmail: any = "";

    protected _step: Step;

    constructor(private service: InviteService,
        private route: ActivatedRoute,
        readonly loading: LoadingService,
        readonly auth: AuthService,
        readonly formService: FormService) { }

    ngOnInit(): void {

        const token: string = this.route.snapshot.params.token;

        if (token.startsWith("test-")) {
            this.initTestMode(token);
        }
        else {
            this.service.getOrgInvite(token).subscribe((invite) => {
                const email = invite.email
                this.auth.getAuthenticateUserEmail(email).subscribe((res) => {
                    const userExists: boolean = res && res.result;

                    if (userExists) {
                        if (!this.auth.isLoggedIn() && (this.auth.user?.email != invite.email)) {
                            this._step = Step.Login;
                        }
                        else {
                            this._step = Step.Accept;
                        }
                    }
                    else {
                        this._step = Step.Register;
                    }
                    this.invite = invite;
                    this.inviteEmail = invite.email;
                }, (err) => {
                    this.errorObject = err;
                    this.summaryError = true;
                });
            }, (err) => {

                this.errorObject = err;
                this.summaryError = true;
            })
        }
    }

    private initTestMode(token: string) {
        this._step = token.substring(5) as Step;

        this.invite = Model.New(OrganizationInvite, {
            invitedBy: Model.New(User, {
                email: 'inviter-email@example.com',
                fullName: 'Invited Name'
            }),
            organization: Model.New(Organization, {
                name: 'Org Name'
            })
        });
    }

    submitRegisterForm(formData: UntypedFormGroup) {
        const token = this.route.snapshot.params.token;
        this.errorObject = null;

        const rawValue = formData.getRawValue();
        const user = Object.assign({}, formData.getRawValue());
        this.auth.tryRegister(Model.New(RegisterUserForm, user), "staff invite").subscribe({
            next: (val) => {
                this.errorObject = null;
                this._step = Step.Accept;
            }, error: (err) => {
                this.errorObject = err;
            }
        });
    }

    submitLoginForm(formData: UntypedFormGroup) {
        const token = this.route.snapshot.params.token;
        this.errorObject = null;

        this.auth.tryLogin(formData.getRawValue().email, formData.value.password, "staff invite login").subscribe({
            next: (val) => {
                this._step = Step.Accept;
            }, error: (err) => {
                this.errorObject = err;
            }
        });
    }

    acceptInvite() {
        const token = this.route.snapshot.params.token;
        this.errorObject = null;

        this.service.registerStaff(token).subscribe(() => {
            this._step = Step.Complete;
        }, (err) => {
            this.errorObject = err;
        });
    }
}
