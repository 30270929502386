import { Model, Member } from "@uon/model";

export interface InvitationCodeQuery {
    inviteCode: string;
}

@Model()
export class SSORedirectQuery {

    @Member()
    error: string;
    
    @Member()
    state: string;

    @Member()
    code: string;

    @Member()
    lang: string;

    @Member()
    context: any;
}

@Model()
export class SSORedirectResponse {
    @Member()
    type: 'auth';

    @Member()
    result: {
        user: any,
        expires: number
    };

    @Member()
    token: string;

    @Member()
    productCode: string;

    @Member()
    platform: string;

    @Member()
    bundleIdentifier: string;

    @Member()
    store: boolean;
}