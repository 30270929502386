<div class="custom-form-field custom-form-toggle-field one-line-field">
    <div class="field-line">
        <mat-label *ngIf="field.label"
                   (contextmenu)="showDebugs($event)">{{field.label}}</mat-label>
        <app-help-icon *ngIf="data.hint"
                       [text]="data.hint"
                       placement="left"></app-help-icon>
        <span class="spacer"></span>
        <mat-slide-toggle [formControl]="field.control"
                          (checked)="onFieldToggleChanged($event)"></mat-slide-toggle>
    </div>
    <mat-error *ngIf="field.control.invalid">
        {{ formService.getFieldErrorMessage(field.control, data.typeKey || field.key) }}
    </mat-error>
</div>