<app-dialog icon="people">
    <app-dialog-title i18n="@@msm-word-list-dialog-title">MSM Games List</app-dialog-title>
    <app-dialog-content class="word-list-tabs">
        <mat-tab-group mat-align-tabs="start"
                       #tabGroup
                       (selectedTabChange)="tabChanged($event)">

            <mat-tab *ngFor="let filter of filters">

                <ng-template mat-tab-label>
                    <span i18n="@@msm-game-heading">
                        {{filter.label}}
                    </span>
                </ng-template>
                <app-columns-selection-colums-list [gameColumns]="columns | listTypeColumns:filter.type:filter.subtype"></app-columns-selection-colums-list>
            </mat-tab>
        </mat-tab-group>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="accent"
            (click)="updateTableDisplayCol()">
        <span i18n="@@msm-game-word-list-model-save-button">Save</span>
    </button>
</app-dialog>

<ng-template #wordsTooltipTemplate>
    <app-columns-selection-word-list-tooltip [column]="_columnTooltip"></app-columns-selection-word-list-tooltip>
</ng-template>