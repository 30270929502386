<app-page type="centered-full-width">
    <app-page-header2-card class="account-page-header"
                           bgImageUrl="assets/headers/account.jpg">
        <design-card-title i18n="@@account-overview-page--title">{{auth.user.firstName}}'s Account</design-card-title>
        <design-card-subtitle i18n="@@account-overview-page--subtitle">
            Manage your informations.
        </design-card-subtitle>
    </app-page-header2-card>
    <app-page-content class="flex-column flex-default-gap" style="justify-content: flex-start;">
        <mat-tab-group class="app-mat-tab-group centered-tab-group medium full-width"
                       mat-stretch-tabs="false">
            <mat-tab class="form-tab"
                     bodyClass="app-tab--medium">
                <ng-template mat-tab-label>
                    <span i18n="@@common-profile">Profile</span>&nbsp;<mat-icon color="warn"
                              class="tab-warning-icon"
                              *ngIf="profileForm.formGroup.invalid">warning</mat-icon>
                </ng-template>
                <div class="flex-column flex-default-gap">
                    <div>
                        <span i18n="@@common-member-since"> Member Since </span>: {{profile.user.registeredOn | date:'mediumDate'}}
                    </div>
                    <app-form-builder-renderer [form]="profileForm"></app-form-builder-renderer>
                </div>
            </mat-tab>
            <mat-tab class="form-tab"
                     bodyClass="app-tab--medium">
                <ng-template mat-tab-label>
                    <span i18n="@@common-preferences">Preferences</span>&nbsp;<mat-icon color="warn"
                              class="tab-warning-icon"
                              *ngIf="settingsForm.formGroup.invalid">warning</mat-icon>
                </ng-template>
                <app-form-builder-renderer [form]="settingsForm"
                                           [templates]="[emailPreferencesTemplate]"></app-form-builder-renderer>
            </mat-tab>
        </mat-tab-group>
        <div class="app-form-actions"
             style="margin-left: auto; margin-right: auto">
            <button mat-flat-button
                    appDialogAction
                    color="primary"
                    (click)="saveChanges()"
                    [disabled]="(!profileForm.formGroup.dirty && !settingsForm.formGroup.dirty) ||profileForm.formGroup.invalid || settingsForm.formGroup.invalid || loadingService.isLoading || (loadingService.loadingChange | async)"
                    i18n="@@common-save">Save</button>
        </div>

        <app-error-message-area [error]="errorObject"></app-error-message-area>
    </app-page-content>
</app-page>

<ng-template #emailPreferencesTemplate
             let-data>
    <app-user-email-prefs *ngIf="data.field?.control"
                          [formGroup]="data.field?.control"
                          [emailPreferences]="profile?.user.emailPreferences"
                          [showTitle]="false"></app-user-email-prefs>
</ng-template>