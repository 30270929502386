<div class="secondary-text hint-title"
     i18n="@@did-you-know--title">Did you Know?</div>
<div class="hint-content">
    <div class="message">
        <div templateOutlet></div>
    </div>
    <div class="button-container">
        <button mat-icon-button
                (click)="nextHint()">
            <mat-icon>
                keyboard_arrow_right
            </mat-icon>
        </button>
    </div>
</div>
<span class="spacer"></span>
<app-progress-step [nbSteps]="hintComponents?.length || 0"
                   [currentStep]="currentHintIndex+1"
                   [style]="'dot'"
                   [readonly]="false"
                   [cumulative]="false"
                   (onChange)="loadHint($event - 1)"></app-progress-step>