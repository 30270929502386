import { Directive } from '@angular/core';

@Directive({
    selector: 'design-card-content',
    host: {
        'class': 'design-card-content'
    }
})
export class DesignCardContentDirective {

    constructor() { }

}
