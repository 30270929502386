import { Directive } from '@angular/core';

@Directive({
    selector: 'app-page-header-card-subtitle',
    host: {
        'class': 'app-page-header-card-subtitle'
    }
})
export class PageHeaderCardSubtitleDirective {

    constructor() { }

}
