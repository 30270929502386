import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface JsonViewerDialogData {
    data: any;
}

@Component({
  selector: 'app-json-viewer-dialog',
  templateUrl: './json-viewer-dialog.component.html',
  styleUrl: './json-viewer-dialog.component.scss'
})
export class JsonViewerDialogComponent {

    json: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: JsonViewerDialogData,
    private mdDialogRef: MatDialogRef<JsonViewerDialogComponent>) {
        this.json = JSON.stringify(data.data, null, 2).trim();
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: JsonViewerDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<JsonViewerDialogComponent, JsonViewerDialogData, void>(dialogService, dialog, JsonViewerDialogComponent, {
            data,
            width: '100vw',
            maxWidth: '800px',
            height: '90%'
        }, config);
    }

    protected getKeys(obj: any): string[] {
        if(!obj) return [];

        return Object.keys(obj);
    }
}
