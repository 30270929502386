import { ComponentType } from "@angular/cdk/overlay";
import { AppDialogConfig, DialogService } from "./dialog.service";
import { TemplateRef, Type } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

export class AppDialogRef<C, D = unknown, R = unknown> {


    ref: MatDialogRef<C, R>;

    constructor(
        private dialogService: DialogService,
        private dialog: MatDialog,
        private componentType: ComponentType<C> | TemplateRef<C>,
        private config: MatDialogConfig<D>,
        private extraConfig?: AppDialogConfig
    ) {
    }

    show(d?: D): Promise<R> {
        if(d) {
            this.config.data = d;
        }

        if(!this.extraConfig) {
            this.extraConfig = {};
        }

        if(!this.extraConfig.dialog) {
            this.extraConfig.dialog = this.dialog;
        }

        const ref = this.dialogService.openDialog<C, D, R>(this.componentType, this.config, this.extraConfig);
        this.ref = ref;

        return new Promise((resolve, rejects) => {
            ref.beforeClosed().subscribe({
                next: (result?: R) => {
                    resolve(result);
                },
                error: (err: any) => {
                    console.error(err);
                    rejects(err);
                }
            });
        });
    }
}