<ng-container [ngSwitch]="countryCode" style="width: 100%">
    <mat-form-field appearance="outline"
                    floatLabel="always"
                    [formGroup]="addressFormGroup"
                    *ngSwitchDefault
                    >
        <mat-label i18n="@@tell-us-form-state-or-province-label">State or province</mat-label>
        <input type="text"
               formControlName="state"
               (change)="formService.trimSpaces(selectFormControl)"
               autocomplete="off"
               matInput
               required>
    </mat-form-field>
</ng-container>