import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';


@Injectable({
    providedIn: 'root'
})
export class GlobalMessageService {


    constructor(private languageService: LanguageService) { }

    getMessage() {
        // Here is the place to set global message.
        /*
        if(this.languageService.currentLanguageCode == "es") {
            return {
                messageType: "warning",
                userMessage: "¡Atención! Se está aventurando en una versión beta de nuestro sitio en español. Esta sección aún no está completa."
            };
        }
        */

        return undefined;
    }
}
