<app-dialog icon="person">
    <app-dialog-title i18n="@@users-selection-dialog-title">Select an user</app-dialog-title>
    <app-dialog-message>
        <div *ngIf="data.message">{{data.message || ""}}<br /></div>
        <strong>{{data.org.name}}&nbsp;</strong>
    </app-dialog-message>
    <app-dialog-content>
        <app-users-selection [orgId]="data.org.id"
                                [role]="data.role"
                                [singleUserSelect]="data.singleUserSelect"
                                (onUserChange)="onUserChange($event)">
        </app-users-selection>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            [disabled]="!selectedUser"
            (click)="onSelect()">
        <span i18n="@@common-select">Select</span>
    </button>
</app-dialog>