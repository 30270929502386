import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ClassroomListPageComponent } from './classroom-list-page/classroom-list-page.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClassroomPageComponent } from './classroom-page/classroom-page.component';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ColNamePipe } from './col-name.pipe';
import { ColKeyPipe } from './col-key.pipe';
import { GameProgressSelectedColumnsPipe } from './game-progress-selected-columns-pipe';
import { GameProgressListTypeColumnsPipe } from './game-progress-list-type-columns.pipe';
import { MsmGameProgressComponent } from './msm-game-progress/msm-game-progress.component';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { MmoGameProgressComponent } from './mmo-game-progress/mmo-game-progress.component';
import { ColumnsSelectionDialogComponent } from './columns-selection-dialog/columns-selection-dialog.component';
import { ColHeaderFilterPipe } from './col-header-filter.pipe';
import { HomeworkModule } from '../homework/homework.module';
import { StudentModule } from '../student/student.module';
import { SeatUiModule } from '../seat-ui/seat-ui.module';
import { AssignTokensDialogComponent } from './assign-tokens-dialog/assign-tokens-dialog.component';
import { CreateClassroomDialogComponent } from './create-classroom-dialog/create-classroom-dialog.component';
import { AssignTokensPanelComponent } from './assign-tokens-panel/assign-tokens-panel.component';
import { AddStudentDialogComponent } from './add-student-dialog/add-student-dialog.component';
import { AssignClassroomTokensDialogComponent } from './assign-classroom-tokens-dialog/assign-classroom-tokens-dialog.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { AssignStudentHomeworksDialogComponent } from './assign-student-homeworks-dialog/assign-student-homeworks-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ExportStudentsDialogComponent } from './export-students-dialog/export-students-dialog.component';
import { ImportStudentsTabComponent } from './add-student-dialog/import-students-tab/import-students-tab.component';
import { SelectClassroomDialogComponent } from './classroom-page/select-classroom-dialog/select-classroom-dialog.component';
import { ClassroomUiModule } from '../classroom-ui/classroom-ui.module';
import { CollaboratorListComponent } from './classroom-page/collaborator-list/collaborator-list.component';
import { OnboardingUiModule } from '../onboarding-ui/onboarding-ui.module';
import { OrganizationUiModule } from '../organization-ui/organization-ui.module';
import { MmoActivityListComponent } from './mmo-game-progress/mmo-activity-list/mmo-activity-list.component';
import { MmoDateListComponent } from './mmo-game-progress/mmo-date-list/mmo-date-list.component';
import { MsmActivityListComponent } from './msm-game-progress/msm-activity-list/msm-activity-list.component';
import { MsmDateListComponent } from './msm-game-progress/msm-date-list/msm-date-list.component';
import { ColumnsSelectionColumsListComponent } from './columns-selection-dialog/columns-selection-colums-list/columns-selection-colums-list.component';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';
import { InviteCollaboratorDialogComponent } from './classroom-page/invite-collaborator-dialog/invite-collaborator-dialog.component';
import { MmoTooltipComponent } from './mmo-tooltip/mmo-tooltip.component';
import { MsmTooltipComponent } from './msm-tooltip/msm-tooltip.component';
import { ColumnsSelectionWordListTooltipComponent } from './columns-selection-dialog/columns-selection-word-list-tooltip/columns-selection-word-list-tooltip.component';
import { ClassroomRoleCardComponent } from './classroom-role-card/classroom-role-card.component';
import { ClassroomCodePanelComponent } from './classroom-code-panel/classroom-code-panel.component';


const INTERNAL_COMPONENTS = [
    ClassroomCodePanelComponent,
    ClassroomRoleCardComponent,
    ColumnsSelectionWordListTooltipComponent,
    MmoTooltipComponent,
    MsmTooltipComponent
];


@NgModule({
    declarations: [
        ClassroomListPageComponent,
        ClassroomPageComponent,
        ColNamePipe,
        ColKeyPipe,
        ColHeaderFilterPipe,
        GameProgressListTypeColumnsPipe,
        GameProgressSelectedColumnsPipe,
        MsmGameProgressComponent,
        MmoGameProgressComponent,
        ColumnsSelectionDialogComponent,
        AssignTokensDialogComponent,
        CreateClassroomDialogComponent,
        AssignTokensPanelComponent,
        AddStudentDialogComponent,
        AssignClassroomTokensDialogComponent,
        AssignStudentHomeworksDialogComponent,
        ExportStudentsDialogComponent,
        ImportStudentsTabComponent,
        SelectClassroomDialogComponent,
        CollaboratorListComponent,
        MmoActivityListComponent,
        MmoDateListComponent,
        MsmActivityListComponent,
        MsmDateListComponent,
        ColumnsSelectionColumsListComponent,
        InviteCollaboratorDialogComponent,
        ...INTERNAL_COMPONENTS
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        AuthUiModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatChipsModule,
        MatCheckboxModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatDatepickerModule,
        TooltipModule,
        OnboardingUiModule,
        HomeworkModule,
        StudentModule,
        OrganizationUiModule,
        SeatUiModule,
        NgbPopoverModule,
        QRCodeModule,
        ClassroomUiModule,
        DashboardUiModule,
    ],
    exports: [
        
    ],
    providers: [
        DatePipe
    ]
})
export class ClassroomModule { }
