import { EventEmitter, Injectable } from '@angular/core';
import { ApiRoutes, EmailTemplateInfo, RoutesServer, SubscriptionInvoice } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiDirectoryService } from './api-directory.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const EMAIL_TEMPLATE_INFO_SERIALIZER = new JsonSerializer(EmailTemplateInfo);

@Injectable({
    providedIn: 'root'
})
export class StudentService {

    onCreateInvoice: EventEmitter<SubscriptionInvoice> = new EventEmitter();

    constructor(private auth: AuthService,
        private dirService: ApiDirectoryService,
        private http: HttpClient) { }

    doesUsernameExists(username: string): Observable<boolean> {
        const encoded_username = encodeURIComponent(username);

        return this.dirService.serverGetResponse(RoutesServer.Api, ApiRoutes.Student, `/checkUserName?username=${encoded_username}`, { withCredentials: true, observe: 'response'}).pipe(
            map((r) => {
                if(r.status == 404) {
                    return false;
                }
                else if(r.status == 204) {
                    return true;
                }
                else if(r.status == 200) {
                    return (r.body as any).exists;
                }
                
                throw r;
            })
        );    
    }

}
