import { Subscription } from "rxjs";


/**
 * An utility class to manage a list of subscription with less code.
 */
export class RxSubscriptionList {

    private subsList: Subscription[] = [];
    private subsDict: {[key: string]: Subscription} = {};

    add(sub: Subscription) {
        this.subsList.push(sub);
    }

    /**
     * Set a subscription with an associated key (to be able to release it with a key).
     * 
     * @param key Subscrpition key.
     * @param sub The subscription. 
     */
    set(key: string, sub: Subscription) {
        this.subsDict[key] = sub;
    }

    releaseKey(key: string) {
        const sub = this.subsDict[key];
        if(sub) {
            sub.unsubscribe();
            delete this.subsDict[key];
        }
    }

    releaseAll() {
        for (const sub of this.subsList) {
            sub.unsubscribe();
        }

        this.subsList = [];

        for(const key of Object.keys(this.subsDict)) {
            this.subsDict[key].unsubscribe();
        }

        this.subsDict = {};
    }
}