import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization, UpdateOrgQuery } from '@applogic/model';
import { OrganizationService } from 'src/app/organization/organization.service';
import { LoadingService } from 'src/app/services/loading.interceptor';
import PlaceResult = google.maps.places.PlaceResult;
import { GoogleMapsService } from 'src/app/address-ui/google-maps.service';
import { AngularUtils } from 'src/app/services/angular-utils';
import { AddressFormComponent } from 'src/app/address-ui/address-form/address-form.component';
import { Model } from '@uon/model';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { CustomFormBuilder } from 'src/app/core/form-builder/custom-form-builder';
import { CustomFormUtils } from 'src/app/core/form-builder/custom-form-utils';
import { AddressNameInputResult } from 'src/app/address-ui/address-name-input/address-name-input.component';

interface CreateOrUpdateOrganizationDialogData {
    org?: Organization
}

@Component({
    selector: 'app-create-or-update-organization-dialog',
    templateUrl: './create-or-update-organization-dialog.component.html',
    styleUrls: ['./create-or-update-organization-dialog.component.scss']
})
export class CreateOrUpdateOrganizationDialogComponent implements OnInit {

    org: Organization;

    billingAddressFormData: any;

    errorObject: any;

    @ViewChild("billingAddressForm", { static: true })
    billingAddressForm: AddressFormComponent;

    form = new CustomFormBuilder();


    constructor(@Inject(MAT_DIALOG_DATA) public data: CreateOrUpdateOrganizationDialogData,
        private mdDialogRef: MatDialogRef<CreateOrUpdateOrganizationDialogComponent, Organization>,
        private orgService: OrganizationService,
        readonly loading: LoadingService,
        public googleAddressService: GoogleMapsService) {
        this.org = this.data.org;

        const nameField = this.form.addFormControlTemplate("name", 0, {
            label: $localize`:@@common-name:Name`,
            useMatFormField: true
        });

        CustomFormUtils.AddOrgTypeField(this.form);
    }

    ngOnInit(): void {
        if(this.org && this.org.billingAddress) {
            this.form.patch({
                name: this.org.name,
                type: this.org.type,
                typeOther: this.org.typeOther
            });
            this.billingAddressFormData = this.org.billingAddress;
        }
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: CreateOrUpdateOrganizationDialogData = {}, config: AppDialogConfig = {}) {
        return new AppDialogRef<CreateOrUpdateOrganizationDialogComponent, CreateOrUpdateOrganizationDialogData, Organization>(dialogService, dialog, CreateOrUpdateOrganizationDialogComponent, {
            data,
            disableClose: true,
            closeOnNavigation: true,
            maxWidth: '700px',
            width: "700px",
            panelClass: 'makeItMiddle'
        }, config);
    }

    submitNewOrg() {
        this.errorObject = undefined;

        let form: any = this.form.value;
        form.billingAddress = this.billingAddressForm.formValue;

        this.orgService.createOrg(form).subscribe((org) => {
            this.mdDialogRef.close(org);
        }, (err) => {
            this.errorObject = err;
        });
    }

    submitUpdateOrg() {
        this.errorObject = undefined;

        let form: UpdateOrgQuery = this.form.value;
        if(form.name) {
            form.name = form.name.trim();
        }
        form.billingAddress = this.billingAddressForm.formValue;
        this.orgService.updateOrg(this.org.id, form).subscribe({
            next: () => {
                Model.Assign(this.org, form);
                this.mdDialogRef.close(this.org);
            }, error: (err) => {
                this.errorObject = err;
            }
        });
    }

    showDebugs(event) {
        AngularUtils.showFormState("Billing Address Form", this.billingAddressForm?.addressForm.formGroup);
        AngularUtils.showFormState("Organization Form", this.form.formGroup);
        
    }

    onAddressSelect(event: AddressNameInputResult) {
        this.billingAddressForm.onAutocompleteSelected(event.placeResult);
        this.form.patch({
            name: event.placeResult?.name
        });
    }
}
