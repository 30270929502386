import { Directive } from '@angular/core';

@Directive({
    selector: 'design-card-footer',
    host: {
        'class': 'design-card-footer'
    }
})
export class DesignCardFooterDirective {

    constructor() { }

}
