import { CurrencyPipe } from '@angular/common';
import { Injector, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Currency, CurrencyOptions } from '@applogic/model';
import { LanguageService } from '../services/language.service';
import { ProductService } from '../services/product.service';
import { ApCurrencyCommonPipe } from './apcurrency-common.pipe';

@Pipe({
    name: 'apCurrency'
})
export class ApCurrencyPipe extends ApCurrencyCommonPipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer, languageService: LanguageService, private currencyPipe: CurrencyPipe, private productService: ProductService) {
        super(languageService);
    }

    public static instantiateFromInjector(injector: Injector)
    {
        const pipe = new ApCurrencyPipe(injector.get(DomSanitizer), injector.get(LanguageService), injector.get(CurrencyPipe), injector.get(ProductService));
        return pipe;
    }

    transform(amount: number, currencyVal: string | Currency, options?: CurrencyOptions): string {
        // If the currency code is not set yet just print nothing.
        if (!currencyVal) return "";

        if(amount == undefined) {
            return "";
        }

        if (currencyVal instanceof Currency) {
            const currency: Currency = currencyVal as Currency;
            if (currency) {
                return currency.toText(amount, this.fillOptions(options));
            }
        }
        else {
            const currencyCode: string = currencyVal as string;
            const currency = this.productService.getCurrencyFromCache(currencyCode);
            if (currency) {
                return currency.toText(amount, this.fillOptions(options));
            }

            console.error("Unable to retrieve the currency object for '" + currencyCode + "' so fallback to angular currency pipe.");
            return this.currencyPipe.transform(amount, currencyCode);
        }
    }



}
