import { Component, Injector, OnInit, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { BaseGameProgressComponent } from '../base-game-progress/base-game-progress.component';
import * as moment from "moment-timezone";
import { GameProgressSelectableColumn } from '../game-progress-selectable-column';
import { ExpectedField, GameProgressionQuery, GameProgressListInfo, OutputStructure, WordListSubType } from '@applogic/model';
import { MatTabGroup } from '@angular/material/tabs';
import { MMOActivityProgressRow, MMOActivityTableRow, MMODatesProgressRow, MMODatesTableRow, MMOChip } from './mmo-game-progress.model';
import { MmoActivityListComponent } from './mmo-activity-list/mmo-activity-list.component';
import { MmoDateListComponent } from './mmo-date-list/mmo-date-list.component';
import { MMOGameProgressUtils } from './mmo-game-progress.utils';
let numInstances = 0;



@Component({
    selector: 'app-mmo-game-progress',
    templateUrl: './mmo-game-progress.component.html',
    styleUrls: ['../base-game-progress/base-game-progress.component.scss', './mmo-game-progress.component.scss']
})
export class MmoGameProgressComponent extends BaseGameProgressComponent implements OnInit, OnChanges {
    MMOGameProgressUtils = MMOGameProgressUtils;

    @ViewChild(MmoActivityListComponent, {static: false}) activityList: MmoActivityListComponent;
    @ViewChild(MmoDateListComponent, { static: false }) dateList: MmoDateListComponent;
    
    public instanceId: number;

    colorArray = [
        "#468c12",
        "#d400c5",
        "#FFA500",
        "#2E4053",
        "#F70400",
        "#1300F7",
        "#C70039",
    ];

    @ViewChild(MatTabGroup, { static: false }) tab:MatTabGroup;

    constructor(injector: Injector) {
        super(injector);
        this.currentAppGameCode = "MMO";
        this.instanceId = numInstances++;

        this.currentDate = new Date();

        this.translations["en"] = {};
        this.translations["fr"] = {};
        this.translations["es"] = {};

        this.translations["en"]["MMO_CUP_TITLE"] = "Win the cup for this game!";
        this.translations["fr"]["MMO_CUP_TITLE"] = "Gagnez la coupe pour ce jeu!";
        this.translations["es"]["MMO_CUP_TITLE"] = "¡Gana la copa por este juego!";
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.classroomService.isRefreshValue.subscribe((response) => {
            if (this.isSelectedDate == false) {
                this.updateProgressByActivity();
            } else {
                this.updateProgressByDate();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (this.currentGame !== this.currentAppGameCode) return;

        this.setLoading(true);

        setTimeout(() => {
            if (this.classStudent.length > 0) {
                if (this.isSelectedDate == true) {
                    this.setCurrentDate();
                }

                this.refreshAllData().finally(() => {
                    this.setLoading(false);
                });
            }
        });
    }

    /**
     * Update the progress table to display progression by date
     */
    updateProgressByDate() {
        if(!this.dateList) {
            setTimeout(() => {
                this.updateProgressByDate();
            }, 100);
            return;
        }

        this.setLoading(true);
        this.unselectAllColumns();
        let student = this.classStudent;
        this.selectedSeason = undefined;
        const studentId = [];
        let dateTableData = {};
        let finalDateArray: MMODatesTableRow[] = [];
        let listIds = [];

        const startDate = moment(this.EndDate).startOf("day").utc().format();
        const EndDate = moment(this.startDate).endOf("day").utc().format();
        this.dailyColumns = this.progressTableService.getDailyDatesColumns(startDate, EndDate);
        this.dailyColumnsKey = this.dailyColumns.map(c => c.key);

        if(this.dateList) {
            this.dateList.setDateRange(startDate, EndDate);
        }
        else {
            console.error("date list not initialized");
        }
        

        let wordListArray: GameProgressListInfo[] = JSON.parse(JSON.stringify(this.appWordLists));
        const found = this.columns.find(
            (prog) => prog.isSelectedValue == false
        );
        if (found) {
            const listsByGameNo: Map<number, GameProgressListInfo[]> = this.progressTableService.groupBy(
                wordListArray,
                (worddata) => worddata.gameNo
            );

            this.columns.forEach((column: GameProgressSelectableColumn) => {
                if (column.header) return;

                if (column.isSelectedValue === true) {

                    const data = listsByGameNo.get(column.no);
                    const sortedData = MMOGameProgressUtils.sortingData(data);
                    sortedData.map((item, k) => {
                        listIds.push(item._id);
                    });
                }
            });
        }
        student.map((item, i) => {
            const dateTableData: MMODatesTableRow = {} as any;

            studentId.push(item.id);
            dateTableData.student_name = item.fullName;
            dateTableData.student_id = item.id;
            dateTableData.dates = {};
            this.dailyColumns.forEach((c) => {
                if(c.header) return;

                dateTableData.dates[c.key] = [];
            });
            finalDateArray.push(dateTableData);
        });

        let keys: any = {
            studentId: studentId
        };
        if (listIds.length > 0) {
            keys["listId"] = listIds;
        }

        this.retrieveProgress(keys).then((data: any) => {
            this.mergeProgressByDateToTable(data, finalDateArray);
            this.setLoading(false);
        }).catch((ex) => {
            console.error(ex);
            this.setLoading(false);
        });
    }



    /**
     * Merge the progression data (by date) to the table struture.
     * 
     * @param progressData
     * @param table
     */
    mergeProgressByDateToTable(progressData: any, table: MMODatesTableRow[]) {
        let tableRows: MMODatesTableRow[] = JSON.parse(JSON.stringify(table));
        let responseArray: MMODatesProgressRow[] = JSON.parse(JSON.stringify(progressData));
        let wordListArray: GameProgressListInfo[] = [];
        wordListArray = JSON.parse(JSON.stringify(this.appWordLists)) as GameProgressListInfo[];

        // TODO: Why doing this here?!?
        this.refreshSelectedColumns();

        // For each student.
        tableRows.map((tableRow, index) => {

            // Match the corresponding data for this student.
            let studentDataRow = responseArray.find(
                (res) => res.studentId === tableRow.student_id
            );
            if (!studentDataRow) return;
            
            // For each student data.
            studentDataRow.cols.map((itemj, j) => {

                // Find the column that match the student data.
                let column: GameProgressSelectableColumn = this.columns.find(
                    (game) => game.no === itemj.gameNo
                );

                if (column) {
                    if (column.isSelectedValue == true) {
                        let listMatched: GameProgressListInfo = wordListArray.find(
                            (list) => list._id === itemj.listId
                        );

                        if (listMatched) {
                            // For each date.
                            for (let key in tableRow.dates) {
                                let games = tableRow.dates[key];
                                let dateMatched = itemj.dates.filter(
                                    (dated) => moment(dated).format("D/M/YY") === key
                                );
                                if (dateMatched && dateMatched.length > 0) {
                                    let gameNo: number = column.no;

                                    // Find the table cell associate with the game number.
                                    let cell = games.find((item) => item.gameNo == gameNo);

                                    if (!cell) {
                                        cell = { gameNo, chips: [] };
                                        games.push(cell);
                                    }

                                    // Find the chip within the cell associate with a specific list.
                                    // TODO: Why not using the list id instead?
                                    let chip: MMOChip = cell.chips.find((item) => (item.pos == listMatched.pos) && (item.subtype == listMatched.subtype));
                                    //let chip = cell[gameNo].find((item) => item.listId == listMatched._id);

                                    if (!chip) {
                                        chip = new MMOChip();
                                        chip.setList(listMatched);
                                        chip.name = this.localizeString(chip.name);
                                        chip.gameNo = gameNo;
                                        chip.attempts = itemj.success;
                                        chip.color_code = column.color_code;
                                        chip.isPlay = true;
                                        chip.completedlevels = [];
                                        chip.label = listMatched.subtype == WordListSubType.cup ? "🏆" : listMatched.pos.toString();
                                        
                                        cell.chips.push(chip);

                                        this.onChipDataAdded(chip);
                                    }
                                    else {
                                        chip.attempts += itemj.success;
                                    }

                                    chip.completedlevels.push(itemj.levelNo);

                                    games.map((dateData, c) => {
                                        dateData.chips.map((gameList, i) => {
                                            if (gameList.levels.length > 1) {
                                                if (
                                                    gameList.levels.length ===
                                                    gameList.completedlevels.length
                                                ) {
                                                    gameList.completedlevelssublevel = true;
                                                } else {
                                                    gameList.completedlevelssublevel = false;
                                                }
                                            } else {
                                                gameList.completedlevelssublevel = true;
                                            }
                                        });
                                    });
                                }
                            }
                        }
                    }
                }
            });

        });

        this.dateList.setProgressData(tableRows, this.selectedSeason);
    }


    /**
     * Update the progress table to display progression by activity (or wordlist).
     */
    updateProgressByActivity() {
        if(!this.activityList) {
            setTimeout(() => {
                this.updateProgressByActivity();
            }, 100);
            return;
        }

        // TODO: Only needs to be done once.
        this.activityList.setActivities(this.appActivities, this.currentLanguageType);

        this.updateColumns();
        let studentData: MMOActivityTableRow[] = [];
        let studentIds = [];
        this.selectedSeason = undefined;
        let wordListArray: GameProgressListInfo[] = [];
        wordListArray = JSON.parse(JSON.stringify(this.appWordLists));
        const grouped: Map<number, GameProgressListInfo[]> = this.progressTableService.groupBy(
            wordListArray,
            (worddata) => worddata.gameNo
        );

        for (var j = 0; j < this.classStudent.length; j++) {
            const student = this.classStudent[j];
            const studentId: string = student.id;
            const row: MMOActivityTableRow = {
                student_id: studentId,
                student_name: student.fullName,
                games: {}
            };
            studentIds.push(studentId);
            for (var i = 0; i < this.columns.length; i++) {
                if (this.columns[i].header) continue;

                let gameNo: any = this.columns[i].no;

                const data = grouped.get(gameNo);
                MMOGameProgressUtils.sortingData(data);

                const chips: MMOChip[] = [];

                data.forEach((list: GameProgressListInfo) => {
                    const chip: MMOChip = new MMOChip();
                    chip.setList(list);
                    chip.name = this.localizeString(chip.name);
                    chip.color_code = this.columns[i].color_code;
                    chip.attempts = 0;
                    chip.completedlevels = [];
                    chip.isPlay = false;
                    chip.label = chip.subtype == WordListSubType.cup ? "🏆" : chip.pos?.toString();

                    chips.push(chip);
                });

                MMOGameProgressUtils.sortingData(chips);
                
                row.games[gameNo] = chips;
            }
            studentData.push(row);
        }

        this.setLoading(true);

        this.retrieveProgress({ studentId: studentIds }).then((data: any) => {
            setTimeout(() => {
                this.mergeProgressByActivityToTable(data, studentData);
                this.setLoading(false);
            }, 100);
        }).catch((ex) => {
            console.error(ex);
            this.setLoading(false);
        });
    }

    /**
     * Merge the progression data (by activity or wordlist) to the table struture.
     * 
     * @param progressData
     * @param table
     */
     mergeProgressByActivityToTable(progressData: any, table: MMOActivityTableRow[]) {
        this.setLoading(true);
        let studentTableRows: MMOActivityTableRow[] = JSON.parse(JSON.stringify(table));
        let progressArray: MMOActivityProgressRow[] = progressData;
        studentTableRows.map((studentTableRow, i) => {
            let studentDataRow = progressArray.find((item) => studentTableRow.student_id === item.studentId);

            if (!studentDataRow) return;

            // For each student data.
            studentDataRow.cols.map((itemj, j) => {
                // Find the column that match the student data.
                let column: GameProgressSelectableColumn = this.columns.find(
                    (diff) => diff.no === itemj.gameNo
                );

                if (column) {
                    let data = studentTableRow.games[column.key];

                    if (typeof data !== "string") {
                        data.filter((chip) => {
                            if (chip.listId === itemj.listId) {
                                if (chip.levels.length > 1) {
                                    chip.levels.filter((sublevelid) => {
                                        if (sublevelid == itemj.levelNo) {
                                            chip.completedlevels.push(itemj.levelNo);
                                        }
                                    });
                                    if (chip.levels.length === chip.completedlevels.length) {
                                        chip.isPlay = true;
                                        // TODO: Why assigning? Shouldn't we sum the number of success here?
                                        chip.attempts = itemj.success;
                                    }
                                } else {
                                    chip.isPlay = true;
                                    chip.attempts = itemj.success;
                                }

                                this.onChipDataAdded(chip);
                            }
                        });
                    }
                }

            });
        });

        this.activityList.setProgressData(studentTableRows, this.selectedSeason);

        // Let the change for the loading bar to appear briefly if it is too fast.
        setTimeout(() => {

            this.setLoading(false);
        }); 
    }

    /**
     * Retrieve the progress
     * 
     * @param keys The object to filter the result.
     * 
     * @returns {any} Returns the object that contains the progress.
     */
    retrieveProgress(keys: any) {
        keys.language = this.currentLanguageType;

        return new Promise((resolve, reject) => {
            if (this.isSelectedDate == false) {
                const SEND_API_DATA: GameProgressionQuery = {
                    groups: [
                        {
                            "label": "rows",
                            "keys": ["studentId"]
                        },
                        {
                            "label": "cols",
                            "keys": ["listId", "gameNo", "levelNo", "type", "subtype"]
                        }
                    ],
                    gameId: this.currentAppGameCode.toLowerCase(),
                    format: OutputStructure.Table,
                    keys: keys,
                    expected: [ExpectedField.Success],
                };

                this.progressTableService.getProgressGameDetails(SEND_API_DATA)
                    .subscribe((response) => {
                        resolve(response);
                    }, (ex) => {
                        reject(ex);
                    });
            } else {
                const startDate = moment(this.EndDate).startOf("day").utc().format();
                const EndDate = moment(this.startDate).endOf("day").utc().format();

                let SEND_API_DATA: GameProgressionQuery = {
                    groups: [
                        {
                            label: "rows",
                            keys: ["studentId"],
                        },
                        {
                            label: "cols",
                            keys: ["listId", "gameNo", "levelNo", "type", "subtype"],
                        },
                    ],
                    gameId: this.currentAppGameCode.toLowerCase(),
                    format: OutputStructure.Table,
                    keys: keys,
                    from: startDate,
                    to: EndDate,
                    timezone: moment.tz.guess(),
                    expected: [ExpectedField.Success, ExpectedField.Dates],
                };

                this.progressTableService.getProgressGameDetails(SEND_API_DATA)
                    .subscribe((response) => {
                        resolve(response);
                    }, (ex) => {
                        reject(ex);
                    });
            }
        });
    }

    /**
     * On Check and Unchecked Function Call
     */
    onColumnCheckboxChanged(column: GameProgressSelectableColumn) {
        if (column.isSelectedValue == true) {
            this.unselectColumn(column);
            column.isSelectedValue = false;
            if (this.isSelectedDate == true) {
                this.updateProgressByDate();
            }
        } else {
            column.isSelectedValue = true;
            this.selectColumn(column);
            if (this.isSelectedDate == true) {
                this.updateProgressByDate();
            }
        }

        this.progressTableService.saveColumnsState(
            this.currentAppGameCode,
            this.currentLanguageType,
            this.columns
        );

        if(this.activityList) {
            this.activityList.updateColumnsSelection();
        }
    }

    // Remove Chip Function
    removeProgressGameChip(column: GameProgressSelectableColumn) {
        column.isSelectedValue = false;
        this.unselectColumn(column);
        this.progressTableService.saveColumnsState(
            this.currentAppGameCode,
            this.currentLanguageType,
            this.columns
        );
        if (this.isSelectedDate == true) {
            this.updateProgressByDate();
        }
        if(this.activityList) {
            this.activityList.updateColumnsSelection();
        }
    }

    /**
     * Triggered when the app words lists (this.appWordLists) is updated.
     */
    protected onAppWordListsUpdate(): void {

    }

    /**
     * Triggered when the app activities list (this.appActivities) is updated.
     */
    protected onAppActivitiesUpdate(): void {
        this.columns = [];
        let column: GameProgressSelectableColumn = new GameProgressSelectableColumn();
        column.isSelectedValue = true;
        column.isSelectable = false;
        column.header = true;
        column.key = 'student_name';
        column.name = 'Student Name';
        column.order = 1;

        this.columns.push(column);

        let count: number = 1;

        this.appActivities.map((item, index) => {
            let column = new GameProgressSelectableColumn();
            column.key = item.no.toString();
            column.id = column.key;
            column.no = item.no;
            column.name = item.name;
            column.color_code = this.colorArray[index % this.colorArray.length];
            column.order = ++count;
            
            if (column.isSelectedValue === undefined) {
                column.isSelectedValue = true;
            }
            this.columns.push(column);
        });

        this.progressTableService.loadColumnsState(this.currentGame, this.currentLanguageType, this.columns);
    }

    localizeString(str: string) {
        const languageDict = this.translations[this.currentLanguageType];
        if(languageDict) {
            const val = languageDict[str];
            if(val) {
                return val;
            }
        }
        
        return str;
    }
}
