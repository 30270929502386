import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { MatButtonModule } from '@angular/material/button';
import { SeatUiModule } from '../seat-ui/seat-ui.module';
import { StudentListPrintPageComponent } from './student-list-print-page/student-list-print-page.component';
import { StudentListComponent } from './student-list/student-list.component';
import { CoreModule } from '../core/core.module';



@NgModule({
    declarations: [
        StudentListPrintPageComponent,
        StudentListComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        AuthUiModule,
        SeatUiModule,
        CoreModule,
    ],
    exports: [
        StudentListComponent,
    ]
})
export class StudentModule { }
