import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DialogComponent } from '../../dialog.component';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-dialog-stepper',
    templateUrl: './dialog-stepper.component.html',
    styleUrl: './dialog-stepper.component.scss'
})
export class DialogStepperComponent<T> {
    ApplogicUtils = ApplogicUtils;
    
    @Input()
    currentStep: number = 0;

    @Input()
    stepCount: number = 1;

    @ViewChild('leftTitleTemplate', { static: true })
    set leftTitleTemplate(template: TemplateRef<any>) {
        this.dialog.leftTitleTemplate = template;
    }

    @ViewChild('rightTitleTemplate', { static: true })
    set rightTitleTemplate(template: TemplateRef<any>) {
        this.dialog.rightTitleTemplate = template;
    }

    @ViewChild('belowHeaderTemplate', { static: true })
    set belowHeaderTemplate(template: TemplateRef<any>) {
        this.dialog.belowHeaderTemplate = template;
    }

    @Output()
    onPrevClick = new EventEmitter();

    @Output()
    onShowDebugs = new EventEmitter<any>();

    constructor(private dialog: DialogComponent<T>) {

    }

    
    prevStep() {
        this.onPrevClick.emit();
    }

    showDebugs(event: any) {
        this.onShowDebugs.emit(event);
    }
}
