import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Classroom } from '@applogic/model';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

export interface SelectClassroomDialogData {
    orgId: string;
    classroomIdToExclude?: string;
}

@Component({
    selector: 'app-select-classroom-dialog',
    templateUrl: './select-classroom-dialog.component.html',
    styleUrls: ['./select-classroom-dialog.component.scss']
})
export class SelectClassroomDialogComponent implements OnInit {

    errorObject: any;

    selectedClassroom: Classroom;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SelectClassroomDialogData,
        private mdDialogRef: MatDialogRef<SelectClassroomDialogComponent, Classroom>) { }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: SelectClassroomDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<SelectClassroomDialogComponent, SelectClassroomDialogData, Classroom>(dialogService, dialog, SelectClassroomDialogComponent, {
            data,
            disableClose: true,
            closeOnNavigation: true,
            width: '600px',
            maxWidth: '100vw'
        },
        config);
    }

    select() {
        this.mdDialogRef.close(this.selectedClassroom);
    }
}
