import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface UrlViewerDialogData {
    title: string,
    url: any
}

@Component({
    selector: 'app-url-viewer-dialog',
    templateUrl: './url-viewer-dialog.component.html',
    styleUrls: ['./url-viewer-dialog.component.scss']
})
export class UrlViewerDialogComponent implements OnInit {

    title: string;
    url: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: UrlViewerDialogData,
    private mdDialogRef: MatDialogRef<UrlViewerDialogComponent>,
    protected sanitizer: DomSanitizer) {
        this.title = data.title;
        // this.url = this.sanitizer.sanitize(SecurityContext.URL, data.url)
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: UrlViewerDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<UrlViewerDialogComponent, UrlViewerDialogData>(dialogService, dialog, UrlViewerDialogComponent, {
            data,
            width: '100vw',
            maxWidth: '800px',
            height: '90%'
        }, config);
    }
}
