import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

let apiLoaded = false;

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {


    videos: any[] = [];

    videoUrl: SafeResourceUrl;

    @ViewChild("showVideoDialog", { static: true })
    showVideoDialog: TemplateRef<any>;
    showVideoDialogRef: MatDialogRef<any>;

    constructor(readonly dialog: MatDialog,
        private _sanitizer: DomSanitizer,
        private languageService: LanguageService,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        let video: any;

        if (this.languageService.currentLanguageCode == "fr") {
            video = {};
            video.id = "UYbS-fl2Uco";
            video.label = "1 - Introduction";
            this.videos.push(video);

            video = {};
            video.id = "N2xYDk2ctGs";
            video.label = "2 - Inviter ou retirer un membre à une organisation";
            this.videos.push(video);

            video = {};
            video.id = "F0mV3nveppA";
            video.label = "3 - Créer une classe";
            this.videos.push(video);

            video = {};
            video.id = "sC5Yt1NPd-U";
            video.label = "4 - Inviter ou retirer un collaborateur à une classe";
            this.videos.push(video);

            video = {};
            video.id = "YH2Jbsxqe9U";
            video.label = "5 - Ajouter ou retirer des élèves à une classe";
            this.videos.push(video);

            video = {};
            video.id = "yhFSm1_1l9k";
            video.label = "6 - Suivre le progrès de mes élèves";
            this.videos.push(video);

            video = {};
            video.id = "5b-eeWdGG9I";
            video.label = "7 - Supprimer une classe de façon définitive";
            this.videos.push(video);

            video = {};
            video.id = "bUyoJpfp2BI";
            video.label = "8 - Obtenir une soumission pour nos produits";
            this.videos.push(video);

            video = {};
            video.id = "CHhYScFLsSo";
            video.label = "9 - Acheter un abonnement";
            this.videos.push(video);

            video = {};
            video.id = "03wORtppwFA";
            video.label = "10 - Consulter mes abonnements";
            this.videos.push(video);

            video = {};
            video.id = "INwm3BwrXAQ";
            video.label = "11 - Modifier mes informations";
            this.videos.push(video);
        }
        else if (this.languageService.currentLanguageCode == "en") {
            video = {};
            video.id = "cAft0DKJegw";
            video.label = "1 - Introduction to the Teacher Support Portal";
            this.videos.push(video);

            video = {};
            video.id = "4ax-Wt11z2o";
            video.label = "2 - Managing the members of an organization";
            this.videos.push(video);

            video = {};
            video.id = "tXMhe4plM1Y";
            video.label = "3 - Creating a classroom";
            this.videos.push(video);

            video = {};
            video.id = "HnTa9RBEI7o";
            video.label = "4 - Managing collaborators to your classroom";
            this.videos.push(video);

            video = {};
            video.id = "s06qLgcLKR4";
            video.label = "5 - Managing the students in your classroom";
            this.videos.push(video);

            video = {};
            video.id = "b_9BV9u_efs";
            video.label = "6 - Following your students' progress";
            this.videos.push(video);

            video = {};
            video.id = "lBFXzvAR-lk";
            video.label = "7 - Deleting a classroom permanently";
            this.videos.push(video);

            video = {};
            video.id = "jpJbAbU9JIE";
            video.label = "8 - Obtaining a quotation for our products";
            this.videos.push(video);

            video = {};
            video.id = "5Kc5piQE2Cw";
            video.label = "9 - Purchasing subscriptions";
            this.videos.push(video);

            video = {};
            video.id = "94MSrtthxWE";
            video.label = "10 - About subscriptions";
            this.videos.push(video);

            video = {};
            video.id = "U8syWalNdv0";
            video.label = "11 - Updating your account";
            this.videos.push(video);
        }

        let show = this.route.snapshot.queryParamMap.get("show");

        if(show) {
            let showIndex = parseInt(show);

            let video = this.videos[showIndex-1];
            if(video) {
                this.showVideo(video.id);
            }
        }
    }

    showVideo(youtubeVideoId: string) {

        let width: string = "min(100vw, 100vh / (9 / 16)) - 64px";
        let height: string = "(" + width + ") * (9 / 16)";
        width = "calc(" + width + ")";
        height = "calc(" + height + ")";
        this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`);
        this.showVideoDialogRef = this.dialog.open(this.showVideoDialog, {
            data: { url: this.videoUrl, id: youtubeVideoId },
            width: width,
            maxWidth: width,
            minWidth: width,
            maxHeight: height,
            height: height
            // position: {
            //     top: "52px",
            // }
        });

        if (!apiLoaded) {
            // This code loads the IFrame Player API code asynchronously, according to the instructions at
            // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            apiLoaded = true;
        }
    }

    closeVideo() {
        if (this.showVideoDialogRef) {
            this.showVideoDialogRef.close();
        }
    }

    goToRoute(str: string): void {

    }
}
