import {Pipe, PipeTransform} from '@angular/core';
import { StringUtils } from '@applogic/model';


export interface RelativeTimeConfig {
    showSeconds?: boolean;
    short?: boolean; // If true, doesn't display the "left" or "ago" suffix.
    showExpired?: boolean; // Show text "expired" if the date is older than the current date.
}

@Pipe({
	name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

	constructor() {

	}

	transform(value: Date, configs?: RelativeTimeConfig) {
		if (!(value instanceof Date))
			value = new Date(value);

		let seconds: number;
        
        seconds = Math.floor(((new Date()).getTime() - value.getTime()) / 1000);

        let left: boolean = false;

        if(seconds < 0) {
            left = true;
            seconds = -seconds;
        }
        else if(configs?.showExpired) {
            return $localize`:@@relative-time--expired: expired `;
        }

		let interval: number = Math.floor(seconds / 31536000);

		if (interval > 1) {
            if(configs?.short) {
                return this.getFormatedTime($localize`:@@relative-time--years: [interval] years `, interval);
            }
            else if(left) {
                return this.getFormatedTime($localize`:@@relative-time--years-left: [interval] years left `, interval);  
            }
            else {
                return this.getFormatedTime($localize`:@@relative-time--years-ago: [interval] years ago `, interval);
            }
		}
		interval = Math.floor(seconds / 2592000);
		if (interval > 1) {
            if(configs?.short) {
                return this.getFormatedTime($localize`:@@relative-time--months: [interval] months `, interval);
            }
            else if(left) {
                return this.getFormatedTime($localize`:@@relative-time--months-left: [interval] months left `, interval);
            }
            else {
                return this.getFormatedTime($localize`:@@relative-time--months-ago: [interval] months ago `, interval);
            }
		}
		interval = Math.floor(seconds / 86400);
		if (interval > 1) {
            if(configs?.short) {
                return this.getFormatedTime($localize`:@@relative-time--days: [interval] days `, interval);
            }
            else if(left) {
                return this.getFormatedTime($localize`:@@relative-time--days-left: [interval] days left `, interval);
            }
            else {
                return this.getFormatedTime($localize`:@@relative-time--days-ago: [interval] days ago `, interval);
            }
		}
		interval = Math.floor(seconds / 3600);
		if (interval > 1) {
            if(configs?.short) {
                return this.getFormatedTime($localize`:@@relative-time--hours: [interval] hours `, interval);
            }
            else if(left) {
                return this.getFormatedTime($localize`:@@relative-time--hours-left: [interval] hours left `, interval);
            }
            else {
                return this.getFormatedTime($localize`:@@relative-time--hours-ago: [interval] hours ago `, interval);
            }
		}
		interval = Math.floor(seconds / 60);
		if (interval > 1) {
            if(configs?.short) {
                return this.getFormatedTime($localize`:@@relative-time--minutes: [interval] minutes `, interval);
            }
            else if(left) {
                return this.getFormatedTime($localize`:@@relative-time--minutes-left: [interval] minutes left `, interval);
            }
            else {
                return this.getFormatedTime($localize`:@@relative-time--minutes-ago: [interval] minutes ago `, interval);   
            }
		}
		else {
            if(configs?.short) {
                return this.getFormatedTime($localize`:@@relative-time--seconds: [interval] seconds `, interval);   
            }
            else if(configs?.showSeconds) {
                if(left) {
                    return this.getFormatedTime($localize`:@@relative-time--seconds-left: [interval] seconds left `, interval);    
                }
                else {
                    return this.getFormatedTime($localize`:@@relative-time--seconds-ago: [interval] seconds ago `, interval);    
                }
            }
            return $localize`:@@relative-time--just-now: just now `;
        }
	}

    getFormatedTime(timeLabel: string, interval: number) {
        timeLabel = timeLabel.replace("[interval]", "{interval}");
        return StringUtils.ParseVariables(timeLabel, {interval});
    }

}
