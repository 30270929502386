import { Model, ID, Member, ArrayMember } from "@uon/model";

@Model()
export class Word {

    @ID()
    id: string;

    @Member()
    word: string;

    // cgram is not always an array in DB, must coerse to array type, somewhere...
    @ArrayMember(String)
    cgram: string[];

    @Member()
    gender: string;

    @Member()
    number: string;

    @Member()
    phon: string;

    // Object that contains the source phonem codage for each database.
    @Member()
    phons: object;

    @Member()
    nbletters: number;

    @Member()
    nbsyll: { oral: number, written: number };

    @Member()
    nbphon: number;

    @Member()
    gemin: number;

    @Member()
    sentence: string;

    @Member()
    consistency: number;

    @Member()
    success: object;

    @Member()
    frequence: object;

    @ArrayMember(String)
    files: string[];

    @Member()
    seg: { pg: string, gp: string };

    @Member()
    mathword: boolean;

    @Member()
    rhyme: { ortho: string, phon: string, match: string };

}