import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomFormGroup, GroupFormConfig } from './custom-form-group';
import { CustomFormField } from './custom-form-field';
import { FormService } from 'src/app/services/form.service';
import { FieldValidationType, ValidationFieldError } from '@applogic/model';
import { BaseConfig, CountryFieldConfig, FieldType, FormControlConfig, FormGroupTemplateFieldConfig, SeparatorConfig } from './custom-form-field-config';
import { AngularUtils } from 'src/app/services/angular-utils';
import { HeadingTextFieldConfig } from './fields/custom-form-heading-text-field/custom-form-heading-text-field.component';
import { InputFieldConfig } from './fields/custom-form-input-field/custom-form-input-field.component';
import { TextAreaFieldConfig } from './fields/custom-form-text-area-field/custom-form-text-area-field.component';
import { SelectFieldConfig } from './fields/custom-form-select-field/custom-form-select-field.component';
import { ToggleFieldConfig } from './fields/custom-form-toggle-field/custom-form-toggle-field.component';
import { FloatLabelType, MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';


export class CustomFormBuilder {

    private _fields: CustomFormField[] = [];
    private _groups: CustomFormGroup[] = [];

    rootGroup: CustomFormGroup;

    private currentGroup: CustomFormGroup;

    private stackedGroup: CustomFormGroup[] = [];

    public formService: FormService;

    public subscriptSizing: SubscriptSizing = 'fixed';
    public floatLabel: FloatLabelType = 'always';
    public appearance: MatFormFieldAppearance = 'outline';

    get value() {
        return this.formGroup.value;
    }

    get valid() {
        return this.formGroup.valid;
    }

    get invalid() {
        return this.formGroup.invalid;
    }

    constructor(public formGroup: FormGroup = new FormGroup({})) {
        this.rootGroup = this.beginGroup();
    }

    beginGroup(key: string = "", config: GroupFormConfig = {}) {

        let control: UntypedFormGroup;
        if (key) {
            control = new FormGroup({});
        }
        else {
            control = this.currentGroup?.formGroup || this.formGroup;
        }

        const group = new CustomFormGroup(key, control, config);

        this._groups.push(group);

        this.addField(group);

        group.form = this;

        if (this.currentGroup) {
            this.stackedGroup.push(this.currentGroup);
        }
        this.currentGroup = group;

        return group;
    }

    endGroup() {
        if (this.stackedGroup.length > 0) {
            this.currentGroup = this.stackedGroup.pop();
        }
        else {
            console.error("The group stack is empty");
        }
    }

    setGroup(key: string) {
        let group = this.getGroup(key);
        if (group) {
            this.currentGroup = group;
        }
        else {
            console.error("Cannot select group. Missing group " + key);
        }
    }

    addSeparator(configs: SeparatorConfig = {}) {
        this.filterConfigs(configs);

        const field = new CustomFormField({
            key: "",
            type: FieldType.Separator,
            control: undefined,
            label: configs.label,
            validators: [],
            data: {
                
            }
        });
        this.addField(field);     
    }

    addInput(key: string, configs: InputFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new FormControl('', configs.validators || []);

        let field = new CustomFormField({
            key,
            type: FieldType.Input,
            control,
            label: configs.label,
            validators: configs.validators,
            data: configs
        });

        field.useMatFormField = true;

        this.addField(field);

        return field;
    }

    addTextArea(key: string, configs: TextAreaFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new FormControl('', configs.validators || []);

        let field = new CustomFormField({
            key,
            type: FieldType.TextArea,
            control,
            label: configs.label,
            validators: configs.validators,
            data: configs
        });

        field.useMatFormField = true;

        this.addField(field);

        return field;
    }

    addHeadingText(key: string, configs: HeadingTextFieldConfig) {
        const control = new FormControl('', []);

        let field = new CustomFormField({
            key,
            type: FieldType.HeadingText,
            control: undefined,
            label: "",
            validators: [],
            data: configs
        });

        this.addField(field);

        return field;
    }

    addSelect(key: string, configs: SelectFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new FormControl('', configs.validators || []);

        let fieldType: FieldType;

        if (configs.style == 'right') {
            fieldType = FieldType.RightSelect;
        }
        else if (configs.style == 'radio') {
            fieldType = FieldType.RadioSelect;
        }
        else {
            fieldType = FieldType.Select;
        }

        let field = new CustomFormField({
            key,
            type: fieldType,
            control,
            label: configs.label,
            validators: configs.validators,
            data: configs
        });

        if(fieldType == FieldType.Select) {
            field.useMatFormField = true;
        }

        this.addField(field);

        return field;
    }

    addToggle(key: string, configs: ToggleFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new FormControl('', configs.validators || []);

        const field = new CustomFormField({
            key,
            type: FieldType.Toggle,
            control,
            label: configs.label,
            validators: configs.validators,
            data: configs
        });

        this.addField(field);

        return field;
    }

    addCountry(key: string, configs: CountryFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new FormControl('', configs.validators || []);

        const field = new CustomFormField({
            key,
            type: FieldType.Country,
            control,
            label: configs.label,
            validators: configs.validators,
            data: {
                isRequired: configs.isRequired
            }
        });

        field.useMatFormField = true;

        this.addField(field);

        return field;
    }

    addFormControl(key: string, type: FieldValidationType, configs: FormControlConfig = {}) {

        if (!this.formService) {
            console.error("Form service must be initialized");
            return;
        }

        const control = this.formService.getFormControl(type, undefined, configs.isRequired);

        let inputType: string = 'text';

        if ((type == FieldValidationType.Password) ||
            (type == FieldValidationType.StudentPassword)) {
            inputType = "password";
        }

        const data: any = configs || {};
        data.inputType = inputType;
        data.typeKey = type;

        let field: CustomFormField;
        if (type == FieldValidationType.HomeworkDesc) {
            field = new CustomFormField({
                key,
                type: FieldType.TextArea,
                control,
                label: this.formService.getFormLabel(type),
                validators: configs.validators,
                data
            });
            field.useMatFormField = true;
        }
        else {
            field = new CustomFormField({
                key,
                type: FieldType.Input,
                control,
                label: this.formService.getFormLabel(type),
                validators: configs.validators,
                data
            });
            field.useMatFormField = true;
        }

        this.addField(field);

        return field;
    }

    addFormGroupTemplate(key: string, templateIdx: number, configs: FormGroupTemplateFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new UntypedFormGroup({}, configs.validators || []);

        const field = new CustomFormField({
            key,
            type: FieldType.FormGroupTemplate,
            control,
            label: configs.label,
            validators: configs.validators,
            data: {
                templateIdx
            }
        });

        field.useMatFormField = configs.useMatFormField || false;

        this.addField(field);

        return field;
    }

    addFormControlTemplate(key: string, templateIdx: number, configs: FormGroupTemplateFieldConfig = {}) {
        this.filterConfigs(configs);

        const control = new UntypedFormControl({}, configs.validators || []);

        const field = new CustomFormField({
            key,
            type: FieldType.FormGroupTemplate,
            control,
            label: configs.label,
            validators: configs.validators,
            data: {
                templateIdx
            }
        });

        field.useMatFormField = configs.useMatFormField || false;

        this.addField(field);

        return field;
    }

    private filterConfigs(configs: BaseConfig) {

    }

    private getGroup(key: string) {
        if (!key) {
            key = "";
        }

        let group = this._groups.find(g => g.key == key);

        if (!group) {
            if (key == "") {
                return this.beginGroup("default");
            }
            console.error("Missing group " + key);
            return;
        }

        return group
    }

    private getCurrentGroup() {
        if (!this.currentGroup) {
            this.currentGroup = this.beginGroup("default");
        }

        return this.currentGroup;
    }

    private addField(field: CustomFormField) {

        if(field.useMatFormField) {
            if(field.data?.appearance == undefined) {
                if(!field.data) {
                    field.data = {};
                }

                field.data.appearance = this.appearance;
            }
            if(field.data?.subscriptSizing == undefined) {
                if(!field.data) {
                    field.data = {};
                }

                field.data.subscriptSizing = this.subscriptSizing;
            }
            if(field.data?.floatLabel == undefined) {
                if(!field.data) {
                    field.data = {};
                }

                field.data.floatLabel = this.floatLabel;
            } 
        }

        this._fields.push(field);
        if (this.currentGroup) {
            // Group without a key share the same fields dictionary.
            if (!field.key && (field instanceof CustomFormGroup)) {
                field.fieldDict = this.currentGroup.fieldDict;
            }
            this.currentGroup.addField(field);
        }
        if (field.key && this.currentGroup) {
            if (field.control != this.currentGroup.formGroup) {
                this.currentGroup.formGroup.addControl(field.key, field.control);
            }
        }
    }

    public setError(err: any) {
        if ((err?.error?.type == "body") && (err.error.errors)) {
            const errors: ValidationFieldError[] = err.error.errors;
            let group: CustomFormGroup = this.rootGroup;
            let field: CustomFormField;

            for (const error of errors) {
                for (const key of error.path) {
                    let child = group.getField(key);
                    if (!child) {
                        // child = group.getField(key.toLowerCase());
                    }
                    if (child instanceof CustomFormGroup) {
                        group = child;
                    }
                    else if (child) {
                        field = child;
                    }
                }

                if (field) {
                    let nbErrors: number = 0;
                    let errors: any = {};
                    for (const subErrorKey of Object.keys(error.errors)) {
                        const subError = error.errors[subErrorKey];
                        errors[subErrorKey.toLowerCase()] = subError;
                        nbErrors++;
                    }
                    if (nbErrors > 0) {
                        field.control.setErrors(errors);
                    }
                }
                else {
                    // console.log("## Field not found " + error.path.join("."));
                }
            }
        }
    }

    patch(data: any) {
        this.formGroup.patchValue(data);
    }

    patchChanged(data: any, formGroup: UntypedFormGroup = this.formGroup) {
        let changed: boolean = false;

        if (!data) {
            console.error("Cannot patch undefined data");
            return;
        }
        const testMode = AngularUtils.isTestMode();

        for (const key of Object.keys(data)) {
            const v = data[key];

            if ((v === undefined) || (v === null)) {
                const control = formGroup.controls[key];
                if (control) {
                    control.setValue(v);
                }
                else {
                    console.error("Missing control for key: '" + key + "' with val (obj): '" + JSON.stringify(v) + "'");
                }
            }
            else if (typeof v == 'object') {
                const control = formGroup.controls[key];
                if (control) {
                    const childdFrmGroup = control as UntypedFormGroup;
                    if (childdFrmGroup) {
                        changed = this.patchChanged(v, childdFrmGroup) || changed;
                    }
                    else {
                        changed = true;
                        if (testMode) {
                            console.log("## Key (obj) '" + key + "' changed from '" + JSON.stringify(control.value) + "' to '" + JSON.stringify(v) + "'");
                        }

                        control.setValue(v);
                    }
                }
                else {
                    console.error("Missing control for key: '" + key + "' with val (obj): '" + JSON.stringify(v) + "'");
                }
            }
            else {
                const control = formGroup.controls[key];
                if (control) {
                    if (v != control.value) {
                        changed = true;
                        if (testMode) {
                            console.log("## Key (val) '" + key + "' changed from '" + JSON.stringify(control.value) + "' to '" + JSON.stringify(v) + "'");
                        }
                        control.setValue(v);
                    }
                }
                else {
                    console.error("Missing control for key: '" + key + "' with val: '" + JSON.stringify(v) + "'");
                }
            }
        }

        return true;
    }

    reset(data: any) {
        this.formGroup.reset(data);
    }
}
