import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { CoreModule } from '../core/core.module';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { RouterModule } from '@angular/router';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';

const COMPONENTS = [
    NotFoundPageComponent
];



@NgModule({
    declarations: [...COMPONENTS],
    imports: [
        CommonModule,
        CoreModule,
        AuthUiModule,
        RouterModule,
        DashboardUiModule,
    ],
    exports: [...COMPONENTS]
})
export class NotFoundModule { }
