<app-user-avatar *ngIf="auth.user"
                 [matMenuTriggerFor]="userMenu"
                 style="cursor: pointer;"
                 [size]="36"></app-user-avatar>


<mat-menu #userMenu="matMenu"
          [overlapTrigger]="true"
          class="user-menu">

    <div class="flex-column flex-default-gap app-menu-container">
        <div class="user-menu-header">
            <div class="user-info">{{ auth.user?.fullName }}<br>
                <small [ngbTooltip]="auth.user?.email"
                       container="body">{{ auth.user?.email }}</small>
            </div>
            <app-user-avatar [size]="36"></app-user-avatar>
        </div>

        <mat-divider></mat-divider>

        <div>
            <div mat-menu-item
                 (click)="myAccount()">
                <mat-icon svgIcon="menu-account"></mat-icon>
                <span i18n="@@main-menu-label-account"> Account </span>
            </div>

            <div mat-menu-item
                 (click)="logout()">
                <mat-icon svgIcon="logout"></mat-icon>
                <span i18n="@@common-sign-out">Sign out</span>
            </div>
        </div>
    </div>

</mat-menu>