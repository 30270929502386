import { Component, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationMember, Organization, CommonQueryFilter, User } from '@applogic/model';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonColumnType, CommonListColumn } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { MessageBoxDialogComponent } from 'src/app/core/message-box-dialog/message-box-dialog.component';
import { MessageBoxButton } from 'src/app/core/message-box-dialog/messagebox-button';
import { MessageBoxIcon } from 'src/app/core/message-box-dialog/messagebox-icon';
import { MessageBoxResult } from 'src/app/core/message-box-dialog/messagebox-result';
import { MessageBoxSettings } from 'src/app/core/message-box-dialog/messagebox-settings';
import { OrganizationService } from 'src/app/organization/organization.service';
import { ChangeMemberRoleDialogComponent } from '../change-member-role-dialog/change-member-role-dialog.component';


@Component({
    selector: 'app-organization-member-list',
    templateUrl: './organization-member-list.component.html',
    styleUrl: './organization-member-list.component.scss'
})
export class OrganizationMemberListComponent extends CommonListComponent<OrganizationMember> {

    @Input()
    organization: Organization;
    
    @Input()
    isAdmin: boolean = false;

    protected currentUserId: string;

    // If specified, add a column to display the role of this user.
    @Input()
    userRole: User;

    constructor(
        private injector: Injector,
        private authService: AuthService,
        private organizationService: OrganizationService,
        private router: Router) {
        super(injector);

        this.alwaysShowSelected = true;
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 20];

        // this.rendererOptions.showSelectColumnsTable = true;
        // this.rendererOptions.showSearchBar = true;
        this.supportCompactMode = true;
    }

    ngOnInit(): void {
        this.currentUserId = JSON.parse(localStorage.getItem('applogic/auth/current_user')).id;

        super.ngOnInit();
        this.initColumns();
        this.refreshItems();
    }

    initColumns() {
        this.initPersistentKey('applogic/organization/member-list' + (this.isAdmin ? '-admin' : ''));

        if (this.userRole) {
            this.addColumn("id", "user.id", true, false, CommonColumnType.ID);
        }

        const nameColumn = this.addColumn($localize`:@@common-fullname: Full Name `, "user.fullName", true, !this.compactRow, CommonColumnType.Text, {
            searchable: true
        });
        const emailColumn = this.addColumn($localize`:@@common-email: Email `, "user.email", true, !this.compactRow, CommonColumnType.Text, {
            searchable: true
        });

        if (!this.compactRow) {
            this.addColumn($localize`:@@common-member-since:Member Since`, "addedOn", true, true, CommonColumnType.Date).parameters.format = 'mediumDate';
        }
        else {
            this.addColumn(nameColumn.label + " /" + emailColumn.label, "compactName", true, true, CommonColumnType.MergeColumns, {
                mergeColumns: [nameColumn, emailColumn]
            });
        }

        this.addColumn($localize`:@@common-role:Role`, "role", true, true, CommonColumnType.Template, {
            isSortable: false,
            fixedWidth: "60px"

        });

        if (this.userRole) {
            this.addColumn(this.userRole.fullName, "userRole", false, true, CommonColumnType.Template, {
                isSortable: false,
                fixedWidth: "60px"
            });
        }

        this.addColumn($localize`:@@common-created-on:Created On`, "createdOn", true, false, CommonColumnType.Date).parameters.format = "mediumDate";
        this.addColumn($localize`:@@common-updated-on:Updated On`, "updatedOn", true, false, CommonColumnType.Date).parameters.format = "mediumDate";

        if (this.isAdmin) {
            this.addColumn("", "actions", false, true, CommonColumnType.Action);
        }

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;
        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });
        queryFilter.other.all = false;

        this.setPreloadItems(this.organization?.staffMembers || []);
    }

    onCompactModeChanged() {
        this.initColumns();
    }

    openChangeMemberRoleDialog(member: OrganizationMember) {

        const appDialog = ChangeMemberRoleDialogComponent.createDialog(this.dialogService, this.dialog, {
            orgId: this.organization.id,
            userId: member.user.id,
            currentRole: member.role,
            isInviteRole: false
        });

        appDialog.show().then((role) => {
            member.role = role;
        });
    }

    openRevokeAccessDialog(member: OrganizationMember) {
        const organization = this.organization;
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@org-remove-member-confirm-text: You are about to remove <strong>${member.user.fullName}</strong>'s access to this organization. `,
            $localize`:@@org-remove-member-confirm-text-notice:  Classrooms assigned to this user will remain associated with <strong>${organization.name}</strong>.
            Afterwards, if a class has no administrator, you have to assign someone new to the role. `
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
        ];


        settings.validateBeforeCallback = async (result) => {
            if (result == MessageBoxResult.OK) {
                try {
                    await firstValueFrom(this.organizationService.removeMember(this.organization.id, member.user.id));

                    this.removeItem(member);
                }
                catch (ex) {
                    return ex;
                }
            }
        };
        const appDialog = MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings);

        appDialog.show();
    }


    canShowAction(row: OrganizationMember) {
        return this.isAdmin && (this.currentUserId != row.user?.id);
    }
}
