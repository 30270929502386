import { EventEmitter, Injectable } from '@angular/core';
import { ApiRoutes, CommonQueryFilter, CommonQueryResponse, GetLastSubscriptionQuery, GetLastSubscriptionReponse, GetSubscriptionReponse, OrderForm, RoutesServer, SendSubscriptionAutoRenewNotificationsResponse, SubAllocationsResponse, Subscription, SubscriptionAllocationMode, SubscriptionInvoice, SubscriptionInvoiceState } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ApiDirectoryService } from './api-directory.service';
import { LanguageService } from './language.service';
import { HttpParamsFromModelProperties } from './angular-utils';
import { SeatService } from './seat.service';
import { HandleErrorDialogService } from './handle-error-dialog.service';
import { MessageBoxDialogComponent } from '../core/message-box-dialog/message-box-dialog.component';
import { MessageBoxButton } from '../core/message-box-dialog/messagebox-button';
import { MessageBoxIcon } from '../core/message-box-dialog/messagebox-icon';
import { MessageBoxResult } from '../core/message-box-dialog/messagebox-result';
import { MessageBoxSettings } from '../core/message-box-dialog/messagebox-settings';
import { DialogService } from './dialog.service';
import { MatDialog } from '@angular/material/dialog';


const INVOICE_SERIALIZER = new JsonSerializer(SubscriptionInvoice);
const SUBSCRIPTION_SERIALIZER = new JsonSerializer(Subscription);
const GET_LAST_SUBSCRIPTION_RESPONSE_SERIALIZER = new JsonSerializer(GetLastSubscriptionReponse);
const GET_SUBSCRIPTION_RESPONSE_SERIALIZER = new JsonSerializer(GetSubscriptionReponse);
const SEND_SUBSCRIPTION_AUTO_RENEW_NOTIFICATIONS_RESPONSE_SERIALIZER = new JsonSerializer(SendSubscriptionAutoRenewNotificationsResponse);

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    onCreateInvoice: EventEmitter<SubscriptionInvoice> = new EventEmitter();

    constructor(private auth: AuthService,
        private languageService: LanguageService,
        private dirService: ApiDirectoryService,
        private seatService: SeatService,
        private errorDialogService: HandleErrorDialogService) { }

    /**
     * Retrieve the list of subscription invoices.
     * 
     * @param {CommonQueryFilter} filter The query filter.
     * @param {boolean} details (Optional) Retrieve more details like the organization name.
    */
    getInvoices(filter?: CommonQueryFilter, details: boolean = false) {
        if (details) {
            if (!filter.other) filter.other = {};
            filter.other.details = true;
        }
        let str = filter ? filter.toString2() : '';

        const id = this.auth.user.id;
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/invoices` + str, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<SubscriptionInvoice>();
                response.count = r.count;
                response.result = r.result.map(s => INVOICE_SERIALIZER.deserialize(s, true));
                return response;
            })
        );
    }


    /**
     * Retrieve an invoice.
     * 
     * @param {string} invoiceId The invoice id.
    */
    getInvoice(invoiceId: string) {
        const id = this.auth.user.id;
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/invoice/` + invoiceId, { withCredentials: true }).pipe(
            map((r: any) => {
                return INVOICE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    /**
     * Update an invoice.
     * 
     * @param {string} invoiceId The invoice id.
    */
    updateInvoice(invoiceId: string, state: SubscriptionInvoiceState) {
        const id = this.auth.user.id;
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Subscription, `/invoice/` + invoiceId, {
            invoice: {
                state
            }
        }, { withCredentials: true });
    }

    getSubscriptionInvoicePdf(invoiceNo) {
        const lang = this.languageService.currentLanguageCode;
        return new Promise((resolve, reject) => {
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/invoice/${invoiceNo.trim()}/pdf/${lang}`,
                { withCredentials: true }).subscribe(response => {
                    resolve(response);

                }, (error) => {

                    reject(error);

                })
        })
    }

    /**
     * Create an invoice from an OrderForm.
     * 
     * @param {OrderForm} orderForm (Optional) The order form.
     * @param {string} orgId The organization id.
    */
    createInvoice(orderForm: OrderForm) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Subscription, `/create-invoice`, { order: orderForm }, { withCredentials: true }).pipe(
            map((r: any) => {
                let invoice = INVOICE_SERIALIZER.deserialize(r, true);
                this.onCreateInvoice.emit(invoice);
                return invoice;
            })
        );
    }

    /**
     * Create an invoice from a Quotation.
     * 
     * @param {string} quotationId The quotation id.
     * @param {string} orgId The organization id.
     * @param {string} userId (Optional) The userId for which the invoice will be created. Only admin can do that.
    */
    createInvoiceFromQuotation(quotationId: string, orgId: string, userId?: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Subscription, `/create-invoice`, { quotationId, orgId, userId }, { withCredentials: true }).pipe(
            map((r: any) => {
                let invoice = INVOICE_SERIALIZER.deserialize(r, true);
                this.onCreateInvoice.emit(invoice);
                return invoice;
            })
        );
    }

    /**
     * 
     * 
     * @param {SubscriptionInvoice} invoice The invoice.
    */
     getStripeHostedUrl(invoice: SubscriptionInvoice): Observable<string> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Stripe, `/invoice-url`, {
            invoiceId: invoice.id,
            stripeInvoiceId: invoice.stripeInvoiceId
         }, { withCredentials: true }).pipe(
            map((r: any) => {
                return r?.hostedUrl;
            })
        );
    }

    /**
     * 
     * 
     * @param {SubscriptionInvoice} invoice The invoice.
    */
     getStripeHostedPdf(invoice: SubscriptionInvoice): Observable<string> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Stripe, `/invoice-url`, {
            invoiceId: invoice.id,
            stripeInvoiceId: invoice.stripeInvoiceId
         }, { withCredentials: true }).pipe(
            map((r: any) => {
                return r?.invoicePdf;
            })
        );
    }

    /**
     * 
     * 
     * @param {SubscriptionInvoice} invoice The invoice.
    */
     getStripePdf(invoice: SubscriptionInvoice): Observable<string> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Stripe, `/invoice-pdf`, {
            invoiceId: invoice.id,
            stripeInvoiceId: invoice.stripeInvoiceId
         }, { withCredentials: true }).pipe(
            map((r: any) => {
                return r?.pdfurl;
            })
        );
    }

    searchSubscriptions(filter: CommonQueryFilter) {
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/search${str}`, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<Subscription>();
                response.count = r.count;
                response.result = r.result.map(s => SUBSCRIPTION_SERIALIZER.deserialize(s, true));
                return response;
            })
        );
    }

    exportSubscriptions(filter: CommonQueryFilter, bookType: string) {
        filter.other.bookType = bookType;
        let str = filter.toString2();
        
        this.dirService.observeRoute(RoutesServer.Api, ApiRoutes.Subscription, `/search/export${str}`).subscribe((routeUrl) => {
            window.open(routeUrl);
        });
    }

    getUserLastSubscription(query: GetLastSubscriptionQuery = {}) {
        const httpParams = HttpParamsFromModelProperties(GetLastSubscriptionQuery, query);
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/last`, {
            params: httpParams,
            withCredentials: true
        }).pipe(
            map((r: any) => {
                return GET_LAST_SUBSCRIPTION_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    getSubscription(subId: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/${subId}`, { withCredentials: true }).pipe(
            map((r: any) => {
                return GET_SUBSCRIPTION_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    changeMode(subscription: Subscription, mode: SubscriptionAllocationMode, subAlloc: SubAllocationsResponse, dialogService: DialogService, dialog: MatDialog) {

        return new Promise<SubscriptionAllocationMode>((resolve, rejects) => {
            let changeModeCallback = () => {
                this.seatService.changeAllocationMode(subscription.id, mode).subscribe({
                    next: (allocation) => {
                        subscription.allocationMode = mode;
    
                        if (subAlloc) {
                            subAlloc.allocations = [allocation];
                            subAlloc.update();
                        }
                        resolve(mode);
                    }, error: (err) => {
                        this.errorDialogService.openErrorDialog(undefined, err, false);
                        rejects(err);
                    }
                })
            };

            if (mode == SubscriptionAllocationMode.Automatic) {
                let settings = new MessageBoxSettings();
                settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
                settings.messages = [
                    $localize`:@@seat-allocation-section-change-allocation-auto-mode:Are you sure you want to switch to automatic mode? This will reset unused tokens.`
                ];
                settings.icon = new MessageBoxIcon("warning", "warn");
                settings.buttons = [
                    new MessageBoxButton(MessageBoxResult.Cancel),
                    new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-yes: Yes`, "warn", "flat"),
                ];
    
                MessageBoxDialogComponent.createDialog(dialogService, dialog, settings).show().then((result) => {
                    if (result == MessageBoxResult.OK) {
                        changeModeCallback();
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                changeModeCallback();
            }
        });

    }

    sendSubscriptionAutoRenewNotifications(testmode: boolean) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Subscription, `/send-auto-renew-notifications`, { testmode }, { withCredentials: true }).pipe(
            map((r: any) => {
                return SEND_SUBSCRIPTION_AUTO_RENEW_NOTIFICATIONS_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }
}
