import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChecklistType, Product } from '@applogic/model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AngularUtils } from 'src/app/services/angular-utils';
import { ProductService } from 'src/app/services/product.service';
import { ChecklistTabComponent } from '../checklist-tab/checklist-tab.component';

@Component({
    selector: 'app-checklist-popover',
    templateUrl: './checklist-popover.component.html',
    styleUrls: ['./checklist-popover.component.scss']
})
export class ChecklistPopoverComponent implements OnInit, OnDestroy {

    public static popoverList: ChecklistPopoverComponent[] = [];

    @Input()
    key: string;

    @Input()
    checklistItem: string;

    @Input()
    checklistType: ChecklistType = ChecklistType.Classroom;

    @Input()
    customText: string;

    @Input()
    placement: string = "top";

    @Input()
    container: string;

    // The parent checklist.
    parent: ChecklistTabComponent;

    @ViewChild('popover') public popover: NgbPopover;

    products: Product[] = [];

    bindedElement: HTMLElement;

    constructor(public readonly elementRef: ElementRef<HTMLElement>,
        private productService: ProductService) { }

    ngOnInit(): void {
        if (this.checklistItem.indexOf("tryProducts") != -1) {
            this.placement = "left";
        }
        else if (this.checklistItem == "subscribed") {
            this.placement = "bottom";
        }

        ChecklistPopoverComponent.popoverList.push(this);

        this.productService.getProducts().then((products) => {
            this.products = products;
        });
    }

    ngOnDestroy(): void {
        const index = ChecklistPopoverComponent.popoverList.indexOf(this, 0);
        if (index > -1) {
            ChecklistPopoverComponent.popoverList.splice(index, 1);
        }
    }

    close() {
        // if(this.popover.isOpen())
        {
            this.popover.close();
        }
    }

    public static closeAll() {
        for(let i=0; i<ChecklistPopoverComponent.popoverList.length; i++) {
            ChecklistPopoverComponent.popoverList[i].close();
        }
    }

    open(bindedElement: HTMLElement = undefined) {
        this.bindedElement = bindedElement;
        this.popover.open();

        if(this.container == "body") {
            this.updatePosition();
        }
    }

    private updatePosition() {
        if (this.bindedElement && this.popover.isOpen) {
            this.elementRef.nativeElement.style.top = this.bindedElement.offsetTop.toString() + "px";

            setTimeout(() => {
                this.updatePosition();
            }, 10);
        }
    }

    public static scrollToQueryAndOpenPopup(router: Router, route: ActivatedRoute, prefix: string = undefined, scrollable: CdkScrollable = undefined, getElementRefFunc: (elementId: string) => HTMLElement = undefined) {
        return AngularUtils.scrollToQuery(router, route, prefix, scrollable, getElementRefFunc).then((e) => {
            setTimeout(() => {
                let popoverId = route.snapshot.queryParamMap.get("popoverAfterScroll");

                let testmode = route.snapshot.queryParamMap.get("testmode");
                if(!testmode) {
                    AngularUtils.removeRouteQueryParam("popoverAfterScroll", router, route);
                }

                if(!popoverId) popoverId = e.id + "-popover";
                const popover = ChecklistPopoverComponent.popoverList.find(c => c.key == popoverId);
    
                if(popover) {
                    popover.open(e.element);
                }
                else {
                    console.log("No popup with id '" + popoverId + "'");
                }    
            }, 700);
        });
    }

    onClick(event: any) {
        this.close();
    }
}
