import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { DialogService, AppDialogConfig } from 'src/app/services/dialog.service';

export interface SimpleChartTab {
    label: string;
    data: any,
    options: any
}

export interface SimpleChartTabsDialogData {
    tabs: SimpleChartTab[];
}

@Component({
    selector: 'app-simple-chart-tabs-dialog',
    templateUrl: './simple-chart-tabs-dialog.component.html',
    styleUrl: './simple-chart-tabs-dialog.component.scss'
})
export class SimpleChartTabsDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: SimpleChartTabsDialogData,
        private mdDialogRef: MatDialogRef<SimpleChartTabsDialogComponent>) { }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: SimpleChartTabsDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<SimpleChartTabsDialogComponent, SimpleChartTabsDialogData>(dialogService, dialog, SimpleChartTabsDialogComponent, {
            data,
            width: "700px",
            maxWidth: "700px",
            minWidth: "300px",
            maxHeight: "calc(100vh - 64px)",
            position: {
                top: "52px",
            },
            panelClass: 'makeItMiddle'
        }, config);
    }
}
