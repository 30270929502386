import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from './address-form/address-form.component';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GoogleMapsService } from './google-maps.service';
import { NgMapsPlacesModule } from '@ng-maps/places';
import { AddressDialogComponent } from './address-dialog/address-dialog.component';
import { AddressNameInputComponent } from './address-name-input/address-name-input.component';


@NgModule({
    declarations: [
        AddressFormComponent,
        AddressDialogComponent,
        AddressNameInputComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        NgMapsPlacesModule
    ],
    exports: [
        AddressFormComponent,
        AddressDialogComponent,
        AddressNameInputComponent,
    ],
    providers: [
        GoogleMapsService,
    ]
})
export class AddressUiModule { }
