import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private _defaultToastConfig: Partial<IndividualConfig> = {
        positionClass: 'toast-bottom-right',
        timeOut: 2000,
        enableHtml: true
    };

    private _defaultSnackbarConfig: MatSnackBarConfig = {

    };

    constructor(
        private toastr: ToastrService,
        private snackbar: MatSnackBar
    ) {

    }


    notify(options: {
        title?: string,
        message: string,
        type?: 'toast'|'snackbar',
        style?: 'success'|'info'|'warning'|'error'|'message',
        toastrConfig?: Partial<IndividualConfig>,
        snackbarConfig?: MatSnackBarConfig
    }) {
        let type = options.type;
        let style = options.style || 'message';

        if(!type) {
            // Style not supported by the snackbar then use the toastr.
            if((style == 'success') || (style == 'info') || (style == 'warning') || (style == 'error') ) {
                type = 'toast';
            }
            else {
                type = 'snackbar';
            }
        }

        switch(type) {
            case 'toast':
                let toastrConfig = Object.assign({}, this._defaultToastConfig, options.toastrConfig || {});
                if(!toastrConfig) {
                    toastrConfig = {};
                }
        
                switch(style) {
                    case 'error':
                        this.toastr.error(options.message || "", options.title || "", toastrConfig);
                        break;
                    case 'warning':
                        this.toastr.warning(options.message || "", options.title || "", toastrConfig);
                        break;
                    case 'success':
                        this.toastr.success(options.message || "", options.title || "", toastrConfig);
                        break;
                    case 'info':
                        this.toastr.info(options.message || "", options.title || "", toastrConfig);
                        break;
                    default:
                        this.toastr.show(options.message || "", options.title || "", toastrConfig);
                        break;
                }
                
                
                break;

            case 'snackbar':
                let snackbarConfig = Object.assign({}, this._defaultSnackbarConfig, options.snackbarConfig || {});
                this.snackbar.open(options.message || "", options.title || "", snackbarConfig);
                break;
        }
    }
}
