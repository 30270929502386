
import { Model, ID, Member, ArrayMember } from '@uon/model';
import { User } from '../user/user.model';
import { Organization } from '../org/organization.model';
import { CurrencyAmount } from '../common/currency.model';
import { TaxAmount } from '../common/tax.model';

@Model()
export class Invoice {

    @ID()
    id: string;

    @Member()
    invoiceNumber: string;

    @ArrayMember(Object)
    lineItems: any[];

    @ArrayMember(TaxAmount)
    taxes: TaxAmount[];

    @Member()
    subtotal: CurrencyAmount;

    @Member()
    total: CurrencyAmount;

    @Member()
    user: User;

    @Member()
    organization: Organization;

    @Member()
    paymentData: object;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

}