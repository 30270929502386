import { Component, HostBinding, Injector, Input, ViewEncapsulation } from '@angular/core';
import { DesignCardComponent } from 'src/app/core/design-card/design-card.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Subscription as RxSubscription } from 'rxjs';
import { FADE_IN_OUT, OPACITY_IN_OUT } from 'src/app/animations/fade';


@Component({
    selector: 'app-page-header2-card',
    // templateUrl: '../../core/design-card/design-card.component.html',
    templateUrl: './page-header2-card.component.html',
    styleUrls: ['../../core/design-card/design-card.component.scss', './page-header2-card.component.scss'],
    animations: [FADE_IN_OUT, OPACITY_IN_OUT],
    encapsulation: ViewEncapsulation.None
})
export class PageHeader2CardComponent extends DesignCardComponent {

    show: boolean = true;

    @Input()
    secondCard: boolean = false;

    @Input()
    bgImageUrl2: string;

    private showPageContentSupbscription: RxSubscription;

    @HostBinding('@opacityInOut') get animationState() {
        return this.show ? 'true' : 'false';
    }

    constructor(injector: Injector, dialogService: DialogService) {
        super(injector);

        this.showPageContentSupbscription = dialogService.showPageContent.subscribe(show => {
            this.show = show;
        });

        // Change default behaviour before the initialization.
        this.clickable = false;
    }

    ngOnDestroy() {
        if (this.showPageContentSupbscription) {
            this.showPageContentSupbscription.unsubscribe();
        }
    }

    getCard2Styles() {
        const result = super.getStyles();
        result['background-image'] = this.bgImageUrl2 ? "url('" + this.bgImageUrl2 + "')" : "none";
        return result;
    }

    getCard1Class() {
        const classes = super.getClass();

        if(this.secondCard) {
            classes['app-page-header-left-card'] = true;
        }

        return classes;
    }

    getCard2Class() {
        const classes = super.getClass();

        if(this.secondCard) {
            classes['app-page-header-right-card'] = true;
        }

        return classes;
    }
}
