import { Model, Member, ID } from "@uon/model";


@Model()
export class Address {

    @Member()
    street: string;

    @Member()
    city: string;

    @Member()
    state: string;

    @Member()
    country: string;

    @Member()
    zipcode: string;

    @Member()
    stateCode: string;

    @Member()
    line1: string;

    @Member()
    line2: string;

    /**
     * Set address from another address.
     * 
     * Will only assign defined address field. The others fields will be untouched.
     * 
     * @param {Address} address The address to assign.
     * 
     * @returns {boolean} Returns true if the address was modified.
     */
    public set(address: Address): boolean {

        let isAddressUpdated: boolean = false;

        let a1: Address = this;
        let a2: Address = address;

        if(a2.country && (a1.country != a2.country)) {
            a1.country = a2.country;
            isAddressUpdated = true;
        }

        if(a2.state && (a1.state != a2.state)) {
            a1.state = a2.state;
            isAddressUpdated = true;
        }

        if(a2.stateCode && (a1.stateCode != a2.stateCode)) {
            a1.stateCode = a2.stateCode;
            isAddressUpdated = true;
        }

        if(a2.city && (a1.city != a2.city)) {
            a1.city = a2.city;
            isAddressUpdated = true;
        }

        if(a2.line1 && (a1.line1 != a2.line1)) {
            a1.line1 = a2.line1;
            isAddressUpdated = true;
        }

        if(a2.line2 && (a1.line2 != a2.line2)) {
            a1.line2 = a2.line2;
            isAddressUpdated = true;
        }

        if(a2.zipcode && (a1.zipcode != a2.zipcode)) {
            a1.zipcode = a2.zipcode;
            isAddressUpdated = true;
        }

        if(a2.street && (a1.street != a2.street)) {
            a1.street = a2.street;
            isAddressUpdated = true;
        }

        return isAddressUpdated;
    }
}