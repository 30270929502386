import { Component, Input } from '@angular/core';
import { MSMChip } from '../msm-game-progress/msm-game-progress.model';
import * as moment from "moment-timezone";
import { LanguageService } from 'src/app/services/language.service';


@Component({
    selector: 'app-msm-tooltip',
    templateUrl: './msm-tooltip.component.html',
    styleUrl: './msm-tooltip.component.scss'
})
export class MsmTooltipComponent {

    protected _chip: MSMChip;

    @Input()
    set chip(chip: MSMChip) {
        this._chip = chip;
        if(chip.completedlevels) {
            this.completedlevels = chip.completedlevels.sort(function (a, b) {
                return a - b;
            });
        }
        else {
            this.completedDates = [];
        }

        this.completedDatesOverflow = 0;
        if(chip?.completed_Date) {
            this.completedDates = chip.completed_Date.map(d => moment(d)).sort(function (a, b) {
                return -a.diff(b);
            });

            const maxDates = 5;
            if(this.completedDates.length > maxDates) {
                this.completedDatesOverflow = this.completedDates.length - maxDates;
                this.completedDates = this.completedDates.slice(0, maxDates);
            }
        }
        else {
            this.completedDates = [];
        }
    }

    @Input()
    context: 'activity'|'date';

    protected completedlevels: number[];
    protected completedDates: moment.Moment[] = [];
    protected completedDatesOverflow: number = 0;

    constructor(private languageService: LanguageService) {
        
    }

    getFormattedDate(date: moment.Moment) {
        return date.locale(this.languageService.currentLanguageCode).format("dddd, MMMM Do YYYY, h:mm:ss a");
    }

}
