import { Component, OnInit } from '@angular/core';
import { InviteService, ClassroomStudentInviteSummary } from '../invite.service';
import { ActivatedRoute } from '@angular/router';
import { Classroom, ClassroomStudentInvite, Product, Student, User } from '@applogic/model';
import { UntypedFormGroup } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { ProductService } from 'src/app/services/product.service';
import { Model } from '@uon/model';
import { LanguageService } from 'src/app/services/language.service';

enum Step {
    Register = 'register',
    Complete = 'complete',
    StartPlaying = 'start-playing',
    Error = 'error'
}

@Component({
    selector: 'app-student-invite-page',
    templateUrl: './student-invite-page.component.html',
    styleUrls: ['./student-invite-page.component.scss']
})
export class StudentInvitePageComponent implements OnInit {

    Step = Step;
    _step: Step;

    errorObject: any;

    inviteSummary: ClassroomStudentInviteSummary;

    studentFormResult: Student;

    selectedProduct: Product;

    productsList: Product[] = [];

    constructor(private service: InviteService,
        private route: ActivatedRoute,
        readonly loading: LoadingService,
        public productService: ProductService,
        protected languageService: LanguageService) {

    }

    ngOnInit(): void {

        this.productService.getProducts().then((res) => {
            console.log(JSON.stringify(res))
            this.productsList = res;
        })

        const token = this.route.snapshot.params.token;

        if (token.startsWith("test-")) {
            this.initTestMode(token);
        }
        else {
            this.service.getStudentInviteSummary(token).subscribe({
                next: (invite) => {
                    this.inviteSummary = invite;
                    this._step = Step.Register;
                }, error: (err) => {
                    this.errorObject = err;
                    this._step = Step.Error;
                }
            })
        };
    }

    initTestMode(token: string) {
        this._step = token.substring(5) as Step;

        this.inviteSummary = {
            classroom: Model.New(Classroom, {
                name: "My Class"
            }),
            invite: Model.New(ClassroomStudentInvite, {
                createdBy: Model.New(User, {
                    email: "inviter@example.com",
                    fullName: "Inviter User"
                })
            })
        }

        this.studentFormResult = Model.New(Student, {
            fullName: "Student Name",
            username: "Student Username",
            password: "Student Password"
        });
    }

    submitForm(formData: UntypedFormGroup) {
        let stu: Student = Object.assign({}, formData.value);

        this.errorObject = undefined;
        this.service.registerStudent(stu, this.inviteSummary.invite.token).subscribe({
            next: (stu) => {
                this.studentFormResult = stu;
                this._step = Step.Complete;
            },
            error: (err) => {
                this.errorObject = err;
            }
        })
    }

    selectProduct(product: Product) {
        this.selectedProduct = product;
    }
}
