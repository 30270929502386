
import { ID, Member, Model } from "@uon/model";
import { User } from "../user/user.model";


@Model()
export class AppReceipt {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    expireOn: Date;

    // The unique identifier for the purchase.
    @Member()
    purchaseId: string;

    // The purchase type (IAP, PaidApp, Subscription, AppReceipt(For the WinStore))
    @Member()
    type: string;

    // The store name (AppleAppStore, GooglePlay, etc...)
    @Member()
    store: string;

    // The app Id as described on the store
    @Member()
    appId: string;
    
    // The product Id as described on the store
    @Member()
    productId: string;

    // The raw receipt provided by the platform
    @Member()
    receipt: object;

    // The purchase receipt (decoded)
    @Member()
    decodedReceipt: object;
}
