import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubAllocationsResponse, Subscription, SubscriptionAllocationMode } from '@applogic/model';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { RadioSelectCardButtonStyle } from 'src/app/core/radio-select-card-button/radio-select-card-button.component';
import { SubscriptionService } from 'src/app/services/subscription.service';

interface SubscriptionModeDialogData {
    subscription: Subscription,
    subAlloc: SubAllocationsResponse;
}

@Component({
    selector: 'app-subscription-mode-dialog',
    templateUrl: './subscription-mode-dialog.component.html',
    styleUrl: './subscription-mode-dialog.component.scss'
})
export class SubscriptionModeDialogComponent {
    RadioSelectCardButtonStyle = RadioSelectCardButtonStyle;
    SubscriptionAllocationMode = SubscriptionAllocationMode;

    errorObject: any;

    selectedMode: SubscriptionAllocationMode;

    constructor(@Inject(MAT_DIALOG_DATA) protected data: SubscriptionModeDialogData,
        private mdDialogRef: MatDialogRef<SubscriptionModeDialogComponent>,
        private subscriptionService: SubscriptionService,
        private dialogService: DialogService,
        private dialog: MatDialog) {

    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: SubscriptionModeDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<SubscriptionModeDialogComponent, SubscriptionModeDialogData, SubscriptionAllocationMode>(dialogService, dialog, SubscriptionModeDialogComponent, {
            data
        }, config);
    }

    update() {
        this.errorObject = undefined;

        this.subscriptionService.changeMode(this.data.subscription, this.selectedMode, this.data.subAlloc, this.dialogService, this.dialog).then((result) => {
            if (result) {
                this.mdDialogRef.close(result);
            }
        }).catch((ex) => {
            this.errorObject = ex;
        });
    }

    public onOptionChanged(mode: SubscriptionAllocationMode) {
        this.selectedMode = mode;
    }
}
