<app-design-card class="classroom-role-card"
                 [clickable]="false">
    <design-card-image>
        <app-shape type="circle"
                   scale="medium">
            <mat-icon>person</mat-icon>
        </app-shape>
        <!-- <img class="product-image"
                 [src]="_imageUrl" /> -->
    </design-card-image>
    <design-card-title [ngSwitch]="role">
        <span *ngSwitchCase="ClassroomRole.Owner"
              i18n="@@classroom-role-card--owner-title">Owner</span>
        <span *ngSwitchCase="ClassroomRole.Collaborator"
              i18n="@@classroom-role-card--collaborator-title">Collaborator</span>
        <span *ngSwitchCase="ClassroomRole.Admin"
              i18n="@@classroom-role-card--admin-title">Admin</span>
    </design-card-title>
    <design-card-subtitle [ngSwitch]="role">
        <span *ngSwitchCase="ClassroomRole.Owner"
              i18n="@@classroom-role-card--owner-description">Owner description</span>
        <span *ngSwitchCase="ClassroomRole.Collaborator"
              i18n="@@classroom-role-card--collaborator-description">Collaborator description</span>
        <span *ngSwitchCase="ClassroomRole.Admin"
              i18n="@@classroom-role-card--admin-description">Admin description</span>
    </design-card-subtitle>
</app-design-card>