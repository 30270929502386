import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentInvitePageComponent } from './student-invite-page/student-invite-page.component';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StaffMemberInvitePageComponent } from './staff-member-invite-page/staff-member-invite-page.component';
import { CollaboratorInvitePageComponent } from './collaborator-invite-page/collaborator-invite-page.component';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';

@NgModule({
    declarations: [
        StudentInvitePageComponent,
        StaffMemberInvitePageComponent,
        CollaboratorInvitePageComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AuthUiModule,
        DashboardUiModule,
    ]
})
export class InviteModule { }
