import { Component, Injector, Input } from '@angular/core';
import { GameProgressSelectableColumn } from '../../game-progress-selectable-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';

@Component({
    selector: 'app-columns-selection-colums-list',
    templateUrl: './columns-selection-colums-list.component.html',
    styleUrls: ['../../base-game-progress/base-game-progress.component.scss', './columns-selection-colums-list.component.scss']
})
export class ColumnsSelectionColumsListComponent extends CommonListComponent<GameProgressSelectableColumn> {

    _gameColumns: GameProgressSelectableColumn[] = [];

    @Input()
    set gameColumns(val: GameProgressSelectableColumn[]) {
        this.unselectAll();
        this._gameColumns = val
        this.setPreloadItems(val);
        this.setSelectionStateForItems(val.filter((v) => v.isSelectedValue), true);
    }

    get gameColumns() {
        return this._gameColumns;
    }

    protected _columnTooltip: GameProgressSelectableColumn;


    constructor(private injector: Injector) {
        super(injector);
        this.displayedColumns = [];
        this.noPagination = true;
        this.rendererOptions.hidePagination = true;
        this.rendererOptions.showSearchBar = true;
        this.rendererOptions.stickyHeader = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initColumns();
    }

    initColumns() {
        this.initPersistentKey("");

        this.addColumn("", "selection", true, true, CommonColumnType.Selection);
        this.addColumn($localize`:@@msm-game-word-list-table-header-list-name: List Name `, "name", true, true, CommonColumnType.Text, {
            searchable: true,
            fixedWidth: "150px"
        });
        this.addColumn($localize`:@@msm-game-word-list-table-header-description: Description `, "listInfo.desc", true, true, CommonColumnType.Html, {
            searchable: true
        });
        this.addColumn($localize`:@@msm-game-word-list-table-header-Words: Words `, "Words", true, true, CommonColumnType.Template, {
            searchable: true,
            fixedWidth: "40px",
            templateCellIdx: 0
        });

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {

    }

    protected setCurrentColumn(columnTooltip: GameProgressSelectableColumn) {     
        setTimeout(() => {
            this._columnTooltip = columnTooltip;
        }, 200);
    }
}
