import { Member, Model } from "@uon/model";

export type CommonQueryFields = {[fieldName: string]: boolean|CommonQueryFields};

@Model()
export class CommonQueryFilter {
    
    @Member()
    skip?: number;

    @Member()
    limit?: number;

    @Member()
    search?: string;

    @Member()
    sort?: string;

    // For other parameters.
    @Member()
    other: any = {};

    // Some extra filtering.
    @Member()
    filter: any;

    constructor(data: {skip?: number, limit?: number, search?: string, sort?: string, filter?: any}) {
        if(data) {
            if(data.skip || (data.skip == 0)) this.skip = data.skip;
            if(data.limit || (data.limit == 0)) this.limit = data.limit;
            if(data.search) this.search = data.search;
            if(data.sort) this.sort = data.sort;
            if(data.filter) this.filter = data.filter;
        }
    }

    fromQuery(query: any) {
        for(const key of Object.keys(query)) {
            let val = query[key];

            if(key == 'limit') {
                if(typeof val !== 'number') {
                    val = Number(val);
                }
                this.limit = val;
            }
            else if(key == 'skip') {
                if(typeof val !== 'number') {
                    val = Number(val);
                }
                this.skip = val;
            }
            else if(key == 'search') {
                this.search = val;
            }
            else if(key == 'sort') {
                this.sort = val;
            }
            else if(key.startsWith("filter-")) {
                if(!this.filter) this.filter = {};

                let k = key.substring(7);

                this.setDictionaryValue(k, val, this.filter);
            }
            else {
                this.setDictionaryValue(key, val, this.other);
            }
        }
    }

    setObject(key: string, obj: any) {
        this.other[key] = JSON.stringify(obj);
    }

    getObject(key: string, defaultObj?: any) {
        const obj = this.other?.[key];
        if(obj) {
            return JSON.parse(obj);
        }

        return defaultObj;
    }

    setFields(fields: CommonQueryFields) {
        this.setObject("fields", fields);
    }

    getFields(defaultFields?: CommonQueryFields) {
        return this.getObject("fields", defaultFields) as CommonQueryFields;
    }

    setDictionaryValue(key: string, val: string, dict: any) {
        if(key.endsWith("-int")) {
            const k = key.substring(0, key.length - 4);
            if(val.indexOf(',') != -1) {
                dict[k] = val.split(',').map((a: string) => parseInt(a));
            }
            else {
                dict[k] = parseInt(val);
            }
        }
        else {                
            if(val.indexOf(',') != -1) {
                dict[key] = val.split(',');
            }
            else {
                dict[key] = val;
            }
        }
    }

    /**
     * Convert to a string to append to a url.
     * 
     * This will include the '?' character if necessary.
     * Was named toString2() instead of toString() because overriding doesn't work and returns "[object Object]".
     */
    toString2() {
        let str = ''

        if(this.skip || (this.skip == 0)) {
            str += "skip=" + this.skip;
        }

        if(this.limit || (this.limit == 0)) {
            if(str.length > 0) str += "&";
            str += "limit=" + this.limit;
        }

        if(this.search) {
            if(str.length > 0) str += "&";
            str += "search=" + encodeURIComponent(this.search);
        }

        if(this.sort) {
            if(str.length > 0) str += "&";
            str += "sort=" + this.sort;
        }

        if(this.other)
        {
            for (let key in this.other) {
                if (this.other[key] !== undefined) {
                    if(str.length > 0) str += "&";
                    str += key + '=' + this.other[key];
                }
            }
        }

        if(this.filter)
        {
            for (let key in this.filter) {
                if (this.filter[key] !== undefined) {
                    if(str.length > 0) str += "&";
                    let val = this.filter[key];
                    if(Array.isArray(val)) {
                        val = val.join(',');
                    }
                    str += 'filter-' + key + '=' + val;
                }
            }
        }


        if(str.length > 0)
        {
            str = '?' + str;
        }

        return str;
    }

    public convertSortStringToObject(defaultSort?: string[]) {
        
        let val: string[]|string;

        if(!this.sort) {
            if(defaultSort) {
                val = defaultSort;
            }
            else {
                val = [];
            }
        }
        else {
            if(!Array.isArray(this.sort)) {
                val = this.sort.split(',');
            }
            else {
                val = this.sort;
            }
        }
    
        let result: any = {};
        for (let i = 0; i < val.length; i++) {
    
            let v = val[i];
            let sort_order = 1;
    
            if (v.charAt(0) === '-') {
                sort_order = -1;
                v = v.substring(1);
            }
    
            result[v] = sort_order;
        }
    
        return result;
    }

    getArray(key: string, defaultValue?: string[]) {
        let v = this.other?.[key];

        if(!v) {
            return defaultValue;
        }

        if(Array.isArray(v)) {
            return v;
        }

        return [v];
    }

    getHashSet(key: string, defaultValue?: Set<string>) {
        let v = this.other?.[key];

        if(!v) {
            return defaultValue;
        }

        let result = new Set<string>();
        if(Array.isArray(v)) {
            v.forEach(result.add, result);
        }
        else {
            result.add(v);
        }

        return result;
    }
}