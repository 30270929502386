<app-page type="centered">
    <app-page-content class="app-form-container">
        <div *ngIf="loading.isLoading"
             style="display: flex; justify-content: center; width: 100%"
             @fadeInCollapseOut>

            <mat-progress-spinner mode="indeterminate"
                                  diameter="64"></mat-progress-spinner>
        </div>
        <app-design-card class="invite-card"
                         appearance="raised"
                         [clickable]="false">
            <design-card-content>
                <ng-container [ngSwitch]="_step">
                    <div *ngSwitchCase="Step.Error">
                        <ng-template [ngTemplateOutlet]="errorTemplate"></ng-template>
                    </div>

                    <div *ngSwitchCase="Step.Register">
                        <ng-template [ngTemplateOutlet]="registerTemplate"></ng-template>
                    </div>

                    <div *ngSwitchCase="Step.Complete">
                        <ng-template [ngTemplateOutlet]="completeTemplate"></ng-template>
                    </div>

                    <div *ngSwitchCase="Step.StartPlaying">
                        <ng-template [ngTemplateOutlet]="startPlayingTemplate"></ng-template>
                    </div>

                </ng-container>
            </design-card-content>
        </app-design-card>
    </app-page-content>
</app-page>

<ng-template #errorTemplate>
    <div class="header"
         style="justify-content: center;">
        <mat-icon color="warn">warning</mat-icon>
    </div>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <small i18n="@@common-invitation-lookup-error-hint">
        Please contact the person who gave you the link. They may be able to generate a new invitation.
    </small>
</ng-template>

<ng-template #registerTemplate>
    <h2 i18n="@@student-invite-form-title">You are invited to a classroom!</h2>
    <p i18n="@@student-invite-form-message">
        <Strong>{{inviteSummary.invite.createdBy?.fullName}}</Strong> invites you to join
        <Strong>{{inviteSummary.classroom.name}}</Strong>. To jump in, fill out the form below.
    </p>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <app-base-login-register-form [formType]="'student-form'"
                                  #stuForm></app-base-login-register-form>

    <div class="app-form-actions">
        <span class="spacer"></span>
        <button mat-flat-button
                color="primary"
                [disabled]="!stuForm.baseLoginRegisterForm.valid || loading.isLoading || (loading.loadingChange | async)"
                (click)="submitForm(stuForm.baseLoginRegisterForm)">
            <span i18n="@@student-invite-join-button">
                Join Classroom
            </span>

        </button>
    </div>
</ng-template>

<ng-template #completeTemplate>


    <h2 i18n="@@student-invite-complete-title">Congratulations {{ studentFormResult.firstName }}!</h2>

    <p i18n="@@student-invite-complete-context-text">
        You're now part of <strong> {{ inviteSummary.classroom.name }} </strong>!
    </p>

    <p i18n="@@student-invite-complete-context-text2">
        Keep your profile name and password safe. You'll need them to connect to the game.
    </p>

    <div class="property-list">
        <div class="list-item">
            <span class="label"
                  i18n="@@common-username"> Profile name </span>
            <span class="value"> {{ studentFormResult.username }}</span>
        </div>

        <div class="list-item">
            <span class="label"
                  i18n="@@common-password"> Password </span>
            <span class="value"> {{ studentFormResult.password }}</span>
        </div>
    </div>


    <div class="app-form-actions">
        <span class="spacer"></span>
        <button mat-flat-button
                color="accent"
                (click)="_step = Step.StartPlaying">
            <span i18n="@@student-invite-next-button">Get the game!</span>
        </button>
    </div>

</ng-template>

<ng-template #startPlayingTemplate>
    <h2 i18n="@@student-invite-start-playing">Start playing!</h2>

    <div class="instruction-step flex-column justify-content-start"
         style="align-items: unset;">
        <span i18n="@@student-invite-startplaying-text1">
            To start playing now, you first need to install the game. You can play on any platform of your
            choice!
            Choose the game that you want to install.
        </span>
    </div>

    <div class="product-logo">
        <img *ngFor="let product of productsList"
             [attr.src]="productService.getGameIcon(product.shortCode)"
             class="product-image"
             [ngClass]="{'selected-product-image': selectedProduct?.shortCode == product.shortCode}"
             alt=""
             (click)="selectProduct(product)">
    </div>

    <div *ngIf="selectedProduct">
        <div class="instruction-step flex-column justify-content-start"
             style="align-items: unset;">
            <strong>
                <span>
                    {{selectedProduct.currentLocalizedName}}
                </span>
            </strong>
        </div>

        <div class="store-links">
            <a *ngFor="let link of selectedProduct.links | filterType:'store'"
               [href]="languageService.localizeFromDict(link.url)">
                <img [src]="languageService.localizeFromDict(link.button)"
                     [alt]="languageService.localizeFromDict(link.label)">
            </a>
        </div>
    </div>

    <div class="instruction-step"
         *ngIf="selectedProduct">
        <span i18n="@@student-invite-startplaying-text2">
            Then start the game and connect as a student, using your profile name and password. Have fun!
        </span>
    </div>

    <div class="app-form-actions">
        <button mat-stroked-button
                (click)="_step = Step.Complete">
            <span i18n="@@common-close"> Close </span>
        </button>
        <span class="spacer"></span>
    </div>
</ng-template>