<app-design-card class="radio-select-card"
                 [ngClass]="{'selected-card': checked, 'disabled-card': _disabled, 'no-card-text': !hasCardText}"
                 [clickable]="!_disabled"
                 (click)="cardSelect.setChecked()">
    <design-card-title>
        <ng-content select="[card-title]"></ng-content>
    </design-card-title>
    <design-card-content class="secondary-text">
        <ng-content select="[card-text]"></ng-content>
    </design-card-content>
    <design-card-select [groupKey]="group"
                        [value]="value"
                        [disabled]="_disabled"
                        (onCheck)="onCheck.emit($event)"
                        #cardSelect>
    </design-card-select>
</app-design-card>