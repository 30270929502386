import { Model, Member, ArrayMember } from "@uon/model";
import { User } from "./user.model";
import { Organization } from "../org/organization.model";
import { OnboardingForm } from "../onboarding/onboarding-form.model";

@Model()
export class UserProfileResponse {

    @Member()
    user: User;
    
    @ArrayMember(Organization)
    orgs: Organization[];

    @Member()
    classroomCount: number;

    @Member()
    studentCount: number;

    @ArrayMember(OnboardingForm)
    onboardingForms: OnboardingForm[];
}
