<div class="popover-area"
     popoverClass="app-popover--container"
     [autoClose]="false"
     [ngbPopover]="popContent"
     [placement]="placement"
     [popoverTitle]="popTitle"
     (click)="onClick($event)"
     #popover="ngbPopover"
     triggers="mouseenter:mouseleave:manual">
    <ng-content select="[actions]"></ng-content>
</div>

<ng-template #popContent>
    <div *ngIf="checklistType=='classroom'">
        <span class="popover-content"
              *ngIf="checklistItem=='inviteStudent'">
            <p i18n="@@checklist-popover-invitestudent">Invite your students to join your classroom so they can access to the games.</p>
        </span>
        <span class="popover-content"
              *ngIf="checklistItem=='followProgress'">
            <p i18n="@@checklist-popover-followprogress">The progress every student makes in the games is tracked in the progress tables below. Consult the Help menu to learn how it works.</p>
            <button *ngIf="!(parent?.checklist?.followProgress)"
                    mat-flat-button
                    color="accent"
                    (click)="parent?.followProgressUnderstood()">
                <span i18n="@@common-understand-calltoaction">Got it!</span>
            </button>
        </span>

        <div class="popover-small-content"
             *ngIf="checklistItem=='tryProductsMMO'">
            <p i18n="@@checklist-popover-trymmo-1">Try Madam Word, our game for developing phonological awareness from Kindergarten to Grade 3.</p>
            <p i18n="@@checklist-popover-tryproducts-2">Use a student's profile name that you created to log into the game.</p>
        </div>

        <div class="popover-small-content"
             *ngIf="checklistItem=='tryProductsMSM'">
            <p i18n="@@checklist-popover-trymsm-1">Try Words Without Words Worries, our game to master the difficulties of lexical orthography for grades 3 to 6.</p>
            <p i18n="@@checklist-popover-tryproducts-2">Use a student's profile name that you created to log into the game.</p>
        </div>

        <span class="popover-content"
              *ngIf="checklistItem=='subscribed'">
            <p i18n="@@checklist-popover-subscribed">Subscribe to continue progress with your students beyond the free trial!</p>
        </span>
    </div>

    <div *ngIf="checklistType=='organization'">
    </div>

    <span class="popover-content">
        {{customText}}
        <ng-content select="[popupcontent]"></ng-content>
    </span>
</ng-template>
<ng-template #popTitle></ng-template>