import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[appAlignCheck]'
})
export class AlignCheckDirective implements AfterViewInit, OnDestroy {
    private resizeSubscription: Subscription;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngAfterViewInit() {
        this.checkAlignment();

        this.resizeSubscription = fromEvent(window, 'resize')
            // .pipe(debounceTime(100))
            .subscribe(() => {
                this.checkAlignment();
            });
    }

    ngOnDestroy() {
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    private checkAlignment() {
        const children = Array.from(this.el.nativeElement.children) as HTMLElement[];
        if (children.length < 2) {
            return; // If less than 2 children, nothing to check
        }
        const firstChildTop = children[0].offsetTop;

        // Check if all children are aligned horizontally
        const misalignedElements = children.filter(child => child.offsetTop !== firstChildTop);

        children.forEach(child => {
            if (misalignedElements.includes(child)) {
                this.renderer.addClass(child, 'not-aligned');
            } else {
                this.renderer.removeClass(child, 'not-aligned');
                if(misalignedElements.length > 0) {
                    this.renderer.addClass(child, 'aligned-with-not-aligned');
                }
                else {
                    this.renderer.removeClass(child, 'aligned-with-not-aligned');
                }
            }
        });
    }
}
