import { Component, HostBinding, Input } from '@angular/core';
import { LabelType } from 'src/app/core/label/label.component';

@Component({
        selector: 'app-page-header-card',
        templateUrl: './page-header-card.component.html',
        styleUrl: './page-header-card.component.scss'
})
export class PageHeaderCardComponent {

    LabelType = LabelType;

    @Input()
    bgImageUrl: string;

    @Input()
    showContent: boolean = true;

    @Input()
    customLayout: boolean = false;

    @Input()
    count: number;

    @Input()
    minContentTitle: boolean = true;

    @Input()
    titleType: LabelType = LabelType.Ellipsis;

    getLabelWidth() {
        switch(this.titleType) {
            case LabelType.Ellipsis:
                return 'min-content';
            case LabelType.Dynamic:
                return '100%';
        }
    }
}
