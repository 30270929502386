import { Model, ID, Member, ArrayMember } from "@uon/model";
import { Address } from "../common/address.model";


@Model()
export class BillingAddress extends Address  { // 
    constructor() {
        super();
    }

}

@Model()
export class OrganizationAddress extends Address {

    constructor() {
        super();
    }

    @ID()
    id: string;

    @Member()
    uniquePlaceId: string;

    @Member()
    placeName: string;

    @Member()
    streetNumber: string;

    @Member()
    sublocality: string;

    @ArrayMember(Number)
    coords: [number, number];

    @Member()
    createdOn: Date;

}