import { Component, OnInit, Input } from '@angular/core';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';

@Component({
    selector: 'app-general-explanation-card',
    templateUrl: './general-explanation-card.component.html',
    styleUrls: ['./general-explanation-card.component.scss']
})
export class GeneralExplanationCardComponent implements OnInit {

    @Input() section: any;
    content: string;


    constructor(readonly knowledgeBaseService: KnowledgeBaseService) { }

    ngOnInit() {

        this.knowledgeBaseService.updateGeneralExplanation.subscribe((res) => {
            this.getToolTipInfo(res);
        });

    }

    getToolTipInfo(arr: any[]) {

        this.content = this.knowledgeBaseService.getToolTipMessage(arr, 'general');

    }
}
