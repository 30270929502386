import { Component, Input, OnInit } from '@angular/core';
import { Currency } from '@applogic/model';

@Component({
    selector: 'app-price-label',
    templateUrl: './price-label.component.html',
    styleUrls: ['./price-label.component.scss']
})
export class PriceLabelComponent implements OnInit {

    _amount: number;
    _currency: Currency;

    @Input()
    set amount(val: number) {
        if (this._amount != val) {
            this._amount = val;
            this.updateParts();
        }
    }

    get amount() {
        return this._amount;
    }


    @Input()
    set currency(val: Currency) {
        if (this._currency != val) {
            this._currency = val;
            this.updateParts();
        }
    }

    get currency() {
        return this._currency;
    }

    @Input()
    sublabel: string;

    symbol: string;

    integerPart: number;

    decimalPart: string;

    isSymbolRight: boolean = false;

    constructor() { }

    ngOnInit(): void {
        this.updateParts();
    }

    updateParts() {
        if(this.amount == undefined) return;
        this.decimalPart = Math.floor((this.amount % 1) * 100).toFixed(0);
        if(this.decimalPart.length == 1) this.decimalPart = "0" + this.decimalPart;
        
        this.integerPart = Math.floor(this.amount);
        if(this.currency) {
            this.symbol = this.currency.symbol;
            this.isSymbolRight = this.currency.isSymbolRight();
        }
    }

}
