import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CustomFormField } from "./custom-form-field";
import { BaseFieldConfig, FieldType } from "./custom-form-field-config";

export interface GroupFormConfig extends BaseFieldConfig {
    orientation?: 'horizontal'|'vertical';
}

export class CustomFormGroup extends CustomFormField {


    private _fields: CustomFormField[] = [];

    get fields() {
        return this._fields;
    }

    fieldDict: {[key: string]: CustomFormField} = {};

    label: string;

    public formGroup: UntypedFormGroup;

    constructor(key: string, control: UntypedFormGroup, config: GroupFormConfig = {}) {
        
        super({
            key,
            type: FieldType.Group,
            label: config.label,
            control,
            validators: config.validators || [],
            data: {
                orientation: config.orientation
            }
        });

        this.formGroup = control;
    }

    addField(field: CustomFormField) {
        this.fields.push(field);
        this.fieldDict[field.key] = field;
    }

    getField(key: string) {
        return this.fieldDict[key];
    }
}