import { Injectable, Injector, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export interface AppDialogConfig {
    dialogConfig?: MatDialogConfig;
    dialog?: MatDialog;
    hidePageContent?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    defaultConfig: MatDialogConfig<any> = {
        autoFocus: false,
        closeOnNavigation: true,
    };

    activeDialogs: number = 0;

    currentConfig: AppDialogConfig;

    readonly showPageContent = new BehaviorSubject<boolean>(false);

    constructor(private injector: Injector, private dialog: MatDialog, private route: ActivatedRoute) {
        this.dialog.afterAllClosed.subscribe(() => {
            this.showPageContent.next(true);
        });

        this.dialog.afterOpened.subscribe((dialogRef) => {
            if(this.currentConfig?.hidePageContent !== false) {
                this.showPageContent.next(false);
            }
        });
    }

    /**
     * Open a dialog.
     * 
     * @param componentOrTemplateRef The component type reference.
     * @param config Dialog configuration.
     * @param extraConfig Optional extra configuration to override the default configuration.
     * 
     * @returns Return a MatDialogRef.
     */
    openDialog<C, D = unknown, R = unknown>(componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
        config?: MatDialogConfig<D>, extraConfig?: AppDialogConfig): MatDialogRef<C, R> {

        const dialog_config: typeof config = Object.assign({}, this.defaultConfig, config || {}, extraConfig?.dialogConfig || {});

        const dialog = extraConfig?.dialog ? extraConfig?.dialog : this.dialog;
        this.currentConfig = extraConfig;
        return dialog.open<C, D, R>(componentOrTemplateRef, dialog_config);
    }

    /**
     * Open a dialog an return a promise.
     * 
     * @param componentOrTemplateRef The component type reference.
     * @param config Dialog configuration.
     * @param extraConfig Optional extra configuration to override the default configuration.
     * 
     * @returns Return a MatDialogRef.
     */
    openDialogP<C, D = unknown, R = unknown>(componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
        config?: MatDialogConfig<D>, extraConfig?: AppDialogConfig): Promise<R> {

        return new Promise((resolve, rejects) => {
            const ref = this.openDialog<C, D, R>(componentOrTemplateRef, config, extraConfig);

            ref.beforeClosed().subscribe({
                next: (result?: R) => {
                    resolve(result);
                },
                error: (err: any) => {
                    console.error(err);
                    rejects(err);
                }
            });
        });
    }

    /**
     * Open a dialog and returns an observable.
     * 
     * @param componentOrTemplateRef The component type reference.
     * @param config Dialog configuration.
     * @param extraConfig Optional extra configuration to override the default configuration.
     * 
     * @returns Return a MatDialogRef.
     */
    openDialogO<C, D = unknown, R = unknown>(componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
        config?: MatDialogConfig<D>, extraConfig?: AppDialogConfig): Observable<R> {

        const ref = this.openDialog<C, D, R>(componentOrTemplateRef, config, extraConfig);

        return ref.beforeClosed();
    }
}
