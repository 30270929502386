import { Model, Member, ArrayMember } from "@uon/model";
import { EmailCampaignStatus, EmailCampaignUsersFilter, EmailTemplateDst, EmailTemplateSource } from "./email.model";
import { UserEmailDelivery, UserEmailDeliveryOpenStats, UserEmailDeliveryStatusStats } from "../user/user-email-delivery.model";

@Model()
export class SendTestEmailQuery {

    @Member()
    sourceType: EmailTemplateSource;
    
    @Member()
    destType: EmailTemplateDst;

    @Member()
    sourceKey: string;

    @Member()
    email: string;

    @Member()
    from: string;

    @Member()
    params: any;
}

@Model()
export class UploadEmailAssetsQuery {
    
    @Member()
    menuPath: string;

    @Member()
    filename: string;

    @Member()
    name: string;
}

@Model()
export class CreateEmailCampaignQuery {

    @Member()
    name: string;
    
    @Member()
    templateKey: string;

    @Member()
    usersFilter: EmailCampaignUsersFilter;
}

@Model()
export class UpdateEmailCampaignQuery extends CreateEmailCampaignQuery {

    constructor() {
        super();
    }
}

@Model()
export class GetEmailCampaignStatsResponse {

    @Member()
    usersCount: number;

    @Member()
    stats: {
        open: UserEmailDeliveryOpenStats,
        status: UserEmailDeliveryStatusStats
    }

    @Member()
    status: EmailCampaignStatus;
}

export enum SendEmailCampaignTarget {
    CurrentUser = "current-user",
    SiteAdmins = "site-admins",
    All = "all"
}

@Model()
export class SendEmailCampaignQuery {

    @Member()
    target: SendEmailCampaignTarget;

    @Member()
    maxTarget: number;

    @Member()
    deliveryPerSecond: number;
}

@Model()
export class SendEmailCampaignResponse {

    @Member()
    nbTargetUser: number;
}


@Model()
export class GetEmailTemplateKeysResponse {

    @ArrayMember(Object)
    templates: {label: string, value: string}[];
}

@Model()
export class SendEmailResult {

    @Member()
    public subject: string;

    @Member()
    public body: string;

    @Member()
    public error: string;

    @Member()
    public errorData: any;

    @Member()
    public exception: string;

    @Member()
    public userEmailDeliveryId: string;

}

@Model()
export class SendEmailResponse {

    @Member()
    result: SendEmailResult;

    @Member()
    delivery: UserEmailDelivery;
}
