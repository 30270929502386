import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

    set(key: string, data: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        }
        catch (e) {
            console.error(`Error saving to localStorage`, e);
        }
    }

    has(key: string) {
        try {
            const item = localStorage.getItem(key);
            return item != undefined;
        }
        catch (e) {
            console.error(`Error checking data from localStrorage`, e);
        }
    }

    get(key: string) {
        try {
            const item = localStorage.getItem(key);
            return JSON.parse(item);
        }
        catch (e) {
            console.error(`Error getting data from localStrorage`, e);
        }
    }

    getd(key: string, d: any) {
        try {
            if(this.has(key))
            {
                const item = localStorage.getItem(key);
                return JSON.parse(item);
            }
        }
        catch (e) {
            
        }

        return d;
    }

    /**
     * Site a value binded to the site version. If site version change, getSite() will returns undefined.
     *  
     * @param {string} key Key to use.
     * @param {any} data Date to set.
     */    
    setSite(key: string, data: any): void {
        try {
            this.set(key, data);
            this.set(key + "_VERSION", environment.siteVersion);
        }
        catch (e) {
            console.error(`Error saving to localStorage`, e);
        }
    }

    /**
     * Get the last value binded to the current site version.
     *  
     * @param {string} key Key to use.
     * 
     * @returns {any} Return the corresponding value if it exist and it that value match the current site version.
     */   
    getSite(key: string) {
        try {
            let siteVersion = this.get(key + "_VERSION");
            if(siteVersion && (siteVersion == environment.siteVersion)) {
                return this.get(key);
            }

            return undefined;
        }
        catch (e) {
            console.error(`Error getting data from localStrorage`, e);
        }
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
    clear() {
        localStorage.clear();
    }
}
