import { ArrayMember, ID, Member, Model, Required } from "@uon/model";
import { OnboardingAction } from "./action";
import { OnboardingStateComponent } from "./state-component";
import { OnboardingTransition } from "./transition";

@Model()
export class OnboardingState {

    @ID() 
    id: string;

    @Member() 
    key: string;

    @Member() 
    label: string;

    @Member() 
    parent: string;

    // List of actions to execute when entering this state.
    @ArrayMember(OnboardingAction)
    onEnterActions: OnboardingAction[];

    // Array of transitions id.
    // @ArrayMember(OnboardingTransition)
    transitions: OnboardingTransition[];

    components: { [id: string]: OnboardingStateComponent } = {};

    // All conditions related to this state.
    conditions: Set<string>;

}