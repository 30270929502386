import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { PaginationProvider } from 'src/app/services/pagination-provider';

interface HtmlViewerDialogData {
    title: string,
    subtitle: string,
    html: string,
    paginationProvider?: PaginationProvider
}

@Component({
    selector: 'app-html-viewer-dialog',
    templateUrl: './html-viewer-dialog.component.html',
    styleUrls: ['./html-viewer-dialog.component.scss']
})
export class HtmlViewerDialogComponent implements OnInit {

    title: string;
    subtitle: any;
    html: SafeHtml;
    errorObject: any;
    
    paginationProvider: PaginationProvider;
    position: number;
    total: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data: HtmlViewerDialogData,
    private mdDialogRef: MatDialogRef<HtmlViewerDialogComponent>,
    private sanitizer: DomSanitizer) {
        this.title = data.title;
        this.subtitle = data.subtitle;
        this.html = this.sanitizer.bypassSecurityTrustHtml(data.html);
        this.paginationProvider = data.paginationProvider;

        if(this.paginationProvider) {
            this.GetCurrentPage();
        }
    }

    ngOnInit(): void {
    }
    
    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: HtmlViewerDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<HtmlViewerDialogComponent, HtmlViewerDialogData, void>(dialogService, dialog, HtmlViewerDialogComponent, {
            data,
            width: '100vw',
            maxWidth: '800px',
            height: '90%'
        }, config);
    }

    public static createDialogWithPagination(dialogService: DialogService, dialog: MatDialog, title: string, paginationProvider: PaginationProvider, config: AppDialogConfig = {}) {
        return new AppDialogRef<HtmlViewerDialogComponent, HtmlViewerDialogData, void>(dialogService, dialog, HtmlViewerDialogComponent, {
            data: {
                title,
                subtitle: "",
                html: "",
                paginationProvider
            },
            width: '100vw',
            maxWidth: '800px',
            height: '90%'
        }, config);
    }

    PreviousPage() {
        this.errorObject = undefined;
        this.paginationProvider.prevPage();
        this.GetCurrentPage();
    }

    NextPage() {
        this.errorObject = undefined;
        this.paginationProvider.nextPage();
        this.GetCurrentPage();
        
    }

    GetCurrentPage() {
        this.subtitle = "";
        this.html = "";
        this.paginationProvider.getPage().then((data) => {
            this.position = this.paginationProvider.getIndex() + 1;
            this.total = this.paginationProvider.getTotal();

            if(data.title) this.title = data.title;
            if(data.subtitle) this.subtitle = data.subtitle;
            if(data.html) this.html = this.sanitizer.bypassSecurityTrustHtml(data.html);
        }).catch((ex) => {
            this.position = this.paginationProvider.getIndex() + 1;
            this.total = this.paginationProvider.getTotal();

            if(ex.title) this.title = ex.title;
            if(ex.subtitle) this.subtitle = ex.subtitle;
            this.errorObject = ex;
        });
    }

    
}
