import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CommonColumnHeaderType, CommonColumnType, CommonListColumn } from './common-list-column';
import { CommonListColumnsDialogComponent } from './common-list-columns-dialog/common-list-columns-dialog.component';
import { ICommonList } from './common-list.interface';
import { CommonQueryResponse } from '@applogic/model';
import { CommonListColumnOptions, CommonListDateColumnParameters } from './common-list-column-options';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FormService } from 'src/app/services/form.service';
import { CommonListRow } from './common-list-row';
import { AngularUtils } from 'src/app/services/angular-utils';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonListRenderOptions } from './common-list-render-options';
import { ActivatedRoute } from '@angular/router';
import { CommonListRendererComponent } from './common-list-renderer/common-list-renderer.component';


/**
 * This class is used to avoid repeat common code for table list.
 */
@Component({
    template: ''
})
export abstract class CommonListComponent<T> implements OnInit, OnDestroy, AfterViewInit, OnChanges, ICommonList {
    list: ICommonList;

    public static currentListForDebug: ICommonList;

    @Output() onSelectionChanged: EventEmitter<SelectionModel<T>> = new EventEmitter();
    selection = new SelectionModel<T>(true, []);

    @Output()
    onItemsChanged: EventEmitter<CommonListComponent<T>> = new EventEmitter();
    
    errorObject: any;

    columns: CommonListColumn[] = [];

    @Input()
    inputColumns: string[] = [];

    displayedColumns: string[] = [];
    displayedColumnsReferences: CommonListColumn[] = [];
    
    pageSizeOptions: number[] = [10, 25, 50, 100];
    
    dataSource: MatTableDataSource<T> = new MatTableDataSource([]);
    
    paginator: MatPaginator;
    @ViewChild(MatPaginator, { static: false })  set paginatorElement(content: MatPaginator) {
        this.paginator = content;
        this.setDataSourceAttributes();
    }

    lastSort: any;

    sort: MatSort;

    // The only way to the initialize the sort direction is through binding to a property.
    // this.sort.direction doesn't work.
    // Unresolved bugs:
    //   https://github.com/angular/components/issues/10242
    //   https://github.com/angular/components/issues/12754
    sortDirection: SortDirection = '';
    sortColumn: string = '';

    setDataSourceAttributes() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    count: number = 0;
    pageSize: number = 20;
    protected pageSizeDefault: number = 20;
    pageIndex: number = 0;
    
    @Input()
    selectedItemId: string;

    @Input()
    noPagination: boolean = false;
    
    _selectedItem: T;
    selectedItemToRemove: boolean = false;

    lastVisibleColumn: CommonListColumn;

    _areControlsValid: boolean = false;

    perTypeOptions: {[columnType: string]: CommonListColumnOptions};

    wasInitialized: boolean = false;

    set areControlsValid(val: boolean) {
        if(val !== this._areControlsValid) {
            this._areControlsValid = val;
            this.areControlsValidChange.emit(val);
        }
    }

    get areControlsValid() {
        return this._areControlsValid;
    }


    @Output()
    areControlsValidChange = new EventEmitter<boolean>();

    @Input()
    set selectedItem(val: T) {
        this.setSelected(val);
    }

    get selectedItem() {
        return this._selectedItem;
    }

    @Output()
    selectedItemChange: EventEmitter<T> = new EventEmitter<T>();

    filter: any;

    search: string;

    // Set when items was retrieved.
    currentSearch: string;

    // True if there are some search criterion.
    hasCriterion: boolean;

    protected ls: LocalStorageService;
    protected dialog: MatDialog;
    protected dialogService: DialogService;
    protected cRef: ChangeDetectorRef;

    persistencyKey: string;

    @Input()
    columnSelectionPersistency: boolean = true;

    // We want the first refresh of items be done after the MatSort is initialized.
    // To avoid multiple queries.
    private firstRefreshDone: boolean = false;

    protected doInitialRefresh: boolean = true;

    protected currentSorting: string = "";

    public selectedMenuActionItem: T;
    public selectedMenuActionColumn: CommonListColumn;

    @Input()
    rowSelectable: boolean = true;

    @Input()
    rowHighlight: boolean = true;

    @Input()
    alwaysShowSelected: boolean = false;

    supportCompactMode: boolean = false;
    compactRow: boolean = false;

    @Input()
    multiSelect: boolean = true;

    onColumnsUpdate: EventEmitter<CommonListColumn[]> = new EventEmitter<CommonListColumn[]>();

    formService: FormService;
    activatedRoute: ActivatedRoute;

    @Input()
    protected preload: boolean = false;

    protected preloadedItems: T[];

    public innerWidth: any;

    _rendererOptions: CommonListRenderOptions = {
        
    };

    @Input()
    set rendererOptions(v: CommonListRenderOptions) {
        Object.assign(this._rendererOptions, v);
    }

    get rendererOptions() {
        return this._rendererOptions;
    }
    
    @Input()
    formArray: UntypedFormArray;

    @Input()
    contextKey: string;

    @Output()
    onRendererAssigned = new EventEmitter<CommonListRendererComponent>();

    private _renderer: CommonListRendererComponent;

    @ViewChild(CommonListRendererComponent)
    set renderer(renderer: CommonListRendererComponent) {
        this._renderer = renderer;
        this.onRendererAssigned.emit(renderer);
    }

    get renderer() {
        return this._renderer;
    }

    private internalRows: Map<any, CommonListRow>;

    private testtype: string;

    @HostBinding('class') get getHostClass() {
        let classList: string[] = [];
        
        if (this.rendererOptions.verticalScrolling) {
            classList.push("app-common-list--vertical-scrolling");
        }
        return classList.join(" ");
    }
    
    private getInternalRow(item: any) {
        if(!this.internalRows) {
            this.internalRows = new Map<any, CommonListRow>();
        }

        let result: CommonListRow = this.internalRows.get(item);

        if(!result) {
            result = {};
            this.internalRows.set(item, result);
        }

        return result;
    }

    private removeInternalRow(item: any) {
        if(this.internalRows) {
            if(this.internalRows.has(item)) {
                this.internalRows.delete(item);
                return true;
            }
        }

        return false;
    }

    private clearInternalRows() {
        if(this.internalRows) {
            this.internalRows.clear();
        }
    }

    constructor(injector: Injector) {
        this.list = this;
        this.ls = injector.get(LocalStorageService);
        this.dialog = injector.get(MatDialog);
        this.dialogService = injector.get(DialogService);
        this.cRef = injector.get(ChangeDetectorRef);
        this.formService = injector.get(FormService);
        this.activatedRoute = injector.get(ActivatedRoute);

        this.activatedRoute.queryParams.subscribe(params => {
            this.testtype = params["test-type"];
        });
    }

    ngOnInit(): void {
        CommonListComponent.currentListForDebug = this;
        this.updateSize();

        if(this.persistencyKey) {
            this.loadPersistentKey();
        }  
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.firstRefreshDone = true;
            if(this.doInitialRefresh) {
                this.refreshItems("Initial Refresh");
            }
        });

        // Disable sorting locally.
        this.dataSource.sortingDataAccessor = (item, property) => {
            return 0;
        };

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.inputColumns) {
            this.displayedColumns = changes.inputColumns.currentValue;
            // TODO: Maybe adding an option if the items must be fetch again on columns changes.
            /*
            setTimeout(() => {
                this.refreshItems();
            });
            */
        }
    }

    /**
     * This function will update the list of quotations 
     * when user change the page number or next or previous button
     * @param event 
    */
    onPaginateChange(event: any): void {
        this.pageSize = event.pageSize;
        if(this.persistencyKey) {
            this.ls.set(this.persistencyKey + "/pagesize", this.pageSize);
        }
        this.pageIndex = event.pageIndex * event.pageSize;
        this.refreshItems("Pagination Change");
    }

    ngOnDestroy(): void {

    }

    openSelectColumnsDialog(): void {
        const appDialog = CommonListColumnsDialogComponent.createDialog(this.dialogService, this.dialog, {
            columns: this.columns
        });

        appDialog.show().then((dialog) => {
            this.updateColumns(false);
            this.saveColumnsSelection();

            if(dialog?.needsRefreshItems) {
                this.refreshItems();
            }
        });
    }

    getSortingColumn() {
        let sort_col: string;

        if (this.sortColumn && this.sortDirection) {
            sort_col = this.sortColumn;

            if (this.sortDirection == 'desc') {
                sort_col = '-' + sort_col;
            }
        }

        return sort_col
    }

    // Reload preloaded items or simply refresh items if there is no preloaded items.
    reloadItems(reason?: string) {
        this.refreshItems(reason, true);
    }

    refreshItems(reason?: string, forceReload: boolean = false) {
        if(!this.firstRefreshDone) {
            return;
        }

        // console.log("## " + this.constructor.name + ".RefreshItems() " + reason);

        let sort_col: string = this.getSortingColumn();

        setTimeout(() => {
            this.currentSorting = sort_col;
            if (!forceReload && this.preload) {
                if(this.preloadedItems != undefined) {
                    this.setFullSourceItems([...this.preloadedItems], this.noPagination ? undefined : this.pageIndex, this.noPagination ? undefined : this.pageSize, sort_col, this.search);
                }
                else {
                    this.getItems(undefined, undefined, undefined, undefined);
                }
            }
            else {
                this.getItems(this.pageIndex, this.pageSize, sort_col, this.search);
            }
        });
    }

    /**
     * Get items
     * 
     * @param {number} start
     * @param {number} count 
     * @param {string} sort (Optional)
     * @param {any} search (Optional)
     */
    abstract getItems(start: number, count: number, sort?: string, search?: any);


    addItem(item: T, pos?: number) {
        const items = this.dataSource.data;
        if(pos === undefined) {
            items.push(item);
        }
        else {
            items.splice(pos, 0, item);
        }

        if(this.preload) {
            this.preloadedItems.splice(pos, 0, item);
        }

        this.dataSource._updateChangeSubscription(); // 
    }

    removeSelectedItems() {
        const items = [...this.selection.selected];

        this.setSelectionStateForItems(items, false);

        for(const item of items) {
            this.removeItem(item);
        }

        this.refreshItems("remove selected items");
    }

    removeItems(items: T[]) {
        this.setSelectionStateForItems(items, false);

        for(const item of items) {
            this.removeItem(item);
        }

        this.refreshItems("remove items");
    }

    removeItem(item: T) {
        let removed: boolean = false;

        const items = this.dataSource.data;

        const pos = items.indexOf(item);

        if((pos != -1)) {
            items.splice(pos, 1);
            this.dataSource._updateChangeSubscription();
            removed = true;
        }

        if(this.preload) {
            const idx = this.preloadedItems.findIndex(i => i == item);
            if(idx != -1) {
                this.preloadedItems.splice(idx, 1);
                removed = true;
                this.removeFormControlsForItem(item)
            }
        }

        if(this.selection.selected.length > 0) {
            this.setSelectionStateForItems([item], false);
        }

        if(removed) {
            this.count--;
        }
        
        this.removeInternalRow(item);
        
        return removed;
    }

    findItemById(id: string) {
        return this.dataSource.data.find(i => (i as any).id == id);
    }

    removeItemById(id: string) {
        const item = this.findItemById(id);
        if(item) {
            this.removeItem(item);
            return true;
        }

        return false;
    }

    /**
     * Set the preload items list.
     */
    protected setPreloadItems(items: T[]) {
        // Clear previous allocated controls if any.
        this.clearItems();

        this.preload = true;
        this.preloadedItems = items;

        this.refreshItems("preloaded items initialized");
    }

    /**
     * Call with the entire list of items that needs to be filtered with the currents search, pagination and sorting.
     */
    protected setFullSourceItems(items: T[], start: number, count: number, sort?: string, search?: any) {

        if(this.preload && (this.preloadedItems == undefined)) {
            this.setPreloadItems(items);
            return;
        }

        let final_list = items;

        let totalItems = items.length;

        if(search) {
            const lowerCaseSearch = search.toLowerCase();

            final_list = final_list.filter(i => {
                for(const column of this.columns) {
                    if(!column.searchable) continue;

                    const obj = this.getRowValue(column, i);

                    if(typeof obj === 'string') {
                        if(obj.toLocaleLowerCase().indexOf(lowerCaseSearch) != -1) {
                            return true;
                        }
                    }
                }

                return false;
            });

            totalItems = final_list.length;
        }

        if(sort) {
            let sorting = sort;
            let ascending: boolean = true;

            if(sort.startsWith("-")) {
                sorting = sort.substring(1);
                ascending = false;
            }

            let sortingColumn = this.columns.find(c => c.key == sorting);

            // Use the value of the first column when the column is merged.
            if(sortingColumn.options?.mergeColumns) {
                if(sortingColumn.options.mergeColumns.length > 0) {
                    sortingColumn = sortingColumn.options?.mergeColumns[0];
                }
            }

            if(sortingColumn) {
                const thisList = this;
                final_list = [...final_list].sort(function (a, b) {

                    let va = thisList.getRowValue(sortingColumn, a);
                    let vb = thisList.getRowValue(sortingColumn, b);
                    let result;

                    if( (typeof va === 'string') && (typeof vb === 'string') ) {
                        result = va.localeCompare(vb, undefined, {sensitivity: 'base'});
                    }
                    else {
                        if(va < vb) {
                            result = -1;
                        }
                        else if(va > vb) {
                            result = 1;
                        }
                        else {
                            result = 0;
                        }
                    }
        
                    if(!ascending) {
                        result = -result;
                    }

                    return result;
                });
            }
        }

        if(start != undefined) {
            if(count != undefined) {
                final_list = final_list.slice(start, start + count);
            }
            else {
                final_list = final_list.slice(start);
            }
        }
        else if(count != undefined) {
            final_list = final_list.slice(0, count);
        }

        this.setItems(final_list, totalItems);
    }

    protected setItems(items: T[], count: number) {
        this.wasInitialized = true;

        if(this.testtype == "empty-list") {
            items = [];
            count = 0;
        }
        else if(this.testtype == "empty-" + this.constructor.name + "-list") {
            items = [];
            count = 0;
        }

        if(this.preload && (this.preloadedItems == undefined)) {
            this.setPreloadItems(items);
            return;
        }

        this.count = count;
        this.dataSource.data = items;

        // For when an item id is supplied as input.
        if(this.selectedItemId && (this.selectedItem == undefined)) {
            let newSelectedItem: any;

            if(this.preload && this.preloadedItems) {
                newSelectedItem = this.preloadedItems.find(i => (i as any).id == this.selectedItemId);    
            }
            else {
                newSelectedItem = items.find(i => (i as any).id == this.selectedItemId);
            }

            this.selectedItemId = undefined;
            this.selectedItem = newSelectedItem;

            if(this.selectedItem) {
                if(!this.selection.isSelected(this.selectedItem)) {
                    this.selection.select(this.selectedItem);
                }
            }
        }

        // Don't clear the form controls for the preloaded rows.
        if(!this.preload) {
            this.clearItems();
        }

        if(this.alwaysShowSelected && this.selectedItem) {
            this.setSelected(this.selectedItem);
        }
        this.hasCriterion = !!this.search;
        this.currentSearch = this.search;

        this.onItemsChanged.emit(this);
    }

    protected setResponse(response: CommonQueryResponse<T>) {
        this.setItems(response.result, response.count);
    }

    onMenuClick(item: any, event: any) {
        event.stopPropagation();
        this.setSelected(item, true);
    }

    onRowClick(item: any, event: any) {
        if(this.rowSelectable) {
            this.toggleSelected(item);
        }
    }

    protected setSelectedId(id: any) {
        const i = this.dataSource.data.find(i => (i as any).id == id);
        if(i) {
            this.setSelected(i);
        }
    }

    protected setSelected(item?: T, setFromList: boolean = false) {
        const selectedId = (item as any)?.id;
        if(this.selectedItemId != selectedId) {
            if(this.selectedItemToRemove) {
                this.dataSource.data = this.dataSource.data.filter(i => (i as any).id != this.selectedItemId);
                this.selectedItemToRemove = false;
            }
            this.selectedItemId = selectedId;
            this._selectedItem = item;
            this.onSelectedItemChanged(item);
            this.selectedItemChange.emit(item);
        }
        

        // Make sure the selected item is still in the data.
        if(!setFromList && this._selectedItem && this.alwaysShowSelected) {
            this.ensureSelected();
            const items = this.dataSource.data;
            // Add the item if it is not in the array.
            let item = items.find(i => (i as any).id == this.selectedItemId);
            if(!item) {
                // items.unshift(this.selectedItem);
                this.dataSource.data = [this._selectedItem, ...items];
                this.selectedItemToRemove = true;
            }
            else {
                this.selectedItemToRemove = false;
            }
        }

    }

    private ensureSelected(items?: T[]) {

        let fromSource: boolean = false;

        if(!items) {
            items = this.dataSource.data;
            fromSource = true;
        }

        if(this._selectedItem && this.alwaysShowSelected) {
            // Add the item if it is not in the array.
            let item = items.find(i => (i as any).id == this.selectedItemId);
            if(!item) {
                // items.unshift(this.selectedItem);
                items = [this._selectedItem, ...items];
                // console.log("Items##: " + JSON.stringify((items as any).map(i => i?.name)));
                if(fromSource) {
                    this.dataSource.data = items;
                }
                this.selectedItemToRemove = true;
            }
            else {
                this.selectedItemToRemove = false;
            }
        }

        return items;
    }

    protected toggleSelected(item?: T, setFromList: boolean = false) {
        const selectedId = (item as any)?.id;
        if(this.selectedItemId == selectedId) {
            this.setSelected(undefined);
        }
        else {
            this.setSelected(item, setFromList);
        }
    }

    protected onSelectedItemChanged(item: T) {
        
    }

    /**
     * Set the persistent key to save columns selection.
     * 
     * Can be called multiple times for dynamic list type change.
     * 
     * @param {string} key The persistency key.
     */
    protected initPersistentKey(key: string) {
        if(this.contextKey && key) {
            this.persistencyKey = key + "_" + this.contextKey;
        }
        else {
            this.persistencyKey = key;
        }

        
        if(key && this.compactRow) {
            this.persistencyKey += "-compact";
        }
        
        this.columns = [];
        this.displayedColumns = [];
        this.pageIndex = 0;

        this.loadPersistentKey();
    }

    /**
     * Load settings linked to the persistency key.
     * 
     */
    private loadPersistentKey() {
        if(this.persistencyKey) {
            this.pageSize = this.ls.getd(this.persistencyKey + "/pagesize", this.pageSizeDefault);
            this.sortColumn = this.ls.getd(this.persistencyKey + "/sort-column", this.sort?.active);
            this.sortDirection = this.ls.getd(this.persistencyKey + "/sort-direction", this.sort?.direction);
        }
    }

    setColumnTypeDefaultOptions(type: CommonColumnType|string = CommonColumnType.Text, options: CommonListColumnOptions) {
        if(!this.perTypeOptions) {
            this.perTypeOptions = {};
        }

        this.perTypeOptions[type] = options;
    }

    protected addColumn(label: string, key: string, selectable: boolean, selected: boolean, type: CommonColumnType.Date, options: CommonListDateColumnParameters): CommonListColumn;

    protected addColumn(label: string, key: string, selectable: boolean, selected: boolean, type?: CommonColumnType, options?: CommonListColumnOptions): CommonListColumn;

    /**
     * Add a column.
     * 
     * @param {string} label The label of the column.
     * @param {string} key The unique key representing the column.
     * @param {boolean} selectable Current working directory of the child process.
     * @param {boolean} selected The default visibility state of the column.
     * 
     * @returns {CommonListColumn} Returns the new generated column.
     */
    protected addColumn(label: string, key: string, selectable: boolean, selected: boolean, type: CommonColumnType = CommonColumnType.Text, options?: CommonListColumnOptions): CommonListColumn {
        let column = new CommonListColumn();
        column.label = label;

        // To allow same key used with different type.
        if(this.columns.find(c => c.key == key)) {
            column.columnKey = key + "_" + type;
        }
        else {
            column.columnKey = key;
        }
        
        column.key = key;
        if(key.indexOf(".") != -1) {
            column.keys = key.split(".");
        }
        column.selecteable = selectable;
        column.selected = selected;
        column.selectedByDefault = selected;
        column.type = type != undefined ? type : CommonColumnType.Text;
        column.csskey = column.key.replace("\.", "_");
        column.canSort = type == CommonColumnType.Action ? false : true;

        if(type == CommonColumnType.LanguageCode) {
            if(!options?.columnHeaderType) {
                if(!options) {
                    options = {};
                }
                options.columnHeaderType = CommonColumnHeaderType.LanguageCode;
            }
        }

        column.parameters = {};
        column.cellStyles = {};

        if(this.perTypeOptions?.[type]) {
            if(options) {
                options = Object.assign({}, this.perTypeOptions[type], options);
            }
            else {
                options = Object.assign({}, this.perTypeOptions[type], options);
            }
        }

        // TODO: Using Object.assign instead?
        if(options) {
            column.options = options;
            
            if(options.cellStyles) {
                column.cellStyles = Object.assign({}, options.cellStyles);
            }

            if(options.fixedWidth) {
                const width = options.fixedWidth;
                column.cellStyles["max-width"] = width;
                column.cellStyles["min-width"] = width;
                column.cellStyles["width"] = width;
            }

            if(options.rowStyles) {
                column.rowStyles = Object.assign({}, column.cellStyles, options.rowStyles);
            }
            else {
                column.rowStyles = Object.assign({}, column.cellStyles);
            }

            if(options.headerStyles) {
                column.headerStyles = Object.assign({}, column.cellStyles, options.headerStyles);
            }
            else {
                column.headerStyles = Object.assign({}, column.cellStyles);
            }

            if(options.footerStyles) {
                column.footerStyles = Object.assign({}, column.cellStyles, options.footerStyles);
            }
            else {
                column.footerStyles = Object.assign({}, column.cellStyles);
            }

            if(options.elementStyles) {
                column.elementStyles = Object.assign({}, column.elementStyles, options.elementStyles);
            }

            if(options.rowTypeKey !== undefined) {
                column.rowTypeKey = options.rowTypeKey;
            }

            if(options.isSortable !== undefined) {
                column.canSort = options.isSortable;
            }

            if(options.data !== undefined) {
                Object.assign(column.parameters, options.data);
            }

            if(options.columnHeaderType !== undefined) {
                column.headerType = options.columnHeaderType;
            }

            if(options.columnFooterType !== undefined) {
                column.footerType = options.columnFooterType;
            }

            if(options.templateColumnIdx !== undefined) {
                column.templateColumnIdx = options.templateColumnIdx;
            }

            if(options.templateFooterIdx !== undefined) {
                column.templateFooterIdx = options.templateFooterIdx;
            }

            if(options.templateCellIdx !== undefined) {
                column.templateCellIdx = options.templateCellIdx;
            }

            if(options.refreshItemsOnSelect !== undefined) {
                column.refreshItemsOnSelect = options.refreshItemsOnSelect;
            }

            if(options.footerLabel !== undefined) {
                column.footerLabel = options.footerLabel;
            }

            if(options.formControlValidatorType) {
                column.formControlValidatorType = options.formControlValidatorType;
                column.formControlValidators = this.formService.getValidators(options.formControlValidatorType);
            }
            else if(options.formControlValidators !== undefined) {
                column.formControlValidators = options.formControlValidators;
            }

            if(options.formControlAsyncValidators !== undefined) {
                column.formControlAsyncValidators = options.formControlAsyncValidators;
            }

            if(options.searchable !== undefined) {
                column.searchable = options.searchable;
            }
        }

        if(column.searchable == false) {
            column.searchable = type == CommonColumnType.Text;
        }

        if(column.headerType === undefined) {
            if(type == CommonColumnType.Selection) {
                column.headerType = CommonColumnHeaderType.Selection;
            }
            else {
                column.headerType = CommonColumnHeaderType.Normal;
            }
        }

        if(type == 'date') {
            if(!column.parameters.format) {
                column.parameters.format = "yyyy/M/d, h:mm a";
            }

            if(column.parameters.tooltipFormat == undefined) {
                column.parameters.tooltipFormat = "medium";
            }
        }

        this.columns.push(column);

        return column;
    }

    /**
     * Update the list of displayed columns.
     * 
     * @param {boolean} init If true it will use the last saved columns selection. 
     *                        Normally when initialising the columns you put true.
     *                        And after changing the colums selection you put false.
     */
    protected updateColumns(init: boolean) {
        const selectedColumns = this.columns.filter(c => c.selected);
        this.displayedColumns = selectedColumns.map(c => c.columnKey);
        this.displayedColumnsReferences = selectedColumns;

        if(!this.firstRefreshDone || init)
        {
            this.loadColumnsSelection();
        }

        this.lastVisibleColumn = undefined;
        for(const column of this.displayedColumnsReferences) {
            this.lastVisibleColumn = column;
        }

        this.onColumnsUpdate.emit(this.columns);
    }

    protected updateSelectedColumns() {
        const selectedColumns = this.columns.filter(c => c.selected);

        for(let i=0; i<selectedColumns.length; i++) {
            const selectedColumn = selectedColumns[i];
            const previousColumn = i < this.displayedColumns.length ? this.displayedColumns[i] : undefined;

            const isAlreadyDisplayed = this.displayedColumns.find(c => c == selectedColumn.columnKey);

            if(previousColumn) {
                if(previousColumn != selectedColumn.columnKey) {
                    const isSelected = !!selectedColumns.find(c => c.columnKey == previousColumn);
                    this.displayedColumns.splice(i, isSelected ? 0 : 1, selectedColumn.columnKey);
                }
            }
            else {
                this.displayedColumns.push(selectedColumn.columnKey);
            }
        }

        if(this.displayedColumns.length > selectedColumns.length) {
            const diff = this.displayedColumns.length - selectedColumns.length;
            this.displayedColumns.splice(this.displayedColumns.length - diff, diff);
        }

        if(selectedColumns.length != this.displayedColumns.length) {
            console.error("Nb columns mismatchs ##\n\tselectedColumns: " + JSON.stringify(selectedColumns.map(c => c.columnKey)) + "\n\t" + JSON.stringify(this.displayedColumns));
        }
 

    }

    onSelectedChanged(column: CommonListColumn, row: T, selected: boolean) {
        this.setSelectionStateForItems([row], selected);
    }

    protected setSelectionStateForItems(rows: T[], selected: boolean) {
        let changed: boolean = false;

        for(const row of rows) {
            if(!this.multiSelect) {
                this.selection.deselect(...this.selection.selected);
                changed = true;
            }

            if (selected) {
                if (!this.selection.isSelected(row)) {
                    this.selection.select(row);
                    changed = true;
                    this.selectedItem = row;
                }
            } else {
                if (this.selection.isSelected(row)) {
                    this.selection.deselect(row);
                    changed = true;
                }
            }
        }
        
        if(changed) {
            this.onSelectionChanged.emit(this.selection);
        }
    }

    onCheckboxChanged(column: CommonListColumn, row: T, checked: boolean) {
        this.setRowValue(column, row, checked, undefined, false);
    }

    onRadiobuttonChanged(column: CommonListColumn, row: T, checked: boolean, radioButton: any) {
        this.setRowValue(column, row, checked, undefined, false);
    }

    /**
     * Check if all items are currently selected. To initilise the column checkbox.
     */
    isAllSelected(): boolean {
        const selectedCount = this.selection.selected.length;

        if(this.preload) {
            return selectedCount >= this.preloadedItems.length;
        }
        
        const dataCount = this.dataSource.data.length;
        return dataCount == selectedCount;
    }

    /**
     * Select or unselect all. This is associated to the column checkbox.
     */
    selectionToggle(event: any) {
        if (this.isAllSelected()) {
            this.unselectAll();
        }
        else {
            this.selectAll();
        }
    }

    /**
     * Select all items
     */
    selectAll() {
        if(!this.dataSource.data) return;

        if(this.preload) {
            this.selection.select(...this.preloadedItems);
        }
        else {
            this.selection.select(...this.dataSource.data);
        }
        
        this.onSelectionChanged.emit(this.selection);
    }

    /**
     * Unselect all items
     */
    unselectAll() {
        if(!this.dataSource.data) return;

        this.selection.deselect(...this.selection.selected);
        this.onSelectionChanged.emit(this.selection);
    }

    selectItems(items: T[]) {
        this.selection.select(...items);
        
        this.onSelectionChanged.emit(this.selection);
    }

    getCustomLabel(key: string, val: any, row: any) {
        if(val) {
            return val.toString();
        }

        return "";
    }

    getAudioUrl(key: string, val: any, row: any): string {
        return val;
    }

    playAudio(key: string, val: any, row: any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const url: string = this.getAudioUrl(key, val, row);
            let audio = new Audio();
            audio.src = url;
            console.log("Audio to play: " + audio.src);
            audio.load();
            audio.play();
            audio.addEventListener('ended', () => {
                resolve(true);
                /*
                if(this.currentAudio == audio)
                {
                    this.currentAudio = undefined;
                }
                */
            })
        });
    }

    hasAudio(key: string, val: any, row: any): boolean {
        return true;
    }
    
    checkAudio(key: string, val: any, row: any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            resolve(false);
        });
    }

    getImageUrl(key: string, val: any, row: any): string {
        return "";
    }

    getImageThumbUrl(key: string, val: any, row: any): string {
        return this.getImageUrl(key, val, row);
    }

    hasImage(key: string, val: any, row: any): boolean {
        return true;
    }

    checkImage(key: string, val: any, row: any): Promise<boolean> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }

    getUserName(row: any, userId: string): string {

        return "";
    }

    public setSort(sort: MatSort) {
        if(!sort) {
            sort = this.dataSource.sort;
        }
        if (sort) {
            this.sort = sort;

            this.setDataSourceAttributes();

            // Detect first initialisation.
            if(this.sort != this.lastSort)
            {
                if(this.sort) {
                    // To resolve problem with the incorrect initial icon direction, must
                    // properly initialize the MatSort object.
                    // Source: https://stackblitz.com/edit/angular-mat-sort-default?file=app%2Ftable-sorting-example.ts
                    const sortState: Sort = {active: this.sortColumn, direction: this.sortDirection};

                    // The setTimeout is because sometime when this component is nested inside another component the following error is thrown: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value for 'attr.aria-sort': 'none'. Current value: 'descending'.. 

                    setTimeout(() => {
                        this.sort.active = sortState.active;
                        this.sort.direction = sortState.direction;
                        this.sort.sortChange.emit(sortState);    
                    });

                    this.sort.sortChange.subscribe(() => {
                        let changed: boolean = false;

                        if(this.sort.direction != this.sortDirection) {
                            changed = true;
                            // console.log("## Sort direction changed from " + this.sortDirection + " to " + this.sort.direction);
                            this.sortDirection = this.sort.direction;
                            if(this.sortDirection) {
                                this.ls.set(this.persistencyKey + "/sort-direction", this.sortDirection);
                            }
                            else {
                                this.ls.remove(this.persistencyKey + "/sort-direction");
                            }                        
                        }
                        if(this.sort.active != this.sortColumn) {
                            changed = true;
                            // console.log("## Sort column changed from " + this.sortColumn + " to " + this.sort.active);

                            this.sortColumn = this.sort.active;
                            if(this.sortColumn) {
                                this.ls.set(this.persistencyKey + "/sort-column", this.sortColumn);
                            }
                            else {
                                this.ls.remove(this.persistencyKey + "/sort-column");    
                            }
                        }
    
                        if(changed) {
                            this.refreshItems("Sorting Change");
                        }
                    });
                }


                this.lastSort = this.sort;
            }
        }
    }

    public setSearch(search: string) {
        this.search = search;
        this.pageIndex = 0;
        this.refreshItems("Set Search");
    }

    public setMenuActionItem(column: CommonListColumn, row: any) {
        this.selectedMenuActionColumn = column;
        this.selectedMenuActionItem = row;
        this.setSelected(row);
    }

    private saveColumnsSelection() {
        if(!this.persistencyKey) return;
        if(!this.columnSelectionPersistency) return;

        const key = this.persistencyKey + "/columns";

        let columns: any = {};

        for(const column of this.columns) {
            columns[column.columnKey] = this.displayedColumns.indexOf(column.columnKey) != -1;
        }

        this.ls.set(key, columns);
    }

    private loadColumnsSelection() {
        if(!this.persistencyKey) return;
        if(!this.columnSelectionPersistency) return;

        const key = this.persistencyKey + "/columns";

        let columns = this.ls.getd(key, {});

        let displayedColumns: string[] = [];
        let displayedColumnsReference: CommonListColumn[] = [];

        for(const column of this.columns) {
            let selectionColumn: any = columns[column.columnKey];

            let selected: boolean = column.selectedByDefault;

            if(selectionColumn != undefined) {
                selected = selectionColumn;
            }
            
            if(!column.selecteable) {
                selected = column.selectedByDefault;
            }
            column.selected = selected;
            if(selected) {
                displayedColumns.push(column.columnKey);
                displayedColumnsReference.push(column);
            }
        }

        this.displayedColumns = displayedColumns;
        this.displayedColumnsReferences = displayedColumnsReference;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.updateSize();
    }

    updateSize() {
        if(!this.supportCompactMode) {
            return;
        }

        this.innerWidth = window.innerWidth;
        const compactRow = this.innerWidth < 750;
        if(this.compactRow != compactRow) {
            this.compactRow = compactRow;
            this.onCompactModeChanged();
        }
    }

    onCompactModeChanged() {

    }

    setSelectedColumns(keys: string[]) {
        for(const column of this.columns) {
            column.selected = keys.indexOf(column.columnKey) != -1;
            column.selectedByDefault = column.selected;
        }
    }

    isColumnSelected(key: string) {
        const column = this.columns.find(c => c.columnKey == key);
        if(column) {
            return column.selected;
        }
        return false;
    }

    isCellDisabled(column: CommonListColumn, row: T): boolean {
        return false;
    }

    instantiateFormControl(column: CommonListColumn, row: any, initialValue: any) {
        return new UntypedFormControl(initialValue, column.formControlValidators || [], column.formControlAsyncValidators || []);
    }

    getRowValue(column: CommonListColumn, row: any, keys?: string[]) {
        keys = keys || column.keys;

        if(row == undefined) {
            console.error("## Empty row with column " + column.key + " for keys: " + JSON.stringify(keys));
            return "{error}";
        }

        if(keys) {
            for (let i = 0; i < keys.length; i++) {
                if(row) {
                    const key = keys[i];
                    row = row[key];
                } else {
                    break;
                }
            }
        }
        else {
            row = row[column.key];
        }

        return row;
    }

    setRowValue(column: CommonListColumn, row: any, val: any, keys?: string[], updateFormControl: boolean = true) {
        keys = keys || column.keys;

        if(keys) {
            for (let i = 0; i < keys.length; i++) {
                if(row) {
                    const key = keys[i];
                    if(i == (keys.length - 1)) {
                        row[key] = val;
                    }
                    else {
                        row = row[key];
                    }
                } else {
                    break;
                }
            }
        }
        else {
            row[column.key] = val;
        }

        // If column have controls.
        if(updateFormControl && column.formArray) {
            const control = this.getFormControl(column, row, false);
            if(control) {
                if(control.value != val) {
                    control.setValue(val);
                }
            }
        }

        return row;
    }

    onFormStatusChanged(column: CommonListColumn, control: AbstractControl, status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED') {
        // console.log("## onFormStatusChanged() " + this.constructor.name + ".isValid: " + control.valid);
        this.areControlsValid = control.valid;
    }

    
    getFormControl(column: CommonListColumn, row: any, forceCreate: boolean = true) {
        const formKey = column.columnKey + "_control";

        if(!this.formArray) {
            if(!forceCreate) {
                return;
            }

            this.formArray = new UntypedFormArray([]);
            this.formArray.statusChanges.subscribe((status) => {
                this.onFormStatusChanged(column, this.formArray, status);
            });
        }

        if(!column.formArray) {
            if(!forceCreate) {
                return;
            }

            column.formArray = new UntypedFormArray([]);
            this.formArray.push(column.formArray);
            // this.formArray.statusChanges.subscribe((status) => {
            //     this.onFormStatusChanged(column, this.formArray, status);
            // });
        }

        const internalRow = this.getInternalRow(row);
        let controls = internalRow.formControls;
        if(!controls) {
            controls = {};
            internalRow.formControls = controls;
        }

        let control = controls[formKey];
        if(!control) {
            if(!forceCreate) {
                return;
            }

            const initialValue = this.getRowValue(column, row);
            control = this.instantiateFormControl(column, row, initialValue);
            if(!control) {
                control = new UntypedFormControl(initialValue, []);
            }
            
            controls[formKey] = control;

            column.formArray.push(control);
            control.valueChanges.pipe(
                distinctUntilChanged(),
                debounceTime(500)
            ).subscribe((value) => {
                this.setRowValue(column, row, value, undefined, false);
            });

            // We must let the control be binded to the UI otherwise I had an error
            // at initialization where the statusChanges event wasn't emitted for the status changed from PENDING to VALID.
            setTimeout(() => {
                if(initialValue) {
                    control.markAsTouched();
                    control.updateValueAndValidity();
                }
                else {
                    control.markAsPristine();
                }
            })
        }

        return control;
    }

    protected removeFormControlsForItem(item: T) {
        if(!this.formArray) return false;

        let result: boolean = false;

        const internalRow = this.getInternalRow(item);
        if(internalRow.formControls) {
            for(const key of Object.keys(internalRow.formControls))             {
                const control = internalRow.formControls[key];
                if(control) {
                    const parentFormArray = control.parent as UntypedFormArray;

                    if(parentFormArray) {
                        const idx = parentFormArray.controls.findIndex(c => c == control);
                        if(idx != -1) {
                            parentFormArray.removeAt(idx);
                            result = true;
                        }
                    }
                }
            }

            internalRow.formControls = undefined;

            return result;
        }
    }

    private removeFormControl(column: CommonListColumn, row: any) {
        let controls: {[key: string]: UntypedFormControl} = row['form-controls'];
        if(!controls) {
            return;
        }

        const formKey = column.columnKey + "_control";

        let control = controls[formKey];
        if(!control) {
            return;
        }

        delete controls[formKey];

        if(this.formArray && (this.formArray.length > 0)) {
            const idx = this.formArray.controls.findIndex(c => c == control);
            if(idx != -1) {
                this.formArray.removeAt(idx);
            }
        }
    }

    private clearFormControls() {
        if(!this.formArray || (this.formArray.length == 0)) {
            return;
        }

        this.formArray.clear();
    }

    private clearItems() {
        this.clearFormControls();
        this.clearInternalRows();
    }

    showFormDebug() {
        if(this.formArray) {
            AngularUtils.showFormArrayState(this.constructor.name + " Form", this.formArray);
        }
    }

    canShowAction(row: any) {
        return true;
    }

    /**
     * Set the options.
     */
    setOptions(v: CommonListRenderOptions) {
        if(this.renderer) {
            Object.assign(this.rendererOptions, v);
            this.renderer.updateOptions(v);
        }
        else {
            setTimeout(() => {
                this.setOptions(v);
            });
        }
    }
}
