import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Currency, CurrencyOptions } from '@applogic/model';
import { LanguageService } from '../services/language.service';
import { ProductService } from '../services/product.service';

export abstract class ApCurrencyCommonPipe implements PipeTransform {

    constructor(private languageService: LanguageService) {

    }

    abstract transform(value: any, ...args: any[]);

    protected fillOptions(options: CurrencyOptions): CurrencyOptions {
        if(!options) {
            options = {};
        }

        if(window.location.hostname.endsWith(".ca") || (window.location.hostname.indexOf("localhost") != -1)) {
            options.countryCode = "ca";
        }

        if(!options.languageCode) {
            options.languageCode = this.languageService.currentLanguageCode;
        }

        return options;
    }
}
