import { Injectable } from "@angular/core";
import { ApiDirectoryService } from "./api-directory.service";
import { RoutesServer, ApiRoutes, PromoCodeTicket, ApplyPromoCodeForm } from "@applogic/model";
import { HttpParamsFromModelProperties } from "./angular-utils";
import { JsonSerializer } from "@uon/model";

const PROMO_CODE_TICKET_SERIALIZER = new JsonSerializer(PromoCodeTicket);

@Injectable({
    providedIn: 'root'
})
export class PromoCodeService {


    constructor(private dirService: ApiDirectoryService) { }

    /**
     * APPLY PROMOCODE
     * @param promoCode 
     */
    applyPromoCode(promoCode: any, form: ApplyPromoCodeForm): Promise<PromoCodeTicket> {

        const httpParams = HttpParamsFromModelProperties(ApplyPromoCodeForm, form);
        
        return new Promise((resolve, rejects) => {
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.PromoCode, `/${promoCode}`, { 
                params: httpParams,
                withCredentials: true
            }).subscribe(res => {
                resolve(PROMO_CODE_TICKET_SERIALIZER.deserialize(res, true));
            }, (error) => {

                const localizeError = this.localizeErrorMessage(error)
                rejects(localizeError);

            })
        });
    }

    localizeErrorMessage(errorMessage) {
        if (errorMessage?.error?.message === "This Promo Code is invalid.") {
            return $localize`:@@promo-code-error-invalid:This Promo Code is invalid.`;
        } else if (errorMessage?.error?.message === "This Promo Code already used.") {
            return $localize`:@@promo-code-error-is-used:This Promo Code already used.`;
        } else {
            return $localize`:@@promo-code-error-is-expired:This Promo Code is expired.`;
        }
    }
}
