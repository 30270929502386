<app-common-list-renderer [list]="list">
    <renderer-templates [emptyTemplate]="emptyTemplate"
                        [templates]="[userRoleTemplate]">
        <app-organization-member-card-template listClass="app-property-list-container"
                                               [organization]="organization"></app-organization-member-card-template>
    </renderer-templates>
    <app-common-list-renderer-options>
    </app-common-list-renderer-options>
    <button mat-menu-item
            (click)="resendInvite(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="primary">refresh</mat-icon>
        <span i18n="@@common-resend-invitation">Resend invitation</span>
    </button>
    <button mat-menu-item
            (click)="openChangeInviteRoleDialog(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="accent">person</mat-icon>
        <span i18n="@@org-member-change-member-role-text">Change role</span>
    </button>
    <button mat-menu-item
            (click)="deleteInvite(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="warn">close</mat-icon>
        <span i18n="@@org-cancel-invitation">Cancel invitation</span>
    </button>
</app-common-list-renderer>

<ng-template #userRoleTemplate
             let-data>
    <app-organization-role-chip [role]="data?.row.role"
                                *ngIf="data?.row && data?.row.role"></app-organization-role-chip>
</ng-template>

<ng-template #emptyTemplate>
    <p style="text-align: center;">
        <em class="secondary-text"
            i18n="@@organization-details-no-invitation-message">
            No invitation outstanding.
        </em>
        <br>
        <br>
    </p>
</ng-template>