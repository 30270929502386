import { BACKSPACE } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { MultiCounters, Utils } from '@applogic/model';

export enum QuantityInputType {
    Counters = "counters",
    Number = "number"
}
@Component({
    selector: 'app-quantity-input',
    templateUrl: './quantity-input.component.html',
    styleUrls: ['./quantity-input.component.scss']
})
export class QuantityInputComponent implements OnInit, OnChanges {

    @Input()
    type: QuantityInputType = QuantityInputType.Counters;

    @Output()
    onQuantityChanged: EventEmitter<MultiCounters> = new EventEmitter();

    @Input()
    quantity: MultiCounters = {};

    @Input()
    max: MultiCounters = {};

    @Input()
    min: MultiCounters = {};

    @Input()
    counterKey: string;

    @Input()
    center: boolean = false;

    @Output()
    onQuantityModelChanged: EventEmitter<number> = new EventEmitter();

    @Input()
    quantityModel: number = 0;

    @Input()
    maxQuantity: number;

    @Input()
    minQuantity: number;

    canDecrease: boolean = false;
    canIncrease: boolean = true;

    @Input()
    showControls: boolean = true;

    @ViewChild('textInput1') textInput1!: ElementRef;
    @ViewChild('textInput2') textInput2!: ElementRef;

    @Input()
    style: 'normal'|'flat' = 'normal';

    private _lastWidth: number = 0; // Last ajusted width.
    private readonly WIDTH_THRESHOLD: number = 10; // Width change threshold.


    constructor() { }

    ngOnInit(): void {
        if (this.type == QuantityInputType.Counters) {
            if (this.quantity && this.counterKey) {
                if (!this.quantity[this.counterKey]) {
                    this.quantity[this.counterKey] = 0;
                }

                this.onChangeQuantityNumber();
            }
        }
    }

    ngAfterViewInit(): void {
        this.adjustInputsWidth();
        setTimeout(() => {
            this.adjustInputsWidth();
        });
    }


    /**
     * 
     * 
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.updateButtons();
    }

    /**
     * Only Number Enter 
     * @param event 
     */
    keyPress(event: KeyboardEvent, textInput: HTMLTextAreaElement) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != BACKSPACE && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    keyUp(event: KeyboardEvent, textInput: HTMLTextAreaElement) {
        
    }

    keyDown(event: KeyboardEvent, textInput: HTMLTextAreaElement) {
        this.adjustInputWidth(textInput);
    }

    onInput(event: any, textInput: HTMLTextAreaElement) {
        this.adjustInputWidth(textInput);
    }

    adjustInputsWidth() {
        if (this.textInput1) {
            this.adjustInputWidth(this.textInput1.nativeElement);
        }

        if (this.textInput2) {
            this.adjustInputWidth(this.textInput2.nativeElement);
        }
    }

    adjustInputWidth(textInput: HTMLTextAreaElement) {
        const calculator = document.getElementById('textWidthCalculator');
        if (calculator) {
            calculator.textContent = textInput.value || textInput.placeholder;
            let calculatedWidth = calculator.offsetWidth;

            if ( !this._lastWidth || (this._lastWidth < calculatedWidth) || Math.abs(this._lastWidth - calculatedWidth) > this.WIDTH_THRESHOLD) {
                textInput.style.width = `${calculatedWidth}px`;
                this._lastWidth = calculatedWidth; // Update the last calculated value.
            }
        }
    }

    /**
     * Increase quantity
     * @param index 
     */
    increaseQuantity() {
        if (this.type == QuantityInputType.Number) {
            if (this.quantityModel == undefined) {
                this.quantityModel = 1;
            }
            else {
                this.quantityModel++;
            }
        }
        else {
            if (!this.quantity[this.counterKey]) {
                this.quantity[this.counterKey] = 1;
            } else {
                this.quantity[this.counterKey]++;
            }
        }

        this.onChangeQuantityNumber();
    }

    /**
     * Decrease quantity
     * @param index 
     */
    decreaseQuantity() {
        if (this.type == QuantityInputType.Number) {
            if (this.quantityModel == undefined) {
                this.quantityModel = -1;
            }
            else {
                this.quantityModel--;
            }
        }
        else {
            if (!this.quantity[this.counterKey]) {
                this.quantity[this.counterKey] = -1;
            } else {
                this.quantity[this.counterKey]--;
            }
        }

        this.onChangeQuantityNumber();
    }

    onChangeQuantityNumber() {
        this.updateButtons();

        if (this.type == QuantityInputType.Number) {
            this.onQuantityModelChanged.emit(this.quantityModel);
        }
        else {
            this.onQuantityChanged.emit(this.quantity);
        }

        setTimeout(() => {
            this.adjustInputsWidth();
        });
    }

    /**
     * Update buttons states.
     * 
     * It also make sure values are number.
     */
    private updateButtons() {
        let canIncrease: boolean = true;
        let canDecrease: boolean = true;

        if (this.type == QuantityInputType.Number) {
            if (typeof this.quantityModel !== "number") {
                if (this.quantityModel == undefined) {
                    this.quantityModel = 0;
                } else {
                    let str = String(this.quantityModel);
                    if (str.length > 0) {
                        this.quantityModel = parseInt(String(this.quantityModel));
                    }
                    else {
                        this.quantityModel = 0;
                    }
                }
            }

            if (this.maxQuantity != undefined) {

                if (this.quantityModel >= this.maxQuantity) {
                    this.quantityModel = this.maxQuantity;
                    canIncrease = false;
                }
            }

            if (this.minQuantity != undefined) {

                if (this.quantityModel <= this.minQuantity) {
                    this.quantityModel = this.minQuantity;
                    canDecrease = false;
                }
            } else {
                canDecrease = this.quantityModel > 0;
            }
        }
        else {
            for (const k of Object.keys(this.quantity)) {
                if (typeof this.quantity[k] !== "number") {
                    if (this.quantity[k] == undefined) {
                        this.quantity[k] = 0;
                    } else {
                        this.quantity[k] = parseInt(this.quantity[k].toString());
                    }
                }
            }


            const key = this.counterKey;

            // console.log("min: "+ this.min[key] + " max: " + this.max[key] + " qty: " + this.quantity[key]);

            if (this.max) {
                let max = this.max[key] ? this.max[key] : 0;

                if (this.quantity[key] >= max) {
                    this.quantity[key] = max;
                    canIncrease = false;
                }
            }

            if (this.min) {
                let min = this.min[key] ? -this.min[key] : 0;

                if (this.quantity[key] <= min) {
                    this.quantity[key] = min;
                    canDecrease = false;
                }
            } else {
                canDecrease = this.quantity[this.counterKey] > 0;
            }
        }

        this.canIncrease = canIncrease;
        this.canDecrease = canDecrease;
    }
}
