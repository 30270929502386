import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Classroom, ClassroomHomework, Student } from '@applogic/model';
import { MessageBoxDialogComponent } from 'src/app/core/message-box-dialog/message-box-dialog.component';
import { MessageBoxButton } from 'src/app/core/message-box-dialog/messagebox-button';
import { MessageBoxIcon } from 'src/app/core/message-box-dialog/messagebox-icon';
import { MessageBoxResult } from 'src/app/core/message-box-dialog/messagebox-result';
import { MessageBoxSettings } from 'src/app/core/message-box-dialog/messagebox-settings';
import { HomeworkCreateDialogComponent } from '../homework-create-dialog/homework-create-dialog.component';
import { HomeworkService } from '../homework.service';
import { Subscription as RxSubscription } from "rxjs";
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { CommonColumnType, CommonListColumn } from 'src/app/core/common-list/common-list-column';

@Component({
    selector: 'app-homework-list',
    templateUrl: './homework-list.component.html',
    styleUrls: ['./homework-list.component.scss']
})
export class HomeworkListComponent extends CommonListComponent<ClassroomHomework> implements OnInit, OnDestroy, AfterViewInit {

    @Input() classroom: Classroom;

    // When defined, the selection will be initialized with homeworks associated to this student.
    @Input() student: Student;

    @Input() showEnabledOnly: boolean = false;

    @Input() displayForSelection: boolean = false;

    @Input() actionTemplate;

    homeworks: ClassroomHomework[] = [];
    filteredHomeworks: ClassroomHomework[] = [];

    canAddRemoveHomework: boolean = true; // TODO

    private homeworkSubscription: RxSubscription;

    private static homeworksLists: {[key: string]: {count: number, homeworks: ClassroomHomework[]}} = {};

    constructor(
        private injector: Injector,
        private homeworkService: HomeworkService,
        private cdr: ChangeDetectorRef) {
        super(injector);

        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 20];
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.homeworks = [];

        this.initColumns();

        this.homeworkSubscription = this.homeworkService.subscribeHomeworks(this.classroom.id, (homeworks) => {
            this.homeworks = homeworks;
            this.updateData();
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if(this.homeworkSubscription) {
            this.homeworkSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    initColumns() {
        this.updateColumns(true);

        let key = 'applogic/classroom/homework-list';

        if(this.displayForSelection) {
            key += "-selection";
        }

        this.initPersistentKey(key);

        if(this.displayForSelection) {
            this.addColumn("", "select", true, true, CommonColumnType.Selection);
        }

        const labelColumn = this.addColumn($localize`:@@classroom-homework-label-column:Title`, "label", true, false, CommonColumnType.Text, {
            
        });

        const isEnabledColumn = this.addColumn("", "isEnabled", true, false, CommonColumnType.Activated, {

        });

        this.addColumn($localize`:@@classroom-homework-label-column:Title`, "mergedLabelColumn", true, true, CommonColumnType.MergeColumns, {
            mergeColumns: [labelColumn, isEnabledColumn]
        });

        if(this.compactRow) {

        }

        this.addColumn($localize`:@@classroom-homework-assignation-column:Assignment`, "students", true, true, CommonColumnType.Template, {
            templateCellIdx: 0,
            fixedWidth: "100px"
        });

        if(!this.displayForSelection) {
            this.addColumn("", "actions", false, true, CommonColumnType.Action);
        }

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        // No implementations since using homework are preloaded.
    }

    showAddHomeworkDialog() {
        HomeworkCreateDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroom: this.classroom
        }).show().then((homework) => {
            if (homework) {
                this.homeworks.push(homework);
                this.updateData();
            }
        });
    }

    showEditHomeworkDialog(homework: ClassroomHomework) {
        HomeworkCreateDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroom: this.classroom,
            homework
        }).show().then(() => {

        });
    }

    showRemoveHomeworkDialog(homework: ClassroomHomework) {
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@classroom-homework-delete-paragraph1-confirm-text:You are about to delete the homework <strong>${homework.label}</strong>
            from your classroom.`,
            $localize`:@@classroom-homework-delete-paragraph2-confirm-text-notice:Deleting this homework will also remove the associated students progression. <br>
            This action cannot be undone.`
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
        ];

        MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings).show().then((result) => {
            if (result == MessageBoxResult.OK) {
                this.homeworkService.removeHomework(this.classroom.id, homework.id).subscribe(() => {
                    let idx = this.homeworks.indexOf(homework);
                    if (idx != -1) {

                        this.homeworks.splice(idx, 1);

                        this.updateData();
                    }
                });
            }
        });
    }

    updateData() {

        if (this.showEnabledOnly) {
            this.filteredHomeworks = this.homeworks.filter(h => h.isEnabled);
        }
        else {
            this.filteredHomeworks = this.homeworks;
        }

        this.setPreloadItems(this.filteredHomeworks);

        if (this.student) {
            const selectedItems = this.filteredHomeworks.filter(h => h.classroomShared || (h.students && h.students.find(s => s.studentId == this.student.id)));
            this.selectItems(selectedItems);
        }
    }

    isCellDisabled(column: CommonListColumn, row: ClassroomHomework): boolean {
        if(column.key == "select") {
            return row.classroomShared;
        }

        return super.isCellDisabled(column, row);
    }
}
