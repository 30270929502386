import { Injectable, Output, EventEmitter } from "@angular/core";
import { Observable, of, Subscription as RxSubscription, throwError } from "rxjs";
import { ClassroomHomework, Utils, RoutesServer, ApiRoutes } from "@applogic/model";
import { JsonSerializer } from "@uon/model";
import { map, catchError } from "rxjs/operators";
import { SharedListService } from "../services/shared-list.service";
import { ApiDirectoryService } from "../services/api-directory.service";
const HOMEWORK_SERIALIZER = new JsonSerializer(ClassroomHomework);


@Injectable({
    providedIn: "root",
})
export class HomeworkService {

    constructor(
        private sharedList: SharedListService,
        private dirService: ApiDirectoryService) {
    }

    subscribeHomeworks(classroomId: string, updateCallback: (list: ClassroomHomework[]) => void): RxSubscription {
        return this.sharedList.allocate("homeworks" + classroomId, () => {
            return new Promise((resolve, rejects) => {
                this.getHomeworks(classroomId).subscribe((list) => {
                    resolve(list);
                }, (err) => {
                    rejects(err);
                });
            })
        }, updateCallback, false)
    }

    getHomeworks(classroomId: string): Observable<ClassroomHomework[]> {

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Homework, `/${classroomId}`, { withCredentials: true }).pipe(
            map((r: any[]) => r.map((v) => HOMEWORK_SERIALIZER.deserialize(v, true))),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    createHomework(homework: ClassroomHomework): Observable<ClassroomHomework> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Homework, `/${homework.classroomId}`, homework, { withCredentials: true }).pipe(
            map((r: any) => HOMEWORK_SERIALIZER.deserialize(r, true)),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    updateStudentHomeworks(classroomId: string, studentId: string, homeworksToAdd: string[], homeworksToRemove: string[]) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Homework, `/classroom-${classroomId}/student/${studentId}`, {
            homeworksToAdd,
            homeworksToRemove
        }, { withCredentials: true }).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    updateHomework(homework: ClassroomHomework, currenthomework: ClassroomHomework) {
        
        let prevStudentIdList: string[] = [];
        if(currenthomework.students) prevStudentIdList = currenthomework.students.map((s) => s.studentId);

        let nextStudentIdList: string[] = [];
        if(homework.students) nextStudentIdList = homework.students.map((s) => s.studentId);

        
        let diff = Utils.extractAddedAndRemoved(prevStudentIdList, nextStudentIdList);

        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Homework, `/${homework.classroomId}`, {
            homeworkId: homework.id,
            label: homework.label,
            desc: homework.desc,
            lang: homework.lang,
            eqolListId: homework.eqolListId,
            isEnabled: homework.isEnabled,
            classroomShared: homework.classroomShared,
            addStudents: diff.added,
            removeStudents: diff.removed,
            wordsyntaxOptions: homework.wordsyntaxOptions,
            wordsSource: homework.wordsSource,
            words: homework.words
        }, { withCredentials: true }).pipe(
            map((r: any) => HOMEWORK_SERIALIZER.deserialize(r, true)),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    removeHomework(classroomId: string, homeworkId: string) {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Homework, `/${classroomId}/${homeworkId}`, { withCredentials: true }).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );;
    }
}
