import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { Subscription } from 'rxjs';
import { RadioGroupComponent } from '../radio-group/radio-group.component';


@Component({
    selector: 'app-radio-select-button',
    templateUrl: './radio-select-button.component.html',
    styleUrls: ['./radio-select-button.component.scss']
})
export class RadioSelectButtonComponent implements OnInit, OnDestroy {



    _formControl1: UntypedFormControl;

    @Input()
    set formControl1(formControl: UntypedFormControl) {
        if (this._formControl1 != formControl) {
            this._formControl1 = formControl;
            if (formControl) {
                this.disabled = formControl.disabled;
                formControl.statusChanges.subscribe((status) => {
                    this.disabled = formControl.disabled;
                });
                formControl.valueChanges.subscribe((value) => {
                    this.checked = value;
                });
            }
        }
    }

    get formControl1() {
        return this._formControl1;
    }

    @Input()
    group: string;

    @Input()
    value: any;

    @ViewChild(MatRadioButton, { static: true }) radioButton: MatRadioButton;

    @Output()
    selectedValueChanged = new EventEmitter<any>();

    @Input()
    canDeselect: boolean = false;

    private _checked: boolean;

    @Input()
    get checked(): boolean {
        return this._checked;
    }

    set checked(v: boolean) {
        if (v !== this._checked) {
            this.setChecked(v, false);
        }
    }

    // Occurs when user click on the radio button (or programmatically set the value and specify to trigger an event).
    @Output()
    onCheck: EventEmitter<RadioSelectButtonComponent> = new EventEmitter<RadioSelectButtonComponent>();

    @Output()
    change: EventEmitter<RadioSelectButtonComponent> = new EventEmitter<RadioSelectButtonComponent>();

    _disabled: boolean = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(v: boolean) {
        if (v !== this._disabled) {
            this._disabled = v;
            this.disabledChange.emit(v);
        }
    }

    @Output()
    disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();


    private _subscription: Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef, @Optional() private radioGroup: RadioGroupComponent) {
        
    }

    ngOnInit(): void {
        if (this.radioGroup) {
            this.group = this.radioGroup.key;
        }

        if (!this.group) {
            return;
        }

        let group = RadioGroupComponent.AddButton(this.group, this);

        if ((group.selectedValue != undefined) && (this.value == group.selectedValue)) {
            
            // Using setTimeout() Because sometimes at some places the following exception occurs: ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                this.setChecked(true, false);
            });
        }

        this._subscription = group.valueChanged.subscribe((v) => {
            this.selectedValueChanged.emit(v);
        });
    }

    ngOnDestroy(): void {
        if (!this.group) {
            return;
        }

        RadioGroupComponent.RemoveButton(this.group, this);

        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    onRadioButtonChanged(event: MatRadioChange) {
        this.setChecked(event.source.checked, true);
    }

    onButtonClick(event: any) {
        event.stopPropagation();
    }

    public setChecked(checked: boolean, isEvent: boolean) {
        if(this._checked == checked) {
            return;
        }

        this._checked = checked;

        if (checked == true) {
            RadioGroupComponent.SetSelectedValue(this.group, this.value);
        }

        if (this._formControl1) {
            this._formControl1.setValue(checked);
        }

        if ((checked == true) && isEvent) {
            this.onCheck.emit(this);
        }

        this.change.emit(this);
    }

}
