import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { GlobalMessageService } from 'src/app/services/global-message.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { FADE_IN_OUT, OPACITY_IN_OUT } from 'src/app/animations/fade';
import { DialogService } from 'src/app/services/dialog.service';


export enum PageType {
    Normal = 'normal',
    Centered = 'centered',
    CenteredFullWidth = 'centered-full-width',
    Full = 'full'
}

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrl: './page.component.scss',
    animations: [FADE_IN_OUT, OPACITY_IN_OUT]
})
export class PageComponent {

    PageType = PageType;
    
    _title: string;

    @Input()
    set title(val: string) {
        this._title = val;
        this.elementRef.nativeElement.title = "";
        this.updateTitle();
    }

    protected currentTitle: string;

    @Input()
    hasMenuButton: boolean = true;

    @Input()
    showToolbar: boolean = true;

    @Input()
    type: PageType = PageType.Normal;

    globalMessage: any;

    @Input()
    showFullLogo: boolean = true;

    @Input()
    showLogo: boolean = false;
    
    @HostBinding('class.no-overflow-x')
    @Input()
    hideOverflowX: boolean = false;
    
    privacyLink: SafeUrl;
    termsLink: SafeUrl;
    
    private breakpointSub: Subscription;

    @HostBinding('class') get getHostClass() {
        return "app-page--" + this.type + "-page";
    }

    constructor(
        private elementRef: ElementRef,
        protected auth: AuthService,
        readonly nav: NavigationService,
        readonly breakpoints: BreakpointObserver,
        private globalMessageService: GlobalMessageService,
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        protected dialogService: DialogService) {
        this.globalMessage = this.globalMessageService.getMessage();

        this.privacyLink = sanitizer.bypassSecurityTrustUrl($localize`:@@link-privacy:https://rebrand.ly/appligo-privacy-en`);
        this.termsLink = sanitizer.bypassSecurityTrustUrl($localize`:@@link-terms:https://rebrand.ly/appligo-terms-en`);
        
        this.breakpointSub = this.breakpoints.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
            .subscribe((match) => {

                this.showFullLogo = match.matches
            })
    }

    ngOnInit() {
        this.updateTitle();
    }

    ngOnDestroy() {
        if (this.breakpointSub) this.breakpointSub.unsubscribe();
    }

    private updateTitle() {
        if(this._title) {
            this.currentTitle = this._title;
        }
        else if(this.route.snapshot.data?.title) {
            // let routeSnapshot = this.route.snapshot;
            // while(routeSnapshot) {
            //     routeSnapshot = routeSnapshot.parent;
            // }
            
            this.currentTitle = this.route.snapshot.data.title;
        }
        else {
            this.currentTitle = "";
        }
    }
}
