import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { RadioGroupComponent } from 'src/app/core/radio-group/radio-group.component';

export enum RadioSelectCardButtonStyle {
    Normal = 'normal',
    TwoCards = 'two-cards',
    Custom = 'custom'
}
@Component({
    selector: 'app-radio-select-card-button',
    templateUrl: './radio-select-card-button.component.html',
    styleUrls: ['./radio-select-card-button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RadioSelectCardButtonComponent {
    
    RadioSelectCardButtonStyle = RadioSelectCardButtonStyle;

    @Input()
    group: string;

    @Input()
    value: any;

    @Input()
    cardStyle: RadioSelectCardButtonStyle = RadioSelectCardButtonStyle.TwoCards;

    @HostBinding('class') get getClasses() {
        if(this.cardStyle == RadioSelectCardButtonStyle.TwoCards) {
            return 'two-cards';
        }

        return 'normal-cards';
    }

    _disabled: boolean = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(v: boolean) {
        if (v !== this._disabled) {
            this._disabled = v;
            this.disabledChange.emit(v);
        }
    }

    @Output()
    disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    _checked: boolean;

    get checked(): boolean {
        return this._checked;
    }

    set checked(v: boolean) {
        if (v !== this._checked) {
            this._checked = v;

            if (v == true) {
                RadioGroupComponent.SetSelectedValue(this.group, this.value);
            }
        }
        
    }

    @Output()
    onCheck: EventEmitter<RadioSelectCardButtonComponent> = new EventEmitter<RadioSelectCardButtonComponent>();

    @Input()
    hasCardText: boolean = true;
}
