<div class="container">
    <div class="left-pane">
        <div class="left-container">
            <ng-content select="[left-pane]"></ng-content>
        </div>
    </div>
    <div class="right-pane">
        <div class="right-container">
            <ng-content select="[right-pane]"></ng-content>
        </div>
    </div>
</div>