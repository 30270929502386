import { ArrayMember, Member, Model } from "@uon/model";
import { Student } from "../user/student.model";
import { User } from "../user/user.model";
import { OrganizationInvite } from "../org/invite.model";
import { Organization } from "../org/organization.model";
import { SubscriptionAllocation } from "../subscription/subscription-allocation.model";
import { Subscription, SubscriptionPlan } from "../subscription/subscription.model";
import { SubscriptionSeat } from "../subscription/subscription-seat.model";
import { Classroom } from "../org/classroom.model";
import { AppReceiptAssociation } from "../appreceipt/app-receipt-association.model";
import { AppReceipt } from "../appreceipt/app-receipt.model";

@Model()
export class OrgDetailsResponse {
    @Member()
    org: Organization;

    @ArrayMember(Subscription)
    subs: Subscription[];

    @ArrayMember(OrganizationInvite)
    invites: OrganizationInvite[];

    @Member()
    classroomCount: number;

    @Member()
    studentCount: number;

    @ArrayMember(SubscriptionAllocation)
    subscriptionAllocations: SubscriptionAllocation[];
}


@Model()
export class SubscriptionAllocationDetailsResponse {

    @Member()
    allocation: SubscriptionAllocation;

    @ArrayMember(SubscriptionSeat)
    seats: SubscriptionSeat[];

    @ArrayMember(User)
    users: User[];

    @ArrayMember(Student)
    students: Student[];

    @ArrayMember(Classroom)
    classrooms: Classroom[];
}

@Model()
export class AdminAddOrgSubscriptionQuery {

    @Member()
    orgId: string;

    @Member()
    productCode: string;

    @Member()
    expiresOn: Date;

    @Member()
    plan: SubscriptionPlan; 

    @Member()
    seats: number;

    @Member()
    userId: string;
}

@Model()
export class UserDetailsResponse {

    @Member()
    user: User;

    @ArrayMember(Organization)
    orgs: Organization[];

    @ArrayMember(AppReceipt)
    appReceipts: AppReceipt[];

    @ArrayMember(AppReceiptAssociation)
    associations: AppReceiptAssociation[];
}