import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Classroom, User } from '@applogic/model';
import { UsersSelectionComponent } from 'src/app/organization-ui/users-selection/users-selection.component';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { ClassroomService } from '../../classroom.service';

interface InviteCollaboratorDialogData {
     classroom: Classroom,
     excludedUsers: User[]
}

@Component({
     selector: 'app-invite-collaborator-dialog',
     templateUrl: './invite-collaborator-dialog.component.html',
     styleUrl: './invite-collaborator-dialog.component.scss'
})
export class InviteCollaboratorDialogComponent {

     errorObject: any;

     @ViewChild(UsersSelectionComponent, { static: false })
     usersSelectionComponent: UsersSelectionComponent;

     constructor(
          @Inject(MAT_DIALOG_DATA) public data: InviteCollaboratorDialogData,
          private mdDialogRef: MatDialogRef<InviteCollaboratorDialogComponent, boolean>,
          protected loading: LoadingService,
          private classroomService: ClassroomService) { }


     public static createDialog(dialogService: DialogService, dialog: MatDialog, data: InviteCollaboratorDialogData, config: AppDialogConfig = {}) {
          return new AppDialogRef<InviteCollaboratorDialogComponent, InviteCollaboratorDialogData, boolean>(dialogService, dialog, InviteCollaboratorDialogComponent, {
               data,
               width: "600px",
               maxWidth: "600px",
               //               minWidth: "300px",
               maxHeight: "calc(100vh - 64px)",
               position: {
                    top: "52px",
               }
          },
               config);
     }

     sendInvite() {
          this.errorObject = undefined;

          const collaboratorEmailsArray = [];
          this.usersSelectionComponent.collaboratorAdd.forEach((element) => {
               collaboratorEmailsArray.push(element.email);
          });
          this.classroomService
               .updateClassroomCollaborators(
                    this.data.classroom.id,
                    this.usersSelectionComponent.collaboratorAdd,
                    this.usersSelectionComponent.collaboratorRemove
               ).subscribe(() => {
                    this.classroomService
                         .sendCollaboratorsInvite(this.data.classroom.id, collaboratorEmailsArray)
                         .subscribe(
                              (invs) => {
                                   this.mdDialogRef.close(true);
                              },
                              (err) => {
                                   this.errorObject = err;
                              }
                         );
               });
     }
}
