import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { User } from '@applogic/model';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  @Input() user: User;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    if(!this.user) {
        this.user = this.auth.user;
    }
  }

}
