import { AfterViewInit, Component, ContentChild, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ICommonList } from 'src/app/core/common-list/common-list.interface';
import { CommonListColumn } from '../common-list-column';
import { FormService } from 'src/app/services/form.service';
import { MatMenu } from '@angular/material/menu';
import { CommonListRenderOptions } from '../common-list-render-options';
import { CommonListRendererOptionsComponent } from '../common-list-renderer-options/common-list-renderer-options.component';
import { CommonListAbstractRendererComponent } from '../common-list-abstract-renderer/common-list-abstract-renderer.component';
import { CommonListRendererTemplatesComponent } from '../common-list-renderer-templates/common-list-renderer-templates.component';

export enum CommonListDisplayMode {
    Default = 'default',
    Card = 'card'
}

export enum CommonListRendererStyle {
    Plain = "plain",
    Normal = "normal"
}


@Component({
    selector: 'app-common-list-renderer',
    templateUrl: './common-list-renderer.component.html',
    styleUrls: ['./common-list-renderer.component.scss']
})
export class CommonListRendererComponent extends CommonListAbstractRendererComponent implements OnInit, AfterViewInit  {

    CommonListDisplayMode = CommonListDisplayMode;

    @Input()
    displayMode: CommonListDisplayMode = CommonListDisplayMode.Default;
    
    @Input()
    isAdmin: boolean = false;

    @Input()
    style: CommonListRendererStyle = CommonListRendererStyle.Normal;

    _contentOptions: CommonListRendererOptionsComponent;

    @ContentChild(CommonListRendererOptionsComponent)
    set contentOptions(contentOptions: CommonListRendererOptionsComponent) {
        this._contentOptions = contentOptions;
        if(contentOptions) {
            // console.log("## contentOptions(" + this.list.constructor.name + "): " + JSON.stringify(contentOptions));
            this.setOptions(contentOptions);
        }
    }

    get contentOptions() {
        return this._contentOptions;
    }

    _list: ICommonList;

    @Input()
    set list(l: ICommonList) {
        if(this._list != l) {
            this._list = l;
            this.listChange.emit(l);

            if(this._list) {
                const objectKeys = Object.keys(this._list.rendererOptions);
                if(objectKeys.length > 0) {
                    this.setOptions(this._list.rendererOptions);
                }
            }
        }
    }

    get list() {
        return this._list;
    }

    @Output()
    listChange: EventEmitter<ICommonList> = new EventEmitter<ICommonList>();

    @ContentChild(CommonListRendererTemplatesComponent)
    templatesOptions: CommonListRendererTemplatesComponent<any>;
    


    private _lastInputOptions: CommonListRenderOptions;

	@Input()
    set options(options: CommonListRenderOptions) {
        if(options) {
            if (options != this._lastInputOptions) { // To avoid an infinit loop with two-way binding.
                this._lastInputOptions = options;
                this.setOptions(options);
            }
        }
    }

    get options() {
        return this._options;
    }

    @Output()
    optionsChange: EventEmitter<CommonListRenderOptions> = new EventEmitter<CommonListRenderOptions>();

    _listData: any = {};

    // List data can be used by child components to store data related to the list.
    get listData() {
        return this._listData;
    }


    public _options: CommonListRenderOptions;

    @ViewChild('actionMatMenu') actionMenu: MatMenu;

    @HostBinding('class') get getHostClass() {
        let classList: string[] = [];

        if(this._options.verticalScrolling) {
            classList.push("app-common-list--vertical-scrolling");
        }
        return classList.join(" ");
    }
    
    constructor(private container: ViewContainerRef, public formService: FormService) {
        super();

        // To initialize default options.
        this.setOptions({});
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {

    }
    
    getRowValue(column: CommonListColumn, row: any, keys?: string[]) {
        return this.list.getRowValue(column, row, keys);
    }

    getBoolValue(column: CommonListColumn, row: any) {
        let v = this.getRowValue(column, row);

        if(v == undefined) {
            v = column.parameters?.defaultValue;
        }

        if(v === true) {
            return "true";
        }
        else if(v === false) {
            return "false";
        }

        return "";
    }

    menuActionOpened(column: CommonListColumn, row: any) {
        this.list.setMenuActionItem(column, row);
    }

    setOptions(options: CommonListRenderOptions) {
        // console.log("## setOptions " + this.list?.constructor.name + " " + JSON.stringify(options));

        // Must keep reference to the options component.
        // So only set properties that are not defined.
        if(this._contentOptions && this._options && (this._contentOptions != options)) {

            if(this._options) {
                for(const key of Object.keys(options)) {
                    const v = this._options[key];
                    if(v === undefined) {
                        this._options[key] = options[key];
                    }
                }
            }
        }
        else {
            if(this._options) {
                for(const key of Object.keys(this._options)) {
                    const v = options[key];
                    if(v === undefined) {
                        options[key] = this._options[key];
                    }
                }
            }
            this._options = options;
        }

        if(this._options.tableContainerClasses === undefined) {
            this._options.tableContainerClasses = [];
        }

        setTimeout(() => {
            if(this._options.showColumnHeader === undefined) {
                this._options.showColumnHeader = true;
            }

            if(this._options.showColumnFooter === undefined) {
                this._options.showColumnFooter = false;
            }
            this.optionsChange.emit(options);
        });
    }

    updateOptions(options: CommonListRenderOptions) {
        for(const key of Object.keys(options)) {
            const v = options[key];
            this._options[key] = options[key];
        }
    }
}
