import { Component, HostBinding, Input } from '@angular/core';
import { Organization, OrganizationMember, User } from '@applogic/model';
import { CardRowData } from 'src/app/core/common-list/display-mode/common-list-card-display-mode/common-list-card-display-mode.component';

@Component({
    selector: 'app-organization-member-card',
    templateUrl: './organization-member-card.component.html',
    styleUrl: './organization-member-card.component.scss'
})
export class OrganizationMemberCardComponent {
    
    @Input()
    organization: Organization;

    @Input()
    currentUser: User;

    @Input()
    data: CardRowData<OrganizationMember>;

    @HostBinding('class') get getHostClass() {
        return 'list-item';
    }

}
