@if(state != State.None) {
    <div class="flex-row full-width secondary-text"
         *ngIf="isTrial">
        <span i18n="@@common-free-trial">free trial</span>
        <span class="spacer"></span>
        <span *ngIf="subscription">{{subscription.expiresOn | relativeTime:{showExpired:true} }}</span>
    </div>
    <mat-progress-bar class="trial-progress"
                      mode="determinate"
                      [value]="trialPercent"
                      *ngIf="isTrial"></mat-progress-bar>
    <mat-list class="small-list full-width">
        <mat-list-item>
            <mat-icon>check</mat-icon>
            <span i18n="@@upgrade-now-section--feature1">French, English and Spanish</span>
        </mat-list-item>
        <mat-list-item>
            <mat-icon>check</mat-icon>
            <span i18n="@@upgrade-now-section--feature2">Access all content</span>
        </mat-list-item>
        <mat-list-item>
            <mat-icon>check</mat-icon>
            <span i18n="@@upgrade-now-section--feature3">Install on all your devices</span>
        </mat-list-item>
    </mat-list>

    @switch(state) {
        @case(State.SubscribeNow) {
            <button mat-flat-button
                    color="accent"
                    (click)="upgradeNow()"
                    i18n="@@common-subscribe">
                Subscribe
            </button>
        }
        @case(State.UpgradeNow) {
            <button mat-flat-button
                    color="accent"
                    (click)="upgradeNow()"
                    i18n="@@common-upgrade-now">
                Upgrade Now
            </button>
        }
    }
}
