import { Model, ID, Member, ArrayMember } from "@uon/model";
import { CommonModel } from "../common/common.model";


@Model()
export class SubscriptionSeat {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    usedOn: Date;

    @Member()
    active: boolean;
    
    @Member()
    expiresOn: Date; // Should be binded to the subscription expiresOn property.

    @Member()
    subscriptionId: string;

    @Member()
    allocationId: string;

    @Member()
    productId: string;

    @Member()
    userId: string;

    @Member()
    studentId: string;
}