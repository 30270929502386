import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-list-count-indicator',
    templateUrl: './list-count-indicator.component.html',
    styleUrl: './list-count-indicator.component.scss'
})
export class ListCountIndicatorComponent {

    _count: number;

    @Input()
    set count(count: number) {
        this._count = count;

        if(count < 10) {
            this.labelClass = "label label-1";
        }
        else if (count < 100) {
            this.labelClass = "label label-2";
        }
        else if (count < 1000) {
            this.labelClass = "label label-3";
        }
        else if (count < 10000) {
            this.labelClass = "label label-4";
        }
        else if (count < 100000) {
            this.labelClass = "label label-5";
        }
        else if (count < 1000000) {
            this.labelClass = "label label-6";
        }
        else {
            this.labelClass = "label label-7";
        }
    }

    labelClass: string = "label label-1";
}
