<app-page type="centered">
    <app-page-content class="app-form-container">
        <div *ngIf="!summaryError && !invite"
             style="display: flex; justify-content: center; width: 100%"
             @fadeInOut>

            <mat-progress-spinner mode="indeterminate"
                                  diameter="64"></mat-progress-spinner>
        </div>

        <div class="card"
             *ngIf="summaryError"
             style="text-align: center;"
             @fadeInOut>

            <div class="header"
                 style="justify-content: center;">
                <mat-icon color="warn">warning</mat-icon>
            </div>

            <app-error-message-area [error]="errorObject"></app-error-message-area>

            <small i18n="@@common-invitation-lookup-error-hint">
                Please contact the person who gave you the link. They may be able to generate a new invitation.
            </small>

            <p>
                <button mat-flat-button
                        color="primary"
                        routerLink="/"
                        i18n="@@common-go-to-home">Back to home</button>
            </p>
        </div>

        <app-design-card *ngIf="invite"
                         class="invite-card"
                         appearance="raised"
                         [clickable]="false">
            <design-card-content>
                <h2 *ngIf="_step != Step.Complete"
                    i18n="@@organization-invite-title">
                    You are invited to join the organization!
                </h2>

                <p *ngIf="_step != Step.Complete">
                    <strong title="{{invite.invitedBy.email}}">{{invite.invitedBy.fullName}}</strong>
                    <span i18n="@@org-staff-invite-form-context-text"> has invited you to join the organization </span>
                    <strong>"{{ invite.organization.name }}"</strong>
                </p>

                <ng-container [ngSwitch]="_step">
                    <div *ngSwitchCase="Step.Accept">
                        <ng-template [ngTemplateOutlet]="acceptTemplate"></ng-template>
                    </div>

                    <div *ngSwitchCase="Step.Register">
                        <ng-template [ngTemplateOutlet]="registerTemplate"></ng-template>
                    </div>


                    <div *ngSwitchCase="Step.Login">
                        <ng-template [ngTemplateOutlet]="loginTemplate"></ng-template>
                    </div>

                    <div *ngSwitchCase="Step.Complete">
                        <ng-template [ngTemplateOutlet]="completeTemplate"></ng-template>
                    </div>
                </ng-container>
            </design-card-content>
        </app-design-card>
    </app-page-content>
</app-page>

<ng-template #loginTemplate>
    <p>
        <span i18n="@@staff-invite-form-context-text-login">Please sign in with your account to join
            <strong>{{ invite.organization.name }}</strong>.</span>
        <br>

    </p>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <app-base-login-register-form [email]="inviteEmail"
                                  [formType]="'user-login'"
                                  [readonlyEmailIfProvided]="true"
                                  #commonForm></app-base-login-register-form>

    <div class="app-form-actions"
         style="align-items: flex-end;">
        <span class="spacer"></span>
        <button mat-flat-button
                color="primary"
                [disabled]="commonForm.baseLoginRegisterForm.invalid || loading.isLoading || (loading.loadingChange | async)"
                (click)="submitLoginForm(commonForm.baseLoginRegisterForm)">
            <span i18n="@@staff-invite-form-submit-label-login">
                Sign in & Join
            </span>
        </button>
    </div>
</ng-template>

<ng-template #registerTemplate>
    <p>
        <span i18n="@@staff-invite-form-context-text">Please register for an account to join
            <strong>{{ invite.organization.name }}</strong>.</span>
        <br>

    </p>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <app-base-login-register-form [email]="inviteEmail"
                                  [formType]="'register'"
                                  [readonlyEmailIfProvided]="true"
                                  #commonForm></app-base-login-register-form>

    <div class="app-form-actions"
         style="align-items: flex-end;">

        <span class="spacer"></span>
        <button mat-flat-button
                color="primary"
                [disabled]="!commonForm.baseLoginRegisterForm.valid || loading.isLoading || (loading.loadingChange | async)"
                (click)="submitRegisterForm(commonForm.baseLoginRegisterForm)">
            <span i18n="@@staff-invite-form-submit-label">
                Register & Join
            </span>



        </button>
    </div>
</ng-template>

<ng-template #acceptTemplate>

    <p i18n="@@org-staff-invite-form-context-instructions">
        To join, simply click on 'Accept Invitation' below.
    </p>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <div class="app-form-actions">

        <span class="spacer"></span>

        <button mat-flat-button
                color="primary"
                (click)="acceptInvite()">

            <span i18n="@@org-staff-invite-form-submit-label">
                Accept Invitation
            </span>

        </button>

    </div>
</ng-template>

<ng-template #completeTemplate>
    <h2 i18n="@@staff-invite-complete-title">Congratulations {{ auth.user.firstName }}!</h2>

    <p i18n="@@staff-invite-complete-context-text">
        You are now part of <strong>{{ invite.organization.name }}</strong>!
    </p>


    <div class="app-form-actions">
        <span class="spacer"></span>
        <button mat-flat-button
                color="accent"
                routerLink="/classrooms">
            <span i18n="@@staff-invite-next-step-button-label">Continue to my account</span>
        </button>
    </div>
</ng-template>