import { Component, Input, OnInit } from '@angular/core';
import { Classroom, ClassroomRole } from '@applogic/model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-classroom-role-chip',
    templateUrl: './classroom-role-chip.component.html',
    styleUrls: ['./classroom-role-chip.component.scss']
})
export class ClassroomRoleChipComponent implements OnInit {

    ClassroomRole = ClassroomRole;

    private _classroom: Classroom;
    private _userId: string;

    @Input()
    role: ClassroomRole;

    @Input()
    set classroom(val: Classroom) {
        this._classroom = val;

        this.updateUserRole();
    }


    @Input()
    set userId(val: string) {
        this._userId = val;

        this.updateUserRole();
    }
    
    constructor(public authService: AuthService) { }

    ngOnInit(): void {
    }

    private updateUserRole() {
        this.role = undefined;

        if(this._userId) {
            if(this._classroom) {
                if(this._classroom.owner?.id == this._userId) {
                    this.role = ClassroomRole.Owner;
                }
                else if(this._classroom.collaborators?.find(c => c.user?.id == this._userId)) {
                    this.role = ClassroomRole.Collaborator;
                }
                else {
                    this.role = ClassroomRole.Admin;
                }
            }
        }
        else if(this._classroom?._currentUserRole) {
            this.role = this._classroom._currentUserRole;
        }
    }
}
