import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-unsubscribe-email-page',
    templateUrl: './unsubscribe-email-page.component.html',
    styleUrls: ['./unsubscribe-email-page.component.scss']
})
export class UnsubscribeEmailPageComponent implements OnInit {

    get hasToken() {
        return !!this.route.snapshot.params.token;
    }

    errorObject: any;

    emailPreferencesForm: UntypedFormGroup = new UntypedFormGroup({});

    email: string;

    isUserLogged: boolean;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        public snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.isUserLogged = this.authService.isLoggedIn();
        const token = this.route.snapshot.params.token;
        if (token == "test") {
            this.email = "test@example.com";
        }
        else {
            this.authService.getEmailPreferences(this.route.snapshot.params.token).then((body: any) => {
                if (body.prefs) this.emailPreferencesForm.patchValue(body.prefs);
                this.email = body.email;
            }).catch((ex) => {
                console.error(ex);
                this.errorObject = ex;
            });
        }
    }

    onEmailPreferencesChanged(evt) {
        this.errorObject = undefined;
        this.authService.updateEmailPreferences(this.emailPreferencesForm.value, this.route.snapshot.params.token).catch((ex) => {
            console.error(ex);
            this.errorObject = ex;
        });
    }
}
