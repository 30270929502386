import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRoutes, ChecklistTrialClassroom, ChecklistTrialOrganization, ChecklistType, CommonQueryFilter, CommonQueryResponse, OnboardingForm, OnboardingFormDeleteResponse, OnboardingFormGetResponse, OnboardingFormProcessResponse, OnboardingFormUpdate, OnboardingFormUpdateResponse, OnboardingState, OnboardingStatsResponse, OnboardingTransition, OnboardingUser, RoutesServer, SubscriptionAllocationMode } from "@applogic/model";
import { JsonSerializer, Model } from "@uon/model";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiDirectoryService } from "../services/api-directory.service";
import { AngularUtils } from "../services/angular-utils";

const USER_SERIALIZER = new JsonSerializer(OnboardingUser);
const ONBOARDING_FORM_SERIALIZER = new JsonSerializer(OnboardingForm);
const ONBOARDING_FORM_GET_RESPONSE_SERIALIZER = new JsonSerializer(OnboardingFormGetResponse);
const ONBOARDING_FORM_UPDATE_RESPONSE_SERIALIZER = new JsonSerializer(OnboardingFormUpdateResponse);
const ONBOARDING_FORM_DELETE_RESPONSE_SERIALIZER = new JsonSerializer(OnboardingFormDeleteResponse);
const ONBOARDING_FORM_PROCESS_RESPONSE_SERIALIZER = new JsonSerializer(OnboardingFormProcessResponse);
const ONBOARDING_STATE_SERIALIZER = new JsonSerializer(OnboardingState);
const ONBOARDING_TRANSITION_SERIALIZER = new JsonSerializer(OnboardingTransition);
const CHECKLIST_CLASSROOM_SERIALIZER = new JsonSerializer(ChecklistTrialClassroom);
const CHECKLIST_ORGANIZATION_SERIALIZER = new JsonSerializer(ChecklistTrialOrganization);
const ONBOARDING_STATS_RESPONSE_SERIALIZER = new JsonSerializer(OnboardingStatsResponse);

@Injectable({
    providedIn: "root",
})
export class OnboardingService {

    public states: { [id: string]: OnboardingState } = {};
    public transitions: { [id: string]: OnboardingTransition } = {};
    public wasLoaded: boolean = false;

    private static testTrialChecklistClassroom: ChecklistTrialClassroom;
    private static testTrialChecklistOrganization: ChecklistTrialOrganization;

    constructor(private http: HttpClient,
        private dirService: ApiDirectoryService) {

    }

    public async loadDefinitions() {
        return new Promise<void>((resolve, reject) => {
            if (this.wasLoaded) {
                resolve();
                return;
            }

            this.getStates().toPromise().then((states: OnboardingState[]) => {
                states.forEach((s: OnboardingState) => {
                    this.states[s.key] = s;
                });

                return this.getTransitions().toPromise();
            }).then((transitions: OnboardingTransition[]) => {
                transitions.forEach((t: OnboardingTransition) => {
                    this.transitions[t.id] = t;
                });

                this.wasLoaded = true;
                resolve();
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    getUserStatus(userId: string): Observable<any> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Onboarding, `/getUserStatus?userId=${userId}`, { withCredentials: true }).pipe(
            map((r: any) => USER_SERIALIZER.deserialize(r, true)),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    getStates() {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Onboarding, `/states`, { withCredentials: true }).pipe(
            map((r: any[]) => r.map((s) => ONBOARDING_STATE_SERIALIZER.deserialize(s, true))),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    getTransitions() {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Onboarding, `/transitions`, { withCredentials: true }).pipe(
            map((r: any[]) => r.map((s) => ONBOARDING_TRANSITION_SERIALIZER.deserialize(s, true))),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    getStateShortKeyLabel(key: string): string {
        if (key.length > 4) {
            key = key.substring(key.length - 4);
        }

        return key;
    }

    getStateLabel(key: string): string {
        let state: OnboardingState = this.states[key];

        if (state) {
            return state.label;
        }

        return "";
    }

    getStateKeyLabel(key: string, sourceKey?: string): string {
        let state: OnboardingState = this.states[key];
        if (!sourceKey) sourceKey = key;

        if (state) {
            if (!state.label) {
                if (state.parent) {
                    return this.getStateKeyLabel(state.parent, sourceKey);
                }
            } else {
                return state.label + " (" + sourceKey + ")";
            }
        }

        return sourceKey;
    }

    getTransitionDescription(id: string) {
        if (!id) return "No Transition";

        let result: string = "";

        result = "Id: " + id + "\n";

        let transition: OnboardingTransition = this.transitions[id];
        if (!transition) {
            console.log("Transitions: " + JSON.stringify(this.transitions));
            return "Missing Transition";
        }

        if (transition.conditions) {
            transition.conditions.forEach((c) => {
                result += c.type + "\t";
                if (c.params) {
                    result += JSON.stringify(c.params);
                }
                result += "\n";
            });
        } else {
            result = "Immediate";
        }

        return result;
    }

    getState(key: string) {
        if (!key) return;

        return this.states[key];
    }

    getTransition(id: string) {
        if (!id) return;

        return this.transitions[id];
    }

    getChecklist(elementId: string, checklistType: ChecklistType): Promise<ChecklistTrialClassroom | ChecklistTrialOrganization> {
        return new Promise((resolve, rejects) => {

            if (AngularUtils.isTestMode()) {
                const testChecklist = this.getTestTrial(checklistType);

                if(testChecklist) {
                    resolve(testChecklist);
                    return;
                }
            }

            this.dirService.observeRoute(RoutesServer.Api, ApiRoutes.Onboarding, `/checklist-${checklistType}/${elementId}`).subscribe({
                next: (routeUrl) => {
                    return this.http.get(`${routeUrl}`, { withCredentials: true, observe: 'response' }).subscribe({
                        next: (response) => {
                            if (response.status == 200) {
                                if (checklistType == ChecklistType.Classroom) {
                                    resolve(CHECKLIST_CLASSROOM_SERIALIZER.deserialize(response.body, true));
                                }
                                else {
                                    resolve(CHECKLIST_ORGANIZATION_SERIALIZER.deserialize(response.body, true));
                                }
                            }
                            else if (response.status == 404) {
                                resolve(null);
                            }
                            else {
                                throw response;
                            }
                        }, error: (err) => {
                            if (err.status == 404) {
                                resolve(null);
                            }
                            else {
                                rejects(err);
                            }
                        }
                    });
                },
                error: (err) => {
                    rejects(err);
                }
            })
        });
    }

    updateChecklist(elementId: string, checklistType: ChecklistType, changes: any): Promise<ChecklistTrialClassroom | ChecklistTrialOrganization> {
        return new Promise((resolve, rejects) => {

            if (AngularUtils.isTestMode()) {
                if (AngularUtils.isTestMode()) {
                    const testChecklist = this.getTestTrial(checklistType);

                    if (testChecklist) {
                        Model.Assign(testChecklist, changes);
                        resolve(testChecklist);
                        return;
                    }
                }
            }

            this.dirService.observeRoute(RoutesServer.Api, ApiRoutes.Onboarding, `/checklist-${checklistType}/${elementId}`).subscribe({
                next: (routeUrl) => {
                    this.http.patch(`${routeUrl}`, { changes }, { withCredentials: true, observe: 'response' }).subscribe({
                        next: response => {
                        if (response.status == 200) {
                            if (checklistType == ChecklistType.Classroom) {
                                resolve(CHECKLIST_CLASSROOM_SERIALIZER.deserialize(response.body, true));
                            }

                            resolve(CHECKLIST_ORGANIZATION_SERIALIZER.deserialize(response.body, true));
                        }
                        else if (response.status == 404) {
                            resolve(null);
                        }
                        else {
                            throw response;
                        }
                        },
                        error: (err) => {
                            if (err.status == 404) {
                                resolve(null);
                            }
                            else {
                                rejects(err);
                            }
                        }
                    });
                },
                error: (err) => {
                    rejects(err);
                }
            });
        });
    }

    notifyPlayGame(gameCode: string, data: any = {}) {

        this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, `/game-event/${gameCode}`, data, { withCredentials: true }).subscribe(res => {

        }, (error) => {
            console.log(error);
        });
    }

    notifyPopoverClick(popoverType: string, data: any) {

        this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, `/popover-click/` + popoverType, data, { withCredentials: true }).subscribe(res => {

        }, (error) => {
            console.log(error);
        });
    }

    /**
     * Reset the onboarding for an user.
     *
     * This will remove the onboarding user data and its trial subscription.
     * Only an admin can use this function.
     *  
     * @param {string} userId The user id.
     */
    reset(userId: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, `/reset`, { userId }, { withCredentials: true });
    }

    /**
     * Shift the onboarding user time.
     *  
     * @param {string} userId The user id.
     * @param {string} delay The delay to shift time. (Format '24h' for hours, '24d' for days, '24m' for minutes)
     */
    reschedule(userId: string, delay: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, `/reschedule`, { userId, delay }, { withCredentials: true });
    }

    // Only for admins.
    getStats() {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, `/stats`, {}, { withCredentials: true }).pipe(
            map((r: any) => ONBOARDING_STATS_RESPONSE_SERIALIZER.deserialize(r, true))
        );
    }

    searchUsers(filter: CommonQueryFilter) {
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Onboarding, `/search${str}`, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<OnboardingUser>();
                response.count = r.count;
                response.result = r.result.map(s => USER_SERIALIZER.deserialize(s, true));
                return response;
            })
        );
    }

    searchForms(filter: CommonQueryFilter) {
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Onboarding, `/forms/search${str}`, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<OnboardingForm>();
                response.count = r.count;
                response.result = r.result.map(s => ONBOARDING_FORM_SERIALIZER.deserialize(s, true));
                response.data = r.data;
                return response;
            })
        );
    }

    createForm() {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, '/forms/start', {}, { withCredentials: true }).pipe(
            map((r: any) => {
                return {

                }
            })
        );
    }

    getForm(formId: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Onboarding, `/forms/${formId}`, { withCredentials: true }).pipe(
            map((r: any) => {
                return ONBOARDING_FORM_GET_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    updateForm(formId: string, updates: OnboardingFormUpdate) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Onboarding, `/forms/${formId}`, updates, { withCredentials: true }).pipe(
            map((r: any) => {
                return ONBOARDING_FORM_UPDATE_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    processForm(formId: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Onboarding, `/forms/${formId}/process`, {}, { withCredentials: true }).pipe(
            map((r: any) => {
                return ONBOARDING_FORM_PROCESS_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    deleteForm(formId: string) {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Onboarding, `/forms/${formId}`, { withCredentials: true }).pipe(
            map((r: any) => {
                return ONBOARDING_FORM_DELETE_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    exportUsers(filter: CommonQueryFilter, bookType: string) {
        filter.other.bookType = bookType;
        let str = filter.toString2();

        this.dirService.observeRoute(RoutesServer.Api, ApiRoutes.Onboarding, `/search/export${str}`).subscribe((routeUrl) => {
            window.open(routeUrl);
        });
    }

    private getTestTrial(type: ChecklistType) {
        if(type == ChecklistType.Classroom) {
            if (!OnboardingService.testTrialChecklistClassroom) {
                OnboardingService.testTrialChecklistClassroom = Model.New(ChecklistTrialClassroom, {
                    classroomId: "test",
                    followProgress: false,
                    inviteStudent: false,
                    subscribed: false,
                    tryProducts: {
                        "MMO": false,
                        "MSM": false
                    }
                });
            }

            return OnboardingService.testTrialChecklistClassroom;
        }
        else if (type == ChecklistType.Organization) {
            if (!OnboardingService.testTrialChecklistOrganization) {
                OnboardingService.testTrialChecklistOrganization = Model.New(ChecklistTrialOrganization, {
                    subscriptionId: "testSubId",
                    allocationMode: SubscriptionAllocationMode.Automatic,
                    subNo: "ABO-test",
                    allocateTokens: false,
                    setTokenMode: false,
                    createQuotation: false,
                    inviteMembers: false,
                    orgId: "testOrgId"
                });
            }

            return OnboardingService.testTrialChecklistOrganization;
        }
    }

}

