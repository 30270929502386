<div class="options"
     [ngClass]="{'align-left-toolbar': alignLeft}">

    <app-common-list-show-columns-button *ngIf="_options.showSelectColumnsOption && alignLeft"
                                         [renderer]="_renderer"></app-common-list-show-columns-button>

    <app-common-list-display-mode-select *ngIf="_options.showDisplayMode && !alignLeft"
                                         [renderer]="_renderer"></app-common-list-display-mode-select>
    <app-common-list-search-input *ngIf="(_options.showSearchBar && ((list.dataSource.data.length > 0) || list.hasCriterion)) && (!_options.autoHideSearchBar || list.hasCriterion || (list.count > list.pageSize))"
                                  [list]="list"
                                  class="search-input"
                                  [placeholder]="_options.searchPlaceholder"
                                  [formInputStyle]="false"></app-common-list-search-input>
    <app-common-list-display-mode-select *ngIf="_options.showDisplayMode && alignLeft"
                                         [renderer]="_renderer"></app-common-list-display-mode-select>

    <span class="spacer"></span>
    <ng-content select="[table-option],[common-list-toolbar-option]"></ng-content>

    <app-common-list-show-columns-button *ngIf="_options.showSelectColumnsOption && !alignLeft"
                                         [renderer]="_renderer"></app-common-list-show-columns-button>
</div>