import { Component, Input, OnInit } from '@angular/core';
import { PriceTable, SubscriptionPlan, SubscriptionType, Tax } from '@applogic/model';
import { RequestQuotationService } from 'src/app/request-quotation/request-quotation.service';
import { AngularUtils } from 'src/app/services/angular-utils';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { LanguageService } from 'src/app/services/language.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-price-table',
    templateUrl: './price-table.component.html',
    styleUrls: ['../products-common-card-select.component.scss', './price-table.component.scss']
})
export class PriceTableComponent implements OnInit {

    AngularUtils = AngularUtils;
    
    SubscriptionType = SubscriptionType;

    _priceTable: PriceTable;

    public taxSymbol: string = '0.2-4';

    @Input()
    set priceTable(val: PriceTable) {
        if (this._priceTable != val) {
            this._priceTable = val;
            this.updateTable();
        }
    }

    get priceTable() {
        return this._priceTable;
    }

    @Input()
    subscriptionType: SubscriptionType;

    @Input()
    subscriptionPlan: SubscriptionPlan;

    @Input()
    showProduct: boolean = true;

    planLabel: string;

    constructor(
        public productService: ProductService,
        public quotationService: RequestQuotationService,
        private languageService: LanguageService
    ) {

    }

    ngOnInit(): void {
        this.updateTable();
    }

    updateTable() {
        this.planLabel = ApplogicUtils.getSubscriptionPlanLabel(this.subscriptionPlan, this.subscriptionType);
    }

    getTaxTitle(tax: Tax) {
        const name = tax.code?.name?.[this.languageService.currentLanguageCode];
        if(name) return name;
        return "";
    }
}
