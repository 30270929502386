<mat-form-field [appearance]="appearance"
                [floatLabel]="floatLabel"
                [subscriptSizing]="subscriptSizing"
                style="width: 100%"
                class="country-field">
    <mat-label><span i18n="@@common-address-country">Country</span><span *ngIf="isRequired">*</span></mat-label>
    <mat-select [(ngModel)]="selectedCountry"
                i18n-placeholder="@@select-country-placeholder"
                placeholder="Country"
                class="custom-select"
                (ngModelChange)="onChangeSelectedValue($event)"
                (click)="countrySearchInput.value = '';filterOrgCountry('')">
        <div class="input-div">
            <input matInput
                   class="search-input"
                   i18n-placeholder="@@common-country-search"
                   placeholder="Search...."
                   #countrySearchInput
                   (input)="filterOrgCountry(countrySearchInput.value)"
                   autocomplete="off">
            <mat-icon matSuffix
                      class="empty-icon"
                      style="cursor: pointer;"
                      *ngIf="countrySearchInput.value"
                      (click)="countrySearchInput.value = ''; filterOrgCountry('')">
                clear
            </mat-icon>
        </div>
        <mat-option *ngFor="let coun of countriesFilterList"
                    [value]="coun.alpha2"
                    class="custom-option">
            <div class="show-country">
                <span class="imgdiv"><img *ngIf="showIcon"
                         src="https://appligogiques-web.s3.amazonaws.com/countries/flags/{{coun.alpha2}}.png"
                         alt="coun_flag"
                         loading="lazy"></span>
                <span>{{coun.name}}</span>
            </div>
        </mat-option>
        <div *ngIf="!countriesFilterList.length"
             class="select-empty"
             i18n="@@common-country-data-empty-message"> Data is not found </div>
    </mat-select>
</mat-form-field>