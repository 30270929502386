export class LoadingProcess {

    private _name: string;
    private _service: any;  // Type "any" to avoid circular dependency since this object is instantiated in LoadingService.

    constructor(name: string, service: any) {
        this._name = name;
        this._service = service;
    }

    stopLoading() {
        this._service.stopLoading(this._name);
    }
}