<app-common-list-renderer [isAdmin]="true"
                          [list]="list">
    <renderer-templates [templateColumns]="[productSeatColumnTemplate, tokenFooterTemplate]"></renderer-templates>
    <app-common-list-toolbar>
        <div table-option>
            <button mat-flat-button
                    color="primary"
                    (click)="inviteNewStudent()">
                <span i18n="@@classroom-seats-allocation-list--invite-student-button">
                    Add a student
                </span>
            </button>
        </div>
    </app-common-list-toolbar>
</app-common-list-renderer>

<ng-template #tokenFooterTemplate
             let-data>
    {{freeSeats[data.column.parameters.productCode]}}
</ng-template>

<ng-template #productSeatColumnTemplate
             let-data>
    <app-seat-token [displayType]="'icon-only'"
                    [productCode]="data?.column.parameters.productCode"
                    (click)="onProductClick(data?.column.parameters.productCode)"></app-seat-token>
</ng-template>