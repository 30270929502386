import { Component, Input } from '@angular/core';
import { GameProgressSelectableColumn } from '../../game-progress-selectable-column';

@Component({
    selector: 'app-columns-selection-word-list-tooltip',
    templateUrl: './columns-selection-word-list-tooltip.component.html',
    styleUrl: './columns-selection-word-list-tooltip.component.scss'
})
export class ColumnsSelectionWordListTooltipComponent {

    protected _column: GameProgressSelectableColumn;

    @Input()
    set column(column: GameProgressSelectableColumn) {
        let words: any;

        if (column.listInfo.groups) {
            let wordsGroup: any = column.listInfo.groups.find(a => a.level == column.list_subtype);
            words = wordsGroup.words;
        } else {
            words = column.listInfo.words;
        }

        this._words = words || [];
    }

    protected _words: any[] = [];
}
