import { Directive } from '@angular/core';

@Directive({
    selector: '[app-dialog-header],[appDialogHeader],app-dialog-header',
    host: {
        class: 'app-dialog-header'
    }
})
export class DialogHeaderDirective {

    constructor() { }

}
