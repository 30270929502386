import { Component, Input } from '@angular/core';
import { AbstractCustomFormFieldComponent } from '../abstract-custom-form-field/abstract-custom-form-field.component';
import { BaseFieldConfig, MatFormFieldConfig } from '../../custom-form-field-config';
import { MatSelectChange } from '@angular/material/select';
import { FormService } from 'src/app/services/form.service';
import { FormControl } from '@angular/forms';


export interface SelectFieldConfig extends BaseFieldConfig, MatFormFieldConfig {
    options?: { value: any, label: string }[];
    style?: 'default' | 'right' | 'radio';
    searchEnabled?: boolean;
}

@Component({
    selector: 'app-custom-form-select-field',
    templateUrl: './custom-form-select-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-select-field.component.scss']
})
export class CustomFormSelectFieldComponent extends AbstractCustomFormFieldComponent {

    @Input()
    data: SelectFieldConfig;

    public filterCtrl: FormControl<string> = new FormControl<string>('');

    constructor(protected formService: FormService) {
        super();
    }

    ngOnInit() {
        // listen for search field value changes
        this.filterCtrl.valueChanges
            .subscribe(() => {
                this.filterOptions();
            });
    }

    onFieldSelectChanged(ev: MatSelectChange) {

    }


  public filteredOptions: { value: any, label: string }[];


    onKey(value) {
        if (this.data?.searchEnabled) {
            this.filteredOptions = this.search(value);
        }
    }

    search(value: string) {
        let filter = value.toLowerCase();
        return this.data.options.filter(option => option.label.toLowerCase().startsWith(filter));
    }

    getOptions() {
        if (!this.data) {
            return [];
        }
        else if (this.data.searchEnabled) {
            if (!this.filteredOptions) {
                this.filteredOptions = this.data.options;
            }
            return this.filteredOptions;
        }
        else {
            return this.data.options;
        }
    }


    protected filterOptions() {
        if (!this.data.options) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrl.value;
        if (!search) {
            this.filteredOptions = this.data.options;
            return;
        } else {
            search = search.toLowerCase();
        }

        // filter the options
        this.filteredOptions = this.data.options.filter(option => {

            if(option.label.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            if(option.value.toLowerCase().indexOf(search) > -1) {
                return true;
            }

            return false;
        })
    }
}
