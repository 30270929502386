import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Classroom } from '@applogic/model';
import { PdfViewerDialogComponent } from 'src/app/core/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import { PrintService } from 'src/app/services/print.service';

export enum ClassroomCodePanelLayout {
    PageHeader = 'page-header',
    CardContent = 'card-content'
}

@Component({
    selector: 'app-classroom-code-panel',
    templateUrl: './classroom-code-panel.component.html',
    styleUrl: './classroom-code-panel.component.scss'
})
export class ClassroomCodePanelComponent {
    ClassroomCodePanelLayout = ClassroomCodePanelLayout;

    @Input()
    classroom: Classroom;

    @Input()
    layout: ClassroomCodePanelLayout = ClassroomCodePanelLayout.PageHeader;

    constructor(
        private printService: PrintService,
        private dialogService: DialogService,
        private dialog: MatDialog
    ) {
        
    }

    printStudentsList() {
        this.printService.printDocument(['classrooms', this.classroom.id, 'students']);
    }

    printParentsInformation() {
        const url = $localize`:@@parent-letter-url:https://appligogiques-web.s3.amazonaws.com/documents/parent+letter/Letter+to+parent+or+guardian.pdf`;

        PdfViewerDialogComponent.createDialog(this.dialogService, this.dialog, {
            title: "",
            pdfLink: url
        }).show();
    }
}
