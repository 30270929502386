import { ArrayMember, ID, Member, Model } from "@uon/model";

/**
 * The word structure for a WordList.
 */
 @Model()
export class WordListWord {

    @Member()
    text: string;

    @Member()
    html: string;

    @Member()
    syntax: string;

    @Member()
    mainsyntax: string;

    @Member()
    pos: number;
}
