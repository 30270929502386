import { Subscription } from "./subscription.model";
import { SubscriptionAllocation } from "./subscription-allocation.model";
import { ArrayMember, Member, Model } from "@uon/model";
import { User } from "../user/user.model";
import { SubAllocationsResponse } from "./sub-allocations-response";

/**
 * To contains all the user allocations grouped by subscription.
 * 
 * This structure is to be used with a route response.
 */
@Model()
export class UserAllocationsResponse {

    @Member()
    userId: string;

    @Member()
    orgId: string;

    @ArrayMember(SubAllocationsResponse)
    public subscriptions: SubAllocationsResponse[];

    public update() {
        for(let i=0; i<this.subscriptions.length; i++) {
            let s = this.subscriptions[i];
            s.update();
        }
    }

    public getAllocation(id: string) {
        if(!this.subscriptions) return;
        for(let i=0; i<this.subscriptions.length; i++) {
            let subAlloc = this.subscriptions[i];

            if(!subAlloc.allocations) continue;

            for(let j=0; j<subAlloc.allocations.length; j++) {
                let alloc = subAlloc.allocations[j];

                if(alloc.id == id) {
                    return alloc;
                }
            }
        }
    }
}