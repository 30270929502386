import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartType, } from "chart.js/auto";
import 'chartjs-adapter-date-fns';
import { Subject } from "rxjs";


@Component({
    selector: 'app-simple-chart',
    templateUrl: './simple-chart.component.html',
    styleUrls: ['./simple-chart.component.scss']
})
export class SimpleChartComponent<T extends ChartType = ChartType> implements OnInit {

    @Input() type: T;
    @Input() data: ChartData<T>;
    @Input() options: any = {};
    @Input() aspect: number = 1;
    @Input() showLegend: boolean = true;

    @ViewChild('canvas', { static: true }) canvasEl: ElementRef<HTMLCanvasElement>;

    private _destroy = new Subject<void>();
    private chart: Chart<T>;

    constructor() {
    }

    ngOnInit(): void {
        if (this.chart) {
            (this.chart.options as any).plugins.legend.labels.color = '#333';
            this.chart.update();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.isFirstChange() && this.chart) {
            this.chart.data = this.data;
            (this.chart.options as any).animation = false;
            this.chart.update();
        }
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    ngAfterViewInit() {

        this.chart = new Chart(this.canvasEl.nativeElement, {
            type: this.type,
            data: this.data,
            options: Object.assign({
                plugins: {
                    legend: {
                        position: 'top',
                        display: this.showLegend,
                        labels: {
                            boxWidth: 24,
                            color: '#333'
                        }
                    }
                },
                aspectRatio: this.aspect,
                maintainAspectRatio: false
            }, this.options)
        });


    }

}
