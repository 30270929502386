<div class="classroom-code-container"
     [ngClass]="{'card-content-layout': layout == 'card-content', 'page-header-layout': layout == 'page-header'}">
    <!-- <span class="label spacer"
          i18n="@@common-classroom-code">Classroom Code</span> -->
    <div class="code-area">
        <div class="code-area-label-container">
            <span class="label spacer code-area-label"
                  i18n="@@common-classroom-code">Classroom Code</span>
        </div>
        <div class="classroom-code-label-container">
            <span class="classroom-code-label">{{ classroom.login.code }}</span>
        </div>
        @if(layout == ClassroomCodePanelLayout.PageHeader) {
        <span class="spacer"></span>
        <button mat-flat-button
                color="accent"
                (click)="printStudentsList()"
                i18n="@@classroom-print-studentslist"
                *ngIf="classroom.students.length">
            Print students list
        </button>
        <!-- <app-help-icon [template]="ClassRoomCode"
                        placement="bottom"></app-help-icon> -->
        }
    </div>
    @if(layout == ClassroomCodePanelLayout.CardContent) {
    <div class="buttons-area">
        <button mat-flat-button
                color="accent"
                (click)="printStudentsList()"
                i18n="@@classroom-print-studentslist-long"
                *ngIf="classroom.students.length">
            Print student access cards
        </button>
        <button mat-flat-button
                color="accent"
                (click)="printParentsInformation()"
                i18n="@@classroom-code-panel--print-parent-informations">
            Print information for parents
        </button>
    </div>
    }
</div>