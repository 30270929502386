<app-common-list-renderer [list]="list">
    <renderer-templates [emptyTemplate]=""
                        [templates]="[homeworkAssignmentTemplate]"
                        [templateColumns]="[]"
                        [actionMenuTemplate]="actionTemplate"></renderer-templates>
    <button mat-menu-item
            (click)="showEditHomeworkDialog(selectedMenuActionItem)"
            menuitems>
        <mat-icon>edit</mat-icon>
        <span i18n="@@common-edit">Edit</span>
    </button>

    <button mat-menu-item
            (click)="showRemoveHomeworkDialog(selectedMenuActionItem)"
            *ngIf="canAddRemoveHomework"
            menuitems>
        <mat-icon color="warn">delete</mat-icon>
        <span i18n="@@common-remove">Remove</span>
    </button>
</app-common-list-renderer>

<ng-template #homeworkAssignmentTemplate
             let-data>
    <div class="homework-info">
        <span *ngIf="data.row.classroomShared"
                class="homewokr-isenabled-true-text"
                i18n="@@classroom-homework-assignation-classroom">
            classroom
        </span>
        <span *ngIf="!data.row.classroomShared && data.row.students && (data.row.students.length > 0)"
                class="secondary-text"
                i18n="@@classroom-homework-assignation-students">
            {{data.row.students?.length}} students
        </span>
        <span *ngIf="!data.row.classroomShared && (!data.row.students || (data.row.students.length == 0))"
                class="secondary-text"
                i18n="@@classroom-homework-assignation-none">
            None
        </span>
    </div>
</ng-template>