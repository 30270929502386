import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormService } from '../../services/form.service';
import { Subscription as RxSubscription } from 'rxjs';

@Component({
    selector: 'app-select-states-option',
    templateUrl: './select-states-option.component.html',
    styleUrls: ['./select-states-option.component.scss']
})
export class SelectStatesOptionComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    addressFormGroup: UntypedFormGroup;
    
    @Input() selectFormControl: UntypedFormControl;

    @Input()
    countryCode: string;

    // The subscription to the selectFormControl.valueChanges.
    valueChangesSubscription: RxSubscription;

    constructor(readonly formService: FormService) {

    }

    ngOnInit(): void {
    }

    ngOnChanges(changes:SimpleChanges){
        // When the Input FormControl change.
        if(changes.selectFormControl){
            // Unsubscribe from the previous FormControl valueChanges.
            if(this.valueChangesSubscription) {
                this.valueChangesSubscription.unsubscribe();
                this.valueChangesSubscription = undefined;
            }

            
            if(this.selectFormControl) {
                // Register the new FormControl valueChanges.
                this.valueChangesSubscription = this.selectFormControl.valueChanges.subscribe(value => {
                    // this.selectedCountry = value;
                    // this.showSelectedData();
                });

                // this.selectedCountry = this.selectFormControl.value;
                // this.showSelectedData();
            }
        }
    }

    ngOnDestroy() {
        if(this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
            this.valueChangesSubscription = undefined;
        }
    }
}
