import { BaseGameProgressUtils } from "../base-game-progress/base-game-progress.utils";
import { GameProgressSelectableColumn } from "../game-progress-selectable-column";
import { MSMChip } from "./msm-game-progress.model";


export class MSMGameProgressUtils extends BaseGameProgressUtils {

    /**  Get chips for the "DATES" progress table */
    public static getDateChips(dates: { [dateKey: string]: MSMChip[] }, column: GameProgressSelectableColumn, selectedSeason: number): MSMChip[] {
        let chips = dates?.[column.key];

        if (!chips) {
            return [];
        }

        if (selectedSeason) {
            chips = this.filterChipsBySeason(chips, selectedSeason);
        }

        return chips;
    }

    public static getActivityChips(lists: {[listKey: string]: MSMChip[]}, column: GameProgressSelectableColumn, selectedSeason: number): MSMChip[] {
        let chips = lists?.[column.key];

        if(!chips) {
            return [];
        }
        
        if(selectedSeason) {
            chips = MSMGameProgressUtils.filterChipsBySeason(chips, selectedSeason);
        }

        return chips;
    }
}