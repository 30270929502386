import { GameProgressListInfo, WordList, WordListSubType, WordListType } from "@applogic/model";

export class GameProgressSelectableColumn {
    // Id property for the CommonListComponent.
    public id: string;

    public header: boolean = false;
    public key: string; // Unique selectable key.
    public name: string;
    public no: any;  // TODO: Something there is a toString() and other it is compared with a string directly. So for now putting "any" type.
    
    public isSelectable: boolean = true;
    public isSelectedValue: boolean;
    public is_selectedAll: boolean;

    public color_code: string;

    public list_type: WordListType;
    public list_subtype: WordListSubType;

    public getLevelKey() {
        let result: string = "";
        
        if(this.list_subtype) {
            result = this.list_subtype;
        }
        else if(this.list_type) {
            result = this.list_type;
        }

        return result;
    }

    public listInfo: GameProgressListInfo;

    public order: number; // For ordering the columns.
}
