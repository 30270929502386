import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { OnboardingUser } from '@applogic/model';
import { OnboardingService } from '../onboarding.service';
import { LocalStorageService } from "src/app/services/local-storage.service";
import { FADE_IN_OUT, OPACITY_IN_OUT } from "src/app/animations/fade";
import { LOCAL_STORAGE_USER_KEY } from 'src/app/auth/auth.service';
import { MatTab } from '@angular/material/tabs';

@Component({
    selector: 'app-onboarding-help-tab',
    templateUrl: './onboarding-help-tab.component.html',
    styleUrls: ['./onboarding-help-tab.component.scss'],
    providers: [{ provide: MatTab, useExisting: OnboardingHelpTabComponent }],
    animations: [FADE_IN_OUT, OPACITY_IN_OUT],
})
export class OnboardingHelpTabComponent extends MatTab implements OnInit {

    onboardingUser: OnboardingUser;
    currentUser: any;
    currentUserId: string;

    protected _isVisible: boolean = true;

    @Input()
    set isVisible(val: boolean) {
        if (this._isVisible != val) {
            this.isVisibleChange.emit(val);
            this._isVisible = val;
        }
    }

    get isVisible() {
        return this._isVisible;
    }

    @Output()
    isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    constructor(readonly elementRef: ElementRef<HTMLElement>, private localStorageService: LocalStorageService,
        private onboardingService: OnboardingService, _viewContainerRef: ViewContainerRef) {
        super(_viewContainerRef, undefined);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.currentUser = this.localStorageService.get(LOCAL_STORAGE_USER_KEY);
        if (this.currentUser) {
            this.currentUserId = this.currentUser.id;
        }
        this.isVisible = false;

        // Disable the onboarding section until it will be ready.
        /*
        if(this.currentUserId) {
            this.onboardingService.getUserStatus(this.currentUserId).subscribe((u) => {
                this.onboardingUser = u;
                if(this.onboardingUser?.state?.key) {
                    this.isVisible = true;
                }
            });
        }*/
    }

}
