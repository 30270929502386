import { Component } from '@angular/core';

@Component({
    selector: 'app-credit-cards',
    templateUrl: './credit-cards.component.html',
    styleUrl: './credit-cards.component.scss'
})
export class CreditCardsComponent {

    cards: { label: string, img: string }[] = [
        {
            label: "Visa",
            img: "assets/icons/credit cards/visa.svg"
        },
        {
            label: "Mastercard",
            img: "assets/icons/credit cards/mastercard.svg"
        },
        {
            label: "American Express",
            img: "assets/icons/credit cards/amex.svg"
        }
    ];

    otherCards: { label: string, img: string }[] = [
        {
            label: "Dicover",
            img: "assets/icons/credit cards/discover.svg"
        },
        {
            label: "JCB",
            img: "assets/icons/credit cards/jcb.svg"
        },
        {
            label: "Diners Club",
            img: "assets/icons/credit cards/diners.svg"
        },
        {
            label: "UnionPay",
            img: "assets/icons/credit cards/unionpay.svg"
        }
    ]

    private _interval;

    protected _currentIndex = 0;
    protected _currentLabel = "";

    ngOnInit() {
        this.nextCard();

        this._interval = setInterval(() => {
            this.nextCard();
        }, 4000);

    }

    nextCard() {
        this._currentIndex = (this._currentIndex + 1) % this.otherCards.length;
        this._currentLabel = this.otherCards[this._currentIndex].label;
    }

    ngOnDestroy() {
        if (this._interval) {
            clearInterval(this._interval);
        }
    }
}
