import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CustomFormBuilder } from '../custom-form-builder';
import { CustomFormGroup } from '../custom-form-group';
import { CustomFormField } from '../custom-form-field';
import { FormService } from 'src/app/services/form.service';
import { FieldType } from '../custom-form-field-config';
import { AngularUtils } from 'src/app/services/angular-utils';


@Component({
    selector: 'app-form-builder-renderer',
    templateUrl: './form-builder-renderer.component.html',
    styleUrls: ['./form-builder-renderer.component.scss']
})
export class FormBuilderRendererComponent implements OnInit {
    FieldType = FieldType;

    _form: CustomFormBuilder;
    _group: CustomFormGroup;
    _currentGroup: CustomFormGroup;

    @Input()
    templates: TemplateRef<any>[] = [];

    @Input()
    set group(val: CustomFormGroup) {
        this._group = val;
        this.updateGroups();
    }

    get group() {
        return this._group;
    }

    @Input()
    set form(val: CustomFormBuilder) {
        this._form = val;
        this.updateGroups();
    }

    get form() {
        return this._form;
    }

    @Input()
    isFirst: boolean = true;

    constructor(protected formService: FormService) { }

    ngOnInit(): void {
    }





    private updateGroups() {
        if(!this._form) {
            this._currentGroup = new CustomFormGroup("", null, {
                
            });
        }
        else {
            if(this.group) {
                this._currentGroup = this.group;
            }
            else {
                this._currentGroup = this._form.rootGroup;
            }
        }
    }

    setCountryValue(field: CustomFormField, event: any){
        field.control.setValue(event);
        field.control.markAsDirty();
    }

    onInputFocus(field: CustomFormField) {
        field.onInputFocus();
    }

    onInputFocusOut(field: CustomFormField) {
        field.onInputFocusOut();
    }

    onInputChange(field: CustomFormField, event: Event) {
        field.onInputChange(event);
    }

    showDebugs(event: any) {
        AngularUtils.showFormGroupState("form", this.form.formGroup);
    }
}
