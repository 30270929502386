import { Component, OnInit } from '@angular/core';
import { CommonListAbstractCellRendererComponent } from './renderers/common-list-abstract-cell-renderer/common-list-abstract-cell-renderer.component';
import { CommonListColumn } from '../common-list-column';


@Component({
    selector: 'app-common-list-cell-renderer',
    templateUrl: './common-list-cell-renderer.component.html',
    styleUrls: ['./common-list-cell-renderer.component.scss']
})
export class CommonListCellRendererComponent extends CommonListAbstractCellRendererComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    getCurrencyValue(column: CommonListColumn, row: any) {
        
        const keys = Array.isArray(column.parameters?.currencyKeys) ? column.parameters?.currencyKeys : column.parameters?.currencyKeys.split('.');
        let v = this.getRowValue(column, row, keys);

        if(v == undefined) {
            v = column.parameters?.defaultCurrency;
        }

        return v;
    }

    getKeys(obj: any): string[] {
        if(!obj) return [];

        return Object.keys(obj);
    }
}
