import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { RadioGroupComponent } from 'src/app/core/radio-group/radio-group.component';
import { RadioSelectButtonComponent } from 'src/app/core/radio-select-button/radio-select-button.component';


@Component({
    selector: 'design-card-select',
    templateUrl: './design-card-select.component.html',
    styleUrl: './design-card-select.component.scss'
})
export class DesignCardSelectComponent {

    @Input()
    groupKey: string;

    @Input()
    value: any;

    @Input()
    disabled: boolean;

    @HostBinding('class') get getHostClass() {
        return "design-card-select";
    }

    @Input()
    set default(value: any) {
        if(value != undefined) {
            RadioGroupComponent.SetSelectedValue(this.groupKey, value);
        }
    }

    @Output()
    onCheck: EventEmitter<RadioSelectButtonComponent> = new EventEmitter<RadioSelectButtonComponent>();

    @ViewChild(RadioSelectButtonComponent, { static: true })
    private radioButton: RadioSelectButtonComponent;

    onRadioSelectButtonCheck(button: RadioSelectButtonComponent) {
        if(!this.disabled) {
            RadioGroupComponent.SetSelectedValue(this.groupKey, button.value);
            this.onCheck.emit(button);
        }
    }

    setChecked() {
        if(!this.disabled) {
            this.radioButton.setChecked(true, true);
        }
    }

}
