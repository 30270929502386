import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product, SubscriptionGrant, SubscriptionPlan, SubscriptionPlanUtils, SubscriptionType, TableProduct } from '@applogic/model';
import { SelectableProduct } from 'src/app/order/base-payment-form/selectable-product';
import { ProductService } from 'src/app/services/product.service';

export enum ProductOrderCardLayout {
    Selection = 'selection',
    Onboarding = 'onboarding',
    Summary = 'summary'
}

@Component({
    selector: 'app-product-order-card',
    templateUrl: './product-order-card.component.html',
    styleUrl: './product-order-card.component.scss'
})
export class ProductOrderCardComponent {

    Layout = ProductOrderCardLayout;
    SubscriptionType = SubscriptionType;

    private _product: SelectableProduct;
    private _tableProduct: TableProduct;
    protected _currency: string;

    protected p: {seats?: number} = {};

    protected _imageUrl: string;

    protected _productLabel: string;
    protected _productCode: string;

    protected _subscriptionType: SubscriptionType;
    protected _subscriptionPlan: SubscriptionPlan;

    protected _months: number = 0;

    protected get months() {
        return this._months;
    }

    protected get price(): number {
        if(this._product) {
            return this._product.price.amount.value;
        }
        else if (this._tableProduct) {
            
            return this._tableProduct.unitPrice;
        }

        return 0;
    }

    protected get pricePerMonth(): number {
        const plan = this._subscriptionPlan || this.product.subscriptionPlan;

        if(!plan) {
            console.error("Cannot determine the price per month without the subscription plan " + JSON.stringify(this.product));
            return this.price;
        }
        let nbMonths = SubscriptionPlanUtils.getMonths(plan);

        return this.price / nbMonths;
    }

    @Input()
    layout: ProductOrderCardLayout = ProductOrderCardLayout.Selection;

    @Input()
    set subscriptionGrant(grant: SubscriptionGrant) {
        this.productService.getProduct(grant.productShortCode.toLowerCase()).then((product) => {
            if(product) {
                this._productCode = product.shortCode.toLowerCase();
                this._imageUrl = product.imageUrl;
                this._productLabel = product.currentLocalizedName;
                this.p = {
                    seats: grant.seats
                }
            }
        })
    }

    @Input()
    set product(product: SelectableProduct) {
        this._product = product;
        if(product) {
            this.p = product;
            this._currency = product.price.amount.currency;
            this._imageUrl = product.info.imageUrl;
            this._productLabel = product.info.currentLocalizedName;
            this._productCode = product.code.toLowerCase();
            this._subscriptionType = product.subscriptionType;
            this.productService.getProduct(product.code).then((p) => {
                this._currentProduct = p;
            });
        }
    }

    get product() {
        return this._product;
    }

    protected _currentProduct: Product;

    @Input()
    set currency(currency: string) {
        this._currency = currency;
    }

    get  currency() {
        return this._currency;
    }

    @Input()
    set tableProduct(product: TableProduct) {
        this._tableProduct = product;

        if(product) {
            this.p = product;
            this._productCode = product.productCode.toLowerCase();
            this._imageUrl = this.productService.getGameIcon(product.productCode);
            this._productLabel = this.productService.getGameName(product.productCode);

            this.productService.getProduct(product.productCode).then((p) => {
                this._currentProduct = p;
            });
        }
    }

    @Input()
    set subscriptionType(type: SubscriptionType) {
        this._subscriptionType = type;
    }

    @Input()
    set subscriptionPlan(plan: SubscriptionPlan) {
        this.setSuscriptionPlan(plan);
    }

    get tableProduct() {
        return this._tableProduct;
    }

    get totalPrice() {
        if(this._tableProduct) {
            return this._tableProduct.totalPrice;
        }

        if(this._product) {
            return this._product.seats * (this._product.price?.amount.value || 0);
        }
    }

    @Output()
    onUpdate = new EventEmitter<ProductOrderCardComponent>();

    onQuantityChanged(c: number) {
        if(this.layout != ProductOrderCardLayout.Selection) {
            return;
        }

        this.p.seats = c;

        this.onUpdate.emit(this);
    }

    constructor(private productService: ProductService) {

    }

    private setSuscriptionPlan(plan: SubscriptionPlan) {
        this._subscriptionPlan = plan;
        this._months = SubscriptionPlanUtils.getMonths(plan) || 0;
    }

    getStyleClass() {
        return "product-order-card " + this.layout + "-layout";
    }

    getCardHeight() {
        switch(this.layout) {
            case ProductOrderCardLayout.Onboarding:
                return 137;

            case ProductOrderCardLayout.Summary:
                return 191;

            case ProductOrderCardLayout.Selection:
                return 191 - 38;
        }
    }
}
