import { Address } from "../common/address.model";
import { removeDiacriticalMarks } from 'remove-diacritical-marks'

export type ToInterface<T> = {[K in keyof T]: T[K]};
export type ToInterfaceO<T> = {[K in keyof T]?: T[K]};

export class Utils {

    // Convert a string enum to enum.
    // https://stackoverflow.com/questions/17380845/how-do-i-convert-a-string-to-enum-in-typescript
    public static getEnumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
        return (Object.values(enm) as unknown as string[]).includes(value)
            ? value as unknown as T
            : undefined;
    }

    // Source: https://stackoverflow.com/questions/52992321/generic-function-to-convert-string-to-typescript-enum
    public static getEnumFromStringKey<T, K extends string>(enumObj: { [P in K]: T }, str: string): T {
        const enumKey = str as K
        return enumObj[enumKey]
    }

    // Source: https://stackoverflow.com/questions/52992321/generic-function-to-convert-string-to-typescript-enum
    public static getEnumFromString<T, K extends string>(enumObj: { [P in K]: T }, str: string): T {
        let result: T = Utils.getEnumFromStringValue(enumObj, str);

        if(!result) {
            result = Utils.getEnumFromStringKey(enumObj, str);
        }

        return result;
    }

    public static getAddressFormattedData(results: any): Address {
        var street = "";
        var city = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var stateCode = "";
        results.address_components.map((address: any) => {

            switch (address.types[0]) {
                case "street_number":
                    street += " " + address.long_name;
                    break;
                case "route":
                    street += " " + address.long_name;
                    break;
                case "country":
                    country = address.short_name;
                    break;
                case "postal_code":
                    zipcode = address.long_name;
                    break;
                case "administrative_area_level_1":
                    state = address.long_name;
                    stateCode = address.short_name
                    break;
                case "locality":
                    city = address.long_name;
                    break;
                default:
                    if (!city.length) {

                        if (address.types[0] === "administrative_area_level_2") {

                            city = address.long_name;

                        } else if (address.types[0] === "administrative_area_level_3") {

                            city = address.long_name;

                        }
                    }

                    break;
            }
        });

        let result: Address = new Address();
        result.street = street;
        result.city = city;
        result.state = state;
        result.country = country;
        result.zipcode = zipcode;
        result.stateCode = stateCode;

        return result;
    }

    /**
     * Stringify a object and resolve circular reference errors.
     * 
     * @param params 
     * 
     * @returns {string}
     */
    public static stringify(params: any): string {
        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key: any, value: any) => {
                if (typeof value === "object" && value !== null) {
                    if (seen.has(value)) {
                        return;
                    }
                    seen.add(value);
                }
                return value;
            };
        };

        return JSON.stringify(params, getCircularReplacer());
    }

    /**
     * An utility function that take two array and returns list of added and removed elements.
     * 
     * @param sub 
     */
    public static extractAddedAndRemoved(prevList: string[], newList: string[]): { added: string[], removed: string[], preserved: string[] } {
        // Build sets
        let prevSet: Set<string> = new Set<string>();
        prevList.map((e) => {
            prevSet.add(e);
        });

        let newSet: Set<string> = new Set<string>();
        newList.map((e) => {
            newSet.add(e);
        });

        let added: string[] = [];
        let removed: string[] = [];
        let preserved: string[] = [];

        newList.forEach(function (item: string) {
            if (!prevSet.has(item)) {
                added.push(item);
            } else {
                preserved.push(item);
            }
        });

        prevSet.forEach(function (item: string) {
            if (!newSet.has(item)) {
                removed.push(item);
            }
        });

        return {
            added: added,
            removed: removed,
            preserved: preserved
        };
    }

    public static clone<T>(instance: T): T {
        const copy = new (instance.constructor as { new(): T })();
        Object.assign(copy, instance);
        return copy;
    }

    public static isNumber(value: string | number): boolean {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    }

    /**
     * Ensure an error properly converted to an object.
     * 
     * Because when a TypeError is converted to json, it give "{}".
     */
    public static ErrorToObject(err: any) {
        TypeError
        let result: any = err;

        if (err) {
            if (err instanceof Error) { // note: TypeError extends Error
                result = {
                    name: err.name,
                    message: err.message,
                    stack: err.stack
                };

                if (err.stack) result.stack = err.stack;
            }
        }

        return result;
    }

    /**
     * Decode a JWT token.
     * 
     * Source: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
     * 
     * @param token 
     */
    public static parseJwt (token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    public static normalizeEmail(email: string): string {
        email = removeDiacriticalMarks(email);
        email = email.toLowerCase();
        email = email.trim();
        return email;
    }

    public static isSafari(userAgent?: string, vendor?: string) {
        // Source: https://stackoverflow.com/questions/7944460/detect-safari-browser
        var isSafari = vendor && vendor.indexOf('Apple') > -1 &&
            userAgent &&
            userAgent.indexOf('CriOS') == -1 &&
            userAgent.indexOf('FxiOS') == -1;
        
        return isSafari;
    }

    public static monthDiff(dateFrom: Date, dateTo: Date) {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    public static doesDataMatch(obj: any, data: any): boolean {

        if(data == undefined) {
            return false;
        }

        let result: boolean = true;

        for(const key of Object.keys(obj)) {
            const objV = obj[key];
            const dataV = data[key];

            if(typeof objV === 'object') {
                if(typeof dataV === 'object') {
                    result = this.doesDataMatch(objV, dataV);
                }
                else {
                    return false;
                }
            }
            else if(typeof objV !== typeof dataV) {
                return false;
            }
            else if(objV != dataV) {
                return false;
            }

            if(!result) {
                break;
            }
        }

        return result;
    }
}