import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { map } from 'rxjs/operators';
import { GameProgressSelectableColumn } from "./game-progress-selectable-column";
const LOCAL_STORAGE_GAME_ACTIVITY_KEY_FR = "game/activities/fr";
const LOCAL_STORAGE_GAME_ACTIVITY_KEY_EN = "game/activities/en";
const LOCAL_STORAGE_GAME_WORDLIST_KEY_MSM_FR = "game/wordlist/msm/fr";
const LOCAL_STORAGE_GAME_WORDLIST_KEY_MSM_EN = "game/wordlist/msm/en";
@Pipe({
    name: "colName",
    pure: true,
})
export class ColNamePipe implements PipeTransform {
    constructor(private localStoreService: LocalStorageService) { }

    transform(columnKey: any, columns: GameProgressSelectableColumn[] = null): any {
        if (columnKey == "student_name") {
            return $localize`:@@common-student-name: Student’s name `;
        } else {
            if(columns) {
                let column: GameProgressSelectableColumn = columns.find(c => c.key == columnKey);
                if(column) {
                    return column.name;
                }
            }
        }
        
        return columnKey;
    }
}
