<app-page i18n-title="@@main-menu-label-subscriptions"
          title="Subscriptions">
    <app-page-header2-card appDefaultPageHeaderCard
                           bgImageUrl="assets/headers/subscription.jpg">
        <design-card-title i18n="@@subscription-page--title">Subscriptions</design-card-title>
        <design-card-subtitle i18n="@@subscription-page--subtitle">
            Manage the purchase and renewal of your subscriptions, or view your active quotations.
        </design-card-subtitle>
    </app-page-header2-card>
    <app-page-content>
        <mat-tab-group mat-stretch-tabs="false"
                       (selectedTabChange)="onTabChanged($event);">
            <mat-tab i18n-label="@@common-subscriptions"
                     label="Subscriptions">
                <app-subscription-list></app-subscription-list>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <div class="tab-template-label">
                        <span i18n="@@common-quotations">Quotations</span>
                    </div>
                </ng-template>
                <app-quotation-list></app-quotation-list>
            </mat-tab>
            <mat-tab i18n-label="@@common-licenses"
                     label="Licenses"
                     *ngIf="licenses.length > 0">
                <app-license-list [licenses]="licenses"></app-license-list>
            </mat-tab>
        </mat-tab-group>
    </app-page-content>
</app-page>


<!-- Subscription Menu -->
<app-subscription-card-menu #subscriptionMenu="subscriptionMenu"></app-subscription-card-menu>