import { I } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { LanguageService } from 'src/app/services/language.service';
import { StripeUtils } from 'src/app/services/stripe-utils';
import { OrderService } from '../../order/order.service';

@Component({
    selector: 'app-stripe-payment-panel',
    templateUrl: './stripe-payment-panel.component.html',
    styleUrls: ['./stripe-payment-panel.component.scss']
})
export class StripePaymentPanelComponent implements OnInit {

    @Input()
    public clientSecret: string;

    @Input()
    public subscriptionId: string;

    errorObject: any;



    elementsOptions: StripeElementsOptions = {
        locale: 'en'
    };
    
    valid: boolean = true;
    paying: boolean = false;
    succeed: boolean = false;

    @ViewChild(StripePaymentElementComponent)
    paymentElement: StripePaymentElementComponent;
     
    constructor(protected stripeService: StripeService,
        private fb: UntypedFormBuilder,
        private orderService: OrderService,
        private languageService: LanguageService) {
        this.elementsOptions.locale = this.languageService.currentLanguageCode as StripeElementLocale;
    }

    ngOnInit(): void {
    }

    public confirmSetup() {
        return new Promise<string>((resolve, rejects) => {
            this.errorObject = undefined;
            this.paying = true;
            this.stripeService.confirmSetup({
                elements: this.paymentElement.elements,
                confirmParams: {
                    payment_method_data: {

                    }
                },
                redirect: 'if_required'
            }).subscribe(result => {
                this.paying = false;
                if (result.error) {
                    this.errorObject = StripeUtils.localizeStripeError(result.error);
                    resolve(undefined);
                } else {
                    // The payment has been processed!
                    if (result.setupIntent.status === 'succeeded') {
                        this.succeed = true;
                        
                        if (typeof result.setupIntent.payment_method === 'string' || result.setupIntent.payment_method instanceof String) {
                            resolve(result.setupIntent.payment_method as string);
                        }
                        else {
                            resolve(result.setupIntent.payment_method.id);
                        }
                    }
                    else {
                        this.errorObject = {
                            message: "Error unexpected payment status '" + result.setupIntent.status + "'",
                            errorEventId: "StripeStatus" + result.setupIntent.status
                        };

                        resolve(undefined);
                    }
                }
            }, (ex) => {
                console.error(ex);
                rejects(ex);
            });
        });
    }

    public pay() {
        return new Promise<boolean>((resolve, rejects) => {
            this.errorObject = undefined;
            this.paying = true;
            this.stripeService.confirmPayment({
                elements: this.paymentElement.elements,
                confirmParams: {
                    payment_method_data: {
                        /*
                      billing_details: {
                        name: this.paymentElementForm.get('name').value,
                        email: this.paymentElementForm.get('email').value,
                        address: {
                          line1: this.paymentElementForm.get('address').value || '',
                          postal_code: this.paymentElementForm.get('zipcode').value || '',
                          city: this.paymentElementForm.get('city').value || '',
                        }
                      }
                      */
                    }
                },
                redirect: 'if_required'
            }).subscribe(result => {
                this.paying = false;
                if (result.error) {
                    this.errorObject = StripeUtils.localizeStripeError(result.error);
                    resolve(false);
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                        this.succeed = true;
                        resolve(true);
                    }
                    else {
                        this.errorObject = {
                            message: "Error unexpected payment status '" + result.paymentIntent.status + "'",
                            errorEventId: "StripeStatus" + result.paymentIntent.status
                        };

                        resolve(false);
                    }
                }
            }, (ex) => {
                console.error(ex);
                rejects(ex);
            });
        });
    }
}
