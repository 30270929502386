<app-common-list-renderer [list]="list">
    <renderer-templates [emptyTemplate]="emptyQuotationsList"
                        [actionTemplate]="actionsTemplate"></renderer-templates>
    <app-common-list-toolbar [alignLeft]="true">
        <div table-option>
            <div>
                <button mat-stroked-button color="accent" routerLink="/order/request-quotation" i18n="@@quotation-request-button" class="mr-35 request-quotation">
                    Request a quotation
                </button>
            </div>
        </div>
    </app-common-list-toolbar>


    <button mat-menu-item (click)="onShowQuote(selectedMenuActionItem)" menuitems>
        <mat-icon>
            receipt
        </mat-icon>
        <span i18n="@@common-show-quotation">Show the quotation</span>
    </button>

    <button mat-menu-item (click)="onDownloadQuote(selectedMenuActionItem)" menuitems>
        <mat-icon>
            cloud_download
        </mat-icon>
        <span i18n="@@common-download-quotation">Download the quotation</span>
    </button>

    <button mat-menu-item (click)="onCreateInvoiceClick(selectedMenuActionItem)" menuitems>
        <mat-icon>
            create
        </mat-icon>
        <span i18n="@@common-create-invoice">Create an invoice</span>
    </button>

    <button mat-menu-item (click)="onPayQuotationClick(selectedMenuActionItem)" menuitems>
        <mat-icon>
            create
        </mat-icon>
        <span i18n="@@common-pay-now">Pay now</span>
    </button>

</app-common-list-renderer>

<ng-template #emptyQuotationsList>
    <span i18n="@@quotation-list-empty">You don't have Quotations yet.</span>
</ng-template>

<ng-template #actionsTemplate let-row>
    <div [attr.id]="'quo-create-invoice'" *ngIf="row.id == quotationIdForCreatingInvoiceOnboarding" style="width: 0px">
        <app-checklist-popover [key]="'quo-create-invoice-popover'" checklistItem="custom" placement="left-top">
            <p i18n="@@common-click-here" popupcontent>Click Here</p>
        </app-checklist-popover>
    </div>
</ng-template>