<form [formGroup]="baseLoginRegisterForm"
      class="base-login-register-form"
      [attr.name]="formName?formName:formType">

    <input type="hidden"
           *ngIf="isFieldIncluded(FieldType.Context)"
           formControlName="context"
           autocomplete="on"
           id="{{formType}}-{{formName}}-context"
           name="{{formType}}-{{formName}}-context">

    <app-error-message-area *ngIf="showError"
                            [error]="errorObject"></app-error-message-area>
    <app-dev-site-warning *ngIf="!isEmailSameAsLoggedInUser && !isAccountExist"></app-dev-site-warning>

    <div class="logo"
         *ngIf="showLogo">
        <app-logo [blackColor]="true"
                  [responsive]="false"></app-logo>
    </div>

    <div class="register-message"
         *ngIf="(formType == 'register') && showRegisterMessage">
        <p><span i18n="@@register-top-message">Create your account and try our educational solutions for families and schools.</span><br /><span class="no-credit-required"
                  i18n="@@register-top-message-no-credit-card-required">No credit card required.</span></p>
    </div>

    <!-- Fields -->
    <div class="fields-section">
        <div *ngIf="!smallAlternateLogin && isFieldIncluded(FieldType.SocialLogin)"
             [ngClass]="{'social-login-section': !isGoogleButtonHidden, 'hidden-field': isGoogleButtonHidden}">
            <div class="button-section">
                <app-google-login-button buttonType="standard"
                                         [text]="formType == FormType.Register ? 'signup_with' : 'signin_with'"
                                         (onGoogleLoginClick)="onGoogleLoginClick($event)"></app-google-login-button>
                <!-- <app-facebook-login-button buttonType="custom"></app-facebook-login-button> -->
            </div>

            <span>&nbsp;</span>
            <app-error-message-area *ngIf="showError"
                                    [error]="errorObjectGoogle"></app-error-message-area>

            <span class="alternate-section">
                <hr>
                <span i18n="@@alternate-login">OU</span>
                <hr>
            </span>
            <span>&nbsp;</span>
        </div>

        <div>
            <div class="email-field">
                <mat-form-field appearance="outline"
                                floatLabel="floatLabel"
                                style="width: 100%"
                                *ngIf="isFieldIncluded(FieldType.Email)"
                                [ngClass]="{'hidden-field': isEmailHidden}">
                    <mat-label *ngIf="!useLabelAsPlaceholder">{{getLabel('email')}}</mat-label>

                    <input type="email"
                           name="{{formType}}-{{formName}}-email"
                           id="{{formType}}-{{formName}}-email"
                           formControlName="email"
                           autocomplete="on"
                           [placeholder]="getPlaceholder('email')"
                           matInput
                           required
                           (blur)="checkEmailExist2()"
                           (keyup.enter)="callingLoginApis()">
                    <mat-error *ngIf="baseLoginRegisterForm.controls.email.invalid">
                        {{ formService.getEmailErrorMessage(baseLoginRegisterForm.controls.email) }}
                    </mat-error>
                </mat-form-field>
                <div>
                    <div *ngIf="smallAlternateLogin && isFieldIncluded(FieldType.SocialLogin)"
                         [ngClass]="{'google-login-section-small': !isGoogleButtonHidden, 'hidden-field': isGoogleButtonHidden}">
                        <app-google-login-button [buttonType]="'icon'"
                                                 (onGoogleLoginClick)="onGoogleLoginClick($event)"></app-google-login-button>
                    </div>
                </div>
            </div>
        </div>


        <div class="show-email-message"
             *ngIf="!isHiddenErrorEmailExist">
            <span [ngClass]="{'account-not-exist': !isAccountExist,'account-exist': isAccountExist}">{{ emailExistMessage }}</span>
        </div>

        <mat-form-field appearance="outline"
                        floatLabel="floatLabel"
                        style="width: 100%"
                        *ngIf="isFieldIncluded(FieldType.FullName)"
                        [ngClass]="{'hidden-field': isFullNameHidden}">
            <mat-label *ngIf="!useLabelAsPlaceholder">{{getLabel('userName')}}</mat-label>
            <input type="text"
                   id="{{formType}}-{{formName}}-fullName"
                   name="{{formType}}-{{formName}}-fullName"
                   autocomplete="on"
                   formControlName="fullName"
                   matInput
                   [placeholder]="getPlaceholder('userName')"
                   (change)="formService.trimSpaces(baseLoginRegisterForm.controls.fullName)"
                   required>

            <mat-error *ngIf="baseLoginRegisterForm.controls.fullName.invalid">
                {{ formService.getFullNameErrorMessage(baseLoginRegisterForm.controls.fullName) }}
            </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline"
                        floatLabel="floatLabel"
                        *ngIf="isFieldIncluded(FieldType.ProfileName)"
                        [ngClass]="{'hidden-field': isProfileHidden}">
            <mat-label *ngIf="!useLabelAsPlaceholder">{{getLabel('profile')}}</mat-label>
            <input type="text"
                   matInput
                   formControlName="username"
                   autocomplete="on"
                   id="{{formType}}-{{formName}}-profileName"
                   name="{{formType}}-{{formName}}-profileName"
                   [placeholder]="getPlaceholder('profile')"
                   (input)="formService.preventSpaces(baseLoginRegisterForm.get('username'))"
                   required>
            <mat-error *ngIf="baseLoginRegisterForm.controls.username.invalid">
                {{ formService.getFormUsernameErrorMessage(baseLoginRegisterForm.controls.username) }}
            </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline"
                        floatLabel="floatLabel"
                        style="width: 100%"
                        *ngIf="isFieldIncluded(FieldType.Password)"
                        [ngClass]="{'hidden-field': isPasswordHidden}">
            <mat-label *ngIf="!useLabelAsPlaceholder">{{getLabel('password')}}</mat-label>
            <mat-icon class="password-vis-toggle"
                      matSuffix
                      (click)="passwordField.type = passwordField.type == 'text' ? 'password': 'text'">
                {{ passwordField.type == "password" ? 'visibility' : 'visibility_off' }}
            </mat-icon>
            <input type="password"
                   matInput
                   formControlName="password"
                   name="{{formType}}-{{formName}}-password"
                   id="{{formType}}-{{formName}}-password"
                   autocomplete="on"
                   [placeholder]="getPlaceholder('password')"
                   (input)="formService.trimSpaces(baseLoginRegisterForm.controls.password)"
                   (keyup.enter)="callingLoginApis()"
                   [required]="isPasswordRequired"
                   #passwordField>
            <mat-error *ngIf="baseLoginRegisterForm.controls.password.invalid">
                {{ formService.getPasswordErrorMessage(baseLoginRegisterForm.controls.password,formType) }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
                        floatLabel="floatLabel"
                        *ngIf="isFieldIncluded(FieldType.ConfirmPassword)"
                        [ngClass]="{'hidden-field': isConfirmPasswordHidden }">
            <mat-label *ngIf="!useLabelAsPlaceholder">{{getLabel('confirmPassword')}}</mat-label>
            <mat-icon class="password-vis-toggle"
                      matSuffix
                      (click)="confirmPasswordField.type = confirmPasswordField.type == 'text' ? 'password': 'text'">
                {{ confirmPasswordField.type == "password" ? 'visibility' : 'visibility_off' }}
            </mat-icon>
            <input #confirmPasswordField
                   type="password"
                   matInput
                   formControlName="confirmPassword"
                   name="{{formType}}-{{formName}}-confirmPassword"
                   id="{{formType}}-{{formName}}-confirmPassword"
                   autocomplete="on"
                   (input)="formService.trimSpaces(baseLoginRegisterForm.controls.confirmPassword)"
                   [placeholder]="getPlaceholder('confirmPassword')"
                   [required]="isPasswordRequired">
            <mat-error *ngIf="baseLoginRegisterForm.controls.confirmPassword.invalid">
                {{ formService.getPasswordErrorMessage(baseLoginRegisterForm.controls.confirmPassword,formType) }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
                        floatLabel="floatLabel"
                        style="width: 100%"
                        *ngIf="isFieldIncluded(FieldType.InviteCode) && !isInviteCodeHidden"
                        [ngClass]="{'hidden-field': isInviteCodeHidden}">
            <mat-label *ngIf="!useLabelAsPlaceholder">{{getLabel('inviteCode')}}</mat-label>
            <input type="text"
                   id="{{formType}}-{{formName}}-inviteCode"
                   name="{{formType}}-{{formName}}-inviteCode"
                   autocomplete="on"
                   formControlName="inviteCode"
                   matInput
                   [placeholder]="getPlaceholder('inviteCode')"
                   (change)="formService.trimSpaces(baseLoginRegisterForm.controls.inviteCode)"
                   required>

            <!--
        <mat-error *ngIf="baseLoginRegisterForm.controls.inviteCode.invalid">
        {{ formService.getFullNameErrorMessage(baseLoginRegisterForm.controls.inviteCode) }}
        </mat-error>
        -->

        </mat-form-field>

        <mat-form-field appearance="outline"
                        floatLabel="floatLabel"
                        *ngIf="isFieldIncluded(FieldType.Language)"
                        [ngClass]="{'hidden-field': !isLanguageShow }">
            <mat-label *ngIf="!useLabelAsPlaceholder"
                       i18n="@@common-language"> Language </mat-label>
            <mat-select formControlName="lang"
                        name="lang">
                <mat-option *ngFor="let l of language.languages"
                            value="{{ l }}">{{ language.getLanguageName(l) }}</mat-option>
            </mat-select>
        </mat-form-field>

        <app-user-email-prefs [formGroup]="emailPrefsFormGroup"
                              *ngIf="isFieldIncluded(FieldType.UserEmailPreferences)"
                              [ngClass]="{'hidden-field': isUserEmailPrefsHidden }"></app-user-email-prefs>

        <mat-form-field class="no-border-form-field checkbox-form-field"
                        appearance="outline"
                        floatLabel="floatLabel"
                        *ngIf="isFieldIncluded(FieldType.AcceptTerms)">
            <input hidden=true
                   formControlName="acceptTerms"
                   matInput>
            <mat-checkbox class="accept-terms-checkbox"
                          (change)="onAccepTermsChanged($event)"
                          (contextmenu)="showDebugs($event)"
                          i18n="@@register-form--agree-terms-and-privacy">I agree to the <a [href]="termsLink"
                   target="_blank">Terms of Service</a> and <a [href]="privacyLink"
                   target="_blank">Privacy Policy</a></mat-checkbox>
            <mat-error *ngIf="baseLoginRegisterForm.controls.acceptTerms.invalid"
                       i18n="@@common-accept-terms--must-check">
                Please check this box if you want to proceed.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="no-border-form-field checkbox-no-error-form-field"
                        appearance="outline"
                        floatLabel="floatLabel"
                        *ngIf="isFieldIncluded(FieldType.AcceptNewsletter)"
                        [formGroup]="emailPrefsFormGroup">
            <input hidden=true
                   class="hidden-field"
                   formControlName="newsletters"
                   matInput>
            <mat-checkbox class="accept-newsletter-checkbox"
                          (change)="onAccepNewsletterChanged($event)"
                          (contextmenu)="showDebugs($event)"
                          [checked]="acceptNewsletter"
                          i18n="@@register-form--agree-newsletter">join mailing list</mat-checkbox>
        </mat-form-field>
    </div>

    <ng-content select="[actions]"></ng-content>

    <div class="accept-terms-text"
         *ngIf="(formType == 'register') && !isFieldIncluded(FieldType.AcceptTerms)">
        <p><span i18n="@@accept-terms-on-button-click">By clicking "REGISTER" or "Sign up with Google", you agree to the <a [href]="termsLink"
                   target="_blank">Appligogiques Terms of Service</a> and <a [href]="privacyLink"
                   target="_blank">Privacy Policy</a>.</span></p>
    </div>
</form>