<span #hiddenText style="visibility: hidden; white-space: pre;">{{hiddenTextValue}}</span>

<div *ngIf="wordsInitialized" class="wordlist">
    <div class="wordedit" *ngFor="let container of containers">
        <input #wordInput (paste)="onPaste(container, $event)" [(ngModel)]="container.word.text" (ngModelChange)="onWordPropertyChanged($event, container, 'text')" (blur)="onBlur($event, container)" (input)="onInput($event, container)" class="word-input" [ngStyle]="{'width.px': container.width, 'white-space':'pre'}" matInput>
        <div>
            <button mat-button (click)="removeWord($event, container)" class="close-button">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-button (click)="addNewWord($event)" class="add-button">
            <mat-icon class="add-icon">add_circle_outline</mat-icon>
        </button>
        <button mat-button (click)="alphaSort()" class="add-button">
            <mat-icon class="add-icon">sort_by_alpha</mat-icon>
        </button>
    </div>
</div>

<app-error-message-area *ngIf="wordsInitialized" [error]="errorObject"></app-error-message-area>