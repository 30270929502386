import { EqolCategoryInfo } from "./category-info";
import { EqolColumnInfo } from "./column-info";
import { StringUtils } from "./StringUtils";

export let EqolColumns : Array<EqolColumnInfo> = [];
export let EqolCategories : Array<EqolCategoryInfo> = [];
export let EqolColumnsDict : Map<string, EqolColumnInfo> = new Map<string, EqolColumnInfo>();


function initColumns() {
    let wordCategory: EqolCategoryInfo = new EqolCategoryInfo("word", "mot");
    EqolCategories.push(wordCategory);

    EqolColumns = [];

    // Define all simple columns.
    let columns = [
        {
            key: "cgram",
            label: "Catégorie grammaticale",
            category: wordCategory,
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                return data.cgram ? data.cgram.join(' ') : '-';
            }
        
        },
        {
            key: "consistency",
            label: "Consistance orthographique",
            category: wordCategory,
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                return data.consistency ? data.consistency : undefined;
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                return data.consistency ? data.consistency + '%' : '-';
            }
        },
        {
            key: "files",
            label: "files"
        },
        {
            key: "frequency",
            label: "Fréquence"
        },
        {
            key: "gemin",
            label: "Lettres géminées",
            category: wordCategory,
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                return data.gemin ? data.gemin : undefined;
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);
                return v ? v: '-';
            }
        },
        {
            key: "gender",
            label: "Genre",
            category: wordCategory
        },
        {
            key: "nbletters",
            label: "nombre de lettres",
            category: wordCategory
        },
        {
            key: "nbsyll",
            label: "nombre de syllabes",
            databaseField: "nbsyll",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                return (data.nbsyll && data.nbsyll.written) ? data.nbsyll.written : undefined;
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);
                return v ? v: '-';
            }
        },
        {
            key: "number",
            label: "Nombre",
            category: wordCategory
        },
        {
            key: "phon",
            label: "Phoneme (IPA)",
            category: wordCategory,
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let phon: any = data.phon;
                if (Array.isArray(phon)) {
                    let phons = [...phon];
                    for(let i=0; i<phons.length;i++) {
                        phons[i] = '/' + StringUtils.Sampa2Ipa(phons[i]).trim() + '/';
                    }
                    return phons;
                }

                if (!phon) return undefined;
                return '/' + StringUtils.Sampa2Ipa(phon).trim() + '/';
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.join(", ");
                }

                return v ? v: '';
            }
        },
        {
            key: "phon_sampa",
            label: "Phoneme (X-Sampa)",
            category: wordCategory,
            databaseField: "phon",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let phon: any = data.phon;
                if (!phon) return undefined;

                if (Array.isArray(phon)) {
                    let phons = [...phon];
                    for(let i=0; i<phons.length;i++) {
                        phons[i] = phons[i].trim();
                    }
                    return phons;
                }

                return phon.trim();
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.join(", ");
                }

                return v ? v: '';
            }
        },
        {
            key: "phon_manulex",
            label: "Phoneme (Manulex)",
            category: wordCategory,
            databaseField: "phons",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let phons: any = data.phons;
                if (!phons) return undefined;
                return phons['manulex'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.join(", ");
                }

                return v ? v: '';
            }
        },
        {
            key: "phon_lexique",
            label: "Phoneme (Lexique.org)",
            category: wordCategory,
            databaseField: "phons",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let phons: any = data.phons;
                if (!phons) return undefined;
                return phons['lexique'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.join(", ");
                }

                return v ? v: '';
            }
        },
        {
            key: "word",
            label: "Mot"
        },
        {
            key: "nbsyll.oral",
            label: "nombre de syllabes orales",
            category: wordCategory,
            databaseField: "nbsyll",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                return (data.nbsyll && data.nbsyll.oral) ? data.nbsyll.oral : undefined;
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);
                return v ? v: '-';
            }
        },
        {
            key: "nbsyll.written",
            label: "nombre de syllabes écrites",
            category: wordCategory,
            databaseField: "nbsyll",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                return (data.nbsyll && data.nbsyll.written) ? data.nbsyll.written : undefined;
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);
                return v ? v: '-';
            }
        },
        {
            key: "sentence",
            label: "Phrase",
            category: wordCategory
        },
        {
            key: "nbphons",
            label: "nombre de phonèmes"
        },
        {
            key: "seg.pg",
            label: "Phonème-Graphème",
            category: wordCategory,
            databaseField: "seg",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let seg: any = data?.seg;
                if (!seg) return undefined;
                return seg['pg'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                // Note that the grapheme part of the association seems to remains intact when using Sampa2Ipa because it is all in lower case.
                if (Array.isArray(v)) {
                    v = v.map(s => StringUtils.Sampa2IpaAssociation(s)).join(", ");
                }
                else if(v) {
                    v = StringUtils.Sampa2IpaAssociation(v);
                }

                return v ? v: '';
            }
        },
        {
            key: "seg.gp",
            label: "Graphème-Phonème",
            category: wordCategory,
            databaseField: "seg",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let seg: any = data?.seg;
                if (!seg) return undefined;
                return seg['gp'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.map(s => StringUtils.Sampa2IpaAssociation(s)).join(", ");
                }
                else if(v) {
                    v = StringUtils.Sampa2IpaAssociation(v);
                }
                
                return v ? v: '';
            }
        },
        {
            key: "mathword",
            label: "Mot Mathématique",
            category: wordCategory,
            databaseField: "mathword",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let seg: any = data?.mathword;
                if (!seg) return undefined;
                return seg;
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.join(", ");
                }
                
                return v ? "oui": '';
            }
        },
        {
            key: "rhyme.ortho",
            label: "Rime (Orthographe)",
            category: wordCategory,
            databaseField: "rhyme",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let rhyme: any = data?.rhyme;
                if (!rhyme) return undefined;
                return rhyme['ortho'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.join(", ");
                }
                
                return v ? v: '';
            }
        },
        {
            key: "rhyme.phon",
            label: "Rime (Phonèmes)",
            category: wordCategory,
            databaseField: "rhyme",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let rhyme: any = data?.rhyme;
                if (!rhyme) return undefined;
                return rhyme['phon'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.map(s => StringUtils.Sampa2Ipa(s)).join(", ");
                }
                else if(v) {
                    v = StringUtils.Sampa2Ipa(v);
                }
                
                return v ? v: '';
            }
        },
        {
            key: "rhyme.match",
            label: "Rime (Association)",
            category: wordCategory,
            databaseField: "rhyme",
            getValueCallback: (column: EqolColumnInfo, data: any) => {
                let rhyme: any = data?.rhyme;
                if (!rhyme) return undefined;
                return rhyme['match'];
            },
            getFormattedValueCallback: (column: EqolColumnInfo, data: any) => {
                let v: any = column.getValue(data);

                if (Array.isArray(v)) {
                    v = v.map(s => StringUtils.Sampa2IpaAssociation(s)).join(", ");
                }
                else if(v) {
                    v = StringUtils.Sampa2IpaAssociation(v);
                }
                
                return v ? v: '';
            }
        }
    ];

    columns.map((c: any) => {
        let column: EqolColumnInfo = new EqolColumnInfo(c.key, c.label);
        if(c.category) column.category = c.category;
        if(c.getValueCallback) column.getValueCallback = c.getValueCallback;
        if(c.getFormattedValueCallback) column.getFormattedValueCallback = c.getFormattedValueCallback;
        if(c.databaseField) column.databaseField = c.databaseField;

        EqolColumns.push(column);
    });


    // Define all composed columns.
    initComposedColumns();


    EqolColumns.forEach((c: EqolColumnInfo) => {
        if(c.category) {
            c.category.addColumn(c);
        }

        EqolColumnsDict.set(c.key, c);
    });

    // Define getValue for all columns where getValue is not defined.
    EqolColumns.forEach((c: EqolColumnInfo) => {
        if(!c.databaseField) c.databaseField = c.key;

        if(!c.getValueCallback) {
            c.getValueCallback = (column: EqolColumnInfo, data: any) => {
                return data[column.key];
            };

            c.getFormattedValueCallback = (column: EqolColumnInfo, data: any) => {
                let val: any = data[column.key];
                if(!val)
                {
                    val = "-";
                }

                return val;
            };
        }
    });
}

/**
 * Init composed columns.
 * 
 * Composed columns are frequency, frequency_cumul_*, frequency_total_*, success_level_*
 */
function initComposedColumns() {
    let frequencyCategory: EqolCategoryInfo = new EqolCategoryInfo("frequency_total", "fréquence");
    EqolCategories.push(frequencyCategory);

    let column: EqolColumnInfo = new EqolColumnInfo("frequency", "Fréquence totale");
    column.category = frequencyCategory;
    column.data.spacer = true;
    column.group = 0;
    column.databaseField = "frequence.eqol.forme_million";
    column.getValueCallback = (column: EqolColumnInfo, data: any) => {
        return (data.frequence && data.frequence.eqol && data.frequence.eqol.forme_million) ? data.frequence.eqol.forme_million: undefined
    };
    column.getFormattedValueCallback = (column: EqolColumnInfo, data: any) => {
        let v: any = column.getValue(data);
        return v ? (v / 1000).toFixed(4)  : '-';
    };
    EqolColumns.push(column);

    for(let i=2; i<=6;i++)
    {
        let level: number = i;
        let column: EqolColumnInfo = new EqolColumnInfo("frequency_cumul_" + i, "Fréquence cumulée 1-" + i);
        column.data.level = i;
        column.category = frequencyCategory;
        column.group = 1;
        column.databaseField = "frequence.eqol.cumul_moy.a" + i;
        column.getValueCallback = (column: EqolColumnInfo, data: any) => {
            const LEVEL_PROP = 'a' + level;
            return (data.frequence && data.frequence.eqol.cumul_moy && data.frequence.eqol.cumul_moy[LEVEL_PROP]) && typeof(data.frequence.eqol.cumul_moy[LEVEL_PROP]) === 'number' ? data.frequence.eqol.cumul_moy[LEVEL_PROP] : undefined
        };
        column.getFormattedValueCallback = (column: EqolColumnInfo, data: any) => {
            let v: any = column.getValue(data);
            return v ? v.toFixed(4) : '-';
        };
        EqolColumns.push(column);
    }

    for(let i=1; i<=6;i++)
    {
        let level: number = i;
        let column: EqolColumnInfo = new EqolColumnInfo("frequency_level_" + i, "Fréquence par classe " + i);
        column.category = frequencyCategory;
        column.data.level = i;
        column.group = 2;
        column.databaseField = "frequence.eqol.total_log_1000.a" + i;
        column.getValueCallback = (column: EqolColumnInfo, data: any) => {
            const LEVEL_PROP = 'a' + level;
            return (data.frequence && data.frequence.eqol.total_log_1000 && data.frequence.eqol.total_log_1000[LEVEL_PROP]) && typeof(data.frequence.eqol.total_log_1000[LEVEL_PROP]) === 'number' ? data.frequence.eqol.total_log_1000[LEVEL_PROP] : undefined;
        };
        column.getFormattedValueCallback = (column: EqolColumnInfo, data: any) => {
            let v: any = column.getValue(data);
            return v ? v.toFixed(4) : '-';
        };

        EqolColumns.push(column);
    }

    let successCategory: EqolCategoryInfo = new EqolCategoryInfo("success_total", "Taux de réussite");
    EqolCategories.push(successCategory);

    column = new EqolColumnInfo("success_total", "Taux de réussite");
    column.category = successCategory;
    column.data.spacer = true;
    column.group = 0;
    column.databaseField = "success.total";
    column.getValueCallback = (column: EqolColumnInfo, data: any) => {
        return data.success && data.success.total ? data.success.total : undefined;
    };
    column.getFormattedValueCallback = (column: EqolColumnInfo, data: any) => {
        let v: any = column.getValue(data);
        return v ? (v * 100).toFixed(2) + '%': '-';
    };
    EqolColumns.push(column);

    for(let i=1; i<=6;i++)
    {
        let level: number = i;
        let column: EqolColumnInfo = new EqolColumnInfo("success_level_" + i, "Réussite classe " + i);
        column.category = successCategory;
        column.data.level = i;
        column.group = 1;
        column.databaseField = "success.a" + i;
        column.getValueCallback = (column: EqolColumnInfo, data: any) => {
            const LEVEL_PROP = 'a' + level;
            return data.success && data.success[LEVEL_PROP] ? data.success[LEVEL_PROP] : undefined;
        };
        column.getFormattedValueCallback = (column: EqolColumnInfo, data: any) => {
            let v: any = column.getValue(data);
            return v ? (v * 100).toFixed(2) + '%': '-';
        };
        EqolColumns.push(column);
    }
}

initColumns();
