import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionModeChipComponent } from './subscription-mode-chip/subscription-mode-chip.component';


const EXPORTS_COMPONENTS = [
    SubscriptionModeChipComponent
];

@NgModule({
    declarations: [
        ...EXPORTS_COMPONENTS
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ...EXPORTS_COMPONENTS
    ]
})
export class SubscriptionUiModule { }
