import { Component, Input, Optional } from '@angular/core';
import { CommonListRenderOptions } from '../../common-list-render-options';
import { ICommonList } from '../../common-list.interface';
import { CommonListRendererComponent } from '../../common-list-renderer/common-list-renderer.component';
import { Subscription } from 'rxjs';
import { CommonListComponent } from '../../common-list.component';


@Component({
    selector: 'app-common-list-toolbar',
    templateUrl: './common-list-toolbar.component.html',
    styleUrl: './common-list-toolbar.component.scss'
})
export class CommonListToolbarComponent {

    public _options: CommonListRenderOptions = {};
    public _extraOptions: CommonListRenderOptions = {};

    @Input()
    set options(options: CommonListRenderOptions) {
        this._options = options;
        Object.assign(this._options, this._extraOptions);
    }

    @Input()
    set extraOptions(options: CommonListRenderOptions) {
        this._extraOptions = options;
        Object.assign(this._options, this._extraOptions);
    }

    _list: ICommonList;

    protected set list(l: ICommonList) {
        this._list = l;
    }

    protected get list() {
        return this._list;
    }

    protected _parent: CommonListRendererComponent|CommonListComponent<any>;
    protected _renderer: CommonListRendererComponent;

    @Input()
    set parent(p: CommonListRendererComponent|CommonListComponent<any>) {
        for(const sub of this._subscriptions) {
            sub.unsubscribe;
        }
        this._subscriptions = [];

        this._parent = p;

        if(p) {
            if(p instanceof CommonListRendererComponent) {
                this.setFromRenderer(p);
            }
            else {
                if(p.renderer) {
                    this.setFromRenderer(p.renderer);
                }
                else {
                    // Renderer may be not initialized yet.
                    this._subscriptions.push(p.onRendererAssigned.subscribe((renderer) => {
                        if(renderer) {
                            // Simply redo the reassignation of the parent.
                            setTimeout(() => {
                                this.parent = p;
                            });
                        }
                    }));
                    this.list = p;
                    this.options = p.rendererOptions;
                }
            }
        }
    }

    get parent() {
        return this._parent;
    }

    private _subscriptions: Subscription[] = [];

    @Input()
    alignLeft: boolean = false;

    constructor(@Optional() parent: CommonListRendererComponent) {
        if(parent != null) {
            this.parent = parent;
        }
    }

    ngOnDestroy() {
        this.parent = undefined;
    }
    
    private setFromRenderer(renderer: CommonListRendererComponent) {
        this._renderer = renderer;
        this.list = renderer.list;
        this._subscriptions.push(renderer.listChange.subscribe((list) => {
            this.list = list;
        }));

        this.options = renderer.options;
        this._subscriptions.push(renderer.optionsChange.subscribe((options) => {
            this.options = options;
        }));
    }
}
