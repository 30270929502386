<img class="credit-card"
     *ngFor="let card of cards"
     [alt]="card.label"
     [title]="card.label"
     [src]="card.img" />
<div class="other-cards">
    <img class="other-credit-card"
         *ngFor="let card of otherCards; let i = index"
         [alt]="_currentLabel"
         [title]="_currentLabel"
         [src]="card.img"
         [ngClass]="{'visible': _currentIndex == i}" />
</div>