import { ArrayMember, ID, Member, Model, Required } from "@uon/model";
import { OnboardingAction } from "./action";
import { OnboardingCondition } from "./condition";

@Model()
export class OnboardingTransition {

    @ID() 
    id: string;

    @Member() 
    name: string;

    @Member()
    from: string;

    @Member() 
    to: string;

    @Member() 
    priority: number;

    // Conditions for the transition to happen.
    @ArrayMember(OnboardingCondition)
    conditions: OnboardingCondition[];

    // List of actions to execute when using this transition.
    @ArrayMember(OnboardingAction)
    onTransition: OnboardingAction[];
}