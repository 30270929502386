<div *ngIf="themeService.visible" class="compact-input theme-switch-select">
    <!-- <button (click)="toggleTheme()">Toggle Theme</button>
    <span>{{themeService.theme}}</span> -->
    <mat-form-field class="no-subscripting-form-field">
        <mat-select [(ngModel)]="selectedTheme"
                    (ngModelChange)="onThemeSelect()">
            <mat-option [value]="theme"
                        *ngFor="let theme of themeService.themes">{{theme}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>