import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-progress-step',
    templateUrl: './progress-step.component.html',
    styleUrl: './progress-step.component.scss'
})
export class ProgressStepComponent {

    protected _steps:number[] = [1, 2];

    protected _nbSteps: number = 2;

    @Input()
    protected style: 'bar'|'dot' = 'bar';

    @Input()
    set nbSteps(count: number) {
        this._nbSteps = count;
        this._steps = Array.from({ length: count }, (v, k) => k + 1);
    }

    @Input()
    currentStep: number = 1;

    @Input()
    readonly: boolean = true;

    @Input()
    cumulative: boolean = true;

    @Output()
    onChange: EventEmitter<number> = new EventEmitter<number>();


    protected onStepClick(idx: number) {
        if(!this.readonly) {
            this.currentStep = idx;
            this.onChange.emit(idx);
        }
    }

    protected isStepComplete(idx: number) {
        if(this.cumulative) {
            return idx <= this.currentStep;
        }
        else {
            return idx == this.currentStep;
        }
    }
}
