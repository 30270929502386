import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from '@applogic/model';
import * as moment from 'moment';


enum LabelState {
    SubscriptionWillRenew = 'subscription-will-renew',
    SubscriptionWillExpire = 'subscription-will-expire',
    TokensWillRenew = 'tokens-will-renew',
    TokensWillExpire = 'tokens-will-expire',
    AutoRenewWillRenew = 'auto-renew-will-renew',
    AutoRenewWillExpire = 'auto-renew-will-expire',
}

enum DisplayMode {
    Subscription = 'subscription',
    Tokens = 'tokens',
    AutoRenew = 'auto-renew'
}

@Component({
    selector: 'app-subscription-validity-label',
    templateUrl: './subscription-validity-label.component.html',
    styleUrls: ['./subscription-validity-label.component.scss']
})
export class SubscriptionValidityLabelComponent implements OnInit {

    LabelState = LabelState;

    protected _subscription: Subscription;
    protected _displayMode: DisplayMode = DisplayMode.Subscription;


    @Input()
    set subscription(sub: Subscription) {
        this._subscription = sub;

        this.updateState();
    }

    get subscription() {
        return this._subscription;
    }

    @Input()
    showNumber: boolean = true;

    @Input()
    set displayMode (mode: DisplayMode) {
        this._displayMode = mode;
        this.updateState();
    }

    protected _state: LabelState;

    constructor() { }

    ngOnInit(): void {
    }

    checkSubscriptionExpire(expireDate: Date) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }

    getState() {
        this.updateState();
        return this._state;
    }

    updateState() {
        this._state = undefined;

        const sub = this._subscription;

        if(!sub) {
            return;
        }

        if(!this.checkSubscriptionExpire(sub.expiresOn)) {
            const isRenew = (sub?.renewOption != 'Do Nothing') && (sub?.renewOption != undefined);

            switch(this._displayMode) {
                case DisplayMode.Subscription:
                    this._state = isRenew ? LabelState.SubscriptionWillRenew : LabelState.SubscriptionWillExpire;
                    break;
                
                case DisplayMode.Tokens:
                    this._state = isRenew ? LabelState.TokensWillRenew : LabelState.TokensWillExpire;
                    break;

                case DisplayMode.AutoRenew:
                    this._state = isRenew ? LabelState.AutoRenewWillRenew : LabelState.AutoRenewWillExpire;
                    break;
            }
        }
    }
}
