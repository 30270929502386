import { Component, Input } from '@angular/core';
import { AbstractCustomFormFieldComponent } from '../abstract-custom-form-field/abstract-custom-form-field.component';
import { FormService } from 'src/app/services/form.service';
import { BaseFieldConfig, MatFormFieldConfig } from '../../custom-form-field-config';


export interface InputFieldConfig extends BaseFieldConfig, MatFormFieldConfig {
    placeholder?: string;
    autocomplete?: boolean;
    inputType?: string;
    hidePlaceholderOnFocus?: boolean;
    trimSpaces?: boolean; // Default true

}


@Component({
    selector: 'app-custom-form-input-field',
    templateUrl: './custom-form-input-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-input-field.component.scss']
})
export class CustomFormInputFieldComponent extends AbstractCustomFormFieldComponent {

    @Input()
    data: InputFieldConfig;

    hidePlaceholderOnFocus?: boolean;

    private _placeholder2: string;
    
    constructor(protected formService: FormService) {
        super();
    }

    ngOnInit() {
        this.hidePlaceholderOnFocus = this.data.hidePlaceholderOnFocus;
        if (this.hidePlaceholderOnFocus) {
            this._placeholder2 = this.data.placeholder;
        }
    }
    
    onInputFocus() {
        // this.field.onInputFocus();
        if (this.hidePlaceholderOnFocus) {
            this.field.placeholder = "";
        }
    }

    onInputFocusOut() {
        // this.field.onInputFocusOut();
        if (this.hidePlaceholderOnFocus) {
            this.field.placeholder = this._placeholder2;
        }
    }

    onInputChange(event: Event) {
        // this.field.onInputChange(event);
        if (this.data.trimSpaces !== false) {
            const target: any = event.target;
            this.field.control.setValue(target.value.trim());
        }
    }
}
