import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { LoadingService } from '../../services/loading.interceptor';
import { COLLAPSE_HEIGHT } from 'src/app/animations/collapse';
import { MatStepper } from '@angular/material/stepper';
import { BaseLoginRegisterFormComponent } from '../../auth-ui/base-login-register-form/base-login-register-form.component';
import { ApiDirectoryServer, UserServiceType, Utils } from '@applogic/model';
import { environment } from 'src/environments/environment';
import { SocialLoginService } from 'src/app/social/social-login.service';
import { SocialLoginData } from 'src/app/social/social-login-data';
import { Router } from '@angular/router';




@Component({
    selector: 'app-login-form-header',
    template: '<ng-content></ng-content>',
})
export class LoginFormHeaderComponent implements OnInit {

    constructor() { }

    ngOnInit() {

    }

}

@Component({
    selector: 'app-login-form-footer',
    template: '<ng-content></ng-content>',
})
export class LoginFormFooterComponent implements OnInit {

    constructor() { }

    ngOnInit() {

    }

}



@Component({
    selector: 'app-login-form',
    animations: [COLLAPSE_HEIGHT],
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss', '../common-login-register.scss']
})
export class LoginFormComponent implements OnInit {

    email: string = "";

    user: any = {
        id: "",
        fullName: ''
    };

    errorObject: any;

    passwordError: string = null;

    @Input()
    multiStep: boolean = false;

    @ViewChild(MatStepper, { static: true })
    stepper: MatStepper;

    @ViewChild(BaseLoginRegisterFormComponent) childComponent: BaseLoginRegisterFormComponent;


    @Output() success = new EventEmitter<any>();
    @Output() failure = new EventEmitter<any>();

    isStepNext: boolean = false;

    server: ApiDirectoryServer;

    constructor(
        private authService: AuthService,
        public loading: LoadingService,
        private socialLoginService: SocialLoginService,
        private router: Router) {
    }

    ngOnInit(): void {
    }

    emailCheck(formData: UntypedFormGroup) {
        if (formData.controls.email.invalid) {
            return false;
        }

        this.errorObject = null;

        const email = formData.value.email;

        this.authService.getAuthenticateServers(email, true, UserServiceType.Appligogiques).then((servers) => {
            if (servers && servers.length) {
                const firstServer = servers[0];
                if (environment.serverType != "prod") {
                    console.log("Selected server: " + JSON.stringify(firstServer));
                }
                this.server = firstServer.server;
                this.user = { id: firstServer.id, fullName: firstServer.fullName };
                this.user.email = email;
                this.email = email;
                this.stepper.next();
                this.isStepNext = true;
            } else {
                this.errorObject = {
                    userMessage: $localize`:@@login-form-email-not-associated-with-any-account:There’s no account associated with this email address.`
                }
            }
        }).catch((err) => {
            this.errorObject = err;
            const e = err.error;

            if (e.email) {
                // this.errorObject.userMessage = e.email[0];
            }
        });

        /*
        this.authService.getAuthenticateUserEmail(email).subscribe((res) => {

            if (res && res.result) {
                this.user = res.result;
                this.user.email = email;
                this.email = email;
                this.stepper.next();
                this.isStepNext = true;
            } else {
                this.errorObject = {
                    userMessage: $localize`:@@login-form-email-not-associated-with-any-account:There’s no account associated with this email address.`
                }
            }
        }, (err) => {
            this.errorObject = err;
            const e = err.error;

            if (e.email) {
                // this.errorObject.userMessage = e.email[0];
            }
        });
        */
    }

    stepBack() {
        this.isStepNext = false;
        this.stepper.previous();
        this.errorObject = null;
    }

    onServiceLoginClick(response: SocialLoginData) {
        this.errorObject = undefined;
        this.authService.tryLoginServiceServerResolve(response).then(() => {
            this.success.emit(response);
        }).catch((ex) => {
            const payload = Utils.parseJwt(response.data.credential);

            if (payload) {
                if (payload.email) {
                    if (payload.name) {
                        BaseLoginRegisterFormComponent.registerUserName = payload.name;
                    }
                    BaseLoginRegisterFormComponent.registerUserEmail = payload.email;
                    this.router.navigate(['/register']);
                }
            }

            this.errorObject = ex;
        });
    }

    signIn(formData: UntypedFormGroup) {
        if (formData.controls.password.invalid) {
            return false;
        }

        this.authService.clear();
        this.errorObject = null;

        const email = this.email || formData.controls.email.value;
        const pass = formData.value.password;

        this.authService.tryLogin(email, pass, "site login", this.server)
            .subscribe((res: any) => {
                if (res.type === 'auth') {
                    return this.success.emit(res);
                } else {
                    // Internal error
                    this.errorObject = {
                        userMessage: $localize`:@@form-field-login-internal-error:If you see this, refresh your browser page and re-enter your information. If the problem persists, <a href="mailto:info@appligogiques.com">please contact us</a>.` + " (2)"
                    };
                }

            }, (err) => {
                this.errorObject = err;
                if (this.childComponent.passwordField) {
                    this.childComponent.passwordField.nativeElement.focus();
                    this.childComponent.passwordField.nativeElement.select();
                }
            });


    }

    /**
     * calling api on press enter of input field
     * @param event 
     */
    onPressEnter(event) {
        if (event.formType === "login-email-step") {
            this.emailCheck(event.formData)
        } else {
            this.signIn(event.formData);
        }
    }

    onLoginSucceed(event: any) {
        setTimeout(() => {
            this.success.emit(event);
        });

    }

    onLoginFailed(event: any) {
        setTimeout(() => {
            this.failure.emit(event);
        });
    }
}
