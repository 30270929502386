import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss', '../common-login-register.scss']
})
export class LoginPageComponent implements OnInit {

    


    constructor(private router: Router,
        private route: ActivatedRoute) { }



    ngOnInit(): void {
    }


    onSuccess(ev: any) {
        let navigateTo: string = this.route.snapshot.queryParamMap.get('navigateTo');

        if(!navigateTo) navigateTo = "";
        else {
            navigateTo = decodeURIComponent(navigateTo);
        }

        // this.router.navigate([navigateTo], { replaceUrl: true })
        this.router.navigateByUrl(navigateTo, { replaceUrl: true })
        .then((val) => {
        });
    }

    onFailure(ev: any) {

    }

}
