import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterType'
})
export class FilterTyperPipe implements PipeTransform {

    transform(objs:any[], type: string|string[]) {
        if(Array.isArray(type)) {
            return objs.filter(a => type.indexOf(a.type) != -1);
        }
        else {
            return objs.filter(a => a.type == type);
        }
    }
}
