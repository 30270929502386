<div  class="dialog-title pop-dialog-warning">
    <mat-icon color="warn">warning</mat-icon>
</div>
<div class="sorry-title">
        Sorry!
</div>

<app-error-message-area [error]="errorObject"></app-error-message-area>

<div class="button-section">
    <button mat-flat-button 
        color="primary"
        (click)="goToBack()">
        Go Back
    </button>
</div>