import { Model, ID, Member } from "@uon/model";
import { User } from "../user/user.model";

export enum AppRemoteDebugSource {
    App = 'app',
    Web = 'web'
}

@Model()
export class AppRemoteDebugSession {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    user: User;

    @Member()
    productCode: string;

    @Member()
    installId: string;

    @Member()
    context: any;

}

@Model()
export class AppRemoteDebugMessage {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    sessionId: string;

    @Member()
    user: User;

    @Member()
    key: string;

    @Member()
    data: any;

    @Member()
    source: AppRemoteDebugSource;
}

@Model()
export class AppRemoteDebugProductSettings {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    productCode: string;

    @Member()
    category: string;

    @Member()
    settings:  {[settingName: string]: object};
}