
import { Model, ID, Member, ArrayMember } from '@uon/model';
import { LogType } from './log-type';

export enum ServerType {
    All = "all",
    ApiServer = "api",
    AuthServer = "auth"
}

@Model()
export class LogConfig {

    @ID()
    id: string;

    @Member()
    serverType: ServerType;

    @Member()
    email: string;

    @Member()
    subject: string;

    @ArrayMember(Object)
    filters: any[];
}



