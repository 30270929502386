import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

export enum ShapeType {
    Circle = 'circle',
    Square = 'square'
}

export enum ShapeScale {
    ExtraSmall = 'extra-small',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge = 'extra-large',
    Custom = 'custom',
}

@Component({
    selector: 'app-shape',
    templateUrl: './shape.component.html',
    styleUrl: './shape.component.scss',
    encapsulation: ViewEncapsulation.None

})
export class ShapeComponent {

    @Input()
    type: ShapeType = ShapeType.Circle;

    @Input()
    scale: ShapeScale = ShapeScale.Small;

    @Input()
    size: string;

    @Input()
    imageUrl: string;

    @Input()
    styles: any = {};

    @Input()
    color: string;
    
    @HostBinding('class') get hostClass() {
        return "common-shape " + this.type + "-shape " + this.scale + "-shape";
    }

    @HostBinding('style.background-image') get hostBackgroundImage() {
        return this.imageUrl ? "url('" + this.imageUrl + "')" : "none";
    }

    @HostBinding('style') get hostStyles() {
        if(this.scale == ShapeScale.Custom) {
            this.styles['width'] = this.size;
            this.styles['min-width'] = this.size;
            this.styles['max-width'] = this.size;
            this.styles['height'] = this.size;
            this.styles['min-height'] = this.size;
            this.styles['max-height'] = this.size;
        }
        if(this.color) {
            this.styles['background-color'] = this.color;
        }
        return this.styles;
    }
}
