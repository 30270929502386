<mat-form-field [hidden]="hidden || search.disabled"
                [appearance]="appearance"
                [floatLabel]="floatLabel"
                [subscriptSizing]="subscriptSizing"
                class="full-width">

    <mat-label>{{label}}</mat-label>

    <input type="text"
           name="address_name"
           [formControl]="control"
           matInput
           [mapAutocomplete]="googleMapsService.autocompleteOptions"
           placeholder=""
           autocomplete="off"
           type="address"
           (placeResult)="onAutocompleteSelected($event)"
           (blur)="setAddressNameField(search.value)"
           required
           #search
           class="address-name-input">
</mat-form-field>

<mat-form-field [hidden]="onlySearch || hidden || !search.disabled"
                [appearance]="appearance"
                [floatLabel]="floatLabel"
                [subscriptSizing]="subscriptSizing"
                class="full-width">

    <mat-label>{{label}}</mat-label>

    <input type="text"
           [formControl]="control"
           matInput
           autocomplete="off"
           type="address"
           (blur)="setAddressNameField(search.value)"
           (input)="onInputChange($event)"
           required>
</mat-form-field>