import { ArrayMember, Member, Model } from "@uon/model";
import { WordListType } from "../wordlist/wordlist-type";
import { WordListSubType } from "../wordlist/wordlist-subtype";
import { WordList } from "../wordlist/wordlist";

@Model()
export class GameProgressListInfo extends WordList {

    constructor() {
        super();
    }
    
    @Member()
    public _id: string;

    @Member()
    public type: WordListType;

    @Member()
    public subtype: WordListSubType;

    @Member()
    public season: number;

    @ArrayMember(String)
    public level: string[];

    @Member()
    gameNo: number;
}
