<app-dialog [icon]="data.icon?.name"
            [iconColor]="data.icon?.color"
            [errorObject]="errorObject">
    <app-dialog-title>{{data.title}}</app-dialog-title>
    <app-dialog-content>
        <p *ngFor="let message of data.messages"
           [innerHTML]="message"></p>
    </app-dialog-content>
    <button *ngFor="let button of getButtons('stroke')"
            [color]="button.color ? button.color : null"
            mat-stroked-button
            (click)="onButtonClick(button)"
            appDialogAction>
        {{button.label}}
    </button>
    <button *ngFor="let button of getButtons('flat')"
            [color]="button.color ? button.color : null"
            mat-flat-button
            (click)="onButtonClick(button)"
            appDialogAction>
        {{button.label}}
    </button>
</app-dialog>