<div class="price-table-container">
    <div *ngIf="showProduct"
         class="product-list">
        <div *ngFor="let product of priceTable?.products; let i = index"
             class="summary-show">
            <app-product-order-card [tableProduct]="product"
                                    [subscriptionType]="subscriptionType"
                                    [subscriptionPlan]="subscriptionPlan"
                                    [currency]="_priceTable?.currency"
                                    layout="summary"></app-product-order-card>
        </div>
    </div>
    <mat-list class="medium-list horizontal full-width">
        <mat-list-item>
            <mat-icon>check</mat-icon>
            <span i18n="@@portal-subscription--benefit1">No Advertising</span>
        </mat-list-item>
        <mat-list-item>
            <mat-icon>check</mat-icon>
            <span i18n="@@portal-subscription--benefit2">Access on all your devices</span>
        </mat-list-item>
        <mat-list-item>
            <mat-icon>check</mat-icon>
            <span i18n="@@portal-subscription--benefit3">Cancel at any time</span>
        </mat-list-item>
    </mat-list>
    <!-- <h5 i18n="@@price-table-total-section-label">Total with applicable tax (in {{priceTable?.currency | apCurrencyCodeSymbol}})</h5> -->
    <div class="final-total">
        <div *ngIf="(priceTable.discounts?.length > 0) || (priceTable.taxes.length > 0)"
             class="show-total">
            <div class="heading"
                 i18n="@@price-table-subtotal-label">
                Subtotal
            </div>
            <div class="show-amount">
                {{ priceTable?.subtotal | apCurrency:priceTable?.currency}}
            </div>
        </div>
        <div class="show-total"
             *ngFor="let discount of priceTable.discounts">
            <div class="heading"
                 i18n="@@price-table-discount-label">
                PromoCode Discount ({{discount.rate}}%)
            </div>
            <div class="show-amount">
                - {{ discount.amount | apCurrency:priceTable?.currency }}
            </div>
        </div>
        <div class="show-total"
             *ngFor="let tax of priceTable.taxes ">
            <div class="heading">
                <span [title]="getTaxTitle(tax)">{{ tax.code?.code | apLocalize:tax.name }}</span> ({{ tax.rate * 100 | number:taxSymbol}}%) {{tax.no}}
            </div>
            <div class="show-amount">
                {{ tax.amount | apCurrency:priceTable?.currency }}
            </div>
        </div>
        <div class="show-total">
            <div class="heading"
                 i18n="@@price-table-total-label">
                Total
            </div>
            <div class="show-amount">
                {{ priceTable?.total | apCurrency:priceTable?.currency:{narrowSymbol: false} }}
            </div>
        </div>
    </div>
</div>