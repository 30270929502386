
import { ID, Member, Model } from "@uon/model";
import { User } from "../user/user.model";
import { AppReceipt } from "./app-receipt.model";


@Model()
export class AppReceiptHistory extends AppReceipt {
    constructor() {
        super();
    }
}
