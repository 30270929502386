<div class="language-toolbar-area">
    <app-toolbar-language [whiteStyle]="false"></app-toolbar-language>
</div>

<app-divided-page>
    <div left-pane>
    </div>
    <div class="container auth-page right-pane-container"
         right-pane>
        <div class="login-form">


            <app-login-form [multiStep]="false"
                            (success)="onSuccess($event)"
                            (failure)="onFailure($event)">


            </app-login-form>



        </div>
    </div>
</app-divided-page>