import { Pipe, PipeTransform } from '@angular/core';
import { ProductService } from '../services/product.service';

@Pipe({
    name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

    constructor(private productService: ProductService) { }

    transform(productCode: string, shortName?: boolean): string {

        const product = this.productService.getProductSync(productCode);

        if(product) {
            if(shortName) {
                return product.currentLocalizedShortName;
            }
            else {
                return product.currentLocalizedName;
            }
        }
        else {
            console.error("Products list not loaded!");
        }

        return "";
    }

}
