import { WordListSubType, WordListType } from "@applogic/model"
import { Chip } from "../base-game-progress/base-game-progress.component"

export class MMOChip extends Chip {
    

}

export interface MMOActivityProgressRow {
    studentId: string,
    cols: MMOActivityProgressCol[]
}

export interface MMOActivityProgressCol {
    success: number,
    listId: string,
    gameNo: number,
    levelNo: number,
    type: WordListType,
    subtype: WordListSubType
}

export interface MMODatesProgressRow {
    studentId: string,
    cols: MMODatesProgressCol[]
}

export interface MMODatesProgressCol {
    success: number,
    dates: string[],
    listId: string,
    gameNo: number,
    levelNo: number,
    type: WordListType,
    subtype: WordListSubType
}

export interface MMODatesTableRow {
    student_name: string,
    student_id: string,
    // dates: {[date: string]: {[gameNo: string]: MMOChip[]} [] }
    dates: {[date: string]: {gameNo: number, chips: MMOChip[]} [] }
}

export interface MMOActivityTableRow {
    student_name: string,
    student_id: string,
    games: {[gameNo: string]: MMOChip[] }
}