import { Component, ContentChildren, ElementRef, HostBinding, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { HintContentComponent } from './hint-content/hint-content.component';
import { TemplateOutletDirective } from 'src/app/core/directives/template-outlet.directive';

@Component({
    selector: 'app-hints-viewer',
    templateUrl: './hints-viewer.component.html',
    styleUrl: './hints-viewer.component.scss'
})
export class HintsViewerComponent {

    @ContentChildren(HintContentComponent) hintComponents: QueryList<HintContentComponent>;

    @ViewChild(TemplateOutletDirective, { static: true }) outlet: TemplateOutletDirective;

    currentHintIndex = 0;

    @HostBinding('class') get getHostClass() {
        return "hint-section app-container full-height flex-column";
    }

    ngAfterContentInit() {
        this.loadHint(this.currentHintIndex);
    }

    loadHint(index: number) {
        this.outlet.viewContainerRef.clear();
        const hintComponent = this.hintComponents.toArray()[index];
        this.outlet.viewContainerRef.createEmbeddedView(hintComponent.hintTemplate);
    }

    nextHint() {
        this.currentHintIndex = (this.currentHintIndex + 1) % this.hintComponents.length;
        this.loadHint(this.currentHintIndex);
    }

    previousHint() {
        this.currentHintIndex = (this.currentHintIndex - 1 + this.hintComponents.length) % this.hintComponents.length;
        this.loadHint(this.currentHintIndex);
    }
}
