/**
 * Structure used to query game data progression for the progress table of the website.
 */
 export interface GameProgressionQuery {

    // To define the data grouping. To be used instead of the "rows" and "cols" properties.
    groups: ProgressGroup[];

    // The gameId. Ex.: "mmo", "msm"
    gameId: string;

    // To define the returned structure. (default value is "table")
    //  "cells" to get a list of cells
    //  "table" to get a table with rows and columns.
    format?: OutputStructure;
    
    // List to filter the progression.
    //  @param {string|Array.<string>} keys.listId     (Optional) To filter the progression by specific list(s).
    //  @param {string|Array.<string>} keys.studentId  (Optional) To filter the progression by specific student(s).
    //  @param {string|Array.<string>} keys.language   (Optional) To filter the progression by specific list language(s).
    //  @param {string|Array.<string>} keys.type To filter the type of list.
    //                                 (For Words Without Worries. Possibles values: "easy", "hard")
    //  @param {string|Array.<string>} keys.subtype (Optional) To filter the progression by specific list sub type.
    //  @param {number|Array.<number>} keys.levelNo    (Optional) To filter the progression by specific list level(s).
    //                                 (For Madam Word. Possibles values: 1, 2, 3)
    keys: any;

    // Date string from which we are interested to get the progression. The standard UTC date format: YYYY-MM-DDTHH:mm:ss.sssZ
    from?: string;

    // Date string to which we are interested to get the progression. The standard UTC date format: YYYY-MM-DDTHH:mm:ss.sssZ
    to?: string;

    // The timezone of the user. The timezone is only required for grouping data with dates. It it not used with the properties "from" and "to".
    timezone?: string;

    // What is the data that we want to retrieve in each cells.
    expected: ExpectedField[];
}

export interface ProgressGroup {
    // The name of this group.
    label: string;

    // Keys use to group values.
    keys: string[];
}

export enum OutputStructure {
    Table = "table",
    Cells = "cells"
}

export enum ExpectedField {
    Success = "success", // Counter for success
    Games = "games", // List of games that have been won.
    Dates = "dates", // Success dates.
    exists = "exists" // No data, the cells exists if there is at least one success.
}