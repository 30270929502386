<mat-table style="display: none">
    <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
</mat-table>
<table class="mat-table allocation-list-table" *ngIf="(subAlloc.allocations | allocationCount:['userId']) > 0">
    <tr class="mat-header-row">
        <th class="mat-header-cell"></th>
        <th class="mat-header-cell set-min-width-allocation-table" *ngFor="let productCode of productsCode">
            <app-seat-token [displayType]="'icon-only'" [productCode]="productCode"></app-seat-token>
        </th>
        <th class="mat-header-cell set-min-width-allocation-table"></th>
    </tr>
    <tr class="mat-row" *ngFor="let alloc of subAlloc.allocations | allocationFilter:['userId']">
        <td class="mat-cell">
            <div class="allocation-info-line">
                <div class="avatar-user">
                    <div class="avatar">
                        <app-user-avatar [user]="getUser(alloc.filter.userId)">
                        </app-user-avatar>
                    </div>
                    <p>{{ getUserName(alloc.filter.userId) }}</p>
                </div>
                <div class="allocation-info-desc">
                    <div class="allocation-info-desc-line" *ngIf="getTotalUsedSeats(alloc) > 0"
                        [innerHtml]="getUserDesc(alloc)"></div>
                </div>
            </div>
        </td>
        <td class="mat-cell product-count" *ngFor="let productCode of productsCode">{{ getTotalFreeSeats(alloc,
            productCode)}}</td>
        <td class="mat-cell">
            <button *ngIf="contextMenu" mat-icon-button [matMenuTriggerFor]="contextMenu"
                [matMenuTriggerData]="{member: {subAlloc: subAlloc, alloc: alloc}}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </td>
    </tr>
</table>

<!--
<table mat-table [dataSource]="allocationsList" matSort class="mat-elevation-z8 allocation-list-table" matSortActive="user"
    matSortDisableClear matSortDirection="asc" *ngIf="subAlloc && (subAlloc.allocations?.length > 0) && (allocationsList.filteredData.length > 0)">

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
            <div class="allocation-info-line">
                <div class="avatar">
                    <app-user-avatar [user]="getUser(element.filter.userId)">
                    </app-user-avatar>
                </div>
                <p>{{ getUserName(element.filter.userId) }}</p>
                <div class="allocation-info-desc" >
                    <div class="allocation-info-desc-line" *ngIf="getTotalUsedSeats(element) > 0" [innerHtml]="getUserDesc(element)"></div>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container *ngFor="let productCode of productsCode" [matColumnDef]="'product_' + productCode.toLowerCase()">
        <th mat-header-cell *matHeaderCellDef class="set-min-width-allocation-table"><app-seat-token [displayType]="'icon-only'" [productCode]="productCode"></app-seat-token></th>
        <td mat-cell *matCellDef="let element" class="product-count">
            {{ getTotalFreeSeats(element, productCode)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="products">
        <th mat-header-cell *matHeaderCellDef class="set-min-width-allocation-table"></th>
        <td mat-cell *matCellDef="let element">
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="set-min-width-allocation-table"></th>
        <td mat-cell *matCellDef="let element">
            <button *ngIf="contextMenu" mat-icon-button [matMenuTriggerFor]="contextMenu"
            [matMenuTriggerData]="{member: {subAlloc: subAlloc, alloc: element}}">
            <mat-icon>more_vert</mat-icon>
            </button>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayCol"></tr>
    <tr mat-row *matRowDef="let row; columns: displayCol;"></tr>
</table>
-->

<!--
<mat-paginator #studentCardsPaginator="matPaginator" [pageIndex]="0" [length]="allocationsList.data.length" [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 100]" *ngIf="allocationsList.data.length">
</mat-paginator>
-->