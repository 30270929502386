import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Organization, OrganizationRole, User } from '@applogic/model';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';


export interface UsersSelectionDialogData {
    org: Organization;
    role?: OrganizationRole;
    singleUserSelect: boolean;
    message?: string;
}
@Component({
    selector: 'app-users-selection-dialog',
    templateUrl: './users-selection-dialog.component.html',
    styleUrls: ['./users-selection-dialog.component.scss']
})
export class UsersSelectionDialogComponent implements OnInit {

    selectedUser: User;

    constructor(@Inject(MAT_DIALOG_DATA) public data: UsersSelectionDialogData,
        private mdDialogRef: MatDialogRef<UsersSelectionDialogComponent>) {
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: UsersSelectionDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<UsersSelectionDialogComponent, UsersSelectionDialogData, User>(dialogService, dialog, UsersSelectionDialogComponent, {
            data,
            maxWidth: "90%",
            minWidth: "40%",
            panelClass: 'makeItMiddle'
        }, config);
    }

    onSelect() {
        this.mdDialogRef.close(this.selectedUser);
    }

    onUserChange(user: User) {
        this.selectedUser = user;
    }
}
