import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-pop-up-message-dialog',
  templateUrl: './error-pop-up-message-dialog.component.html',
  styleUrls: ['./error-pop-up-message-dialog.component.scss']
})
export class ErrorPopUpMessageDialogComponent implements OnInit {
    
    userMessage: string = "";
    errorObject: any;
    navigateRoot: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data:any,
            public dialogRef: MatDialogRef<ErrorPopUpMessageDialogComponent>,
            private router:Router) {

            if(data.errorObject && !data.errorObject.userMessage && data.userMessage)
            {
                data.errorObject.userMessage = data.userMessage;
            }
            else if(!data.errorObject && data.userMessage)
            {
                data.errorObject = {userMessage: data.userMessage};
            }
    
            this.userMessage = data.userMessage;
            this.errorObject = data.errorObject;

            if(!this.errorObject) {
                console.error("[ErrorPopUpMessageDialogComponent] Missing error object");
            }

            this.navigateRoot = data.navigateRoot;
    }

    ngOnInit(): void {
    }

    /**
     * go back to dashboard
     */
    goToBack(){
        if(this.navigateRoot)
        {
            this.router.navigate(["/"]);
        }
        this.dialogRef.close();
    }
}
