import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, SubscriptionAllocation } from '@applogic/model';
import { UsersSelectionComponent } from 'src/app/organization-ui/users-selection/users-selection.component';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { FormService } from 'src/app/services/form.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { ProductService } from 'src/app/services/product.service';
import { SeatService } from 'src/app/services/seat.service';

interface AddSeatsAllocationDialogData {
    orgId: string,
    subscription: Subscription
}

@Component({
    selector: 'app-add-seats-allocation-dialog',
    templateUrl: './add-seats-allocation-dialog.component.html',
    styleUrls: ['./add-seats-allocation-dialog.component.scss']
})
export class AddSeatsAllocationDialogComponent implements OnInit {

    orgId: string;
    subscription: Subscription;

    errorObject: any;

    @ViewChild(UsersSelectionComponent, { static: false })
    usersSelectionComponent: UsersSelectionComponent;
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: AddSeatsAllocationDialogData,
        private mdDialogRef: MatDialogRef<AddSeatsAllocationDialogComponent, SubscriptionAllocation>,
        private seatService: SeatService,
        public formService: FormService,
        private cdr: ChangeDetectorRef,
        private languageService: LanguageService,
        public productService: ProductService,
        readonly loading: LoadingService) { }

    ngOnInit(): void {
        this.orgId = this.data.orgId;
        this.subscription = this.data.subscription;
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: AddSeatsAllocationDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<AddSeatsAllocationDialogComponent, AddSeatsAllocationDialogData, SubscriptionAllocation>(dialogService, dialog, AddSeatsAllocationDialogComponent, {
            data,
            width: "80%",
            maxWidth: "80%",
            minWidth: "80%",
            // height:"calc(100% - 43px);",
            height: "95%",
            position: {
                top: '1%',
                left: '10%'
            },
            panelClass: 'makeItMiddle'
        }, config);
    }

    public addSeatsAllocation()
    {
        this.seatService.addAllocation(this.subscription.id, this.usersSelectionComponent.collaboratorAdd[0].id).subscribe((allocation) => {
            this.mdDialogRef.close(allocation);
        }, (err) => {
            this.errorObject = err;
        });
    }

}
