
import { Injectable, NgZone } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpContextToken
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, timer, of } from 'rxjs';
import { map, catchError, delay, finalize, debounce, debounceTime, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingProcess } from './loading-process';

export const IGNORE_LOADING_CONTEXT = new HttpContextToken<boolean>(() => false);

/**
 * Service that provide the access of the loading state.
 */
@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    readonly loadingChange: Observable<boolean> = new BehaviorSubject<boolean>(false);

    isLoading: boolean = false;

    private loadingSubject = new BehaviorSubject<boolean>(true);

    private loadingsMap: Map<string, number> = new Map<string, number>();

    private allowMoreStops: Set<string> = new Set<string>();

    private verboseLog: boolean = true;

    constructor(private ngZone: NgZone) {
        this.loadingChange = this.loadingSubject.pipe(
            shareReplay(1),
            debounce(updating => updating ? of({}) : timer(500))
        );

        this.loadingChange.subscribe((v) => {
            this.isLoading = v;
        });

        this.allowMoreStops.add("http");

        this.loadingSubject.next(false);
    }

    /**
     * Start a loading.
     * 
     * @param {string} name (Optional) The loading name is used to avoid state conflict when there is multiple loading types.
     * @param {string} label (Optional) An extra text to display.
    */
    public startLoading(name?: string, label?: string) {
        if (!name) name = "default";
        if (!label) label = "";

        if(this.verboseLog) {
            console.log("startLoading(" + name + "," + label + ")");
        }

        let count: number = 1;

        if (this.loadingsMap.has(name)) {
            count += this.loadingsMap.get(name);
            
        }

        this.loadingsMap.set(name, count);

        this.updateLoading();

        return new LoadingProcess(name, this);
    }

    /**
     * Stop a loading.
     * 
     * @param {string} name (Optional) The loading name is used to avoid state conflict when there is multiple loading types.
     * @param {string} label (Optional) An extra text to display. 
    */
    public stopLoading(name?: string, label?: string) {
        if (!name) name = "default";
        if (!label) label = "";

        if(this.verboseLog) console.log("stopLoading(" + name + "," + label + ")");

        let count: number = -1;

        if (this.loadingsMap.has(name)) {
            count += this.loadingsMap.get(name);
        }

        if (count < 0) {
            if (!environment.production && this.verboseLog && !this.allowMoreStops.has(name)) {
                console.error("There was more stopLoading than startLoading for the loading named '" + name + "'");
            }
            count = 0;
        }

        if(count == 0) {
            this.loadingsMap.delete(name);
        }
        else {
            this.loadingsMap.set(name, count);
        }

        this.updateLoading();
    }

    public setLoading(name: string, state: boolean, label?: string) {
        if (state) {
            this.startLoading(name, label);
        }
        else {
            this.stopLoading(name, label);
        }
    }

    public getLoading(name: string): LoadingInstance {
        return new LoadingInstance(this, name);
    }

    private updateLoading() {
        let newState: boolean = false;

        if(this.verboseLog) {
            let debug: string = "";

            for (let key of this.loadingsMap.keys()) {
                let val: number = this.loadingsMap.get(key);
                debug += " " + key + ": " + val;
            }

            debug += " inZone: " + NgZone.isInAngularZone();

            console.log("Update Loading: " + debug);
        }

        for (let key of this.loadingsMap.keys()) {
            let val: number = this.loadingsMap.get(key);
            if(val > 0) {
                newState = true;
                break;
            }
        }

        if (newState != this.loadingSubject.value) {
            this.ngZone.run(() => this.loadingSubject.next(newState));
        }
    }
}

/**
 * To load and unload the same thing without repeating startLoading(name) and stopLoading(name).
 */
export class LoadingInstance {

    constructor(private service: LoadingService, private name: string) {
        
    }

    startLoading() {
        this.service.startLoading(this.name);
    }

    stopLoading() {
        this.service.stopLoading(this.name);
    }
}

/**
 * Class that intercept the request/response to set the loading state.
 * 
 * When a request is intercept the loading state become active.
 * When a response is intercept the loading state become disable.
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {


    constructor(private service: LoadingService) {

    }

    filterRequest(request: HttpRequest<any>) {
        if(!request.context) {
            return true;
        }

        if(request.context.get(IGNORE_LOADING_CONTEXT)) {
            return false;
        }

        return true;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            delay(0),
            map((event: HttpEvent<any>) => {
                // If the event is not an HttpResponse it means that a request was started.
                // The "loadingChange" become true until the HttpResponse is received.
                if( !(event instanceof HttpResponse)) {
                    if(this.filterRequest(request)) {
                        this.service.startLoading("http", "(" + request.method + ") " +  request.url);
                    }
                }
                return event;
            }),
            catchError((err: any) => {
                return throwError(err);
            }),
            finalize(() => {
                // request completes, errors, or is cancelled
                if(this.filterRequest(request)) {
                    this.service.stopLoading("http", "(" + request.method + ") " +  request.url);
                }
            })
        );

    }

}