import { Model, Member, ID } from "@uon/model";

/**
 * Model use to generate unique numbers.
 */
@Model()
export class ModelNo {
    @ID()
    id: string;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    prefix: string;

    @Member()
    no: number; // The next available number.

}