<app-page [title]="classroom.name">
    <app-page-header2-card class="classroom-page-header"
                           bgImageUrl="https://lh3.googleusercontent.com/FlzIBMEVPo7rwUyiQE5TZuVOjo2EVPl0s0IlNPc4ntQDf6oGj4w9FMN35nDV7CLZJjj3i3jJGMUW92fuMP2MPHWiY_WcEImw3ThABZEtyhXTOMCl4P4=w2400-rj"
                           titleType="dynamic">
        <design-card-title>
            <span i18n="@@common-about">
                About
            </span>
            &nbsp;
            <span>{{ classroom.name }}</span>
        </design-card-title>
        <design-card-subtitle>
            <span *ngIf="classroom.organization"
                  i18n-tooltip="@@common-organization"
                  tooltip="Organization">

                <a class="go-to-org-link"
                   (click)="ApplogicUtils.navigateToOrganization(router, classroom.organization)">
                    {{ classroom.organization.name }}
                </a>
            </span>
            <span i18n-tooltip="@@common-created-on"
                  tooltip="Created On">&nbsp;- {{ classroom.createdOn | date }}</span>
        </design-card-subtitle>
    </app-page-header2-card>
    <app-page-content>
        <mat-tab-group mat-stretch-tabs="false"
                       mat-align-tabs="start"
                       class="responsive app-mat-tab-group">
            <mat-tab i18n-label="@@common-informations"
                     label="Informations">

                <div class="flex-row-responsive flex-default-gap">
                    <app-classroom-role-card [role]="classroom._currentUserRole"></app-classroom-role-card>

                    <app-design-card class="stats-card"
                                     [clickable]="false">
                        <design-card-content>
                            <app-classroom-code-panel [classroom]="classroom"
                                                      layout="card-content"></app-classroom-code-panel>
                        </design-card-content>
                    </app-design-card>

                    <app-design-card class="stats-card delete-classroom-card"
                                     [clickable]="false">
                        <design-card-content class="flex-column flex-default-gap">
                            <span typeScale="title-large" i18n="@@classroom-page--deleting-information">Deleting information</span>
                            <app-shape type="circle"
                                       scale="large">
                                <mat-icon>delete</mat-icon>
                            </app-shape>
                            <button mat-flat-button
                                    color="accent"
                                    (click)="showDeleteClassroomDialog()"
                                    i18n="@@classroom-page--delete-classroom"
                                    *ngIf="classroom.students.length">
                                Delete classroom
                            </button>
                        </design-card-content>
                    </app-design-card>
                </div>
            </mat-tab>
            <mat-tab i18n-label="@@common-progress-title"
                     label=" Progress "
                     bodyClass="flex-column flex-default-gap">

                <div class="tab-header">
                    <app-checklist-popover checklistItem="followProgress">
                        <div actions>
                            <h2 i18n="@@common-progress-title">
                                Progress
                            </h2>
                        </div>
                    </app-checklist-popover>

                    <!-- <app-help-icon [template]="ClassRoomProgress"
                                   placement="left"></app-help-icon> -->

                    <span class="spacer">
                    </span>
                </div>

                <ng-template [ngTemplateOutlet]="progressTemplate"></ng-template>

            </mat-tab>
            <mat-tab i18n-label="@@classroom-homework-card-title"
                     label=" Homeworks ">

                <div class="tab-header">
                    <h2>
                        <span i18n="@@classroom-homework-card-title">
                            Homeworks
                        </span>
                    </h2>

                    <app-list-count-indicator [count]="homeworkList.count"></app-list-count-indicator>

                    <!-- <app-help-icon [template]="ClassRoomProgress"
                                   placement="left"></app-help-icon> -->

                    <span class="spacer">
                    </span>
                </div>

                <app-common-list-toolbar [parent]="collaboratorList"
                                         [alignLeft]="true">
                    <button mat-stroked-button
                            color="accent"
                            class="mr-35"
                            (click)="homeworkList.showAddHomeworkDialog()"
                            i18n="@@classroom-homework-add-homework"
                            common-list-toolbar-option>
                        Add homework
                    </button>
                </app-common-list-toolbar>

                <app-homework-list [classroom]="classroom"
                                   #homeworkList></app-homework-list>
            </mat-tab>
            <mat-tab i18n-label="@@common-students"
                     label=" Students ">

                <ng-template [ngTemplateOutlet]="studentsTemplate"></ng-template>

            </mat-tab>
            <mat-tab i18n-label="@@common-collaboration"
                     label=" Collaboration ">
                <div class="tab-header">
                    <h2 i18n="@@common-collaboration">
                        Collaboration
                    </h2>
                    <app-list-count-indicator [count]="collaboratorList.count"></app-list-count-indicator>
                    <!-- <app-help-icon [template]="ClassRoomCollaboration"
                                   placement="left"></app-help-icon> -->
                    <span class="spacer"></span>
                </div>

                <app-common-list-toolbar [parent]="collaboratorList"
                                         [alignLeft]="true">
                    <button mat-stroked-button
                            color="accent"
                            (click)="invitedCollaboratorList.openCollaboratorDialog()"
                            i18n="@@common-invite-collaborator"
                            class="mr-35"
                            common-list-toolbar-option>
                        Invite a collaborator
                    </button>
                </app-common-list-toolbar>

                <app-collaborator-list [classroom]="classroom"
                                       #collaboratorList></app-collaborator-list>

                <app-collaborator-list [classroom]="classroom"
                                       [invited]="true"
                                       #invitedCollaboratorList></app-collaborator-list>
            </mat-tab>
            <mat-tab i18n-label="@@user-seats-allocation-card-help-title"
                     label=" My Tokens "
                     [labelClass]="!isUsersSeatAllocationVisible ? 'hidden-field' : ''">
                <div class="tab-header">
                    <h2>
                        <span i18n="@@user-seats-allocation-card-help-title">
                            My Tokens
                        </span>
                    </h2>

                    <app-list-count-indicator [count]="usersSeatAllocation?.totalTokens"></app-list-count-indicator>

                    <span class="spacer">
                    </span>
                </div>

                <app-user-seats-allocation-panel *ngIf="isOwner || isAdmin"
                                                 [userId]="currentUserId"
                                                 [orgId]="classroom.organization.id"
                                                 [hidden]="!isUsersSeatAllocationVisible"
                                                 [(isVisible)]="isUsersSeatAllocationVisible"></app-user-seats-allocation-panel>
            </mat-tab>
            <app-checklist-tab *ngIf="trialChecklist"
                               [(checklist)]="trialChecklist"
                               [elementId]="classroom?.id"
                               i18n-label="@@onboarding-checklist-title"
                               label=" todo "
                               ngProjectAs="mat-tab"></app-checklist-tab>

            <!-- Onboarding Help -->
            <app-onboarding-help-tab style="width: 100%"
                                     [labelClass]="!isOnboardingVisible ? 'hidden-field' : ''"
                                     i18n-label="@@onboarding-section-help-title"
                                     label=" Onboarding "
                                     [hidden]="!isOnboardingVisible"
                                     ngProjectAs="app-card"
                                     [(isVisible)]="isOnboardingVisible"
                                     ngProjectAs="mat-tab"></app-onboarding-help-tab>
        </mat-tab-group>

    </app-page-content>
</app-page>


<ng-template matMenuContent
             #studentActionsMenu
             let-row>

    <button mat-menu-item
            (click)="showEditStudentDialog(row)">

        <mat-icon>
            edit
        </mat-icon>

        <span i18n="@@common-edit">Edit</span>

    </button>

    <button mat-menu-item
            (click)="removeStudent(row)"
            *ngIf="canAddRemoveStudent">

        <mat-icon color="warn">delete</mat-icon>

        <span i18n="@@common-remove-student">Remove student from classroom</span>

    </button>

    <button mat-menu-item
            (click)="assignTokens(row)"
            *ngIf="isUsersSeatAllocationVisible">

        <mat-icon>assignment</mat-icon>

        <span i18n="@@classroom-assign-tokens-to-student">Assign tokens to this student</span>

    </button>

    <button mat-menu-item
            (click)="showAssignHomeworksDialog(row)">

        <mat-icon svgIcon="homework"></mat-icon>

        <span i18n="@@classroom-assign-homeworks-to-student">Assign homeworks</span>

    </button>

</ng-template>



<!--
STUDENT DELETED SNACKBAR
-->
<ng-template #studentDeletedSnackbar
             let-data>
    <span i18n="@@classroom-student-delete-snackbar-message">
        {{ data.fullName }} successfully deleted.
    </span>

</ng-template>
<!--
ALL STUDENTS DELETED SNACKBAR
-->
<ng-template #studentAllDeletedSnackbar
             let-data>
    <span i18n="@@classroom-student-selected-delete-snackbar-message">
        Selected students successfully deleted.
    </span>
</ng-template>



<!--
ToolTip Info
-->
<ng-template #AboutClassRoom>
    <span i18n="@@classroom-manage-info-tooltip-text">
        Add collaborators or students to the classroom, and follow their progress.
    </span>
</ng-template>
<!--
ToolTip Collaborator
-->
<ng-template #ClassRoomCollaboration>
    <span i18n="@@classroom-manage-collaborate-tooltip-text">
        Click the Invite Collaborator button to invite a member. Collaborators must be members of
        the organization this classroom is part of. A collaborator will have access to the same
        classroom management functionalities as you.
    </span>
</ng-template>
<!--
ToolTip Student
-->
<ng-template #ClassRoomStudent>
    <span i18n="@@classroom-manage-student-tooltip-text">
        To add one or more students to the classroom, click the Add students button. To
        modify a student’s information (full name, profile name, password) or remove this
        student from the classroom, click on the three dots to the right of this
        student's box and select the action of your choice.
    </span>
</ng-template>
<!--
ToolTip Progress
-->
<ng-template #ClassRoomProgress>
    <span i18n="@@classroom-manage-progress-tooltip-text">
        Click on the product button to view the table of activities completed by
        your students for a given application. You will have the option of exporting or
        printing this data.
    </span>
</ng-template>

<!--
ToolTip Info
-->
<ng-template #ClassRoomCode>
    <span i18n="@@classroom-manage-classroom-code-tooltip-text">
        The classroom code allow students to connect to the classroom.
    </span>
</ng-template>


<ng-template #progressTemplate>
    <div *ngIf="classroomProgressList.length">
        <mat-accordion class="progressList">
            <mat-expansion-panel class="list-item"
                                 (closed)="panelOpenState = true"
                                 *ngFor="let p of classroomProgressList"
                                 (opened)="openGroup(p.productShortCode)">
                <mat-expansion-panel-header class="grants-block">

                    <mat-panel-title>
                        <div class="titile-block"
                             [attr.id]="'game-' + p.productShortCode">
                            <img [attr.src]="productService.getGameIcon(p.productShortCode)">
                            <span>
                                {{ productService.getGameName(p.productShortCode) }}
                            </span>
                        </div>

                        <span class="spacer"></span>

                        <div class="title-right-align">
                            <app-checklist-popover [key]="'tryProducts' + p.productShortCode + '-popover'"
                                                   [checklistItem]="'tryProducts' + p.productShortCode">
                                <div actions>
                                    <button mat-stroked-button
                                            color="accent"
                                            (click)="playButtonClick(products[p.productShortCode], $event)"
                                            i18n="@@common-play-button"
                                            class="mr-35">
                                        PLAY
                                    </button>
                                </div>
                            </app-checklist-popover>
                        </div>

                    </mat-panel-title>

                </mat-expansion-panel-header>
                <div class="progress-details"
                     *ngIf="classroom.students.length > 0 ">
                    <div class="top-button">

                        <div class="left-side">

                            <div class="game-language-button">

                                <p i18n="@@game-progress-label">
                                    Show progress in
                                </p>
                                <mat-form-field appearance="outline"
                                                class="select-language-form">

                                    <mat-label i18n="@@common-language">
                                        Language
                                    </mat-label>

                                    <mat-select [value]="currentLanguage"
                                                (selectionChange)="onUserLanguageChange($event)">

                                        <mat-option value="fr">Français</mat-option>
                                        <mat-option value="en">English</mat-option>
                                        <mat-option value="es">Español</mat-option>

                                    </mat-select>

                                </mat-form-field>
                            </div>
                        </div>
                        <div class="present-by-button">

                            <p i18n="@@game-progress-Present-data-label">
                                Present data by
                            </p>
                            <mat-button-toggle-group name="fontStyle"
                                                     aria-label="Font Style"
                                                     class="toggle-buttons">
                                <mat-button-toggle value="Dates"
                                                   i18n="@@game-progress-toggle-button-date"
                                                   (change)="dateToggleIsSelected = true"
                                                   [checked]="dateToggleIsSelected == true">
                                    Dates
                                </mat-button-toggle>

                                <mat-button-toggle value="Activities"
                                                   [checked]="dateToggleIsSelected == false"
                                                   i18n="@@game-progress-toggle-button-activities"
                                                   (change)="dateToggleIsSelected = false">
                                    Activities
                                </mat-button-toggle>

                            </mat-button-toggle-group>

                        </div>
                        <div class="center-button">
                            <button mat-stroked-button
                                    color="accent"
                                    (click)="refreshTable()">
                                <span i18n="@@game-progress-refresh-button">Refresh</span>
                            </button>
                        </div>
                        <!-- <div class="right-side">
                                                            <button mat-stroked-button color="accent">
                                                                <span>Export Data</span>
                                                            </button>
                                                        </div> -->
                    </div>

                    <div class="progress-details-data"
                         *ngIf="p.productShortCode  == 'MMO'">
                        <app-mmo-game-progress [classroomId]="classroom?.id"
                                               [currentLanguageType]="currentLanguage"
                                               [currentGame]="currentAppGameCode"
                                               [isSelectedDate]="dateToggleIsSelected"
                                               [classStudent]="classroom.students">
                        </app-mmo-game-progress>
                    </div>
                    <div class="progress-details-data"
                         *ngIf="p.productShortCode  == 'MSM'">
                        <app-msm-game-progress [classroomId]="classroom?.id"
                                               [currentLanguageType]="currentLanguage"
                                               [currentGame]="currentAppGameCode"
                                               [isSelectedDate]="dateToggleIsSelected"
                                               [classStudent]="classroom.students">
                        </app-msm-game-progress>
                    </div>

                </div>
                <div *ngIf="classroom.students.length === 0"
                     class="empty-data-found">
                    <em class="secondary-text"
                        i18n="@@progress-data-student-data-empty-message">No student
                        present in this classroom</em>
                </div>

            </mat-expansion-panel>

        </mat-accordion>

    </div>
</ng-template>

<ng-template #studentsTemplate>
    <div class="tab-header">
        <h2 i18n="@@common-students">
            Students
        </h2>
        <app-list-count-indicator [count]="studentsList.count"></app-list-count-indicator>
        <!-- <app-help-icon [template]="ClassRoomStudent"
                       placement="left"></app-help-icon> -->
        <span class="spacer"></span>
    </div>


    <app-common-list-toolbar [parent]="studentsList"
                             [alignLeft]="true">
        <div [ngClass]="{'hidden-field': selectedStudentCount > 0}"
             class="flex-row flex-default-gap"
             common-list-toolbar-option>

            <app-checklist-popover checklistItem="inviteStudent"
                                   placement="auto">
                <div actions>
                    <button mat-stroked-button
                            color="accent"
                            (click)="showAddStudentDialog()"
                            i18n="@@common-add-students"
                            *ngIf="canAddRemoveStudent">
                        Add students
                    </button>
                </div>
            </app-checklist-popover>

            <button mat-stroked-button
                    color="accent"
                    class="subheader-button"
                    (click)="showAssignTokensDialog()"
                    i18n="@@common-assign-tokens"
                    *ngIf="isUsersSeatAllocationVisible && (classroom.students.length > 0)">
                Assign tokens
            </button>

            <button mat-stroked-button
                    color="accent"
                    class="subheader-button"
                    (click)="exportStudentsList()"
                    i18n="@@classroom-export-studentslist"
                    *ngIf="classroom.students.length">
                Export students list
            </button>
        </div>

        <div [ngClass]="{'hidden-field': selectedStudentCount == 0}"
             common-list-toolbar-option>
            <div class="selected-students-actions-section">

                <button (click)="onClearSelection()"
                        class="close-button "
                        mat-icon-button>
                    <mat-icon>
                        clear
                    </mat-icon>
                </button>

                <small class="secondary-text"
                       i18n="@@classroom-selected-student-count">{ selectedStudentCount, plural, =0 {No selected students} =1 {1 selected student} other { {{selectedStudentCount}} selected students } }</small>

                <div>
                    <button mat-flat-button
                            color="accent"
                            class="mr-35 subheader-button"
                            i18n="@@common-remove"
                            (click)="openRemoveAllDailog()"
                            *ngIf="canAddRemoveStudent && classroom.students.length"
                            [disabled]="!studentList?.selection?.selected?.length">Remove</button>
                </div>

                <div>
                    <button mat-flat-button
                            color="accent"
                            class="mr-35 subheader-button"
                            (click)="transferSelectedStudents()"
                            [disabled]="((studentList?.selection?.selected?.length || 0) == 0) || loading.isLoading || (loading.loadingChange | async)"
                            i18n="@@classroom-page--transfer-students-to-another-classroom"
                            *ngIf="canAddRemoveStudent && classroom.students.length">Transfer to another classroom</button>
                </div>
            </div>
        </div>
    </app-common-list-toolbar>
    <app-student-list [students]="students"
                      [actionTemplate]="studentActionsMenu"
                      #studentsList>
    </app-student-list>
</ng-template>