import { Component, HostBinding } from '@angular/core';
import { CommonListCommonDisplayModeComponent } from '../common-list-common-display-mode/common-list-common-display-mode.component';
import { Subscription } from 'rxjs';
import { CommonListComponent } from '../../common-list.component';
import { CommonListRendererComponent } from '../../common-list-renderer/common-list-renderer.component';

export interface CardRowData<T> {
    row: T,
    list: CommonListComponent<T>,
    renderer: CommonListRendererComponent
}

@Component({
    selector: 'app-common-list-card-display-mode',
    templateUrl: './common-list-card-display-mode.component.html',
    styleUrl: './common-list-card-display-mode.component.scss'
})
export class CommonListCardDisplayModeComponent extends CommonListCommonDisplayModeComponent {
    rows: any[] = [];

    dataSourceSubscription: Subscription;

    ngOnInit() {
        // Initialize with current DataSource rows.
        this.rows = this.list.dataSource.data;

        // Register to DataSource rows list change.
        this.dataSourceSubscription = this.list.dataSource.connect().subscribe(rows => {
            this.rows = rows;
        });
    }

    ngOnDestroy() {
        this.dataSourceSubscription.unsubscribe();
    }

    @HostBinding('class') get getHostClass() {
        return 'flex-column full-width flex-default-gap';
    }
}
