<ul [style.color]="modified === true ? 'green' : 'black'">
    <li *ngFor="let choice of list; let i = index">{{choice}}<button class="choice-button" mat-icon-button (click)="onChoiceRemove(i, $event)">
        <mat-icon>
            delete
        </mat-icon>
    </button></li>
    <li *ngIf="(maxElements < 0) || (list.length < maxElements)"><input type="text" [style.color]="modified === true ? 'green' : 'black'" (change)="onChoiceAddChanged($event)" [(ngModel)]="toadd" value="toadd" /><button class="choice-button" mat-icon-button (click)="onChoiceAdd($event)">
        <mat-icon>
            add
        </mat-icon>
    </button></li>
</ul>