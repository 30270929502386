<app-success-screen>
    <div [ngSwitch]="type"
         screen-content>
        <ng-container *ngSwitchCase="'subscription'">
            <h2 i18n="@@order-success-screen--subscription-title">Thank you! You are now subscribed</h2>
            <span i18n="@@order-success-screen--subscription-subtitle">Invoice can be found in your account section of the portal</span>
        </ng-container>
        <ng-container *ngSwitchCase="'quotation'">
            <h2 i18n="@@order-success-screen--quotation-title">Quotation Ready. Check Your Email.</h2>
            <span i18n="@@order-success-screen--quotation-subtitle">An email has been sent to you with the quotation attached. You will also find the submission in the Quotations section of the Subscriptions menu.</span>
        </ng-container>
        <ng-container *ngSwitchCase="'trial-organization'">
            <ng-template [ngTemplateOutlet]="trialOrganizationTemplate"></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'trial-classroom'">
            <ng-template [ngTemplateOutlet]="trialOrganizationTemplate"></ng-template>
        </ng-container>
    </div>
    <button mat-flat-button
            color="primary"
            (click)="accessTheGames()"
            screen-action>
        <span i18n="@@order-success-screen--access-the-games">Access The Games</span>
    </button>
</app-success-screen>



<ng-template #trialOrganizationTemplate>
    <h2 i18n="@@trial-complete-title">Congratulations!</h2>
    <span i18n="@@trial-complete-blurb">
        Your account and organization have been created. You now have access to
    </span>

    <span class="games">
        <strong>
            <span>
                {{ productService.getGameName('MMO') }} & {{ productService.getGameName('MSM') }}
            </span>
        </strong>
    </span>

    <span i18n="@@common-until">
        until
    </span>

    <span class="dates">
        <strong>{{ _form.subscription.expiresOn | date: 'MM/dd/yyyy' }}</strong>.
    </span>

    <p i18n="@@trial-complete-question-blurb">
        If you have any questions you would like to share with us, please let us know at:
        <a href="mailto:info@appligogiques.com">info&#64;appligogiques.com</a>
    </p>

    <p i18n="@@trial-otherwise-click-blurb">
        Otherwise, click on the link below to get started
    </p>
</ng-template>