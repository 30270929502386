import { ArrayMember, ID, Member, Model, Required } from "@uon/model";
import { OnboardingAction } from "./action";
import { OnboardingCondition } from "./condition";

@Model()
export class OnboardingStateComponent {

    @ID() 
    id: string;

    @Member() 
    type: string;

    @Member()
    states: string;

    @Member() 
    params: object;
}