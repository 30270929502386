import { Injectable, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { FieldValidation, FieldValidationType, FieldValidations, ValidationFieldError } from '@applogic/model';
import { FormUtils } from './form.utils';

interface ErrorMessage {
    message: string;
    context?: any;
}
interface FieldErrors {
    label?: string;
    errors: {[errorCode: string]: ErrorMessage};
}

@Injectable({
    providedIn: 'root'
})
export class FormService {


    private currentInlineEditorRef: OverlayRef;

    fieldsErrors: {[fieldKey: string]: FieldErrors} = {};

    constructor(private overlay: Overlay) {

        this.registerField(FieldValidationType.FullName, $localize`:@@common-fullname: Full Name `);
        this.registerError(FieldValidationType.FullName, 'required',
            $localize`:@@form-field-required-message:This field is required`);
        this.registerError(FieldValidationType.FullName, 'minlength',
            $localize`:@@form-field-name-minlength-message:Must be at least 5 characters`);
        this.registerError(FieldValidationType.FullName, 'maxlength',
            $localize`:@@form-field-name-maxlength-message: Cannot be longer than 64 characters`);

            this.registerField(FieldValidationType.Email, $localize`:@@common-email: Email `);
        this.registerError(FieldValidationType.Email, 'required',
            $localize`:@@form-field-required-message:This field is required`);
        this.registerError(FieldValidationType.Email, ['pattern', "validatepattern"],
            $localize`:@@form-field-email-pattern-message:Must be a valid email address`);

        this.registerField(FieldValidationType.ProfileName, $localize`:@@common-username: Profile name `);
        this.registerError(FieldValidationType.ProfileName, 'required',
            $localize`:@@form-field-required-message:This field is required`);
        this.registerError(FieldValidationType.ProfileName, 'minlength',
            $localize`:@@form-field-username-minlength-message:Your profile name must be at least 5 characters`);
        this.registerError(FieldValidationType.ProfileName, 'maxlength',
            $localize`:@@form-field-username-maxlength-message:Your profile name cannot be longer than 32 characters`);
        this.registerError(FieldValidationType.ProfileName, ['pattern', "validatepattern"],
            $localize`:@@form-field-username-pattern-message:Your profile name can only contain numbers and letters.`);

        this.registerField(FieldValidationType.Password, $localize`:@@common-password: Password `);
        this.registerError(FieldValidationType.Password, 'required',
            $localize`:@@form-field-required-message:This field is required`);
        this.registerError(FieldValidationType.Password, 'minlength',
            $localize`:@@form-field-password-minlength-8-message:Your password must be at least 8 characters long`);
        this.registerError(FieldValidationType.Password, 'maxlength',
            $localize`:@@form-field-password-maxlength-32-message:Your password cannot be longer than 32 characters`);
        this.registerError(FieldValidationType.Password, 'validateEqual',
            $localize`:@@form-field-password-match:Your password must match`);

        this.registerField(FieldValidationType.StudentPassword, $localize`:@@common-password: Password `);
        this.registerError(FieldValidationType.StudentPassword, 'required',
            $localize`:@@form-field-required-message:This field is required`);
        this.registerError(FieldValidationType.StudentPassword, 'minlength',
            $localize`:@@form-field-password-minlength-6-message:Your password must be at least 6 characters long`);
        this.registerError(FieldValidationType.StudentPassword, 'maxlength',
            $localize`:@@form-field-password-maxlength-32-message:Your password cannot be longer than 32 characters`);
        this.registerError(FieldValidationType.StudentPassword, 'validateEqual',
            $localize`:@@form-field-password-match:Your password must match`);
    }

    registerField(fieldKey: string, label: string) {
        let fieldErrors: FieldErrors = this.fieldsErrors[fieldKey];

        if(!fieldErrors) {
            fieldErrors = {
                errors: {}
            };

            this.fieldsErrors[fieldKey] = fieldErrors;
        }

        fieldErrors.label = label.trim();
    }

    registerError(fieldKey: string, errorCodes: string|string[], message: string) {

        if(!Array.isArray(errorCodes)) {
            errorCodes = [errorCodes];
        }

        let fieldErrors: FieldErrors = this.fieldsErrors[fieldKey];

        if(!fieldErrors) {
            fieldErrors = {
                errors: {}
            };

            this.fieldsErrors[fieldKey] = fieldErrors;
        }

        for(const errorCode of errorCodes) {
            fieldErrors.errors[errorCode] = {
                message
            };
        }
    }

    /**
     * use as calback to an input (input) event
     * @param control 
     */
    preventSpaces(control: AbstractControl) {
        control.setValue(control.value.replace(/\s/g, ''));
    }


    /**
     * use as callback to an input (change) event
     * @param control 
     */
    trimSpaces(control: AbstractControl) {
        control.setValue(control.value.trim());
    }

    /**
     * in  this function we returning form control
     * according to its type 
     * @param type 
     */
    getFormControl(type: string, val: string = '', isRequired?: boolean) {
        switch (type) {
            case 'confirmPassword': 
                if(isRequired || (isRequired === undefined)) {
                    return new UntypedFormControl(val, [Validators.required, this.validatePassword()])
                }
                return new UntypedFormControl(val, [this.validatePassword()])
        }
        return new UntypedFormControl(val, this.getValidators(type, isRequired));
    }

    getFormLabel(type: string, formType: string = "") {
        switch(type) {
            case 'userName':
                if(formType == 'student-form') {
                    return $localize`:@@common-student-fullname: Student’s name `;
                }
                return $localize`:@@common-fullname: Full Name `;
            case 'fullName':
                return $localize`:@@common-fullname: Full Name `;
            case 'email':
                return $localize`:@@common-email: Email `;
            case 'profile':
                return $localize`:@@common-username: Profile name `;
            case 'password':
                return $localize`:@@common-password: Password `;
            case 'confirmPassword':
                return $localize`:@@common-confirm-password:Confirm Password`;
            case 'inviteCode':
                return $localize`:@@common-invite-code: Invitation Code `;
            case 'homework-label':
                return $localize`:@@classroom-homework-label-column:Title`;
            case 'homework-desc':
                return $localize`:@@classroom-homework-desc-column:Description`;
        }

        return "";
    }

    getValidators(field: string, isRequired?: boolean) : ValidatorFn[] {
        let result: ValidatorFn[] = [];

        if( (isRequired === undefined) || (isRequired === true) ) {
            result.push(Validators.required);
        }

        let f: FieldValidation = FieldValidations[field];

        if(f) {
            if(f.minLength > 0)
            {
                result.push(Validators.minLength(f.minLength));
            }

            if(f.maxLength > 0)
            {
                result.push(Validators.maxLength(f.maxLength));
            }

            if(f.pattern)
            {
                result.push(Validators.pattern(f.pattern));
            }
        }

        return result;
    }

    getFieldErrorMessage(field: AbstractControl, name: string) {

        const localizedMessage = field.errors?.localizedMessage;
        if(localizedMessage) {
            return localizedMessage;
        }
        
        if(name == 'fullName') {
            return this.getFullNameErrorMessage(field);
        }
        else if(name == 'email') {
            return this.getEmailErrorMessage(field);
        }
        else if(name == 'password') {
            return this.getPasswordErrorMessage(field);
        }
        else if(name == 'profileName') {
            return this.getFormUsernameErrorMessage(field);
        }

        if (field.hasError('required')) {
            return $localize`:@@form-field-required-message:This field is required`;
        }
        
        if (field.hasError('minlength')) {
            let minLength = FieldValidations[name].minLength;
            return $localize`:@@form-field-minlength-message: Must be at least ${minLength} characters`;
        }

        if (field.hasError('maxlength')) {
            let maxLength = FieldValidations[name].maxLength;
            return $localize`:@@form-field-maxlength-message: Cannot be longer than ${maxLength} characters`;
        }

        return FormUtils.GetControlError(field);
    }

    getFullNameErrorMessage(field: AbstractControl) {

        if (!field.touched) {
            return '';
        }

        const fieldErrors = this.fieldsErrors[FieldValidationType.FullName];
        if(!fieldErrors) {
            return '';
        }

        for(const errorCode of Object.keys(fieldErrors.errors)) {
            if (field.hasError(errorCode)) {
                return fieldErrors.errors[errorCode].message;
            }
        }

        return '';
    }

    getEmailErrorMessage(field: AbstractControl) {

        if (!field.touched) {
            return '';
        }

        const fieldErrors = this.fieldsErrors[FieldValidationType.Email];
        if(!fieldErrors) {
            return '';
        }

        for(const errorCode of Object.keys(fieldErrors.errors)) {
            if (field.hasError(errorCode)) {
                return fieldErrors.errors[errorCode].message;
            }
        }

        return '';
    }

    getPasswordErrorMessage(field: AbstractControl,formType?:string) {
        let result: string;

        if (!field.touched) {
            return result = '';
        }

        const fieldErrors = this.fieldsErrors[formType === 'student-form' ? FieldValidationType.StudentPassword : FieldValidationType.Password];
        if(!fieldErrors) {
            return '';
        }

        for(const errorCode of Object.keys(fieldErrors.errors)) {
            if (field.hasError(errorCode)) {
                return fieldErrors.errors[errorCode].message;
            }
        }

        return result;
    }

    validate(model: any, key: string, val: any, isRequired: boolean): string {

        let f: FieldValidation = FieldValidations[key];
        const validators = f.getValidators(isRequired);

        for (const validator of validators) {
            try {
                validator(model, key, val);
            }
            catch (ex) {

                if(ex.reason == "field is required") {
                    return $localize`:@@form-field-required-message:This field is required`;
                }
                else if(ex.reason == "below minimum length") {
                    const minLength = f.minLength;
                    return $localize`:@@form-field-minlength-message: Must be at least ${minLength} characters`;
                }
                else if(ex.reason == "above maximum length") {
                    const maxLength = f.maxLength;
                    return $localize`:@@form-field-maxlength-message: Cannot be longer than ${maxLength} characters`;
                }
                else {
                    return ex.reason;
                }
            }
        }

        return "";
    }

    getFormUsernameErrorMessage(field: AbstractControl) {

        if (!field.touched) {
            return "";
        }

        const fieldErrors = this.fieldsErrors[FieldValidationType.ProfileName];
        if(!fieldErrors) {
            return '';
        }

        for(const errorCode of Object.keys(fieldErrors.errors)) {
            if (field.hasError(errorCode)) {
                return fieldErrors.errors[errorCode].message;
            }
        }

        return "";
    }

    showInlineEditor(value: any,
        formControl: AbstractControl,
        element: HTMLElement,
        template: TemplateRef<any>,
        viewContainerRef: ViewContainerRef) {

        // reset field with current value
        formControl.reset(value, { touched: true });

        // mark as touch to display errors without blur
        formControl.markAsTouched();

        // À retester.
        const position = this.overlay
            .position()
            .flexibleConnectedTo(
                new ElementRef(element),
            ).withPositions([{ originX: "start", originY: "top", overlayX: "start", overlayY: "top" }])
            .withDefaultOffsetY(-8) // difference in padding
            .withDefaultOffsetX(-0.5);

        // create the overlay
        const ref = this.overlay.create({
            backdropClass: "cdk-overlay-transparent-backdrop",
            hasBackdrop: true,
            disposeOnNavigation: true,
            //panelClass: 'account-cdk-overlay-service',
            positionStrategy: position
            // width: bounds.width,
        });

        const on_resize = () => {
            const bounds = element.getBoundingClientRect();
            ref.updateSize({ width: bounds.width });
        }

        window.addEventListener('resize', on_resize, false);

        // dispose on backdrop clicked
        ref.backdropClick().subscribe(() => {
            ref.dispose();
            
        });

        // remove window resize listener when overlay is detached
        ref.detachments().subscribe(() => {
            window.removeEventListener('resize', on_resize, false);
            this.currentInlineEditorRef = null;
        })

        // resize and focus input when the overlay is loaded
        ref.attachments().subscribe(() => {
            on_resize();
            ref.hostElement.querySelector("input")?.focus();
        });

        // attach template portal
        const portal = new TemplatePortal(template, viewContainerRef);
        ref.attach(portal);


        this.currentInlineEditorRef = ref;
    }


    disposeInlineEditor() {
        this.currentInlineEditorRef.dispose();
    }

    /**
     * in this function we are checking confirm password 
     * is match  with password  
     */
    validatePassword(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } => {
    
            // self value (e.g. retype password)
            let v = c.value;
    
            // control value (e.g. password)
            let e = c.root.get('password');
    
            // value not equal
            if (e && v !== e.value)
                return {
                    validateEqual: v
                }
            return null;
    
        };
    }

    getFieldError(fieldKey: string, errorCode: string, context: any, defaultError: string = '') {
        let errorMsg: string;

        const fieldErrors = this.fieldsErrors[fieldKey];

        if(fieldErrors) {
            const fieldError = fieldErrors.errors[errorCode.toLowerCase()];

            if(fieldError) {
                errorMsg = this.getErrorMessage(fieldError, context || FieldValidations[fieldKey]);
            }
        }

        if(!errorMsg) {
            errorMsg = FormUtils.GetUonErrorFromCode(errorCode, context, defaultError);
        }

        return errorMsg;
    }

    getErrorMessage(errorMessage: ErrorMessage, context: any) {
        let message = errorMessage.message;

        if(context) {
            for(const key of Object.keys(context)) {
                message = message.split("{" + key + "}").join(context[key].toString());
            }
        }

        return message;
    }


    GetResponseFieldsErrors(bodyErrors: ValidationFieldError[], defaultError: string = '') {
        const errors: string[] = [];

        errors.push($localize`:@@form-validation--message:Form validation error`);
        for(const err of bodyErrors) {
            const fieldKey = err.path.join(".");
            for(const fieldErrorKey of Object.keys(err.errors)) {
                let errorMsg = this.getFieldError(fieldKey, fieldErrorKey, err.errors[fieldErrorKey], defaultError);

                if(!errorMsg) {
                    errorMsg = err.errors[fieldErrorKey];

                    if(typeof errorMsg !== 'string') {
                        errorMsg = (errorMsg as any).msg;
                    }
                }

                errors.push((this.fieldsErrors[fieldKey]?.label || fieldKey) + ": " + errorMsg);
            }
        }

        if(errors.length == 1) {
            return [];
        }

        return errors;
    }

}
