import { WordListSubType, WordListType } from "@applogic/model"
import { Chip } from "../base-game-progress/base-game-progress.component"

export class MSMChip extends Chip
{
    completed_Date?: string[]
}

export interface MSMActivityProgressRow {
    studentId: string,
    cols: MSMActivityProgressCol[]
}

export interface MSMActivityProgressCol {
    success: number,
    dates: string[],
    listId: string,
    gameNo: number,
    levelNo: number,
    type: WordListType,
    subtype: WordListSubType
}

export interface MSMDatesProgressRow {
    studentId: string,
    cols: MSMDatesProgressCol[]
}

export interface MSMDatesProgressCol {
    success: number,
    dates: string[],
    listId: string,
    gameNo: number,
    type: WordListType,
    subtype: WordListSubType
}

export interface MSMActivitiesTableRow {
    student_name: string,
    student_id: string,
    lists: {[listKey: string]: MSMChip[]}
}

export interface MSMDatesTableRow {
    student_name: string,
    student_id: string,
    dates: {[date: string]: MSMChip[] }
}