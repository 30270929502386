import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from '../services/language.service';

@Pipe({
    name: 'apLocalize'
})
export class ApLocalizePipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer, private languageService: LanguageService) {

    }

    transform(amount: any, defaultValue: string): string {
        if(amount) {
            const val = amount[this.languageService.currentLanguageCode];
            if(val) {
                return val;
            }
        }

        return defaultValue;
    }



}
