import { Component } from '@angular/core';
import { CommonListAbstractCellRendererComponent } from '../common-list-abstract-cell-renderer/common-list-abstract-cell-renderer.component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-common-list-language-code-renderer',
    templateUrl: './common-list-language-code-renderer.component.html',
    styleUrl: './common-list-language-code-renderer.component.scss'
})
export class CommonListLanguageCodeRendererComponent extends CommonListAbstractCellRendererComponent {

    constructor(protected languageService: LanguageService) {
        super();
    }

    getLanguageLabel(code: string) {
        return this.languageService.getLanguageName(code);
    }
}
