
import { Component, Input, ViewEncapsulation } from '@angular/core';


export enum InstitutionalWelcomeOption {
    Trial = 'trial',
    RequestQuote = 'request-quote',
    OrderProduct = 'order-product'
}

@Component({
    selector: 'app-institutional-welcome-option-card',
    templateUrl: './institutional-welcome-option-card.component.html',
    styleUrl: './institutional-welcome-option-card.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class InstitutionalWelcomeOptionCardComponent {
    Option = InstitutionalWelcomeOption;

    @Input()
    option: InstitutionalWelcomeOption;
}


