<app-dialog>
    <app-dialog-title>{{title}}</app-dialog-title>
    <app-dialog-content>
        <div *ngIf="paginationProvider"
             class="actions">
            <button mat-flat-button
                    *ngIf="paginationProvider.prevPage && paginationProvider.canPrev()"
                    color="accent"
                    (click)="PreviousPage()">
                <span>Previous</span>
            </button>
            <span class="spacer"></span>
            {{position}}&nbsp;/&nbsp;{{total}}
            <span class="spacer"></span>
            <button mat-flat-button
                    *ngIf="paginationProvider.nextPage && paginationProvider.canNext()"
                    color="accent"
                    (click)="NextPage()">
                <span>Next</span>
            </button>
        </div>

        <div matDialogContent
             class="invoice-dialog-content">
            <h2>{{subtitle}}</h2>
            <app-error-message-area [isAdmin]="true"
                                    [error]="errorObject"></app-error-message-area>
            <div [innerHtml]="html"></div>
        </div>
    </app-dialog-content>
</app-dialog>