<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[userRoleTemplate]">
        <app-organization-member-card-template listClass="app-property-list-container"
                                               [organization]="organization"></app-organization-member-card-template>
    </renderer-templates>
    <app-common-list-renderer-options [showDisplayMode]="true"
                                      i18n-searchPlaceholder="@@common-find-a-member-placeholder"
                                      searchPlaceholder="Find a member">
    </app-common-list-renderer-options>
    <button mat-menu-item
            (click)="openChangeMemberRoleDialog(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="accent">person</mat-icon>
        <span i18n="@@org-member-change-member-role-text">Change role</span>
    </button>

    <button mat-menu-item
            (click)="openRevokeAccessDialog(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="warn">close</mat-icon>
        <span i18n="@@org-member-revoke-access-member">Remove member</span>
    </button>
</app-common-list-renderer>

<ng-template #userRoleTemplate
             let-data>
    <app-organization-role-chip [role]="data?.row.role"
                                *ngIf="data?.row && data?.row.role"></app-organization-role-chip>
</ng-template>