import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Classroom, Student, StudentGameData, ClassroomStudentInvite, OrganizationInvite, ClassroomCollaboratorInvite, RoutesServer, ApiRoutes } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClassroomService } from '../classroom/classroom.service';
import { ApiDirectoryService } from '../services/api-directory.service';

const GAMEDATA_SERIALIZER = new JsonSerializer(StudentGameData);
const STUDENT_SERIALIZER = new JsonSerializer(Student);
const SERIALIZER = new JsonSerializer(ClassroomStudentInvite);
const CLASSROOM_SERIALIZER = new JsonSerializer(Classroom);
const ORG_INVITE_SERIALIZER = new JsonSerializer(OrganizationInvite);
const COLLABORATOR_INVITE_SERIALIZER = new JsonSerializer(ClassroomCollaboratorInvite);



export interface ClassroomStudentInviteSummary {
    invite: ClassroomStudentInvite;
    classroom: Classroom
}

export interface OrgInviteSummary {
    invite: OrganizationInvite;
    organ: Classroom
}
export interface CollaboratorInviteSummary {
    invite: ClassroomCollaboratorInvite;
    classroom: Classroom
}


@Injectable({
    providedIn: 'root'
})
export class InviteService {


    constructor(private classroomService: ClassroomService,
        private dirService: ApiDirectoryService) { }


    getOrgInvite(token: string): Observable<OrganizationInvite> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Invite, `/staff/${token}`, { withCredentials: true })
            .pipe(
                map((r: any) => ORG_INVITE_SERIALIZER.deserialize(r, true)),
                catchError(err => {
                    if(err.status == 404)
                    {
                        err.userMessage = $localize`:@@staff-invite-not-found:The invitation was not found or expired.`;
                    }
                    return throwError(err);
                })
            );
    }
    getCollaboratorsInviteSummary(token: string): Observable<CollaboratorInviteSummary> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Invite, `/collaborator/${token}`, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    return { invite: COLLABORATOR_INVITE_SERIALIZER.deserialize(r.invite, true), classroom: CLASSROOM_SERIALIZER.deserialize(r.classroom, true) };
                }),
                catchError(err => {
                    
                    if(err.status == 404)
                    {
                        err.userMessage = $localize`:@@org-invite-not-found:The invitation was not found or expired.`;
                    }

                    return throwError(err);
                })
            );
    }
    acceptInviteCollaborator(token: string) {
        const payload: any=""
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Invite, `/collaborator/${token}`,payload, { withCredentials: true }).pipe(
            map((r: any) => {
                
            }),
            catchError(err => {

                if (err.status == 404) {
                    err.userMessage = $localize`:@@org-invite-not-found:The invitation was not found or expired.`;
                }

                return throwError(err);
            })
        );
    }


    getStudentInviteSummary(token: string): Observable<ClassroomStudentInviteSummary> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Invite, `/student/${token}`, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    return { invite: SERIALIZER.deserialize(r.invite, true), classroom: CLASSROOM_SERIALIZER.deserialize(r.classroom, true) };
                }),
                catchError(err => {
                    if(err.status == 404)
                    {
                        err.userMessage = $localize`:@@student-invite-not-found:The invitation was not found or expired.`;
                    }

                    return throwError(err);
                })
            );
    }
  

    registerStudent(student: Student, token: string) {

        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Invite, `/student/${token}`, student, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    return STUDENT_SERIALIZER.deserialize(r, true);
                }),
                catchError(err => {
                    if (err.status == 404) {
                        err.userMessage = $localize`:@@student-invite-not-found:The invitation was not found or expired.`;
                    }
                    else {
                        err.userMessage = this.classroomService.localizeErrorMessage(err.error.message);
                    }
                    return throwError(err);
                  })
            );
    }

    registerStaff(token: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Invite, `/staff/${token}`, {}, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    return true;
                }),
                catchError(err => {
                    return throwError(err)
                })
            );
    }




}




@Injectable({
    providedIn: 'root'
})
export class StudentInviteSummaryResolverService  {

    constructor(private service: InviteService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClassroomStudentInviteSummary> {
        return this.service.getStudentInviteSummary(route.params.token);
    }
}

