import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'setPlaceholder'
})
export class SetPlaceholderPipe implements PipeTransform {

    transform(formType:any ,formControlName:any) {
        let placeholder = "";
        if (formType === 'student-form') {
                if (formControlName === 'profile') {
                    placeholder =    $localize`:@@common-classroommanager-student-profile-placeholder:Type in student's profile name`;
                } else if (formControlName === 'userName') {
                    placeholder =    $localize`:@@common-classroommanager-student-fullName-placeholder:Type in student's full name`;
                } else if (formControlName === 'password') {
                    placeholder =    $localize`:@@common-classroommanager-student-password-placeholder:Type in student's password`;
                }
        }
        if (formType === 'forgotPassword' || formType === 'login-hidden-email' 
            || formType === 'login-email-step') {
            if (formControlName === 'email') {
                placeholder = $localize`:@@forgotPassword-email-example-placeholder:abc@example.com`;
            } 
        }
        if (formType === 'billing-info') {
            if (formControlName === 'email') {
                placeholder = $localize`:@@billing-info-email-placeholder:your email address`;
            } else if (formControlName === 'userName') {
                placeholder = $localize`:@@billing-info-full-name-placeholder:Full Name`;
            } else if (formControlName === 'password') {
                placeholder = $localize`:@@billing-info-password-placeholder:Password`;
            }
        }
        return placeholder ;
    }

}
