import { Subscription } from "./subscription.model";
import { SubscriptionAllocation } from "./subscription-allocation.model";
import { ArrayMember, Member, Model } from "@uon/model";
import { User } from "../user/user.model";
import { SubAllocationsResponse } from "./sub-allocations-response";

/**
 * To contains all subscription with all its allocations for an organization.
 * 
 * This structure is to be used with a route response.
 */
@Model()
export class OrgAllocationsResponse {

    @ArrayMember(SubAllocationsResponse)
    public subscriptions: SubAllocationsResponse[];

    @ArrayMember(User)
    public users: User[];

    public update() {
        for(let i=0; i<this.subscriptions.length; i++) {
            let s = this.subscriptions[i];
            s.update();
        }
    }
}