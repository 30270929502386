import { ArrayMember, ID, Member, Model } from "@uon/model";

/**
 * The wordlist structure.
 */
 @Model()
export class WordList {

    @ID()
    id: string;

    @Member()
    createdAt: Date;

    @Member()
    updatedAt: Date;

    @Member()
    scopes: any;

    @Member()
    apps: any;

    @Member()
    props: any;

    @Member()
    name: string;

    @Member()
    desc: string;

    @Member()
    note: string;

    @Member()
    pos: number;

    @Member()
    enabled: boolean;

    @ArrayMember(Object)
    words: any[];

    @Member()
    groups: any;
}
