<app-dialog icon="person">
    <app-dialog-title i18n="@@orgs-selection-dialog-title">Select an organization</app-dialog-title>
    <app-dialog-content>
        <div class="dialog-message">
            {{message}}<br />
            <strong>{{currentOrg?.name}}&nbsp;</strong>
        </div>
        <div>
            <app-orgs-selection [(currentOrg)]="currentOrg"
                                [userRole]="userRole">
                <div org-select-text>
                    <ng-content select="[org-select-text]"></ng-content>
                </div>
            </app-orgs-selection>
        </div>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            [disabled]="!currentOrg"
            (click)="onSelect()">
        <span i18n="@@common-select">Select</span>
    </button>
</app-dialog>