<div id="printable" class="students">
    <table class="students-table">
        <tr *ngFor="let pair of students">
            <td [ngClass]="{'student': true,'noborder': !pair.student1}">
                <div *ngIf="pair.student1">
                    <ng-template *ngTemplateOutlet="studentTemplate; context: {$implicit: pair.student1}"></ng-template>
                </div>
            </td>
            <td [ngClass]="{'student': true,'noborder': !pair.student2}">
                <div *ngIf="pair.student2">
                    <ng-template *ngTemplateOutlet="studentTemplate; context: {$implicit: pair.student2}"></ng-template>
                </div>
            </td>
        </tr>
    </table>
</div>

<ng-template #studentTemplate let-student>
    <table style="width:100%">
        <tr>
            <td class="infos">
                <div class="info-line">
                    <span i18n="@@common-name">Name</span>: <span class="line-data">{{student.fullName}}</span>
                </div>
                <div class="info-line">
                    <span i18n="@@common-username"> Profile name </span>: <span class="line-data">{{student.username}}</span>
                </div>
                <div class="info-line">
                    <span i18n="@@common-password"> Password </span>: <span class="line-data">{{student.password}}</span>
                </div>
            </td>
            <td class="products">
                <div *ngIf="student.subscriptionSeats"
                     class="tokens-section">
                    <div *ngFor="let seat of student.subscriptionSeats">
                        <img [src]="getProductImageUrl(seat.productId)"
                             style="width: 24px; height: 24px;" />
                    </div>
                </div>
            </td>
        </tr>
    </table>
</ng-template>