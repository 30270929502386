<button mat-icon-button
        [matMenuTriggerFor]="languageMenu">
    <mat-icon>language</mat-icon>
</button>

<mat-menu #languageMenu="matMenu"
          overlapTrigger="false"
          xPosition="before">
    <button mat-button
            *ngFor="let l of languageService.languages"
            (click)="onLanguageClick(l)">
        <span>{{ languageService.getLanguageName(l) }}</span>
    </button>
</mat-menu>