import { Component, Input } from '@angular/core';
import { ProductsSelectComponent } from '../products-select/products-select.component';
import { ProductOrderCardComponent, ProductOrderCardLayout } from '../product-order-card/product-order-card.component';
import { SubscriptionPlan, SubscriptionType } from '@applogic/model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


@Component({
    selector: 'app-products-order-card',
    templateUrl: './products-order-card.component.html',
    styleUrls: ['../products-select/products-select.component.scss', './products-order-card.component.scss'],
    providers: [ {provide: ProductsSelectComponent, useExisting: ProductsOrderCardComponent }]
})
export class ProductsOrderCardComponent extends ProductsSelectComponent {

    Layout = ProductOrderCardLayout;
    
    SubscriptionPlan = SubscriptionPlan;
    SubscriptionType = SubscriptionType;
    
    @Input()
    readonly: boolean = false;

    @Input()
    showLabels: boolean = true;

    protected yearlyChecked: boolean = false;

    getProducts() {
        if(this.readonly) {
            return this.products.filter(p => p.seats > 0);
        }
        else {
            return this.products;
        }
    }

    onProductCardUpdate(card: ProductOrderCardComponent) {
        this.onQuantityChanged(card.product.seats, card.product);
        this.triggerUpdate();
    }

    protected onIndividualSubscritionPlanToggle(event: MatSlideToggleChange) {
        if (event.checked) {
            this.setSubscriptionPlan(SubscriptionPlan.yearly);
        }
        else {
            this.setSubscriptionPlan(SubscriptionPlan.monthly);
        }
    }

    public setSubscriptionPlan(plan: SubscriptionPlan, reset: boolean = true) {
        super.setSubscriptionPlan(plan, reset);
        this.yearlyChecked = plan == SubscriptionPlan.yearly;
    }
}
