<app-common-list-renderer [list]="list"
                          #organizationList>
    <renderer-templates [templates]="[userRoleTemplate, nbUsersTemplate]">
        <app-organization-card-template></app-organization-card-template>
    </renderer-templates>
    <app-common-list-renderer-options [showDisplayMode]="mode == 'list'">
    </app-common-list-renderer-options>
    <app-common-list-toolbar [alignLeft]="true">
        <mat-checkbox *ngIf="(mode == 'selection') && isSiteAdmin"
                      (change)="onShowAll($event)"
                      [disableRipple]="true"
                      [checked]="showAll"
                      common-list-toolbar-option>Show All (Site Admin)</mat-checkbox>
        <button *ngIf="mode == 'list'"
                mat-stroked-button
                color="accent"
                (click)="openNewOrgDialog()"
                i18n="@@common-create-an-organization"
                common-list-toolbar-option>
            Create an organization
        </button>
    </app-common-list-toolbar>
</app-common-list-renderer>

<ng-template #userRoleTemplate
             let-data>
    <app-organization-role-chip [role]="getRole(data?.row, data?.column)"
                                *ngIf="data?.row && getRole(data?.row, data?.column)"></app-organization-role-chip>
</ng-template>

<ng-template #nbUsersTemplate
             let-data>
    <span *ngIf="organizationList.displayMode == CommonListDisplayMode.Default"
          class="secondary-text">{{data.row._memberCount || 0}}</span>
    <span *ngIf="organizationList.displayMode == CommonListDisplayMode.Card"
          class="secondary-text"
          i18n="@@organization-member-count">{ data.row._memberCount, plural, =0 {No members} =1 {1 member} other { {{data.row._memberCount}} members } }</span>
</ng-template>