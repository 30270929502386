import { Component, Input, Optional, TemplateRef, ViewChild } from '@angular/core';
import { CommonListRendererTemplatesComponent } from '../../common-list-renderer-templates.component';


@Component({
    selector: 'app-card-template',
    standalone: true,
    imports: [],
    templateUrl: './card-template.component.html',
    styleUrl: './card-template.component.scss'
})
export class CardTemplateComponent<T> {
    _template: TemplateRef<{row: T}>;

    @ViewChild(TemplateRef<{row: T}>, { static: true })
    set template(template: TemplateRef<{row: T}>) {
        this._template = template;

        if(this.parent) {
            this.parent.cardTemplate = template;
        }
    }

    @Input()
    set listClass(classes: string) {
        if (this.parent) {
            this.parent.cardListClass = classes;
        }
    }

    constructor(@Optional() private parent: CommonListRendererTemplatesComponent<T>) {
        if (!this.parent) {
            console.error('The component using directive cardTemplate must be used as a child of the component CommonListRendererTemplatesComponent');
        }
        else {
            parent.cardTemplate = this._template;
        }
    }
}
