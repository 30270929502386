import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface LanguageInfo
{
    code: string;
    label: string;
};

const LOCALE_TO_LANG = {
    'fr': 'Français',
    'en': 'English',
    'es': 'Español'
};

export interface ILanguageService {
    changeLanguage(lang: string): void;
}

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public selected: string = "";
    private nbLockCurrentPage: number = 0;
    private languagesList: LanguageInfo[] = [];

    constructor(@Inject(LOCALE_ID) readonly localeId: string, private router: Router) {
        this.selected = localeId;
        const idx = this.selected.indexOf("-");
        if(idx != -1) {
            this.selected = this.selected.substring(0, idx);
        }

        this.languagesList = [
            { code: "fr", label: $localize`:@@common-language-french:French` },
            { code: "en", label: $localize`:@@common-language-english:English` },
            { code: "es", label: $localize`:@@common-language-spanish:Spanish` }];
    }

    get getCurrentLanguage() {
        return LOCALE_TO_LANG[this.selected];
    }

    get currentLanguageCode() {
        return  this.selected;
    }

    get otherLanguages() {
        const langs = Object.keys(LOCALE_TO_LANG);
        return langs.filter(l => l !== this.selected);
    }

    get languages() {
        return Object.keys(LOCALE_TO_LANG);;
    }

    getLanguageName(id: string) {
        return LOCALE_TO_LANG[id];
    }

    getLanguagesInfo() {
        return this.languagesList;
    }

    changeLanguage(lang: string) {

        // If language not defined, use the browser language.
        if (!lang) {
            lang = this.selected;
        }

        // TODO: Jira bug [AEP-448] Find a better way to replace the language.
        // Otherwise it may conflic with a page starting with a language code.
        const renamed_path = location.pathname.replace(`/${this.selected}`, `/${lang}`);

        if (location.pathname === renamed_path) {
            return;
        }

        if (!environment.multiLanguages) {
            alert("Not supported in this current build configuration. (" + lang + ")\n" + location.href + "\n" + renamed_path);
            return;
        }

        location.href = renamed_path;
    }

    /**
     * Check if the current page is locked for language change.
     */
    isLocked(): boolean {
        return this.nbLockCurrentPage > 0;
    }

    /**
     * Lock the current page.
     * 
     * To avoid page change when the language is changing.
     */
    lockCurrentPage(): void {
        this.nbLockCurrentPage++;
    }

    /**
     * Unlock the current page.
     * 
     * To that page change can occur again when the language is changing.
     */
    unlockCurrentPage(): void {
        this.nbLockCurrentPage--;
    }

    plural(count: number, zeroText: string, oneText: string, manyText: string) {
        let result: string;

        if(count == 0) {
            result = zeroText.replace("[count]", count.toString());
        } else if(count == 1) {
            result = oneText.replace("[count]", count.toString());
        } else {
            result = manyText.replace("[count]", count.toString());
        }

        return result;
    }

    /**
     * Localize a string from a dictionary.
     * 
     * The dictionary keys correspond to the language code. The "default" key is used when
     * the string doesn't need to be translated.
     * 
     * @param {{[key:string]: string}} dict The dictionary of translation.
     * @param {string} prefix (Optional) Optional prefix to be added to the key. (The '-' will be added between the prefix and the key)
     * 
     * @returns {string} Returns the corresponding localized string.
     */
     localizeFromDict(dict: {[key:string]: string}, prefix: string = undefined) {
        if(!dict)
        {
            console.error("Missing translation from this dictionary: " + JSON.stringify(dict));
            if(environment.serverType != "prod") {
                return "!ERROR!";
            }

            return "";
        }
        if(prefix) prefix += "-";
        else prefix = "";
        let result = dict[prefix + this.currentLanguageCode];
        if(!result) {
            result = dict[prefix + 'default'];
        }
        return result;
    }
}
