import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationBillingForm, Organization, OrganizationTitle, OrganizationMember, QuotationOrganization, Address } from '@applogic/model';
import { Model } from '@uon/model';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { AddressNameInputResult } from 'src/app/address-ui/address-name-input/address-name-input.component';
import { GoogleMapsService } from 'src/app/address-ui/google-maps.service';
import { BaseLoginRegisterFormComponent, UserAuthenticationResult } from 'src/app/auth-ui/base-login-register-form/base-login-register-form.component';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomFormBuilder } from 'src/app/core/form-builder/custom-form-builder';
import { AddressFields, CustomFormUtils } from 'src/app/core/form-builder/custom-form-utils';
import { OrgsSelectionDialogComponent } from 'src/app/organization-ui/orgs-selection-dialog/orgs-selection-dialog.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FormService } from 'src/app/services/form.service';

@Component({
    selector: 'app-organization-billing-form',
    templateUrl: './organization-billing-form.component.html',
    styleUrl: './organization-billing-form.component.scss'
})
export class OrganizationBillingFormComponent {

    protected _tellsUsFormData: OrganizationBillingForm;

	@Input()
    set formData(val: OrganizationBillingForm) {
        this.setFormDataFromInput(val);
    }

    @Input()
    showLabel: boolean = true;

    get formData() {
        return this._tellsUsFormData;
    }

    public get addressForm(): UntypedFormGroup {
        return this.getOrgControl().controls.address as UntypedFormGroup;
    }

    @Output()
    formDataChange: EventEmitter<OrganizationBillingForm> = new EventEmitter<OrganizationBillingForm>();

    @Output() onchangeUpdateForm: EventEmitter<OrganizationBillingForm> = new EventEmitter();

    @Output() addressFromGoogle: EventEmitter<any> = new EventEmitter();

    isValid: boolean = false;

    @Output() isValidChange = new EventEmitter<boolean>();

    organizationId: string = "";
    private loadedOrganizationId: string = "";
    

    private newOrgCache: OrganizationBillingForm;

    hideSelectedOrgAddress: boolean = false;

    protected organizationName: string;

    billingForm = new CustomFormBuilder();

    addressFields: AddressFields;

    constructor(public authService: AuthService,
        public formService: FormService,
        public googleAddressService: GoogleMapsService,
        private orgService: OrganizationService,
        private dialogService: DialogService,
        private dialog: MatDialog
    ) {
        const orgIdField = this.billingForm.addFormControlTemplate("orgId", 0, {

        });

        this.billingForm.beginGroup("org");

        const nameField = this.billingForm.addFormControlTemplate("name", 1, {
            label: $localize`:@@tell-us-form-org-name-label:Organization name`
        });

        orgIdField.control.valueChanges.subscribe((v) => {
            nameField.hidden = !!v;
        });

        this.billingForm.beginGroup("address");
        this.addressFields = CustomFormUtils.SetAddressFields(this.billingForm);
        this.billingForm.endGroup();
        CustomFormUtils.AddOrgTitleField(this.billingForm);

        this.billingForm.endGroup();
        
        this.billingForm.patch({
            org: {
            },
            orgId: ""
        });

        this.updateControls(true);

        this.billingForm.formGroup.valueChanges
            .pipe(
                tap(c => {
                    this.updateValidation();
                }),
                debounceTime(400),
                distinctUntilChanged()
            )
            .subscribe(res => {
                this.triggerUpdate();
                
            });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.updateValidation();
    }

    setFormData(data: OrganizationBillingForm) {
        const orgId = this.billingForm.formGroup.value.orgId;
        this.organizationId = orgId || "";
        this.billingForm.reset(JSON.parse(JSON.stringify(data)));
        this.organizationName = data.org?.name || "";
        this.updateControls(!(!!orgId));
    }
    
    getFormData() {
        return this.billingForm.value;
    }



    private getOrgControl() {
        return this.billingForm.formGroup.controls.org as UntypedFormGroup;
    }

    onOrganizationChanged(org: Organization) {
        this.setOrganization(org);

        this.updateControls(!(!!org));
    }

    updateControls(isOrgNew: boolean) {
        const formGroup = this.getOrgControl();

        const controls: AbstractControl[] = [];

        controls.push(formGroup.controls.name);

        const addressFormGroup = formGroup.controls.address as UntypedFormGroup;
        controls.push(addressFormGroup.controls.line1);
        controls.push(addressFormGroup.controls.state);
        controls.push(addressFormGroup.controls.country);
        controls.push(addressFormGroup.controls.zipcode);

        const disableValidators = this.hideSelectedOrgAddress && !isOrgNew;

        this.addressFields.line1.setDisableValidators(disableValidators);
        this.addressFields.state.setDisableValidators(disableValidators);
        this.addressFields.country.setDisableValidators(disableValidators)
        this.addressFields.zipcode.setDisableValidators(disableValidators);
    }

    trimSpaces(control: AbstractControl) {
        const v = control.value.trim();
        if(v != control.value) {
            control.setValue(control.value.trim());
            this.triggerUpdate();
        }
    }

    setCountryValue(event: any) {
        this.billingForm.patch({
            org: {
                address: {
                    country: event,
                }
            }
        });

        this.triggerUpdate();
    }

    public get country(): UntypedFormControl {
        return this.getOrgControl().controls.address.get('country') as UntypedFormControl;
    }

    onChangeOrgTitle(title: OrganizationTitle) {
        this.triggerUpdate();
    }

    /**
     * Update the state that tell if the form (and sub form) are validated.
     */
    private updateValidation() {
        let result: boolean = this.isValid;

        result = this.billingForm.valid;

        this.isValid = result;
        this.isValidChange.emit(result);
    }

    private triggerUpdate() {
        const v = this.billingForm.value;
        if(JSON.stringify(v) != JSON.stringify(this.formData)) {
            this.setFormDataForOutput(this.billingForm.value);
            this.onchangeUpdateForm.emit(this.billingForm.value);
        }
    }

    setOrganization(org: Organization) {
        const orgId = org?.id || "";

        if(!this.loadedOrganizationId) {
            const v = this.billingForm.value;
            v.orgId = ""; // Org id is set before this function is called.
            this.newOrgCache = v;
        }

        let staff: OrganizationMember;

        if(org) {
            staff = this.authService.user
                ? org.staffMembers.find(s => s.user?.id == this.authService.user.id)
                : undefined;
        }

        const hideAddressFields = this.hideSelectedOrgAddress && !!orgId;
        this.addressFields.line1.hidden = hideAddressFields;
        this.addressFields.line2.hidden = hideAddressFields;
        this.addressFields.city.hidden = hideAddressFields;
        this.addressFields.state.hidden = hideAddressFields;
        this.addressFields.zipcode.hidden = hideAddressFields;
        this.addressFields.country.hidden = hideAddressFields;

        this.organizationId = orgId;
        this.loadedOrganizationId = orgId;

        let data = !this.organizationId ? this.newOrgCache : undefined;

        if(!data) {
            data = Model.New(OrganizationBillingForm, {});
            data.orgId = orgId;
            data.org = new QuotationOrganization();
            data.org.name = org?.name || "";
            if(staff) {
                data.org.title = staff.title;
                data.org.otherTitle = staff.otherTitle;
            }

            if(org.billingAddress) {
                // clone the address.
                data.org.address = new Address();
                data.org.address.line1 = org.billingAddress.line1 || "";
                data.org.address.line2 = org.billingAddress.line2 || null;
                data.org.address.state = org.billingAddress.state || "";
                data.org.address.city = org.billingAddress.city || "";
                data.org.address.country = org.billingAddress.country?.toLowerCase() || "";
                data.org.address.zipcode = org.billingAddress.zipcode || "";
            }
            else {
                data.org.address = new Address();
            }
        }

        this.billingForm.patchChanged(JSON.parse(JSON.stringify(data)));

        this.organizationName = data.org?.name || "";

        this.updateControls(false);
    }

    selectOrganisation() {
        OrgsSelectionDialogComponent.createDialog(this.dialogService, this.dialog, {
            message: $localize`:@@quotation-card-select-org-for-invoice-create:You are creating an invoice that is part of organization:`
        }).show().then((org) => {
            if (org) {
                // Retrieve the organization with its address.
                this.orgService.getOrg(org.id).subscribe((response) => {
                    this.setOrganization(response.org);
                });
            }
        });
    }

    protected onAddressSelect(result: AddressNameInputResult) {
        const placeResult = result.placeResult;
        const address = result.address;

        this.addressFromGoogle.emit(placeResult);
        this.billingForm.patch({
            org: {
                name: placeResult?.name,
                address: {
                    line1: address.street,
                    line2: null,
                    city: address.city,
                    state: address.state,
                    country: address.country.toLowerCase(),
                    zipcode: address.zipcode,
                }
            }
        });
    }

    setFormDataFromInput(val: OrganizationBillingForm) {
        if(this._tellsUsFormData != val) {
            this._tellsUsFormData = val;
            if(val) {
                this.setFormData(val);
            }
        }
    }

    setFormDataForOutput(val: OrganizationBillingForm) {
        if(this._tellsUsFormData != val) {
            this._tellsUsFormData = val;
            this.formDataChange.emit(val);
        }
    }
}
