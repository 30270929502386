<app-dialog icon="people" [errorObject]="errorObject">
    <app-dialog-title i18n="@@classroom-collab-dialog-title">Manage your collaborators</app-dialog-title>
    <app-dialog-message>
        <p style="text-align: justify;font-size: 12px;"
           i18n="@@classroom-collab-dialog-message">
            Enter the name or email address of a member of the organization in the field below to find that person in the
            list. Add collaborators to your class by checking the box next to their names. They will receive an email
            invitation when you click the Save button.
        </p>
    </app-dialog-message>
    <app-dialog-content>
        <div class="app-property-list-container">

            <app-users-selection [orgId]="data.classroom?.organization?.id"
                                 [excludeList1]="data.excludedUsers"></app-users-selection>

        </div>

    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            [disabled]="usersSelectionComponent?.collaboratorAdd.length == 0 || loading.isLoading || (loading.loadingChange | async)"
            color="accent"
            (click)="sendInvite()">
        <span i18n="@@collab-model-save-button">Save</span>
    </button>
</app-dialog>