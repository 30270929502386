import { Component, Input, OnInit } from '@angular/core';
import { MultiCountersUtils, Student, SubAllocationsResponse, SubscriptionAllocation, SubscriptionSeat, UserAllocationsResponse } from '@applogic/model';
import { SeatService } from 'src/app/services/seat.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-assign-tokens-panel',
    templateUrl: './assign-tokens-panel.component.html',
    styleUrls: ['./assign-tokens-panel.component.scss']
})
export class AssignTokensPanelComponent implements OnInit {

    checkboxMode: boolean = true;

    checked: { [key: string]: string; } = {};

    multiChecked: { [productKey: string]: { [allocationId: string]: boolean; } } = {};

    disabledProducts: { [key: string]: boolean; } = {};
    processing: number = 0;
    errorObject: any;
    atLeastOneChecked: boolean = false;


    @Input()
    multiSelect: boolean = false;

    @Input()
    student: Student;

    @Input()
    userAllocations: UserAllocationsResponse;

    @Input()
    hideEmptyAllocations: boolean = false;

    @Input()
    autoSelectOnePerProduct: boolean = false;

    subAllocations: SubAllocationsResponse[] = [];
    allocations: SubscriptionAllocation[] = [];

    addedSeats: SubscriptionSeat[] = [];

    constructor(private seatService: SeatService,
        private productService: ProductService) { }

    ngOnInit(): void {
        if (this.student && this.student.subscriptionSeats) {
            this.student.subscriptionSeats.map((s) => {
                this.checked[s.productId] = s.allocationId;
                this.disabledProducts[s.productId] = true;
            });
        }

        if (!this.student && this.autoSelectOnePerProduct) {
            this.checkFirstAttributable();
        }

        // Build the allocations list.
        for (let j = 0; j < this.userAllocations.subscriptions.length; j++) {
            let subAlloc = this.userAllocations.subscriptions[j];

            let toadd: boolean = false;

            for (let k = 0; k < subAlloc.allocations.length; k++) {
                let alloc = subAlloc.allocations[k];
                if (this.hideEmptyAllocations) {
                    if (alloc.getNbSeats('freeSeats') > 0) {
                        this.allocations.push(alloc);
                        toadd = true;
                    }
                } else {
                    this.allocations.push(alloc);
                    toadd = true;
                }
            }

            if (toadd) {
                this.subAllocations.push(subAlloc);
            }
        }
    }

    onCheckedChanged(checked: boolean, allocation: SubscriptionAllocation, productCode: string) {
        if (this.multiSelect) {
            if (!this.multiChecked[productCode]) {
                this.multiChecked[productCode] = {};
            }

            if (this.checkboxMode && !checked) {
                this.multiChecked[productCode][allocation.id] = false;
            } else {
                this.multiChecked[productCode][allocation.id] = true;
            }

        } else {
            if (this.checkboxMode && !checked) {
                this.checked[productCode] = undefined;
            } else {
                this.checked[productCode] = allocation.id;
            }
        }

        this.updateAtLeastOneChecked(checked);
    }

    /**
     * Update the "atLeastOneChecked" property.
     * 
     * @param {boolean} checked The last checked value. Because if set to true, "atLeastOneChecked" is surely true and no need to compute anything.
     */
    updateAtLeastOneChecked(checked: boolean) {
        let atLeastOneChecked = checked;
        if (this.multiSelect) {
            // Check if there are still checkbox checked.
            if (!checked) {
                for (const productCode of Object.keys(this.multiChecked)) {
                    let allocationsId = this.multiChecked[productCode];
                    for (const allocationId of Object.keys(allocationsId)) {
                        if (allocationsId[allocationId]) {
                            atLeastOneChecked = true;
                            break;
                        }
                    }
                    if (atLeastOneChecked) {
                        break;
                    }
                }
            }
        }
        else {
            // Check if there are still checkbox checked.
            if (!checked) {
                for (const productCode of Object.keys(this.checked)) {
                    if (!this.disabledProducts[productCode] && this.checked[productCode]) {
                        atLeastOneChecked = true;
                        break;
                    }
                }
            }
        }

        this.atLeastOneChecked = atLeastOneChecked;
    }

    checkFirstAttributable(productCode?: string) {
        if (!productCode) {
            let products = this.productService.getProductCodes();
            for (let i = 0; i < products.length; i++) {
                let productCode = products[i];

                if (productCode) {
                    this.checkFirstAttributable(productCode);
                }
            }
            return;
        }

        for (let j = 0; j < this.userAllocations.subscriptions.length; j++) {
            let subAlloc = this.userAllocations.subscriptions[j];

            for (let k = 0; k < subAlloc.allocations.length; k++) {
                let alloc = subAlloc.allocations[k];
                let freeSeats = alloc.getNbSeats('freeSeats', productCode);

                if (freeSeats) {
                    this.onCheckedChanged(true, alloc, productCode);
                    return;
                }
            }
        }
    }

    assignTokens(student?: Student) {
        if (!student) student = this.student;
        if (!student) return;

        this.addedSeats = [];

        return new Promise((resolve, reject) => {
            this.processing++;
            this.errorObject = undefined;
            for (const productCode of Object.keys(this.checked)) {
                let allocationId = this.checked[productCode];
                if (!allocationId || this.disabledProducts[productCode]) continue;

                if (!student.subscriptionSeats) student.subscriptionSeats = [];

                let seat = student.subscriptionSeats.find((s) => s.productId == productCode);
                if (!seat) {
                    this.processing++;
                    this.seatService.assignSeatToStudent(allocationId, student.id, productCode).subscribe((seat) => {
                        if (seat) {
                            student.subscriptionSeats.push(seat);
                            this.addedSeats.push(seat);

                            // The model must be updated.
                            let alloc = this.allocations.find(a => a.id == allocationId);

                            if (alloc) {
                                MultiCountersUtils.move(alloc.freeSeats, alloc.usedSeats, { [productCode]: 1 });
                            }
                        }

                        this.processing--;

                        if (this.processing == 0) {
                            if (!this.errorObject) {
                                resolve(this.addedSeats);
                            } else {
                                reject(this.errorObject);
                            }
                        }
                    }, (err) => {
                        this.errorObject = err;
                        this.processing--;

                        if (this.processing == 0) {
                            reject(err);
                        }
                    });
                }
            }
            this.processing--;

            if (this.processing == 0) {
                if (!this.errorObject) {
                    resolve(this.addedSeats);
                } else {
                    reject(this.errorObject);
                }
            }
        });
    }
}
