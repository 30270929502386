import { Injectable } from "@angular/core";
import { AngularUtils } from '../services/angular-utils';
import { MapsAPILoader } from '@ng-maps/core';
import { environment } from "src/environments/environment";


@Injectable()
export class GoogleMapsService {


    autocompleteOptions: google.maps.places.AutocompleteOptions = {
        // See: https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
        types: ['establishment', 'geocode']
    };

    constructor(private mapsAPILoader: MapsAPILoader) {

    }

    async load() {
        return new Promise<void>((resolve, reject) => {
            this.mapsAPILoader.configure({
                apiKey: environment.googleMapApiKey
            });
            
            this.mapsAPILoader.load().then(() => {
                resolve();
            }).catch((ex) => {
                reject(ex);
            })
        });
    }

    async geocode(postalCode, country) {
        return new Promise<{results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus}>((resolve, reject) => {
            this.load().then(() => {
                if (AngularUtils.isDevMode()) console.log("[GoogleMapsService] maps api loaded");
                const GEOCODER = new google.maps.Geocoder;
                if (GEOCODER.geocode) {
                    if (AngularUtils.isDevMode()) console.log("[GoogleMapsService] get geocode");
                    GEOCODER.geocode({ 'address': postalCode + " " + country }, (results, status) => {
                        if (AngularUtils.isDevMode()) console.log("[GoogleMapsService] geocode received");
                        resolve({
                            results,
                            status
                        });
                    });
                }
                else {
                    resolve(undefined);
                }
            }).catch((ex) => {
                reject(ex);
            })
        });
    }
}
