<!-- Declare the template like the tab.html does: https://github.com/angular/components/blob/main/src/material/tabs/tab.html -->
<ng-template>
    <div class="tab-header">

        <!-- <mat-icon>info</mat-icon> -->
        <div class="header">
            <h2>
                <span i18n="@@onboarding-checklist-title">
                    todo
                </span>
            </h2>
        </div>

        <span class="spacer">
        </span>


    </div>

    <div class="app-property-list-container">
        <div class="checklist-checkbox-item"
             *ngFor="let item of items">
            <button *ngIf="!itemsToHide[item.name]"
                    mat-button
                    class="checklist-button"
                    (click)="onChecklistClick(item)">
                <mat-icon *ngIf="item?.isChecked()">check_box</mat-icon>
                <mat-icon *ngIf="!item?.isChecked()">check_box_outline_blank</mat-icon>
                <span class="checklist-button-text">{{item.label}}</span>
            </button>
        </div>
        <app-checklist-popover checklistItem="subscribed"></app-checklist-popover>

    </div>
</ng-template>