import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface ExportTypeSelectDialogData {
    title?: string,
    message?: string,
    selectMessage?: string,
    icon?: string
}

@Component({
    selector: 'app-export-type-select-dialog',
    templateUrl: './export-type-select-dialog.component.html',
    styleUrls: ['./export-type-select-dialog.component.scss']
})
export class ExportTypeSelectDialogComponent implements OnInit {

    public title: string = $localize`:@@export-type-select-default-title:Export Format`;
    public message: string = $localize`:@@export-type-select-default-subtitle:Please choose your export format:`;
    public selectMessage: string = "";
    public icon: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ExportTypeSelectDialogData,
        private mdDialogRef: MatDialogRef<ExportTypeSelectDialogComponent, string>) {
        if(this.data.title) this.title = this.data.title;
        if(this.data.message) this.message = this.data.message;
        if(this.data.selectMessage) this.selectMessage = this.data.selectMessage;
        if(this.data.icon) this.icon = this.data.icon;
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: ExportTypeSelectDialogData = {}, config: AppDialogConfig = {}) {

        return new AppDialogRef<ExportTypeSelectDialogComponent, ExportTypeSelectDialogData, string>(dialogService, dialog, ExportTypeSelectDialogComponent,             {
            data,
            width: "700px",
            maxWidth: "700px",
            minWidth: "300px",
            maxHeight: "calc(100vh - 64px)",
            position: {
                top: "52px",
            },
            panelClass: 'makeItMiddle'
        }, config);
    }

    exportResultTable(bookType: string) {
        this.mdDialogRef.close(bookType);
    }
}
