<div class="products-order-card product-selection"
     [ngClass]="{'no-labels':!showLabels}">
    <h3 i18n="@@products-select-choose-subscription-period"
        class="no-margin"
        *ngIf="!readonly && showLabels && (subscriptionType == SubscriptionType.organization)">Choose the subscription period</h3>
    <mat-button-toggle-group *ngIf="!readonly && (subscriptionType == SubscriptionType.organization)"
                             class="app-button-toggle--full-width subscription-plan-selection"
                             name="fontStyle"
                             aria-label="Font Style"
                             [(ngModel)]="subscriptionPlan">
        <mat-button-toggle *ngFor="let plan of plans"
                           (change)="onchangeSubscriptionPlan($event);"
                           [value]="plan">
            {{getPlanLabel(plan)}}
            <span class="subscription-plan-discount secondary-text"
                  *ngIf="plan == SubscriptionPlan.halfYearly"
                  i18n="@@subscription-plan-halfyearly-discount">Save 20%</span>
            <span class="subscription-plan-discount secondary-text"
                  *ngIf="plan == SubscriptionPlan.yearly"
                  i18n="@@subscription-plan-yearly-discount">Save 30%</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="flex-row flex-default-gap"
         style="align-items: center;"
         *ngIf="showLabels || (!readonly && (subscriptionType == SubscriptionType.individual))">
        <h3 i18n="@@products-select-product-selection"
            class="no-margin"
            *ngIf="showLabels">Select your products</h3>
        <span class="spacer"></span>
        <div class="individual-subscription-plan-slider"
             *ngIf="!readonly && (subscriptionType == SubscriptionType.individual)">
            <span class="monthly-plan">{{getPlanLabel(SubscriptionPlan.monthly)}}</span>
            <mat-slide-toggle class="normal-slide-toggle"
                              [checked]="yearlyChecked"
                              (change)="onIndividualSubscritionPlanToggle($event)"></mat-slide-toggle>
            <!-- <span>{{getPlanLabel(SubscriptionPlan.yearly)}}</span> -->
            <span i18n="@@products-order-card--yearly-save-30-percent">Annual. Save 30%</span>
        </div>
    </div>
    <div class="products">
        <div class="products-show"
             *ngFor="let product of getProducts(); let i = index">
            <app-product-order-card [product]="product"
                                    [subscriptionPlan]="subscriptionPlan"
                                    (onUpdate)="onProductCardUpdate($event)"
                                    [layout]="readonly ? Layout.Summary : Layout.Selection"></app-product-order-card>
        </div>
        <div class="error-container"
             *ngIf="!isHiddenSeatError"
             i18n="@@org-payment-product-error-message">
            minimum {{minimumSeats}} license
        </div>
    </div>
</div>