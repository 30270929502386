import { Model, ID, Member, ArrayMember } from '@uon/model';

@Model()
export class RedirectLink {
    
    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    name: string;

    @Member()
    shortcut: string;

    @Member()
    link: string;

    @Member()
    visits: number;
}

