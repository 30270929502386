import { Component, Input, OnInit } from '@angular/core';
import { Classroom, ClassroomStudentsImportResponse, FieldValidationType, Student } from '@applogic/model';
import { FormService } from 'src/app/services/form.service';

class StudentEntry extends Student {
    warning?: string;
    warnings?: { [key: string]: string };
    isValid: boolean;
}

@Component({
    selector: 'app-import-students-tab',
    templateUrl: './import-students-tab.component.html',
    styleUrls: ['./import-students-tab.component.scss']
})
export class ImportStudentsTabComponent implements OnInit {

    @Input()
    classroom: Classroom;

    entries: StudentEntry[] = [];

    isValid: boolean = false;

    invalidEntries: { [key: string]: StudentEntry } = {};

    validCount: number = 0;
    invalidCount: number = 0;

    alreadyTakenNames: {[key: string]: boolean} = {};

    constructor(private formService: FormService) {
        for (let i = 0; i < 10; i++) {
            this.entries.push(new StudentEntry());
        }
    }

    ngOnInit(): void {
    }

    onFullNameChange(entry: StudentEntry, idx: number, evt: any) {
        entry.fullName = evt.target.value;
        this.onRowUpdate(entry, idx);
    }

    onUserNameChange(entry: StudentEntry, idx: number, evt: any) {
        entry.username = evt.target.value;
        this.onRowUpdate(entry, idx);
    }

    onPasswordChange(entry: StudentEntry, idx: number, evt: any) {
        entry.password = evt.target.value;
        this.onRowUpdate(entry, idx);
    }

    onRowUpdate(entry: StudentEntry, idx: number) {
        if (idx == this.entries.length - 1) {
            this.entries.push(new StudentEntry());
        }
        this.validateEntry(entry, idx);
    }

    validateEntry(entry: StudentEntry, idx: number) {

        let isValid: boolean = false;

        if (!entry.fullName && !entry.username && !entry.password) {
            entry.warning = undefined;
            entry.warnings = undefined;
            isValid = undefined;
            delete this.invalidEntries[idx];
        }
        else {
            entry.warning = undefined;
            entry.warnings = undefined;
            isValid = true;

            const fields = [
                FieldValidationType.FullName, 'fullName',
                FieldValidationType.ProfileName, 'username',
                FieldValidationType.StudentPassword, 'password'];

            delete this.invalidEntries[idx];

            for (let i = 0; i < fields.length; i += 2) {
                const fieldKey = fields[i];
                const propKey = fields[i + 1];

                const errMsg = this.formService.validate(entry, fieldKey, entry[propKey], true);

                if(errMsg) {
                    this.isValid = false;
                    if(entry.warnings == undefined) entry.warnings = {};
                    entry.warnings[propKey] = errMsg;
                    entry.warning = errMsg;
                    this.invalidEntries[idx] = entry;
                    isValid = false;
                }
            }
        }

        if(isValid) {
            const student = this.classroom.students.find(s => s.username == entry.username);
            if(student || this.alreadyTakenNames[entry.username]) {
                const errMsg = $localize`:@@form-field-username-already-exists:Profile name already taken`
                entry.warning = errMsg;
                if(entry.warnings == undefined) entry.warnings = {};
                entry.warnings['username'] = errMsg;
                isValid = false;
                this.invalidEntries[idx] = entry;
                console.log("Username: " + JSON.stringify(entry));
            }
        }

        this.setValid(entry, isValid);

        this.isValid = Object.keys(this.invalidEntries).length == 0;
    }

    setValid(entry: StudentEntry, isValid: boolean) {
        if(entry.isValid === true) {
            this.validCount--;
        }
        else if(entry.isValid === false) {
            this.invalidCount--;
        }

        entry.isValid = isValid;

        if(isValid === true) {
            this.validCount++;
        }
        else if(isValid === false) {
            this.invalidCount++;
        } 
    }

    onPaste(entry: StudentEntry, idx: number, event: any) {
        let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
        let pastedText: string = clipboardData.getData('text');
        if (!pastedText) return;

        const lines = pastedText.split(/\r?\n/);

        let i = idx;

        for (const line of lines) {
            if (line.trim().length == 0) continue;

            const elements = line.split(/\t|;|,/)

            while (i >= this.entries.length) {
                this.entries.push(new StudentEntry());
            }

            const e = this.entries[i];

            if (elements.length > 0) {
                e.fullName = elements[0].trim();
            }

            if (elements.length > 1) {
                e.username = elements[1].trim();
            }

            if (elements.length > 2) {
                e.password = elements[2].trim();
            }

            this.validateEntry(e, i);

            i++;
        }

        return false;
    }

    setResponse(res: ClassroomStudentsImportResponse) {
        for(const name of res.nameAlreadyTaken) {
            this.alreadyTakenNames[name] = true;

            const entryIdx = this.entries.findIndex(e => e.username == name);
            if(entryIdx != -1) {
                this.validateEntry(this.entries[entryIdx], entryIdx);
            }
        }
    }
}
