import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { AuthService } from '../auth.service';
import { FormService } from 'src/app/services/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { SocialLoginData } from 'src/app/social/social-login-data';
import { RegisterSSOQuery, RegisterUserForm } from '@applogic/model';
import { Model } from '@uon/model';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss', '../common-login-register.scss']
})
export class RegisterPageComponent implements OnInit {

    errorObject: string = null;
    wasClickedOnce: boolean = false;

    constructor(readonly loading: LoadingService,
        readonly authService: AuthService,
        readonly formService: FormService,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
    }

    submitForm(formData: UntypedFormGroup) {
        this.errorObject = null;
        this.wasClickedOnce = true;

        if(formData.invalid) {
            for (let k in formData.controls) {
                formData.controls[k].markAsTouched();
            }
            return;
        }

        this.authService.tryRegister(Model.New(RegisterUserForm, formData.value), "registration form").subscribe(() => {
            this.onSuccess();
        }, (err) => {
            console.log("ex: " + JSON.stringify(err));
            this.errorObject = err;
        });
    }

    onSuccess() {
        let navigateTo: string = this.route.snapshot.queryParamMap.get('navigateTo');

        if (!navigateTo) {
            this.router.navigate(['', 'account']);
        }
        else {
            navigateTo = decodeURIComponent(navigateTo);
            this.router.navigateByUrl(navigateTo, { replaceUrl: true })
                .then((val) => {
                });
        }
    }

    onServiceLoginClick(response: SocialLoginData) {
        this.errorObject = undefined;

        
        this.authService.tryRegisterSSO(Model.New(RegisterSSOQuery, {
            token: response.data,
            serviceType: response.type
        }), "registration form").subscribe(() => {
            this.onSuccess();
        }, (err) => {
            console.log("ex: " + JSON.stringify(err));
            this.errorObject = err;
        });
    }

}
