<mat-progress-bar *ngIf="loading.loadingChange | async"
                  @fadeInOut
                  mode="indeterminate"
                  class="global-progress-bar">
</mat-progress-bar>
<mat-drawer-container class="full-width">
    <mat-drawer [class]="isAuthenticated ? '' : 'hidden-element'"
                autoFocus="false"
                [disableClose]="false"
                [opened]="nav.menuOpened | async"
                (openedChange)="nav.onOpenedChange($event)"
                [mode]="nav.menuMode | async">
        <app-main-nav></app-main-nav>
    </mat-drawer>
    <mat-drawer-content>
        <div class="contents"
             #contents
             cdkScrollable
             [@routerTransition]="o.isActivated ? o.activatedRoute : ''">
            <router-outlet #o="outlet"></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<router-outlet name="print"></router-outlet>