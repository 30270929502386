import { Model, ID, Member } from '@uon/model';
import { SubscriptionPlan } from '../subscription/subscription.model';
import { Address } from '../common/address.model';
import { User } from '../user/user.model';
import { Organization, OrganizationTitle } from '../org/organization.model';
import { PriceTable } from '../order/price-table';

@Model()
export class QuotationOrganization {

    @Member()
    name: string;

    @Member()
    address: Address;

    @Member()
    title: OrganizationTitle;

    @Member()
    otherTitle: string;
}


@Model()
export class Quotation {

    @ID()
    id: string;

    @Member()
    quotationNo: string;

    @Member()
    fullName: string;

    @Member()
    email: string;

    @Member()
    user: User;

    @Member()
    adminUser: User;
   
    @Member()
    org: Organization;

    @Member()
    organization: QuotationOrganization;

    @Member()
    plan: SubscriptionPlan;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    expiryDate: Date;

    @Member()
    priceTable: PriceTable;

}