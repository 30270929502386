import { Component } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';


@Component({
    selector: 'app-theme-switch',
    templateUrl: './theme-switch.component.html',
    styleUrl: './theme-switch.component.scss'
})
export class ThemeSwitchComponent {

    selectedTheme: string;

    constructor(protected themeService: ThemeService) {
        this.selectedTheme = themeService.theme;
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    onThemeSelect() {
        this.themeService.setTheme(this.selectedTheme);
    }
}
