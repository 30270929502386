

import { Model, ID, Member, ArrayMember } from '@uon/model';
import { OrganizationAddress, BillingAddress } from './address.model';

import { User } from '../user/user.model';


export enum OrganizationType {
    School = "school",
    SchoolBoard = "schoolboard",
    ServiceCenter = "servicecenter",
    ServiceDistrict = "schooldistrict",
    Trials = "trials",
    Other = "other",
}

export enum OrganizationRole {
    Administrator = "admin",
    Member = "member"
}

export enum OrganizationTitle {
    Teacher = "teacher",
    Principal = "principal",
    Secretary = "secretary",
    Professional = "professional",
    Remedial = "remedial",
    Councillor = "councillor",
    Other = "other"
}

export enum ProductStatus {
    Unknown = "unknown",
    Trial = "trial",
    Purchased = "purchased",
    Subscribed = "subscribed"
}
@Model()
export class OrgProductStatus {
    @Member()
    id: string;

    @Member()
    status: ProductStatus;

    @Member()
    expiresOn: Date;

    @Member()
    isOverridden: boolean;
}


@Model()
export class OrganizationMember {

    @Member()
    user: User;

    @Member()
    role: OrganizationRole;

    @Member()
    title: OrganizationTitle;

    @Member()
    otherTitle: string;

    @Member()
    addedOn: Date;
}


@Model()
export class Organization {

    /**
     * 
     * @param _id 
     */
    constructor(_id?: string) {
        this.id = _id;
    }
    
    @ID()
    id: string;

    @Member()
    name: string;

    @Member()
    address: OrganizationAddress;

    @Member()
    billingAddress: BillingAddress;

    @Member()
    type: OrganizationType;

    @Member()
    typeOther: string;

    @Member()
    parentOrganization: Organization;

    @ArrayMember(OrganizationMember)
    staffMembers: OrganizationMember[];

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @ArrayMember(String)
    subs?: any[]
    
    @Member()
    isDeleted?: boolean;

    @Member()
    productStatus: {[key:string]:OrgProductStatus}

    @Member()
    deletedAt:Date;

    // Denormalized data

    // TODO: Seems to be denormalized in the frontend. Could be done in the backend instead.
    //       Add also add the underscore.
    @Member()
    role?: OrganizationRole;

    // Staff member of the current user.
    @Member()
    _staffMember: OrganizationMember;

    @Member()
    _memberCount: number;

}