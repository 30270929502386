import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Student, SubAllocationsResponse, SubscriptionAllocation, UserAllocationsResponse } from '@applogic/model';
import { SeatService } from 'src/app/services/seat.service';
import { AssignTokensPanelComponent } from '../assign-tokens-panel/assign-tokens-panel.component';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface AssignTokensDialogData {
    student: Student,
    userAllocations: UserAllocationsResponse,
    errorObject?: any;
}

@Component({
    selector: 'app-assign-tokens-dialog',
    templateUrl: './assign-tokens-dialog.component.html',
    styleUrls: ['./assign-tokens-dialog.component.scss']
})
export class AssignTokensDialogComponent implements OnInit {

    student: Student;
    userAllocations: UserAllocationsResponse;

    @ViewChild(AssignTokensPanelComponent, { static: false })
    tokensPanel: AssignTokensPanelComponent;

    errorObject: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: AssignTokensDialogData,
        private mdDialogRef: MatDialogRef<AssignTokensDialogComponent>,
        private seatService: SeatService) {
        this.student = data.student;
        this.userAllocations = data.userAllocations;
        this.errorObject = data.errorObject;
    }

    ngOnInit(): void {

    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: AssignTokensDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<AssignTokensDialogComponent, AssignTokensDialogData>(dialogService, dialog, AssignTokensDialogComponent, {
            data,
            width: "40%",
            maxWidth: "80%",
            minWidth: "40%",
            autoFocus: false, // We don't want the first radio button to be gray.
            panelClass: 'makeItMiddle'
        },
        config);
    }

    assignTokens() {
        this.tokensPanel.assignTokens().then((seat) => {
            this.mdDialogRef.close();
        }).catch((ex) => {
            this.errorObject = ex;
        });
        
    }
}
