<app-common-list-renderer [list]="list"
                          #classroomList>
    <renderer-templates [emptyTemplate]="emptySection"
                        [noResultTemplate]="noResultTemplate"
                        [templates]="[nbStudentsTemplate, yourRoleTemplate]">
        <app-classroom-card-template></app-classroom-card-template>
    </renderer-templates>
    <app-common-list-renderer-options [showDisplayMode]="mode == 'list'">
    </app-common-list-renderer-options>
    <app-common-list-toolbar [alignLeft]="true">
        <button *ngIf="canCreateClass"
                mat-stroked-button
                color="accent"
                [disabled]="account.currentOrg == null"
                (click)="openNewClassroomDialog()"
                common-list-toolbar-option>
            <span i18n="@@common-create-classroom">Create classroom</span>
        </button>
    </app-common-list-toolbar>
    <button *ngIf="canCreateClass"
            mat-menu-item
            color="accent"
            [disabled]="account.currentOrg == null"
            (click)="openRemoveClassroomDialog(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="warn">delete</mat-icon>
        <span i18n="@@common-remove">Remove</span>
    </button>
</app-common-list-renderer>

<ng-template #emptySection>
    <ng-container *ngIf="orgId">
        <span i18n="@@org-classes-empty">
            You don't have classrooms yet.
        </span>
    </ng-container>

    <!-- For the global classrooms section -->
    <ng-container *ngIf="!orgId">
        <h2 i18n="@@my-classrooms-new-title">You don't have a classroom yet.</h2>

        <p i18n="@@my-classrooms-new-paragraph">
            Create a classroom by clicking the button at the top left. If you should be part of a class that already exists, ask its administrator to invite you as a collaborator.
        </p>
    </ng-container>
</ng-template>

<ng-template #noResultTemplate>
    <p style="text-align: center;">
        <em class="secondary-text"
            i18n="@@search-no-result">
            No result
        </em>
        <br>
        <br>
    </p>
</ng-template>

<ng-template #nbStudentsTemplate
             let-data>
    <span *ngIf="classroomList.displayMode == CommonListDisplayMode.Default"
          class="secondary-text">{{data.row._studentsCount || 0}}</span>
    <span *ngIf="classroomList.displayMode == CommonListDisplayMode.Card"
          class="secondary-text"
          i18n="@@classroom-student-count">{ data.row._studentsCount, plural, =0 {No students} =1 {1 student} other { {{data.row._studentsCount}} students } }</span>
</ng-template>

<ng-template #yourRoleTemplate
             let-data>
    <app-classroom-role-chip [classroom]="data.row"></app-classroom-role-chip>
</ng-template>
