import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { CommonListColumn } from '../common-list-column';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface CommonListColumnsDialogData {
    columns: CommonListColumn[]
}
@Component({
    selector: 'app-common-list-columns-dialog',
    templateUrl: './common-list-columns-dialog.component.html',
    styleUrls: ['./common-list-columns-dialog.component.scss']
})
export class CommonListColumnsDialogComponent implements OnInit {

    columns: CommonListColumn[] = [];

    selected: {[key: string]: boolean} = {};

    needsRefreshItems: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: CommonListColumnsDialogData,
                private mdDialogRef: MatDialogRef<CommonListColumnsDialogComponent>) {
        this.columns = data.columns;

        this.columns.forEach((c) => {
            if(c.selected) {
                this.selected[c.columnKey] = true;
            }
        });
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: CommonListColumnsDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<CommonListColumnsDialogComponent, CommonListColumnsDialogData, CommonListColumnsDialogComponent>(dialogService, dialog, CommonListColumnsDialogComponent,             {
            data,
            maxWidth: "90%",
            panelClass: 'makeItMiddle',
            autoFocus: false,
        }, config);
    }

    cancel() {
        this.mdDialogRef.close();
    }

    apply() {
        this.needsRefreshItems = false;

        for(const column of this.columns)
        {
            const selected = this.selected[column.columnKey] ? true : false;

            if(column.selected != selected) {
                column.selected = selected;
                if(selected && column.refreshItemsOnSelect) {
                    this.needsRefreshItems = true;
                }
            }
        }

        this.mdDialogRef.close(this);
    }

    reset() {
        for(const column of this.columns)
        {
            this.selected[column.columnKey] = column.selectedByDefault;
        }
    }

    onColumnCheck(column: CommonListColumn, event: any) {
        this.selected[column.columnKey] = event.checked;
    }
}
