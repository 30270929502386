import { Component } from '@angular/core';
import { Classroom } from '@applogic/model';
import { CardTemplateComponent } from 'src/app/core/common-list/common-list-renderer-templates/components/card-template/card-template.component';


@Component({
    selector: 'app-classroom-card-template',
    templateUrl: './classroom-card-template.component.html',
    styleUrl: './classroom-card-template.component.scss'
})
export class ClassroomCardTemplateComponent extends CardTemplateComponent<Classroom> {

}
