import { Member, Model } from "@uon/model";
import { SubscriptionPlan, SubscriptionType } from "../subscription/subscription.model";

@Model()
export class SelectedProduct {

    @Member()
    code: string;

    @Member()
    subscriptionType: SubscriptionType;

    @Member()
    subscriptionPlan: SubscriptionPlan;
    
    @Member()
    stripePriceId: string;

    @Member()
    seats: number;

    /**
     * Convert untyped list of products receive from a post data to a typed list of products.
     * 
     * @param products
     * @param data 
    */
    public static LoadProductsFromFormData(formdata: any, plan: SubscriptionPlan) : SelectedProduct[] {
        let products: SelectedProduct[] = [];

        for (var i = 0, len = formdata.products.length; i < len; i++) {
            let pAny: any = formdata.products[i];
            let p: SelectedProduct = new SelectedProduct();
            p.code = pAny.code;
            p.subscriptionType = pAny.type;
            p.seats = pAny.seats;
            p.subscriptionPlan = plan;
            p.stripePriceId = pAny.stripePriceId;
            products.push(p);
        }

        return products;
    }

    /**
     * Save the products list to be used in stripe with the metadata.
     * 
     * @param products
     * @param data 
    */
    public static SaveProducts(products: SelectedProduct[], data: any): void {
        data.productCount = products.length;
        for(let i=0; i<products.length; i++) {
            data["product" + (i+1)] = JSON.stringify(products[i]);
        }

    }

    /**
     * Save the products list to be used in stripe with the metadata.
     * 
     * @param data 
     * 
     * @returns {SelectedProduct[]} Return the list of products.
    */
    public static LoadProducts(data: any) : SelectedProduct[] {
        let result: SelectedProduct[] = [];

        let count: number = data.productCount;

        for(let i=0; i<count; i++) {
            let pdata: any = data["product" + (i+1)];
            let p: SelectedProduct = new SelectedProduct();
            Object.assign(p, JSON.parse(pdata));
            result.push(p);
        }

        return result;
    }
}