import { Model, ID, Member, ArrayMember, MinLength, MaxLength, ValidateEmail, JsonSerializer } from '@uon/model';
import { FieldValidations } from '../common/field.validations';
import { UserEmailCategory } from './user-email-category';

@Model()
export class UserEmailPreferences {

    // If false, all no email will be sent.
    @Member()
    opt: boolean;

    @Member()
    newsletters: boolean;

    @Member()
    gettingStarted: boolean;

    @Member()
    updatedOn: Date;

    @Member()
    errorStatus: string;

    public constructor() {

    }

    public static fromData(data: any): UserEmailPreferences {
        return USER_EMAIL_PREFS_SERIALIZER.deserialize(data);
    }

    public setAll(): void {
        this.opt = true;
        this.newsletters = true;
        this.gettingStarted = true;
    }

    public setUndefinedToDefault() {
        if(this.opt == null) {
            this.opt = true;
        }

        if(this.newsletters == null) {
            this.newsletters = true;
        }

        if(this.gettingStarted == null) {
            this.gettingStarted = true;
        }
    }

    public check(category: UserEmailCategory): boolean {
        if( (category == UserEmailCategory.Always) || (category == UserEmailCategory.System) ) {
            return true;
        }
        else if(category == UserEmailCategory.GettingStarted) {
            if (this.gettingStarted === false) {
                return false;
            }
        }
        else if(category == UserEmailCategory.Newsletters) {
            if (this.newsletters === false) {
                return false;
            }
        }

        return (this.opt === undefined) || (this.opt === true);
    }
}

const USER_EMAIL_PREFS_SERIALIZER = new JsonSerializer(UserEmailPreferences);