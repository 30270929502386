import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PromoCodeTicket, SubscriptionPlan, SubscriptionType } from '@applogic/model';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
    selector: 'app-promo-code-input',
    templateUrl: './promo-code-input.component.html',
    styleUrl: './promo-code-input.component.scss'
})
export class PromoCodeInputComponent {

    @Input()
    subscriptionType: SubscriptionType;

    @Input()
    subscriptionPlan: SubscriptionPlan;

    @Input()
    promoCodeValue: string = "";

    @Output() promoCodeChangedEvent: EventEmitter<PromoCodeTicket> = new EventEmitter();

    @HostBinding('class.hidden-feature')
    hiddenFeature: boolean = true;

    isPromoCodeApplied: boolean = false;


    promoCodeErrorMessage: string = "";

    promoCodeTicket: PromoCodeTicket;
    
    constructor(
        private promoCodeService: PromoCodeService
    ) {

    }

    ngOnInit(): void {
        if (this.promoCodeValue.length) {
            this.applyPromo();
        }
    }


    applyPromo() {
        this.promoCodeService.applyPromoCode(this.promoCodeValue, {
            type: this.subscriptionType,
            plan: this.subscriptionPlan
        }).then((res) => {
            this.promoCodeTicket = res;
            this.isPromoCodeApplied = true;
            this.promoCodeChangedEvent.emit(this.promoCodeTicket );
            this.promoCodeErrorMessage = "";
        }).catch((error) => {
            this.promoCodeErrorMessage = error;
        })
    }

    onChangePromoCode() {
        if (!this.promoCodeValue.length) {
            this.promoCodeErrorMessage = "";
        }
    }

    removePromoCode() {
        this.isPromoCodeApplied = false;
        this.promoCodeValue = "";
        this.promoCodeChangedEvent.emit(undefined);
        this.promoCodeTicket = undefined;
    }
}
