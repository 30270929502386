<app-dialog icon="payment">
    <app-dialog-title i18n="@@payment-method-select-dialog-title">Choose a payment method</app-dialog-title>
    <app-dialog-content>
        <app-payment-method-select-panel [paymentTarget]="paymentTarget"
                                         [(paymentMethodId)]="paymentMethodId"></app-payment-method-select-panel>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="accent"
            [disabled]="!paymentMethodId || (paymentSelectPanel?.currentMethodId == paymentMethodId) || loading.isLoading || (loading.loadingChange | async)"
            (click)="selectOrCreate()">

        <span *ngIf="paymentMethodId != 'NEW'"
              i18n="@@payment-method-select-dialog-select-button">Select</span>
        <span *ngIf="paymentMethodId == 'NEW'"
              i18n="@@payment-method-select-dialog-create-button">Create</span>
    </button>
</app-dialog>