import { Model, Member, ArrayMember } from "@uon/model";
import { Subscription, SubscriptionPlan, SubscriptionType } from "./subscription.model";
import { UserType } from "../user/user.model";
import { Organization } from "../org/organization.model";
import { Classroom } from "../org/classroom.model";
import { OrderForm } from "../order/order-form";

@Model()
export class GetLastSubscriptionQuery {

    @Member()
    userId?: string;

    @Member()
    userType?: UserType;

    @Member()
    productCode?: string;

    @Member()
    details?: boolean;

    @ArrayMember(String)
    plans?: SubscriptionPlan[];

    @Member()
    type?: SubscriptionType;
}

@Model()
export class GetLastSubscriptionReponse {

    @Member()
    subscription?: Subscription;

}

@Model()
export class GetSubscriptionReponse {

    @Member()
    subscription?: Subscription;

}

@Model()
export class CreateTrialSubscriptionResponse {

    @Member()
    organization: Organization;

    @Member()
    subscription: Subscription;

    @Member()
    classroom: Classroom;

    @Member()
    orderForm: OrderForm;
}

@Model()
export class GetOrderFormQuery {

    @Member()
    checkoutSessionId?: string;

    @Member()
    invoiceId?: string;
    
    @Member()
    quotationId?: string;

    @Member()
    subscriptionId?: string;

}

@Model()
export class SendSubscriptionAutoRenewNotificationsQuery {

    @Member()
    testmode: boolean;
}

@Model()
export class SendSubscriptionAutoRenewNotificationsResponse {

    @Member()
    nbNotifications: number;

    @ArrayMember(String)
    subscriptionIdList: string[];

    @ArrayMember(String)
    deliveryIdList: string[];
}