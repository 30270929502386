import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-toolbar-content',
    templateUrl: './toolbar-content.component.html',
    styleUrls: ['./toolbar-content.component.scss']
})
export class ToolbarContentComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
