import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserEmailPrefsComponent } from './user-email-prefs/user-email-prefs.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/**
 * Module for components shared between the AuthModule and the AccountModule.
 */
@NgModule({
    declarations: [UserEmailPrefsComponent],
    imports: [
        CommonModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule
    ],
    exports: [
        UserEmailPrefsComponent
    ]
})
export class AuthAndAccountModule { }
