<div class="user-card app-property-list-container">
    <div class="list-item">
        <div class="avatar">
            <app-user-avatar [user]="user"></app-user-avatar>
        </div>

        <div class="staff-info-block">
            <span class="name-role">
                <span>
                    {{ user.fullName }}
                </span>
                <small class="secondary-text db">
                    {{ user.email }}
                </small>
            </span>
        </div>
    </div>
</div>