import { Model, Member } from "@uon/model";


@Model()
export class TranslatedValue<T> {

    @Member()
    language: string;

    @Member()
    value: T;

}