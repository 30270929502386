
import { Model, ID, Member } from '@uon/model';

export interface CurrencyOptions {
    countryCode?: string;
    languageCode?: string;
    narrowSymbol?: boolean;
}

@Model()
export class Currency {

    @ID()
    id: string;

    @Member()
    enabled: boolean;

    @Member()
    name: string;

    @Member()
    code: string;

    @Member()
    symbol: string;

    @Member()
    symbolCode: string;

    @Member()
    decimal_digits: number;

    @Member()
    format: string;

    // Some currency may round value to 5 cents, so rounding will be 0.05. Zero value means no rounding.
    @Member()
    rounding: number;

    isSymbolRight(options?: CurrencyOptions) {
        if(options) {
            if(options.countryCode == "ca") {
                return true;
            }
        }

        return false;
    }

    toText(amount: number, options?: CurrencyOptions) {
        let amountText: string = amount.toFixed(this.decimal_digits);

        let symbol: string;

        if((options != undefined) && (options.narrowSymbol != undefined) && !options.narrowSymbol) {
            symbol = this.symbol + this.code;
        } else {
            symbol = this.symbol;
        }

        let isSymbolRight: boolean = this.isSymbolRight(options);
        if(isSymbolRight) {
            return amountText + " " + symbol;
        }

        return symbol + " " + amountText;
    }

    toCodeWithSymbol(options?: CurrencyOptions) {
        let isSymbolRight: boolean = this.isSymbolRight(options);
        if(isSymbolRight) {
            return this.code + " " + this.symbol;
        }

        return this.symbol + " " + this.code;
    }

    /**
     * Convert the standart currency value to its corresponding smallest currency units. 
     * 
     * Used to send price update for Stripe.
     * 
     * @param {number} amount Amount in the standard currency value.
     */
    toSmallestUnit(amount: number) {
        if(this.decimal_digits) {
            return amount * (10 ** this.decimal_digits);
        }

        return amount;
    }

    /**
     * Convert the smallest currency units to its corresponding standart currency value. 
     * 
     * Used to read price received from Stripe.
     * 
     * @param {number} amount Amount in the smallest units currency value.
     */
    fromSmallestUnit(amount: number) {
        if(this.decimal_digits) {
            return amount / (10 ** this.decimal_digits);
        }
        
        return amount;
    }
}