<div class="flex-column flex-default-gap">
    <h2 *ngIf="invited && (count > 0)"
        class="invitation-title">
        <span i18n="@@collaborator-list--pending-invitations">Pending invitation(s)</span>
        <app-list-count-indicator [count]="count"></app-list-count-indicator>
    </h2>
    <app-common-list-renderer [list]="list"
                              *ngIf="!invited || (count > 0)">
        <renderer-templates [templates]="[userAvatar, userRole]"></renderer-templates>
        <button mat-menu-item
                *ngIf="!invited && selectedMenuActionItem && canAccessCollabContextualMenu(selectedMenuActionItem.id)"
                (click)="openRevokeAccessDialog(selectedMenuActionItem)"
                menuitems>
            <mat-icon color="warn">close</mat-icon>
            <span i18n="@@org-member-revoke-access-collaborate">Remove collaborator</span>
        </button>
        <button mat-menu-item
                (click)="openChangeOwnerShip(selectedMenuActionItem)"
                *ngIf="selectedMenuActionItem && canAccessCollabContextualMenu(selectedMenuActionItem.id) && !invited && !isUserOwner(selectedMenuActionItem.id)"
                menuitems>
            <mat-icon color="accent">person</mat-icon>
            <span i18n="@@collaborate-member-change-member-role-text">Transfer administration role</span>
        </button>
        <button mat-menu-item
                *ngIf="invited && selectedMenuActionItem && canAccessCollabContextualMenu(selectedMenuActionItem.id)"
                (click)="resendInvite(selectedMenuActionItem?.id)"
                menuitems>
            <mat-icon color="accent">person</mat-icon>
            <span i18n="@@common-resend-invitation">Resend invitation</span>
        </button>
        <button mat-menu-item
                *ngIf="invited && selectedMenuActionItem && canAccessCollabContextualMenu(selectedMenuActionItem.id)"
                (click)="deleteInvite(selectedMenuActionItem)"
                menuitems>
            <mat-icon color="warn">close</mat-icon>
            <span i18n="@@common-cancel-invitation">Cancel invitation</span>
        </button>
    </app-common-list-renderer>
</div>
<ng-template #userAvatar
             let-data>
    <div class="avatar">
        <app-user-avatar [user]="data.row">
        </app-user-avatar>
    </div>
</ng-template>

<ng-template #userRole
             let-data>
    <app-classroom-role-chip [classroom]="classroom"
                             [userId]="data.row.id"></app-classroom-role-chip>
</ng-template>