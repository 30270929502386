<app-dialog icon="person"
            *ngIf="student"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@classroom-edit-student-dialog-title">Edit student profile</app-dialog-title>
    <app-dialog-message i18n="@@classroom-edit-student-dialog-subtitle">Change <strong>{{ student.fullName }}</strong>'s information.</app-dialog-message>
    <app-dialog-content class="student-form">
        <app-base-login-register-form [formType]="'student-form'"
                                      [formData]="student"
                                      #stuForm>
        </app-base-login-register-form>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            [disabled]="!stuForm.baseLoginRegisterForm.valid || loading.isLoading || (loading.loadingChange | async)"
            (click)="submitStudentForm(stuForm.baseLoginRegisterForm, student)">
        <span i18n="@@common-save">Save</span>
    </button>
</app-dialog>
<app-dialog icon="person_add"
            *ngIf="!student"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@classroom-add-student-dialog-title">Add Students</app-dialog-title>
    <app-dialog-content>
        <mat-tab-group mat-align-tabs="start"
                       (selectedTabChange)="onTabChanged($event);">

            <!--
            MANUAL ADD
        -->
            <mat-tab>

                <ng-template mat-tab-label>
                    <span i18n="@@classroom-add-student-manually">Add</span>
                </ng-template>

                <p i18n="@@classroom-student-message">
                    Each student needs a unique profile name to access the games.
                </p>

                <div class="assign-seats-panel-section">
                    <div *ngIf="userAllocations"
                         i18n="@@add-student-dialog-select-tokens">First, select the token (s) you
                        are assigning to this student.</div>
                    <app-assign-tokens-panel *ngIf="userAllocations"
                                             class="assign-seats-panel"
                                             [student]="student"
                                             [userAllocations]="userAllocations"
                                             [hideEmptyAllocations]="true"
                                             [autoSelectOnePerProduct]="true">
                    </app-assign-tokens-panel>
                </div>

                <div class="student-form">
                    <app-base-login-register-form [formType]="'student-form'"
                                                  #stuForm></app-base-login-register-form>
                </div>

                <app-error-message-area [error]="errorObject"></app-error-message-area>

                <div class="action-section">

                    <button mat-stroked-button
                            (click)="closeStudentDialog()"
                            i18n="@@common-cancel">
                        Cancel
                    </button>

                    <span class="spacer"></span>

                    <button mat-flat-button
                            color="primary"
                            [disabled]="!stuForm.baseLoginRegisterForm.valid || loading.isLoading || (loading.loadingChange | async)"
                            (click)="submitStudentForm(stuForm.baseLoginRegisterForm, student)"
                            i18n="@@classroom-student-add-button">
                        <span>Add student</span>
                    </button>

                </div>

            </mat-tab>


            <!--
            BY INVITATION
        -->

            <mat-tab>

                <ng-template mat-tab-label>
                    <span i18n="@@classroom-add-student-invite">
                        Invite
                    </span>
                </ng-template>

                <div>
                    <p style="margin-bottom: unset;"
                       i18n="@@classroom-add-student-invite-message">
                        Invite your students to
                        <strong>{{ classroom.name }}</strong>
                        by sharing the link below with them,
                        their parent, or responsible adult.
                    </p>

                    <div class="info-box">

                        <mat-icon>info</mat-icon>
                        <em i18n="@@student-invite-sharing-notice">
                            Please note that anyone with this link will be able to join your classroom as a student.
                        </em>

                    </div>

                    <div class="invitation-link">

                        <input #studentInvitationUrlSrc
                               type="text"
                               [value]="studentInviteLink"
                               readonly>

                        <button mat-stroked-button
                                color="accent"
                                (click)="copyLinkToClipboard()">

                            <mat-icon>link</mat-icon>
                            <span i18n="@@classroom-invite-student-copy-link-short-label">Copy</span>

                        </button>

                    </div>

                    <div class="qr-box">
                        <div>
                            <qrcode [qrdata]="studentInviteLink"
                                    [width]="256"
                                    [errorCorrectionLevel]="'M'"></qrcode>
                        </div>
                    </div>

                </div>

                <app-error-message-area [error]="errorObject"></app-error-message-area>
                <div style="display: flex; margin-top: 16px">
                    <span class="spacer"></span>

                    <button mat-stroked-button
                            (click)="closeStudentDialog()"
                            i18n="@@common-close">
                        Close
                    </button>

                </div>

            </mat-tab>


            <!--
            BY IMPORTING STUDENTS LIST
            -->
            <mat-tab>

                <ng-template mat-tab-label>
                    <span i18n="@@classroom-import-students">
                        Import
                    </span>
                </ng-template>

                <div>
                    <p i18n="@@classroom-import-students-message">
                        Copy and paste a table of your students from Excel or a csv file. Separators are tab, comma, semicolon. Each student must be on a separate line.
                    </p>

                    <app-import-students-tab [classroom]="classroom"></app-import-students-tab>

                </div>

                <app-error-message-area [error]="errorObject"></app-error-message-area>
                <div style="display: flex; margin-top: 16px">
                    <button mat-stroked-button
                            matDialogClose
                            i18n="@@common-cancel">
                        Cancel
                    </button>

                    <span class="spacer"></span>

                    <button mat-flat-button
                            color="primary"
                            [disabled]="!importStudentsTab || !importStudentsTab?.isValid || (importStudentsTab?.validCount == 0)"
                            (click)="importStudents()">
                        <span i18n="@@common-import-button"> Import </span>
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </app-dialog-content>
</app-dialog>


<!--
INVITATION COPIED SNACKBAR
-->
<ng-template #studentLinkCopiedSnackbar
             let-data>

    <span i18n="@@classroom-student-invite-link-copy-snackbar-message">
        Invitation link copied to clipboard.
    </span>

</ng-template>

<!--
INVITATION CREATED SNACKBAR
-->
<ng-template #studentLinkCreatedSnackbar
             let-data>

    <span i18n="@@classroom-student-invite-created-snackbar-message">
        Invitation link successfully created.
    </span>

</ng-template>