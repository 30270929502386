import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Classroom, Student, SubAllocationsResponse, UserAllocationsResponse } from '@applogic/model';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { ClassroomService } from '../classroom.service';
import { environment } from "src/environments/environment";
import { AssignTokensPanelComponent } from '../assign-tokens-panel/assign-tokens-panel.component';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { AssignTokensDialogComponent } from '../assign-tokens-dialog/assign-tokens-dialog.component';
import { SeatService } from 'src/app/services/seat.service';
import { ImportStudentsTabComponent } from './import-students-tab/import-students-tab.component';
import { Model } from '@uon/model';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface AddStudentDialogData {
    classroom: Classroom,
    student?: Student,
    userAllocations?: UserAllocationsResponse
}

@Component({
    selector: 'app-add-student-dialog',
    templateUrl: './add-student-dialog.component.html',
    styleUrls: ['./add-student-dialog.component.scss']
})
export class AddStudentDialogComponent implements OnInit {

    errorObject: any;

    classroom: Classroom;
    student: Student;

    userAllocations: UserAllocationsResponse;

    studentInviteLink: string = "";

    @ViewChild("studentInvitationUrlSrc", { static: false })
    studentInvitationUrlSrc: ElementRef<HTMLInputElement>;

    @ViewChild("studentLinkCopiedSnackbar", { static: true })
    studentLinkCopiedSnackbar: TemplateRef<any>;

    @ViewChild("studentLinkCreatedSnackbar", { static: true })
    studentLinkCreatedSnackbar: TemplateRef<any>;

    @ViewChild(AssignTokensPanelComponent, { static: false })
    tokensPanel: AssignTokensPanelComponent;

    @ViewChild(ImportStudentsTabComponent, { static: false })
    importStudentsTab: ImportStudentsTabComponent;

    constructor(@Inject(MAT_DIALOG_DATA) public data: AddStudentDialogData,
        private mdDialogRef: MatDialogRef<AddStudentDialogComponent>,
        public classroomService: ClassroomService,
        readonly loading: LoadingService,
        private snackbar: MatSnackBar,
        private languageService: LanguageService,
        private errorDialogService: HandleErrorDialogService,
        private dialog: MatDialog,
        private seatService: SeatService,
        private dialogService: DialogService) {
        this.student = data.student;
        this.classroom = data.classroom;
        this.userAllocations = data.userAllocations;
    }

    ngOnInit(): void {
        this.updateStudentLink();
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: AddStudentDialogData, config: AppDialogConfig = {}) {

        if (data.student) {
            return new AppDialogRef<AddStudentDialogComponent, AddStudentDialogData, Student[]>(dialogService, dialog, AddStudentDialogComponent,
                {
                    data,
                    maxWidth: "960px",
                    minWidth: "300px",
                    maxHeight: "calc(100vh - 64px)",
                },
                config);
        } else {
            return new AppDialogRef<AddStudentDialogComponent, AddStudentDialogData, Student[]>(dialogService, dialog, AddStudentDialogComponent,
                {
                    data,
                    width: "650px",
                    maxWidth: "650px",
                    minWidth: "300px",
                    maxHeight: "calc(100vh - 64px)",
                    position: {
                        top: "52px",
                    },
                },
                config);
        }
    }

    submitStudentForm(formData: UntypedFormGroup, student?: Student) {
        this.errorObject = undefined;
        const stu = student
            ? Model.New(Student, (student as any).toJSON())
            : new Student();
        Model.Assign(stu, formData.value);
        (student
            ? this.classroomService.patchStudent(this.classroom, stu)
            : this.classroomService.createStudent(this.classroom, stu)
        ).subscribe(
            (s) => {
                if (this.userAllocations) {
                    this.tokensPanel.assignTokens(s).then(() => {
                        this.mdDialogRef.close([s]);
                    }).catch((ex) => {
                        this.mdDialogRef.close([s]);

                        this.seatService.updateUserAllocations(this.userAllocations).subscribe(() => {
                            AssignTokensDialogComponent.createDialog(this.dialogService, this.dialog, {
                                student: s,
                                userAllocations: this.userAllocations,
                                errorObject: ex
                            }).show();
                        });
                    });
                } else {
                    this.mdDialogRef.close([s]);
                }
            },
            (error) => {
                this.errorObject = error;
            }
        );
    }

    closeStudentDialog() {
        this.mdDialogRef.close();
    }

    copyLinkToClipboard() {
        this.studentInvitationUrlSrc.nativeElement.select();
        if (document.execCommand("copy")) {
            this.snackbar.openFromTemplate(this.studentLinkCopiedSnackbar, {
                duration: 3000,
            });
        }
    }

    generateStudentInvitation() {
        return this.classroomService
            .createStudentInvitation(this.classroom)
            .subscribe(() => {
                this.updateStudentLink();
                this.snackbar.openFromTemplate(this.studentLinkCreatedSnackbar, {
                    duration: 3000,
                });
            });
    }


    updateStudentLink() {
        if (!this.classroom.studentInvitation) {
            this.generateStudentInvitation();
            return;
        }

        if (this.classroom.studentInvitation.expiresOn < new Date()) {
            this.generateStudentInvitation();
            return;
        }
        const short_locale = environment.production
            ? "/" + this.languageService.currentLanguageCode
            : "";

        const url = `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""
            }${short_locale}/student-invite/${this.classroom.studentInvitation.token}`;
        this.studentInviteLink = url;
    }

    importStudents() {
        this.errorObject = undefined;

        const students = this.importStudentsTab.entries.filter(e => e.isValid);
        if(students.length > 1000) {
            this.errorObject = {
                userMessage: $localize`:@@classroom-max-students:Maximum 1000 students can be added at once!`
            }
            return;
        }
        this.classroomService.importStudents(this.classroom, students).subscribe((res) => {
            if(res.nameAlreadyTaken && (res.nameAlreadyTaken.length > 0)) {
                this.importStudentsTab.setResponse(res);
            }
            else {
                this.mdDialogRef.close(res.students);
            }
        }, (err) => {
            console.error("Er: " + JSON.stringify(err));
            this.errorObject = err;
        });
    }

    onTabChanged(event: any) {
        this.errorObject = undefined;
    }
}
