<div class="screen-container app-form-container">
    <!-- <span class="spacer"></span> -->
    <div class="checkmark-section">
        <img src="assets/images/success.png"
             class="success-background-image">
        <mat-icon>check_circle</mat-icon>
    </div>
    <ng-content select="[screen-content]"></ng-content>
    <div class="app-form-actions big-buttons flex-column">
        <span class="spacer"></span>
        <ng-content select="[screen-action]"></ng-content>
    </div>
</div>