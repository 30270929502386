import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDirectoryHost, ApiDirectoryList, ApiDirectoryServer, ApiRoutes, RoutesServer } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import * as AsyncLock from "async-lock";
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

const API_DIRECTORY_LIST_SERIALIZER = new JsonSerializer(ApiDirectoryList);



@Injectable({
    providedIn: 'root'
})
export class ApiDirectoryService {

    lock = new AsyncLock();
    ds: ApiDirectoryList;

    configServer: ApiDirectoryServer;

    apiRoutes: { [key: string]: string } = {};
    authRoutes: { [key: string]: string } = {};

    constructor(private http: HttpClient,
        private ls: LocalStorageService) {

        this.authRoutes[ApiRoutes.Signin] = '/signin';
        this.apiRoutes[ApiRoutes.Subscription] = '/v3/subscriptions';
        this.apiRoutes[ApiRoutes.License] = '/api/v2/licenses';
        this.apiRoutes[ApiRoutes.Users] = '/v3/users';
        this.apiRoutes[ApiRoutes.Admin] = '/v3/administration';
        this.apiRoutes[ApiRoutes.AccountRecovery] = '/v3/account-recovery';
        this.apiRoutes[ApiRoutes.Email] = '/v3/email';
        this.apiRoutes[ApiRoutes.Knowledgebase] = '/v3/knowledgebase';
        this.apiRoutes[ApiRoutes.Product] = '/v3/product';
        this.apiRoutes[ApiRoutes.PromoCode] = '/v3/promo-code';
        this.apiRoutes[ApiRoutes.Quotation] = '/v3/quotation';
        this.apiRoutes[ApiRoutes.Stripe] = '/v3/stripe';
        this.apiRoutes[ApiRoutes.Translation] = '/v3/translations';
        this.apiRoutes[ApiRoutes.Wordlist] = '/v3/wordlists';
        this.apiRoutes[ApiRoutes.Classroom] = '/v3/classrooms';
        this.apiRoutes[ApiRoutes.GameData] = '/v3/gamedata';
        this.apiRoutes[ApiRoutes.Organization] = '/v3/organizations';
        this.apiRoutes[ApiRoutes.Homework] = '/v3/homeworks';
        this.apiRoutes[ApiRoutes.Invite] = '/v3/invites';
        this.apiRoutes[ApiRoutes.Eqol] = '/v3/eqol';
        this.apiRoutes[ApiRoutes.Onboarding] = '/v3/onboarding';
        this.apiRoutes[ApiRoutes.Order] = '/v3/orders';
        this.apiRoutes[ApiRoutes.Seat] = '/v3/seats';
        this.apiRoutes[ApiRoutes.Searchlist] = '/v3/searchlist';
        this.apiRoutes[ApiRoutes.Activity] = '/v3/activities';
        this.apiRoutes[ApiRoutes.Suggestion] = '/v3/suggestions';
        this.apiRoutes[ApiRoutes.Analytics] = '/v3/analytics';
        this.apiRoutes[ApiRoutes.RemoteDebug] = '/v3/remote-debug';
        this.apiRoutes[ApiRoutes.Student] = '/v3/students';
    }

    getHostUrl(server: RoutesServer) {
        if (server == RoutesServer.Api) {
            const server = this.ds.currentServer;
            return this.filterHost(server.api.host);
        }
        else {
            const server = this.ds.currentServer;
            return this.filterHost(server.auth.host);
        }
    }

    serverPost(server: RoutesServer, route: ApiRoutes, suffix: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        context?: HttpContext;
    }) {
        return this.observeRoute(server, route, suffix).pipe(mergeMap((routeUrl: string) => {
            return this.http.post(routeUrl, body, options);
        }));
    }

    serverPut(server: RoutesServer, route: ApiRoutes, suffix: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }) {
        return this.observeRoute(server, route, suffix).pipe(mergeMap((routeUrl: string) => {
            return this.http.put(routeUrl, body, options);
        }));
    }

    serverGet(server: RoutesServer, route: ApiRoutes, suffix: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }) {
        return this.observeRoute(server, route, suffix).pipe(mergeMap((routeUrl: string) => {
            return this.http.get(routeUrl, options);
        }));
    }

    serverGetResponse(server: RoutesServer, route: ApiRoutes, suffix: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }) {
        return this.observeRoute(server, route, suffix).pipe(mergeMap((routeUrl: string) => {
            return this.http.get(routeUrl, options);
        }));
    }



    serverPatch(server: RoutesServer, route: ApiRoutes, suffix: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }) {
        return this.observeRoute(server, route, suffix).pipe(mergeMap((routeUrl: string) => {
            return this.http.patch(routeUrl, body, options);
        }));
    }

    serverDelete(server: RoutesServer, route: ApiRoutes, suffix: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        body?: any;
    }) {
        return this.observeRoute(server, route, suffix).pipe(mergeMap((routeUrl: string) => {
            return this.http.delete(routeUrl, options);
        }));
    }

    observeRoute(server: RoutesServer, route: ApiRoutes, suffix: string) {
        if (server == RoutesServer.Api) {
            return from(this.getApiRoute(route, suffix));
        }
        else {
            return from(this.getAuthRoute(route, suffix));
        }
    }

    observeServer(): Observable<ApiDirectoryServer> {
        return from(this.getServer());
    }

    getRoute(server: RoutesServer, route?: ApiRoutes, suffix?: string) {

        if (!this.ds.currentServer) {
            console.error("ApiDirectoryService.getRoute() was called before the current server was set.");
        }

        if (!suffix) suffix = "";

        if (!route) {
            return this.getHostUrl(server) + suffix;
        }

        if (server == RoutesServer.Api) {
            const server = this.ds.currentServer;
            if (server.api.routes && server.api.routes[route]) {
                return this.filterHost(server.api.host) + server.api.routes[route] + suffix;
            }

            return this.filterHost(server.api.host) + this.apiRoutes[route] + suffix;
        }
        else {
            const server = this.ds.currentServer;
            if (server.auth.routes && server.auth.routes[route]) {
                return this.filterHost(server.auth.host) + server.auth.routes[route] + suffix;
            }

            return this.filterHost(server.auth.host) + this.authRoutes[route] + suffix;
        }
    }

    async getApiRoute(route: ApiRoutes, suffix: string): Promise<string> {
        if (!this.ds) {
            await this.getList();
        }

        const server = this.ds.currentServer;
        if (server.api.routes && server.api.routes[route]) {
            return this.filterHost(server.api.host) + server.api.routes[route] + suffix;
        }

        return this.filterHost(server.api.host) + this.apiRoutes[route] + suffix;
    }

    async getAuthRoute(route: ApiRoutes, suffix: string): Promise<string> {
        if (!this.ds) {
            await this.getList();
        }

        const server = this.ds.currentServer;
        if (server.auth.routes && server.auth.routes[route]) {
            return this.filterHost(server.auth.host) + server.auth.routes[route] + suffix;
        }

        return this.filterHost(server.auth.host) + this.authRoutes[route] + suffix;
    }

    async getServer(): Promise<ApiDirectoryServer> {
        if (!this.ds) {
            await this.getList();
        }

        return this.ds.currentServer;
    }

    async getList(): Promise<ApiDirectoryList> {
        return new Promise((resolve, rejects) => {

            if (this.ds) {
                resolve(this.ds);
                return;
            }

            this.lock.acquire("api_directory_service", async (done) => {
                if (this.ds) {
                    done();
                    resolve(this.ds);
                }
                else {
                    let apiDirectoryServiceUrl: string;
                    let updateUrl: boolean = false;
                    let redirectionCount: number = 0;

                    apiDirectoryServiceUrl = this.ls.get("api_directory_service_url");

                    if (!apiDirectoryServiceUrl) {
                        apiDirectoryServiceUrl = environment.apiDirectoryServiceUrl;
                        updateUrl = true;
                    }

                    let ds: ApiDirectoryList;

                    let lastNomEmptyDS: ApiDirectoryList;

                    while (!ds && (redirectionCount < 5)) {

                        ds = await this.getDS(apiDirectoryServiceUrl);

                        if (ds) {
                            lastNomEmptyDS = ds;
                            const server = ds.servers.find(s => s.key == environment.serverKey);
                            if (server && server.redirect) {
                                redirectionCount++;
                                apiDirectoryServiceUrl = server.redirect;
                                ds = undefined;
                                updateUrl = true;
                            }
                            else if (ds.redirect) {
                                redirectionCount++;
                                apiDirectoryServiceUrl = ds.redirect;
                                ds = undefined;
                                updateUrl = true;
                            }
                        }
                        else {
                            // Fallback previous url
                            if (apiDirectoryServiceUrl != environment.apiDirectoryServiceUrl) {
                                redirectionCount++;
                                apiDirectoryServiceUrl = environment.apiDirectoryServiceUrl;
                                ds = undefined;
                            }
                            else {
                                break;
                            }
                        }
                    }

                    // In case of a redirection to invalid url.
                    if (!ds) {
                        ds = lastNomEmptyDS;
                    }

                    if (ds && updateUrl) {
                        this.ls.set("api_directory_service_url", apiDirectoryServiceUrl);

                    }

                    // Build default DirectoryService
                    if (!ds) {
                        ds = new ApiDirectoryList();
                        ds.servers = [];

                        ds.servers.push(this.getConfigurationServer());
                        ds.currentServer = this.getConfigurationServer();
                    } else {
                        ds.currentServer = ds.servers.find(s => s.key == environment.serverKey);
                    }

                    // If no corresponding server, use the configuration file to generate one.
                    if (!ds.currentServer) {
                        ds.currentServer = this.getConfigurationServer();
                    }

                    this.ds = ds;

                    done();
                    resolve(this.ds);
                }
            });
        });
    }

    /**
     * Create the ApiDirectoryServer from the configuration (the environment file).
     */
    private getConfigurationServer(): ApiDirectoryServer {
        if (!this.configServer) {
            let siteUrl = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`;

            let server = new ApiDirectoryServer();
            server.key = environment.serverKey;
            server.type = environment.serverType;
            server.auth = new ApiDirectoryHost();
            server.auth.host = environment.authBaseUrl;
            server.api = new ApiDirectoryHost();
            server.api.host = environment.apiBaseUrl;
            server.site = new ApiDirectoryHost();
            server.site.host = "https://appligogiques.com";
            server.portal = new ApiDirectoryHost();
            server.portal.host = siteUrl;

            this.configServer = server;
        }
        return this.configServer;
    }

    private async getDS(apiDirectoryServiceUrl: string): Promise<ApiDirectoryList> {
        return new Promise((resolve, rejects) => {
            this.http.get(apiDirectoryServiceUrl, { withCredentials: false, responseType: "json" }).subscribe((res: any) => {
                this.ds = API_DIRECTORY_LIST_SERIALIZER.deserialize(res);
                resolve(this.ds);

            }, (error) => {
                console.error(error);
                resolve(undefined);
            })
        });
    }

    public filterHost(host: string): string {
        return this.syncDomainExt(window.location.href, host);
    }

    /**
     * Sync the domain extension for the API server with the host.
     * 
     * @param {string} siteUrl The site url. 
     * @param {string} apiUrl The api url. 
     * 
     * @returns {string} Return the apiUrl with the domain extension matching the site url.
     */
    private syncDomainExt(siteHost: string, apiHost: string): string {
        let result: string = apiHost;

        let apiUrl = (new URL(apiHost));
        let siteUrl = (new URL(siteHost));

        const apiExtIdx = apiUrl.hostname.lastIndexOf('.');
        const siteExtIdx = siteUrl.hostname.lastIndexOf('.');

        // Check if both url have domain extensions.
        if( (apiExtIdx != -1) && (siteExtIdx != -1) ) {
            // Extract domains extensions.
            const apiExt = apiUrl.hostname.substring(apiExtIdx);
            const siteExt = siteUrl.hostname.substring(siteExtIdx);
            
            // Check if domains extensions doesn't matches. 
            if(apiExt != siteExt) {
                // Replace the domain extension.
                apiUrl.hostname = apiUrl.hostname.substring(0, apiExtIdx) + siteExt;
                result = apiUrl.toString();


            }
        }

        // The Url.toString() adding an extra slash to the url, so it must be removed.
        if(result.endsWith("/") && !apiHost.endsWith("/")) {
            result = result.substring(0, result.length-1);
        }

        return result;
    }

}
