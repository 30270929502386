import { EqolColumnInfo } from './column-info';

export class EqolCategoryInfo {
    readonly key: string;
    readonly label: string;
    public columns : Array<EqolColumnInfo> = [];

    // List of columns grouped by group number.
    public groups?: Map<number, Array<EqolColumnInfo>> = new Map<number, Array<EqolColumnInfo>>();

    constructor(key: string, label: string) {
        this.key = key;
        this.label = label;
    }

    public addColumn(column: EqolColumnInfo) : void{
        if(!column.group)
        {
            column.group = 0;
        }

        if(!this.groups.has(column.group))
        {
            this.groups.set(column.group, new Array<EqolColumnInfo>());
        }
        this.groups.get(column.group).push(column);
    }
}
