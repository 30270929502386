export enum ApiRoutes {
    Signin = 'signin',
    Subscription = 'subscription',
    License = 'license',
    Users = 'users',
    Admin = 'admin',
    AccountRecovery = 'account-recovery',
    Email = 'email',
    Knowledgebase = 'knowledgebase',
    Quotation = 'quotation',
    Product = 'product',
    PromoCode = 'promo-code',
    Stripe = 'stripe',
    Translation = 'translation',
    Wordlist = 'wordlist',
    Classroom = 'classroom',
    GameData = 'gamedata',
    Organization = 'organization',
    Homework = 'homework',
    Invite = 'invite',
    Eqol = 'eqol',
    Onboarding = 'onboarding',
    Order = 'order',
    Seat = 'seat',
    Searchlist = 'searchlist',
    Activity = 'activity',
    Suggestion = 'suggestion',
    Analytics = 'analitics',
    RemoteDebug = 'remote-debug',
    Student = 'student'
};