import { EMAIL_REGEXP, MaxLength, MinLength, Required, ValidatePattern, Validator } from "@uon/model";

// Structure for the body validation error from the @uon library.
export interface ValidationFieldError {
    path: string[];
    errors: { [errorCode: string]: string|any };
}

export class FieldValidation {

    minLength: number = 0; // Zero means not used.

    maxLength: number = 0; // Zero means not used.

    pattern: RegExp = undefined;

    public getValidators(isRequired: boolean) : Validator[] {
        let result: Validator[] = [];

        if(isRequired) {
            result.push(Required());
        }

        if(this.minLength > 0) {
            result.push(MinLength(this.minLength));
        }

        if(this.maxLength > 0) {
            result.push(MaxLength(this.maxLength));
        }

        if(this.pattern) {
            result.push(ValidatePattern(this.pattern));
        }

        return result;
    }
}

export enum FieldValidationType {
    Email = 'email',
    Password = 'password',
    StudentPassword = 'studentPassword',
    ProfileName = 'profileName',
    FullName = 'fullName',
    ClassroomName = 'classroomName',
    HomeworkLabel = 'homework-label',
    HomeworkDesc = 'homework-desc'
}

export const FieldValidations: {[key: string]: FieldValidation; } = {
    "email": {
        minLength: 0,
        maxLength: 0,
        pattern: EMAIL_REGEXP,
        getValidators: FieldValidation.prototype.getValidators
    },
    "password": {
        minLength: 8,
        maxLength: 32,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    },
    "studentPassword": {
        minLength: 6,
        maxLength: 32,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    },
    "profileName": {
        minLength: 5,
        maxLength: 32,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    },
    "fullName": {
        minLength: 5,
        maxLength: 64,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    },
    "classroomName": {
        minLength: 5,
        maxLength: 64,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    },
    "homework-label": {
        minLength: 0,
        maxLength: 32,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    },
    "homework-desc": {
        minLength: 0,
        maxLength: 1024,
        pattern: undefined,
        getValidators: FieldValidation.prototype.getValidators
    }
};
