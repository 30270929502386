import { Classroom } from "@applogic/model";
import { firstValueFrom } from "rxjs";
import { MessageBoxDialogComponent } from "../core/message-box-dialog/message-box-dialog.component";
import { MessageBoxButton } from "../core/message-box-dialog/messagebox-button";
import { MessageBoxIcon } from "../core/message-box-dialog/messagebox-icon";
import { MessageBoxResult } from "../core/message-box-dialog/messagebox-result";
import { MessageBoxSettings } from "../core/message-box-dialog/messagebox-settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ClassroomService } from "./classroom.service";
import { DialogService } from "../services/dialog.service";
import { MatDialog } from "@angular/material/dialog";


export class ClassroomDialogs {

    public static showDeleteClassroomDialog(dialogService: DialogService, dialog: MatDialog, classroom: Classroom, snackbar: MatSnackBar, classroomService: ClassroomService) {
        return new Promise<void>((resolve, rejects) => {
            let settings = new MessageBoxSettings();
            settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
            settings.messages = [
                $localize`:@@classroom-delete-confirm-text:You're about to delete the following classroom <strong>${classroom.name}</strong>.`,
                $localize`:@@classroom-delete-confirm-text-notice: Once this classroom is deleted, the list of students, their progress, and <br> their access to the games will be lost permanently. `
            ];
            settings.icon = new MessageBoxIcon("warning", "warn");
            settings.buttons = [
                new MessageBoxButton(MessageBoxResult.Cancel),
                new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
            ];
    
            settings.validateBeforeCallback = async (result) => {
                if (result == MessageBoxResult.OK) {
                    try {
                        await firstValueFrom(classroomService.deleteClassroom(classroom));
       
                        snackbar.open($localize`:@@classroom-delete-snackbar-message: ${classroom.name} successfully deleted. `, "action", {
                            data: classroom,
                            duration: 3000,
                        });
                        resolve();
                    }
                    catch(ex) {
                        rejects(ex);
                        return ex;
                    }
                }
            };
    
            MessageBoxDialogComponent.createDialog(dialogService, dialog, settings).show();
        });
   }
}