import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


/**
 * Component for editing a list of strings.
 * 
 * You can provide the list directly using the "list" input.
 * For list embedded inside an object, you can also use the "prop" and "propKey"
 * to provide a list inside an object. If the list doesn't exists, it will be created.
 */
@Component({
    selector: 'app-simple-editable-list',
    templateUrl: './simple-editable-list.component.html',
    styleUrls: ['./simple-editable-list.component.scss']
})
export class SimpleEditableListComponent implements OnInit {

    @Input()
    list: string[] = [];

    @Input()
    prop: any = {};

    @Input()
    propKey: string;

    @Input()
    maxElements: number = -1;

    @Input()
    spaceAdd: boolean = true;

    @Output() onChange = new EventEmitter<string[]>();

    modified: boolean = false;

    toadd: string = "";

    constructor() { }

    ngOnInit(): void {
        this.getList(false);
    }

    /**
     * Get the current list.
     * 
     * The "forceCreate" option serve the purpose of avoiding allocating the list in the object:
     *   this.prop[this.propKey] = [];
     * So that the property will remains unset until something is added.
     * 
     * @param {boolean} forceCreate To force create the list if undefined.
     * 
     * @returns {string[]} The list of selected options.
     */
    getList(forceCreate: boolean): string[] {
        if(this.propKey) {
            if(!this.prop[this.propKey]) {
                if(forceCreate) {
                    this.prop[this.propKey] = [];
                }
            }
            if(this.list != this.prop[this.propKey]) {
                if(this.prop[this.propKey] == undefined) {
                    this.list = [];
                }
                else {
                    this.list = this.prop[this.propKey];
                }
            }
            return this.list;
        }

        return this.list;
    }
    onChoicesChanged(index: number, event: any) {
        this.modified = true;
        const list = this.getList(true);
        list[index] = event.target.value;
        this.onChange.emit(list);
    }

    onChoiceRemove(index: number, event: any) {
        this.modified = true;
        const list = this.getList(false);
        this.toadd = list[index];
        list.splice(index, 1);
        this.onChange.emit(list);
    }

    onChoiceAddChanged(event: any) {
        this.toadd = event.target.value;
    }

    onChoiceAdd(event: any) {
        this.modified = true;
        const list = this.getList(true);

        if(this.spaceAdd) {
            var array = this.toadd.match(/[^\s]+/g);
            list.push(...array);
        }
        else {
            list.push(this.toadd);
        }
        this.onChange.emit(list);
    }
}
