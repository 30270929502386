import { Member, Model } from "@uon/model";
import { User } from "../user/user.model";
import { OnboardingState } from "./state";

/**
* Utility class to retrieve a user with the corresponding onboarding state.
*/
@Model()
export class UserWithOnboardingState extends User {

    constructor() {
        super();
    }
    
    @Member() 
    state: OnboardingState;
}