import { Member, Model } from "@uon/model";

@Model()
export class OnboardingUserState {
    
    @Member() 
    key: string;

    @Member() 
    entered: Date;
}