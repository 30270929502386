import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICommonList } from '../common-list.interface';

@Component({
    selector: 'app-common-list-search-input',
    templateUrl: './common-list-search-input.component.html',
    styleUrls: ['./common-list-search-input.component.scss']
})
export class CommonListSearchInputComponent implements OnInit {
    
    @Input()
    list: ICommonList;

    @Input()
    formInputStyle: boolean = true;

    _placeholder: string = $localize`:@@common-search-bar-placeholder:Search...`;

	@Input()
    set placeholder(val: string) {
        if(val == undefined) {
            this._placeholder = $localize`:@@common-search-bar-placeholder:Search...`;
        }
        else {
            this._placeholder = val;
        }
    }

    get placeholder() {
        return this._placeholder;
    }
	

    searchInput: ElementRef;

    @ViewChild('searchInput', { static: false }) set searchInputElement1(element: ElementRef) {
        if(element && (this.searchInput != element)) {
            this.setElement(element);
        }
    }

    constructor() { }

    ngOnInit(): void {
        
    }

    private setElement(element: ElementRef) {
        this.searchInput = element;
        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged()

        ).subscribe(() => {
            this.list.setSearch(this.searchInput.nativeElement.value);
        });
    }

}
