<div class="toollip-data">
    @switch(context) {
    @case('date') {
    <p> <span>{{_chip.name }}</span></p>
    }
    }
    @if((context == 'date') && (completedlevels.length > 0)) {
    <div class="sublevelList">
        <div>
            <span i18n="@@game-progress-tooltip-completed-levels">Completed Levels</span> :
        </div>
        <div class="sublevel"
             *ngFor="let level of completedlevels"
             [style]="{'background': _chip.color_code, 'border-color': _chip.color_code }">
            <span class="number-show"> {{level}}</span>
        </div>
    </div>
    }
    @else {
    <div class="sublevelList">
        <table>
            <tr>
                <td class="table-label-column">
                    <span i18n="@@game-progress-tooltip-finished">Finished on</span>:
                </td>
                <td>
                    <div class="table-value-column">
                        <ng-container *ngFor="let date of completedDates">
                            <span class="activityTooltip">
                                {{getFormattedDate(date)}}
                            </span>
                        </ng-container>
                        <span *ngIf="completedDatesOverflow > 0">
                            ...
                        </span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    }
</div>