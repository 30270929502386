import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: 'design-card-image',
    host: {
        'class': 'design-card-image'
    }
})
export class DesignCardImageDirective {

    @HostBinding('class') get getAlignClass() {
        return 'middle-align';
    }
    
    constructor() { }

}
