import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ClassroomSeatsStudentAllocation, FieldValidationType, MultiCounters, OnboardingSubscriptionClassroomSeatsAllocation, Product, Student, SubAllocationsResponse, SubscriptionAllocationMode } from '@applogic/model';
import { ClassroomService } from 'src/app/classroom/classroom.service';
import { CommonColumnHeaderType, CommonColumnType, CommonListColumn } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { ProductService } from 'src/app/services/product.service';
import { SeatService } from 'src/app/services/seat.service';
import { StudentService } from 'src/app/services/student.service';
import { profileNotUsedValidator } from 'src/app/validators/profile-not-used.validator';

interface ClassroomStudent {
    selected?: boolean;
    isInvite: boolean;
    student: Student;
    seats: { [productCode: string]: boolean };
    reservedSeats: { [productCode: string]: boolean };
    rowType: CommonColumnType;
    seatsRowType: CommonColumnType;
}

@Component({
    selector: 'app-classroom-seats-allocation-list',
    templateUrl: './classroom-seats-allocation-list.component.html',
    styleUrls: ['./classroom-seats-allocation-list.component.scss']
})
export class ClassroomSeatsAllocationListComponent extends CommonListComponent<ClassroomStudent>  implements OnInit {
    SubscriptionAllocationMode = SubscriptionAllocationMode;

    products: Product[] = [];
    freeSeats: MultiCounters = {};
    productColumns: {[productCode: string]: CommonListColumn} = {};

    _classroom: OnboardingSubscriptionClassroomSeatsAllocation;

	@Input()
    set classroom(val: OnboardingSubscriptionClassroomSeatsAllocation) {
        this.classroomChange.emit(val);
        this._classroom = val;
    }

    get classroom() {
        return this._classroom;
    }

    @Output()
    classroomChange = new EventEmitter<OnboardingSubscriptionClassroomSeatsAllocation>();

    @Input()
    subscriptionId: string;

    @Input()
    allocationMode: SubscriptionAllocationMode;

    private _isValid: boolean;

    set isValid(val: boolean) {
        if(val != this._isValid) {
            this.isValidChange.emit(val);
            this._isValid = val;
        }
    }

    get isValid() {
        return this._isValid;
    }

    @Output()
    isValidChange = new EventEmitter<boolean>();

    subAllocs: SubAllocationsResponse;

    constructor(injector: Injector, private seatService: SeatService, private productService: ProductService, private classroomService: ClassroomService, private studentService: StudentService) {
        super(injector);
        this.doInitialRefresh = false;
        this.supportCompactMode = true;
        this.noPagination = true;

        this.rendererOptions.showSelectColumnsTable = false;
        this.rendererOptions.showSelectColumnsOption = false;
        this.rendererOptions.stickyHeader = true;
        this.rendererOptions.stickyFooter = true;
        this.rendererOptions.showSearchBar = true;
        this.rendererOptions.searchPlaceholder = $localize`:@@students-search-bar-placeholder:Search students...`;
        this.rendererOptions.verticalScrolling = true;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.rendererOptions.showColumnFooter = this.allocationMode == SubscriptionAllocationMode.Manual;

        if(!this.classroom) {
            this.classroom = {
                allocations: []
            };
            this.classroom.allocations = [];
        }

        this.selection.changed.subscribe((value) => {
            if(value.added) {
                value.added.forEach((member) => {
                    this.setSelectedStudent(true, member, false);
                });
            }

            if(value.removed) {
                value.removed.forEach((member) => {
                    this.setSelectedStudent(false, member, false);
                });
            }
        });

        this.seatService.getSubscriptionAllocations(this.subscriptionId).subscribe((response) => {
            this.subAllocs = response;
            const defaultAllocs = this.subAllocs.getDefaultAlloc();
            
            if(defaultAllocs) {
                this.freeSeats = Object.assign({}, defaultAllocs.freeSeats || {});
            }
            else {
                this.freeSeats = Object.assign({}, this.subAllocs.freeSeats || {});
            }

            if(this.allocationMode == SubscriptionAllocationMode.Manual) {
                this.productService.getProducts().then((res) => {
                    this.products = res.filter(p => {
                        const shortCode = p.shortCode.toLowerCase();
                        return !!this.subAllocs.totalSeats[shortCode];
                    });

                    this.initColumns();
                    this.refreshItems();
                });
            }
            else {
                this.initColumns();
                this.refreshItems();
            }

        }, (err) => {
            console.error(err);
        });
    }

    initColumns() {
        let key = 'applogic/seat/classroom-seats-allocation';

        this.initPersistentKey(key)

        this.addColumn("", "select", true, true, CommonColumnType.Selection);

        const nameColumn = this.addColumn($localize`:@@common-student-fullname: Student’s name `, "student.fullName", true, !this.compactRow, CommonColumnType.RowType, {
            searchable: true,
            footerStyles: {
                "display": "flex",
                "flex-direction": "row-reverse"
            },
            cellStyles: {
                "min-width": "141px"
                // "width": "100%"
            },
            rowTypeKey: 'rowType',
            data: {
                inputPlaceholder: $localize`:@@common-student-fullname: Student’s name `
            },
            formControlValidatorType: FieldValidationType.FullName
        });

        const usernameColumn = this.addColumn($localize`:@@common-username: Profile name `, "student.username", true, !this.compactRow, CommonColumnType.RowType, {
            searchable: true,
            footerStyles: {
                "display": "flex",
                "flex-direction": "row-reverse"
            },
            cellStyles: {
                "min-width": "141px"
                // "width": "100%"
            },
            rowTypeKey: 'rowType',
            data: {
                inputPlaceholder: $localize`:@@common-username: Profile name `
            },
            formControlValidatorType: FieldValidationType.ProfileName,
            formControlAsyncValidators: [profileNotUsedValidator(this.studentService)]
        });

        const passwordColumn = this.addColumn($localize`:@@common-password: Password `, "student.password", true, !this.compactRow, CommonColumnType.RowType, {
            searchable: true,
            footerLabel: $localize`:@@common-available-tokens:Available Tokens`,
            footerStyles: {
                "display": "flex",
                "flex-direction": "row-reverse"
            },
            cellStyles: {
                "min-width": "141px"
                // "width": "100%"
            },
            rowTypeKey: 'rowType',
            data: {
                inputPlaceholder: $localize`:@@common-password: Password `
            },
            formControlValidatorType: FieldValidationType.StudentPassword
        });

        if(this.compactRow) {
            const columnName = [nameColumn.label, usernameColumn.label, passwordColumn.label].map(l => l.trim()).join("\n");
            this.addColumn(columnName, "student.password", true, this.compactRow, CommonColumnType.MergeColumns, {
                footerLabel: $localize`:@@common-available-tokens:Available Tokens`,
                headerStyles: {
                    "white-space": "pre-wrap"
                },
                footerStyles: {
                    "display": "flex",
                    "flex-direction": "row-reverse"
                },
                cellStyles: {
                    "min-width": "141px"
                    // "width": "100%"
                },
                rowTypeKey: 'rowType',
                mergeColumns: [ nameColumn, usernameColumn, passwordColumn ]
            });
        }

        for (let i = 0; i < this.products.length; i++) {
            const product = this.products[i];
            const productCode = product.shortCode.toLowerCase();
            const columnKey = 'seats.' + productCode;
            const productColumn = this.addColumn(product.shortCode, columnKey, true, true, CommonColumnType.RowType, {
                isSortable: false,
                fixedWidth: "45px",
                cellStyles: {
                    'text-align': 'center',
                    'display': 'grid'
                },
                headerStyles: {
                    "display": "flex",
                    "align-items": "center",
                    "justify-content": "center"
                },
                columnHeaderType: CommonColumnHeaderType.Template,
                columnFooterType: CommonColumnHeaderType.Template,
                rowTypeKey: 'seatsRowType',
                templateColumnIdx: 0,
                templateFooterIdx: 1,
                data: {
                    productCode,
                    product: product,
                    canDeselect: true
                }
            });
            this.productColumns[productCode] = productColumn;
        }

        // this.addColumn("", "actions", true, true, CommonColumnType.Action);
        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        const seats: {[productCode: string]: boolean} = {};
        const seatsStr = JSON.stringify(seats);

        for (const product of this.products) {
            seats[product.shortCode.toLowerCase()] = false;
        }

        if(this.classroom.classroomId) {
            this.classroomService.getClassroom(this.classroom.classroomId).subscribe((response) => {
                this.classroom.name = response.classroom.name;
                this.classroom.slug = response.classroom.slug;
                response.allocateSeatsToStudents();

                let students = response.classroom.students.map((student) => {
                    let result: ClassroomStudent = {
                        isInvite: false,
                        student,
                        seats: JSON.parse(seatsStr),
                        reservedSeats: JSON.parse(seatsStr),
                        rowType: CommonColumnType.Text,
                        seatsRowType: CommonColumnType.Void
                    };

                    const allocation = this.classroom.allocations.find(a => a.student.id == student.id);
                    if(allocation) {
                        result.reservedSeats = allocation.seats;
                        result.seats = JSON.parse(JSON.stringify(allocation.seats));
                        this.setSelectedStudent(true, result);
                    }

                    return result;
                });

                const invitedStudents = this.getInvitedStudents();

                const allStudents = [...invitedStudents, ...students];

                // Set the initial isValid state.
                this.validate();

                this.setPreloadItems(allStudents);
                
            });
        }
        else {
            // Set the initial isValid state.
            this.validate();

            this.setPreloadItems(this.getInvitedStudents());
        }
    }

    private getInvitedStudents() {
        const invitedMembers = this.classroom.allocations.filter(r => !(!!r.student.id)).map(r => {
            let result: ClassroomStudent = {
                isInvite: true,
                student: r.student as any,
                seats: JSON.parse(JSON.stringify(r.seats)),
                reservedSeats: r.seats,
                rowType: CommonColumnType.FormControlInput,
                seatsRowType: CommonColumnType.Void
            };

            this.setSelectedStudent(true, result);

            return result;
        });

        return invitedMembers;
    }

    inviteNewStudent() {
        const student = new Student();
        student.username = "";
        student.fullName = "";
        student.password = "";

        const seats: {[productCode: string]: boolean} = {};

        for (const product of this.products) {
            const code = product.shortCode.toLowerCase();
            seats[code] = this.freeSeats[code] > 0;
        }

        const member: ClassroomStudent = {
            isInvite: true,
            student,
            seats,
            reservedSeats: JSON.parse(JSON.stringify(seats)),
            rowType: CommonColumnType.FormControlInput,
            seatsRowType: CommonColumnType.Void
        };

        this.classroom.allocations.push({
            seats: member.reservedSeats,
            student
        });

        this.addItem(member, 0);
        this.setSelectedStudent(true, member);
    }

    /**
     * Must be called to change or initialize selected items.
     * 
     * @param checked
     * @param member
     * @param isInit True if the selection is part of the initialization. False if the selection is a new one.
     */
    setSelectedStudent(checked: boolean, member: ClassroomStudent, isInit: boolean = true) {
        if(!!member.selected == checked) {
           return; 
        }

        member.selected = checked;

        if(member.selected) {
            if(!this.selection.isSelected(member)) {
                this.selection.select(member);
            }
            member.seatsRowType = CommonColumnType.RadioButton;
        }
        else {
            if(this.selection.isSelected(member)) {
                this.selection.deselect(member);
            }
            member.seatsRowType = CommonColumnType.Void;
            this.removeFormControlsForItem(member);
        }

        if(checked) {
            const allocationIdx = this.classroom.allocations.findIndex(a => a.student == member.student);

            if(allocationIdx == -1) {
                const allocation: ClassroomSeatsStudentAllocation = {
                    student: member.student,
                    seats: member.reservedSeats
                };

                this.classroom.allocations.push(allocation);
            }

            if(!isInit) {
                for (const product of this.products) {
                    const code = product.shortCode.toLowerCase();
                    member.seats[code] = this.freeSeats[code] > 0;
                    member.reservedSeats[code] = this.freeSeats[code] > 0;
                }
            }

            this.validate();
        }
        else {
            const allocationIdx = this.classroom.allocations.findIndex(a => a.student == member.student);
            if(allocationIdx != -1) {
                this.classroom.allocations.splice(allocationIdx, 1);
                this.validate();
            }

            if(!member.student.id) {
                this.removeItem(member);
            }
        }

        for (const product of this.products) {
            const productCode = product.shortCode.toLowerCase();
            if (!member.reservedSeats[productCode]) {
                member.reservedSeats[productCode] = false;

                this.setRowValue(this.productColumns[productCode], member, false);
            }
            else {
                // Make sure the binded control value follow the current value before it disappear.
                this.setRowValue(this.productColumns[productCode], member, member.selected);

                if(member.selected) {
                    this.freeSeats[productCode] -= 1;
                }
                else {
                    this.freeSeats[productCode] += 1;

                    // Reset default value
                    member.seats[productCode] = false;
                    member.reservedSeats[productCode] = false;
                }

                this.onColumnUpdate(productCode); 
            }
        }
    }

    private validate() {
        this.isValid = this.classroom.allocations && (this.classroom.allocations.length > 0) && this.areControlsValid && (this.selection.selected?.length > 0);
    }

    onFormStatusChanged(column: CommonListColumn, control: AbstractControl, status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED') {
        super.onFormStatusChanged(column, control, status);
        this.validate();
    }
    
    onRadiobuttonChanged(column: CommonListColumn, row: ClassroomStudent, checked: boolean, radioButton: any) {
        super.onRadiobuttonChanged(column, row, checked, radioButton);

        this.syncSeatsChanges(row, column.parameters.productCode);
    }

    // For if we want to use a checkbox instead of a radio button.
    onCheckboxChanged(column: CommonListColumn, row: ClassroomStudent, checked: boolean) {
        super.onCheckboxChanged(column, row, checked);

        
        const productCode = column.parameters.productCode;
        this.syncSeatsChanges(row, productCode);
    }

    syncSeatsChanges(row: ClassroomStudent, productCode: string) {
        const newSeatsValue = row.seats[productCode] || false;
        const currentSeatsValue = row.reservedSeats[productCode] || false;
        
        if(newSeatsValue != currentSeatsValue) {
            row.reservedSeats[productCode] = newSeatsValue;

            if(newSeatsValue) {
                this.freeSeats[productCode] -= 1;
            }
            else {
                this.freeSeats[productCode] += 1;
            }

            const control = this.getFormControl(this.productColumns[productCode], row);
            if(control) {
                control.setValue(newSeatsValue);
            }

            this.onColumnUpdate(productCode); 
        }
    }

    onColumnUpdate(productCode: string) {
        const productColumn = this.productColumns[productCode];

        if(!productColumn.formArray) return;

        const seats = this.freeSeats[productCode];

        if( (seats == 1) || (seats == 0) ) {
            const disabled = !(seats > 0);
            if(productColumn.formArray) {
                for(const control of productColumn.formArray.controls) {
                    const controlDisabled = !!control.value ? false : disabled;
                    if(controlDisabled != control.disabled) {
                        if(controlDisabled) {
                            control.disable();
                        }
                        else {
                            control.enable();
                        }
                    }
                }
            }
        }
    }

    onProductClick(productCode: string) {
        let isAllSelectedOrAllocated: boolean = true;

        if(this.freeSeats[productCode] > 0) {
            for(const item of this.preloadedItems) {
                if(!item.selected) continue;

                if(item.seats[productCode] != true) {
                    isAllSelectedOrAllocated = false;
                }
            }
        }

        for(const item of this.preloadedItems) {
            if(!item.selected) continue;

            const selected = !!item.seats[productCode];
            if(selected == isAllSelectedOrAllocated) {
                if(selected || (this.freeSeats[productCode] > 0)) {
                    item.seats[productCode] = !selected;
                    this.syncSeatsChanges(item, productCode);
                }
            }
        }
    }

    instantiateFormControl(column: CommonListColumn, row: any, initialValue: any) {
        const control = super.instantiateFormControl(column, row, initialValue);

        if(column.parameters.productCode) {
            const enabled = initialValue ? true : this.freeSeats[column.parameters.productCode] > 0;
            if(!enabled) {
                control.disable();
            }
        }

        return control;
    }

    onCompactModeChanged() {
        if(this.products) {
            this.initColumns();
        }
    }
}
