import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { FacebookLoginButtonComponent } from './facebook-login-button/facebook-login-button.component';
import { GoogleLoginButtonComponent } from './google-login-button/google-login-button.component';


@NgModule({
    declarations: [
        FacebookLoginButtonComponent,
        GoogleLoginButtonComponent
    ],
    exports: [
        FacebookLoginButtonComponent,
        GoogleLoginButtonComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
    ],
    providers: [

    ]
})
export class SocialModule { }
