import { Component, Input, OnInit } from '@angular/core';
import { MultiCounters, SubscriptionAllocation } from '@applogic/model';
import * as moment from 'moment';
import { LanguageService } from 'src/app/services/language.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';

export enum SeatTokenDisplayType {
    Normal = "normal",
    IconOnly = "icon-only",
    FreeOnly = "free-only",
    LabelOnly = "label-only",
    Counters = "counters",
    DotOnly = "dot-only"
}

@Component({
    selector: 'app-seat-token',
    templateUrl: './seat-token.component.html',
    styleUrls: ['./seat-token.component.scss']
})
export class SeatTokenComponent implements OnInit {

    @Input() allocation: SubscriptionAllocation;
    @Input() productCode: string = "";

    @Input() type: string;
    @Input() displayType: SeatTokenDisplayType = SeatTokenDisplayType.Normal;
    @Input() size: number = 40;

    @Input() label: string;

    // To manually display the x / y values.
    @Input() counter1: MultiCounters;
    @Input() counter2: MultiCounters;

    @Input() expiresOn: Date;

    productName: string = "";
    public tooltips: string = "";

    backgroundStyle: any = {};



    constructor(private productService: ProductService,
        private languageService: LanguageService) { }

    ngOnInit(): void {
        this.updateTooltips();

        this.productService.getGameNameAsync(this.productCode).then((name) => {
            this.productName = name;
        });

        this.productService.getProduct(this.productCode).then((product) => {
            this.updateBackgroundStyle(product.imageUrl);
        });

        this.refreshLabels();
    }

    getTooltips() {
        this.updateTooltips();
        return this.tooltips;
    }

    updateBackgroundStyle(imageUrl: string) {

        const style: any = {
            width: `${this.size}px`,
            height: `${this.size}px`,
            flexBasis: `${this.size}px`,
            fontSize: `${Math.floor(this.size / 2) - 5}px`

        };

        let backgroundUrl: string = imageUrl;

        style.backgroundImage = "url('" + backgroundUrl + "')";
        style.fontWeight = "bold";
        style.textShadow = "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;";
        style.color = "black";

        this.backgroundStyle = style;
    }

    getBorderStyle() {

        const style: any = {
            width: `${this.size}px`,
            height: `${this.size}px`,
            flexBasis: `${this.size}px`,
            fontSize: `${Math.floor(this.size / 2) - 5}px`

        };

        return style;
    }

    refreshLabels() {
        /*
                if(this.displayNothing) {
                    this.upperText = "";
                    this.lowerText = "";
                    return;
                }
        
                if (this.label) {
                    this.upperText = "";
                    this.lowerText = this.label;
                    return;
                }
        
                if (this.displayProductCodeOnly) {
                    this.upperText = "";
                    this.lowerText = this.productCode;
                    return;
                }
        
                if (this.counter1) {
                    this.upperText = this.counter1[this.productCode].toString();
                    this.lowerText = this.counter2[this.productCode].toString();
                    return;
                }
        
                if (!this.allocation || !this.allocation.freeSeats) return "";
        
                if (this.displayFreeOnly) {
                    let nbFreeTokens: number = 0;
        
                    if (this.allocation.freeSeats && this.allocation.freeSeats[this.productCode]) {
                        nbFreeTokens = this.allocation.freeSeats[this.productCode];
                    }
                    this.upperText = "";
                    this.lowerText = nbFreeTokens.toString();
                    return;
                }
        
                let t = this.getXYTotal();
                this.upperText = t.used.toString();
                this.lowerText = t.total.toString();
                */
    }

    getXYTotal() {
        let total: number = 0;
        let used: number = 0;

        if (this.allocation.freeSeats && this.allocation.freeSeats[this.productCode]) {
            total += this.allocation.freeSeats[this.productCode];
        }

        if (this.allocation.usedSeats && this.allocation.usedSeats[this.productCode]) {
            used = this.allocation.usedSeats[this.productCode];
            total += used;
        }

        return { used, total };

    }

    getXYTotalLabel() {
        let t = this.getXYTotal();

        return t.used + " / " + t.total;

    }

    updateTooltips() {
        let result: string = "";

        if (this.productCode) {
            if(this.displayType == SeatTokenDisplayType.IconOnly) {
                result += $localize`:@@common-token-for:Token for [forname]`.replace("[forname]", this.productName) + "\n";
            } else {
                result += $localize`:@@common-tokens-for:Tokens for [forname]`.replace("[forname]", this.productName) + "\n";
            }
        }

        if (this.allocation) {

            result += $localize`:@@seat-token-tooltip-used-tokens:Number of used token: `;
            let nbUsedTokens: number = 0;

            if (this.allocation.usedSeats && this.allocation.usedSeats[this.productCode]) {
                nbUsedTokens = this.allocation.usedSeats[this.productCode];
            }
            result += nbUsedTokens.toString() + "\n";

            result += $localize`:@@seat-token-tooltip-available-tokens:Number of token available: `;
            if (this.displayType != SeatTokenDisplayType.FreeOnly) {
                result += this.getXYTotalLabel();
            } else {
                let nbFreeTokens: number = 0;

                if (this.allocation.freeSeats && this.allocation.freeSeats[this.productCode]) {
                    nbFreeTokens = this.allocation.freeSeats[this.productCode];
                }
                result += nbFreeTokens.toString();
            }
        }
        else if (this.counter1) {
            result += $localize`:@@seat-token-tooltip-available-tokens:Number of token available: `;
            result += this.counter1[this.productCode] + " / " + this.counter2[this.productCode];
        }

        if (this.expiresOn) {
            result += $localize`:@@common-expiration-date:Expiration Date` + ":\n\t" + moment(this.expiresOn).locale(this.languageService.currentLanguageCode).format("dddd, MMMM Do YYYY, h:mm:ss a");
        }

        if (this.tooltips != result) {
            this.tooltips = result;
        }
    }

    toNumber(v: any) {
        if (!v) {
            return 0;
        }
        return v;
    }
}
