import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicMaterialColorSelectorComponent } from './dynamic-material-color-selector/dynamic-material-color-selector.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { DynamicMaterialColorsSelectorComponent } from './dynamic-material-colors-selector/dynamic-material-colors-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { DynamicMaterialColorSelectionDialogComponent } from './dynamic-material-color-selection-dialog/dynamic-material-color-selection-dialog.component';
import { CoreModule } from '../core/core.module';
import { DynamicMaterialColorSelectionButtonComponent } from './dynamic-material-color-selection-button/dynamic-material-color-selection-button.component';



@NgModule({
    declarations: [
        DynamicMaterialColorSelectorComponent,
        DynamicMaterialColorsSelectorComponent,
        DynamicMaterialColorSelectionDialogComponent,
        DynamicMaterialColorSelectionButtonComponent,
    ],
    exports: [
        DynamicMaterialColorSelectorComponent,
        DynamicMaterialColorsSelectorComponent,
        DynamicMaterialColorSelectionDialogComponent,
        DynamicMaterialColorSelectionButtonComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatExpansionModule,
    ]
})
export class MaterialDesignModule { }
