import { Directive, HostBinding, Input } from '@angular/core';
import { ColorRole } from 'src/app/material-design';



@Directive({
    selector: '[colorRole]'
})
export class ColorRoleDirective {

    @Input('colorRole')
    colorRole: ColorRole;

    @HostBinding('style.color') get getColorStyle() {
        return 'var(--app-m3-color-role--' + this.colorRole + ')';
    }

    constructor() { }

}
