import { AbstractControl, NgControl } from "@angular/forms";

export class FormUtils {

    public static readonly UON_VALIDATION_ERROR_CODES: string[] = ['Required', 'MinLength', 'MaxLength', 'ValidateEmail', 'ValidatePhone', 'ValidateMongoId', 'MinArraySize', 'MaxArraySize'];


    static GetUonErrorFromCode(errorCode: string, context?: any, defaultError: string = '') {

        switch (errorCode) {
            case 'Required':
                return $localize`:@@form-validation--field-is-required:Field is required`;

            case 'MinLength':
                if (context.minLength) {
                    const minLength = context.minLength;
                    return $localize`:@@form-validation--below-minimum-length-characters:Minimum ${minLength} characters`;
                }

                return $localize`:@@form-validation--below-minimum-length:Below minimum length`;

            case 'MaxLength':
                if (context.maxLength) {
                    const maxLength = context.maxLength;
                    return $localize`:@@form-validation--above-maximum-length-characters:Maximum ${maxLength} characters`;
                }

                return $localize`:@@form-validation--above-maximum-length:Above maximum length`;

            case 'ValidateEmail':
                return $localize`:@@form-validation--invalid-email:Not a valid email address`;

            case 'ValidatePhone':
                return $localize`:@@form-validation--invalid-phone:Not a valid phone number`;

            case 'ValidateMongoId':
                return $localize`:@@form-validation--invalid-mongo-id:Invalid database id`;

            case 'ValidatePattern':
                return $localize`:@@form-validation--invalid-form:Does not match pattern`;

            case 'MaxArraySize':
                if (context.maxLength) {
                    const maxLength = context.maxLength;
                    return $localize`:@@form-validation--above-array-maximum-size-with-value:Maximum of ${maxLength} elements`;
                }

                return $localize`:@@form-validation--above-array-maximum-size:Above maximum number of elements`;

            case 'MinArraySize':
                if (context.minLength) {
                    const minLength = context.minLength;
                    return $localize`:@@form-validation--below-array-minimum-size-with-value:Minimum of ${minLength} elements`;
                }

                return $localize`:@@form-validation--below-array-minimum-size:below minimum number of elements`;
        }

        return defaultError;
    }

    static GetControlError(control: NgControl|AbstractControl, context?: any, defaultError: string = '') {

        for (const errorCode of FormUtils.UON_VALIDATION_ERROR_CODES) {
            if (control.hasError(errorCode)) {
                return FormUtils.GetUonErrorFromCode(errorCode, context);
            }
        }
    
        return defaultError;
    }

    static GetControlErrors(control: NgControl|AbstractControl, defaultError: string = ''): string[] {

        const errors: string[] = [];

        for (const errorCode of FormUtils.UON_VALIDATION_ERROR_CODES) {
            if (control.hasError(errorCode)) {
                errors.push(FormUtils.GetUonErrorFromCode(errorCode, control.getError(errorCode), defaultError));
            }
        }
    
        return errors;
    }

 
}