import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { CoreModule } from '../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UsersSelectionComponent } from './users-selection/users-selection.component';
import { OrgsSelectionDialogComponent } from './orgs-selection-dialog/orgs-selection-dialog.component';
import { OrgsSelectionComponent } from './orgs-selection/orgs-selection.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { UsersSelectionDialogComponent } from './users-selection-dialog/users-selection-dialog.component';
import { ChangeMemberRoleDialogComponent } from './change-member-role-dialog/change-member-role-dialog.component';
import { InviteMemberDialogComponent } from './invite-member-dialog/invite-member-dialog.component';
import { CreateOrUpdateOrganizationDialogComponent } from './create-or-update-organization-dialog/create-or-update-organization-dialog.component';
import { AddressUiModule } from '../address-ui/address-ui.module';
import { OrganizationCardTemplateComponent } from './organization-card-template/organization-card-template.component';
import { OrganizationCardComponent } from './organization-card/organization-card.component';
import { OrganizationSelectionFormControlComponent } from './organization-selection-form-control/organization-selection-form-control.component';
import { OrganizationRoleCardComponent } from './organization-role-card/organization-role-card.component';
import { OrganizationMemberListComponent } from './organization-member-list/organization-member-list.component';
import { OrganizationMemberCardTemplateComponent } from './organization-member-card-template/organization-member-card-template.component';
import { OrganizationMemberCardComponent } from './organization-member-card/organization-member-card.component';
import { OrganizationMemberInvitationListComponent } from './organization-member-invitation-list/organization-member-invitation-list.component';


const ORGS_EXPORTS_COMPONENTS = [
    ChangeMemberRoleDialogComponent,
    CreateOrUpdateOrganizationDialogComponent,
    InviteMemberDialogComponent,
    OrganizationCardComponent,
    OrganizationCardTemplateComponent,
    OrganizationListComponent,
    OrganizationMemberCardComponent,
    OrganizationMemberCardTemplateComponent,
    OrganizationMemberInvitationListComponent,
    OrganizationMemberListComponent,
    OrganizationRoleCardComponent,
    OrganizationSelectionFormControlComponent,
    OrgsSelectionComponent,
    OrgsSelectionDialogComponent,
    UsersSelectionComponent,
    UsersSelectionDialogComponent,
]

@NgModule({
    declarations: [
        ...ORGS_EXPORTS_COMPONENTS
    ],
    imports: [
        AddressUiModule,
        CommonModule,
        CoreModule,
        RouterModule,
        ReactiveFormsModule,
        AuthUiModule,
        OverlayModule,
        TooltipModule,
        RouterModule,
        ReactiveFormsModule,
    ],
    exports: [
        ...ORGS_EXPORTS_COMPONENTS
    ]
})
export class OrganizationUiModule { }
