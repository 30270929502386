import { ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Organization, OrgAllocationsResponse, SubscriptionAllocation, SubAllocationsResponse, User, SubscriptionGrant, OrganizationRole } from '@applogic/model';
import { AccountService } from 'src/app/account/account.service';
import { MessageBoxDialogComponent } from 'src/app/core/message-box-dialog/message-box-dialog.component';
import { MessageBoxButton } from 'src/app/core/message-box-dialog/messagebox-button';
import { MessageBoxIcon } from 'src/app/core/message-box-dialog/messagebox-icon';
import { MessageBoxResult } from 'src/app/core/message-box-dialog/messagebox-result';
import { MessageBoxSettings } from 'src/app/core/message-box-dialog/messagebox-settings';
import { ChecklistPopoverComponent } from 'src/app/onboarding-ui/checklist-popover/checklist-popover.component';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { LanguageService } from 'src/app/services/language.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProductService } from 'src/app/services/product.service';
import { AddSeatsAllocationDialogComponent } from '../add-seats-allocation-dialog/add-seats-allocation-dialog.component';
import { TransferSeatsDialogComponent } from '../transfer-seats-dialog/transfer-seats-dialog.component';
import { SeatService } from 'src/app/services/seat.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SubscriptionModeDialogComponent } from 'src/app/account/subscriptions/subscription-mode-dialog/subscription-mode-dialog.component';


@Component({
    selector: 'app-org-seats-allocation-panel',
    templateUrl: './org-seats-allocation-panel.component.html',
    styleUrls: ['./org-seats-allocation-panel.component.scss']
})
export class OrgSeatsAllocationPanelComponent implements OnInit {

    @Input()
    key: string = "";

    @Input()
    organization: Organization;

    orgAllocs: OrgAllocationsResponse;

    @Input()
    totalTokens: number = 0;

    protected _isVisible: boolean = true;

    @Input()
    set isVisible(val: boolean) {
        if (this._isVisible != val) {
            this.isVisibleChange.emit(val);
            this._isVisible = val;
        }
    }

    get isVisible() {
        return this._isVisible;
    }

    @Output()
    isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    constructor(readonly elementRef: ElementRef<HTMLElement>,
        private localStorageService: LocalStorageService,
        private seatService: SeatService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private errorDialogService: HandleErrorDialogService,
        private languageService: LanguageService,
        private productService: ProductService,
        private accountService: AccountService) {
    }

    ngOnInit(): void {
        // Only admin can manage organization tokens. 
        if(this.accountService.getSelfRole(this.organization) != OrganizationRole.Administrator) {
            setTimeout(() => {
                this.isVisible = false;
            });
            
            return;
        }

        this.seatService.getOrgSubscriptionAllocations(this.organization.id).toPromise().then((allocations) => {
            this.orgAllocs = allocations;

            let totalTokens: number = 0;
            for(const sub of allocations.subscriptions) {
                for(const key of Object.keys(sub.totalSeats)) {
                    const seats = sub.totalSeats[key];
                    if(!isNaN(seats)) {
                        totalTokens += seats;
                    }
                }
            }
            this.totalTokens = totalTokens;
        }).catch((ex) => {
            console.error(ex);
        });;
    }

}
