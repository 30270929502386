<app-dialog icon="person_add">
    <app-dialog-title i18n="@@common-invite-member"> Invite a member </app-dialog-title>
    <app-dialog-message>
        <p i18n="@@org-member-invite-blurb">
            Invite members to <strong> {{ data.organization.name }}</strong> by entering their email below.
        </p>
    </app-dialog-message>
    <app-dialog-content class="invite-form">
        <form [formGroup]="inviteForm"
              class="invite-form">

            <mat-form-field appearance="outline"
                            floatLabel="always">
                <mat-label i18n="@@common-role">Role</mat-label>

                <mat-select formControlName="role">
                    <mat-option *ngFor="let role of OrganizationRoles"
                                [value]="role">{{ ApplogicUtils.getOrganizationRoleLabel(role) }}</mat-option>
                </mat-select>
                <mat-hint i18n="@@org-invite-role-hint">Please select the role invitees will have in the organization.
                </mat-hint>
            </mat-form-field>


            <mat-form-field appearance="outline"
                            floatLabel="always">
                <mat-label i18n="@@common-emails">Emails</mat-label>
                <textarea type="email"
                          matInput
                          formControlName="emails"
                          required></textarea>
                <mat-hint i18n="@@org-invite-email-hint">Enter one email address per line.</mat-hint>
            </mat-form-field>
        </form>

    </app-dialog-content>
    <button mat-stroked-button
            matDialogClose
            appDialogAction
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            [disabled]="!inviteForm.valid || loading.isLoading || (loading.loadingChange | async)"
            (click)="submitInviteForm()">
        <span i18n="@@common-invite">Invite</span>
    </button>
</app-dialog>