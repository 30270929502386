
import { ID, Member, Model } from "@uon/model";
import { User } from "../user/user.model";
import { AppReceipt } from "./app-receipt.model";


@Model()
export class AppReceiptAssociation {

    @ID()
    id: string;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    // The app receipt.
    @Member()
    appReceipt: AppReceipt;

    // The user associated with this installation, if any
    @Member()
    user: string;

    // The installation id.
    @Member()
    installId: string;

    @Member()
    data: object;
}
