<div class="product-selection">
    <h3 i18n="@@products-select-choose-subscription-period">Choose the subscription period</h3>
    <mat-button-toggle-group class="app-button-toggle--full-width subscription-plan-selection"
                             name="fontStyle"
                             aria-label="Font Style"
                             [(ngModel)]="subscriptionPlan">
        <mat-button-toggle *ngFor="let plan of plans"
                           (change)="onchangeSubscriptionPlan($event);"
                           [value]="plan">{{getPlanLabel(plan)}}</mat-button-toggle>
    </mat-button-toggle-group>
    <h3 i18n="@@products-select-product-selection">Select your products</h3>
    <div class="products">
        <div class="products-show"
             *ngFor="let product of products; let i = index">
            <div class="card">
                <div [ngClass]="{'big-img-div': bigIcons, 'img-div': !bigIcons}">
                    <img [src]="product.info.imageUrl"
                         alt="product_image">
                </div>
                <div class="text-div">
                    <span class="product-name">{{product.info.currentLocalizedName}}, {{product.price?.amount.value | apCurrency:product.price?.amount.currency}}
                        <span *ngIf="subscriptionType == 'organization'"
                              i18n="@@product-per-student">per student</span>
                    </span>
                    <!-- <mat-checkbox (click)="$event.stopPropagation()"
                                   (change)="$event ? selection.toggle(product) : null; calculateTotalAmount()"
                                   [checked]="selection.isSelected(product)">
                         </mat-checkbox> -->
                </div>
                <div [ngClass]="{'form-side': true, 'form-side-horiz': !bigIcons, 'form-side-vertical': bigIcons}">
                    <div class="profile-count">
                        <app-quantity-input type="number"
                                            class="quantity-input"
                                            [(quantityModel)]="product.seats"
                                            (onQuantityModelChanged)="onQuantityChanged($event, product); onChange()">
                        </app-quantity-input>
                    </div>
                    <div class="game-price"
                         *ngIf="showSubTotal">
                        <div>
                            <mat-form-field appearance="outline"
                                            floatLabel="always"
                                            style="width: 90%">
                                <mat-label i18n="@@product-sub-total-label">Sub Total</mat-label>
                                <input type="text"
                                       name="price"
                                       matInput
                                       [(ngModel)]="pricesLabel[product.code]"
                                       disabled>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="error-container"
         *ngIf="!isHiddenSeatError"
         i18n="@@org-payment-product-error-message">
        minimum {{minimumSeats}} license
    </div>
</div>