import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Currency, CurrencyOptions } from '@applogic/model';
import { LanguageService } from '../services/language.service';
import { ProductService } from '../services/product.service';
import { ApCurrencyCommonPipe } from './apcurrency-common.pipe';

@Pipe({
    name: 'apCurrencyCodeSymbol'
})
export class ApCurrencySymbolPipe extends ApCurrencyCommonPipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer, languageService: LanguageService, private currencyPipe: CurrencyPipe, private productService: ProductService) {
        super(languageService);
    }

    transform(currencyVal: string | Currency, options?: CurrencyOptions): string {

        // If the currency code is not set yet just print nothing.
        if (!currencyVal) return "";

        if (currencyVal instanceof Currency) {
            const currency: Currency = currencyVal as Currency;
            if (currency) {
                return currency.toCodeWithSymbol(this.fillOptions(options));
            }
        }
        else {
            const currencyCode: string = currencyVal as string;

            const currency = this.productService.getCurrencyFromCache(currencyCode);
            if (currency) {
                return currency.toCodeWithSymbol(this.fillOptions(options));
            }

            console.error("Unable to retrieve the currency object for '" + currencyCode + "' so simply display the currency code");
            return currencyCode;
        }
    }

    

}
