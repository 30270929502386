import { Model, ID, Member, ArrayMember } from "@uon/model";
import { MultiCounters } from "../common/multi-counters";
import { CommonModel } from "../common/common.model";
import { Subscription } from "./subscription.model";
import { SubscriptionAllocationMode } from "./subscription-allocation-mode";


// To allocate subscription seats.
@Model()
export class SubscriptionAllocation {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    active: boolean;

    @Member()
    expiresOn: Date; // Should be binded to the subscription expiresOn property.

    @Member()
    subscriptionId: string;

    @Member()
    filter: any;

    @Member()
    usedSeats: MultiCounters;

    @Member()
    freeSeats: MultiCounters;

    @Member()
    mode: SubscriptionAllocationMode;

    getNbSeats(type: string, productCode?: string): number {

        let counters: MultiCounters;

        if (type == 'freeSeats') {
            counters = this.freeSeats;
        }
        else if (type == 'usedSeats') {
            counters = this.usedSeats;
        }

        if (!productCode) {
            let total: number = 0;

            for(const key of Object.keys(counters))
            {
                total += counters[key] ? counters[key] : 0;
            }

            return total;
        }
        else {
            let seats = counters[productCode];
            return seats ? seats : 0;
        }

        return 0;
    }

    getSeats(type: string): MultiCounters {

        if (type == 'freeSeats') {
            return this.freeSeats;
        }
        else if (type == 'usedSeats') {
            return this.usedSeats;
        }

    }
}