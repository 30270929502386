<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[validityTemplate]"
                        [templateColumns]="[productSeatColumnTemplate]"></renderer-templates>
</app-common-list-renderer>

<ng-template #validityTemplate
             let-data>
    <app-subscription-validity-label [subscription]="data.row.sub"
                                     [showNumber]="false">
    </app-subscription-validity-label>
</ng-template>

<ng-template #productSeatColumnTemplate
             let-data>
    <app-seat-token [displayType]="'icon-only'"
                    [productCode]="data?.column.parameters.productCode"></app-seat-token>
</ng-template>