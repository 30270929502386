import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';


@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    themes: string[] = ["no-theme", "previous-theme", "light-theme", "dark-theme", "light-dynamic-theme", "dark-dynamic-theme", "light-theme-no-comp", "dark-theme-no-comp"];
    themeIdx: number = 2;
    theme: string;

    isDark: boolean = false;

    visible: boolean = false;

    onThemeChanged = new EventEmitter<string>();

    constructor(protected activatedRoute: ActivatedRoute, private ls: LocalStorageService) {
        
        if(environment.theme.showToggle || this.activatedRoute.snapshot.queryParamMap.get("show-theme-toggle")) {
            this.visible = true;
        }

        let defaultTheme = environment.theme.default || this.activatedRoute.snapshot.queryParamMap.get("theme");
        if(defaultTheme) {
            if(defaultTheme == "persistent") {
                defaultTheme = ls.get("theme") || "light-theme";
            }
            const idx = this.themes.findIndex(t => t == defaultTheme);
            if(idx != -1) {
                this.themeIdx = idx;
            }
        }

        this.updateTheme();
    }

    toggleTheme() {
        this.themeIdx++;
        if(this.themeIdx >= this.themes.length) {
            this.themeIdx = 0;
        }
        document.body.classList.remove('starting-theme');
        this.updateTheme();
    }

    updateTheme() {
        this.theme = this.themes[this.themeIdx];

        document.body.classList.remove('starting-theme');
        document.body.classList.add('dynamic-material-color');

        for(const theme of this.themes) {
            if(theme != this.theme) {
                document.body.classList.remove(theme);
            }
            else {
                document.body.classList.add(theme);
            }
        }

        this.onThemeChanged.emit(this.theme);
    }

    setTheme(theme: string) {
        const themeIdx = this.themes.findIndex(a => a == theme);
        if(themeIdx != -1) {
            if(themeIdx != this.themeIdx) {
                this.themeIdx =  themeIdx;
                this.ls.set("theme", theme);
                this.updateTheme();
            }
        }
    }
}
