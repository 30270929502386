import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

export interface SimpleChartDialogData {
    data: any,
    options: any
}

@Component({
    selector: 'app-simple-chart-dialog',
    templateUrl: './simple-chart-dialog.component.html',
    styleUrls: ['./simple-chart-dialog.component.scss']
})
export class SimpleChartDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: SimpleChartDialogData,
        private mdDialogRef: MatDialogRef<SimpleChartDialogComponent>) { }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: SimpleChartDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<SimpleChartDialogComponent, SimpleChartDialogData>(dialogService, dialog, SimpleChartDialogComponent, {
            data,
            width: "700px",
            maxWidth: "700px",
            minWidth: "300px",
            maxHeight: "calc(100vh - 64px)",
            position: {
                top: "52px",
            },
            panelClass: 'makeItMiddle'
        }, config);
    }
}
