<app-page i18n-title="@@common-not-found"
          title="Not Found"
          type="centered">
    <app-page-content>

        <app-design-card class="not-found-card"
                         appearance="raised"
                         [clickable]="false">
            <design-card-content>
                <h1 typeScale="display-large" style="text-align: center">404</h1>
                <p i18n="@@not-found-content">
                    It looks like the page you are looking for does not exists.
                </p>
                <div class="app-form-actions">

                    <button mat-flat-button
                            [routerLink]="['/']"
                            i18n="@@not-found-back-to-overview">
                        GO BACK TO OVERVIEW
                    </button>

                </div>
            </design-card-content>
        </app-design-card>
    </app-page-content>
</app-page>