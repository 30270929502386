import { Component } from '@angular/core';

@Component({
    selector: 'app-billing-page',
    templateUrl: './billing-page.component.html',
    styleUrl: './billing-page.component.scss'
})
export class BillingPageComponent {

}
