import { Directive } from '@angular/core';

@Directive({
    selector: '[appDefaultPageHeaderCard]',
    host: {
        'class': 'app-default-page-header-card'
    }
})
export class DefaultPageHeaderCardDirective {

    constructor() { }

}
