import { Component, OnInit } from '@angular/core';
import { COLLAPSE_HEIGHT, FADE_IN_COLLAPSE_OUT } from 'src/app/animations/collapse';
import { FADE_IN_OUT } from 'src/app/animations/fade';
import { InviteService, CollaboratorInviteSummary } from '../invite.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { AuthService } from 'src/app/auth/auth.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { Model } from '@uon/model';
import { Classroom, ClassroomCollaboratorInvite, User } from '@applogic/model';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


enum Step {
    Login = 'login',
    Accept = 'accept',
    Error = 'error'
}

@Component({
    selector: 'app-collaborator-invite-page',
    templateUrl: './collaborator-invite-page.component.html',
    styleUrls: ['./collaborator-invite-page.component.scss'],
    animations: [FADE_IN_OUT, COLLAPSE_HEIGHT, FADE_IN_COLLAPSE_OUT]
})
export class CollaboratorInvitePageComponent implements OnInit {

    Step = Step;

    inviteSummary: CollaboratorInviteSummary;

    errorObject: any;

    isLoading = true;

    _step: Step;

    token: string;

    constructor(
        private service: InviteService,
        private route: ActivatedRoute,
        readonly loading: LoadingService,
        public routerNav: Router,
        readonly auth: AuthService,
        readonly formService: FormService
    ) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.params.token;
        if (this.token.startsWith("test-")) {
            this.initTestMode();
        }
        else {
            this.statusInvitation();
        }
    }

    initTestMode() {
        this._step = this.token.substring(5) as Step;

        this.inviteSummary = {
            classroom: Model.New(Classroom, {
                
            }),
            invite: Model.New(ClassroomCollaboratorInvite, {
                email: 'invited-user@example.com',
                invited: Model.New(User, {
                    id: ApplogicUtils.generateRandomString(10, 'ABCDEFGHIJKLMNPQRSTUVWXY23456789'),
                    email: 'invited-user@example.com',
                    fullName: 'Invited User'
                }),
                invitedBy: Model.New(User, {
                    email: 'inviter-user@example.com',
                    fullName: 'Inviter User'
                })
            })
        }

        this.isLoading = false;
    }

    //  Get a Invitation Status 

    statusInvitation() {
        const token = this.token;

        this.service.getCollaboratorsInviteSummary(token)
            .subscribe({
                next: (invite) => {
                    if (!this.auth.isLoggedIn() || (this.auth.user.email != invite.invite.email)) {
                        this._step = Step.Login;
                    }
                    else {
                        this._step = Step.Accept;
                    }

                    this.isLoading = false;
                    this.inviteSummary = invite;
                }, error: (err) => {
                    this.errorObject = err;
                    this._step = Step.Error;
                    this.isLoading = false;
                }
            })
    }

    /**
     * Function to login.
     */
    submitLoginForm(formData: UntypedFormGroup) {

        const token = this.route.snapshot.params.token;
        this.errorObject = null;

        this.auth.tryLogin(this.inviteSummary.invite.email, formData.value.password, "collaborator invite login").subscribe({
            next: (val) => {
                this.statusInvitation();
            }, error: (err) => {
                this.errorObject = err;
            }
        })
    }

    /**
     * Function to accept an invitation.
     */
    acceptInvite() {
        this.errorObject = undefined;
        const token = this.route.snapshot.params.token;
        this.service.acceptInviteCollaborator(token).subscribe({
            next: (response) => {
                this.routerNav.navigate(["/classrooms"])
            }, error: (err) => {
                this.errorObject = err;
            }
        })
    }

}



