<ng-template #leftTitleTemplate>
    <ng-container *ngIf="currentStep > 1">
        <button mat-button
                appDialogAction
                (contextmenu)="showDebugs($event)"
                (click)="prevStep()">
            <mat-icon>keyboard_backspace</mat-icon>
            <span i18n="@@common-back">Back</span>
        </button>
        <div class="back-button-vert-line"></div>
    </ng-container>
</ng-template>

<ng-template #rightTitleTemplate>
    <span class="secondary-text step-progress-label">{{ApplogicUtils.getStepXofY(currentStep, stepCount)}}</span>
</ng-template>

<ng-template #belowHeaderTemplate>
    <app-progress-step [nbSteps]="stepCount"
                       [currentStep]="currentStep"></app-progress-step>
</ng-template>