<mat-menu #subscriptionMenu="matMenu" overlapTrigger="true" xPosition="before">

    <ng-template matMenuContent let-subscription="subscription">
        <div
            [ngClass]="{'menu-large':subscription.type == 'organization','menu-short':subscription.type != 'organization'}">
            <div *ngIf="canCancelSubscription(subscription)">
                <button mat-menu-item (click)="cancelConfirmationDialog(subscription)">

                    <mat-icon color="warn">close</mat-icon>

                    <span i18n="@@common-cancel-subscription">Cancel subscription</span>

                </button>

                <!-- <button mat-menu-item 
                      *ngIf="subscription.user">
            
                        <mat-icon color="warn">close</mat-icon>
            
                        <span i18n="@@active-automatic-renewal-button">Activate automatic renewal</span>
            
                    </button> -->
            </div>
            <div *ngIf="isOwner(subscription) && subscription.stripeDetails?.subsId && (checkSubscriptionExpire(subscription.expiresOn) ==  false) ">

                <button mat-menu-item (click)="updatePaymentMethod(subscription)">

                    <mat-icon color="warn">replay</mat-icon>

                    <span i18n="@@subscription-card-menu-update-payment-method">Update payment method</span>

                </button>

            </div>
            <div *ngIf="checkSubscriptionExpire(subscription.expiresOn) ==  true">

                <button mat-menu-item>

                    <mat-icon color="warn">replay</mat-icon>

                    <span i18n="@@reactive-subscription-button">Reactivate subscription</span>

                </button>

            </div>

            <app-subscription-mode-panel [subscription]="subscription" *ngIf="canChangeAllocationMode"></app-subscription-mode-panel>
        </div>
    </ng-template>

</mat-menu>