<!-- <app-design-card class="default-card"
                 *ngFor="let row of rows"
                 [clickable]="false">
    <design-card-content *ngIf="parent.templatesOptions?.cardTemplate">
        <ng-template [ngTemplateOutlet]="parent.templatesOptions?.cardTemplate"
                     [ngTemplateOutletContext]="{$implicit: {row}}"></ng-template>
    </design-card-content>
</app-design-card> -->
<div class="flex-row-responsive list-card-container" [class]="parent.templatesOptions?.cardListClass || ''">
    <ng-container *ngFor="let row of rows">
        <ng-template *ngIf="parent.templatesOptions?.cardTemplate && _list"
                     [ngTemplateOutlet]="parent.templatesOptions?.cardTemplate"
                     [ngTemplateOutletContext]="{$implicit: {row, list: _list, renderer: parent}}"></ng-template>
    </ng-container>
</div>

<app-common-list-paginator [list]="list"
                           [parent]="parent"></app-common-list-paginator>