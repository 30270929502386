import { Model, ID, Member, ArrayMember, MinLength, MaxLength, ValidateEmail } from '@uon/model';
import { Address } from '../common/address.model';
import { FieldValidations } from '../common/field.validations';
import { UserServiceLinks } from './service-links.model';
import { UserEmailPreferences } from './user-email-preferences.model';
import { UserRole } from './user-role.model';

@Model()
export class UserDenormData {

    @Member()
    hasAccessOrgSubscription: boolean;
}

@Model()
export class User {

    /**
     * 
     * @param _id 
     */
    constructor(_id?: string) {
        this.id = _id;
    }

    @ID()
    id: string;

    @Member()
    registered: boolean;

    /**
     * The user's fullname
     */
    @Member({
        validators: FieldValidations.fullName.getValidators(false)
    })
    fullName: string;

    /**
     * Shortcut to user's first name
     */
    get firstName() {
        if(!this.fullName) return this.fullName;
        
        return this.fullName.split(' ')[0];
    }

    /**
     * The user's email
     */
    @Member({
        validators: FieldValidations.email.getValidators(false)
    })
    email: string;

    /**
     * The user's phone number
     */
    @Member()
    phone: string;
    
    /**
     * User's hashed password
     */
    @Member({
        validators: FieldValidations.password.getValidators(false)
    })
    password: string;

    /**
     * Whether the user wants to be on the mailing list
     */
    @Member()
    mailingList: boolean;

    @Member()
    emailPreferences: UserEmailPreferences;

    /**
     * The user's preferred language
     */
    @Member()
    lang: string;

    
    /**
     * The user's preferred currency
     */
     @Member()
     currency: string;

    /**
     * Document creation time.
     */
    @Member()
    createdOn: Date;

    /**
     * User registration date
     */
    @Member()
    registeredOn: Date;

    /**
     * User registration source
     */
    @Member()
    registeredSource: string;

    /**
     * Last update time
     */
    @Member()
    updatedOn: Date;

    /**
     * 
     */
    @ArrayMember(String)
    roles: UserRole[];

    /**
     * 
     */
    @Member()
    rolesParams: {[key: string]: any};

    @Member()
    isDeleted?: boolean;

    @Member()
    serviceLinks: UserServiceLinks;

    // Equivalent to the shipping address. It is used to calculated taxes.
    @Member()
    address: Address;

    @Member()
    lastSeenOn: Date;

    @Member()
    denormData: UserDenormData;
}

export enum UserType {
    Student = 'student',
    User = 'user'
}