<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[cellTemplate]"
                        [templateColumns]="[]"></renderer-templates>
</app-common-list-renderer>


<ng-template #cellTemplate
             let-data>
    <div class="progress-table-date-cell"
         *ngIf="data.row as element">
        <span *ngFor="let game of element.dates?.[data.column.key]; ;let i=index;"
              class="game-array">
            <div *ngFor="let chip of MMOGameProgressUtils.getDateChips(game, selectedSeason)"
                 class="particulargame"
                 [style.borderColor]="chip.color_code"
                 (mouseenter)="setCurrentChip(chip)"
                 (click)="setCurrentChip(chip)"
                 [ngStyle]="{'background-color':chip.isPlay && chip.completedlevelssublevel ? chip.color_code : '#ffffff', 
                    'color':chip.isPlay && chip.completedlevelssublevel ? '#ffffff' : '#000000'}">
                <div [ngbPopover]="popOver"
                     popoverClass="app-popover--plain-tooltip"
                     (mousewheel)="p.close(false)"
                     #p="ngbPopover">
                    <span class="show-number">{{chip.label}}</span>
                </div>
            </div>
        </span>
    </div>
</ng-template>

<ng-template #popOver>
    <app-mmo-tooltip context='date'
                     [chip]="currentChip"></app-mmo-tooltip>
</ng-template>