import { Member, Model } from "@uon/model";
import { Student } from "../user/student.model";


@Model()
export class ClassroomHomeworkStudent {

    @Member()
    studentId: string;

    @Member()
    addedOn: Date;
}