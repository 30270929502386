import { Component, OnInit, Input } from '@angular/core';
import { FADE_IN_OUT } from 'src/app/animations/fade';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    animations: [FADE_IN_OUT],
    host: {
        '[style.fontSize]': "size + 'em'"
    }
})
export class LogoComponent implements OnInit {


    @Input()
    size: number = 2.4;

    @Input()
    logoOnly: boolean = false;

    @Input()
    blackColor: boolean = false;

    @Input()
    responsive: boolean = true;

    constructor( private router: Router ) { }

    ngOnInit(): void {
    }

}
