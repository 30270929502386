import { Model, ID, Member } from "@uon/model";
import { User } from "../user/user.model";



@Model()
export class LegacyLicense {

    @ID()
    id: string;

    @Member()
    user: User;

    @Member()
    productCode: string;

    @Member()
    licenseType: 'single' | 'volume' | 'iap' | 'platform';

    @Member()
    seatCount: number;

    @Member()
    lastReactivation: Date;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    expiresOn: Date;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

}