import { Model } from "@uon/model";
import { OnboardingForm } from "../onboarding-form.model";
import { SubscriptionAllocationMode } from "../../subscription/subscription-allocation-mode";
import { MultiCounters } from "../../common/multi-counters";

export enum OnboardingSubscriptionStep {
    Delegate = 'delegate',
    AllocationMode = 'allocation-mode',
    Invite = 'invite',
    AssignStudentsToken = 'assign-students-token',
    ClassroomCreateOrSelect = 'create-or-select-classroom',
    CreateClassroom = 'create-classroom',
    SelectClassroom = 'select-classroom',
    Thanks = 'thanks',
    Completed = 'completed'
}

export interface OnboardingSubscriptionUserInvite {
    user: {
        id?: string;
        email?: string;
        username?: string;
        fullName?: string;
        selected?: boolean;
        emailDeliveryId?: string;
    }
    seats?: MultiCounters;
    processed?: boolean;

}

export interface ClassroomSeatsStudentAllocation {
    student: {
        id: string;
        fullName: string;
        username: string;
        password: string;
    },
    seats: {[productCode: string]: boolean};
    processed?: boolean;
}

export interface OnboardingSubscriptionClassroomSeatsAllocation {
    classroomId?: string;
    slug?: string;
    name?: string;

    allocations: ClassroomSeatsStudentAllocation[];
}

export interface OnboardingSubscriptionData {
    delegate?: boolean;
    allocationMode?: SubscriptionAllocationMode;
    createOrSelectClassroom?:'create'|'select';
    classroomSeatsAllocation?: OnboardingSubscriptionClassroomSeatsAllocation;
    users?: OnboardingSubscriptionUserInvite[];
    shareWithTi?: boolean;
    tiEmail?: string;
    tiEmailDeliveryId?: string;
}

@Model()
export class OnboardingSubscriptionForm extends OnboardingForm {

    constructor() {
        super();
    }

    getData() {
        return this.data as OnboardingSubscriptionData;
    }

    isDelegateSet() {
        const data = this.data as OnboardingSubscriptionData;
        return data?.delegate != undefined;
    }

    isDelegate() {
        const data = this.data as OnboardingSubscriptionData;
        return !!data?.delegate;
    }

    isAllocationModeSet() {
        const data = this.data as OnboardingSubscriptionData;
        return data?.allocationMode != undefined;
    }

    getAllocationMode(): SubscriptionAllocationMode {
        const data = this.data as OnboardingSubscriptionData;
        return data?.allocationMode;
    }

    doCreateClassroom() {
        const data = this.data as OnboardingSubscriptionData;
        return data?.createOrSelectClassroom == "create";
    }
}