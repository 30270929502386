import { trigger, style, state, transition, animate } from '@angular/animations';


export const FADE_IN_OUT = trigger('fadeInOut', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s linear', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate('0.25s linear', style({ opacity: 0 }))
    ])
])


export const OPACITY_IN_OUT = trigger('opacityInOut', [

    state('true', style({ opacity: 1 })),
    state('false', style({ opacity: 0 })),

    transition('false <=> true', [
        animate('0.25s linear')
    ])
])