import { SiteEventCallback } from "./site-event-callback";
import { SiteEventService } from "./site-event.service";

/**
 * For an easier events management.
 */
export class SiteEventsBinder {
    private events: { [key:string]: SiteEventCallback} = {};
    private registered: boolean = false;

    constructor(private siteEvents: SiteEventService) {

    }

    /**
     * Add a callback for an event.
     * 
     * If "register()" was already call, the event will be registered immediately.
     * Otherwise you need to call "register()" to register all events added.
     * 
     * @param {string} eventName The event name.
     * @param {SiteEventCallback} callback The callback to be called. (For a class method create the callback this way: this.onEvent.bind(this))
     */
    public addCallback(eventName: string, callback: SiteEventCallback) {
        if(this.registered && this.events[eventName]) {
            console.error("Callback '" + eventName + "' will be overwritten!");
            this.siteEvents.unregisterEvent(eventName, this.events[eventName]);
        }

        this.events[eventName] = callback;

        if(this.registered) {
            this.siteEvents.registerEvent(eventName, callback);
        }
    }

    /**
     * Register all events added using "addCallback".
     */
    public register() {
        if(this.registered) return;
        this.registered = true;
        for(const key of Object.keys(this.events)) {
            this.siteEvents.registerEvent(key, this.events[key]);
        }
    }

    /**
     * Unregister all events added using "addCallback".
     */
    public release() {
        if(!this.registered) return;
        this.registered = false;
        for(const key of Object.keys(this.events)) {
            this.siteEvents.unregisterEvent(key, this.events[key]);
        }
    }
}


