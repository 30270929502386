import { Component, Input } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BaseFieldConfig } from '../../custom-form-field-config';
import { AbstractCustomFormFieldComponent } from '../abstract-custom-form-field/abstract-custom-form-field.component';
import { FormService } from 'src/app/services/form.service';


export interface ToggleFieldConfig extends BaseFieldConfig {
    hint?: string;
}


@Component({
    selector: 'app-custom-form-toggle-field',
    templateUrl: './custom-form-toggle-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-toggle-field.component.scss']
})
export class CustomFormToggleFieldComponent extends AbstractCustomFormFieldComponent {

    @Input()
    data: ToggleFieldConfig;

    constructor(protected formService: FormService) {
        super();
    }
    
    onFieldToggleChanged(ev: MatSlideToggleChange) {

    }

}
