import { ArrayMember, Member, Model } from "@uon/model";
import { ApiDirectoryServer } from "./api-directory-server";

/**
 * 
 */
@Model()
export class ApiDirectoryList {

    @Member()
    public label: {[id: string]: string};

    @Member()
    public redirect: string;

    @ArrayMember(ApiDirectoryServer)
    public servers: ApiDirectoryServer[];

    //#region NON SERIALIZABLES

    public currentServer: ApiDirectoryServer;

    //#endregion NON SERIALIZABLES
}