<app-page i18n-title="@@my-classrooms-app-title"
          [title]="auth.user.firstName + '\'s classrooms'">
    <app-page-header2-card appDefaultPageHeaderCard
                           bgImageUrl="assets/headers/classroom-list-header.jpg">
        <page-header-card-extended-title [count]="classroomList.count">
            <!-- <app-title i18n="@@my-classrooms-app-title">{{auth.user.firstName}} 's classrooms</app-title> -->
            <app-title i18n="@@common-classrooms-heading">Your Classrooms</app-title>
        </page-header-card-extended-title>
        <design-card-subtitle i18n="@@common-classrooms-sub-heading">
            Access classrooms you collaborate to, or create new ones.
            A classroom is part of an organization, and you can play a role in more than one organization.
        </design-card-subtitle>
    </app-page-header2-card>
    <app-page-content>
        <app-classrooms-list [canCreateClass]="canCreateClass"
                             [checkbox]="false"
                             mode="list"
                             context="for-user"
                             #classroomList></app-classrooms-list>
    </app-page-content>
</app-page>