<app-design-card class="card-design-organization"
                 [routerLink]="getRouterLink()"
                 [clickable]="true">
    <design-card-header>
        <app-common-list-card-element-renderer [cardData]="data"
                                               columnKey="selection"
                                               [alwaysVisible]="false"></app-common-list-card-element-renderer>
        <span class="spacer"></span>
        <app-common-list-card-element-renderer [cardData]="data"
                                               columnKey="actions"></app-common-list-card-element-renderer>
    </design-card-header>
    <design-card-image>
        <app-shape type="circle"
                   scale="medium"
                   [styles]="ApplogicUtils.getStylesFromString(data.row.id)">
            {{ApplogicUtils.createInitials(data.row.name)}}
        </app-shape>
    </design-card-image>
    <design-card-title class="app-md-title-medium">{{data.row.name}}</design-card-title>
    <design-card-subtitle class="app-md-title-small">{{ApplogicUtils.getOrganizationTypeLabel(data.row?.type)}}</design-card-subtitle>
    <design-card-footer>
        <mat-divider class="footer-divider"></mat-divider>
        <app-common-list-card-element-renderer [cardData]="data"
                                               columnKey="_memberCount"
                                               class="secondary-text"></app-common-list-card-element-renderer>
        <span class="spacer"></span>
        <app-common-list-card-element-renderer [cardData]="data"
                                               columnKey="createdOn"
                                               class="secondary-text"></app-common-list-card-element-renderer>
    </design-card-footer>
</app-design-card>