import { Component, Input } from '@angular/core';
import { CommonListDisplayMode, CommonListRendererComponent } from '../../common-list-renderer/common-list-renderer.component';

@Component({
    selector: 'app-common-list-display-mode-select',
    templateUrl: './common-list-display-mode-select.component.html',
    styleUrl: './common-list-display-mode-select.component.scss'
})
export class CommonListDisplayModeSelectComponent {

    @Input()
    renderer: CommonListRendererComponent;
    
    onListClick() {
        this.renderer.displayMode = CommonListDisplayMode.Default;
    }

    onCardClick() {
        this.renderer.displayMode = CommonListDisplayMode.Card;
    }
}
