import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { AngularUtils } from 'src/app/services/angular-utils';
import { OrderService } from '../../order/order.service';
import { StripePaymentPanelComponent } from '../stripe-payment-panel/stripe-payment-panel.component';

@Component({
    selector: 'app-stripe-payment-dialog',
    templateUrl: './stripe-payment-dialog.component.html',
    styleUrls: ['./stripe-payment-dialog.component.scss']
})
export class StripePaymentDialogComponent implements OnInit {

    private subject: Subject<boolean>;

    public clientSecret: string;
    public subscriptionId: string;

    @ViewChild(StripePaymentPanelComponent)
    paymentPanel: StripePaymentPanelComponent;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private mdDialogRef: MatDialogRef<StripePaymentDialogComponent>,
        private orderService: OrderService) {
        this.subject = this.data.subject;
        this.clientSecret = this.data.clientSecret;
        this.subscriptionId = this.data.subscriptionId;
        if (AngularUtils.isDevMode()) {
            console.log("Client Secret: " + this.clientSecret + " SubscriptionId: " + this.subscriptionId);
        }
    }

    ngOnInit(): void {
        this.mdDialogRef.beforeClosed().subscribe(() => {
            const succeed: boolean = this.paymentPanel?.succeed ? true : false;
            if (!succeed) {
                this.orderService.deleteCheckout(this.subscriptionId);
            }
            this.subject.next(succeed);
        });
    }

    public static show(dialog: MatDialog, subscriptionId: string, clientSecret: string): Observable<any> {
        let subject = new Subject<any>();
        dialog.open(
            StripePaymentDialogComponent,
            {
                data: {
                    subject,
                    subscriptionId,
                    clientSecret
                },
                width: "400px",
                maxWidth: "80vw",
                minWidth: "300px",
                panelClass: 'makeItMiddle'
            }
        );

        return subject;
    }

    pay() {
        this.paymentPanel.pay().then((succeed) => {
            if(succeed) this.mdDialogRef.close();
        });
    }
 

}
