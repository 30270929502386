import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassroomService } from '../classroom.service';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface ExportStudentsDialogData {
    classroomId: string
}
@Component({
    selector: 'app-export-students-dialog',
    templateUrl: './export-students-dialog.component.html',
    styleUrls: ['./export-students-dialog.component.scss']
})
export class ExportStudentsDialogComponent implements OnInit {

    classroomId: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ExportStudentsDialogData,
        private mdDialogRef: MatDialogRef<ExportStudentsDialogComponent>,
        private classroomService: ClassroomService) {
            this.classroomId = this.data.classroomId;
    }

    ngOnInit(): void {
        
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: ExportStudentsDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<ExportStudentsDialogComponent, ExportStudentsDialogData, void>(dialogService, dialog, ExportStudentsDialogComponent, {
            data,
            width: "700px",
            maxWidth: "700px",
            minWidth: "300px",
            maxHeight: "calc(100vh - 64px)",
            position: {
            top: "52px",
            },
            panelClass: 'makeItMiddle'
        }, config);
    }

    exportResultTable(format: string) {
        this.classroomService.exportStudentsList(this.classroomId, format);
        this.mdDialogRef.close();
    }
}
