import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, TemplateRef } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { FADE_IN_OUT } from 'src/app/animations/fade';
import { AccountService } from 'src/app/account/account.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Organization } from '@applogic/model';
import { LanguageService } from 'src/app/services/language.service';
import { COLLAPSE_HEIGHT } from 'src/app/animations/collapse';
import { AngularUtils } from 'src/app/services/angular-utils';



const COLLAPSE_ANIM = trigger('collapse', [
    state('closed', style({
        width: 'var(--sidebar-width)',
        transform: 'translateX(-300px)'
    })),
    state('opened', style({
        width: 'var(--sidebar-width)',
        transform: 'translateX(0px)'
    })),

    transition('closed <=> opened', [
        animate('0.25s ease-in-out')
    ])
]);


@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss'],
    animations: [COLLAPSE_ANIM, COLLAPSE_HEIGHT, FADE_IN_OUT]
})
export class MainNavComponent implements OnInit {

    _animState = new BehaviorSubject<string>('closed');
    _opened: boolean = false;

    @ViewChild('currentOrg', { static: false })
    currentOrgElement: ElementRef<HTMLElement>;

    @ViewChild('orgSelectTemplate', { static: true })
    orgSelectTemplate: TemplateRef<any>;


    private selectOverlayRef: OverlayRef;

    protected _testMode: boolean = false;

    constructor(readonly nav: NavigationService,
        readonly account: AccountService,
        readonly language: LanguageService,
        private overlay: Overlay,
        private viewContainer: ViewContainerRef
    ) {
        
    }

    ngOnInit(): void {
        this._testMode =  AngularUtils.isTestMode();
        this.nav.menuOpened.subscribe((val) => {
            this._opened = val;
            this.updateaAnimationState();
        })
    }

    updateaAnimationState() {
        this._animState.next(this._opened ? 'opened' : 'closed');
    }

   
    // openOrgSelect() {

    //     const bounds = this.currentOrgElement.nativeElement.getBoundingClientRect();

    //     let ref = this.selectOverlayRef = this.overlay.create({
    //         positionStrategy: this.overlay.position()
    //             .flexibleConnectedTo(this.currentOrgElement)
    //             .withPositions([{
    //                 originX: 'start',
    //                 originY: 'bottom',
    //                 overlayX: 'start',
    //                 overlayY: 'top',
    //               }])
    //             .withDefaultOffsetY(-4),
    //         backdropClass: 'cdk-overlay-transparent-backdrop',
    //         hasBackdrop: true,
    //         width: bounds.width
    //     });

    //     ref.backdropClick().subscribe(() => {

    //         ref.dispose();
    //     });


    //     let portal = new TemplatePortal(this.orgSelectTemplate, this.viewContainer);
    //     ref.attach(portal);
    // }

    selectOrg(org: Organization) {


        this.account.setCurrentOrg(org);
        this.selectOverlayRef.dispose();

    }

    isShowTooltip(org: Organization) {
        const ELE = document.getElementById(org.id);
        let isShow = false;
        if (ELE.scrollWidth > 206) {
            isShow = true;
        }
        return isShow;
    }

}
