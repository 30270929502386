import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { map } from 'rxjs/operators';
import { GameProgressSelectableColumn } from "./game-progress-selectable-column";
@Pipe({
  name: "selectedColumns",
  pure: true,
})
export class GameProgressSelectedColumnsPipe implements PipeTransform {
  constructor(private localStoreService: LocalStorageService) {}

  transform(columns: GameProgressSelectableColumn[]) {
    return columns.filter(column => column.isSelectedValue);
  }
}
