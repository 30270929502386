import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiCounters, MultiCountersUtils, Product, SubAllocationsResponse, Subscription, SubscriptionAllocation, User } from '@applogic/model';
import { Subject } from 'rxjs';
import { UsersSelectionComponent } from 'src/app/organization-ui/users-selection/users-selection.component';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { FormService } from 'src/app/services/form.service';
import { LanguageService } from 'src/app/services/language.service';
import { ProductService } from 'src/app/services/product.service';
import { SeatService } from 'src/app/services/seat.service';

interface TransferSeatsDialogData {
    subAlloc: SubAllocationsResponse,
    // The allocation from which to transfer the seats.
    sourceAllocation: SubscriptionAllocation,
    targetAllocation?: SubscriptionAllocation,
    user?: User
}

@Component({
    selector: 'app-transfer-seats-dialog',
    templateUrl: './transfer-seats-dialog.component.html',
    styleUrls: ['./transfer-seats-dialog.component.scss']
})
export class TransferSeatsDialogComponent implements OnInit {

    subAlloc: SubAllocationsResponse;
    source: SubscriptionAllocation;
    target: SubscriptionAllocation;
    subscription: Subscription;

    showUserSelection: boolean = false;
    user: User;

    quantity: MultiCounters = {};

    products: Product[] = [];

    errorObject: any;

    @ViewChild(UsersSelectionComponent, {static: false})
    usersSelection: UsersSelectionComponent;

    wasQuantitySet: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: TransferSeatsDialogData,
        private mdDialogRef: MatDialogRef<TransferSeatsDialogComponent, SubscriptionAllocation>,
        private seatService: SeatService,
        public formService: FormService,
        public productService: ProductService) {
            this.subAlloc = this.data.subAlloc;
            this.source = this.data.sourceAllocation;
            this.target = this.data.targetAllocation;
            this.subscription = this.subAlloc.subscription;

            if(this.data.user) {
                this.setUser(this.data.user);
            }
    
            if(!this.user) {
                this.showUserSelection = true;
            }

            this.productService.getProducts().then((products) => {
                this.products = products.filter((p) => this.subscription.grants.find((g) => g.productShortCode == p.shortCode) );
            });
        }

    ngOnInit(): void {


    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: TransferSeatsDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<TransferSeatsDialogComponent, TransferSeatsDialogData, SubscriptionAllocation>(dialogService, dialog, TransferSeatsDialogComponent, {
            data,
            width: "550px",
            maxWidth: "550px",
            minWidth: "300px",
            panelClass: 'makeItMiddle'
        }, config);
    }

    async sendSeats() {
        this.errorObject = undefined;

        let alloc: SubscriptionAllocation = this.target;

        if(!alloc.id) {
            alloc = await this.seatService.addAllocation(this.subscription.id, this.user.id).toPromise();
            this.target = alloc;
            this.subAlloc.addAllocation(alloc);
        }

        this.seatService.transferSeats(this.source, alloc, this.quantity).subscribe(() => {
            MultiCountersUtils.move(this.source.freeSeats, alloc.freeSeats, this.quantity);
            this.mdDialogRef.close(alloc);
        }, (err) => {
            this.errorObject = err;
        });
    }

    onQuantityChanged(c: MultiCounters) {
        for(const key of Object.keys(c)) {
            if(c[key]) {
                this.wasQuantitySet = true;
                return;
            }
        }

        this.wasQuantitySet = false;
    }

    onUserChange(u: User) {
        this.setUser(u);
    }

    setUser(u: User) {
        this.user = u;

        let alloc: SubscriptionAllocation;
        
        if(this.subAlloc.allocations) {
            alloc = this.subAlloc.allocations.find(a => a.filter?.userId == u.id);
        }

        if(!alloc) {
            alloc = new SubscriptionAllocation();
            alloc.freeSeats = {};
            alloc.usedSeats = {};
        }

        this.target = alloc; 

        this.quantity = {};
        this.wasQuantitySet = false;

        this.subscription.grants.forEach((g) => {
            this.quantity[g.productShortCode.toLowerCase()] = 0;
        })
        
    }
}
