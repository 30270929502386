import { ArrayMember, Member, Model } from "@uon/model";

@Model()
export class OnboardingCondition {
    @Member() 
    type: string;

    @Member() 
    params: object;

    // For composite conditions.
    @ArrayMember(OnboardingCondition)
    conditions: OnboardingCondition[];

    transitionId: string;
}