import { Component, Input } from '@angular/core';
import { ClassroomRole } from '@applogic/model';
import { ApplogicUtils } from 'src/app/services/applogic-utils';

@Component({
    selector: 'app-classroom-role-card',
    templateUrl: './classroom-role-card.component.html',
    styleUrl: './classroom-role-card.component.scss'
})
export class ClassroomRoleCardComponent {
    ApplogicUtils = ApplogicUtils;

    ClassroomRole = ClassroomRole;


    @Input()
    role: ClassroomRole;
}
