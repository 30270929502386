import { Directive } from '@angular/core';

@Directive({
    selector: '[app-dialog-content],[appDialogContent],app-dialog-content',
    host: {
        class: 'app-dialog-content'
    }
})
export class DialogContentDirective {

    constructor() { }

}
