import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FormService } from 'src/app/services/form.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroupDirective, AbstractControl, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { LanguageService } from 'src/app/services/language.service';
import { AuthService } from '../../auth/auth.service';
import { ModelNo, RegisterUserForm, User, UserEmailPreferences, UserServiceType, Utils } from '@applogic/model';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { environment } from 'src/environments/environment';
import { SocialLoginData, SocialUser } from 'src/app/social/social-login-data';
import { SetPlaceholderPipe } from '../set-placeholder.pipe';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AngularUtils } from 'src/app/services/angular-utils';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Model } from '@uon/model';


enum Fields {
    Email = "email",
    Password = "password",
    ConfirmPassword = "confirmpassword",
    FullName = "fullname",
    ProfileName = "profilename",
    Language = "language",
    Context = "context",
    UserEmailPreferences = "useremailprefs",
    InviteCode = "invitecode",
    SocialLogin = "sociallogin",
    AcceptTerms = "accept-terms",
    AcceptNewsletter = "accept-newsletter"
};

enum FormType {
    Register = "register",
    LoginEmailStep = "login-email-step",
    LoginHiddenEmail = "login-hidden-email",
    LoginPasswordStep = "login-password-step",
    ForgotPassword = "forgotPassword",
    StudentForm = "student-form",
    UserLogin = "user-login",
    BillingInfo = "billing-info",
}

export enum UserAuthenticationResult {
    Logged,
    Registered,
    AlreadyLogged
};

export class BaseLoginRegisterFormData {
    public email: string;
    public password: string;
    public fullName: string;
    public inviteCode: string;
    public lang: string;
    public emailPreferences: UserEmailPreferences;
}

@Component({
    selector: 'app-base-login-register-form',
    templateUrl: './base-login-register-form.component.html',
    styleUrls: ['./base-login-register-form.component.scss']
})
export class BaseLoginRegisterFormComponent implements OnInit, OnChanges {

    FormType = FormType;

    public static registerUserEmail: string;
    public static registerUserName: string;

    errorObject: any;
    errorObjectGoogle: any;

    FieldType = Fields;

    @Input() showError: boolean = true;

    @Input()
    showLogo: boolean = false;

    @Input() email: string;

    @Input() formType: FormType;

    // If true, it will init form values with the current logged user.
    @Input() initWithLoggedUser: boolean;

    // If true, if user is logged, form will not be editable. (To be used with 'initWithLoggedUser')
    @Input() canSetEmailWhileLogged: boolean = true;

    // If true, the email will be read only if an email was provided in input.
    @Input() readonlyEmailIfProvided: boolean = false;


    @Input() canEditFullnameOfAnExistingAccount: boolean = false;

    // By default, when the password field is enabled, the field is required.
    // But when editing the user password, this field is not mandatory.
    @Input() isPasswordRequired: boolean = true;

    @Input() showRegisterMessage: boolean = false;

    // Set for fields to be poentially visible (because some field may be hidden base on other states).
    private fields: Set<Fields> = new Set<Fields>();

    baseLoginRegisterForm: UntypedFormGroup = new UntypedFormGroup({});
    isEmailHidden: boolean = true;
    isPasswordHidden: boolean = true;
    isFullNameHidden: boolean = true;
    isProfileHidden: boolean = true;
    isConfirmPasswordHidden: boolean = true;
    isUserEmailPrefsHidden: boolean = true;
    isInviteCodeHidden: boolean = true;
    isGoogleButtonHidden: boolean = false;

    emailPrefsFormGroup: UntypedFormGroup = new UntypedFormGroup({});

    @Input() formData: any;

    @Input() isHasToken: any;

    @Input() isLanguageShow: boolean;

    isAccountExist = false;

    isHiddenErrorEmailExist = true;

    emailExistMessage = $localize`:@@payment-user-email-not-exist-message:Create your Appligogiques account at the same time. Simply enter your password below.`;

    @Output() callApiOnPressEnter: EventEmitter<any> = new EventEmitter();

    @Output() onCheckUserAccountIsExist: EventEmitter<any> = new EventEmitter();

    @Output() onServiceLoginClick: EventEmitter<SocialLoginData> = new EventEmitter();

    @ViewChild('passwordField', { static: false }) passwordField: ElementRef<HTMLInputElement>;


    isUserLoggedIn: boolean;

    isEmailSameAsLoggedInUser: boolean = false;

    @Input() isStepNext: boolean;
    @Input() formName: string;

    @Input() smallAlternateLogin: boolean = true;

    @Input() useLabelAsPlaceholder: boolean = false;
    @Input() floatLabel: 'auto' | 'always' = 'always'

    serviceType: UserServiceType = UserServiceType.Appligogiques;
    serviceData: any;
    serviceEmail: string;

    private setPlaceholderPipe = new SetPlaceholderPipe();

    privacyLink: SafeUrl;
    termsLink: SafeUrl;

    acceptNewsletter: boolean = true;

    get invalid(): boolean {
        if (!this.baseLoginRegisterForm) return false;

        if (this.serviceType != UserServiceType.Google) {
            if (this.serviceData) {
                return true;
            }
        }

        return this.baseLoginRegisterForm.invalid;
    }

    constructor(public formService: FormService,
        readonly language: LanguageService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private loadingService: LoadingService,
        private sanitizer: DomSanitizer) {
        this.privacyLink = sanitizer.bypassSecurityTrustUrl($localize`:@@link-privacy:https://rebrand.ly/appligo-privacy-en`);
        this.termsLink = sanitizer.bypassSecurityTrustUrl($localize`:@@link-terms:https://rebrand.ly/appligo-terms-en`);
    }

    ngOnInit(): void {
        this.isUserLoggedIn = !this.authService.isLoggedIn();

        this.fields.clear();

        this.fields.add(Fields.Context);

        /**
         * in these condition form field is 
         * enable  according to its form type is matched
         */
        if (this.formType === FormType.LoginEmailStep) {
            this.fields.add(Fields.Email);
            if (environment.googleIdentityService?.enabled) {
                this.fields.add(Fields.SocialLogin);
            }
        } else if (this.formType === FormType.LoginPasswordStep) {
            this.fields.add(Fields.Password);
        } else if (this.formType === FormType.Register) {
            this.fields.add(Fields.Email);
            this.fields.add(Fields.FullName);
            this.fields.add(Fields.Password);
            this.fields.add(Fields.ConfirmPassword);
            if (environment.inviteMode?.enabled) {
                this.fields.add(Fields.InviteCode);
            }
            if (environment.googleIdentityService?.enabled) {
                this.fields.add(Fields.SocialLogin);
            }
            this.fields.add(Fields.UserEmailPreferences);
            if (this.isLanguageShow) {
                this.fields.add(Fields.Language);
            }
            // this.fields.add(Fields.AcceptTerms);
            this.fields.add(Fields.AcceptNewsletter);
        } else if (this.formType === FormType.ForgotPassword) {
            if (this.isHasToken) {
                this.fields.add(Fields.Password);
                this.fields.add(Fields.ConfirmPassword);
            } else {
                this.fields.add(Fields.Email);
            }
        } else if (this.formType === FormType.StudentForm) {
            this.fields.add(Fields.FullName);
            this.fields.add(Fields.ProfileName);
            this.fields.add(Fields.Password);
        } else if (this.formType === FormType.UserLogin) {
            this.fields.add(Fields.Email);
            this.fields.add(Fields.Password);
            if (environment.googleIdentityService?.enabled) {
                this.fields.add(Fields.SocialLogin);
            }
        } else if (this.formType === FormType.BillingInfo) {
            this.fields.add(Fields.Email);
            if (environment.googleIdentityService?.enabled) {
                this.fields.add(Fields.SocialLogin);
            }
            this.fields.add(Fields.FullName);
            this.fields.add(Fields.Password);
            if (environment.inviteMode?.enabled) {
                this.fields.add(Fields.InviteCode);
            }
            this.isHiddenErrorEmailExist = false;
        } else {
            this.fields.add(Fields.Password);
        }

        if (this.fields.has(Fields.Context)) {
            this.baseLoginRegisterForm.addControl('context', new UntypedFormControl(''));
        }

        /**
         * add email form control
         */
        if (this.fields.has(Fields.Email)) {
            this.baseLoginRegisterForm.addControl('email', this.formService.getFormControl('email'))
        }
        /**
         * add password form control
         */
        if (this.fields.has(Fields.Password)) {
            const passwordControl = this.formType === FormType.StudentForm ? "studentPassword" : "password";
            this.baseLoginRegisterForm.addControl('password', this.formService.getFormControl(passwordControl, undefined, this.isPasswordRequired));
        }

        /**
         * add full name control 
         */
        if (this.fields.has(Fields.FullName)) {
            this.baseLoginRegisterForm.addControl('fullName', this.formService.getFormControl('fullName'));
        }

        /**
         * add confirm password form control 
         */
        if (this.fields.has(Fields.ConfirmPassword)) {
            this.baseLoginRegisterForm.addControl('confirmPassword', this.formService.getFormControl('confirmPassword', undefined, this.isPasswordRequired))
        }

        /**
         * add language form control 
         */
        if (this.fields.has(Fields.Language)) {
            this.baseLoginRegisterForm.addControl("lang", new UntypedFormControl('fr', [Validators.required]));
        }

        /**
         * add the accept terms form control 
         */
        if (this.fields.has(Fields.AcceptTerms)) {
            this.baseLoginRegisterForm.addControl("acceptTerms", new UntypedFormControl(null, [Validators.required]));
        }

        /**
         * add profile form control
         */
        if (this.fields.has(Fields.ProfileName)) {
            this.baseLoginRegisterForm.addControl('username', this.formService.getFormControl('profileName'))
        }

        /**
         * add email form control
         */
        if (this.fields.has(Fields.UserEmailPreferences) || this.fields.has(Fields.AcceptNewsletter)) {
            // this.baseLoginRegisterForm.addControl('emailprefs', this.formBuilder.group({newsletters: true, gettingstarted: true}));

            this.baseLoginRegisterForm.addControl('emailPreferences', this.emailPrefsFormGroup);
        }

        /**
         * add the accept newletter form control 
         */
        if (!this.fields.has(Fields.UserEmailPreferences) || this.fields.has(Fields.AcceptNewsletter)) {
            this.emailPrefsFormGroup.addControl("newsletters", new UntypedFormControl(this.acceptNewsletter, []));
        }

        /**
         * add invite code form control
         */
        if (this.fields.has(Fields.InviteCode)) {
            this.baseLoginRegisterForm.addControl('inviteCode', new UntypedFormControl('', this.getInviteCodeValidators()));
        }

        this.updateVisibleFields();

        /**
         * set data into form 
         */
        if (this.formData) {
            this.baseLoginRegisterForm.reset(this.formData);
            setTimeout(() => {
                if (this.fields.has(Fields.UserEmailPreferences) && this.formData.emailPreferences) {
                    // Patch value doesn't seems to like class and interface.  
                    const emailPreferences = JSON.parse(JSON.stringify(this.formData.emailPreferences));
                    this.emailPrefsFormGroup.patchValue(emailPreferences);
                }
            });
        }

        if (this.formType === FormType.Register) {
            if (BaseLoginRegisterFormComponent.registerUserName) {
                this.baseLoginRegisterForm.patchValue({
                    fullName: BaseLoginRegisterFormComponent.registerUserName
                });

                BaseLoginRegisterFormComponent.registerUserName = undefined;
            }

            if (BaseLoginRegisterFormComponent.registerUserEmail) {
                this.baseLoginRegisterForm.patchValue({
                    email: BaseLoginRegisterFormComponent.registerUserEmail
                });

                BaseLoginRegisterFormComponent.registerUserEmail = undefined;
            }
        }

        this.updateEmailValue(true);
        /**
         * set disable field and remove password 
         * form control when user is logged in  and 
         * call api check email is exist or not  
         */
        if (this.formType === FormType.BillingInfo) {
            if (!this.isUserLoggedIn) {

                this.checkEmailExist();
            }

            if (this.email) {
                this.checkEmailExist();
            }
        }



    }

    /**
     * in this function we check the changes of isStepNext
     * if isStepNext is true then form is reset and error set null
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isStepNext && this.isStepNext) {
            this.baseLoginRegisterForm.reset();
            this.baseLoginRegisterForm.controls.password.setErrors(null);
            this.updateEmailValue(false);
        }
    }

    isFieldIncluded(field: Fields) {
        return this.fields.has(field);
    }

    /**
     * update value email in form 
     * when email is exist 
     */
    updateEmailValue(onInit: boolean) {
        let email: string;

        if (onInit && this.initWithLoggedUser) {
            email = this.authService?.user?.email;

            if (this.authService.isLoggedIn()) {
                this.baseLoginRegisterForm.controls.fullName.disable();
                this.baseLoginRegisterForm.patchValue({
                    fullName: this.authService.user.fullName
                });
            }
        }

        if (!email && this.email) {
            email = this.email;
        }

        if (email) {
            this.isEmailSameAsLoggedInUser = email == this.authService?.user?.email;

            /** if form type is not equal to this  login-email-step then email field is set to be disabled */

            const emailControl = this.baseLoginRegisterForm.controls['email'];

            if (emailControl) {
                if (!this.canSetEmail()) {

                    emailControl.disable();
                    this.isGoogleButtonHidden = true;
                } else {
                    emailControl.enable();
                    this.isGoogleButtonHidden = false;
                }
            }

            this.baseLoginRegisterForm.patchValue({
                email: email
            });
        }

        this.updateVisibleFields();
    }

    canSetEmail(): boolean {
        return (!this.readonlyEmailIfProvided || !this.email) &&
            // Will always returns false if user is logged and canSetEmailWhileLogged==false.
            !(this.initWithLoggedUser && !this.canSetEmailWhileLogged && this.authService.isLoggedIn());
    }


    checkEmailExist2() {
        // To resolve the bug [AEP-1196]. It seems that upon first webpage interaction, the blur of the first is triggered if browser auto fill the form field.
        // If user was trying to click on the button, the button will instantly be disable because of this event.
        setTimeout(() => {
            this.checkEmailExist();
        }, 200);
    }

    /**
     * Checking Email is Exist or not 
     */
    checkEmailExist() {
        if ((this.formType === FormType.BillingInfo) || (this.formType === FormType.Register)) {
            const email = this.baseLoginRegisterForm.controls.email.value;

            // Check if email was modified. If it was modified, returns to the Appligogiques login or registering.
            if (this.email != email) {
                this.isGoogleButtonHidden = false;
                this.serviceType = UserServiceType.Appligogiques;
                this.serviceData = undefined;
            }

            if (this.canSetEmail()) {
                this.baseLoginRegisterForm.controls['email'].enable();
            }

            let sameLoggedUser = (email == this.authService?.user?.email) && this.authService.isLoggedIn();
            this.isEmailSameAsLoggedInUser = sameLoggedUser;

            if (sameLoggedUser) {
                this.baseLoginRegisterForm.patchValue({
                    fullName: this.authService?.user?.fullName
                });

                // If email is the same as the logged email no need to query
                // the server to check if account exists since it is obviously true.
                this.setAccountExists(true);
            }
            else if (email && this.baseLoginRegisterForm.controls.email.valid) {
                this.clearErrors();
                this.authService.checkEmailExist(email).subscribe(emailExists => {
                    this.setAccountExists(emailExists);
                }, (error) => {
                    this.errorObject = error;
                    console.error(error);
                    this.emailExistMessage = $localize`:@@form-field-internal-error:Internal error. Please try again. If the problem persists, please contact us via email <a href="mailto:info@appligogiques.com">info@appligogiques.com</a>.`
                    // TODO: Display an error message
                });
            }
        }
    }

    /**
     * Set if the account for the current entered email exists or not.
     * 
     * This will emit an event and set appropriate message to be displayed.
     */
    private setAccountExists(state: boolean) {
        if (state) {
            if (this.formName === "trial-form") {
                this.emailExistMessage = $localize`:@@tell-us-form-email-exists-message:It looks like you already have an Appligogiques account!`;
            } else {
                this.emailExistMessage = $localize`:@@payment-user-email-exist-message:It looks you already have an Appligogiques account! Your purchase will be associated with this account.`;
            }
        } else {
            this.emailExistMessage = $localize`:@@payment-user-email-not-exist-message:Create your Appligogiques account at the same time. Simply enter your password below.`;
        }

        this.isAccountExist = state;
        this.updateVisibleFields();
        this.onCheckUserAccountIsExist.emit(this.isAccountExist);
    }

    /**
     * on press enter calling api  
     * from login-form-component
     */
    callingLoginApis() {
        if (this.formType === FormType.LoginEmailStep ||
            this.formType === FormType.LoginPasswordStep) {
            const data = {
                formType: this.formType,
                formData: this.baseLoginRegisterForm
            }
            this.callApiOnPressEnter.emit(data);
        }
    }

    /**
     * Update the visible fields.
     * 
     * The property this.fields already contains the list of fields to include.
     * But some fields also depends on some other state to be displayed depending on 
     * the select form type.
     */
    updateVisibleFields() {
        this.isEmailHidden = !this.fields.has(Fields.Email);
        this.isPasswordHidden = !this.fields.has(Fields.Password);
        this.isFullNameHidden = !this.fields.has(Fields.FullName);
        this.isConfirmPasswordHidden = !this.fields.has(Fields.ConfirmPassword);
        this.isProfileHidden = !this.fields.has(Fields.ProfileName);
        this.isLanguageShow = this.fields.has(Fields.Language);
        this.isUserEmailPrefsHidden = !this.fields.has(Fields.UserEmailPreferences);
        this.isInviteCodeHidden = !this.fields.has(Fields.InviteCode);

        if (this.formType === FormType.BillingInfo) {
            if (environment.inviteMode?.enabled) {
                this.isInviteCodeHidden = this.isAccountExist;
            }
            if (!this.isPasswordHidden) {
                this.isPasswordHidden = this.isAccountExist && this.authService.isLoggedIn() && this.isEmailSameAsLoggedInUser;

                if (this.isPasswordHidden) {
                    this.baseLoginRegisterForm.controls['password'].disable();
                } else {
                    this.baseLoginRegisterForm.controls['password'].enable();
                }
            }

            // We want to hide the fullname field for account that exists.
            // Except for the logged user. We display a read-only field with
            // the fullname for the logged user.
            if (!this.isFullNameHidden) {
                this.isFullNameHidden = !(this.isEmailSameAsLoggedInUser && this.authService.isLoggedIn()) && this.isAccountExist;

                if (this.isFullNameHidden) {
                    this.baseLoginRegisterForm.controls['fullName'].disable();
                } else if (this.isAccountExist && !this.canEditFullnameOfAnExistingAccount) {
                    this.baseLoginRegisterForm.controls['fullName'].disable();
                } else {
                    this.baseLoginRegisterForm.controls['fullName'].enable();
                }
            }
        }

        // Add or remove the inviteCode base on its visibility.
        if (this.fields.has(Fields.InviteCode)) {
            if (this.isInviteCodeHidden) {
                if (this.baseLoginRegisterForm.contains("inviteCode")) {
                    this.baseLoginRegisterForm.removeControl("inviteCode");
                    this.baseLoginRegisterForm.updateValueAndValidity();
                }

            } else {
                if (!this.baseLoginRegisterForm.contains("inviteCode")) {
                    this.baseLoginRegisterForm.addControl('inviteCode', new UntypedFormControl('', this.getInviteCodeValidators()));
                    this.baseLoginRegisterForm.updateValueAndValidity();
                }
            }
        }
    }



    public getFormData(): BaseLoginRegisterFormData {
        let result: BaseLoginRegisterFormData = new BaseLoginRegisterFormData();

        result.email = this.baseLoginRegisterForm.controls.email?.value;
        result.fullName = this.baseLoginRegisterForm.controls.fullName?.value;
        result.password = this.baseLoginRegisterForm.controls.password?.value;

        if (this.fields.has(this.FieldType.InviteCode)) {
            result.inviteCode = this.baseLoginRegisterForm.controls.inviteCode?.value;
        }

        return result;
    }

    /**
     * Authenticate or register the user.
     * 
     * In this function user login if email is exist or register  
     * if email is not exist then call generate 
     * card token method on its response
     * 
     * @param {FormGroup} formData The form that contains the user registration or login informations.
     */
    public userAuthentication(context: string): Promise<UserAuthenticationResult> {
        this.clearErrors();

        return new Promise((resolve, reject) => {
            BaseLoginRegisterFormComponent.staticUserAuthentication(this, this.isAccountExist, context, this.loadingService, this.authService).then((result) => {
                resolve(result);
            }).catch((err) => {
                this.errorObject = err;

                this.updateVisibleFields();
                reject(err);
            });
        });
    }

    /**
     * Authenticate or register the user.
     * 
     * In this function user login if email is exist or register  
     * if email is not exist then call generate 
     * card token method on its response
     * 
     * @param {FormGroup} formData The form that contains the user registration or login informations.
     */
    public static staticUserAuthentication(c: BaseLoginRegisterFormComponent, doesAccountExist: boolean, context: string, loadingService: LoadingService, authService: AuthService): Promise<UserAuthenticationResult> {
        loadingService.startLoading("login");

        const formData: BaseLoginRegisterFormData = c.getFormData();

        return new Promise((resolve, reject) => {

            // If user is not logged or logged user doesn't correspond to the user that we want to authenticate.
            if (!authService.isLoggedIn() || (formData.email != authService.user?.email)) {
                if (doesAccountExist) {
                    authService.tryLogin(formData.email, formData.password, context + " login").subscribe(response => {
                        loadingService.stopLoading("login");
                        resolve(UserAuthenticationResult.Logged);
                    }, (err) => {
                        loadingService.stopLoading("login");
                        reject(err);
                    })
                } else {
                    const userData = Object.assign({}, formData);
                    authService.tryRegister(Model.New(RegisterUserForm, userData), context + " register").subscribe(response => {
                        loadingService.stopLoading("login");
                        resolve(UserAuthenticationResult.Registered);
                    }, (err) => {
                        loadingService.stopLoading("login");
                        reject(err);
                    })
                }
            } else {
                loadingService.stopLoading("login");
                resolve(UserAuthenticationResult.AlreadyLogged);
            }
        });
    }

    getInviteCodeValidators(): ValidatorFn[] {
        return [Validators.required];
    }

    onGoogleLoginClick(user: SocialUser) {
        this.clearErrors();

        const response = user.response;

        let data: SocialLoginData = {
            type: UserServiceType.Google,
            data: response || user
        };
        this.onServiceLoginClick.emit(data);

        this.baseLoginRegisterForm.invalid

        if (this.formType === FormType.BillingInfo) {
            this.authService.resolveAuthenticateServer(data).then(() => {
                this.authService.tryLoginService(data).subscribe(() => {
                    this.email = this.authService?.user?.email;
                    this.serviceEmail = this.email;
                    this.serviceType = UserServiceType.Google;
                    this.serviceData = response;
                    this.isGoogleButtonHidden = true;

                    this.updateEmailValue(true);
                    this.checkEmailExist();
                }, (ex) => {
                    this.errorObject = ex;
                });
            }).catch((ex) => {
                if (ex.status == 404) {
                    if (user.name) {
                        this.baseLoginRegisterForm.patchValue({
                            fullName: user.name
                        });
                    }

                    if (user.email) {
                        this.email = user.email;
                        this.baseLoginRegisterForm.patchValue({
                            email: user.email
                        });
                    }

                    if (user.name || user.email) {

                        this.serviceType = UserServiceType.Appligogiques;

                        this.updateEmailValue(true);
                        this.checkEmailExist();
                    }
                }
                else {
                    this.errorObject = ex;
                }
            });
        }
    }



    clearErrors() {
        this.errorObject = undefined;
        this.errorObjectGoogle = undefined;
    }

    getLabel(formControlName: string) {
        return this.formService.getFormLabel(formControlName, this.formType);
    }

    getPlaceholder(formControlName: string) {
        if (this.useLabelAsPlaceholder) {
            return this.getLabel(formControlName);
        }

        return this.setPlaceholderPipe.transform(this.formType, formControlName);
    }

    onAccepTermsChanged(event: MatCheckboxChange) {
        this.baseLoginRegisterForm.controls.acceptTerms.markAsTouched();
        if (event.checked) {
            this.baseLoginRegisterForm.patchValue({ acceptTerms: event.checked });
        }
        else {
            this.baseLoginRegisterForm.patchValue({ acceptTerms: null });
        }
    }

    onAccepNewsletterChanged(event: MatCheckboxChange) {
        this.emailPrefsFormGroup.controls.newsletters.markAsTouched();
        if (event.checked) {
            this.acceptNewsletter = true;
            this.emailPrefsFormGroup.patchValue({ newsletters: event.checked });
        }
        else {
            this.acceptNewsletter = false;
            this.emailPrefsFormGroup.patchValue({ newsletters: false });
        }
    }

    showDebugs(event) {
        AngularUtils.showFormState("Base Login Register Form", this.baseLoginRegisterForm);
    }
}
