<app-dialog [errorObject]="errorObject">
    <app-dialog-title *ngIf="!org"
                      i18n="@@common-create-org-model-title">Create a new organization</app-dialog-title>
    <app-dialog-title *ngIf="org"
                      i18n="@@common-edit-org-model-title">Edit the organization</app-dialog-title>
    <app-dialog-content>
        <app-form-builder-renderer [form]="form"
                                   [templates]="[addressNameTemplate]"></app-form-builder-renderer>
        <div>
            <h3 i18n="@@common-billing-address"
                style="margin-top: 0px">Billing Address</h3>

            <app-address-form #billingAddressForm
                              [allowSearch]="false"
                              [formData]="billingAddressFormData"></app-address-form>
        </div>
    </app-dialog-content>

    <button mat-stroked-button
            matDialogClose
            appDialogAction
            i18n="@@common-org-cancel-button"
            (contextmenu)="showDebugs($event)">Cancel</button>
    <button *ngIf="!org"
            mat-flat-button
            appDialogAction
            color="accent"
            (click)="submitNewOrg()"
            [disabled]="form.invalid || billingAddressForm?.invalid || loading.isLoading || (loading.loadingChange | async)"
            i18n="@@common-org-create-button">Create</button>
    <button *ngIf="org"
            mat-flat-button
            appDialogAction
            color="accent"
            (click)="submitUpdateOrg()"
            [disabled]="form.invalid || billingAddressForm?.invalid || loading.isLoading || (loading.loadingChange | async)"
            i18n="@@common-org-update-button">Update</button>
</app-dialog>

<ng-template #addressNameTemplate
             let-data>
    <app-address-name-input [label]="data.field?.label"
                            [control]="data.field?.control"
                            [name]="org?.name || ''"
                            [appearance]="data.field?.data.appearance"
                            [floatLabel]="data.field?.data.floatLabel"
                            [subscriptSizing]="data.field?.data.subscriptSizing"
                            (onAddressSelect)="onAddressSelect($event)"></app-address-name-input>
</ng-template>