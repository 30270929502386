import { Injectable } from '@angular/core';
import { SiteEvent } from './site-event';
import { SiteEventCallback } from './site-event-callback';

@Injectable({
    providedIn: 'root'
})
export class SiteEventService {

    events: { [id: string]: SiteEvent } = {};

    global: SiteEvent = new SiteEvent();

    constructor() { }

    public getEvent(eventName: string): SiteEvent {
        let event: SiteEvent = this.events[eventName];

        if (!event) {
            event = new SiteEvent();
            this.events[eventName] = event;
        }

        return event;
    }

    public registerEvent(eventName: string, callback: SiteEventCallback) {
        let event: SiteEvent = this.getEvent(eventName);
        event.listeners.push(callback);
    }

    public unregisterEvent(eventName: string, callback: SiteEventCallback) {
        let event: SiteEvent = this.getEvent(eventName);
        let idx = event.listeners.indexOf(callback);

        if (idx != -1) {
            event.listeners.splice(idx, 1);
        }
    }

    public registerGlobal(callback: SiteEventCallback) {
        this.global.listeners.push(callback);
    }

    public unregisterGlobal(eventName: string, callback: SiteEventCallback) {
        let idx = this.global.listeners.indexOf(callback);

        if (idx != -1) {
            this.global.listeners.splice(idx, 1);
        }
    }

    public async notify(eventCategory: string, eventName: string, eventData: any) {
        let event: SiteEvent = this.getEvent(eventName);

        // console.log("SiteEventsService.notify() name: " + eventName + " Category: " + eventCategory + " Listeners: " + event.listeners?.length + " Data: " + JSON.stringify(eventData));

        for (let i = 0; i < event.listeners.length; i++) {
            let listener = event.listeners[i];
            try {
                await listener(eventCategory, eventName, eventData);
            }
            catch (ex) {
                console.error(ex);
            }
        }

        for (let i = 0; i < this.global.listeners.length; i++) {
            let listener = this.global.listeners[i];
            try {
                await listener(eventCategory, eventName, eventData);
            }
            catch (ex) {
                console.error(ex);
            }
        }
    }


}
