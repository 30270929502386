import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { DialogService, AppDialogConfig } from 'src/app/services/dialog.service';


interface DynamicMaterialColorSelectionDialogData {

}

@Component({
    selector: 'app-dynamic-material-color-selection-dialog',
    templateUrl: './dynamic-material-color-selection-dialog.component.html',
    styleUrl: './dynamic-material-color-selection-dialog.component.scss'
})
export class DynamicMaterialColorSelectionDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DynamicMaterialColorSelectionDialogData,
        private mdDialogRef: MatDialogRef<DynamicMaterialColorSelectionDialogComponent, any>
    ) {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: DynamicMaterialColorSelectionDialogData, config: AppDialogConfig = {}) {
        
        return new AppDialogRef<DynamicMaterialColorSelectionDialogComponent, DynamicMaterialColorSelectionDialogData, any>(dialogService, dialog, DynamicMaterialColorSelectionDialogComponent, {
            data,
            panelClass: "app-dialog--panel-class--with-border",
            maxWidth: "400px"
        }, config);
    }
}
