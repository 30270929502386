import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-facebook-login-button',
    templateUrl: './facebook-login-button.component.html',
    styleUrls: ['./facebook-login-button.component.scss', '../social-button.scss']
})
export class FacebookLoginButtonComponent {

    @Input() buttonType: 'standard'|'custom'|'icon';
    
    errorObject: any;

    signInWithFacebook(): void {
    }
}
