import { Member, Model } from "@uon/model";
import { ApiDirectoryHost } from "./api-directory-host";

/**
 * 
 */
@Model()
export class ApiDirectoryServer {

    @Member()
    public key: string;

    @Member()
    public type: string;

    @Member()
    public redirect: string;

    @Member()
    public auth: ApiDirectoryHost;

    @Member()
    public api: ApiDirectoryHost;

    @Member()
    public portal: ApiDirectoryHost;

    @Member()
    public site: ApiDirectoryHost;
}