import { Member, Model } from "@uon/model";
import { OnboardingUserState } from "./user-state";

@Model()
export class OnboardingHistoryEntry {

    @Member() 
    state: OnboardingUserState;

    @Member() 
    transitionId: string;

    @Member() 
    trace: any;
}