import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { InstitutionalWelcomeOption } from '../institutional-welcome-option-card/institutional-welcome-option-card.component';


@Component({
    selector: 'app-institutional-welcome-page',
    templateUrl: './institutional-welcome-page.component.html',
    styleUrls: ['./institutional-welcome-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InstitutionalWelcomePageComponent implements OnInit {

    Option = InstitutionalWelcomeOption;
    
    isUserLoggedIn: boolean;

    selectedOption: InstitutionalWelcomeOption;

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
        this.isUserLoggedIn = this.authService.isLoggedIn();
    }

    ngOnInit(): void {
    }

    navigateTo(url) {
        this.router.navigate([url])
    }

    next() {
        switch(this.selectedOption) {
            case this.Option.Trial:
                this.navigateTo('order/trial');
                break;
            case this.Option.RequestQuote:
                this.navigateTo('order/request-quotation');
                break;
            case this.Option.OrderProduct:
                this.navigateTo('order/organization');
                break;
        }
    }

    public onOptionChanged(option: InstitutionalWelcomeOption) {
        this.selectedOption = option;
    }
}
