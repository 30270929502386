import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Currency, MultiCounters, SubscriptionPlan, SubscriptionPlanUtils, SubscriptionPrice, SubscriptionType, Utils } from '@applogic/model';
import { SelectableProduct } from '../../order/base-payment-form/selectable-product';
import { BACKSPACE } from '@angular/cdk/keycodes';
import { ProductService } from 'src/app/services/product.service';
import { ApCurrencyPipe } from 'src/app/core/apcurrency.pipe';
import { ActivatedRoute } from '@angular/router';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-products-select',
    templateUrl: './products-select.component.html',
    styleUrls: ['./products-select.component.scss']
})
export class ProductsSelectComponent implements OnInit {

    // Event to be called after the component finish loading all its data.
    @Output() onReady: EventEmitter<ProductsSelectComponent> = new EventEmitter();

    @Output() onUpdate: EventEmitter<SelectableProduct[]> = new EventEmitter();

    plans: SubscriptionPlan[] = [];

    @Input()
    subscriptionType: SubscriptionType = SubscriptionType.organization;

    protected _subscriptionPlan: SubscriptionPlan;

    @Input()
    set subscriptionPlan(plan: SubscriptionPlan) {
        this._subscriptionPlan = plan;
    }

    get subscriptionPlan() {
        return this._subscriptionPlan;
    }

    @Output()
    subscriptionPlanChange = new EventEmitter<SubscriptionPlan>();
    
    _currency: Currency;

	@Input()
    set currency(currency: Currency) {
        if(this._currency != currency) {
            // CAD is default so no needs to update the products twice in that case.
            const updateProducts = !!this._currency || (currency?.code != "CAD");

            this._currency = currency;
            this.currencyChange.emit(currency);

            if(updateProducts) {
                this.initProductsOfType(this.subscriptionType, currency.code);
            }
        }
    }

    get currency() {
      return this._currency;
    }

    @Output()
    currencyChange: EventEmitter<Currency> = new EventEmitter<Currency>();


    @Input()
    showSubTotal: boolean = false;

    @Input()
    bigIcons: boolean = false;

    minimumSeats: number = 0;

    @Input()
    autoCheck: boolean = true;

    priceList: SubscriptionPrice[] = [];

    products: SelectableProduct[] = [];
    
    isHiddenSeatError = true;
    seatCount: number = 0;

    quantity: MultiCounters = {};
    apcurrencyPipe: ApCurrencyPipe;

    constructor(private injector: Injector, private productService: ProductService, private route: ActivatedRoute) { }

    pricesLabel: {[key: string]: string} = {};

    ngOnInit(): void {
        this.apcurrencyPipe = ApCurrencyPipe.instantiateFromInjector(this.injector);
        this.setSubscriptionType(this.subscriptionType);
    }

    public isValid() {
        
        let result = false;

        if(this.isHiddenSeatError) {
            result = this.seatCount >= this.minimumSeats;
        }

        return result;
    }

    /**
     * Initialize the products list.
     * 
     * @param {SubscriptionType} type The type of products: "individual" or "organization".
     */
    initProductsOfType(type: SubscriptionType, currencyCode: string) {
        if(!currencyCode) return;

        let productList: any[] = [];
        this.productService.getProducts().then((res: any) => {
            productList = res.map(el => {
                el['seats'] = 0;
                return el;
            });
        }).then(() => {
            return this.productService.getSubscriptionPrices(type, currencyCode);
        }).then((subscriptionPrices: SubscriptionPrice[]) => {

            this.priceList = subscriptionPrices;

            let products = productList.map(p => {
                let product: SelectableProduct = new SelectableProduct();
                product.code = p.shortCode;
                product.seats = 0;

                product.info = p;
                product.subscriptionType = this.subscriptionType;

                return product;
            });

            this.products = products.filter(p => {
                if (p.info) return true;
                return false;
            });

            let startingPlan = this.subscriptionPlan;

            let plan = this.route.snapshot.queryParamMap.get("plan");
            if(plan) {
                // Verify that the plan exists.
                const productPlan = this.plans.find(p => p == plan);
                if(productPlan) {
                    startingPlan = productPlan;
                }
            }

            if(startingPlan) {
                this.setSubscriptionPlan(startingPlan, false);
            }

            this.resetSelection(true);

            this.onReady.emit(this);
        }).catch((error) => {
            console.log("Show Error =====> ", error.error.message);
        })
    }

    /**
     * Occur when the subscription plan change.
     * 
     * This clear the products selection and reset the amount.
     */
    onchangeSubscriptionPlan(event) {
        const subscriptionPlan = Utils.getEnumFromString(SubscriptionPlan, event.value);
        this.setSubscriptionPlan(subscriptionPlan);
    }

    public setSubscriptionType(type: SubscriptionType) {
        this.subscriptionType = type;
        this.minimumSeats = SubscriptionPlanUtils.getMinimumSeats(type);
        if (this.subscriptionType == SubscriptionType.individual) {
            this.subscriptionPlan = SubscriptionPlan.yearly;
        }
        else {
            this.subscriptionPlan = SubscriptionPlan.quarterly;
        }

        this.plans = SubscriptionPlanUtils.getPlans(type);

        this.initProductsOfType(this.subscriptionType, this.currency ? this.currency.code : "CAD");
    }

    public setSubscriptionPlan(plan: SubscriptionPlan, reset: boolean = true) {
        this.subscriptionPlan = plan;
        this.products.forEach(p => {
            p.subscriptionPlan = this.subscriptionPlan;
        });

        if (reset) {
            this.resetSelection();
        }
    }

    
    /**
     * Reset selection values.
     * 
     * @param {boolean} useParams Use query params for initial values.
     */
    resetSelection(useParams: boolean = false) {
        const lastQuantity: any = {};
        let validateQuantity: boolean = false;

        if(useParams) {
            for (const product of this.products) {
                lastQuantity[product.code] = 0;
                const keyQty = product.code.toLowerCase() + "-qty";
                let qtyParam = this.route.snapshot.queryParamMap.get(keyQty);
                if (qtyParam) {
                    const qty = parseInt(qtyParam);
                    if (!isNaN(qty)) {
                        lastQuantity[product.code] = qty;
                        validateQuantity = true;
                    }
                }
            }
        }
        else {
            this.products.map((item: SelectableProduct) => {
                lastQuantity[item.code] = item.seats;
            });
        }

        this.products.map((item: SelectableProduct) => {
            item.seats = 0;
            item.totalPrice = 0;
            item.subscriptionType = this.subscriptionType;

            if(this.currency)
            {
                this.pricesLabel[item.code] = this.apcurrencyPipe.transform(0, this.currency);
            }

            for (let i = 0; i < this.priceList.length; i++) {
                let price = this.priceList[i];
                if ((price.productCode == item.code) && (price.plan == this.subscriptionPlan)) {
                    item.price = price;
                    break;
                }
            }
        });

        for(const product of this.products) {
            const qty = lastQuantity[product.code];
            this.setSeats(product.code, qty, product.stripePriceId);
        }

        this.triggerUpdate();
        this.isHiddenSeatError = true;

        if(validateQuantity) {
            this.checkIfValid();
        }
    }

    onChange() {
        this.triggerUpdate();
    }

    public checkIfValid(): boolean {
        if(this.minimumSeats > 1)
        {
            this.isHiddenSeatError = this.seatCount < this.minimumSeats ? false : true;
        }
        else {
            this.isHiddenSeatError = true;
        }

        return this.isValid();
    }

    setSeats(productCode: string, seats: number, stripePriceId: string) {
        let idx = this.products.findIndex(a => a.code == productCode);
        
        if(idx != -1) {
            let p: SelectableProduct = this.products[idx];
            p.seats = seats;

            if(stripePriceId)
            {
                p.stripePriceId = stripePriceId;
            }

            this.onChangeSeatNumber(p);
            this.triggerUpdate();
        }
        else {
            console.error("Product " + productCode + " not found");
        }
    }

    /**
     * Increase Seat
     * @param index 
     */
    increaseSeat(product: SelectableProduct) {
        product.seats++;
        this.onChangeSeatNumber(product);
    }

    /**
     * Decrease Seat
     * @param index 
     */
    decreaseSeat(product: SelectableProduct) {
        product.seats--;
        this.onChangeSeatNumber(product);
    }

    onChangeSeatNumber(product: SelectableProduct) {
        const TOTAL_SEATS = product.seats;
        const type = this.subscriptionPlan;
        product.totalPrice = TOTAL_SEATS * product.price.amount.value;
        if(this.currency)
        {
            
            this.pricesLabel[product.code] = this.apcurrencyPipe.transform(product.totalPrice, this.currency);
        }
        product.stripePriceId = product.price.stripeId;

        // No need to triggerUPdate() since the onChange() will be automatically called after.
        // this.triggerUpdate();
    }

    
    onQuantityChanged(c: number, product: SelectableProduct) {
        product.seats = c;
        this.onChangeSeatNumber(product);
    }

    /**
     * Only Number Enter 
     * @param event 
     */
    keyPress(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != BACKSPACE && !pattern.test(inputChar)) {
            event.preventDefault();
        }

        this.triggerUpdate();
    }

    triggerUpdate() {
        this.updateTotalSeats();
        this.onUpdate.emit(this.products);
    }

    /**
     * Compute the total number of seats.
     */
    updateTotalSeats() {
        this.seatCount = 0;
        this.products.forEach(item => this.seatCount += item.seats);

        if(this.autoCheck)
        {
            this.checkIfValid();
        }
    }

    getPlanLabel(plan: SubscriptionPlan) {
        const label = ApplogicUtils.getSubscriptionPlanLabel(plan, this.subscriptionType);

        return label;
    }

}
