import { Component, Input } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { AbstractCustomFormFieldComponent } from '../abstract-custom-form-field/abstract-custom-form-field.component';
import { SelectFieldConfig } from '../custom-form-select-field/custom-form-select-field.component';
import { FormService } from 'src/app/services/form.service';


export interface RadioFieldConfig extends SelectFieldConfig {
    disabled?: boolean;
}


@Component({
    selector: 'app-custom-form-radio-select-field',
    templateUrl: './custom-form-radio-select-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-radio-select-field.component.scss']
})
export class CustomFormRadioSelectFieldComponent extends AbstractCustomFormFieldComponent {

    @Input()
    data: RadioFieldConfig;

    constructor(protected formService: FormService) {
        super();
    }
    
    onFieldRadioSelectChanged(ev: MatRadioChange) {

    }
}
