
<mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
    <mat-label>Full name</mat-label>
    <input type="text" name="fullName" matInput placeholder="John Doe">
</mat-form-field>


<mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
    <mat-label>Email</mat-label>
    <input type="email" name="email" matInput placeholder="abc@example.com">
</mat-form-field>

<mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
    <mat-label>Password</mat-label>
    <input type="password" name="password" matInput placeholder="Enter your password...">
</mat-form-field>