import { Component, HostBinding, Input } from '@angular/core';

export enum LabelType {
    Normal = 'normal',
    Dynamic = 'dynamic',
    Ellipsis = 'ellipsis'
}

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrl: './label.component.scss'
})
export class LabelComponent {

    @Input()
    type: LabelType = LabelType.Normal;

    @Input()
    display: 'block'|'inline' = 'block';

    @Input()
    minFontSize: number = 4;

    @Input()
    maxFontSize: number = 20;
    
    @HostBinding('style.display') get getDisplayStyle() {
        return this.display;
    }
}
