import { Component, Input } from '@angular/core';
import { CommonListRenderOptions } from '../common-list-render-options';

// Declare an intermediate type to force implement all the interface (to add the @Input() prefix).
type CommonListRenderOptionsInputs = Required<CommonListRenderOptions>;

@Component({
    selector: 'app-common-list-renderer-options',
    templateUrl: './common-list-renderer-options.component.html',
    styleUrl: './common-list-renderer-options.component.scss'
})
export class CommonListRendererOptionsComponent implements CommonListRenderOptionsInputs {

    @Input()
    showSearchBar: boolean;

    @Input()
    autoHideSearchBar: boolean;

    @Input()
    stickyHeader: boolean;

    @Input()
    stickyFooter: boolean;

    @Input()
    hidePagination: boolean;

    // Auto hide pagination if all items fit in one page.
    @Input()
    autoHidePagination: boolean;

    @Input()
    autoCheckAudio: boolean;
    
    @Input()
    tableComponentClasses: string[];

    @Input()
    tableContainerClasses: string[];

    @Input()
    tableContainerStyles: any;

    @Input()
    cardListClasses: string[];

    @Input()
    showColumnHeader: boolean;

    @Input()
    showColumnFooter: boolean;

    @Input()
    showSelectColumnsOption: boolean;

    @Input()
    showSelectColumnsActionHeader: boolean;

    @Input()
    showSelectColumnsTable: boolean;

    @Input()
    showDisplayMode: boolean;

    @Input()
    searchPlaceholder: string;

    @Input()
    verticalScrolling: boolean;
}
