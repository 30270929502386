import { Address } from "../common/address.model";
import { removeDiacriticalMarks } from 'remove-diacritical-marks'
import { WordList } from "src";

export class WordListUtils {
    public static textToFilename(text: string, langCode: string) {
        if(langCode == "es") {
            text = text.replace("ñ", "nn");
        }

        // Remove diacritics.
        // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        text = text.replace("'", "_");
        text = text.replace("-", "");
        text = text.replace("@", "");
        text = text.replace("/", "");
        text = text.toLowerCase();

        return text;
    }

    public static getWordAudioUrl(langCode: string, gameCode: string, gameNo: number, season: number, wordText: string, options: any = {}) {
        let url: string;

        const l = WordListUtils.getAssetsLocation(gameCode, langCode, season);

        if(gameCode == "mmo") {
            if(gameNo == 5) {
                let text: string = WordListUtils.textToFilename(wordText, langCode);

                url = l.rootUrl + "/game05_sounds/" + langCode + "/mmo_text_game05_sound_" + text + ".wav";
            }
            else if( (gameNo == 4) && (options.reverse) ) {
                let text: string = WordListUtils.textToFilename(wordText, langCode);

                url = l.rootUrl + "/game04/" + langCode + "/mmo_text_" + text + "_reverse.wav";
            }
            else {
                let text: string = WordListUtils.textToFilename(wordText, langCode);

                url = l.rootUrl + "/words_sounds/" + langCode + "/mmo_text_picture_" + text + ".wav";
            }
        }
        else if(gameCode == 'msm') {
            let text: string = WordListUtils.textToFilename(wordText, langCode);

            url = l.rootUrl + "/words_sounds/" + langCode + "/" + text + ".wav";
        }

        return url;
    }

    public static getPictureUrl(langCode: string, gameCode: string, gameNo: number, season: number, wordText: string) {
        if(gameCode == "mmo") {
            if(gameNo == 5) return "";
            
            const l = WordListUtils.getAssetsLocation(gameCode, langCode, season);

            const filename: string = WordListUtils.textToFilename(wordText, langCode);
            const imgUrl = `${l.rootUrl}/pictures/${langCode}/mmo_picture_${filename}.png`;
            return imgUrl;
        }

        return "";
    }

    public static getAssetsLocation(gameCode: string, langCode: string, season: number) {
        let rootUrl: string = "https://appligogiques-web.s3.amazonaws.com/wordlist_section/";

        rootUrl += gameCode;

        if(gameCode == "mmo") {
            if(langCode == "en") {
                if(season == 1) {
                    rootUrl = "https://appligogiques-web.s3.amazonaws.com/wordlist_section/mmo/season1";
                }
            }
        }

        return {
            rootUrl
        }
    }
}