import { Member, Model } from "@uon/model";

/**
 * 
 */
@Model()
export class ApiDirectoryHost {

    @Member()
    public host: string;

    @Member()
    public routes: any;
}