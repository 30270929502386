<div class="help-icon secondary-text">   
    @switch(type) {
        @case('tooltip') {
            <div class="help-icon-shape">
                <div *ngIf="template"
                     class="help-icon-label"
                     [tooltip]="template"
                     contentType="template"
                     [placement]="placement"
                     tooltipClass="angular-tooltip"
                     noArrow="false">
                    i
                </div>
                <div *ngIf="text"
                     class="help-icon-label"
                     [tooltip]="text"
                     [placement]="placement"
                     tooltipClass="angular-tooltip"
                     noArrow="false">
                    i
                </div>
            </div>
        }

        @case('popover') {
            <div class="help-icon-shape"
                 *ngIf="template"
                 [ngbPopover]="template"
                 (mousewheel)="helpPopover.close(false)"
                 popoverClass="app-popover--rich-tooltip"
                 #helpPopover="ngbPopover">
                <div class="help-icon-label">
                    i
                </div>
            </div>
        }
    }
</div>