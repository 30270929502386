import { AbstractControl, ValidatorFn } from "@angular/forms";
import { CustomFormBuilder } from "./custom-form-builder";
import { FieldType } from "./custom-form-field-config";

export interface FieldInfo {
    key: string,
    type: FieldType;
    typeKey?: string;
    control: AbstractControl;
    form?: CustomFormBuilder;
    label: string;
    data: any;
    validators: ValidatorFn[];
}

export class CustomFormField implements FieldInfo {

    constructor(fields: FieldInfo) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

    useMatFormField?: boolean;

    key: string;

    typeKey?: string;

    type: FieldType;

    control: AbstractControl;

    hidden: boolean = false;

    disabled: boolean = false;

    form?: CustomFormBuilder;

    label: string;

    placeholder?: string;

    hint?: string;

    data: any;

    validators: ValidatorFn[];

    private _disableValidators: boolean = false;

    onInputFocus() {

    }

    onInputFocusOut() {

    }

    onInputChange(event: Event) {
        
    }

    setDisableValidators(state: boolean) {
        if(state) {
            if(!this._disableValidators) {
                this._disableValidators = true;
                this.control.addValidators(this.validators || []);
                this.control.updateValueAndValidity();
            }
        }
        else {
            if(this._disableValidators) {
                this._disableValidators = false;
                this.control.clearValidators();
                this.control.updateValueAndValidity();
            }
        }
    }
}