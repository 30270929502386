<app-page type="centered">
    <app-page-content class="app-form-container">
        <app-design-card class="invite-card"
                         appearance="raised"
                         [clickable]="false">
            <design-card-content [ngSwitch]="_step">
                <div *ngSwitchCase="Step.RequestForm">
                    <ng-template [ngTemplateOutlet]="requestFormTemplate"></ng-template>
                </div>
                <div *ngSwitchCase="Step.RequestSent">
                    <ng-template [ngTemplateOutlet]="requestSentTemplate"></ng-template>
                </div>
                <div *ngSwitchCase="Step.UpdateForm">
                    <ng-template [ngTemplateOutlet]="updateFormTemplate"></ng-template>
                </div>
                <div *ngSwitchCase="Step.UpdateSent">
                    <ng-template [ngTemplateOutlet]="updateSentTemplate"></ng-template>
                </div>
                <app-error-message-area [error]="errorObject"></app-error-message-area>
            </design-card-content>
        </app-design-card>
    </app-page-content>
</app-page>

<ng-template #requestFormTemplate>
    <h2 i18n="@@password-reset-title">Password reset</h2>

    <p i18n="@@password-reset-step1-message">Please enter the email address associated with the account.</p>

    <app-base-login-register-form [formType]="'forgotPassword'"
                                  [isHasToken]="hasToken"
                                  #forgotPassword></app-base-login-register-form>

    <div class="app-form-actions">
        <span class="spacer"></span>
        <button mat-flat-button
                color="accent"
                [disabled]="forgotPassword.baseLoginRegisterForm.invalid || loading.isLoading || (loading.loadingChange | async)"
                (click)="submitEmailForm(forgotPassword.baseLoginRegisterForm)">
            <span i18n="@@common-submit">Submit</span>
        </button>
    </div>
</ng-template>

<ng-template #requestSentTemplate>
    <p i18n="@@password-reset-email-sent-message">We sent an email to
        <strong>{{ showEmail }}</strong> with instructions
        on how to reset your password.
    </p>
</ng-template>

<ng-template #updateFormTemplate>
    <h2 i18n="@@password-reset-title">Password reset</h2>

    <p i18n="@@password-reset-step2-message">Please enter a new password for your account.</p>

    <app-base-login-register-form [formType]="'forgotPassword'"
                                  [isHasToken]="hasToken"
                                  #forgotPassword></app-base-login-register-form>
    <div class="app-form-actions">
        <span class="spacer"></span>
        <button mat-flat-button
                color="accent"
                [disabled]="forgotPassword.baseLoginRegisterForm.invalid || loading.isLoading || (loading.loadingChange | async)"
                (click)="submitPasswordForm(forgotPassword.baseLoginRegisterForm)">
            <span i18n="@@common-submit">Submit</span>
        </button>
    </div>
</ng-template>

<ng-template #updateSentTemplate>
    <h2 i18n="@@password-reset-complete-title">Success!</h2>

    <p i18n="@@password-reset-password-change-complete-message">
        Your password has been successfully changed. You can now sign in with your new password.
    </p>

    <div class="app-form-actions">
        <span class="spacer"></span>
        <button mat-flat-button
                color="primary"
                routerLink="/login">
            <span i18n="@@common-signin">Sign in</span>
        </button>
    </div>
</ng-template>