import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GameProgressSelectableColumn } from '../game-progress-selectable-column';
import _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { ColumnsSelectionColumsListComponent } from './columns-selection-colums-list/columns-selection-colums-list.component';


interface ColumnsSelectionDialogData {
    gameType: any,
    languageType: any,
    columns: GameProgressSelectableColumn[]
}

@Component({
    selector: 'app-columns-selection-dialog',
    templateUrl: './columns-selection-dialog.component.html',
    styleUrls: ['../base-game-progress/base-game-progress.component.scss', './columns-selection-dialog.component.scss']
})
export class ColumnsSelectionDialogComponent implements OnInit {

    public columns: GameProgressSelectableColumn[] = [];

    currentTabsType: any = {};

    public filters: any[] = [
        {
            "label": $localize`:@@game-wordlist-level-easy:easy`,
            "type": "builtin",
            "subtype": "easy"
        },
        {
            "label": $localize`:@@game-wordlist-level-hard:hard`,
            "type": "builtin",
            "subtype": "hard"
        },
        {
            "label": $localize`:@@game-wordlist-level-homework:homework`,
            "type": "homework"
        }];

    _columnTooltip: GameProgressSelectableColumn;

    @ViewChildren(ColumnsSelectionColumsListComponent)
    lists: ColumnsSelectionColumsListComponent[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: ColumnsSelectionDialogData,
        private mdDialogRef: MatDialogRef<ColumnsSelectionDialogComponent, boolean>,
        private domSanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.currentTabsType = this.filters[0];

        // Copy the array of columns to force the update.
        this.columns = this.data.columns;
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: ColumnsSelectionDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<ColumnsSelectionDialogComponent, ColumnsSelectionDialogData, boolean>(dialogService, dialog, ColumnsSelectionDialogComponent,             {
            data
        }, config);
    }

    /** Tabs onChange  Function  */
    tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
        this.currentTabsType = this.filters[
            tabChangeEvent.index
        ];
    };

    /** Update Display Col on Click Of Save  */
    updateTableDisplayCol() {
        if(this.lists) {
            this.columns.forEach((column) => {
                let isSelected: boolean = false;
                for(const l of this.lists) {
                    if(l.selection.isSelected(column)) {
                        isSelected = true;
                        break;
                    }
                }
                column.isSelectedValue = isSelected;
            });
        }
            
        this.mdDialogRef.close(true);
    }
}
