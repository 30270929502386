import { Component, OnInit, ViewChild, OnChanges, Injector, SimpleChanges } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import * as moment from "moment-timezone";
import { BaseGameProgressComponent } from "../base-game-progress/base-game-progress.component";
import { GameProgressSelectableColumn } from "../game-progress-selectable-column";
import { ColumnsSelectionDialogComponent } from "../columns-selection-dialog/columns-selection-dialog.component";
import { ExpectedField, GameProgressAppActivity, GameProgressionQuery, GameProgressListInfo, OutputStructure, WordListSubType, WordListType } from "@applogic/model";
import { MSMActivitiesTableRow, MSMActivityProgressRow, MSMChip, MSMDatesProgressRow, MSMDatesTableRow } from "./msm-game-progress.model";
import { MsmDateListComponent } from "./msm-date-list/msm-date-list.component";
import { MsmActivityListComponent } from "./msm-activity-list/msm-activity-list.component";
import { MSMGameProgressUtils } from "./msm-game-progress.utils";
import { DialogService } from "src/app/services/dialog.service";


@Component({
    selector: 'app-msm-game-progress',
    templateUrl: './msm-game-progress.component.html',
    styleUrls: ['../base-game-progress/base-game-progress.component.scss', './msm-game-progress.component.scss'],
})
export class MsmGameProgressComponent extends BaseGameProgressComponent implements OnInit, OnChanges {

    MSMGameProgressUtils = MSMGameProgressUtils;
    
    @ViewChild(MsmActivityListComponent, {static: false}) activityList: MsmActivityListComponent;
    @ViewChild(MsmDateListComponent, { static: false }) dateList: MsmDateListComponent;

    gameWordListArray: Array<any> = [];

    constructor(
        injector: Injector,
        private dialog: MatDialog,
        private dialogService: DialogService
    ) {
        super(injector);
        this.currentDate = new Date();
        this.currentAppGameCode = "MSM";
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.classroomService.isRefreshValue.subscribe((response) => {
            if (response == true) {
                if (this.isSelectedDate == true) {
                    this.updateProgressByDate();
                } else {
                    this.updateProgressByActivity();
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (this.currentGame !== this.currentAppGameCode) return;

        if (this.classStudent.length > 0) {

            this.setLoading(true);

            if (this.isSelectedDate == true) {
                this.setCurrentDate();
            }

            this.refreshAllData().finally(() => {
                this.setLoading(false);
            });
        }
    }

    /** Open MSM Dailog box */
    openMSMDailogBox() {
        const appDialog = ColumnsSelectionDialogComponent.createDialog(this.dialogService, this.dialog, {
            gameType: this.currentGame,
            languageType: this.currentLanguageType,
            columns: this.columns
        });

        appDialog.show().then((val) => {
            if (val) {
                this.updateColumns();
                this.progressTableService.saveColumnsState(this.currentGame, this.currentLanguageType, this.columns);

                if(this.activityList) {
                    this.activityList.updateColumnsSelection();
                }
                /*
                if (this.isSelectedDate == true) {
                    this.updateProgressByDate();
                } else {
                    this.updateProgressByActivity();
                }
                */
            }
        });
    }

    /**
     * Update the progress table to display progression by activity (or wordlist).
     */
    updateProgressByActivity() {
        if(!this.activityList) {
            setTimeout(() => {
                this.updateProgressByActivity();
            }, 100);
            return;
        }

        // TODO: Only needs to be done once.
        this.activityList.setAppWordLists(this.appWordLists, this.homeworkWordLists, this.currentLanguageType);

        this.updateColumns();
        let studentData: MSMActivitiesTableRow[] = [];
        let studentIds = [];
        let activityList: GameProgressAppActivity[] = [];
        let gameChips: MSMChip[] = [];
        activityList = JSON.parse(JSON.stringify(this.appActivities));

        this.classStudent.map((student, s) => {
            const row: MSMActivitiesTableRow = {
                student_name: student.fullName,
                student_id: student.id,
                lists: {}
            };
            studentIds.push(student.id);

            for (var i = 0; i < this.columns.length; i++) {
                let column: GameProgressSelectableColumn = this.columns[i];

                if (column.header) continue;

                gameChips = [];
                
                activityList.map((itema, a) => {
                    const chip: MSMChip = new MSMChip();
                    chip.setGame(itema);
                    chip.label = chip.gameNo.toString();
                    chip.color_code = column.color_code;
                    chip.isPlay = false;
                    gameChips.push(chip);
                });

                gameChips.sort(function (a, b) {
                    return a.gameNo - b.gameNo;
                });

                
                row.lists[column.listInfo._id + "_" + column.getLevelKey()] = gameChips;
            }

            studentData.push(row);
        });

        this.setLoading(true);

        this.retrieveProgress({ studentId: studentIds }).then((data: any) => {
            if (data.length != 0) {
                this.mergeProgressByActivityToTable(data, JSON.parse(JSON.stringify(studentData)));
            } else {
                this.setLoading(false);
            }
        }).catch((ex) => {
            console.error(ex);
        });
    }

    /**
     * Merge the progression data (by activity or wordlist) to the table struture.
     * 
     * @param progressData
     * @param table
     */
    mergeProgressByActivityToTable(progressData: MSMActivityProgressRow[], table: MSMActivitiesTableRow[]) {
        let studentTableRows: MSMActivitiesTableRow[] = JSON.parse(JSON.stringify(table));
        let progressArray: MSMActivityProgressRow[] = JSON.parse(JSON.stringify(progressData));

        // For each student.
        studentTableRows.map((studentTableRow, i) => {
            let studentDataRow = progressArray.find(
                (stdu) => stdu.studentId === studentTableRow.student_id
            );

            if (!studentDataRow) return;

            // For each student data.
            studentDataRow.cols.map((itemj, j) => {

                // Find the column that match the student data.
                let column: GameProgressSelectableColumn = this.columns.find(
                    (diff) => (diff.list_type === itemj.type) && (diff.list_subtype === itemj.subtype) && (diff.listInfo?._id == itemj.listId)
                );

                if (column) {
                    let data = studentTableRow.lists[column.key];

                    if(!data) {
                        console.error("Unable to find studentTableRow with key " + column.key);
                        console.log("studentTableRow: " + JSON.stringify(studentTableRow));
                    }

                    data.filter((chip) => {
                        if (chip.gameNo === itemj.gameNo) {
                            chip.isPlay = true;
                            chip.completed_Date = itemj.dates;
                            this.onChipDataAdded(chip);
                        }
                    });
                }
            });
        });

        this.activityList.setProgressData(studentTableRows, this.selectedSeason);

        this.setLoading(false);
    }

    /**
     * Retrieve the progress
     * 
     * @param keys The object to filter the result.
     * 
     * @returns {any} Returns the object that contains the progress.
     */
    retrieveProgress(keys: any) {
        keys.language = this.currentLanguageType;

        return new Promise((resolve, reject) => {
            if (this.isSelectedDate == false) {
                const SEND_API_DATA: GameProgressionQuery = {
                    groups: [
                        {
                            label: "rows",
                            keys: ["studentId"],
                        },
                        {
                            label: "cols",
                            keys: ["listId", "gameNo", "levelNo", "type", "subtype"],
                        },
                    ],
                    gameId: this.currentGame.toLowerCase(),
                    format: OutputStructure.Table,
                    keys: keys,
                    expected: [ExpectedField.Success, ExpectedField.Dates],
                };

                this.progressTableService.getProgressGameDetails(SEND_API_DATA)
                    .subscribe((response) => {
                        resolve(response);
                    }, (ex) => {
                        reject(ex);
                    });
            } else {
                const startDate = moment(this.EndDate).startOf("day").utc().format();
                const endDate = moment(this.startDate).endOf("day").utc().format();

                const SEND_API_DATA: GameProgressionQuery = {
                    groups: [
                        {
                            label: "rows",
                            keys: ["studentId"],
                        },
                        {
                            label: "cols",
                            keys: ["listId", "gameNo", "levelNo", "type", "subtype"],
                        },
                    ],
                    gameId: this.currentGame.toLowerCase(),
                    format: OutputStructure.Table,
                    keys: keys,
                    from: startDate,
                    to: endDate,
                    timezone: moment.tz.guess(),
                    expected: [ExpectedField.Success, ExpectedField.Dates],
                };

                this.progressTableService.getProgressGameDetails(SEND_API_DATA)
                    .subscribe((response) => {
                        resolve(response);
                    }, (ex) => {
                        reject(ex);
                    });
            }
        });
    }

    // Remove Chip Function
    removeProgressGameChip(column: GameProgressSelectableColumn) {
        column.isSelectedValue = false;
        this.unselectColumn(column);
        this.progressTableService.saveColumnsState(
            this.currentAppGameCode,
            this.currentLanguageType,
            this.columns
        );
        if (this.isSelectedDate == true) {
            this.updateProgressByDate();
        }

        if(this.activityList) {
            this.activityList.updateColumnsSelection();
        }
    }

    /**
     * Update the table data to display progression by date
     */
    updateProgressByDate() {
        if(!this.dateList) {
            setTimeout(() => {
                this.updateProgressByDate();
            }, 100);
            return;
        }

        this.unselectAllColumns();
        let student = this.classStudent;
        const studentId = [];
        let dateTableData: MSMDatesTableRow;
        let finalDateArray: MSMDatesTableRow[] = [];
        const startDate = moment(this.EndDate).startOf('day').utc().format();
        const EndDate = moment(this.startDate).endOf('day').utc().format();

        this.dailyColumns = this.progressTableService.getDailyDatesColumns(startDate, EndDate);
        this.dailyColumnsKey = this.dailyColumns.map(c => c.key);

        if(this.dateList) {
            this.dateList.setDateRange(startDate, EndDate);
        }
        else {
            console.error("date list not initialized");
        }

        this.columns.forEach((column) => {
            if (column.isSelectedValue == true) {
                this.selectColumn(column);
            }
        });
        student.map((item, i) => {
            dateTableData = {
                student_id: item.id,
                student_name: item.fullName,
                dates: {}
            };
            studentId.push(item.id);
            this.dailyColumns.forEach((c) => {
                if(c.header) return;
                
                dateTableData.dates[c.key] = [];
            });
            finalDateArray.push(dateTableData);
        });

        this.retrieveProgress({ studentId: studentId }).then((data: any) => {
            this.mergeProgressByDateToTable(data, finalDateArray);
        }).catch((ex) => {
            console.error(ex);
        });
    }

    /**
     * Merge the progression data (by date) to the table struture.
     * 
     * @param progressData
     * @param table
     */
    mergeProgressByDateToTable(progressData: MSMDatesProgressRow[], table: MSMDatesTableRow[]) {
        let studentTableRows: MSMDatesTableRow[] = JSON.parse(JSON.stringify(table));
        let responseArray: MSMDatesProgressRow[] = JSON.parse(JSON.stringify(progressData));

        // TODO: Why doing this here?!?
        this.refreshSelectedColumns();

        studentTableRows.map((studentTableRow, index) => {
            // Match the corresponding data for this student.
            let studentDataRow = responseArray.find(
                (res) => res.studentId === studentTableRow.student_id
            );
            if (!studentDataRow) return;

            // For each student data.
            studentDataRow.cols.map((itemj, j) => {

                // Find the column that match the student data.
                let column: GameProgressSelectableColumn = this.columns.find(
                    (game) => (game.list_type === itemj.type) && (game.list_subtype === itemj.subtype) && (game.listInfo?.id === itemj.listId)
                );

                if (column) {
                    let listInfo: GameProgressListInfo = column.listInfo;

                    if (column.isSelectedValue === true) {

                        // For each date.
                        for (let key in studentTableRow.dates) {
                            let data = studentTableRow.dates[key];
                            let dateMatched = itemj.dates.filter(
                                (dated) => moment(dated).format("D/M/YY") === key
                            );
                            if (dateMatched && dateMatched.length > 0) {
                                let chip: MSMChip = data.find((item) => (item.listId === itemj.listId) && (item.type == column.list_type) && (item.subtype == column.list_subtype));

                                // Find the chip within the cell associate with a specific list.
                                if (!chip) {
                                    chip =  new MSMChip();
                                    chip.completedlevels = [];
                                    chip.completedlevelssublevel = false;
                                    chip.setList(listInfo);
                                    chip.attempts = itemj.success;
                                    chip.color_code = column.color_code;
                                    chip.name = column.name;

                                    chip.subtype = column.list_subtype;

                                    if(column.list_type != listInfo.type) {
                                        console.error("Column list type '" + column.list_type + "' doesn't match the list type '" + listInfo.type + "'");
                                    }

                                    if(column.name != listInfo.name) {
                                        console.error("Column name '" + column.name + "' doesn't match the list name '" + listInfo.name + "'");
                                    }

                                    data.push(chip);
                                    this.onChipDataAdded(chip);
                                }

                                chip.completedlevels.push(itemj.gameNo);

                                if (chip.completedlevels.length === 5) {
                                    chip.completedlevelssublevel = true;
                                }
                            }
                        }
                    }
                }
            });
        });

        this.dateList.setProgressData(studentTableRows, this.selectedSeason);
    }

    /**
     * Triggered when the app words lists (this.appWordLists) is updated.
     */
    protected onAppWordListsUpdate(): void {
        this.columns = [];

        let hardList: GameProgressSelectableColumn[] = [];

        let easyCount: number = 0;
        let hardCount: number = 0;
        let column: GameProgressSelectableColumn = new GameProgressSelectableColumn();
        column.header = true;
        column.isSelectedValue = true;
        column.isSelectable = false;
        column.key = 'student_name';
        column.name = 'Student Name';
        column.order = 1;
        this.columns.push(column);

        let count: number = 1;

        this.appWordLists.map((item, index) => {
            item.level.forEach((level) => {
                column = new GameProgressSelectableColumn();
                column.key = item._id + "_" + level;
                column.id = column.key;
                column.name = item.name;
                column.list_type = WordListType.builtin;
                column.listInfo = item;
                column.order = ++count;

                if (level == "easy") {
                    column.list_subtype = WordListSubType.easy;
                    column.color_code = "#d400c5";
                    column.isSelectedValue = easyCount <= 2;
                    easyCount++;
                    this.columns.push(column);
                } else if (level == "hard") {
                    column.list_subtype = WordListSubType.hard;
                    column.color_code = "#468c12";
                    column.isSelectedValue = hardCount <= 2;
                    hardCount++;
                    hardList.push(column);
                }
                else {
                    column.color_code = "#FFA500";
                }
            });
        });
        this.columns.push(...hardList);
    }

    /**
     * Triggered when the homeworks words lists (this.homeworkWordLists) is updated.
     */
    protected onHomeworkWordListsUpdate(): void {
        let column: GameProgressSelectableColumn;

        this.homeworkWordLists.map((item, index) => {
            
            column = new GameProgressSelectableColumn();
            column.key = item.id + "_homework";
            column.id = column.key;
            column.name = item.name;
            column.list_type = WordListType.homework;
            column.list_subtype = undefined;
            column.listInfo = item;
            column.order = this.columns.length + 1;

            column.color_code = "#d400c5";
            column.isSelectedValue = item.enabled;
            this.columns.push(column);
        });

        this.progressTableService.loadColumnsState(this.currentGame, this.currentLanguageType, this.columns);
    }

    /**
     * Triggered when the app activities list (this.appActivities) is updated.
     */
    protected onAppActivitiesUpdate(): void {

    }

}
