import { ID, Model, Member } from "@uon/model";

@Model()
export class TaxCode {
    
    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    key: string;
    
    @Member()
    code: object;

    @Member()
    name: object;
}

@Model()
export class Tax {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    deletedOn: Date;

    @Member()
    lastUsedOn: Date;

    // The tax code from TaxCode in english. TODO: Should be renamed for key.
    @Member()
    name: string;

    @Member()
    code: TaxCode;

    @Member()
    countryCode: string;

    @Member()
    stateCode: string;

    @Member()
    rate: number;

    @Member()
    stripeId: string;

    // Just for debugging purpose.
    @Member()
    stripeError: string;

    // Just for debugging purpose.
    @Member()
    contextCreated: any;

    @Member()
    no: string;

    @Member()
    archived: boolean;
}