import { ValidatorFn } from "@angular/forms";
import { FloatLabelType, MatFormFieldAppearance, SubscriptSizing } from "@angular/material/form-field";

export enum FieldType {
    Input = 'input',
    Separator = 'separator',
    TextArea = 'text-area',
    Select = 'select',
    RadioSelect = 'radio-select',
    RightSelect = 'right-select',
    Toggle = 'toggle',
    Group = 'group',
    Country = 'country',
    FormGroupTemplate = 'formgroup',
    HeadingText = 'heading-text',
}

export interface BaseConfig {
    typeKey?: string; // Input type for error messages.
    label?: string;
    data?: any;
}

export interface BaseFieldConfig extends BaseConfig {
    validators?: ValidatorFn[];
}

export interface SeparatorConfig extends BaseConfig {
    
}

export interface FormFieldConfig extends BaseConfig {

}

export interface CountryFieldConfig extends BaseFieldConfig {
    isRequired?: boolean;
}

export interface MatFormFieldConfig {
    appearance?: MatFormFieldAppearance;
    subscriptSizing?: SubscriptSizing;
    floatLabel?: FloatLabelType;
}




export interface FormControlConfig extends BaseFieldConfig {
    hidePlaceholderOnFocus?: boolean;
    isRequired?: boolean;
    hint?: string;
    placeholder?: string;
}

export interface FormGroupTemplateFieldConfig extends BaseFieldConfig {
    useMatFormField?: boolean;
}