import { Model, ArrayMember } from '@uon/model';
import { OrganizationInvite } from './invite.model';

@Model()
export class SendInvitesResponse {

    @ArrayMember(OrganizationInvite)
    invites: OrganizationInvite[];

}

