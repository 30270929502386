import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Classroom, MultiCountersUtils, SubscriptionSeat, UserAllocationsResponse } from '@applogic/model';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { ProductService } from 'src/app/services/product.service';
import { AssignTokensPanelComponent } from '../assign-tokens-panel/assign-tokens-panel.component';
import { SeatService } from 'src/app/services/seat.service';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface AssignClassroomTokensDialogData {
    classroom: Classroom,
    userAllocations: UserAllocationsResponse
}

@Component({
    selector: 'app-assign-classroom-tokens-dialog',
    templateUrl: './assign-classroom-tokens-dialog.component.html',
    styleUrls: ['./assign-classroom-tokens-dialog.component.scss']
})
export class AssignClassroomTokensDialogComponent implements OnInit {

    errorObject: any;

    classroom: Classroom;
    userAllocations: UserAllocationsResponse;

    @ViewChild(AssignTokensPanelComponent, { static: false })
    tokensPanel: AssignTokensPanelComponent;

    addedSeats: SubscriptionSeat[] = [];
    productsCode: string[] = [];

    // Attributes for displaying the result in case of an error.
    displayResult: boolean = false;
    allAssigned: boolean = false;
    assignedProducts: string[] = [];
    allocationsByProducts: { [productCode: string]: { studentsId: string[], allocationsId: string[] } } = {};

    result: SubscriptionSeat[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: AssignClassroomTokensDialogData,
        private mdDialogRef: MatDialogRef<AssignClassroomTokensDialogComponent, SubscriptionSeat[]>,
        private seatService: SeatService,
        private errorDialogService: HandleErrorDialogService,
        private productService: ProductService,
        private dialog: MatDialog) {
        this.productsCode = this.productService.getProductCodes();
        this.classroom = data.classroom;
        this.userAllocations = data.userAllocations;
    }

    ngOnInit(): void {

    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: AssignClassroomTokensDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<AssignClassroomTokensDialogComponent, AssignClassroomTokensDialogData, SubscriptionSeat[]>(dialogService, dialog, AssignClassroomTokensDialogComponent, {
            data,
            maxWidth: "1000px"
        },
        config);
    }

    assignTokens() {

        let allocationsId: string[] = [];

        this.userAllocations.subscriptions.forEach((sub) => {
            sub.allocations.forEach((alloc) => {
                allocationsId.push(alloc.id);
            });
        });

        if (allocationsId.length == 0) {
            return;
        }

        let now = new Date();

        let allocationsByProducts: { [productCode: string]: { studentsId: string[], allocationsId: string[] } } = {};

        for (const productCode of Object.keys(this.tokensPanel.multiChecked)) {
            allocationsByProducts[productCode] = { studentsId: [], allocationsId: [] };
            allocationsByProducts[productCode].studentsId = this.classroom.students.filter((s) => {
                if (s.subscriptionSeats) {
                    this.allAssigned = true;
                    let seat = s.subscriptionSeats.find((s) => (s.productId == productCode) && (s.expiresOn > now));

                    if (seat) {
                        return false;
                    }

                    return true;
                }

                return true;
            }).map((s) => s.id);

            let allocationsId = this.tokensPanel.multiChecked[productCode];
            for (const allocationId of Object.keys(allocationsId)) {
                if (allocationsId[allocationId]) {
                    allocationsByProducts[productCode].allocationsId.push(allocationId);
                }
            }
        }

        this.allocationsByProducts = allocationsByProducts;
        this.assignedProducts = Object.keys(allocationsByProducts);

        // this.displayResultTest();

        this.seatService.assignSeatToStudents(allocationsByProducts).subscribe((seats) => {
            if (seats) {
                seats.forEach((seat) => {
                    this.addedSeats.push(seat);

                    // Update the corresponding allocation.
                    let alloc = this.userAllocations.getAllocation(seat.allocationId);
                    if(alloc) {
                        if(!alloc.freeSeats) alloc.freeSeats = {};
                        if(!alloc.usedSeats) alloc.usedSeats = {};
                        MultiCountersUtils.move(alloc.freeSeats, alloc.usedSeats, {[seat.productId]: 1});
                    } else {
                        console.error("Missing allocation id " + seat.allocationId + " for the seat id " + seat.id);
                    }
                });

                this.result = seats;

                this.displayResult = true;
            }
        }, (err) => {
            this.errorDialogService.openErrorDialog(undefined, err, false);
        });
    }

    displayResultTest() {
        let seats: SubscriptionSeat[] = [];

        for (let i = 0; i < this.classroom.students.length; i++) {
            let student = this.classroom.students[i];
            if (student.subscriptionSeats) {
                for (let j = 0; j < student.subscriptionSeats.length; j++) {
                    if(Math.random() < 0.4) {
                        seats.push(student.subscriptionSeats[j]);
                    }
                }
            }
        }

        this.addedSeats = seats;
        this.displayResult = true;
    }

    /**
     * Return the product result sentences.
     * 
     * @param {string} productCode The product code.
     */
    getProductResult(productCode: string): string {

        let result: string = "";

        let productParam = this.allocationsByProducts[productCode];
        let studentsId: string[] = this.addedSeats.filter((s) => {
            if (s.productId == productCode) {
                return true;
            }

            return false;
        }).map((s) => s.id);

        let nbAssignedStudents: number = studentsId.length;
        let nbNotAssignedStudents: number = productParam.studentsId.length - nbAssignedStudents;

        result += $localize`:@@assign-students-to-product-tokens-result-line1:Tokens have been assigned to ${nbAssignedStudents} student profiles.` + "\n";

        if (nbNotAssignedStudents > 0) {
            result += $localize`:@@assign-students-to-product-tokens-result-line2:${nbNotAssignedStudents} students do not yet have a token.`;
            setTimeout(() => {
                this.allAssigned = false;
            });
        }

        return result;
    }

    onClose() {
        this.mdDialogRef.close(this.result);
    }
}
