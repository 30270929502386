import { Member, Model } from "@uon/model";

/**
 * The list of options to generate the word syntax.
 */
 @Model()
export class WordSyntaxOptions {

    constructor() {
        this.consecutiveSupport = false;
        this.auditiveConfusions = true;
        this.visualConfusions = true;
        this.doubleConsonant = true;
    }

    @Member()
    public consecutiveSupport: boolean;

    @Member()
    public auditiveConfusions: boolean;

    @Member()
    public visualConfusions: boolean;

    @Member()
    public doubleConsonant: boolean;

    clone(): WordSyntaxOptions {
        let result = new WordSyntaxOptions();
        result.consecutiveSupport = this.consecutiveSupport;
        result.auditiveConfusions = this.auditiveConfusions;
        result.visualConfusions = this.visualConfusions;
        result.doubleConsonant = this.doubleConsonant;
        return result;
    }
}