import { Model, ID, Member } from '@uon/model';
import { UserActivityType } from './user-activity-type';

@Model()
export class UserActivity {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    userId: string;

    @Member()
    userType: string;

    @Member()
    type: UserActivityType;

    @Member()
    subtype: string;

    @Member()
    context: string;

    @Member()
    data: any;

    @Member()
    logEventId: string;

    @Member()
    dimensions: {[key: string]: any};

}