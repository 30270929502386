import { Model, ID, Member, ArrayMember } from "@uon/model";
import { Invoice } from "./invoice.model";
import { Organization } from "../org/organization.model";
import { User } from "../user/user.model";
import { MultiCounters } from "../common/multi-counters";
import { SubscriptionAllocationMode } from "./subscription-allocation-mode";
import { Classroom } from "../org/classroom.model";



@Model()
export class SubscriptionGrant {

    @Member()
    productShortCode: string;

    @Member()
    seats: number;

}

@Model()
export class StripeDetails {

    @Member()
    subsId: string;

    @Member()
    invoiceId: string;

    @Member()
    priceId: string;

    @Member()
    status: Status;

    @Member()
    countryCode: string;

    @Member()
    currency: string;

    @Member()
    event: Object;
}

export enum SubscriptionType {
    organization = "organization",
    individual = "individual"
}

export enum SubscriptionPlan {
    monthly = "monthly",
    yearly = "yearly",
    trial = "trial",
    customized = 'customized',
    quarterly = "quarterly",
    halfYearly = "halfYearly",
    trialClassroom = "trialClassroom",
    trialOrganization = "trialOrganization",
}

export class SubscriptionPlanUtils {

    private static trialLength: number = -1;

    public static setTrialLength(len: number) {
        SubscriptionPlanUtils.trialLength = len;
    }

    public static getTrials()
    {
        return [SubscriptionPlan.trial, SubscriptionPlan.trialClassroom, SubscriptionPlan.trialOrganization];
    }

    public static isTrial(plan: SubscriptionPlan): boolean {
        return (plan == SubscriptionPlan.trial) || (plan == SubscriptionPlan.trialClassroom || (plan == SubscriptionPlan.trialOrganization));
    }

    /**
     * Check if a plan can be set manually. Other types can only be generated automatically.
     * 
     * @param {SubscriptionPlan} plan 
     * 
     * @returns {boolean} Returns true if the plan can be set manually.
     */
    public static isManual(plan: SubscriptionPlan): boolean {
        return (plan == 'trial') || (plan == 'customized');
    }

    public static getExpireDate(plan: SubscriptionPlan, from: Date = undefined): Date {
        if(!from) from = new Date();
        
        switch(plan)
        {
            case SubscriptionPlan.halfYearly:
                from.setMonth(from.getMonth() + 6);
                break;

            case SubscriptionPlan.monthly:
                from.setMonth(from.getMonth() + 1);
                break;

            case SubscriptionPlan.quarterly:
                from.setMonth(from.getMonth() + 3);
                break;

            case SubscriptionPlan.trial:
            case SubscriptionPlan.trialClassroom:
            case SubscriptionPlan.trialOrganization:
                if(SubscriptionPlanUtils.trialLength == -1)
                {
                    throw new Error("The trial length not set");
                }
                from = new Date(Date.now() + SubscriptionPlanUtils.trialLength);
                break;

            case SubscriptionPlan.yearly:
                from.setFullYear(from.getFullYear() + 1);
                break;
        }
        
        return from;
    }

    public static getMonths(plan: SubscriptionPlan) {
        switch(plan)
        {
            case SubscriptionPlan.monthly:
                return 1;
            case SubscriptionPlan.quarterly:
                return 3;    
            case SubscriptionPlan.halfYearly:
                return 6;
            case SubscriptionPlan.yearly:
                return 12;
        }
    }

    public static getPlans(type: SubscriptionType) {
        if(type == SubscriptionType.individual) {
            return [SubscriptionPlan.monthly, SubscriptionPlan.yearly];
        }
        else if(type == SubscriptionType.organization) {
            return [SubscriptionPlan.quarterly, SubscriptionPlan.halfYearly, SubscriptionPlan.yearly];
        }

        return [];
    }

    public static getLabelForStripe(type: SubscriptionType, plan: SubscriptionPlan, currency: string) {
        const months = this.getMonths(plan);
        return type + " - " + plan + " (" + months + " month" + (months > 1 ? "s" : "") + ")(" + currency + ")"
    }

    public static getMinimumSeats(type: SubscriptionType) {
        if(type == SubscriptionType.organization) return 10;
        return 1;
    }
}

export enum RenewOption {
    DoNothing = "Do Nothing",
    RenewMonthly = "Renew Monthly",
    RenewAnnually = "Renew Annually",
    RenewQuarterly = "Renew Quarterly",
    RenewHalfYearly = "Renew HalfYearly"
}

export enum Status {
    active = "active",
    past_due = "past_due",
    unpaid = "unpaid",
    canceled = "canceled",
    incomplete = "incomplete",
    incomplete_expired = "incomplete_expired",
    trialing = "trialing",
    all = "all",
    ended = "ended",
}

@Model()
export class SubscriptionNotifications {

    @Member()
    autoRenewEmailDeliveryId: string;

    @Member()
    autoRenewEmailDeliveryDate: Date;
}

@Model()
export class Subscription {

    @ID()
    id: string;

    @ArrayMember(SubscriptionGrant)
    grants: SubscriptionGrant[];

    @Member()
    type: SubscriptionType;

    @Member()
    subNo: string;

    @Member()
    invoice: Invoice;

    // The organization in which the subscription is associated (if type is SubscriptionType.organization).
    @Member()
    organization: Organization;

    // The user in which the subscription is associated (if type is SubscriptionType.user).
    @Member()
    user: User;

    // The classroom in which the subscription is associated (if type is SubscriptionType.organization).
    // (and also if plan == SubscriptionPlan.trialClassroom)
    @Member()
    classroom: Classroom;

    // The owner of the subscription. (This is essentially the buyer or the person that register a trial).
    @Member()
    owner: User;

    @Member()
    createdBy: User;

    @Member()
    stripeDetails: StripeDetails;

    @Member()
    renewOption: RenewOption;

    @Member()
    plan: SubscriptionPlan;

    @Member()
    createdOn: Date;

    @Member()
    expiresOn: Date;

    @Member()
    renewOn: Date;

    @Member()
    renewCount: number;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    updatedOn: Date;

    @Member()
    usedOn: Date;

    @Member()
    allocationMode: SubscriptionAllocationMode;

    // To track the quotation that was used to create this subscription.
    @Member()
    quotationId: string;

    // Optional parameter to tell if subscription is active or not. If not defined, consider the subscription is active.
    @Member()
    active: boolean;

    @Member()
    notifications: SubscriptionNotifications;

    getTotalSeats() {
        let counters: MultiCounters = {};

        if(this.grants)
        {
            this.grants.forEach((g) => {
                counters[g.productShortCode.toLowerCase()] = g.seats;
            });
        }

        return counters;
    }

    @Member()
    _usedSeats: MultiCounters;

    @Member()
    _freeSeats: MultiCounters;

    @Member()
    _totalSeats: MultiCounters;

    @Member()
    _seatsUsageRatio: number;
}