import { Component, Input } from '@angular/core';
import { CustomFormInputFieldComponent, InputFieldConfig } from '../custom-form-input-field/custom-form-input-field.component';
import { MatFormFieldConfig } from '../../custom-form-field-config';

export interface TextAreaFieldConfig extends InputFieldConfig, MatFormFieldConfig {
    placeholder?: string;
    autocomplete?: boolean;
}

@Component({
    selector: 'app-custom-form-text-area-field',
    templateUrl: './custom-form-text-area-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-text-area-field.component.scss']
})
export class CustomFormTextAreaFieldComponent extends CustomFormInputFieldComponent {


    @Input()
    data: TextAreaFieldConfig;
}
