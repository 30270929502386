import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { AccountPageComponent } from './account-page/account-page.component';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionPageComponent } from './subscriptions/subscription-page.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { SubscriptionCardComponent } from './subscriptions/subscription-card/subscription-card.component';
import { SubscriptionCardMenuComponent } from './subscriptions/subscription-card-menu/subscription-card-menu.component';
import { SubscriptionCancelDialogComponent } from './subscriptions/subscription-cancel-dialog/subscription-cancel-dialog.component';
import { AuthAndAccountModule } from '../shared/auth-and-account/auth-and-account.module';
import { SubscriptionModePanelComponent } from './subscriptions/subscription-mode-panel/subscription-mode-panel.component';
import { SubscriptionInvoiceEditDialogComponent } from './subscriptions/subscription-invoice-edit-dialog/subscription-invoice-edit-dialog.component';
import { ProductModule } from '../product/product.module';
import { QuotationListComponent } from './subscriptions/quotation-list/quotation-list.component';
import { SubscriptionInvoiceListComponent } from './subscriptions/subscription-invoice-list/subscription-invoice-list.component';
import { OnboardingUiModule } from '../onboarding-ui/onboarding-ui.module';
import { OrderUiModule } from '../order-ui/order-ui.module';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';
import { BillingPageComponent } from './billing-page/billing-page.component';
import { SubscriptionListComponent } from './subscriptions/subscription-list/subscription-list.component';
import { LicenseListComponent } from './subscriptions/license-list/license-list.component';
import { SubscriptionCardTemplateComponent } from './subscriptions/subscription-card-template/subscription-card-template.component';
import { SubscriptionModeDialogComponent } from './subscriptions/subscription-mode-dialog/subscription-mode-dialog.component';


@NgModule({
    declarations: [
        AccountPageComponent,
        BillingPageComponent,
        LicenseListComponent,
        SubscriptionPageComponent,
        SubscriptionCancelDialogComponent,
        SubscriptionCardComponent,
        SubscriptionCardTemplateComponent,
        SubscriptionCardMenuComponent,
        SubscriptionInvoiceEditDialogComponent,
        SubscriptionInvoiceListComponent,
        SubscriptionListComponent,
        SubscriptionModeDialogComponent,
        SubscriptionModePanelComponent,
        QuotationListComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        ReactiveFormsModule,
        AuthUiModule,
        MatTableModule,
        OverlayModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatTabsModule,
        TooltipModule,
        AuthAndAccountModule,
        ProductModule,
        OnboardingUiModule,
        OrderUiModule,
        DashboardUiModule,
    ],
    exports: [
        SubscriptionModeDialogComponent,
        SubscriptionModePanelComponent
    ]
})
export class AccountModule { }
