<div class="form-container">


    <div class="header-container">
        <ng-content select="app-login-form-header"></ng-content>

    </div>

    <ng-container *ngIf="!multiStep">
        <app-base-login-register-form [email]="email"
                                      floatLabel="auto"
                                      [formType]="'user-login'"
                                      [formName]="'login-form'"
                                      [showLogo]="true"
                                      (callApiOnPressEnter)="onPressEnter($event)"
                                      (onServiceLoginClick)="onServiceLoginClick($event)"
                                      [smallAlternateLogin]="false"
                                      #emailBaseForm>
            <div class="actions"
                 actions>
                <button mat-flat-button
                        color="primary"
                        class="custom-primary-button"
                        (click)="signIn(emailBaseForm.baseLoginRegisterForm)"
                        [disabled]="emailBaseForm.baseLoginRegisterForm.invalid">
                    <span i18n="@@common-signin">Sign in</span>
                </button>
            </div>
        </app-base-login-register-form>

        <div class="actions">
            <span class="link">
                <a class="login-register-footer-link"
                   i18n="@@login-form-forgot-password"
                   routerLink="/password-reset">
                    Forgot your password?
                </a>
            </span>

            <span class="spacer"></span>

            <span class="link">
                <a class="login-register-footer-link"
                   i18n="@@login-register-label"
                   routerLink="/register"
                   queryParamsHandling="preserve">New here? Sign up</a>
            </span>
        </div>

        <app-error-message-area [error]="errorObject"></app-error-message-area>
    </ng-container>

    <ng-container *ngIf="multiStep">
        <mat-horizontal-stepper #stepper
                                [linear]="true">

            <mat-step>
                <ng-container>
                    <h4 i18n="@@login-form-welcome-to-appligogiques-portal">
                        Welcome to the Appligogiques portal
                    </h4>

                    <p i18n="@@login-form-email-address-sub-heading-blurb"
                       class="f-14 padding-bottom-10">
                        Enter your email address to log on, or create an account to access the portal features.
                    </p>
                    <app-base-login-register-form [email]="email"
                                                  [useLabelAsPlaceholder]="false"
                                                  [formType]="'login-email-step'"
                                                  [formName]="'login-form'"
                                                  (callApiOnPressEnter)="onPressEnter($event)"
                                                  (onServiceLoginClick)="onServiceLoginClick($event)"
                                                  [smallAlternateLogin]="false"
                                                  #emailBaseForm>
                        <div class="actions"
                             actions>

                            <span class="link">
                                <a class="login-box-links"
                                   i18n="@@login-register-label"
                                   routerLink="/register"
                                   queryParamsHandling="preserve">New here? Sign up</a>
                            </span>

                            <span class="spacer"></span>

                            <button mat-flat-button
                                    color="primary"
                                    (click)="emailCheck(emailBaseForm.baseLoginRegisterForm)"
                                    [disabled]="emailBaseForm.baseLoginRegisterForm.invalid">
                                <span i18n="@@common-next"> Next </span>
                            </button>

                        </div>
                    </app-base-login-register-form>

                    <app-error-message-area [error]="errorObject"></app-error-message-area>
                </ng-container>
            </mat-step>

            <mat-step>
                <ng-container>
                    <app-user-card *ngIf="user"
                                   (click)="stepBack()"
                                   [user]="user"></app-user-card>

                    <h4>
                        <span *ngIf="user"
                              i18n="@@common-greetings">Greetings</span> {{ user.fullName }}!
                    </h4>

                    <p i18n="@@login-form-password-sub-heading-blurb"
                       class="padding-bottom-10 f-14">
                        Enter the password associated with your account.
                    </p>

                    <app-base-login-register-form [formType]="'login-password-step'"
                                                  [formName]="'login-form'"
                                                  [isStepNext]="isStepNext"
                                                  (callApiOnPressEnter)="onPressEnter($event)"
                                                  #passwordBaseForm>
                        <div class="actions"
                             actions>

                            <span class="link">
                                <a class="login-box-links"
                                   i18n="@@login-form-forgot-password"
                                   routerLink="/password-reset">
                                    Forgot your password?
                                </a>
                            </span>

                            <span class="spacer"></span>

                            <button mat-flat-button
                                    color="primary"
                                    type="submit"
                                    (click)="signIn(passwordBaseForm.baseLoginRegisterForm)"
                                    [disabled]="passwordBaseForm.baseLoginRegisterForm.invalid || !passwordBaseForm.baseLoginRegisterForm.controls.password.value">
                                <span i18n="@@common-next"> Next </span>
                            </button>

                        </div>
                    </app-base-login-register-form>

                    <app-error-message-area [error]="errorObject"></app-error-message-area>
                </ng-container>
            </mat-step>

        </mat-horizontal-stepper>
    </ng-container>

</div>