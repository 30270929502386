import { Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrl: './dialog.component.scss'
})
export class DialogComponent<T> {
    ApplogicUtils = ApplogicUtils;

    @Input()
    icon: string;

    @Input()
    svgIcon: string;

    @Input()
    iconColor: string;

    @Input()
    errorObject: any;

    @Input()
    adminSection: boolean = false;

    @Input()
    showCloseButton: boolean = true;

    @Input()
    showTitleSection: boolean = true;

    @Input()
    showActionsSection: boolean = true;

    @HostBinding("class") get getHostClass() {
        return 'app-dialog-host';
    }

    @HostBinding('class.no-overflow-x')
    @Input()
    hideOverflowX: boolean = false;

    @Input()
    isDraggable: boolean = false;

    @Input()
    draggableCacheKey: string;

    leftTitleTemplate: TemplateRef<any>;
    rightTitleTemplate: TemplateRef<any>;
    belowHeaderTemplate: TemplateRef<any>;

    constructor(protected mdDialogRef: MatDialogRef<T>, protected dialog: MatDialog) {

    }

    ngOnInit() {
        // console.log("#### " + this.mdDialogRef.componentInstance?.constructor.name);
    }

    onCloseButtonClick() {
        this.mdDialogRef.close();
    }
}
