
export const SiteEvents = {
    Organization: {
        Category: "org",
        Events: {
            InviteUser: "InviteUser",
            AllocateSeats: "AllocateSeats"
        }
    },
    Subscription: {
        Category: "subscription",
        Events: {
            AllocationModeChanged: "allocation-mode-changed"
        }
    }
};