<div [ngClass]="{'icons-layout': layout == 'icons', 'list-layout': layout == 'list'}">
    <a *ngFor="let link of (product?.links || []) | filterType:['store','install']"
       [href]="languageService.localizeFromDict(link.url)"
       (click)="onLinkClick(link)"
       target="_blank"
       [ngClass]="{'list-link': layout == 'list'}">
        <app-shape type="square"
                   scale="extra-small"
                   color="unset"
                   [ngbTooltip]="languageService.localizeFromDict(link.label)"
                   [imageUrl]="languageService.localizeFromDict(link.icon)"></app-shape>
        @if(layout == 'list') {
        <span *ngIf="layout == 'list'">{{languageService.localizeFromDict(link.label)}}</span>
        <span class="spacer"></span>
        <span>></span>
        }
    </a>
    <span *ngIf="showPlayButton && product"
          class="spacer"></span>

    <a *ngIf="showPlayButton && product"
       [routerLink]="'/games/' + product.shortCode.toLowerCase()"
       (click)="playButtonClick($event)">
        <mat-icon class="extra-small-shape play-arrow">play_arrow</mat-icon>
    </a>
</div>