import { ValidationFailure } from "@uon/model";

/**
 * Creates a validator that will test that a string value is at least the provided length 
 * @param min 
 */
export function MinLengthEx(min: number, options: {trimSpaces?: boolean} = {}) {

    return function (model: any, key: string, val: string) {

        if (val === null || val === undefined) {
            return val;
        }

        let str_len: number;

        if(options.trimSpaces) {
            str_len = val.trim().length;
        }
        else {
            str_len = val.length;
        }

        if (str_len < min) {
            throw new ValidationFailure(MinLengthEx, key, val, {
                msg: `below minimum length`,
                minLength: min,
                length: val.length
            });
        }

        return val;

    }
}

/**
 * Creates a validator that will test that a string value is at maximum the provided length 
 * @param max 
 */
export function MaxLengthEx(max: number, options: {trimSpaces?: boolean} = {}) {

    return function (model: any, key: string, val: string) {

        if (val === null || val === undefined) {
            return val;
        }

        let str_len: number;

        if(options.trimSpaces) {
            str_len = val.trim().length;
        }
        else {
            str_len = val.length;
        }

        if (str_len > max) {
            throw new ValidationFailure(MaxLengthEx, key, val, {
                msg: `above maximum length`,
                maxLength: max,
                length: val.length
            });
        }

        return val;

    }
}