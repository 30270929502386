import { Component, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Organization, CommonQueryFilter, User, OrganizationInvite } from '@applogic/model';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { ChangeMemberRoleDialogComponent } from '../change-member-role-dialog/change-member-role-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
    selector: 'app-organization-member-invitation-list',
    templateUrl: './organization-member-invitation-list.component.html',
    styleUrl: './organization-member-invitation-list.component.scss'
})
export class OrganizationMemberInvitationListComponent extends CommonListComponent<OrganizationInvite> {

    @Input()
    organization: Organization;

    @Input()
    isAdmin: boolean = false;

    protected currentUserId: string;

    // If specified, add a column to display the role of this user.
    @Input()
    userRole: User;

    constructor(
        private injector: Injector,
        private authService: AuthService,
        private organizationService: OrganizationService,
        private router: Router,
        private notificationService: NotificationService
    ) {
        super(injector);

        this.alwaysShowSelected = true;
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 20];

        this.supportCompactMode = true;
    }

    ngOnInit(): void {
        this.currentUserId = JSON.parse(localStorage.getItem('applogic/auth/current_user')).id;

        super.ngOnInit();
        this.initColumns();
        this.refreshItems();
    }

    initColumns() {
        this.initPersistentKey('applogic/organization/' + (this.isAdmin ? '-admin' : ''));

        if (this.userRole) {
            this.addColumn("id", "user.id", true, false, CommonColumnType.ID);
        }

        this.addColumn($localize`:@@common-email: Email `, "email", true, true);

        if (!this.compactRow) {
            this.addColumn($localize`:@@common-invited-on:Invited on`, "createdOn", true, true, CommonColumnType.Date).parameters.format = 'mediumDate';
        }

        this.addColumn($localize`:@@common-role:Role`, "role", true, true, CommonColumnType.Template, {
            isSortable: false,
            fixedWidth: "60px"

        });

        // this.addColumn($localize`:@@common-user-role:User Role`, "userRole", false, true, CommonColumnType.Template).canSort = false;
        if (this.userRole) {
            this.addColumn(this.userRole.fullName, "userRole", false, true, CommonColumnType.Template, {
                isSortable: false,
                fixedWidth: "60px"
            });
        }

        if (this.isAdmin) {
            this.addColumn("", "actions", false, true, CommonColumnType.Action);
        }

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;
        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });
        queryFilter.other.all = false;

        this.organizationService.getInvites(this.organization.id)
            .subscribe((invites) => {
                this.setPreloadItems(invites);
            });

        
    }

    onCompactModeChanged() {
        this.initColumns();
    }

    canShowAction(row: OrganizationInvite) {
        return this.isAdmin;
    }

    resendInvite(invite: OrganizationInvite) {
        this.organizationService.resendInvites(this.organization.id, invite.id)
            .subscribe({
                next: (val) => {
                    this.notificationService.notify({
                        message: $localize`:@@staff-invite-success-notification: Invitation successfully sent `,
                        style: 'success'
                    });
                    
                },
                error: (err) => {
                    console.error(err);
                    this.notificationService.notify({
                        message: $localize`:@@staff-invite-failed-notification: Send invitation failed `,
                        style: 'error'
                    });
                }
            });
    }

    openChangeInviteRoleDialog(invite: OrganizationInvite) {

        const appDialog = ChangeMemberRoleDialogComponent.createDialog(this.dialogService, this.dialog, {
            orgId: this.organization.id,
            inviteId: invite.id,
            currentRole: invite.role,
            isInviteRole: true
        });

        appDialog.show().then((role) => {
            invite.role = role;
        });
    }

    deleteInvite(invite: OrganizationInvite) {
        this.organizationService.removeInvite(this.organization.id, invite).subscribe(() => {
            this.removeItem(invite);
        });
    }

    resendAllInvitations() {
        if (this.preloadedItems) {
            for (let i = 0; i < this.preloadedItems.length; i++) {
                this.resendInvite(this.preloadedItems[i]);
            }
        }
    }
}
