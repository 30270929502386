import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormService } from 'src/app/services/form.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-error-message-area',
    templateUrl: './error-message-area.component.html',
    styleUrls: ['./error-message-area.component.scss']
})
export class ErrorMessageAreaComponent implements OnInit {

    protected _error: any;

    userMessage: SafeHtml;

    errorMessage: string;

    errorSubMessage: string;

    @Input()
    noPadding: boolean = false;

    @HostBinding('style.display') get display() {
        return this._error ? 'block' : 'none';
    }
    
    @Input()
    messageType: string = 'error';

    @Input()
    isAdmin: boolean = false;

    @Input()
    logError: boolean = false;

    @Input()
    set error(val: any) {
        if (val) {
            // console.log("Err: " + JSON.stringify(val));
            // console.log("constructor?.name: " + val.constructor?.name );
            // console.log("typeof: " + JSON.stringify(typeof val) );

            if(this.logError)
            {
                try {
                    console.error(val);
                } catch(ex) {
                    console.error(ex);
                }
            }

            if(val.constructor?.name == "HttpErrorResponse" ) {
                this.errorMessage = val.message;
                if(val.error) {
                    if( (val.error?.type == "body") && (val.error.errors)) {
                        // Only generate the body validation error if there is not user message or
                        // if this is the internal error message.
                        if(!val.userMessage || (val.userMessage.indexOf("mailto:info") != -1)) {
                            const validationMessage = this.formService.GetResponseFieldsErrors(val.error.errors).join("<br/>\n");
                            if(validationMessage) {
                                val.userMessage = validationMessage;
                            }
                        }
                    }

                    this.errorSubMessage = "Response: " + JSON.stringify(val.error);
                }
            }
            else {
                if (val.error && val.error.errorEventId) {
                    this.errorEventId = val.error.errorEventId;
                } else if(val.errorEventId) {
                    this.errorEventId = val.errorEventId;
                } else {
                    this.errorEventId = undefined;
                }

                if (val.error) {
                    this.errorMessage = val.error.message;
                } else {
                    this.errorMessage = val.message;
                }
            }

            if(val.userMessage) {
                this.userMessage = this.domSanitizer.bypassSecurityTrustHtml(val.userMessage);
            } else {
                this.userMessage = undefined;
            }

            if(val.messageType) {
                this.messageType = val.messageType;
            }

        } else {
            this.errorEventId = undefined;
            this.errorMessage = undefined;
            this.userMessage = undefined;
        }

        if(this._error != val) {
            this.errorChange.emit(val);
            this._error = val;
        }

    }
    get error() {
        return this._error;
    }

    @Output()
    errorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    isProd: boolean = false;

    errorEventId: string;

    constructor(private domSanitizer: DomSanitizer, private formService: FormService) {
        this.isProd = environment.production;
    }

    ngOnInit(): void {
    }

}
