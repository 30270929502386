import { Directive, HostBinding, Input } from '@angular/core';
import { TypeScale } from 'src/app/material-design';

@Directive({
  selector: '[typeScale]'
})
export class TypeScaleDirective {

    @Input('typeScale')
    typeScale: TypeScale;

    @Input('weight')
    weight: string;

    @HostBinding('class') get getHostClass() {
        return 'app-md-' + this.typeScale;
    }

    @HostBinding('style') get getStyles() {
        return {
            ...this.weight && {
                'font-weight': this.weight
            }
        }
    }

    constructor() { }

}
