import { ArrayMember, ID, Member, Model } from "@uon/model";

@Model()
export class AnalyticsEvent {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    source: string;

    @Member()
    sessionId: string;

    // From Google Analytics
    @Member()
    clientId: string;

    @Member()
    userId: string;

    @Member()
    name: string;

    @Member()
    params: {[key: string]: any};

    @Member()
    clientIp: string;

    @Member()
    userAgent: string;

    @Member()
    installId: string;

    @Member()
    nogtag: boolean;
}