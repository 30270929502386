
import { Model, ID, Member, ArrayMember } from '@uon/model';
import { LogType } from './log-type';

@Model()
export class LogEvent {

    @ID()
    id: string;

    @Member()
    type: LogType;

    @Member()
    category: string;

    @Member()
    name: string;

    @Member()
    data: object;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    filtered: boolean;
}



