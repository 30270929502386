import { Model, Member } from "@uon/model";
import { PromoCodeType } from "./promo-code.model";
import { SubscriptionPlan, SubscriptionType } from "../subscription/subscription.model";


@Model()
export class CreatePromoCodeForm {
    
    @Member()
    code: string;

    @Member()
    type: PromoCodeType;

    @Member()
    validFrom: Date;

    @Member()
    validUntil: Date;

    @Member()
    discount: number;

    @Member()
    count?: number;

    @Member()
    stripeCouponCode?: string;
}

@Model()
export class ApplyPromoCodeForm {   

    @Member()
    type: SubscriptionType;

    @Member()
    plan?: SubscriptionPlan;

}