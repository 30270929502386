import { CdkScrollable } from "@angular/cdk/scrolling";
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PriceTable, PromoCodeTicket, SubscriptionPlan, TableDiscount, TableProduct, TableTaxAmount, Tax } from "@applogic/model";
import { environment } from "src/environments/environment";
import { SelectableProduct } from "../order/base-payment-form/selectable-product";
import { HttpParams } from "@angular/common/http";
import { FindModelAnnotation, Model } from "@uon/model";

export declare type FormControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

/**
 * An utility class for the Angular site.
 */
export class AngularUtils {

    static FoceDevMode: boolean = false;

    static isDevMode() {
        if (window.location.href.indexOf("devmode=true") != -1) {
            return true;
        }

        return (environment.serverType != "prod") || AngularUtils.FoceDevMode;
    }

    private static _isTestMode: boolean;

    static isTestMode() {
        if (AngularUtils._isTestMode != undefined) {
            return AngularUtils._isTestMode;
        }
        if (window.location.href.indexOf("testmode=true") != -1) {
            AngularUtils._isTestMode = true;
            return true;
        }

        AngularUtils._isTestMode = false;
        return false;
    }

    static printDebug(message: string) {
        if (!AngularUtils.isDevMode()) return;
        console.log("[DevMode][Debug] " + message);
    }

    static showFormState(label: string, form: UntypedFormGroup) {

        if (!form) return;
        console.log("FormState: " + label);
        Object.keys(form.controls).forEach(key => {
            this.showControlState(label, "." + key, form.controls[key]);
        });
    }

    static showFormGroupState(label: string, form: UntypedFormGroup, filterStatus?: FormControlStatus) {

        if (!form) return;

        this.showControlState(label, "", form, filterStatus);

        Object.keys(form.controls).forEach(key => {
            const control = form.controls[key];
            this.showControlState(label, "." + key, control, filterStatus);
        });
    }

    static showFormArrayState(label: string, form: UntypedFormArray, filterStatus?: FormControlStatus) {

        if (!form) return;

        for (let i = 0; i < form.controls.length; i++) {
            const control = form.controls[i];
            this.showControlState(label, "[" + i + "]", control, filterStatus);
        };
    }

    static showControlState(label: string, key: string, control: AbstractControl, filterStatus?: FormControlStatus) {
        if (key && (control instanceof UntypedFormGroup)) {
            this.showFormGroupState(label + key, control, filterStatus);
            return;
        }

        if ((filterStatus == undefined) || (filterStatus == control.status)) {
            let output: string = '';
            output += label + key + ": (" + control.constructor.name + ")";
            output += "\n\tDisabled: " + control.disabled;
            output += "\n\tValid: " + control.valid;
            output += "\tStatus: " + control.status;
            output += "\tErrors: " + JSON.stringify(control.errors);
            output += "\n\tTouched: " + control.touched;
            output += "\tDirty: " + control.dirty;
            output += "\tPristine: " + control.pristine;
            output += "\n\tValue: " + JSON.stringify(control.value);
            console.log(output);
        }

        if (control instanceof UntypedFormArray) {
            this.showFormArrayState(label + key, control);
        }
    }

    public static isDescendant(pParent: HTMLElement, pChild: HTMLElement) {
        try {
            while (pChild !== null) {
                if (pChild === pParent) {
                    return true;
                } else {
                    pChild = (pChild.parentNode as HTMLElement);
                }
            }
        } catch (e) {
            console.warn('isDescendant ', e);
        }
        return false;
    }

    public static getChildIndexes(pParent: HTMLElement, pChild: HTMLElement) {
        let lastChild: HTMLElement;
        let indexes: number[] = [];
        try {
            while (pChild !== null) {
                if (pChild === pParent) {
                    return indexes.reverse();
                } else {
                    lastChild = pChild;
                    pChild = (pChild.parentNode as HTMLElement);
                }

                if (pChild) {
                    var index = Array.prototype.indexOf.call(pChild.children, lastChild as Node);
                    indexes.push(index);
                }
            }
        } catch (e) {
            console.warn('getChildIndex ', e);
        }
        return [];
    }

    /**
     * Scroll to an element id pointed by the query parameter "scrollTo."
     * 
     * @param {ActivatedRoute} route The route to be able to retrieve the query.
     * @param {string} prefix (Optional) Prefix of the element id that is required to actually scrollTo.
     * @param {CdkScrollable} scrollable (Optional) The CdkScrollable to be used to scroll the element.
     * @param {(elementId: string) => HTMLElement} getElementRefFunc (Optional) Callback to retrieve the HTMLElement for an element id.
     * 
     * @returns {id:string, element: HTMLElement} If succeed, it return the element id and the html element where it was scrolled.
     */
    public static scrollToQuery(router: Router, route: ActivatedRoute, prefix: string = undefined, scrollable: CdkScrollable = undefined, getElementRefFunc: (elementId: string) => HTMLElement = undefined) {
        return new Promise<{ id: string, element: HTMLElement }>((resolve, reject) => {
            try {
                let scrollTo = route.snapshot.queryParamMap.get("scrollTo");

                if (scrollTo) {
                    if (prefix && !scrollTo.startsWith(prefix)) return;

                    let testmode = route.snapshot.queryParamMap.get("testmode");
                    if (!testmode) {
                        AngularUtils.removeRouteQueryParam("scrollTo", router, route);
                    }

                    setTimeout(() => {
                        let element: HTMLElement;
                        if (getElementRefFunc) {
                            element = getElementRefFunc(scrollTo);
                        } else {
                            element = document.getElementById(scrollTo);
                        }

                        if (element) {
                            if (scrollable) {
                                const bounds = element.getBoundingClientRect();
                                scrollable.scrollTo({ behavior: 'smooth', top: bounds.top - 80 + scrollable.measureScrollOffset('top') });
                            }
                            else {
                                element.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                            }
                        }

                        resolve({ id: scrollTo, element: element });
                    }, 1000);
                }
            }
            catch (ex) {
                console.error(ex);
                reject(ex);
            }
        });
    }

    public static removeRouteQueryParam(key: string, router: Router, activatedRoute: ActivatedRoute): void {
        activatedRoute.queryParams.subscribe((p: Params) => {
            // Remove the logintoken parameter but keep other existing parameters.
            let np = JSON.parse(JSON.stringify(p));
            delete np[key];

            router.navigate(
                [],
                {
                    relativeTo: activatedRoute,
                    queryParams: np,
                    replaceUrl: true // to avoid be able to go back with consumed query parameters.
                });
        });

    }

    public static getHtmlElementPath(elem: HTMLElement): string {
        let elemPath = "";
        while (elem) {
            elemPath += "/" + elem.tagName + " (" + elem.id + ")";

            elem = elem.parentElement;
        }

        return elemPath;
    }

    public static createPriceTable(productList: SelectableProduct[], taxes: Tax[], promoCodeTicket: PromoCodeTicket, langCode: string) {
        let priceTable: PriceTable = new PriceTable();

        priceTable.products = [];
        for (const product of productList) {
            const tableProduct = new TableProduct();
            tableProduct.productCode = product.code;
            tableProduct.totalPrice = product.totalPrice;
            tableProduct.seats = product.seats;
            tableProduct.unitPrice = product.price.amount.value;
            priceTable.currency = product.price.amount.currency;
            priceTable.products.push(tableProduct);

            product.subscriptionPlan
        }

        let sumtotal: number = 0;
        let subtotal = 0;
        let total = 0;
        let subTotalValue = 0
        productList.map(itemk => {
            subTotalValue += itemk.totalPrice;
        })
        subtotal = subTotalValue;
        priceTable.subtotal = subtotal;

        priceTable.discounts = [];

        if (promoCodeTicket && (promoCodeTicket.discount != 0)) {

            let applicablePrice: number = 0;

            productList.map(itemk => {
                if (promoCodeTicket && promoCodeTicket.checkConstraints({
                    type: itemk.subscriptionType,
                    plan: itemk.subscriptionPlan
                })) {
                    applicablePrice += itemk.totalPrice;
                }
            });

            if (applicablePrice != 0) {
                const discount = new TableDiscount();
                discount.code = promoCodeTicket.code;
                discount.rate = promoCodeTicket.discount;
                discount.amount = (applicablePrice * promoCodeTicket.discount) / 100;
                subTotalValue = subTotalValue - discount.amount;
                priceTable.discounts.push(discount);
            }
        }

        let subTotalTaxValue = subTotalValue;
        if (taxes && taxes.length) {
            taxes.map(taxes => {

                sumtotal = (sumtotal) + (subTotalValue * taxes.rate);

            })
        }

        total = subTotalValue + sumtotal;
        priceTable.total = total;

        priceTable.taxes = [];
        for (const tax of taxes) {
            const tableTax = new TableTaxAmount();
            tableTax.name = tax.name;
            if (tax.code?.code?.[langCode]) {
                tableTax.name = tax.code.code[langCode];
            }
            if (tax.code?.name?.[langCode]) {
                tableTax._taxCodeName = tax.code.name[langCode];
            }

            tableTax.no = tax.no;
            tableTax.rate = tax.rate;
            tableTax.amount = subTotalValue * tax.rate;
            priceTable.taxes.push(tableTax);
        }

        return priceTable;
    }

    public static getSubscriptionLabel(plan: SubscriptionPlan) {
        if (plan == SubscriptionPlan.monthly) {
            return $localize`:@@common-subscription-monthly:monthly subscription`;
        }
        else if (plan == SubscriptionPlan.quarterly) {
            return $localize`:@@common-subscription-quarterly:3 months subscription`;
        }
        else if (plan == SubscriptionPlan.halfYearly) {
            return $localize`:@@common-subscription-halfyearly:6 months subscription`;
        }
        else if (plan == SubscriptionPlan.yearly) {
            return $localize`:@@common-subscription-yearly:yearly subscription`;
        }

        return "";
    }

    public static downloadBase64File(data: string, filename: string, contentType: string) {
        const blob = AngularUtils.base64ToBlob(data, contentType);
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
    }

    public static base64ToBlob(b64Data, contentType, sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public static parseCssValue(cssValue, element) {
        const numericValue = parseFloat(cssValue);

        // Extract the unit
        const unit = cssValue.match(/[\d.\-+]*\s*(.*)/)[1] || '';

        if (unit === 'px') {
            // If unit is in pixels then simply return the value.
            return numericValue;
        } else if (unit === 'em') {
            // Convert the 'em' in 'pixels' using the font size of the element.
            const fontSize = parseFloat(getComputedStyle(element).fontSize);
            return numericValue * fontSize;
        }

        return numericValue;
    }
}

/**
 * Convert model to HttpParams.
 * @param model 
 * @returns 
 */
export function HttpParamsFromModelProperties<T>(TCreator: { new(): T; }, args: { [Property in keyof T]: T[Property] }) {

    // Keep only JSON properties.
    const model = JSON.parse(JSON.stringify(args));

    return new HttpParams({
        fromObject: model
    })
}

/**
 * Convert model to HttpParams.
 * @param model 
 * @returns 
 */
export function modelToHttpParams(model: any) {

    // Keep only properties.
    model = JSON.parse(JSON.stringify(model));

    return new HttpParams({
        fromObject: model
    })
}

export function GetFormGroupDirtyValues(form: FormGroup) {

    let result: any = {};
    for (var k in form.controls) {

        let c = form.controls[k];
        if (c.dirty) {

            if (c instanceof FormGroup) {
                result[k] = GetFormGroupDirtyValues(c);
            }
            else if (c instanceof FormArray) {
                // need to access value from form, c.value includes disabled control

                result[k] = form.value[k] || [];
            }
            else {
                result[k] = GetFormControlValue(c as FormControl);
            }

        }
    }
    return result;
}

function GetFormControlValue(control: FormControl) {
    let value = control.value;

    // check if ref
    if (typeof value == 'object' && value !== null) {
        let model = FindModelAnnotation(value.constructor) as Model;
        if (model && model.id) {
            return { [model.id.key]: value[model.id.key] };
        }
    }

    return value;
}