<app-dialog icon="people"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@assign-student-homeworks-dialog-title">Assign Homeworks</app-dialog-title>
    <app-dialog-content>
        <app-homework-list [classroom]="classroom"
                                 [student]="student"
                                 [displayForSelection]="true"
                                 [showEnabledOnly]="true"
                                 #assignStudentHomeworksListTable></app-homework-list>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="accent"
            (click)="assignHomeworks(assignStudentHomeworksListTable)">
        <span i18n="@@common-assign-button">Assign</span>
    </button>
</app-dialog>