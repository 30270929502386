import { Directive } from '@angular/core';

@Directive({
    selector: '[app-dialog-footer],[appDialogFooter],app-dialog-footer'
})
export class DialogFooterDirective {

    constructor() { }

}
