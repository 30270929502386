import { Model, Member, ID, ValidateEmail, ArrayMember } from "@uon/model";
import { CurrencyAmount } from "../common/currency.model";
import { OrganizationAddress } from "../org/address.model";
import { AdministrativeContact } from "../org/contact.model";
import { SubscriptionGrant } from "./subscription.model";



@Model()
export class SubscriptionQuote {

    @ID()
    id: string;

    @Member()
    quoteNumber: string;

    @Member()
    address: OrganizationAddress;

    @Member()
    administrativeContact: AdministrativeContact;

    @Member()
    requesterContact: AdministrativeContact;

    @ArrayMember(SubscriptionGrant)
    lineItems: SubscriptionGrant[];

    @Member()
    total: CurrencyAmount;

    @Member()
    initiatedOn: Date;

    @Member()
    completedOn: Date;

    @Member()
    expiresOn: Date;


}