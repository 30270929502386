import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MultiCountersUtils, OrgAllocationsResponse, SubAllocationsResponse, SubscriptionAllocation } from '@applogic/model';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-allocation-list-panel',
    templateUrl: './allocation-list-panel.component.html',
    styleUrls: ['./allocation-list-panel.component.scss']
})
export class AllocationListPanelComponent implements OnInit {

    @Input()
    orgAllocs: OrgAllocationsResponse;

    @Input()
    subAlloc: SubAllocationsResponse;

    @Input() contextMenu;

    allocationsList = new MatTableDataSource();

    productsCode: string[] = [];

    displayCol: string[] = ['user', 'products', 'action'];

    originalPush: any;

    allocationsList2: SubscriptionAllocation[];

    constructor(private languageService: LanguageService,
        private changeDetectorRefs: ChangeDetectorRef) { }

    ngOnInit(): void {

        let newList = [];
        this.productsCode = this.subAlloc.subscription.grants.map(g => g.productShortCode.toLowerCase());

        for (let i = 0; i < this.displayCol.length; i++) {
            let colName: string = this.displayCol[i];

            if (colName == "products") {
                this.productsCode.forEach((p) => {
                    newList.push("product_" + p);
                });
            } else {
                newList.push(colName);
            }
        }

        this.displayCol = newList;

        this.setData(this.subAlloc.allocations);

        /*
        this.allocationsList.filterPredicate =
            (data: SubscriptionAllocation, filter: string) => {
                return data.filter.userId ? true : false;
            };

        this.allocationsList.filter = "-";
        */  
    }

    ngOnDestroy() {
        this.setData([]);
    }

    setData(data: any[]) {
        /*
        if (this.allocationsList.data) {
            this.allocationsList.data.push = this.originalPush;
        }

        this.originalPush = data.push;

        data.push = function () {
            console.log("###element added###")
            let result = Array.prototype.push.apply(data, arguments);

            setTimeout(() => {
                this.refresh();
            });
            return result;
        }
        */

        this.allocationsList.data = data;

        this.allocationsList2 = data;
    }

    refresh() {
        this.changeDetectorRefs.detectChanges();
    }

    getUserName(userId: string) {
        let user = this.orgAllocs.users.find(a => a.id == userId);

        if (user) {
            return user.fullName;
        }

        return "[" + userId + "]";
    }

    getUser(userId: string) {
        return this.orgAllocs.users.find(a => a.id == userId);
    }

    getUserDesc(allocation: SubscriptionAllocation) {
        let result: string = "";

        // The following plural with the html side was not working:
        // There { totalUsedSeats,
        //     plural,
        //     =0 {is 0 student}
        //     =1 {is 1 student}
        //     other {are {{totalUsedSeats}} students}
        // } who have been assigned to the subscription by this user.

        let totalUsedSeats = this.getTotalUsedSeats(allocation);
        let theyarestudents = this.languageService.plural(totalUsedSeats, "", $localize`:@@common-they-are-x-students-one:They are [count] students`, $localize`:@@common-they-are-x-students-many:They are [count] students`);

        result += $localize`:@@org-seats-allocation-card-user-line:[theyaresxstudents] who have been assigned to the subscription by this user.`.replace("[theyaresxstudents]", theyarestudents);

        return result;
    }

    getTotalFreeSeats(alloc: SubscriptionAllocation | SubAllocationsResponse, productCode?: string): number {
        if (productCode) {
            let result = alloc.freeSeats[productCode];
            return result ? result : 0;
        }

        if (alloc.freeSeats) {
            return MultiCountersUtils.getTotal(alloc.usedSeats);
        }

        return 0;
    }

    getTotalUsedSeats(alloc: SubscriptionAllocation | SubAllocationsResponse, productCode?: string) {
        if (productCode) {
            return alloc.usedSeats[productCode] ? productCode[productCode] : 0;
        }

        if (alloc.usedSeats) {
            return MultiCountersUtils.getTotal(alloc.usedSeats);
        }

        return 0;
    }

    getTotalAllocatedSeats(alloc: SubscriptionAllocation | SubAllocationsResponse, productCode: string): number {
        let total = 0;

        total += alloc.freeSeats[productCode] ? alloc.freeSeats[productCode] : 0;
        total += alloc.usedSeats[productCode] ? alloc.usedSeats[productCode] : 0;

        return total;
    }
}
