<div *ngIf="_quantity" class="plans-section products-common-card-section">
    <div *ngFor="let plan of subscriptionPlans"
         (click)="setSubscriptionPlan(plan.type)"
         [ngClass]="{'selected-card': plan.type == _plan, 'promo-card': !!plan.promoCodeTicket,'card-when-promo': !!plan.promoCodeTicket}" class="card card-shadow remove-box-shadow">
        <h3 class="title">{{plan.label}}</h3>
        {{plan.promoCodeTicket?.style}}
        <div *ngIf="promoCodeTicket">
            <div [ngSwitch]="plan.promoCodeTicket?.style">
                <div *ngSwitchCase="'dragons'" class="subtitle" i18n="@@promo-code--dragons--short-description">special In the eye of the Dragon</div>
                <div *ngSwitchDefault class="subtitle">&nbsp;</div>
            </div>
        </div>
        <div>
            <app-price-label [amount]="plan.pricePerMonth" [currency]="currency" i18n-sublabel="@@products-plan-card-select-per-month" sublabel="per month*"></app-price-label>
        </div>
        <p *ngIf="plan.nbMonths == 1" class="subtext" i18n="@@products-plan-card-select-monthly-invoicing">
            *billed monthly, cancel at any time
        </p>
        <ng-container *ngIf="plan.nbMonths > 1">
            <p class="subtext" i18n="@@products-plan-card-select-period-invoicing" *ngIf="!plan.promoCodeTicket">*A payment of {{plan.price | apCurrency:currency}}, every {{plan.nbMonths}} month</p>
            <p class="subtext" i18n="@@products-plan-card-select-period-invoicing-first-year-promo" *ngIf="plan.promoCodeTicket">*A payment of {{plan.price | apCurrency:currency}}, for the first year, renewal at regular price</p>
        </ng-container>
    </div>
</div>