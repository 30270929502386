import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef,
    ChangeDetectorRef,
    AfterViewInit,
    Optional
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChecklistTrialClassroom, ChecklistType, Classroom, ClassroomResponse, OrganizationMember, OrganizationRole, OrganizationType, Product, Student, SubscriptionSeat, User } from "@applogic/model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
    AbstractControl,
} from "@angular/forms";
import { LoadingService } from "src/app/services/loading.interceptor";
import {
    ClassroomService,
} from "../classroom.service";
import { FADE_IN_OUT, OPACITY_IN_OUT } from "src/app/animations/fade";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ProductService } from "src/app/services/product.service";
import { AccountService } from "src/app/account/account.service";
import { OrganizationService } from "src/app/organization/organization.service";
import { MatSelectChange } from "@angular/material/select";
import { FormService } from "src/app/services/form.service";
import { EMAIL_REGEXP, Model } from "@uon/model";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LanguageService } from "src/app/services/language.service";
import { UserSeatsAllocationPanelComponent } from "src/app/seat-ui/user-seats-allocation-panel/user-seats-allocation-panel.component";
import { AssignTokensDialogComponent } from "../assign-tokens-dialog/assign-tokens-dialog.component";
import { SeatService } from 'src/app/services/seat.service';
import { HandleErrorDialogService } from "src/app/services/handle-error-dialog.service";
import { AddStudentDialogComponent } from "../add-student-dialog/add-student-dialog.component";
import { AssignClassroomTokensDialogComponent } from "../assign-classroom-tokens-dialog/assign-classroom-tokens-dialog.component";
import { ChecklistTabComponent } from "src/app/onboarding-ui/checklist-tab/checklist-tab.component";
import { AssignStudentHomeworksDialogComponent } from "../assign-student-homeworks-dialog/assign-student-homeworks-dialog.component";
import { SharedListService } from "src/app/services/shared-list.service";
import { OnboardingService } from "src/app/onboarding-ui/onboarding.service";
import { ChecklistPopoverComponent } from "src/app/onboarding-ui/checklist-popover/checklist-popover.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { LOCAL_STORAGE_USER_KEY } from "src/app/auth/auth.service";
import { ExportStudentsDialogComponent } from "../export-students-dialog/export-students-dialog.component";
import { AnalyticsEventsService } from "src/app/services/analytics-events.service";
import { SelectClassroomDialogComponent } from "./select-classroom-dialog/select-classroom-dialog.component";
import { StudentListComponent } from "src/app/student/student-list/student-list.component";
import { DialogService } from "src/app/services/dialog.service";
import { MessageBoxDialogComponent } from "src/app/core/message-box-dialog/message-box-dialog.component";
import { MessageBoxButton } from "src/app/core/message-box-dialog/messagebox-button";
import { MessageBoxSettings } from "src/app/core/message-box-dialog/messagebox-settings";
import { MessageBoxIcon } from "src/app/core/message-box-dialog/messagebox-icon";
import { MessageBoxResult } from "src/app/core/message-box-dialog/messagebox-result";
import { firstValueFrom } from "rxjs";
import { ClassroomDialogs } from "../classroom.dialogs";
import { MatTabGroup } from "@angular/material/tabs";
import { NavigationService } from "src/app/services/navigation.service";
import { ApplogicUtils } from "src/app/services/applogic-utils";


@Component({
    selector: "app-classroom-page",
    templateUrl: "./classroom-page.component.html",
    styleUrls: ["./classroom-page.component.scss", "./classroom-page-common.scss"],
    animations: [FADE_IN_OUT, OPACITY_IN_OUT],
})

export class ClassroomPageComponent implements OnInit, AfterViewInit {

    ApplogicUtils = ApplogicUtils;

    classroom: Classroom;
    classroomResponse: ClassroomResponse;

    classroomProgressList = [];

    currentUserId: string;

    @ViewChild("studentDeletedSnackbar", { static: true })
    studentDeletedSnackbar: TemplateRef<any>;

    @ViewChild("studentAllDeletedSnackbar", { static: true })
    studentAllDeletedSnackbar: TemplateRef<any>;

    @ViewChild(UserSeatsAllocationPanelComponent, { static: false })
    usersSeatAllocation: UserSeatsAllocationPanelComponent;

    isUsersSeatAllocationVisible: boolean = false;

    @ViewChild(StudentListComponent, { static: false })
    studentList: StudentListComponent;

    @ViewChild(ChecklistTabComponent, { static: false })
    checklistTab: ChecklistTabComponent;

    protected trialChecklist: ChecklistTrialClassroom;

    products: { [key: string]: Product } = {};

    panelOpenState = false;

    currentLanguage = "fr";

    currentAppGameCode: string = "";

    dateToggleIsSelected = false;

    currentUser: any;

    isAdmin: boolean = false;
    isOwner: boolean = false;
    canAddRemoveStudent: boolean = false;

    public isOnboardingVisible: boolean = false;

    students: Student[] = [];

    selectedStudentCount: number = 0;

    private _matGroup: MatTabGroup;

    @ViewChild(MatTabGroup, { static: false })
    set matGroup(v: MatTabGroup) {
        this._matGroup = v;
    }

    get matGroup() {
        return this._matGroup;
    }

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        readonly loading: LoadingService,
        public classroomService: ClassroomService,
        private orgService: OrganizationService,
        protected router: Router,
        readonly account: AccountService,
        readonly formService: FormService,
        private changeDetectorRef: ChangeDetectorRef,
        private localStorageService: LocalStorageService,
        private languageService: LanguageService,
        public productService: ProductService,
        private seatService: SeatService,
        private errorDialogService: HandleErrorDialogService,
        private analyticsEventsService: AnalyticsEventsService,
        private sharedListService: SharedListService,
        private onboardingService: OnboardingService,
        @Optional() private scrollable: CdkScrollable,
        private dialogService: DialogService,
        private navigationService: NavigationService) {

        this.currentLanguage = languageService.currentLanguageCode;

        this.currentAppGameCode = "";

        this.classroomProgressList = [
            {
                productShortCode: "MMO",
            },
            {
                productShortCode: "MSM",
            },
        ];
    }

    ngOnInit(): void {
        this.getUser();

        let classroomResponse: ClassroomResponse = this.route.snapshot.data.classroom;
        classroomResponse.allocateSeatsToStudents();
        this.classroomResponse = classroomResponse;
        this.classroom = classroomResponse.classroom;

        if (this.classroom.students.length) {
            this.classroom.students.map((student) => {
                student["isSelected"] = false;
            });
        }
        this.classroom.students.reverse();

        this.productService.getProductsDict().then((dict) => {
            this.products = dict;
        });

        this.refreshCurrentUser();

        if (this.classroom) {
            this.onboardingService.getChecklist(this.classroom.id, ChecklistType.Classroom).then((checklist) => {
                this.trialChecklist = checklist as ChecklistTrialClassroom;
            }).catch((ex) => {
                console.error(ex);
            });
        }
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.students = this.classroom.students;
        });

        ChecklistPopoverComponent.scrollToQueryAndOpenPopup(this.router, this.route, "game-", this.scrollable);

        this.studentList.selection.changed.subscribe((v) => {
            this.selectedStudentCount = v.source.selected.length;
        });
    }

    ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }

    getUser() {
        this.currentUser = this.localStorageService.get(LOCAL_STORAGE_USER_KEY);
        if (this.currentUser) {
            this.currentUserId = this.currentUser.id;
        }
    }

    get currentUserIsAdmin() {
        return this._userIsAdmin;
    }

    private _userIsAdmin: boolean = false;

    openChangeMemberRoleDialog(collaborator) { }

    showAddStudentDialog(student?: Student) {
        let subAllocations = this.isUsersSeatAllocationVisible ? this.usersSeatAllocation?.userAllocs : undefined;

        AddStudentDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroom: this.classroom,
            student,
            userAllocations: subAllocations
        }).show().then((students) => {
            this.studentList.refreshItems("student(s) added");
            if (this.checklistTab) {
                this.checklistTab.onStudentAdd();
            }
        }).catch((err) => {
            this.errorDialogService.openErrorDialog(undefined, err, false);
        });
    }

    showAssignTokensDialog() {
        AssignClassroomTokensDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroom: this.classroom,
            userAllocations: this.usersSeatAllocation.userAllocs
        }).show().then((seats) => {
            if (seats) {
                seats.forEach((s) => {
                    this.classroomResponse.addSeat(s);
                });
            }
        });
    }

    showAssignHomeworksDialog(student: Student) {
        AssignStudentHomeworksDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroom: this.classroom,
            student
        }).show().then(() => {

        });
    }

    showEditStudentDialog(student: Student) {
        AddStudentDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroom: this.classroom,
            student
        }).show().then((students) => {

        });
    }

    /**
     * REMOVE STUDENT CONFIRM DIALOG POPUP
     * @param student
     */
    removeStudent(student: Student) {
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@classroom-delete-student-confirm-text: You are about to remove <strong>${student.fullName}</strong> from your classroom. `,
            $localize`:@@classroom-delete-student-confirm-text-notice: Deleting this student will remove all of their progress and access to the applications. <br> This action cannot be undone. `
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
        ];

        settings.validateBeforeCallback = async (result) => {
            if (result == MessageBoxResult.OK) {
                try {
                    const studentIdsArrays = [student];
                    await firstValueFrom(this.classroomService
                        .deleteStudents(this.classroom, studentIdsArrays.map(s => s.id)))

                    this.studentList.removeItem(student);

                    this.snackbar.openFromTemplate(this.studentDeletedSnackbar, {
                        data: student,
                        duration: 3000,
                    });
                }
                catch (ex) {
                    return ex;
                }
            }
        };

        MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings).show();
    }

    /**
     * REMOVE ALL STUDENTS CONFIRMATION DIALOG POPUP
     */
    openRemoveAllDailog() {
        const nbStudents = this.studentList.selection.selected.length;
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@classroom-all-delete-student-confirm-text: You are about to delete <strong>${nbStudents}</strong> students from this classroom. `,
            $localize`:@@classroom-all-delete-student-confirm-text-notice: Deleting these students will remove all of their progress and access to the applications. <br> This action cannot be undone. `
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
        ];

        settings.validateBeforeCallback = async (result) => {
            if (result == MessageBoxResult.OK) {
                try {
                    await firstValueFrom(this.classroomService
                        .deleteStudents(this.classroom, this.studentList.selection.selected.map(s => s.id)));

                    this.studentList.removeSelectedItems();

                    this.snackbar.openFromTemplate(this.studentAllDeletedSnackbar, {
                        duration: 3000,
                    });
                }
                catch (ex) {
                    return ex;
                }
            }
        };

        MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings).show();
    }

    showDeleteClassroomDialog() {
        ClassroomDialogs.showDeleteClassroomDialog(this.dialogService, this.dialog, this.classroom, this.snackbar, this.classroomService).then(() => {
            this.router.navigate(["", "classrooms"], { replaceUrl: true });
        });
    }

    refreshCurrentUser(member?: OrganizationMember) {

        if (!member) {
            if (this.classroom.organization != null) {
                this.orgService.getMember(this.classroom.organization.id, this.currentUserId).subscribe((member) => {
                    if (member) {
                        this.refreshCurrentUser(member);
                    }
                });
            }

            return;
        }

        if (this.classroom.owner) {
            this.isOwner = this.classroom.owner.id == this.currentUser.id;
        }
        else {
            this.isOwner = false;
        }

        let isAdmin: boolean = false;
        if (member) {
            if (member.role == OrganizationRole.Administrator) {
                isAdmin = true;
            }
        }

        if (this.isAdmin != isAdmin) {
            this.isAdmin = isAdmin;
        }

        this.canAddRemoveStudent = this.isAdmin || this.isOwner;
    }

    isUserOwner(userId: string) {
        return this.classroom.owner && (this.classroom.owner.id == userId);
    }

    // OnChange Language Function

    onUserLanguageChange(ev: MatSelectChange) {
        this.currentLanguage = ev.value;
    }

    refreshTable() {
        this.classroomService.isRefreshValue.emit(true);
    }

    openGroup(gameName: any) {
        if (this.classroom.students.length > 0) {
            this.currentAppGameCode = gameName;
        }
    }

    /**
     * 
     */
    assignTokens(student: Student) {
        AssignTokensDialogComponent.createDialog(this.dialogService, this.dialog, {
            student,
            userAllocations: this.usersSeatAllocation.userAllocs
        }).show();
    }

    playButtonClick(product: Product, event: any) {
        if (this.checklistTab) {
            let checklist = Model.New(ChecklistTrialClassroom, {
                tryProducts: {
                    [product.shortCode]: true
                }
            });
            this.checklistTab.updateChecklist(checklist);
        }

        this.navigationService.navigateToGamePage(product, 'classroom', {
            classroomId: this.classroom.id
        }, event);

    }

    exportStudentsList() {
        const appDialog = ExportStudentsDialogComponent.createDialog(this.dialogService, this.dialog, {
            classroomId: this.classroom.id
        });

        appDialog.show();
    }

    transferSelectedStudents() {
        SelectClassroomDialogComponent.createDialog(this.dialogService, this.dialog, {
            orgId: this.classroom.organization.id,
            classroomIdToExclude: this.classroom.id
        }).show().then((classroom) => {
            if (classroom) {
                const students = this.studentList.selection.selected.map(s => s.id);
                this.classroomService.transferStudents(students, this.classroom.id, classroom.id).subscribe((response) => {
                    this.studentList.removeSelectedItems();
                }, (err) => {
                    this.errorDialogService.openErrorDialog(undefined, err, false);
                })
            }
        });
    }

    onClearSelection() {
        this.studentList.selection.clear();
    }
}

export function ValidateEmailList(control: AbstractControl) {
    const list: string[] = control.value
        ? control.value.split(/\r?\n/).filter((e) => e.trim() != "")
        : [];
    if (list.some((e) => !EMAIL_REGEXP.test(e))) {
        return { emailList: true };
    }
    return null;
}
