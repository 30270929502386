import { Model, ID, Member, ArrayMember, MinLength, MaxLength, ValidateEmail } from '@uon/model';
import { User } from '../user/user.model';

@Model()
export class StudentGameDataTimeline {
    
     @ID()
     id: string;

     @Member()
     gameDataId: string;

     @Member()
     studentId: string;

     @Member()
     gameId: string;

     @Member()
     keys: object;
    
     @Member()
     prop: string;

     @Member()
     value: number;

     @Member()
     date: Date;

     @Member()
     createdOn: Date;

     @Member()
     isDeleted: boolean;

     @Member()
     deletedAt: Date;

     @Member()
     deletedBy: User;
     
}

@Model()
export class StudentGameData {

    @ID()
    id: string;

    @Member()
    studentId: string;

    @Member()
    gameId: string;

    @Member()
    key: string;

    @Member()
    keys: any;

    @Member()
    value: any;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    meta: any;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    deletedBy: User;

    @Member()
    version: number;
    
}

