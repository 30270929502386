import { Directive } from '@angular/core';

@Directive({
    selector: 'design-card-header',
    host: {
        'class': 'design-card-header'
    }
})
export class DesignCardHeaderDirective {

    constructor() { }

}
