import { EventEmitter, Injectable, Output } from '@angular/core';
import { SubscriptionAllocation, SubscriptionAllocationMode, SubAllocationsResponse, SubscriptionSeat, OrgAllocationsResponse, UserAllocationsResponse, RoutesServer, ApiRoutes } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AllocateSeatsEvent } from '../event/events/allocate-seats.event';
import { SubscriptionAllocationModeChangedEvent } from '../event/events/subscription-allocation-mode-changed.event';
import { SiteEventService } from '../event/site-event.service';
import { SiteEvents } from '../event/site-events';
import { ApiDirectoryService } from '../services/api-directory.service';

const SUBSCRIPTION_ALLOCATION_SERIALIZER = new JsonSerializer(SubscriptionAllocation);
const SUB_ALLOCATIONS_RESPONSE_SERIALIZER = new JsonSerializer(SubAllocationsResponse);
const ORG_ALLOCATIONS_RESPONSE_SERIALIZER = new JsonSerializer(OrgAllocationsResponse);
const USER_ALLOCATIONS_RESPONSE_SERIALIZER = new JsonSerializer(UserAllocationsResponse);
const SUBSCRIPTION_SEAT_SERIALIZER = new JsonSerializer(SubscriptionSeat);

@Injectable({
    providedIn: 'root'
})
export class SeatService {

    
    // Emit an event each time the getUserAllocations() retrieve the user allocations.
    @Output() userAllocationsRetrieved: EventEmitter<{userId: string, orgId: string, allocations: UserAllocationsResponse}> = new EventEmitter();
    
    constructor(private eventService: SiteEventService,
        private dirService: ApiDirectoryService) { }

    getUserAllocations(userId: string, orgId: string): Observable<UserAllocationsResponse> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Seat, `/user-allocations`, {userId, orgId}, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    let result = USER_ALLOCATIONS_RESPONSE_SERIALIZER.deserialize(r, true);
                    result.update();

                    this.userAllocationsRetrieved.emit({userId, orgId, allocations: result});
                    return result;
                })
            );
    }

    updateUserAllocations(userAlloc: UserAllocationsResponse): Observable<UserAllocationsResponse> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Seat, `/user-allocations`, {userId: userAlloc.userId, orgId: userAlloc.orgId}, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    let result = USER_ALLOCATIONS_RESPONSE_SERIALIZER.deserialize(r, true);
                    result.update();

                    userAlloc.orgId = result.orgId;
                    userAlloc.userId = result.userId;
                    userAlloc.subscriptions = result.subscriptions;
                    userAlloc.update();

                    return userAlloc;
                })
            );
    }

    getSubscriptionAllocations(subscriptionId: string): Observable<SubAllocationsResponse> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Seat, `/sub-allocations/` + subscriptionId, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    let result = SUB_ALLOCATIONS_RESPONSE_SERIALIZER.deserialize(r, true);
                    result.update();
                    return result;
                }),
                catchError(err => {
                    return of(undefined)
                })
            );
    }

    getOrgSubscriptionAllocations(orgId: string): Observable<OrgAllocationsResponse> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Seat, `/org-sub-allocations/` + orgId, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    let result = ORG_ALLOCATIONS_RESPONSE_SERIALIZER.deserialize(r, true);
                    result.update();
                    return result;
                }),
                catchError(err => { return of(undefined) })
            );
    }

    addAllocation(subscriptionId: string, userId: string): Observable<SubscriptionAllocation> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Seat, `/allocations`, { subId: subscriptionId, filter: { userId } }, { withCredentials: true })
            .pipe(
                map((r: any) => SUBSCRIPTION_ALLOCATION_SERIALIZER.deserialize(r.allocation, true))
            );
    }

    removeAllocation(allocationId: string): Observable<any> {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Seat, `/allocations/` + allocationId, { withCredentials: true })
            .pipe(
                map((r: any) => r)
            );
    }

    transferSeats(fromAlloc: SubscriptionAllocation, toAlloc: SubscriptionAllocation, seats: any): Observable<any> {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Seat, `/allocations`, {
            fromAllocId: fromAlloc?.id,
            toAllocId: toAlloc?.id,
            seats
        }, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    let event: AllocateSeatsEvent = {
                        subscriptionId: fromAlloc ? fromAlloc.subscriptionId : toAlloc.subscriptionId,
                        fromAllocId: fromAlloc?.id,
                        toAllocId: toAlloc?.id,
                        seats
                    };
                    this.eventService.notify(SiteEvents.Organization.Category, SiteEvents.Organization.Events.AllocateSeats, event);

                    return r;
                })
            );
    }

    assignSeatToStudent(allocationId: string, studentId: string, productId: string): Observable<SubscriptionSeat> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Seat, `/allocation-assign-student`, {
            allocationId,
            studentId,
            productId
        }, { withCredentials: true })
            .pipe(
                map((r: any) => SUBSCRIPTION_SEAT_SERIALIZER.deserialize(r) )
            );
    }

    assignSeatToStudents(allocationsIdByProduct:  { [productCode: string]: {studentsId: string[], allocationsId: string[]}}): Observable<SubscriptionSeat[]> {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Seat, `/allocation-assign-students`, {
            allocationsIdByProduct
        }, { withCredentials: true })
            .pipe(
                map((r: any) => r.map(v => SUBSCRIPTION_SEAT_SERIALIZER.deserialize(v)) )
            );
    }

    changeAllocationMode(subscriptionId: string, mode: SubscriptionAllocationMode): Observable<SubscriptionAllocation> {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Seat, `/subscription-allocation-mode/` + subscriptionId, {
            mode
        }, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    let subAlloc = SUBSCRIPTION_ALLOCATION_SERIALIZER.deserialize(r);
                    let event: SubscriptionAllocationModeChangedEvent = {
                        subscriptionId: subAlloc.subscriptionId,
                        allocationMode: subAlloc.mode
                    };
                    this.eventService.notify(SiteEvents.Subscription.Category, SiteEvents.Subscription.Events.AllocationModeChanged, event);

                    return subAlloc;
                }),
                catchError(err => { return of(undefined) })
            );
    }
}
