<app-design-card class="design-card-page-header"
                 [ngClass]="{
                    'show-content': !customLayout && showContent,
                    'hide-content': !customLayout && !showContent
                 }"
                 [bgImageUrl]="bgImageUrl"
                 [clickable]="false">
    <design-card-title>
        <div class="title-container">
            <app-list-count-indicator *ngIf="count != undefined"
                                      [count]="count"></app-list-count-indicator>
            <app-label [type]="titleType"
                       [maxFontSize]="28"
                       [ngStyle]="{'width': getLabelWidth()}">
                <ng-content select="app-page-header-card-title"></ng-content>
            </app-label>
            <ng-content select="app-page-header-card-help"></ng-content>
        </div>
    </design-card-title>
    <design-card-subtitle>
        <span><ng-content select="app-page-header-card-subtitle"></ng-content></span>
    </design-card-subtitle>
    <design-card-content>
        <ng-content select="app-page-header-card-content"></ng-content>
    </design-card-content>
</app-design-card>