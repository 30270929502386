import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { DynamicMaterialColorSelectionDialogComponent } from '../dynamic-material-color-selection-dialog/dynamic-material-color-selection-dialog.component';
import { DynamicMaterialColorService } from '../dynamic-material-color.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-dynamic-material-color-selection-button',
    templateUrl: './dynamic-material-color-selection-button.component.html',
    styleUrl: './dynamic-material-color-selection-button.component.scss'
})
export class DynamicMaterialColorSelectionButtonComponent {

    constructor(
        private dialogService: DialogService,
        private dialog: MatDialog,
        protected dynamicMaterialColorService: DynamicMaterialColorService,
        protected themeService: ThemeService
    ) {

    }

    openDialog() {
        DynamicMaterialColorSelectionDialogComponent.createDialog(this.dialogService, this.dialog, {}, {
            hidePageContent: false,
            dialogConfig: {
                hasBackdrop: false
            }
        }).show();
    }
}
