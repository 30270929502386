import { Injectable } from '@angular/core';
import { ApiRoutes, CommonQueryFilter, CommonQueryResponse, Currency, Product, RoutesServer, SubscriptionPrice, SubscriptionPrices, SubscriptionType, Tax } from '@applogic/model';
import * as moment from 'moment';
import { LanguageService } from './language.service';
import { JsonSerializer, Model } from '@uon/model';
import * as AsyncLock from "async-lock";
import { LocalStorageService } from './local-storage.service';
import { ApiDirectoryService } from './api-directory.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

const PRODUCT_SERIALIZER = new JsonSerializer(Product);
const SUBSCRIPTION_PRICE_SERIALIZER = new JsonSerializer(SubscriptionPrice);
const SUBSCRIPTION_PRICES_SERIALIZER = new JsonSerializer(SubscriptionPrices);
const CURRENCY_SERIALIZER = new JsonSerializer(Currency);

const GAMES: any = {
    mmo: 'Madame Mo: Lecture et Écriture',
    mmo_2019: 'Madame Mo (2019)',
    msm_2019: 'Mots sans maux (2019)',
    msm: 'Mots sans maux',
    mw: 'Madam Word'
}

const DOWNLOAD_LINKS: any = {
    mmo_2019_win: 'https://appligogiques-madamemo2019-release.s3.amazonaws.com/installer/MMO/Windows/MMOSetup.msi',
    mmo_2019_mac: 'https://appligogiques-madamemo2019-release.s3.amazonaws.com/installer/MMO/OSX/MMOSetup.pkg',
    msm_2019_win: 'https://appligogiques-motssansmaux-release.s3.amazonaws.com/installer/MSM/Windows/MSMSetup.msi',
    msm_2019_mac: 'https://appligogiques-motssansmaux-release.s3.amazonaws.com/installer/MSM/OSX/MSMSetup.pkg'
}



@Injectable({
    providedIn: 'root'
})
export class ProductService {

    lock = new AsyncLock();
    products: Product[];
    cachedCurrencies: CommonQueryResponse<Currency>;

    constructor(private languageService: LanguageService,
        private ls: LocalStorageService,
        private dirService: ApiDirectoryService) { }

    async loadProductsOnce() {
        if (this.products) return;

        this.products = await this.getProducts();
    }

    getGameActionFromProp(gameId: string, prop: string) {

        gameId = gameId.toLowerCase();
        if (gameId == "mmo" || gameId == "mw") {
            if (prop.startsWith('Badge')) {
                const badge_num = prop.match(/\d/)[0];
                return $localize`:@@student-activity-badge-string:badge #${badge_num}`;
            }
            else if (prop.startsWith('Cup')) {
                const badge_num = prop.match(/\d/)[0];
                return $localize`:@@student-activity-trophy-string:trophy`;
            }
        }

        return "unknown";
    }

    getRewardIcon(gameId: string, key: string, prop: string) {

        if (gameId == "mmo" || gameId == "mw") {

            const game_num = key.match(/\d/)[0];

            if (prop.startsWith('Badge')) {

                const badge_num = prop.match(/\d/)[0];
                return `assets/mmo/game0${game_num}_badge_0${badge_num}.png`;
            }
            else if (prop.startsWith('Cup')) {

                const badge_num = prop.match(/\d/)[0];
                return `assets/mmo/game0${game_num}_trophee_01.png`;
            }
        }

        return "unknown";
    }


    /**
     * Get product name.
     * 
     * @param {string} productCode Product code (lower case or uppercase work)
     * @param {string} langCode (Optional) Language code
     */
    getGameName(productCode: string, langCode?: string) {
        let code = productCode.toLowerCase();

        if (!langCode) langCode = this.languageService.currentLanguageCode;

        if (this.products) {
            let product = this.products.find((p) => p.shortCode == productCode);

            if (product) {
                let localizedName: any = product.localizedName;
                let result = localizedName[langCode];
                if (result) {
                    return result;
                }
            }
        }

        if (code == "mmo") {
            if (langCode == "fr") {
                return "Madame Mo: Lecture et Écriture";
            }
            else if (langCode == "en") {
                return "Madam Word: Reading & Writing";
            }
            else if (langCode == "es") {
                return "Señora Palabra: Leer y Escribir";
            }
        }
        else if (code == "msm") {
            if (langCode == "fr") {
                return "Mots Sans Maux";
            }
            else if (langCode == "en") {
                return "Words Without Worries";
            }
            else if (langCode == "es") {
                return "Palabras Sin Problemas";
            }
        }

        return GAMES[code];
    }

    getProductCodes() {
        return ["mmo", "msm"];
    }

    async getGameNameAsync(gameId: string, langCode?: string) {
        this.ensureProducts();

        if (!langCode) {
            langCode = this.languageService.currentLanguageCode;
        }

        await this.loadProductsOnce();

        gameId = gameId.toUpperCase();

        return this.getGameName(gameId, langCode);
    }

    getGameIcon(gameId: string) {
        this.ensureProducts();

        let product = this.products.find((p) => p.shortCode == gameId);

        if (product) {
            return product.imageUrl;
        }

        // For legacy products
        return `assets/products/${gameId.toLowerCase()}.png`;
    }

    getDownloadLink(code: string, platform: string) {

        const key = code + '_' + platform;

        return DOWNLOAD_LINKS[key.toLowerCase()];
    }

    /**
     * Get the product taxes
     * 
     * @param data 
     */
    getProductTaxes(data: any, context: string): Promise<Tax[]> {
        // Clone the data before modifying it.
        const postData = JSON.parse(JSON.stringify(data));
        postData.context = context;
        return new Promise((resolve, rejects) => {
            this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Product, `/taxes`, postData, { withCredentials: true }).subscribe((res: any) => {
                let taxes: Tax[] = [];

                for (let i = 0; i < res.length; i++) {
                    let tax: Tax = Model.New(Tax, res[i]);
                    taxes.push(tax);
                }

                resolve(taxes);

            }, (error) => {

                rejects(error);

            });
        });
    }

    getProductSync(code: string) {
        if (this.products) {
            let product = this.products.find(p => p.shortCode == code);

            if(!product) {
                code = code.toUpperCase();
                product = this.products.find(p => p.shortCode == code);
            }

            return product;
        }
    }

    async getProduct(code: string) {
        if (!this.products) {
            await this.getProducts();
        }

        if (this.products) {
            let product = this.products.find(p => p.shortCode == code);

            if(!product) {
                code = code.toUpperCase();
                product = this.products.find(p => p.shortCode == code);
            }

            return product;
        }
    }

    /**
     * Get the dictionary of products.
     */
    async getProductsDict(): Promise<{ [key: string]: Product }> {
        await this.getProducts();

        let productsDict: { [key: string]: Product } = {};

        this.products.forEach((p) => {
            productsDict[p.shortCode] = p;
        });

        return productsDict;
    }

    /**
     * Get the list of products.
     */
    getProducts(forceResolve: boolean = false): Promise<Product[]> {

        return new Promise((resolve, rejects) => {

            // Make sure currencies are cached first.
            this.getCurrencies().subscribe((res) => {

                if (!forceResolve && this.products) {
                    resolve(this.products);
                    return;
                }
    
                let lastProductsList = this.ls.getSite('APPLOGIC_PRODUCTS_LIST');
                if (!forceResolve && lastProductsList) {
                    try {
                        let products = lastProductsList.map((r) => PRODUCT_SERIALIZER.deserialize(r));
                        if (products) {
                            this.products = products;
                            this.updateProductsToCurrent();
                            resolve(this.products);
                            return;
                        }
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
    
                this.lock.acquire("product_service_get_products", async (done) => {
                    if (!forceResolve && this.products) {
                        done();
                        resolve(this.products);
                    }
                    else {
                        this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Product, ``, { withCredentials: true }).subscribe((res: any[]) => {
    
                            let result = res.map((r) => PRODUCT_SERIALIZER.deserialize(r));
                            this.products = result;
    
                            this.updateProductsToCurrent();
    
                            this.ls.setSite('APPLOGIC_PRODUCTS_LIST', res);
    
                            done();
                            resolve(result);
    
                        }, (error) => {
                            done();
                            rejects(error);
    
                        })
                    }
                });

            }, (err) => {
                rejects(err);
            });
        });
    }

    /**
     * Get subscription prices.
    */
    getSubscriptionPrices(type: SubscriptionType, currency: string): Promise<SubscriptionPrice[]> {
        return new Promise((resolve, rejects) => {
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Product, `/price?type=${type}&currency=${currency}`, { withCredentials: true }).subscribe((res: any) => {
                let subscriptions: SubscriptionPrice[] = [];

                for (let i = 0; i < res.length; i++) {
                    let price: SubscriptionPrice = new SubscriptionPrice();
                    Object.assign(price, res[i]);
                    subscriptions.push(price);
                }

                resolve(subscriptions);
            }, (error) => {
                rejects(error);
            })
        });
    }

    /**
     * Get all subscription prices for the administractor.
    */
    getAllSubscriptionPrices(filter: CommonQueryFilter) {
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Product, `/prices` + str, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<SubscriptionPrice>();
                response.count = r.count;
                response.result = r.result.map(r => SUBSCRIPTION_PRICE_SERIALIZER.deserialize(r, true));
                return response;
            })
        );
    }

    /**
         * Get all subscription prices for the administractor.
        */
    getAllSubscriptionPricesGrouped(filter: CommonQueryFilter) {
        filter.other.grouped = true;
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Product, `/prices` + str, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<SubscriptionPrices>();
                response.count = r.count;
                response.result = r.result.map(r => SUBSCRIPTION_PRICES_SERIALIZER.deserialize(r, true));
                return response;
            })
        );
    }

    checkSubscriptionExpire(expireDate: Date) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }

    updateProductsToCurrent() {
        this.products.forEach((p) => {
            const langCode = this.languageService.currentLanguageCode;
            p.currentLocalizedName = p.localizedName[langCode];

            // ex.: "Madame Mo: Lecture et Écriture" become "Madame Mo".
            p.currentLocalizedShortName = p.currentLocalizedName;
            const idx = p.currentLocalizedShortName.indexOf(":");
            if (idx != -1) {
                p.currentLocalizedShortName = p.currentLocalizedShortName.substring(0, idx).trim();
            }
        });
    }

    /**
     * Load products if necessary.
     */
    ensureProducts() {
        if (!this.products) {
            this.getProducts(); // May load immediately if load from cache.
            if (!this.products) {
                console.warn("Must preload the products first.")
                return "";
            }
        }
    }

    /**
         * Get all currencies.
        */
    getCurrencies(filter: CommonQueryFilter = undefined, onlyEnabledCurrency: boolean = true) {

        const useCache: boolean = (filter == undefined) && onlyEnabledCurrency;

        if( useCache && this.cachedCurrencies) {
            return of(this.cachedCurrencies);
        }

        if (!onlyEnabledCurrency) {
            if (!filter) {
                filter = new CommonQueryFilter({});
            }
            filter.other.all = true;
        }

        const str = filter ? filter.toString2() : "";


        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Product, `/currencies` + str, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<Currency>();
                response.count = r.count;
                response.result = r.result.map(r => CURRENCY_SERIALIZER.deserialize(r, true));

                if(useCache) {
                    this.cachedCurrencies = response;
                }

                return response;
            })
        );
    }

    getCurrency(currencyCode: string) {
        return new Promise<Currency>((resolve, rejects) => {
            this.getCurrencies().subscribe({
                next: (currencies) => {
                    const currency = currencies.result.find(c => c.code == currencyCode);
                    resolve(currency);
                },
                error: (err) => {
                    rejects(err);
                }
            });
        });
    }

    getCurrencyFromCache(currencyCode: string) {
        if(!this.cachedCurrencies) return;
        const currency = this.cachedCurrencies.result.find(a => a.code == currencyCode);
        if(!currency) return;

        return currency;
    }

    /**
     * Get all Stripe prices. (Must be admin)
     */
    getStripePrices(filter: CommonQueryFilter, onlyEnabledCurrency: boolean = true) {
        const str = filter ? filter.toString2() : "";

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Stripe, `/pricesList` + str, { withCredentials: true }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<any>();
                response.count = r.count;
                response.result = r.result;
                return response;
            })
        );
    }
}
