import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency, MultiCounters, PromoCodeTicket, SelectedProduct, SubscriptionPlan, SubscriptionPlanUtils, SubscriptionPrice, SubscriptionType } from '@applogic/model';
import { AngularUtils } from '../../services/angular-utils';
import { ProductService } from '../../services/product.service';
import { PromoCodeService } from '../../services/promo-code.service';


class SubscriptionPlanCard {
    label: string;
    type: SubscriptionPlan;
    price?: number;
    pricePerMonth?: number;
    nbMonths?: number;
    promoCodeTicket?: PromoCodeTicket;
}

@Component({
    selector: 'app-products-plan-card-select',
    templateUrl: './products-plan-card-select.component.html',
    styleUrls: ['../products-common-card-select.component.scss', './products-plan-card-select.component.scss']
})
export class ProductsPlanCardSelectComponent implements OnInit {

    _quantity: MultiCounters;

    @Input()
    set quantity(val: MultiCounters) {
        if (this._quantity != val) {
            this.quantityChange.emit(val);
            this._quantity = val;
        }

        if(val) {
            this.updateQuantity();
            this.updatePrices();
        }
    }
    get quantity() {
        return this._quantity;
    }


    @Output()
    quantityChange: EventEmitter<MultiCounters> = new EventEmitter<MultiCounters>();


    _plan: SubscriptionPlan;

    @Input()
    set plan(val: SubscriptionPlan) {
        if (this._plan != val) {
            this.planChange.emit(val);
            this._plan = val;
        }
    }
    get plan() {
        return this._plan;
    }

    @Output()
    planChange: EventEmitter<SubscriptionPlan> = new EventEmitter<SubscriptionPlan>();


    _currency: Currency;

    @Input()
    set currency(val: Currency) {
        if (this._currency != val) {
            this.currencyChange.emit(val);
            this._currency = val;
            this.updatePrices();
        }
    }
    get currency() {
        return this._currency;
    }

    @Output()
    currencyChange: EventEmitter<Currency> = new EventEmitter<Currency>();

    subscriptionType: SubscriptionType;
    subscriptionPlans: SubscriptionPlanCard[] = [];

    private prices: SubscriptionPrice[] = [];

    @Input()
    promoCode: string;

    private _promoCodeTicket: PromoCodeTicket;

    @Input()
    set promoCodeTicket(val: PromoCodeTicket) {
        if (this._promoCodeTicket != val) {
            this.promoCodeTicketChange.emit(val);
            this._promoCodeTicket = val;
        }
    }
    get promoCodeTicket() {
        return this._promoCodeTicket;
    }

    @Output()
    promoCodeTicketChange: EventEmitter<PromoCodeTicket> = new EventEmitter<PromoCodeTicket>();
    

    constructor(
        private productService: ProductService,
        private promoCodeService: PromoCodeService) { }

    ngOnInit(): void {
    }

    setSubscriptionType(type: SubscriptionType) {
        this.subscriptionType = type;
        this.plan = undefined;

        this.subscriptionPlans = [];
        if (type == SubscriptionType.individual) {
            this.subscriptionPlans.push({
                label: "",
                type: SubscriptionPlan.monthly
            });
        }
        else {
            this.subscriptionPlans.push({
                label: "",
                type: SubscriptionPlan.quarterly
            });

            this.subscriptionPlans.push({
                label: "",
                type: SubscriptionPlan.halfYearly
            });
        }

        this.subscriptionPlans.push({
            label: "",
            type: SubscriptionPlan.yearly
        });

        for(const sp of this.subscriptionPlans) {
            sp.label = AngularUtils.getSubscriptionLabel(sp.type);
            sp.nbMonths = SubscriptionPlanUtils.getMonths(sp.type);
        }

        this.updatePrices();
    }


    setSubscriptionPlan(plan: SubscriptionPlan) {
        this.plan = plan;

        for(const p of this.subscriptionPlans) {
            if(p.type == plan) {
                this.promoCodeTicket = p.promoCodeTicket;
            }
        }
    }

    updatePrices() {
        if(!this.currency) return;
        if(!this.subscriptionType) return;

        this.productService.getSubscriptionPrices(this.subscriptionType, this.currency.code).then((subscriptionPrices: SubscriptionPrice[]) => {
            if(this.promoCode) {
                this.promoCodeService.applyPromoCode(this.promoCode, {
                    type: this.subscriptionType
                }).then((res: PromoCodeTicket) => {

                    for(const price of subscriptionPrices) {
                        if(res.constraints?.plan && (res.constraints.plan.indexOf(price.plan) == -1)) {
                            continue;
                        }
                        
                        price.amount.value *= (100 - res.discount) / 100.0;
                    }

                    for(const plan of this.subscriptionPlans) {
                        if(res?.constraints?.plan) {
                            const plans = res.constraints.plan;
                            if(plans.indexOf(plan.type) != -1) {
                                plan.promoCodeTicket = res
                            }
                        }
                        else {
                            plan.promoCodeTicket = res;
                        }

                        if(plan.type == this.plan) {
                            this.promoCodeTicket = res;
                        }
                    }
                }).catch((error) => {
                    this.promoCodeTicket = undefined;
                    for(const plan of this.subscriptionPlans) {
                        plan.promoCodeTicket = undefined;
                    }
                    console.error(error);
                }).finally(() => {
                    this.prices = subscriptionPrices;
                    this.updateQuantity();    
                });
            }
            else {
                this.promoCodeTicket = undefined;
                this.prices = subscriptionPrices;
                this.updateQuantity();
            }
        });
    }

    updateQuantity() {

        for(const plan of this.subscriptionPlans) {
            const months = SubscriptionPlanUtils.getMonths(plan.type);
            plan.price = 0;
            plan.pricePerMonth = 0;
        }

        if(!this._quantity) return;

        for(const key of Object.keys(this.quantity)) {
            const q = this.quantity[key];

            if(q > 0) {
                for(const plan of this.subscriptionPlans) {
                    const price = this.prices.find(a => (a.productCode == key) && (a.plan == plan.type));

                    plan.price += q * price.amount.value;
                    plan.pricePerMonth += q * price.amount.value / plan.nbMonths;
                }
                
            }
        }
    }
}
