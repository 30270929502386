import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";


export class SharedList<T> {

    private key: string;

    public count: number;
    private list: T[];
    private subject: BehaviorSubject<T[]>;

    public subscribers: Subscription[];

    public constructor() {
        this.count = 1;
        this.list = [];
        this.subject = new BehaviorSubject<T[]>(undefined);
    }

    public setList(list: T[]) {
        this.list = list;
        this.subject.next(this.list);
    }

    public getObserver() {
        return this.subject.pipe(filter(a => a != undefined));
    }
}