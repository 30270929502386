
import { Model, ID, Member, ArrayMember } from '@uon/model';
import { User } from '../user/user.model';

@Model()
export class LegacyPlayerProfile {

    @ID()
    id: string;

    @Member()
    name: string;

    @Member()
    grade: string;

    @Member()
    avatar: object;

    @ArrayMember(Object)
    preferences: { name: string, value: any }[];

    @Member()
    lastUsedOn: Date;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    owner: User;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    constructor(_id?: string) {
        this.id = _id;
    }
}



