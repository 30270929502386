import { Component, Injector } from '@angular/core';
import { CommonQueryFilter, OrganizationRole, Product, RenewOption, Subscription, SubscriptionGrant, SubscriptionType } from '@applogic/model';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListColumnOptions } from 'src/app/core/common-list/common-list-column-options';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { ProductService } from 'src/app/services/product.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SubscriptionCancelDialogComponent } from '../subscription-cancel-dialog/subscription-cancel-dialog.component';
import { PaymentMethodSelectDialogComponent } from 'src/app/order-ui/payment-method-select-dialog/payment-method-select-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import * as moment from 'moment';
import { AccountService } from '../../account.service';
import { Router } from '@angular/router';
import { Model } from '@uon/model';


@Component({
    selector: 'app-subscription-list',
    templateUrl: './subscription-list.component.html',
    styleUrl: './subscription-list.component.scss'
})
export class SubscriptionListComponent extends CommonListComponent<Subscription> {

    ApplogicUtils = ApplogicUtils;

    products: Product[];

    constructor(
        private injector: Injector,
        protected productService: ProductService,
        private subscriptionService: SubscriptionService,
        private authService: AuthService,
        private accountService: AccountService,
        private router: Router) {
        super(injector);

        // Set default values
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 25, 50];
        
        this.rendererOptions.showSelectColumnsTable = true;
        this.rendererOptions.showSearchBar = false;
        this.rendererOptions.showDisplayMode = false;
        this.supportCompactMode = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.productService.getProducts().then((res) => {
            this.products = res;

            this.initColumns();

            this.refreshItems();
        });
    }

    initColumns() {
        if(this.compactRow) {
            this.initPersistentKey('');
        }
        else {
            this.initPersistentKey('applogic/subscriptions');
        }

        const isAdmin: boolean = false;

        this.addColumn($localize`:@@common-id:ID`, "id", true, false, CommonColumnType.ID);

        this.addColumn("Type", "type", true, !this.compactRow, CommonColumnType.Custom, {
            fixedWidth: "100px"
        });
        
        this.addColumn($localize`:@@common-number:No`, "subNo", true, true, CommonColumnType.NO);

        this.addColumn(this.compactRow ? "" : $localize`:@@common-products:Products`, "products", true, true, CommonColumnType.Template, {
            ...this.compactRow && {
                fixedWidth: "25px"
            },
            templateCellIdx: 1
        });

        this.addColumn($localize`:@@common-tokens:Tokens`, "tokens", true, false, CommonColumnType.Template, {
            templateCellIdx: 2
        });

        this.addColumn($localize`:@@common-tokens-left:Tokens left`, "tokens", true, true, CommonColumnType.Template, {
            fixedWidth: "70px",
            templateCellIdx: 3
        });

        this.addColumn($localize`:@@common-organization-id:Organization ID`, "organization.id", true, false, CommonColumnType.ID);
        this.addColumn($localize`:@@common-org-name-label:Organizations Name`, "organization.name", true, isAdmin);
        // this.addColumn("Org Admin Emails", "orgAdminEmails", true, false, CommonColumnType.Custom);
        // this.addColumn("Org Admin Emails (lines)", "orgAdminEmailsWithLines", true, false, CommonColumnType.Custom);
        
        
        this.addColumn($localize`:@@common-belongs-to: Belongs to `, "owner.fullName", true, false);
        this.addColumn($localize`:@@common-belongs-to-email: Belongs to (Email)`, "owner.email", true, false);

        this.addColumn($localize`:@@common-created-by: Created by `, "createdBy.id", true, false);

        this.addColumn($localize`:@@common-user:User`, "user.fullName", true, isAdmin);
        this.addColumn($localize`:@@common-email: Email `, "user.email", true, false);

        this.addColumn("Plan", "plan", true, isAdmin);

        const commonOptions: CommonListColumnOptions = {
            fixedWidth: "40px",
            cellStyles: {
                'text-align': 'center'
            }
        };

        // for (let i = 0; i < this.productsList.length; i++) {
        //     const product = this.productsList[i];
        //     this.addColumn("Used " + product.shortCode, "_usedSeats." + product.shortCode.toLowerCase(), true, false, CommonColumnType.Text, commonOptions);
        //     this.addColumn("Free " + product.shortCode, "_freeSeats." + product.shortCode.toLowerCase(), true, false, CommonColumnType.Text, commonOptions);

        //     this.addColumn("Total " + product.shortCode, "_totalSeats." + product.shortCode.toLowerCase(), true, false, CommonColumnType.Text, commonOptions);
        // }

        // this.addColumn("Total Free", "_freeSeats.total", true, true, CommonColumnType.Text, commonOptions);
        // this.addColumn("Total Used", "_usedSeats.total", true, true, CommonColumnType.Text, commonOptions);
        // this.addColumn("Total Seats", "_totalSeats.total", true, false, CommonColumnType.Text, commonOptions);

        // this.addColumn("Seats Usage (φ)", "_seatsUsageRatio", true, true, CommonColumnType.Template, {
        //     fixedWidth: "55px",
        //     cellStyles: {
        //         'text-align': 'center',
        //         'display': 'grid'
        //     }
        // });

        this.addColumn($localize`:@@common-created-on:Created On`, "createdOn", true, isAdmin, CommonColumnType.Date).parameters.format = "mediumDate";
        this.addColumn($localize`:@@common-updated-on:Updated On`, "updatedOn", true, false, CommonColumnType.Date).parameters.format = "mediumDate";
        this.addColumn($localize`:@@common-expires-on:Expires On`, "expiresOn", true, false, CommonColumnType.Date).parameters.format = "mediumDate";

        this.addColumn($localize`:@@common-auto-renew-on:Auto-Renew on`, "renewOption", true, true, CommonColumnType.Template, {
            templateCellIdx: 4
        });


        this.addColumn($localize`:@@common-status:Status`, "status", true, !this.compactRow, CommonColumnType.Template, {
            fixedWidth: "60px"
        });

        this.addColumn("", "actions", true, true, CommonColumnType.Action);

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });

        queryFilter.setFields({
            seats: true
        });

        this.subscriptionService.searchSubscriptions(queryFilter).subscribe((res) => {
            this.setResponse(res);
        });
    }

    onCompactModeChanged() {
        if(this.products) {
            this.initColumns();
        }
    }

    getCustomLabel(key: string, val: any, row: Subscription) {
        if(key == "type") {
            return ApplogicUtils.getSubscriptionTypeLabel(row.type);
        }

        return "";
    }


    canCancelSubscription(subscription: Subscription): boolean {
        if (!subscription) return false;
        return this.checkSubscriptionExpire(subscription.expiresOn) == false && (subscription.renewOption != RenewOption.DoNothing) && (subscription.renewOption != undefined);
    }

    checkSubscriptionExpire(expireDate: any) {
        let currentDate = moment();
        let expireDateConst = moment(expireDate);
        if (expireDateConst <= currentDate) {
            return true
        } else {
            return false
        }
    }

    isOwner(subscription: Subscription): boolean {
        if (!subscription) return false;
        
        return this.authService.user?.id == subscription.owner?.id;
    }

    /**
     * open confirmation dialog when 
     * click on cancel subscription
     * @param sub 
     */
    cancelConfirmationDialog(sub: Subscription) {
        SubscriptionCancelDialogComponent.createDialog(this.dialogService, this.dialog, {
            subscription: sub
        }).show().then(() => {
            this.subscriptionService.getSubscription(sub.id).subscribe((updatedSub) => {
                Model.Assign(sub, updatedSub);
            });
        });
    }

    updatePaymentMethod(sub: Subscription) {
        PaymentMethodSelectDialogComponent.createDialog(this.dialogService, this.dialog, {
            paymentTarget: sub
        }).show();
    }

    canChangeAllocationMode(sub: Subscription) {
        if((sub.type == SubscriptionType.organization) && this.accountService.getSelfRole(sub.organization) != OrganizationRole.Administrator) {
            if(sub.owner.id == this.authService.user?.id) {
                return true;
            }
        }
        else {
            return true
        }
    }

    canShowAction(row: Subscription) {
        if(!row.expiresOn) {
            return false;
        }

        return (new Date()).getTime() < row.expiresOn.getTime();
    }

    purchaseSubscription() {
        ApplogicUtils.navigateToSubscriptionPage(this.router, this.dataSource.data?.[0]);
    }

    getTokensUsage(sub: Subscription, grant: SubscriptionGrant) {
        const productCode = grant.productShortCode.toLowerCase();
        const totalSeats = sub._totalSeats[productCode];

        // TODO [AEP6-572] Individual doesn't have tokens allocation so simply display the total tokens.
        if(sub.type == SubscriptionType.individual) {
            return totalSeats.toString();
        }

        const freeSeats = sub._freeSeats[productCode];

        if(freeSeats != undefined) {
            return freeSeats + " / " + totalSeats;
        }

        if(totalSeats != undefined) {
            return totalSeats.toString();
        }
        
        return "";
    }
}
