import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { PageComponent } from './page/page.component';
import { PageContentDirective } from './page/directives/page-content.directive';
import { PageHeaderCardComponent } from './page-header-card/page-header-card.component';
import { PageHeaderCardTitleDirective } from './page-header-card/directives/page-header-card-title.directive';
import { PageHeaderCardSubtitleDirective } from './page-header-card/directives/page-header-card-subtitle.directive';
import { PageHeaderCardHelpDirective } from './page-header-card/directives/page-header-card-help.directive';
import { PageHeaderCardContentDirective } from './page-header-card/directives/page-header-card-content.directive';
import { SuccessScreenComponent } from './success-screen/success-screen.component';
import { PageContentComponent } from './page/components/page-content/page-content.component';
import { HintsViewerComponent } from './hints-viewer/hints-viewer.component';
import { HintContentComponent } from './hints-viewer/hint-content/hint-content.component';
import { PageHeader2CardComponent } from './page-header2-card/page-header2-card.component';
import { SectionTitleHeaderComponent } from './section-title-header/section-title-header.component';
import { PageHeaderCardExtendedTitleComponent } from './page-header-card-extended-title/page-header-card-extended-title.component';
import { DefaultPageHeaderCardDirective } from './page-header-card/directives/default-page-header-card.directive';
import { MaterialDesignModule } from '../material-design/material-design.module';


const DASHBOARD_UI_EXPORTS = [
    DefaultPageHeaderCardDirective,
    HintContentComponent,
    HintsViewerComponent,
    PageContentDirective,
    PageComponent,
    PageHeaderCardComponent,
    PageHeader2CardComponent,
    PageContentComponent,
    PageHeaderCardContentDirective,
    PageHeaderCardExtendedTitleComponent,
    PageHeaderCardHelpDirective,
    PageHeaderCardSubtitleDirective,
    PageHeaderCardTitleDirective,
    SectionTitleHeaderComponent,
    SuccessScreenComponent,
];

@NgModule({
    declarations: [
        ...DASHBOARD_UI_EXPORTS,
    ],
    imports: [
        CommonModule,
        CoreModule,
        AuthUiModule,
        MaterialDesignModule,
    ],
    exports: [
        ...DASHBOARD_UI_EXPORTS,
    ]
})
export class DashboardUiModule { }
