import { Model, ID, Member} from '@uon/model';
import { User } from './user.model';

@Model()
export class SearchList {

    @ID()
    id: string;

    @Member()
    user: User;

    @Member()
    searchlist: string;

    @Member()
    name: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;
    
    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt:Date;

    /**
     * 
     * @param _id 
     */
    constructor(_id?: string) {
        this.id = _id;
    }

}