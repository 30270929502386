import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodSelectDialogComponent } from './payment-method-select-dialog/payment-method-select-dialog.component';
import { PaymentMethodSelectPanelComponent } from './payment-method-select-panel/payment-method-select-panel.component';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StripePaymentDialogComponent } from './stripe-payment-dialog/stripe-payment-dialog.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { StripePaymentPanelComponent } from './stripe-payment-panel/stripe-payment-panel.component';
import { StripeLogoComponent } from './stripe-logo/stripe-logo.component';
import { OrganizationBillingFormComponent } from './organization-billing-form/organization-billing-form.component';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { OrganizationUiModule } from '../organization-ui/organization-ui.module';
import { AddressUiModule } from '../address-ui/address-ui.module';
import { OrderSuccessScreenComponent } from './order-success-screen/order-success-screen.component';
import { ProductModule } from '../product/product.module';
import { MatListModule } from '@angular/material/list';
import { PromoCodeInputComponent } from './promo-code-input/promo-code-input.component';
import { IndividualBillingFormComponent } from './individual-billing-form/individual-billing-form.component';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
    declarations: [
        IndividualBillingFormComponent,
        OrderSuccessScreenComponent,
        OrganizationBillingFormComponent,
        PromoCodeInputComponent,
        StripeLogoComponent,
        StripePaymentDialogComponent,
        StripePaymentPanelComponent,
        PaymentMethodSelectDialogComponent,
        PaymentMethodSelectPanelComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        CoreModule,
        NgxStripeModule.forRoot(environment.stripeApiKey),
        AuthUiModule,
        OrganizationUiModule,
        AddressUiModule,
        ProductModule,
        MatListModule,
        DashboardUiModule,
    ],
    exports: [
        IndividualBillingFormComponent,
        OrderSuccessScreenComponent,
        OrganizationBillingFormComponent,
        PromoCodeInputComponent,
        StripeLogoComponent,
        StripePaymentDialogComponent,
        StripePaymentPanelComponent,
        PaymentMethodSelectDialogComponent,
        PaymentMethodSelectPanelComponent
    ]
})
export class OrderUiModule { }
