import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { User } from '@applogic/model';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {


    @Input() user: User;
    @Input() type: string;
    @Input() size: number = 32;
    @Input() empty: boolean = false;

    constructor(private auth: AuthService) { }

    ngOnInit(): void {

        if (!this.user) {
            this.user = this.auth.user;
        }
    }


    getContainerStyle() {

        const style: any = {
            width: `${this.size}px`,
            height: `${this.size}px`,
            flexBasis: `${this.size}px`,
            fontSize: `${Math.floor(this.size / 2) - 5}px`

        };

        if (this.user) {
            Object.assign(style, ApplogicUtils.getStylesFromString(this.empty ? "" : this.user?.id));
        }

        if(this.empty) {
            style.border = "1px solid black";
        }

        return style;
    }

    getInitials() {
        if (this.empty) return "";

        if (this.user.fullName === undefined) {
            return ApplogicUtils.createInitials(this.user.email);
        } else {
            return ApplogicUtils.createInitials(this.user.fullName);
        }
    }

}
