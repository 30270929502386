
import { Member, Model, Required } from "@uon/model";
import { UserServiceType } from "../user/service-type.model";
import { InvitationCodeQuery } from "./auth.model";
import { FieldValidationType, FieldValidations } from "../common/field.validations";
import { UserEmailPreferences } from "../user/user-email-preferences.model";


@Model()
export class RegisterUserForm implements InvitationCodeQuery {

    @Member({
        validators: FieldValidations[FieldValidationType.Email].getValidators(true)
    })
    email: string;

    @Member({
        validators: FieldValidations[FieldValidationType.FullName].getValidators(true)
    })
    fullName: string;

    @Member({
        validators: FieldValidations[FieldValidationType.Password].getValidators(true)
    })
    password: string;

    @Member()
    inviteCode: string;

    @Member()
    context: any;

    @Member()
    emailPreferences: UserEmailPreferences;

    @Member()
    lang: string;

    @Member()
    source: string;

}

@Model()
export class RegisterSSOQuery implements InvitationCodeQuery {


    @Member({
        validators: [
            Required()
        ]
    })
    token: any;

    @Member({
        validators: [
            Required()
        ]
    })
    serviceType: UserServiceType;

    @Member()
    inviteCode: string;

    @Member()
    context: any;

    @Member()
    emailPreferences: UserEmailPreferences;

    @Member()
    lang: string;

    @Member()
    source: string;
}
