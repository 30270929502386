import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, SubscriptionPlan, SubscriptionPlanUtils } from '@applogic/model';
import { Model } from '@uon/model';
import { Subscription as RxSubscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularUtils } from 'src/app/services/angular-utils';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { SubscriptionService } from 'src/app/services/subscription.service';


enum State {
    None = "none",
    SubscribeNow = "subscribe-now",
    UpgradeNow = "upgrade-now"
}

@Component({
    selector: 'app-upgrade-now-section',
    templateUrl: './upgrade-now-section.component.html',
    styleUrl: './upgrade-now-section.component.scss'
})
export class UpgradeNowSectionComponent {
    State = State;

    protected subscription: Subscription;
    protected isTrial: boolean;
    protected trialPercent: number = 0;
    protected activeSubscription: boolean;

    protected lastSubscription: Subscription;

    protected onUserChangeSubscription: RxSubscription;

    protected static instance: UpgradeNowSectionComponent;

    protected state: State = State.None;

    constructor(
        private authService: AuthService,
        private subscriptionService: SubscriptionService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.route.queryParams.subscribe((res) => {
            this.refreshSubscription();
        });

        this.onUserChangeSubscription = this.authService.onUserChange.subscribe((user) => {
            this.refreshSubscription();
        });
    }

    ngOnInit() {
        if(this.onUserChangeSubscription) {
            this.onUserChangeSubscription.unsubscribe();
        }

        UpgradeNowSectionComponent.instance = this;
    }

    ngOnDestroy() {
        UpgradeNowSectionComponent.instance = undefined;
    }

    refreshSubscription() {
        const subId = this.route.snapshot.queryParamMap.get("debugSubId");
        
        if(subId) {
            this.subscriptionService.getSubscription(subId).subscribe({
                next: (response) => {
                    this.setSubscription(response.subscription);
                },
                error: (ex) => {
                    console.error(ex);
                }
            });
        }
        else {
            this.subscriptionService.getUserLastSubscription().subscribe({
                next: (response) => {
                    this.setSubscription(response.subscription);
                },
                error: (ex) => {
                    console.error(ex);
                }
            });
        }
    }

    setSubscription(subscription: Subscription) {

        this.isTrial = false;
        this.lastSubscription = subscription;
        this.activeSubscription = false;

        if(AngularUtils.isTestMode()) {
            const test: string = 'trial';

            if(test == 'trial') {
                const expiresOn = new Date();
                const createdOn = new Date();
                
                expiresOn.setHours(expiresOn.getHours() + 32);
                createdOn.setDate(createdOn.getDate() - 14);

                subscription = Model.New(Subscription, {
                    createdOn,
                    expiresOn,
                    plan: SubscriptionPlan.trialClassroom
                });
            }
            else if(test == 'no-subscription') {
                subscription = undefined;
            }
            else if(test == 'active-subscription') {
                const expiresOn = new Date();
                const createdOn = new Date();
                
                expiresOn.setHours(expiresOn.getHours() + 32);
                createdOn.setDate(createdOn.getDate() - 14);

                subscription = Model.New(Subscription, {
                    createdOn,
                    expiresOn,
                    plan: SubscriptionPlan.monthly
                });
            }
            else if(test == 'expired-subscription') {
                const expiresOn = new Date();
                const createdOn = new Date();
                
                expiresOn.setHours(expiresOn.getHours() - 1);
                createdOn.setDate(createdOn.getDate() - 14);

                subscription = Model.New(Subscription, {
                    createdOn,
                    expiresOn,
                    plan: SubscriptionPlan.monthly
                });
            }
        }

        
        if(subscription) {
            if(SubscriptionPlanUtils.isTrial(subscription.plan)) {
                this.state = State.UpgradeNow;
            }
            else if(subscription.expiresOn.getTime() < (new Date()).getTime()) {

                this.activeSubscription = true;
                this.state = State.UpgradeNow;
            }
            else {
                this.state = State.None;
            }
        }
        else {
            this.state = State.SubscribeNow;
        }

        console.log("State: " + this.state);
        
        this.subscription = subscription;
        
        if(subscription) {
            this.isTrial = SubscriptionPlanUtils.isTrial(subscription.plan);
            this.trialPercent = 100 * ((new Date()).getTime() - subscription.createdOn.getTime()) / (subscription.expiresOn.getTime() - subscription.createdOn.getTime());
        }
    }

    upgradeNow() {
        ApplogicUtils.navigateToSubscriptionPage(this.router, this.lastSubscription);
    }

    static globalUpgradeNow(router: Router) {
        if(UpgradeNowSectionComponent.instance) {
            UpgradeNowSectionComponent.instance.upgradeNow();
        }
        else {
            ApplogicUtils.navigateToSubscriptionPage(router);
        }
    }
}
