
import { Model, ID, Member, NumberMember, ArrayMember, MaxLength } from '@uon/model';

export enum SuggestionState {
    Unreaded = 0,
    Archived = 1,
    Deleted = 2,
    ToFollow = 3
};

@Model()
export class Suggestion {

    @ID()
    id: string;

    @Member({
        validators: [MaxLength(100)]
    })
    subject: string;

    @Member({
        validators: [MaxLength(1500)]
    })
    message: string;

    @Member()
    requireResponse: boolean;

    @Member()
    productCode: string;

    @Member()
    productVersion: string;

    @Member()
    productType: string;

    @Member()
    platform: string;

    @Member()
    deviceModel: string;

    @Member()
    deviceOS: string;

    @Member()
    deviceId: string;

    @Member()
    installId: string;

    @Member()
    syslang: string;

    @Member()
    lang: string;

    @Member()
    userId: string;

    @Member()
    createdOn: Date;

    @Member()
    reportedOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    state: SuggestionState;
}
