<div class="toollip-data">
    <p> <span>{{_chip.name }}</span></p>
    <p *ngIf="!_chip.isPlay" i18n="@@game-progress-tooltip-incomplete">Incomplete</p>
    <p *ngIf="_chip.isPlay" i18n="@@game-progress-tooltip-completed">Completed</p>
    <p *ngIf="_chip.isPlay"> <span i18n="@@game-progress-tooltip-attemps">Attempts</span> : {{_chip?.attempts}}</p>
    <div class="sublevelList"
         *ngIf="(_chip.levels.length > 1) && ( !_chip.isPlay || (context == 'date') )">
        <div>
            <span i18n="@@game-progress-tooltip-levels">Levels</span> : 
        </div>
        <span *ngFor="let level of levels"
              class="sublevel">
            {{level}}
        </span>
    </div>
    <div *ngIf="(_chip.levels.length > 1) && (_chip.completedlevels.length != 0)">
        <div class="sublevelList">
            <div>
                <span i18n="@@game-progress-tooltip-completed-levels">Completed Levels</span> :
            </div>
            <div class="filled-color"
                  *ngFor="let level of completedlevels">
                <span class="number-show">{{level}}</span>
            </div>
        </div>
    </div>
</div>