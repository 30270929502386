<div *ngIf="subscription.organization"
     [ngClass]="{'compact_mode': compact}">
    <hr class="topline"
        *ngIf="canCancelSubscription(subscription) || (checkSubscriptionExpire(subscription.expiresOn) ==  true)">
    <div *ngIf="showLabel"
         class="mode_change_title"
         i18n="@@subscription-card-seat-allocation-options-title">Subscription
        parameters</div>
    <hr *ngIf="showLabel"
        class="topline">

    <div class="mode_change_line">
        <mat-radio-button class="radio-button"
                          (change)="onAllocModeChange($event.checked, subscription, SubscriptionAllocationMode.Automatic)"
                          [checked]="subscription?.allocationMode == SubscriptionAllocationMode.Automatic">
        </mat-radio-button>
        <div>
            <span class="mode_change_option_name"
                  i18n="@@subscription-card-seat-allocation-auto-name">(DEFAULT)
                First come, first served</span><br />
            <span class="mode_change_option_desc"
                  i18n="@@subscription-card-seat-allocation-auto-desc">Tokens are
                assigned automatically to profiles until there are none left.</span>
        </div>
    </div>
    <div class="mode_change_line">
        <mat-radio-button class="radio-button"
                          (change)="onAllocModeChange($event.checked, subscription, SubscriptionAllocationMode.Manual)"
                          [checked]="subscription?.allocationMode == SubscriptionAllocationMode.Manual">
        </mat-radio-button>
        <div>
            <span class="mode_change_option_name"
                  i18n="@@subscription-card-seat-allocation-manual-name">Manage the
                assignment of tokens</span><br />
            <span class="mode_change_option_desc"
                  i18n="@@subscription-card-seat-allocation-manual-desc">It is the
                admin of the organization who decides who the tokens go to. Tokens are managed from the "Organizations" menu.</span>
        </div>
    </div>

</div>