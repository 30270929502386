import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'hint-content',
    templateUrl: './hint-content.component.html',
    styleUrl: './hint-content.component.scss'
})
export class HintContentComponent {

    @ViewChild('hintTemplate', { static: true }) hintTemplate: TemplateRef<any>;
    
}
