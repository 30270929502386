import { AsyncValidatorFn, UntypedFormArray, ValidatorFn } from "@angular/forms";
import { CommonListColumnOptions } from "./common-list-column-options";
import { FieldValidationType } from "@applogic/model";

export enum CommonColumnType {
    Text = 'text',
    Label = 'label',
    MultiLinesText = 'multi-lines-text',
    Html = 'html',
    Date = 'date',
    User = 'user',
    Custom = 'custom',
    CustomHtml = 'custom-html',
    Image = 'image',
    Audio = 'audio',
    TextAreaReadonly = 'textarea-readonly',
    Selection = 'selection',
    Checkbox = 'checkbox',
    RadioButton = 'radio-button',
    Dictionary = 'dictionary',
    Action = 'action',
    Template = 'template',
    Boolean = 'boolean',
    Currency = 'currency',
    Activated = 'activated',
    LanguageCode = 'language-code',
    ID = 'id',
    NO = 'no',
    Void = 'void',
    FormControlInput = 'form-control-input',
    MergeColumns = 'merge-columns',
    RowType = '',
}

export enum CommonColumnHeaderType {
    Normal = 'normal',
    Template = 'template',
    Selection = 'selection',
    LanguageCode = 'language-code'
}

export class CommonListColumn
{
    public label: string;
    public footerLabel: string;

    // The unique column key.
    public columnKey: string;

    // The key of the data lookup.
    public key: string;
    public keys: string[]; // For nested keys.
    public csskey: string;

    // If the column visibility is selecteable.
    public selecteable: boolean;

    // If the column is visible.
    public selected: boolean;

    // If the column is visible by default.
    public selectedByDefault: boolean;

    public type: CommonColumnType|string;
    public rowTypeKey: string; // Used when type == CommonColumnType.RowType

    public headerType: CommonColumnHeaderType;
    public footerType: CommonColumnHeaderType;

    public templateColumnIdx: number = 0;
    public templateFooterIdx;
    public templateCellIdx: number = 0;

    public parameters: any;

    public canSort: boolean;

    public options: CommonListColumnOptions;

    public headerStyles: any = {};
    public rowStyles: any = {};
    public cellStyles: any = {};
    public elementStyles: any = {};
    public footerStyles: any = {};

    public formControlValidatorType: FieldValidationType;
    public formControlValidators: ValidatorFn[];
    public formControlAsyncValidators: AsyncValidatorFn[];

    public refreshItemsOnSelect: boolean;

    // Used when items are preloaded to identify searchable columns data.
    public searchable: boolean;

    public formArray: UntypedFormArray;
}