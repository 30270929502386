import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-language-footer',
    templateUrl: './language-footer.component.html',
    styleUrls: ['./language-footer.component.scss']
})
export class LanguageFooterComponent implements OnInit {

    constructor(readonly language: LanguageService) { }

    ngOnInit(): void {
    }

}
