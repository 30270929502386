import { Member, Model } from "@uon/model";
import { OrderMode } from "./order-mode";
import { OrderForm } from "./order-form";

export enum CheckoutSessionType {
    Session ='session',
    Invoice = 'invoice',
    Quotation = 'quotation',
    Subscription = 'subscription'
}

@Model()
export class GetCheckoutResponse {

    @Member()
    type: CheckoutSessionType;

    @Member()
    orderForm: OrderForm;
}

@Model()
export class CreateCheckoutResponse {

    @Member()
    type: OrderMode;

    @Member()
    sessionId?: string;

    @Member()
    stripeSubscriptionId?: string;

    @Member()
    clientSecret?: string;
}