import { Component, ViewChild } from '@angular/core';
import { CommonListCommonDisplayModeComponent } from '../common-list-common-display-mode/common-list-common-display-mode.component';
import { MatSort } from '@angular/material/sort';


@Component({
    selector: 'app-common-list-default-display-mode',
    templateUrl: './common-list-default-display-mode.component.html',
    styleUrls: ['../../common-list-renderer/common-list-renderer.component.scss', './common-list-default-display-mode.component.scss']
})
export class CommonListDefaultDisplayModeComponent extends CommonListCommonDisplayModeComponent {

    @ViewChild(MatSort, { static: false }) set content(content: MatSort) {
        this.list.setSort(content);
    }
}
