import { Component, Input, OnInit } from '@angular/core';
import { OrganizationRole } from '@applogic/model';

@Component({
    selector: 'app-organization-role-chip',
    templateUrl: './organization-role-chip.component.html',
    styleUrls: ['./organization-role-chip.component.scss']
})
export class OrganizationRoleChipComponent implements OnInit {

    @Input()
    role: OrganizationRole;

    @Input()
    showColor: boolean = true;

    public get u(): {role: OrganizationRole} {
        return {role: this.role};
    }

    constructor() { }

    ngOnInit(): void {
    }

}
