import { Model, Member, ID } from "@uon/model";
import { OnboardingFormType } from "./onboarding-form-type.model";
import { User } from "../user/user.model";
import { Subscription } from "../subscription/subscription.model";
import { Organization } from "../org/organization.model";

export enum OnboardingFormState {
    NotStarted = 'not-started',
    Incompleted = 'incompleted',
    Completed = 'completed',
    Skipped = 'skipped'
}

@Model()
export class OnboardingForm {

    /**
     * 
     * @param _id 
     */
    constructor(_id?: string) {
        this.id = _id;
    }

    @ID() 
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    type: OnboardingFormType;

    @Member()
    state: OnboardingFormState;

    @Member()
    step: string;

    @Member()
    filter: any;

    @Member()
    data: any;

    @Member()
    _user: User;

    @Member()
    _organization: Organization;

    @Member()
    _subscription: Subscription;
}