export type MultiCounters = { [key: string]: number; };

export class MultiCountersUtils {

    /**
     * Move qty from counters1 to counters2 if there are enough value in counters1.
     * 
     * @param {MultiCounters} counters1 Source counters. 
     * @param {MultiCounters} counters2 Destination counters.
     * @param {MultiCounters} qty Number of values to move..
     * 
     * @returns {boolean} Returns true if values was moved.
     */
    public static move(counters1: MultiCounters, counters2: MultiCounters, qty: MultiCounters): boolean {
        let result: MultiCounters = counters1;

        for (const key of Object.keys(qty)) {
            let count = qty[key];

            if (result[key] < count) {
                return false;
            }
        }

        for (const key of Object.keys(qty)) {
            let count = qty[key];

            if (result[key] >= count) {
                result[key] -= count;

                if (counters2[key]) {
                    counters2[key] += count;
                } else {
                    counters2[key] = count;
                }
            }
        }

        return true;
    }

    /**
     * Add counters1 to counters 2.
     * 
     * @param {MultiCounters} counters1 Source counters. 
     * @param {MultiCounters} counters2 Destination counters.
     * 
     * @returns {MultiCounters} Returns the counters.
     */
    public static add(counters1: MultiCounters, counters2: MultiCounters): MultiCounters {
        let result: MultiCounters = counters1;

        for (const key of Object.keys(counters1)) {
            let count = counters1[key];

            if (result[key] >= count) {

                if (counters2[key]) {
                    counters2[key] += count;
                } else {
                    counters2[key] = count;
                }
            }
        }

        return result;
    }

    /**
     * Check if two counters are equals.
     * 
     * @param {MultiCounters} counters1 First counters.
     * @param {MultiCounters} counters2 Second counters.
     * 
     * @returns {boolean} Returns true if counters are equals.
     */
    public static equal(counters1: MultiCounters, counters2: MultiCounters): boolean {
        let keys = Object.keys(counters1);

        for (const key of Object.keys(counters2)) {
            if (keys.indexOf(key) == -1) {
                keys.push(key);
            }
        }

        for (const key of keys) {
            let v1 = counters1[key] ? counters1[key] : 0;
            let v2 = counters2[key] ? counters2[key] : 0;

            if (v1 != v2) {
                return false;
            }
        }

        return true;
    }

    /**
     * Sum all the values of the MultiCounters.
     * 
     * @param {MultiCounters} counters1 The counters.
     * 
     * @returns {number} Returns the sum of all counter values.
     */
    public static getTotal(counters1: MultiCounters): number {
        let total: number = 0;

        let keys = Object.keys(counters1);

        for (const key of keys) {
            let val = counters1[key]

            if(val) {
                total += val;
            }
        }

        return total;
    }

    /**
     * Clone a MultiCounters
     * 
     * @param {MultiCounters} counters The counters to clone.
     * 
     * @returns {MultiCounters} The cloned counters.
     */
     public static clone(counters: MultiCounters): MultiCounters {
        let result: MultiCounters = {};

        Object.assign(result, counters);

        return result;
    }
}
