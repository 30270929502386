import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-toolbar-language',
    templateUrl: './toolbar-language.component.html',
    styleUrls: ['./toolbar-language.component.scss']
})
export class ToolbarLanguageComponent implements OnInit {

    @Input() 
    whiteStyle: boolean = true;

    constructor(readonly language: LanguageService) { }

    ngOnInit(): void {
    }

}
