<div>
    <app-error-message-area [error]="errorObject"></app-error-message-area>
    <div>&nbsp;</div>
    <div class="field-container">
        <mat-form-field appearance="outline"
                        *ngIf="paymentMethods && (paymentMethods.length > 0)">
            <mat-select class="payment-select-dropdown"
                        (selectionChange)="onPaymentMethodChanged($event)"
                        [ngModel]="paymentMethodId">
                <mat-option *ngFor="let paymentMethod of paymentMethods"
                            [value]="paymentMethod.id">{{paymentMethod.label}} ({{paymentMethod.exp_year}}/{{paymentMethod.exp_month}})</mat-option>
                <mat-option [value]="'NEW'"
                            i18n="@@payment-method-select-panel-add-payment-method">Add a payment method</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <app-stripe-payment-panel *ngIf="('NEW'==paymentMethodId) && clientSecret"
                              [clientSecret]="clientSecret"></app-stripe-payment-panel>
</div>