import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionAllocation } from '@applogic/model';

@Pipe({
    name: 'allocationCount',
    pure: false
})
export class AllocationCountPipe implements PipeTransform {

    transform(allocations: SubscriptionAllocation[], keys: string[]) {
        return allocations.filter(alloc => {
            
            for(const key of Object.keys(alloc.filter))
            {
                if(keys.indexOf(key) != -1) {
                    return true;
                }

            }

            return false;
        }).length;
    }

}
