import { Component, Injector, Input, OnInit } from '@angular/core';
import { Classroom, CommonQueryFilter } from '@applogic/model';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { ClassroomService } from '../../classroom/classroom.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { CreateClassroomDialogComponent } from 'src/app/classroom/create-classroom-dialog/create-classroom-dialog.component';
import { AccountService } from 'src/app/account/account.service';
import { CommonListDisplayMode } from 'src/app/core/common-list/common-list-renderer/common-list-renderer.component';
import { ClassroomDialogs } from 'src/app/classroom/classroom.dialogs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Component({
    selector: 'app-classrooms-list',
    templateUrl: './classrooms-list.component.html',
    styleUrls: ['./classrooms-list.component.scss']
})
export class ClassroomsListComponent extends CommonListComponent<Classroom> implements OnInit {

    ApplogicUtils = ApplogicUtils;
    CommonListDisplayMode = CommonListDisplayMode;
    
    @Input()
    isAdmin: boolean = false;

    @Input()
    orgId: string;

    @Input()
    excludeClassroomId: string;

    @Input()
    context: string = "";

    @Input()
    checkbox: boolean = false;

    @Input()
    canCreateClass: boolean = false;

    @Input()
    mode: 'selection'|'list' = 'selection';
    
    constructor(
        private injector: Injector,
        private classroomService: ClassroomService,
        public authService: AuthService,
        readonly account: AccountService,
        private snackbar: MatSnackBar,
        protected router: Router
    ) {
        super(injector);
        
        this.alwaysShowSelected = true;
        this.supportCompactMode = true;

        this.rendererOptions.stickyHeader = true;
        this.rendererOptions.showSearchBar = true;

        this.rendererOptions.verticalScrolling = true;
    }

    ngOnInit(): void {
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 20];

        this.rendererOptions.verticalScrolling = this.mode != 'list';

        super.ngOnInit();
        this.initColumns();
        this.refreshItems(); 
    }

    initColumns() {
        let key: string;


        if(this.orgId) {
            key = 'applogic/organization/classroom/list' + (this.context ? "-" + this.context : "");
        }
        else {
            key = 'applogic/classroom/list';
        }

        key += this.canCreateClass ? "-edit" : "";

        this.initPersistentKey(key);


        if(this.checkbox) {
            this.addColumn("", "selection", true, true, CommonColumnType.Selection, {
                data: {
                    radioButtonGroup: "classroom-selection"
                }
            });
        }

        if(this.isAdmin) {
            this.addColumn("id", "id", true, false, CommonColumnType.ID);
        }

        this.addColumn($localize`:@@common-name:Name`, "name", true, true, CommonColumnType.Text);

        if(!this.orgId) {
            this.addColumn($localize`:@@common-organization:Organization`, "organization.name", true, true, CommonColumnType.Text, {
                isSortable: false
            });
        }

        this.addColumn($localize`:@@common-students: Students `, "_studentsCount", true, true, CommonColumnType.Template, {
            isSortable: this.preload,
            fixedWidth: "65px"
        });
        
        this.addColumn($localize`:@@common-your-role:Your role`, "yourRole", true, false, CommonColumnType.Template, {
            templateCellIdx: 1,
            isSortable: this.preload,
            fixedWidth: "100px"
        });

        this.addColumn($localize`:@@org-classes-owner-label: Administered by `, "owner.fullName", true, !this.compactRow, CommonColumnType.Text);

        this.addColumn($localize`:@@common-created-on:Created On`, "createdOn", true, !this.compactRow, CommonColumnType.Date).parameters.format = "mediumDate";
        this.addColumn($localize`:@@common-updated-on:Updated On`, "updatedOn", true, false, CommonColumnType.Date).parameters.format = "mediumDate";
        
        if(this.canCreateClass) {
            this.addColumn("", "actions", false, true, CommonColumnType.Action);
        }
        this.updateColumns(true);
    }
    
    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;

        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });
        queryFilter.other.all = false;

        if(this.orgId) {
            queryFilter.other.orgId = this.orgId;
        }

        if(this.excludeClassroomId) {
            queryFilter.other.excludeClassroomId = this.excludeClassroomId;
        }

        queryFilter.setFields({"owner": true, "organization": true});

        this.classroomService.searchClassrooms(queryFilter).subscribe({
            next: (res) => {
                this.setResponse(res);
            }, error: (err) => {
                this.errorObject = err;
                console.error(" Error ", err.error.message);
            }
        });
    }

    getCustomLabel(key: string, val: any, row: Classroom) {
        if(key == "students") {
            if(row.students) {
                return row.students.length;
            }

            return 0;
        }

        return val;
    }

    onCompactModeChanged() {
        this.initColumns();
    }

    openNewClassroomDialog() {
        const appDialog = CreateClassroomDialogComponent.createDialog(this.dialogService, this.dialog);

        appDialog.show().then((v: boolean) => {
            
        });
    }

    openRemoveClassroomDialog(classroom: Classroom) {
        ClassroomDialogs.showDeleteClassroomDialog(this.dialogService, this.dialog, classroom, this.snackbar, this.classroomService).then(() => {
            this.removeItem(classroom);
        });
    }

    onRowClick(item: Classroom, event: any) {
        if(this.mode == 'list') {
            event.stopPropagation();
            this.router.navigate(['/classrooms/' +  item.slug + '/' + item.id], {
                queryParams: {
                    
                }
            });
        }
        else {
            super.onRowClick(item, event);
        }
    }
}
