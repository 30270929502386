import { MatPaginatorIntl } from '@angular/material/paginator';

// To translate the text for the mat paginator.
// Source: https://stackoverflow.com/questions/47593692/how-to-translate-mat-paginator-in-angular-4

const localeRangeLabel = (page: number, pageSize: number, length: number) => {
    const ofLabel = $localize`:@@mat-paginator-intl--of:of`;

    if (length == 0 || pageSize == 0) { return `0 ${ofLabel} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    
    return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
}


export function getLocalePaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = $localize`:@@mat-paginator-intl--items-per-page:Items per page`;
    paginatorIntl.nextPageLabel = $localize`:@@mat-paginator-intl--next-page:Next page`;
    paginatorIntl.previousPageLabel = $localize`:@@mat-paginator-intl--previous-page:Previous page`;
    paginatorIntl.getRangeLabel = localeRangeLabel;

    return paginatorIntl;
}