import { Component, HostBinding, Optional } from '@angular/core';
import { PageComponent } from '../page/page.component';
import { DialogComponent } from 'src/app/core/dialog/dialog.component';

@Component({
    selector: 'app-success-screen',
    templateUrl: './success-screen.component.html',
    styleUrl: './success-screen.component.scss'
})
export class SuccessScreenComponent {
    
    constructor(@Optional() page: PageComponent, @Optional() dialog: DialogComponent<any>) {
        if(page) {
            page.hideOverflowX = true;
        }

        if(dialog) {
            dialog.hideOverflowX = true;
        }
    }

    @HostBinding('style') get hostStyles() {
        return {
            width: "100%",
            height: "100%",
            display: "flex",
            "align-items": "center",
            "flex-direction": "column"
        };
    }

}
