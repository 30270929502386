import { Model, ID, Member, ArrayMember } from "@uon/model";
import { Invoice } from "./invoice.model";
import { Organization } from "../org/organization.model";
import { User } from "../user/user.model";
import { CurrencyAmount } from "../common/currency.model";
import { SubscriptionPlan, SubscriptionType } from "./subscription.model";

export const SubscriptionTypePlanList: {type: SubscriptionType, plan: SubscriptionPlan}[] = [
    {
        type: SubscriptionType.individual,
        plan: SubscriptionPlan.monthly
    },
    {
        type: SubscriptionType.individual,
        plan: SubscriptionPlan.yearly
    },
    {
        type: SubscriptionType.organization,
        plan: SubscriptionPlan.quarterly
    },
    {
        type: SubscriptionType.organization,
        plan: SubscriptionPlan.halfYearly
    },
    {
        type: SubscriptionType.organization,
        plan: SubscriptionPlan.yearly
    }
];

@Model()
export class SubscriptionPrices {

    @Member()
    productCode: string;

    @Member()
    stripeProductId: string;

    @Member()
    amounts: {[key: string]: number};

    @Member()
    currency: string;

    public setAmount(type: SubscriptionType, plan: SubscriptionPlan, amount: number) {
        this.amounts[SubscriptionPrices.getAmountKey(type, plan)] = amount;
    }

    public getAmount(type: SubscriptionType, plan: SubscriptionPlan) {
        return this.amounts[SubscriptionPrices.getAmountKey(type, plan)];
    }

    public static getAmountKey(type: SubscriptionType, plan: SubscriptionPlan) {
        return type + "_" + plan;
    }
}