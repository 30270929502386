import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SocialLoginService {

    constructor(private authService: AuthService) { }


}
