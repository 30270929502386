<app-design-card class="organization-role-card"
                 [ngClass]="{'readonly': readonly}"
                 [clickable]="!readonly"
                 (click)="cardSelect.setChecked()">
    <design-card-image>
        <app-shape type="circle"
                   scale="medium">
            <mat-icon>person</mat-icon>
        </app-shape>
        <!-- <img class="product-image"
                 [src]="_imageUrl" /> -->
    </design-card-image>
    <design-card-title>
        <span> {{ApplogicUtils.getOrganizationRoleLabel(role, true)}} </span>
    </design-card-title>
    <design-card-subtitle [ngSwitch]="role">
        <span *ngSwitchCase="OrganizationRole.Member"
              i18n="@@trial-form-page-step-2-classroom-select">I work directly with students as a teacher, SLP or specialist.</span>
        <span *ngSwitchCase="OrganizationRole.Administrator"
              i18n="@@trial-form-page-step-2-org-select">I manage software purchase and subscriptions for my organization</span>
    </design-card-subtitle>
    <design-card-select groupKey="org-role"
                        [value]="role"
                        #cardSelect>

    </design-card-select>
</app-design-card>