<div class="avatar">
    <app-user-avatar [user]="data.row.user"></app-user-avatar>
</div>
<div class="staff-info-block">
    <span class="name-role">
        <span>{{ data.row.user.fullName }}</span>
    </span>
    <small class="secondary-text"
           i18n="@@common-joined-on">
        Joined on {{ (data.row.addedOn || organization.createdOn) | date }}
    </small>
</div>
<span class="spacer"></span>
<app-organization-role-chip [role]="data.row.role"></app-organization-role-chip>

<app-common-list-card-element-renderer *ngIf="data.row && data.list"
                                       [cardData]="data"
                                       columnKey="actions"></app-common-list-card-element-renderer>