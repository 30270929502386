import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Utils } from '@applogic/model';
import { AuthService } from 'src/app/auth/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { SocialUser } from '../social-login-data';

// https://developers.google.com/identity/gsi/web/reference/js-reference?hl=fr#IdConfiguration
export interface IdConfiguration {
    client_id: string;
    auto_select?: boolean;
    callback?: any;
    login_uri?: string;
    native_callback?: any;
    cancel_on_tap_outside?: boolean;
    prompt_parent_id?: string;
    nonce?: string;
    context?: 'signin'|'signup'|'use';
    state_cookie_domain?: string;
    ux_mode?: 'popup'|'redirect';
    allowed_parent_origin?: string|string[];
    intermediate_iframe_close_callback?: 'logBeforeClose';
    itp_support?: boolean;
    login_hint?: string;
    hd?: string;
    use_fedcm_for_prompt?: boolean;
}

// https://developers.google.com/identity/gsi/web/reference/js-reference?hl=fr#text
export interface GsiButtonConfiguration {
    type: 'standard'|'icon';
    theme?: 'outline'|'filled_blue'|'filled_black';
    size?: 'large'|'medium'|'small';
    text?: 'signin_with'|'signup_with'|'continue_with'|'signin';
    shape?: 'rectangular'|'pill'|'circle'|'square';
    logo_alignment?: 'left'|'center';
    width?: string;
    locale?: string;
}

@Component({
    selector: 'app-google-login-button',
    templateUrl: './google-login-button.component.html',
    styleUrls: ['./google-login-button.component.scss', '../social-button.scss']
})
export class GoogleLoginButtonComponent {

    errorObject: any;

    show: boolean = true;

    @Input() buttonType: 'standard'|'custom'|'icon';
    @Input() text: 'signin_with'|'signup_with'|'continue_with'|'signin' = 'signin_with';
    
    @Output() onGoogleLoginClick = new EventEmitter<SocialUser>();

    @Output() success = new EventEmitter<any>();
    @Output() failure = new EventEmitter<any>();

    onDataCallbackEvent = new EventEmitter<SocialUser>();

    clientId: string;

    @ViewChild('customGoogleBtn') customGoogleBtn: ElementRef;

    constructor(private authService: AuthService, private zone: NgZone, private languageService: LanguageService) {
        this.clientId = environment.googleIdentityService?.frontendClientId;

        const that = this;

        this.onDataCallbackEvent.subscribe((res) => {
            this.zone.run(() => {
                that.onDataCallback(res);
            });
        });


        // (window as any).googleLogin = function (res) {
        //     console.log(res);
        //     const user: SocialUser = {};
        //     user.response = res;
        //     if(res.credential) {
        //         const payload = Utils.parseJwt(res.credential);
        //         if(payload) {
        //             user.name = payload.name;
        //             user.email = payload.email;
        //         }
        //     }
            
        //     that.onDataCallbackEvent.emit(user);
        // };
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        // Must call setTimeout() because in some components, the button is not already created.
        setTimeout(() => {
            this.createButton();
        });
    }

    ngOnDestroy() {
        (window as any).googleLogin = undefined;
    }

    onDataCallback(response: SocialUser) {
        this.onGoogleLoginClick.emit(response);
    }

    private createButton() {
        const that = this;

        
        const btnType = this.buttonType ? this.buttonType : 'standard';

        if(btnType == 'custom') {
            return;
        }
        
        const google: any = (window as any).google;

        this.initialize({
            client_id: this.clientId,
            callback: function (res: {
                clientId: string,
                client_id: string,
                credential: string,
                selected_by: 'btn'|string
            }) {
                console.log("google accounts id event");
                console.log(res);
                const user: SocialUser = {

                };
                user.response = res;
                if(res.credential) {
                    const payload = Utils.parseJwt(res.credential);
                    if(payload) {
                        user.name = payload.name;
                        user.email = payload.email;
                    }
                }
                that.onDataCallbackEvent.emit(user);
            }
        });

        const btn = document.getElementById("g_id_signin_button");
        
        this.renderButton({
            type: btnType,
            theme: 'outline',
            size: 'large',
            locale: this.languageService.currentLanguageCode,
            text: this.text
        });
        
        // google.accounts.id.prompt(); // also display the One Tap dialog

    }

    private initialize(config: IdConfiguration) {
        const google: any = (window as any).google;
        google.accounts.id.initialize(config);
    }

    private renderButton(options: GsiButtonConfiguration) {
        const google: any = (window as any).google;
        google.accounts.id.renderButton(
            document.getElementById("g_id_signin_button"),
            options  // customization attributes
        );
    }
}
