<div class="price-label">
    <div *ngIf="!isSymbolRight" class="symbol-part">
        {{symbol}}
    </div>
    <div class="integer-part">
        {{integerPart}}
    </div>
    <div>
        <div class="decimal-sublabel-part">
            <div class="decimal-part">
                {{decimalPart}}
            </div>
            <div class="sublabel-part">
                {{sublabel}}
            </div>
        </div>
    </div>
    <div *ngIf="isSymbolRight" class="symbol-part">
        {{symbol}}
    </div>
</div>