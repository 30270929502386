import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { OrgsSelectionDialogComponent } from '../orgs-selection-dialog/orgs-selection-dialog.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { Organization, OrganizationRole, OrganizationType } from '@applogic/model';
import { AbstractControl } from '@angular/forms';
import { RxSubscriptionList } from 'src/app/Utilities/rx-subscription-list';

@Component({
    selector: 'app-organization-selection-form-control',
    templateUrl: './organization-selection-form-control.component.html',
    styleUrl: './organization-selection-form-control.component.scss'
})
export class OrganizationSelectionFormControlComponent {

    organizationId: string = "";

    _control: AbstractControl<any, any>;

    @Input()
    set control(control: AbstractControl<any, any>) {

        this._subscriptions.releaseKey("controlValueChange");

        this._control = control;

        if(control) {
            const sub = control.valueChanges.subscribe((v) => {
                if(v != this.selectedOrgId) {
                    if (this.authService.user) {
                        const org = this.orgs.find(o => o.id == v);
                        this.setOrganization(org);
                    }
                    else {
                        this.setOrganization(null);
                    }
                }
                
            });
            this._subscriptions.set("controlValueChange", sub);
        }
    }

    get control() {
        return this._control;
    }

    @Output()
    orgChanged: EventEmitter<Organization> = new EventEmitter<Organization>();

    @Input()
    setFirstOrg: boolean = true;

    orgs: Organization[] = [];

    selectedOrgId: string;

    private _subscriptions = new RxSubscriptionList();

    constructor(
        public authService: AuthService,
        protected orgService: OrganizationService,
        private dialogService: DialogService,
        private dialog: MatDialog) {

    }

    ngOnInit(): void {
        this._subscriptions.add(this.authService.onUserChange.subscribe((user) => {
            this.updateOrganizationList();
        }));
        if (this.authService.isLoggedIn()) {
            this.updateOrganizationList();
        }
    }

    ngOnDestroy() {
        this._subscriptions.releaseAll();
    }

    /**
     * Update the organization list.
     */
    updateOrganizationList() {
        this.orgService.listOrgs().subscribe((orgs) => {
            orgs = orgs.filter(o => o.type != OrganizationType.Trials);
            this.orgs = orgs;

            if (orgs.length > 0) {
                orgs.map(o => {
                    o.role = this.getOrgRole(o);
                });

                // Select the first organization if nothing else is set yet.
                if (this.setFirstOrg) {
                    const FOUND = orgs.find(itemk => itemk.role === OrganizationRole.Administrator)

                    if (FOUND) {
                        this.setOrganization(FOUND);
                    }
                }
            }

        });
    }

    getOrgRole(org: Organization) {
        const role = org.staffMembers.find(s => s.user.id === this.authService.user.id)?.role;
        return role;
    }

    selectOrganisation() {
        OrgsSelectionDialogComponent.createDialog(this.dialogService, this.dialog, {
            message: $localize`:@@quotation-card-select-org-for-invoice-create:You are creating an invoice that is part of organization:`
        }).show().then((org) => {
            if (org) {
                // Retrieve the organization with its address.
                this.orgService.getOrg(org.id).subscribe((response) => {
                    this.setOrganization(response.org);
                });
            }
        });
    }

    setOrganization(org: Organization) {
        
        const orgId = org?.id || "";

        if(orgId == this.selectedOrgId) {
            return;
        }

        if (org) {
            if (!this.orgs.find(o => o.id == org.id)) {
                this.orgs.push(org);
            }
        }

        if(this._control && this.selectedOrgId != orgId) {
            this.selectedOrgId = orgId;
            this._control.setValue(orgId);
            this.orgChanged.emit(org);
        }
    }
}
