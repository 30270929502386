import { Directive } from '@angular/core';

@Directive({
    selector: 'app-page-header-card-title',
    host: {
        'class': 'app-page-header-card-title'
    }
})
export class PageHeaderCardTitleDirective {

    constructor() { }

}
