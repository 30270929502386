import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { map } from 'rxjs/operators';
import { GameProgressSelectableColumn } from "./game-progress-selectable-column";
@Pipe({
  name: "listTypeColumns",
  pure: true,
})
export class GameProgressListTypeColumnsPipe implements PipeTransform {
  constructor(private localStoreService: LocalStorageService) {}

  transform(columns: GameProgressSelectableColumn[], type: string, subType: string) {
    return columns.filter(column => (column.list_type == type) && (column.list_subtype == subType));
  }
}
