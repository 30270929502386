import { Member, Model } from "@uon/model";
import { EmailTemplateType } from "./email-template-info.model";
import { ModelDeleteType } from "../common/common.model";

@Model()
export class CreateEmailTemplateInfoQuery {

    @Member()
    key: string;

    @Member()
    type: EmailTemplateType;

    @Member()
    name: string;

    @Member()
    subject: string;

    @Member()
    body: string;
    
    @Member()
    lang: string;

}

@Model()
export class UpdateEmailTemplateInfoQuery {

    @Member()
    name: string;

    @Member()
    subject: string;

    @Member()
    body: string;

}

@Model()
export class DuplicateEmailTemplateInfoQuery {

    @Member()
    key: string;

    @Member()
    type: EmailTemplateType;

    @Member()
    name: string;

    @Member()
    subject: string;

    @Member()
    body: string;
    
    @Member()
    lang: string;

}

@Model()
export class DeleteEmailTemplateInfoQuery {

    @Member()
    type: ModelDeleteType;
}
