import { ArrayMember, ID, Member, Model } from "@uon/model";

@Model()
export class DocumentHistory {

    public static DefaultFilteredKeys: Set<string> = new Set<string>(["id", "createdOn", "updatedOn"]);

    @ID()
    id: string;

    @Member()
    documentId: string;

    @Member()
    documentType: string;

    @Member()
    userId: string;

    @Member()
    comment: string;

    @Member()
    createdOn: Date;

    @ArrayMember(String)
    keys: string[];

    @Member()
    oldValues: { [key: string]: any };

    @Member()
    newValues: { [key: string]: any };

    public removeDefaultKeys() {
        if(this.keys) {
            this.keys = this.keys.filter(k => {
                    if(DocumentHistory.DefaultFilteredKeys.has(k)) {
                        if(this.oldValues[k]) {
                            delete this.oldValues[k];
                        }
                        if(this.newValues[k]) {
                            delete this.newValues[k];
                        }
                        return false;
                    }

                    return true;
                });
        }
    }

    public static createFromDiff(sourceData: any, targetData: any, documentType: string, documentId: string, userId: string) {
        const history = new DocumentHistory();
        history.createdOn = new Date();
        history.documentType = documentType;
        history.documentId = documentId;
        history.userId = userId;
        history.keys = [];
        history.oldValues = {};
        history.newValues = {};

        this.scanDiff(history, sourceData, targetData, history.oldValues, history.newValues, "");

        return history;
    }

    private static scanDiff(history: DocumentHistory, sourceData: any, targetData: any, oldValues: { [key: string]: any }, newValues: { [key: string]: any }, parentKey: string) {

        let changed: boolean = false;

        const keys = this.getCombineKeys(sourceData, targetData);

        for (const key of keys) {
            const srcVal = sourceData ? sourceData[key] : undefined;
            const dstVal = targetData ? targetData[key] : undefined;

            if ( (srcVal?.constructor == Object) || (dstVal?.constructor == Object)) {
                let addCurrentKey: boolean = false;
                if(srcVal?.constructor != Object) {
                    oldValues[key] = srcVal;
                    addCurrentKey = true;
                }
                
                if(dstVal?.constructor != Object) {
                    newValues[key] = dstVal;
                    addCurrentKey = true;
                }

                if(addCurrentKey) history.keys.push(parentKey + key);

                let newOldValues: { [key: string]: any } = {};
                let newNewValues: { [key: string]: any } = {};
                this.scanDiff(history, srcVal, dstVal, newOldValues, newNewValues, parentKey + key + ".");

                if(Object.keys(newOldValues).length !== 0) {
                    oldValues[key] = newOldValues;
                }

                if(Object.keys(newNewValues).length !== 0) {
                    newValues[key] = newNewValues;
                }
            }
            else if ( !this.isEqual(srcVal, dstVal) ) {
                history.keys.push(parentKey + key);
                oldValues[key] = srcVal;
                newValues[key] = dstVal;
            }
        }

        return changed;
    }

    private static isEqual(srcVal: any, dstVal: any) {
        if ( (srcVal?.constructor == Date) || (dstVal?.constructor == Date)) {
            return srcVal?.getTime() == dstVal?.getTime();
        }

        if(srcVal?.constructor != dstVal?.constructor) {
            return false;
        }

        return srcVal == dstVal;
    }

    private static getCombineKeys(sourceData: any, targetData: any) {
        const keys: any = {};

        if ((sourceData != undefined) && (typeof sourceData == 'object')) {
            for (const key of Object.keys(sourceData)) {
                keys[key] = true;
            }
        }

        if ((targetData != undefined) && (typeof targetData == 'object')) {
            for (const key of Object.keys(targetData)) {
                keys[key] = true;
            }
        }

        return Object.keys(keys);
    }
}