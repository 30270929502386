<app-page type="centered">
    <app-page-content class="app-form-container">
        <app-design-card class="unsubscribe-card"
                         appearance="raised"
                         [clickable]="false">
            <design-card-content>
                <div class="tab-header">
                    <mat-icon>settings</mat-icon>
                    <h2 i18n="@@common-preferences">Preferences</h2>
                </div>

                <div class="property-list" *ngIf="!errorObject">

                    <div class="list-item">
                        <span class="label"
                              i18n="@@common-email"> Email </span>
                        <div class="value">
                            {{ email }}
                        </div>
                    </div>

                    <div class="list-item">
                        <span class="label"
                              i18n="@@common-communication-settings">
                            Communication Settings
                        </span>
                        <div class="value">
                            <app-user-email-prefs [formGroup]="emailPreferencesForm"
                                                  [showTitle]="false"
                                                  (onFormChangedEvent)="onEmailPreferencesChanged($event)"></app-user-email-prefs>

                        </div>
                    </div>

                </div>

                <app-error-message-area [error]="errorObject"></app-error-message-area>
            </design-card-content>
        </app-design-card>
    </app-page-content>
</app-page>