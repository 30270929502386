import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'app-common-list-action-item',
    templateUrl: './common-list-action-item.component.html',
    styleUrl: './common-list-action-item.component.scss'
})
export class CommonListActionItemComponent {

    @Input()
    label: string;

    @Input()
    icon: string;

    @Input()
    color: string;

    @Input()
    iconColor: string;

    @Input()
    disabled: boolean;

    @Output()
    click = new EventEmitter<any>();

}
