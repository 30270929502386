import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[common-list-toolbar-option]'
})
export class CommonListToolbarOptionDirective {

    @HostBinding('class') get getAlignClass() {
        return 'common-list-toolbar-option';
    }
    
    constructor() { }

}
