<div class="app-page--header page-header">
    <div class="page-header-container">
        <div class="menu-button"
             *ngIf="hasMenuButton && !(nav.menuOpened | async)">
            <button mat-icon-button
                    (click)="nav.toggleMenu()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>

        <div class="logo"
             *ngIf="showLogo">
            <app-logo [size]="1.6"
                      [logoOnly]="!showFullLogo"></app-logo>
        </div>

        <div class="title-contents app-md-title-medium"
             [hidden]="!(!!currentTitle)">
            <div class="title-label">{{currentTitle}}</div>
            <ng-content select="app-title"></ng-content>
        </div>

        <div *ngIf="globalMessage"
             class="extra-bar"
             style="font-size: 0.9vw;">
            <app-error-message-area [error]="globalMessage"></app-error-message-area>
        </div>

        <app-theme-switch></app-theme-switch>
        <app-dynamic-material-color-selection-button></app-dynamic-material-color-selection-button>

        <div class="toolbar-content">
            <ng-content select="[page-toolbar-content]"
                        *ngIf="showToolbar"></ng-content>
            <app-toolbar-user-avatar *ngIf="showToolbar && auth.isLoggedIn()"></app-toolbar-user-avatar>
        </div>
    </div>
</div>

<ng-content select="app-page-header-card,app-page-header2-card"></ng-content>
<ng-content select="app-page-content,.app-page-content,[appPageContent],[page-content]"></ng-content>

<span class="page-spacer"
      *ngIf="type == PageType.Normal"></span>

<div class="app-page--footer page-footer">
    <div class="page-footer-container">
        <div class="sub-container">
            <span class="copyright-footer"
                  i18n="@@page-footer--copyright">© 2024 Copyright Appligogiques All rights reserved.‍</span>
            <div class="spacer">
                <div class="spacer"></div>
                <div class="footer-message">
                    <mat-icon svgIcon="precision-manufacturing"></mat-icon>
                    <div class="text"
                         i18n="@@page-footer--message">
                        Run-in version, thank you for your participation
                    </div>
                </div>
            </div>
            <div class="info-links">
                <a [href]="termsLink"
                   target="_blank"
                   i18n="@@page-footer--terms-of-service">Terms of Service</a>
                <a [href]="privacyLink"
                   target="_blank"
                   i18n="@@page-footer--privacy-policy">Privacy Policy</a>
                <div>
                    <app-language-button></app-language-button>
                </div>
            </div>
        </div>
    </div>
</div>