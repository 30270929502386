import { Model, ID, Member, ArrayMember } from "@uon/model";

export class CommonQueryResponse<ModelTypeT> {

    count: number;

    result: ModelTypeT[];

    // For more data related to the result list.
    data: any;
}

export class CommonCountResponse {

    count: number;

}