import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Address, Utils } from '@applogic/model';
import PlaceResult = google.maps.places.PlaceResult;
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { GoogleMapsService } from '../google-maps.service';

export interface AddressNameInputResult {
    placeResult: PlaceResult;
    address: Address;
}

@Component({
    selector: 'app-address-name-input',
    templateUrl: './address-name-input.component.html',
    styleUrl: './address-name-input.component.scss'
})
export class AddressNameInputComponent {

    @Input()
    hidden: boolean = false;

    @Input()
    label: string;

    @Input()
    onlySearch: boolean = false;

    @Input()
    appearance: MatFormFieldAppearance = "outline";
    
    @Input()
    floatLabel: FloatLabelType = "always";

    @Input()
    subscriptSizing: SubscriptSizing = "fixed";

    protected _name: string;

    @Input()
    set name(name: string) {
        this._name = name || "";
        if(this.control) {
            this.control.setValue(name);
        }
    }

    get name() {
        return this._name;
    }

    protected _control: AbstractControl;

    @Input()
    set control(control: AbstractControl) {
        this._control = control;
    }

    get control() {
        return this._control;
    }

    @ViewChild('search', { static: true }) searchElementRef: ElementRef;

    @Output() onAddressSelect: EventEmitter<AddressNameInputResult> = new EventEmitter();

    constructor(protected googleMapsService: GoogleMapsService) {

    }

    ngOnInit() {
        if(!this._control) {
            this._control = new UntypedFormControl();
        }
    }

    onAutocompleteSelected(result: PlaceResult) {
        const address = Utils.getAddressFormattedData(result);
        this.onAddressSelect.emit({
            placeResult: result,
            address
        });

        this.setAddressNameField(result.name);
    }

    setAddressNameField(value: any) {
        const orgName = this.control.value;
        if (!orgName || orgName && orgName != value) {
            this.control.setValue(value);
        }
    }

    onInputChange(event) {
        // this.searchElementRef.nativeElement.value = event.target.value;
    }
}
