<div *ngIf="authService.isLoggedIn() && (orgs.length > 0)"
     class="centered-flex-row">
    <mat-form-field appearance="outline"
                    class="select-organization-form-field"
                    floatLabel="always">
        <mat-label i18n="@@tell-us-form--select-an-organization">Select an organization</mat-label>
        <mat-select [(value)]="organizationId"
                    [formControl]="_control">
            <mat-option value=""
                        i18n="@@tell-us-form--create-new-organization-option">Create a new organization</mat-option>
            <mat-option [value]="org.id"
                        *ngFor="let org of orgs">{{org.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="select-organization-button"
         style="">
        <button mat-flat-button
                color="primary"
                (click)="selectOrganisation()">
            <span i18n="@@common-select">Select</span>
        </button>
    </div>
</div>