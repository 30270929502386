import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-help-icon',
    templateUrl: './help-icon.component.html',
    styleUrl: './help-icon.component.scss'
})
export class HelpIconComponent {

    @Input()
    protected type: 'tooltip'|'popover' = 'tooltip';
    
    @Input()
    template: TemplateRef<any>;

    @Input()
    text: string;

    @Input()
    placement: string;
}
