import { ArrayMember, ID, Member, Model } from "@uon/model";

@Model()
export class ConfusionsConfig {
    @ArrayMember(Object)
    auditive: string[][];

    @ArrayMember(Object)
    visual: string[][];
}

@Model()
export class SoundConfig {
    @Member()
    key: string;

    @Member()
    label: string;

    @ArrayMember(String)
    labels: string[];

    @Member()
    IPA: string;
}

@Model()
export class WordSyntaxLanguageConfig {
    @ID()
    id: string;
    
    @Member()
    lang: string;
    
    @Member()
    sounds: {[key: string]: SoundConfig};

    @Member()
    confusions: ConfusionsConfig;

    @ArrayMember(String)
    doubleConsonants: string[];

    @ArrayMember(String)
    vowels: string[];

    @Member()
    frequency: {[key: string]: number};
}

@Model()
export class WordSyntaxConfig {
    @Member()
    version: string;

    @Member()
    langs: {[key: string]: WordSyntaxLanguageConfig};
}
