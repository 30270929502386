
import { Model, ID, Member, ArrayMember } from '@uon/model';
import { User } from '../user/user.model';

@Model()
export class LocalizeModel {

    @ID()
    id: string;

    @Member()
    key: string;

    @Member()
    fr: string;

    @Member()
    en: string;

    @Member()
    es: string;
}



