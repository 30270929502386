import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonQueryFilter, Organization, Quotation } from '@applogic/model';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { PdfViewerDialogComponent } from 'src/app/core/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { ChecklistPopoverComponent } from 'src/app/onboarding-ui/checklist-popover/checklist-popover.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { OrgsSelectionDialogComponent } from 'src/app/organization-ui/orgs-selection-dialog/orgs-selection-dialog.component';
import { AngularUtils } from 'src/app/services/angular-utils';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { ProductService } from 'src/app/services/product.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
    selector: 'app-quotation-list',
    templateUrl: './quotation-list.component.html',
    styleUrls: ['./quotation-list.component.scss']
})
export class QuotationListComponent extends CommonListComponent<Quotation> implements OnInit {
    quotationIdForCreatingInvoiceOnboarding: string;

    constructor(private injector: Injector,
        private auth: AuthService,
        private router: Router,
        private quotationService: QuotationService,
        private subscriptionService: SubscriptionService,
        private productService: ProductService,
        private orgService: OrganizationService,
        private errorDialogService: HandleErrorDialogService) {
        super(injector);

        // Set default values
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 25, 50];
        this.rendererOptions.tableContainerClasses = ["mat-elevation-z8"];
        this.rendererOptions.showSelectColumnsActionHeader = true;
        this.supportCompactMode = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.productService.getCurrencies().subscribe((response) => {
            this.initColumns();
            this.refreshItems(); 
        });
    }

    initColumns() {
        this.initPersistentKey('applogic/account/quotations');

        this.addColumn($localize`:@@quotation-table-quoteNo-label: Quote no. `, "quotationNo", true, true, CommonColumnType.NO);

        this.addColumn($localize`:@@quotation-table-amount: Amount `, "priceTable.total", true, true, CommonColumnType.Currency).parameters.currencyKeys = "priceTable.currency";

        this.addColumn($localize`:@@common-organization:Organization`, "org", true, !this.compactRow, CommonColumnType.Custom);
        this.addColumn($localize`:@@common-created-by: Created by `, "user.fullName", true, !this.compactRow, CommonColumnType.Text);

        this.addColumn($localize`:@@quotation-table-date: Date `, "createdOn", true, !this.compactRow, CommonColumnType.Date, {
            fixedWidth: "100px",
            data: {
                format: "mediumDate"
            }
        });
        this.addColumn($localize`:@@common-manager-table-update-date-heading:Update Date`, "updatedOn", true, false, CommonColumnType.Date, {
            fixedWidth: "100px",
            data: {
                format: "mediumDate"
            }
        });
        this.addColumn($localize`:@@quotation-table-valid-until: Valid until `, "expiryDate", true, true, CommonColumnType.Date, {
            fixedWidth: "100px",
            data: {
                format: "mediumDate"
            }
        });
        this.addColumn("", "actions", false, true, CommonColumnType.Action);
        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;
        const queryFilter = new CommonQueryFilter({
            skip: start,
            limit: count,
            sort: sort,
            search: search
        });
        queryFilter.other.all = false;

        this.quotationService.getQuotations(queryFilter).then((res) => {
            // const EMAIL = this.auth.user.email;
            // const FILTER = res.result.filter(itemk => itemk.email === EMAIL)
            const FILTER = res.result;

            // Get the first quotation for onboarding
            for (let i = 0; i < FILTER.length; i++) {
                this.quotationIdForCreatingInvoiceOnboarding = FILTER[i].id;
                break;
            }

            this.setItems(FILTER, res.count);
        }).catch((err) => {
            this.errorObject = err;
            console.error(" Error ", err.error.message);
        })
    }

    onShowQuote(quote: any) {
        this.quotationService.getQuotationPDf(quote.quotationNo).then((res: any) => {
            const title = $localize`:@@common-subscription-quotation-no:Quotation ${quote.quotationNo}`;
            PdfViewerDialogComponent.createDialog(this.dialogService, this.dialog, {
                title,
                pdfUrl: res.pdfurl
            }).show();
        }).catch((error) => {
            console.log(error.error.message);
        });
    }

    onDownloadQuote(quote: any) {
        this.quotationService.getQuotationPDf(quote.quotationNo).then((res: any) => {
            AngularUtils.downloadBase64File(res.pdfurl, quote.quotationNo + ".pdf", 'application/pdf');
        }).catch((error) => {
            console.log(error.error.message);
        });
    }

    
    onCreateInvoiceClick(quotation: Quotation) {
        this.orgService.findOrg({
            userId: this.auth.user.id,
            ...quotation.org && {
                orgId: quotation.org.id
            },
            ...!quotation.org && {
                nearestName: quotation.organization.name
            }
        }).subscribe((response) => {
            this.createAnInvoiceFor(quotation, response.org);
        }, (err) => {
            this.createAnInvoiceFor(quotation);
        });
    }

    private createAnInvoiceFor(quotation: Quotation, org?: Organization) {
        OrgsSelectionDialogComponent.createDialog(this.dialogService, this.dialog, {
            message: $localize`:@@quotation-card-select-org-for-invoice-create:You are creating an invoice that is part of organization:`,
            options: {
                org
            }
        }).show().then((org) => {
            if (org) {
                this.subscriptionService.createInvoiceFromQuotation(quotation.id, org.id).subscribe((invoice) => {

                }, (err) => {
                    this.errorDialogService.openErrorDialog(undefined, err, false);
                });
            }
        });
    }

    onPayQuotationClick(quotation: Quotation) {
        this.router.navigate(['/order/organization'], {
            queryParams: {
                quotation_id: quotation.id
            }
        });
    }

    onMenuClick(item: any, event: any) {
        super.onMenuClick(item, event);
        ChecklistPopoverComponent.closeAll();
    }

    getCustomLabel(key: string, val: any, row: Quotation) {
        if(key == "org") {
            if(row.org?.name) {
                return row.org.name;
            }
            else if(row.organization?.name) {
                return row.organization.name;
            }
        }

        return "";
    }

    onCompactModeChanged() {
        this.initColumns();
    }
}
