import { Directive } from '@angular/core';

@Directive({
    selector: '[app-dialog-action],app-dialog-action,[appDialogAction]',
    host: {
        'class': 'app-dialog-action',
    }
})
export class DialogActionDirective {

    constructor() { }

}
