import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Classroom, OrganizationRole, User, Utils } from '@applogic/model';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { OrganizationService } from '../../organization/organization.service';


@Component({
    selector: 'app-users-selection',
    templateUrl: './users-selection.component.html',
    styleUrls: ['./users-selection.component.scss']
})
export class UsersSelectionComponent implements OnInit {

    @Output()
    onUserChange: EventEmitter<User> = new EventEmitter();
    
    classroom: Classroom;

    organizationUsers: User[] = [];

    filteredOrganizationUsers: User[] = [];

    @Input()
    singleUserSelect: boolean = false;

    @Input()
    excludeCurrentUser: boolean = false;

    @Input()
    orgId: string;

    @Input()
    role: OrganizationRole;

    @Input()
    excludeList1: User[] = [];

    @Input()
    excludeList2: User[] = [];

    collaboratorRemove: User[] = [];
    inviteCollaboratorError: string;

    collaboratorAdd: User[] = [];

    constructor(readonly loading: LoadingService,
        private orgService: OrganizationService) { }

    ngOnInit(): void {
        this.collaboratorRemove = [];
        this.collaboratorAdd = [];
        this.inviteCollaboratorError = "";

        this.refreshUsers();
    }

    refreshUsers() {
        this.filteredOrganizationUsers = [];
        this.organizationUsers = [];
        if (this.orgId) {
            this.orgService.getOrg(this.orgId).subscribe((o) => {

                this.organizationUsers = o.org.staffMembers
                    .filter((s) => {
                        if (!this.role || (this.role == s.role)) {
                            return true;
                        }

                        return false;
                    })
                    .map((s) => s.user)
                    .filter((u) => {
                        if (!this.isUserOwner(u.id)) {
                            return true;
                        }
                    });

                this.filterOrgUsers("");
            });
        }
    }

    isUserOwner(userId: string) {
        if(!this.classroom) return false;
        return this.classroom.owner && (this.classroom.owner.id == userId);
    }

    filterOrgUsers(search: string) {
        this.filteredOrganizationUsers = this.organizationUsers.slice(0); // clone the users list.
        this.filterExcludedUsers();
        if (this.organizationUsers.length != 0) {
            if (search == "") {
                this.filteredOrganizationUsers = this.organizationUsers.slice(0);
                this.filterExcludedUsers();
                return;
            }
            this.filteredOrganizationUsers = this.filteredOrganizationUsers.filter(
                (u) => {
                    return u.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }
            );
        }
    }

    filterExcludedUsers() {
        const allCollaboratorList = [
            ...this.excludeList1,
            ...this.excludeList2,
        ];

        const inviteData = [];
        if (this.filteredOrganizationUsers.length != 0) {
            this.filteredOrganizationUsers.forEach((filterData) => {
                let found = allCollaboratorList.find(
                    (user) => user.email === filterData.email
                );
                if (!found) {
                    inviteData.push(filterData);
                }
            });
        }
        this.filteredOrganizationUsers = inviteData;
    }

    onCollaboratorChecked(event: MatCheckboxChange) {
        
        if (this.singleUserSelect || event.checked) {
            this.addCollaborator(event.source.value as any);
        } else {
            this.removeCollaborator(event.source.value as any);
        }
    }

    addCollaborator(u: User) {

        if(this.singleUserSelect) {
            this.collaboratorAdd = [];
        }

        const currentEmail = u["email"];
        const founData = this.collaboratorAdd.find((col) => col.id == u["id"]);
        if (!founData) {
            this.collaboratorAdd.push(u);
        }

        const index = this.collaboratorRemove.indexOf(u);
        if (index > -1) {
            this.collaboratorRemove.splice(index, 1);
        }

        this.onUserChange.emit(u);
    }

    removeCollaborator(u: User) {
        this.collaboratorRemove.push(u);

        const index = this.collaboratorAdd.indexOf(u);
        if (index > -1) {
            this.collaboratorAdd.splice(index, 1);
        }
    }

    isInCollaboratorList(u: User) {
        // return this.collaborators.some(c => c.id === u.id);
    }

    select() {

    }
}
