import { Model, Member } from "@uon/model";


@Model()
export class ProductSku {

    @Member()
    platform: string;

    @Member()
    sku: string;


}