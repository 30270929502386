import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { MessageBoxButton } from './messagebox-button';
import { MessageBoxIcon } from './messagebox-icon';
import { MessageBoxResult } from './messagebox-result';
import { MessageBoxSettings } from './messagebox-settings';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';

interface MessageBoxDialogData extends MessageBoxSettings {
    
}

@Component({
    selector: 'app-message-box-dialog',
    templateUrl: './message-box-dialog.component.html',
    styleUrls: ['./message-box-dialog.component.scss']
})
export class MessageBoxDialogComponent implements OnInit {

    dialogResult: MessageBoxResult = MessageBoxResult.None;

    errorObject: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: MessageBoxDialogData,
        private mdDialogRef: MatDialogRef<MessageBoxDialogComponent, MessageBoxResult>) { }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: MessageBoxDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<MessageBoxDialogComponent, MessageBoxDialogData, MessageBoxResult>(dialogService, dialog, MessageBoxDialogComponent, {
            data,
            width: "400px",
            maxWidth: "80vw",
            minWidth: "300px",
            panelClass: 'makeItMiddle'
        }, config);
    }

    onButtonClick(button: MessageBoxButton) {
        this.dialogResult = button.result;
        this.errorObject = undefined;

        if(this.data.validateBeforeCallback) {
            this.data.validateBeforeCallback(this.dialogResult).then((errorObject) => {
                if(errorObject) {
                    this.errorObject = errorObject;
                }
                else {
                    this.mdDialogRef.close(this.dialogResult);
                }
            }).catch((ex) => {
                this.errorObject = ex;
            })
        }
        else {
            this.mdDialogRef.close(this.dialogResult);
        }  
    }

    getButtons(type: string) {
        return this.data.buttons.filter(b => (b.type || 'stroke') == type)
    }

}
