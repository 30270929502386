import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-title-header',
    templateUrl: './section-title-header.component.html',
    styleUrl: './section-title-header.component.scss'
})
export class SectionTitleHeaderComponent {

    @Input()
    section: 'step';
}
