import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService, LOCAL_STORAGE_USER_KEY } from 'src/app/auth/auth.service';
import { ClassroomService } from '../classroom.service';
import { ActivatedRoute, } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { FADE_IN_OUT, OPACITY_IN_OUT } from 'src/app/animations/fade';
import { AccountService } from 'src/app/account/account.service';
import { KnowledgeBaseService, ToolTipinfo } from 'src/app/services/knowledge-base.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FormService } from 'src/app/services/form.service';



@Component({
    selector: 'app-classroom-list-page',
    templateUrl: './classroom-list-page.component.html',
    styleUrls: ['./classroom-list-page.component.scss'],
    animations: [FADE_IN_OUT, OPACITY_IN_OUT]
})
export class ClassroomListPageComponent implements OnInit {


    @ViewChild('classroomFormTemplate', { static: true })
    classroomFormTemplate: TemplateRef<any>;

    classroomTooltipArr: ToolTipinfo[] = [];

    user: any;

    public isOnboardingVisible: boolean = false;

    public canCreateClass: boolean = false;
    
    constructor(public auth: AuthService,
        readonly account: AccountService,
        readonly loading: LoadingService,
        readonly knowledgeBaseService: KnowledgeBaseService,
        private localStorageService: LocalStorageService,
        public formService: FormService

    ) {

    }

    ngOnInit(): void {
        this.getUser();

        this.canCreateClass = this.account.canCreateClassroom();
    }

    getUser() {
        this.user = this.localStorageService.get(LOCAL_STORAGE_USER_KEY);
    }



}
