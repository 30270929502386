import { Member, Model } from "@uon/model";

@Model()
export class GameProgressAppActivity {
    @Member()
    public _id: string;

    @Member()
    public no: number;

    @Member()
    public name: string;
}
