import { Component, Input } from '@angular/core';
import { CustomFormField } from '../../custom-form-field';
import { FormBuilderRendererComponent } from '../../form-builder-renderer/form-builder-renderer.component';

@Component({
    template: ''
})
export class AbstractCustomFormFieldComponent {

    @Input()
    parent: FormBuilderRendererComponent;
    
    @Input()
    field: CustomFormField;

    showDebugs(event: any) {
        this.parent.showDebugs(event);
    }
}
