import { Model, Member, ValidateEmail } from "@uon/model";


@Model()
export class AdministrativeContact {

    @Member()
    fullName: string;

    @Member()
    position: string;

    @Member({
        validators: [ValidateEmail()]
    })
    email: string;

    @Member({
        validators: []
    })
    phone: string;
}