import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldValidationType, GetOrgResponse, MultiCounters, OnboardingSubscriptionUserInvite, Product, SubAllocationsResponse, SubscriptionAllocationMode, User } from '@applogic/model';
import { Subject } from 'rxjs';
import { CommonColumnHeaderType, CommonColumnType, CommonListColumn } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { ProductService } from 'src/app/services/product.service';
import { SeatService } from 'src/app/services/seat.service';
import { debounceTime } from 'rxjs/operators';
import { LabelType } from 'src/app/core/label/label.component';


interface OrgMember {
    selected?: boolean;
    isInvite: boolean;
    user: User;
    seats: { [productCode: string]: number };
    reservedSeats: { [productCode: string]: number };
    rowType: CommonColumnType;
    reservation?: OnboardingSubscriptionUserInvite;
}

@Component({
    selector: 'app-subscription-seats-allocation-list',
    templateUrl: './subscription-seats-allocation-list.component.html',
    styleUrls: ['./subscription-seats-allocation-list.component.scss']
})
export class SubscriptionSeatsAllocationListComponent extends CommonListComponent<OrgMember> implements OnInit {

    SubscriptionAllocationMode = SubscriptionAllocationMode;

    @Input()
    orgId: string;

    @Input()
    subscriptionId: string;

    @Input()
    allocationMode: SubscriptionAllocationMode;

    productColumns: {[productCode: string]: CommonListColumn} = {};

    _reservedSeats: OnboardingSubscriptionUserInvite[];

    @Input()
    set reservedSeats(val: OnboardingSubscriptionUserInvite[]) {
        this.reservedSeatsChange.emit(val);
        this._reservedSeats = val;
    }

    get reservedSeats() {
        return this._reservedSeats;
    }

    @Output()
    reservedSeatsChange = new EventEmitter<OnboardingSubscriptionUserInvite[]>();

    subAllocs: SubAllocationsResponse;
    private orgResponse: GetOrgResponse;
    products: Product[] = [];

    freeSeats: MultiCounters = {};
    totalSeats: MultiCounters = {};

    private _isValid: boolean = false;

    set isValid(val: boolean) {
        if(val != this._isValid) {
            this._isValid = val;
            this.isValidChange.emit(val);
        }
    }

    get isValid() {
        return this._isValid;
    }

    @Output()
    isValidChange = new EventEmitter<boolean>();

    private validateDebouncer: Subject<void> = new Subject<void>();


    constructor(injector: Injector, private seatService: SeatService, private orgService: OrganizationService, private productService: ProductService) {
        super(injector);
        this.doInitialRefresh = false;
        this.supportCompactMode = true;
        this.noPagination = true;

        this.rendererOptions.showSelectColumnsTable = false;
        this.rendererOptions.showSelectColumnsOption = false;
        this.rendererOptions.stickyHeader = true;
        this.rendererOptions.stickyFooter = true;
        this.rendererOptions.hidePagination = true;
        this.rendererOptions.showSearchBar = true;
        this.rendererOptions.searchPlaceholder = $localize`:@@org-members-search-bar-placeholder:Search members...`;
        this.rendererOptions.verticalScrolling = true;

        this.validateDebouncer
        .pipe(
            debounceTime(150)
        ).subscribe(() => this.validate());
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.rendererOptions.showColumnFooter = this.allocationMode == SubscriptionAllocationMode.Manual;

        this.selection.changed.subscribe((value) => {
            if(value.added) {
                value.added.forEach((member) => {
                    this.setSelectedMember(true, member);
                });
            }

            if(value.removed) {
                value.removed.forEach((member) => {
                    this.setSelectedMember(false, member);
                });
            }
        });

        this.seatService.getSubscriptionAllocations(this.subscriptionId).subscribe((response) => {
            this.subAllocs = response;
            this.freeSeats = Object.assign({}, this.subAllocs.freeSeats || {});
            this.totalSeats = Object.assign({}, this.subAllocs.freeSeats || {});
            
            if (!this.reservedSeats) {
                this.reservedSeats = [];
            }

            if(this.allocationMode == SubscriptionAllocationMode.Manual) {
                this.productService.getProducts().then((res) => {
                    this.products = res.filter(p => {
                        const shortCode = p.shortCode.toLowerCase();
                        return !!this.subAllocs.totalSeats[shortCode];
                    });

                    this.validateDebouncer.next();

                    this.initColumns();
                    this.refreshItems();
                });
            }
            else {
                this.initColumns();
                this.refreshItems();
            }

        }, (err) => {
            console.error(err);
        });
    }

    initColumns() {
        this.initPersistentKey('applogic/seat/org-seats-allocation');

        this.addColumn("", "select", true, true, CommonColumnType.Selection);
        const emailColumn = this.addColumn($localize`:@@common-email: Email `, "user.email", true, !this.compactRow, CommonColumnType.RowType, {
            searchable: true,
            rowTypeKey: 'rowType',
            data: {
                inputPlaceholder: $localize`:@@common-email-address:Email address`,
                labelType: LabelType.Ellipsis
            },
            formControlValidatorType: FieldValidationType.Email
        });

        const fullNameColumn = this.addColumn($localize`:@@common-fullname: Full Name `, "user.fullName", true, !this.compactRow, CommonColumnType.Text, {
            searchable: true,
            footerLabel: $localize`:@@common-available-tokens:Available Tokens`,
            footerStyles: {
                "display": "flex",
                "flex-direction": "row-reverse"
            },
        });

        if(this.compactRow) {
            this.addColumn(emailColumn.label + " / " + fullNameColumn.label, "compactName", true, this.compactRow, CommonColumnType.MergeColumns, {
                footerLabel: $localize`:@@common-available-tokens:Available Tokens`,
                footerStyles: {
                    "display": "flex",
                    "flex-direction": "row-reverse"
                },
                mergeColumns: [emailColumn, fullNameColumn]
            });
        }

        for (let i = 0; i < this.products.length; i++) {
            const product = this.products[i];
            const columnKey = 'product_' + product.shortCode.toLowerCase();
            this.addColumn(product.shortCode, columnKey, true, true, CommonColumnType.Template, {
                isSortable: false,
                fixedWidth: "75px",
                cellStyles: {
                    'text-align': 'center',
                    'display': 'grid'
                },
                headerStyles: {
                    "display": "flex",
                    "align-items": "center",
                    "justify-content": "center"
                },
                columnHeaderType: CommonColumnHeaderType.Template,
                columnFooterType: CommonColumnHeaderType.Template,
                templateColumnIdx: 0,
                templateFooterIdx: 1,
                data: {
                    productCode: product.shortCode.toLowerCase(),
                    product: product
                }
            });
        }

        // this.addColumn("", "actions", true, true, CommonColumnType.Action);
        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        const seats: MultiCounters = {};
        const seatsStr = JSON.stringify(seats);

        for (const product of this.products) {
            seats[product.shortCode.toLowerCase()] = 0;
        }

        this.orgService.getOrg(this.orgId, true).subscribe((response) => {
            this.orgResponse = response;

            let members = response.org.staffMembers.map(s => {
                let result: OrgMember = {
                    isInvite: false,
                    user: s.user,
                    seats: JSON.parse(seatsStr),
                    reservedSeats: JSON.parse(seatsStr),
                    rowType: CommonColumnType.Label,
                    reservation: this._reservedSeats.find(a => a.user.id == s.user.id)
                };

                if(result.reservation) {
                    result.seats = JSON.parse(JSON.stringify(result.reservation.seats));
                    result.reservedSeats = result.reservation.seats;
                    this.setSelectedMember(true, result);
                }

                return result;
            });

            const invitedMembers = this._reservedSeats.filter(r => !(!!r.user.id)).map(r => {
                let result: OrgMember = {
                    isInvite: true,
                    user: r.user as any,
                    seats: JSON.parse(JSON.stringify(r.seats)),
                    reservedSeats: r.seats,
                    rowType: CommonColumnType.FormControlInput,
                    reservation: r
                };

                this.setSelectedMember(true, result);

                return result;
            });
            
            const allMembers = [...invitedMembers, ...members];

            // Set the initial isValid state.
            this.validateDebouncer.next();

            this.setPreloadItems(allMembers);
        });
    }

    inviteNewMember() {
        const user = new User();
        user.fullName = "";
        user.email = "";

        const seats: MultiCounters = {};

        for (const product of this.products) {
            seats[product.shortCode.toLowerCase()] = 0;
        }

        const member: OrgMember = {
            isInvite: true,
            user,
            seats,
            reservedSeats: JSON.parse(JSON.stringify(seats)),
            rowType: CommonColumnType.FormControlInput
        };

        this.addItem(member, 0);
        this.setSelectedMember(true, member);
    }

    getUserName(row: any, userId: string) {

        const user = row.user;
        if (user) {
            return user.fullName + " (" + user.email + ")";
        }

        return userId;
    }

    onQuantityChanged(counter: MultiCounters, member: OrgMember, product: Product) {
        const shortCode = product.shortCode.toLowerCase();
        const newSeatsValue = member.seats[shortCode] || 0;
        const currentSeatsValue = member.reservedSeats[shortCode] || 0;
        const delta = newSeatsValue - currentSeatsValue;
        member.reservedSeats[shortCode] = newSeatsValue;
        
        if(member.reservation) {
            this.freeSeats[shortCode] -= delta;

            // To avoid having a negative value.
            if(this.freeSeats[shortCode] < 0) {
                let adjustement = -this.freeSeats[shortCode];
                if(adjustement > newSeatsValue) {
                    adjustement = newSeatsValue;
                }

                if(member.seats[shortCode] == undefined) {
                    member.seats[shortCode] = 0;
                }
                if(member.reservedSeats[shortCode] == undefined) {
                    member.reservedSeats[shortCode] = 0;
                }
                this.freeSeats[shortCode] += adjustement;
                member.seats[shortCode] -= adjustement;
                member.reservedSeats[shortCode] -= adjustement;
            }

            this.validateDebouncer.next();
        }
    }

    /**
     * Must be called to change or initialize selected items.
     */
    setSelectedMember(checked: boolean, member: OrgMember) {
        if(!!member.selected == checked) {
           return; 
        }

        member.selected = checked;

        if(member.selected) {
            if(!this.selection.isSelected(member)) {
                this.selection.select(member);
            }
        }
        else {
            if(this.selection.isSelected(member)) {
                this.selection.deselect(member);
            }
        }

        if(checked) {
            if(!member.reservation) {
                member.reservation = {
                    user: member.user,  // Bind the object.
                    seats: member.reservedSeats
                };

                this._reservedSeats.push(member.reservation);

                this.validateDebouncer.next();
            }
        }
        else {
            if(member.reservation) {
                const idx = this._reservedSeats.indexOf(member.reservation);
                this._reservedSeats.splice(idx, 1);
                member.reservation = undefined;
                this.validateDebouncer.next();
            }

            if(!member.user.id) {
                this.removeItem(member);
            }
        }

        for (const product of this.products) {
            const productKey = product.shortCode.toLowerCase();
            if (!member.reservedSeats[productKey]) {
                member.reservedSeats[productKey] = 0;
                // this.setRowValue(this.productColumns[productKey], member, 0);
                this.validateDebouncer.next();
            }
            else {
                if(member.selected) {
                    this.freeSeats[productKey] -= member.reservedSeats[productKey];
                    // this.setRowValue(this.productColumns[productKey], member, member.reservedSeats[productKey]);
                    this.validateDebouncer.next();
                }
                else {
                    this.freeSeats[productKey] += member.reservedSeats[productKey];
                    // this.setRowValue(this.productColumns[productKey], member, 0);
                    this.validateDebouncer.next();
                }
            }
        }
    }

    private validate() {
        let isValid = this._reservedSeats && (this._reservedSeats.length > 0) && (this.areControlsValid || !this.formArray);

        if(isValid && (this.allocationMode == SubscriptionAllocationMode.Manual)) {
            isValid = false;
            for(const product of this.products) {
                const productCode = product.shortCode.toLowerCase();
                let allocated = (this.totalSeats[productCode] || 0) - (this.freeSeats[productCode] || 0);
                if(allocated > 0) {
                    isValid = true;
                    break;
                }
            }
        }

        this.isValid = isValid;
        return isValid;
    }

    onFormStatusChanged(column: CommonListColumn, control: AbstractControl, status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED') {
        super.onFormStatusChanged(column, control, status);
        this.validate();
    }

    onCompactModeChanged() {
        if(this.products) {
            this.initColumns();
        }
    }
}
