import { Model, ID, Member, ArrayMember } from "@uon/model";

export class CommonModel {

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;
}

export enum ModelDeleteType {
    SoftDelete = 'soft-delete',
    HardDelete = 'hard-delete'
}