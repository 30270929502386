import { ArrayMember, Member, Model } from "@uon/model";
import { SubscriptionSeat } from "../subscription/subscription-seat.model";
import { Student } from "../user/student.model";
import { Classroom } from "../org/classroom.model";

/**
 * To contains a classrooms and their corresponding students's seats.
 * 
 * This structure is to be used with a route response.
 */
@Model()
export class ClassroomResponse {

    @Member()
    public classroom: Classroom;

    @ArrayMember(SubscriptionSeat)
    public seats: SubscriptionSeat[];

    /**
     * Associate seats with students.
     * 
     * Could be done in the server side. But let the client handle a little bit more of processing.
     * The seats are distribute to the Student.subscriptionSeats properties.
     */
    public allocateSeatsToStudents() {

        let studentDict: { [key: string]: Student; } = {};

        for (let i = 0; i < this.classroom.students.length; i++) {
            let student = this.classroom.students[i];
            studentDict[student.id] = student;

            if(!student.subscriptionSeats) {
                student.subscriptionSeats = [];
            }
        }

        for (let i = 0; i < this.seats.length; i++) {
            let seat = this.seats[i];

            let student = studentDict[seat.studentId];

            student.subscriptionSeats.push(seat);
        }
    }

    public addSeat(seat: SubscriptionSeat) {

        let studentDict: { [key: string]: Student; } = {};

        if(!seat.studentId) return;

        let student = this.classroom.students.find(s => s.id == seat.studentId);

        if(student) {
            if(!student.subscriptionSeats) student.subscriptionSeats = [seat];
            else student.subscriptionSeats.push(seat);
        }
    }
}


/**
 * 
 */
 @Model()
 export class ClassroomStudentsImportResponse {

    @ArrayMember(Student)
    students: Student[];

    @ArrayMember(String)
    nameAlreadyTaken: string[];
 }