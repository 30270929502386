import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Classroom, Organization } from '@applogic/model';
import { AccountService } from 'src/app/account/account.service';
import { FormService } from 'src/app/services/form.service';
import { ClassroomService } from '../classroom.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { AuthService } from 'src/app/auth/auth.service';
import { Model } from '@uon/model';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { AngularUtils } from 'src/app/services/angular-utils';


@Component({
    selector: 'app-create-classroom-dialog',
    templateUrl: './create-classroom-dialog.component.html',
    styleUrls: ['./create-classroom-dialog.component.scss']
})
export class CreateClassroomDialogComponent implements OnInit {

    errorObject: any;

    classroomForm1: UntypedFormGroup;

    public createdClassroom: Classroom;

    public currentOrg: Organization;

    protected currentStep: number = 1;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private mdDialogRef: MatDialogRef<CreateClassroomDialogComponent>,
        private classroomService: ClassroomService,
        public formService: FormService,
        readonly account: AccountService,
        private router: Router,
        readonly loading: LoadingService,
        private authService: AuthService) {
        }

    ngOnInit(): void {
        this.classroomForm1 = new UntypedFormGroup({
            name: this.formService.getFormControl("classroomName")
        });
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, config: AppDialogConfig = {}) {

        return new AppDialogRef<CreateClassroomDialogComponent, void, boolean>(dialogService, dialog, CreateClassroomDialogComponent, {
            disableClose: true,
            position: {
                top: '52px'
            },
            maxWidth: '600px',
            minWidth: '300px',
            maxHeight: 'calc(100vh - 64px)'
        }, config);
    }

    completeClassroomForm(redirect: boolean = false) {

        this.mdDialogRef.close();

        if (redirect) {
            ApplogicUtils.navigateToClassroomPage(this.router, this.createdClassroom);
        }


    }

    submitClassroomForm() {
        this.errorObject = undefined;
        const classroom = new Classroom();
        Model.Assign(classroom, this.classroomForm1.value);

        this.classroomService.createClassroom(this.currentOrg, classroom)
            .subscribe((classroom) => {

                this.createdClassroom = classroom;
                
                this.completeClassroomForm(true);
            }, (err) => {
                this.errorObject = err;
            });

    }

    getInfoNameErrorMessage(field: AbstractControl) {

        if (!field.touched) {
            return '';
        }
        if (field.hasError('required')) {
            return $localize`:@@form-field-required-message:This field is required`;
        }
        if (field.hasError('minlength')) {
            return $localize`:@@form-field-name-minlength-message:Must be at least 5 characters`;
        }
        if (field.hasError('maxlength')) {
            return 'Name must be at most 64 characters';
        }

        return '';
    }

    prevStep() {
        this.currentStep--;
    }

    nexStep() {
        this.currentStep++;
    }

    showDebugs(event: any) {
        AngularUtils.showFormGroupState("Classroom", this.classroomForm1);
    }
}
