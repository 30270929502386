import { Component, HostBinding } from '@angular/core';
import { PageComponent, PageType } from '../../page.component';
import { DialogService } from 'src/app/services/dialog.service';
import { FADE_IN_OUT, OPACITY_IN_OUT } from 'src/app/animations/fade';
import { Subscription as RxSubscription } from 'rxjs';


@Component({
    selector: 'app-page-content',
    templateUrl: './page-content.component.html',
    styleUrl: './page-content.component.scss',
    host: {
        '[class.app-page-content]': 'true'
    },
    animations: [FADE_IN_OUT, OPACITY_IN_OUT]
})
export class PageContentComponent {

    show: boolean = true;

    private showPageContentSupbscription: RxSubscription;

    @HostBinding('@opacityInOut') get animationState() {
        return this.show ? 'true' : 'false';
    }

    @HostBinding('class') get getHostClass() {
        switch(this.page.type) {
            case PageType.Normal:
                return 'app-page--normal-content';
            case PageType.Centered:
                return 'app-page--centered-content';
            case PageType.Full:
                return 'app-page--full-content';
            case PageType.CenteredFullWidth:
                return 'app-page--centered-full-width-content';
        }
    }


    constructor(protected page: PageComponent, dialogService: DialogService) {
        this.showPageContentSupbscription = dialogService.showPageContent.subscribe(show => {
            this.show = show;
        });
    }

    ngOnDestroy() {
        if(this.showPageContentSupbscription) {
            this.showPageContentSupbscription.unsubscribe();
        }
    }
}
