import { Subscription } from "./subscription.model";
import { SubscriptionAllocation } from "./subscription-allocation.model";
import { ArrayMember, Member, Model } from "@uon/model";
import { User } from "../user/user.model";
import { MultiCounters, MultiCountersUtils } from "../common/multi-counters";

/**
 * To contains a subscription with all its allocations.
 * 
 * This structure is to be used with a route response.
 */
@Model()
export class SubAllocationsResponse {

    @Member()
    public subscription: Subscription;

    @ArrayMember(SubscriptionAllocation)
    public allocations: SubscriptionAllocation[];

    @ArrayMember(User)
    public users: User[];

    @Member()
    usedSeats: MultiCounters;

    @Member()
    freeSeats: MultiCounters;

    @Member()
    totalSeats: MultiCounters;

    /**
     * Update the counters value that require the allocations list to be filled.
     * 
     * Can be used on the client side if the computed informations are required.
     */
    public update() {
        this.usedSeats = {};
        this.freeSeats = {};
        this.totalSeats = this.subscription.getTotalSeats();

        for(const key in this.totalSeats) {
            this.freeSeats[key] = 0;
            this.usedSeats[key] = 0;
        }

        for(let i=0; i<this.allocations.length; i++) {
            let alloc = this.allocations[i];
            MultiCountersUtils.add(alloc.usedSeats, this.usedSeats);
            MultiCountersUtils.add(alloc.freeSeats, this.freeSeats);
        }
    }

    /**
     * Remove an allocation and update the default SubscriptionAllocation.
     * 
     * @param {SubscriptionAllocation} allocation The allocation to remove.
     * 
     */
    public removeAllocation(allocation: SubscriptionAllocation) {
        const index = this.allocations.indexOf(allocation);
        if (index != -1) {
            this.allocations.splice(index, 1);
        }

        let subAlloc = this.getDefaultAlloc();

        if( (subAlloc) && (subAlloc != allocation) ) {
            if(allocation.freeSeats) {
                if(!subAlloc.freeSeats) subAlloc.freeSeats = {};
                MultiCountersUtils.move(allocation.freeSeats, subAlloc.freeSeats, allocation.freeSeats);
            }

            if(allocation.usedSeats) {
                if(!subAlloc.usedSeats) subAlloc.usedSeats = {};
                MultiCountersUtils.move(allocation.usedSeats, subAlloc.usedSeats, allocation.usedSeats);
            }
        }
    }

    /**
     * Add an allocation.
     * 
     * @param {SubscriptionAllocation} allocation The allocation to add.
     * 
     */
     public addAllocation(allocation: SubscriptionAllocation) {
        this.allocations.push(allocation);
    }

    /**
     * Retrieve the default SubscriptionAllocation for this subscription.
     * 
     * @returns {SubscriptionAllocation} Returns the default SubscriptionAllocation.
     */
    getDefaultAlloc(): SubscriptionAllocation {
        if (!this.allocations) return;

        for (let i = 0; i < this.allocations.length; i++) {
            let alloc = this.allocations[i];
            if (alloc.filter.subscriptionId) {
                return alloc;
            }
        }
    }
}