<app-common-list-renderer [list]="list">
    <renderer-templates [emptyTemplate]="emptyStudentList"
                        [templates]="[productSeatTemplate, studentAvatar, compactNameTemplate, tokensTemplate]"
                        [templateColumns]="[productSeatColumnTemplate]"
                        [actionMenuTemplate]="actionTemplate"></renderer-templates>
</app-common-list-renderer>

<ng-template #emptyStudentList>
    <p *ngIf="students.length == 0"
       style="text-align: center;">
        <em class="secondary-text"
            i18n="@@classroom-no-students-message">
            Your class is empty
        </em>
        <br>
        <br>
    </p>
</ng-template>

<ng-template #productSeatTemplate
             let-data>
    <div *ngIf="data?.row.subscriptionSeats"
         class="tokens-section">
        <app-seat-token *ngFor="let seat of getSeats(data?.row.subscriptionSeats, data?.column.parameters.productCode)"
                        [displayType]="'dot-only'"
                        [expiresOn]="seat.expiresOn"
                        [productCode]="seat.productId"></app-seat-token>
    </div>
</ng-template>

<ng-template #productSeatColumnTemplate
             let-data>
    <app-seat-token [displayType]="'icon-only'"
                    [productCode]="data?.column.parameters.productCode"></app-seat-token>
</ng-template>

<ng-template #studentAvatar
             let-data>
    <div class="avatar">
        <app-user-avatar [user]="data.row">
        </app-user-avatar>
    </div>
</ng-template>

<ng-template #compactNameTemplate
             let-data>
    <div class="student-info">
        <span>
            {{ data.row.fullName }}
        </span>
        <small class="secondary-text">
            {{ data.row.username }}
        </small>
    </div>
</ng-template>


<ng-template #tokensTemplate
             let-data>
    <div *ngIf="data.row.subscriptionSeats"
         class="tokens-section">
        <app-seat-token *ngFor="let seat of data.row.subscriptionSeats"
                        [displayType]="'icon-only'"
                        [productCode]="seat.productId"
                        [expiresOn]="seat.expiresOn"></app-seat-token>
    </div>
</ng-template>