import { Injectable } from '@angular/core';
import { Organization, Subscription, OrganizationInvite, Invoice, OrganizationMember, RoutesServer, ApiRoutes, CommonQueryFilter, CommonQueryResponse, FindOrgQuery, FindOrgResponse, GetOrgResponse, SendInvitesResponse, UpdateOrgQuery } from '@applogic/model';
import { JsonSerializer } from '@uon/model';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HandleErrorDialogService } from '../services/handle-error-dialog.service';
import { SiteEventService } from '../event/site-event.service';
import { InviteUserSentEvent } from '../event/events/invite-user-sent.event';
import { SiteEvents } from '../event/site-events';
import { ApiDirectoryService } from '../services/api-directory.service';
import { HttpParamsFromModelProperties, modelToHttpParams } from '../services/angular-utils';

const INVITE_SERIALIZER = new JsonSerializer(OrganizationInvite);
const SERIALIZER = new JsonSerializer(Organization);
const SUB_SERIALIZER = new JsonSerializer(Subscription);
const INVOICE_SERIALIZER = new JsonSerializer(Invoice);
const ORGANIZATION_MEMBER_SERIALIZER = new JsonSerializer(OrganizationMember);
const FIND_ORG_RESPONSE_SERIALIZER = new JsonSerializer(FindOrgResponse);
const GET_ORG_RESPONSE_SERIALIZER = new JsonSerializer(GetOrgResponse);
const SEND_INVITES_RESPONSE_SERIALIZER = new JsonSerializer(SendInvitesResponse);

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    constructor(private errorDialogService: HandleErrorDialogService,
        private eventService: SiteEventService,
        private dirService: ApiDirectoryService) { }


    listOrgs(): Observable<Organization[]> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, '', { withCredentials: true })
            .pipe(
                map((r: any[]) => r.map(v => SERIALIZER.deserialize(v, true))),
                catchError(err => { return of([]) })
            );
    }

    searchOrgs(filter: CommonQueryFilter) {
        let str = filter.toString2();

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, "/search" + str, {
            withCredentials: true
        }).pipe(
            map((r: any) => {
                let response = new CommonQueryResponse<Organization>();
                response.count = r.count;
                response.result = r.result.map(s => SERIALIZER.deserialize(s, true));
                return response;
            })
        );
    }

    listSubscriptions(orgId: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Subscription, `/list/${orgId}`, { withCredentials: true })
            .pipe(
                map((r: any[]) => r.map(v => SUB_SERIALIZER.deserialize(v, true)))
            );

    }

    removeMember(orgId: string, userId: string) {

        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}/staff/${userId}`, { withCredentials: true });
    }

    getOrg(id: string, details: boolean = true): Observable<GetOrgResponse> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/${id}?details=${details}`, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    return GET_ORG_RESPONSE_SERIALIZER.deserialize(r, true);
                }),
                catchError(err => {
                    const STATUS_CODE = err?.error?.code;
                    if (STATUS_CODE === 403 || STATUS_CODE === 404) {
                        let message: string = "";
                        if (STATUS_CODE === 403) {
                            message = $localize`:@@org-details-access-error-message:You don't have access to this organization`;
                        } else if (STATUS_CODE === 404) {
                            message = $localize`:@@org-details-delete-error-message:This organization no longer exists.`;
                        }
                        this.errorDialogService.openErrorDialog(message, err, true);
                    }
                    return throwError(err);
                })
            );
    }

    findOrg(query: FindOrgQuery) {
        const httpParams = modelToHttpParams(query);
        
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/find`, {
            params: httpParams,
            withCredentials: true })
        .pipe(
            map((r: any) => {
                return FIND_ORG_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );   
    }

    getMember(orgId: string, userId: string): Observable<OrganizationMember> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}/member/${userId}`, { withCredentials: true })
            .pipe(
                map((r: any) => {
                    return ORGANIZATION_MEMBER_SERIALIZER.deserialize(r, true);
                }),
                catchError(err => {
                    const STATUS_CODE = err?.error?.code;
                    if (STATUS_CODE === 403 || STATUS_CODE === 404) {
                        let message: string = "";
                        if (STATUS_CODE === 403) {
                            message = $localize`:@@org-details-access-error-message:You don't have access to this organization`;
                        }
                        this.errorDialogService.openErrorDialog(message, err, true);
                    }
                    return throwError(err);
                })
            );
    }

    createOrg(org: Organization) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Organization, '', org, { withCredentials: true }).pipe(
            map((r: any) => SERIALIZER.deserialize(r, true))
        );
    }

    updateOrg(orgId: any, value: UpdateOrgQuery) {
        
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}`, value, { withCredentials: true });
    }

    getInvoices(id: string): Observable<Invoice[]> {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/${id}/Invoices`, { withCredentials: true })
            .pipe(
                map((r: any[]) => r.map(v => INVOICE_SERIALIZER.deserialize(v, true)))
            );
    }

    getInvites(id: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/${id}/invites`, { withCredentials: true })
            .pipe(
                map((r: any[]) => r.map(i => INVITE_SERIALIZER.deserialize(i, true)))
            );
    }

    sendInvites(orgId: string, role: string, emails: string[]) {        
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}/invite`, { role, emails }, { withCredentials: true }).pipe(
            map((r: any) => {
                let event: InviteUserSentEvent = {
                    orgId,
                    role,
                    emails
                };
                
                this.eventService.notify(SiteEvents.Organization.Category, SiteEvents.Organization.Events.InviteUser, event);
                
                return SEND_INVITES_RESPONSE_SERIALIZER.deserialize(r, true);
            }));
    }

    removeInvite(id: string, inv: OrganizationInvite) {
        return this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Organization, `/${id}/invite/${inv.id}`, { withCredentials: true });
    }

    changeInviteRole(role: string, orgId: string, inviteId: string) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}/invite/${inviteId}/updateRole`, { role }, { withCredentials: true });
    }

    resendInvites(id: string, inviteId: string) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Organization, `/${id}/invite/${inviteId}`, {}, { withCredentials: true });
    }

    changeMemberRole(role: string, orgId: any, userId: any) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}/staff/${userId}`, { role }, { withCredentials: true }).pipe(
            catchError(err => { return of(['error']) })
        );;
    }

    getCounts(orgId: string, userId: string) {
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Organization, `/${orgId}/staff/${userId}`, { withCredentials: true });
    }
}


@Injectable({
    providedIn: 'root'
})
export class OrgListResolverService  {

    constructor(private orgs: OrganizationService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization[]> {
        return this.orgs.listOrgs();
    }
}


@Injectable({
    providedIn: 'root'
})
export class OrgResolverService  {

    constructor(private orgs: OrganizationService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GetOrgResponse> {
        const id = route.params.id;
        return this.orgs.getOrg(id);
    }
}
