import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-product-icon',
    templateUrl: './product-icon.component.html',
    styleUrls: ['./product-icon.component.scss']
})
export class ProductIconComponent implements OnInit {

    @Input() productCode: string;
    @Input() size: number = 40;
    
    backgroundStyle: any = {};

    constructor(private productService: ProductService) { }

    ngOnInit(): void {
        this.productService.getProduct(this.productCode).then((product) => {
            this.updateBackgroundStyle(product.imageUrl);
        });
    }

    updateBackgroundStyle(imageUrl: string) {

        const style: any = {
            width: `${this.size}px`,
            height: `${this.size}px`,
            flexBasis: `${this.size}px`,
            fontSize: `${Math.floor(this.size / 2) - 5}px`

        };

        let backgroundUrl: string = imageUrl;

        style.backgroundImage = "url('" + backgroundUrl + "')";
        style.fontWeight = "bold";
        style.textShadow = "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;";
        style.color = "black";

        this.backgroundStyle = style;
    }    
}
