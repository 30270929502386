import { Injectable } from '@angular/core';
import { ApiRoutes, CreateCheckoutResponse, Currency, GetCheckoutResponse, GetOrderFormQuery, OrderForm, PaymentMethod, PriceTable, RoutesServer, Subscription } from '@applogic/model';
import { JsonSerializer, Model } from '@uon/model';
import { map } from 'rxjs/operators';
import { ApiDirectoryService } from '../services/api-directory.service';
import { HttpParamsFromModelProperties } from '../services/angular-utils';

const PAYMENT_METHODS_SERIALIZER = new JsonSerializer(PaymentMethod);
const CREATE_CHECKOUT_RESPONSE = new JsonSerializer(CreateCheckoutResponse);
const GET_CHECKOUT_RESPONSE = new JsonSerializer(GetCheckoutResponse);

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(private dirService: ApiDirectoryService) { }

    /**
     * Create a "Stripe Checkout Session" or a PaymentIntentent client secret for Stripe Elements depends on the OrderForm.mode or server default mode.
     * 
     * @param {OrderForm} sendData The order form details.
     */
    createCheckout(sendData: OrderForm): Promise<CreateCheckoutResponse> {
        return new Promise((resolve, rejects) => {
            this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Order, `/checkout`, sendData, { withCredentials: true }).subscribe({
                next: res => {
                    const response = CREATE_CHECKOUT_RESPONSE.deserialize(res, true);
                    resolve(response);
                },
                error: (error) => {
                    rejects(error);
                }
            })
        });
    }

    deleteCheckout(sessionId: string) {
        return new Promise((resolve, rejects) => {
            this.dirService.serverDelete(RoutesServer.Api, ApiRoutes.Order, `/checkout/` + sessionId, { withCredentials: true }).subscribe(res => {
                resolve(res);
            }, (error) => {
                rejects(error);
            })
        });
    }

    getCheckoutInfo(checkoutSessionId: string) {
        return this.getOrderForm({ checkoutSessionId });
    }

    /**
     * Get the form session.
    */
    getOrderForm(query: GetOrderFormQuery): Promise<GetCheckoutResponse> {
        const httpParams = HttpParamsFromModelProperties(GetOrderFormQuery, query);

        return new Promise((resolve, rejects) => {
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Order, `/form`, {
                params: httpParams,
                withCredentials: true
            }).subscribe((res: any) => {
                resolve(GET_CHECKOUT_RESPONSE.deserialize(res, true));
            }, (error) => {
                rejects(error);
            })
        });
    }

    getPaymentMethods(paymentTarget: Subscription|Currency) {
        let queryParams: string = this.getQueryParamsForPaymentTarget(paymentTarget);

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Order, `/paymentMethods${queryParams}`, { withCredentials: true }).pipe(
            map((val: any) => {
                const paymentMethods: PaymentMethod[] = val.paymentMethods.map(s => PAYMENT_METHODS_SERIALIZER.deserialize(s, true));

                const result: {paymentMethods: PaymentMethod[], defaultPayementMethod: string} = {
                    paymentMethods,
                    defaultPayementMethod: val.defaultPayementMethod
                }
                
                return result
            })
        );
    }
    
    setPaymentMethod(paymentMethodId: string, stripeSubscriptionId: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Order, `/paymentMethod`, {paymentMethodId, stripeSubscriptionId}, { withCredentials: true }).pipe(
            map((val: any) => {
                return val
            })
        );
    }

    setPaymentMethodForSubscription(paymentMethodId: string, subscriptionId: string) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Order, `/paymentMethod`, {paymentMethodId, subscriptionId}, { withCredentials: true }).pipe(
            map((val: any) => {
                return val
            })
        );
    }

    initAddPaymentMethod(paymentTarget: Subscription|Currency) {

        let queryParams: string = this.getQueryParamsForPaymentTarget(paymentTarget);

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Order, `/addPaymentMethod${queryParams}`, { withCredentials: true }).pipe(
            map((val: any) => {
                const response: {secret: string} = {secret: val.secret};
                return response
            })
        );
    }

    getQueryParamsForPaymentTarget(paymentTarget: Subscription|Currency) {
        let queryParams: string = "?";

        if(paymentTarget instanceof Subscription) {
            queryParams += "subscriptionId=" + (paymentTarget as Subscription).id;
        }
        else if(paymentTarget instanceof Currency) {
            queryParams += "currency=" + (paymentTarget as Currency).code;
        }

        return queryParams;
    }
}
