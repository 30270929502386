import { Injectable } from '@angular/core';
import { OrderForm, SubscriptionType } from '@applogic/model';
import { SelectableProduct } from '../order/base-payment-form/selectable-product';
import { AnalyticsService } from './analytics.service';
import { ProductService } from './product.service';

/**
 * This service manage more specific events for the AnalyticsService.
 */
@Injectable({
    providedIn: 'root'
})
export class AnalyticsEventsService {

    constructor(private analyticsService: AnalyticsService,
        private productService: ProductService) {

    }

    /**
     * Send the "play_button_click" event.
     */
    sendPlayButtonClick(productCode: string, section: string) {
        this.analyticsService.sendEvent("portal_play_button", {
            product_code: productCode.toLowerCase(),
            section
        });
    }

    /**
     * Send the "subscribe_button" event.
     * 
     * The event of the "subscribe" button of the orders forms.
     */
     sendSubscribeButtonClick(orderForm: OrderForm, subtotal: number) {
        let eventParams: any = {

        };

        this.fillCommonPurchaseCheckout(eventParams, orderForm);

        eventParams.value = subtotal;

        this.analyticsService.sendEvent("subscribe_button", eventParams);
    }

    /**
     * Send the "cancel_checkout" event.
     */
    sendCancelCheckoutEvent(orderForm: OrderForm) {
        let eventParams: any = {

        };

        this.fillCommonPurchaseCheckout(eventParams, orderForm);

        this.analyticsService.sendEvent("purchase_cancelled", eventParams);
    }

    /**
     * Send the google "begin_checkout" event.
     * 
     * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
     */
    sendBeginCheckoutEvent(orderForm: OrderForm, products: SelectableProduct[], subtotal: number) {
        let eventParams: any = {

        };

        this.fillCommonPurchaseCheckout(eventParams, orderForm, products);

        eventParams.value = subtotal;

        this.analyticsService.sendEvent("begin_checkout", eventParams);
    }

    /**
     * Send the google "purchase" event.
     * 
     * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
     */
    sendPurchaseEvent(orderForm: OrderForm, session_id: string) {
        // TODO: Use the transition id of stripe instead of session id.

        let eventParams: any = {
            transaction_id: session_id,
        };

        this.fillCommonPurchaseCheckout(eventParams, orderForm);

        eventParams.affiliation = this.getAffiliation(orderForm.subscriptionType);
        this.analyticsService.sendEvent("purchase", eventParams);
    }

    /**
     * Set common informations for the google "purchase" and "begin_checkout" events.
     */
    private fillCommonPurchaseCheckout(eventParams: any, orderForm: OrderForm, products?: SelectableProduct[]) {
        eventParams.subscription_type = orderForm.subscriptionType;
        eventParams.subscription_plan = orderForm.subscriptionPlan;
        eventParams.renew = orderForm.autorenew;

        if(orderForm.promoCodeTicket?.code) {
            eventParams.coupon = orderForm.promoCodeTicket?.code;
        }

        orderForm.products.forEach((p) => {
            eventParams[p.code.toLowerCase()] = p.seats;
        });

        if (orderForm.priceTable) {
            let taxAmount = orderForm.priceTable.getTaxAmount();
            if (taxAmount) eventParams.tax = taxAmount;

            eventParams.value = orderForm.priceTable.subtotal;

            let eventItems: any[] = [];
            orderForm.priceTable.products.forEach((p) => {
                let eventItem: any = {
                    item_id: "SKU_STRIPE_" + eventParams.subscription_type.toUpperCase() + "_"  + eventParams.subscription_plan.toUpperCase() + "_" + p.productCode.toUpperCase(),
                    item_name: this.productService.getGameName(p.productCode),
                    currency: orderForm.priceTable.currency,
                    price: p.unitPrice,
                    quantity: p.seats
                };

                eventItems.push(eventItem);
            });

            eventParams["items"] = eventItems;
        }
        else if(products) {
            let eventItems: any[] = [];

            orderForm.products.forEach((p) => {
                let eventItem: any = {
                    item_id: "SKU_STRIPE_" + eventParams.subscription_type.toUpperCase() + "_"  + eventParams.subscription_plan.toUpperCase() + "_" + p.code.toUpperCase(),
                    item_name: this.productService.getGameName(p.code)
    
                };
    
                let selectableProduct: SelectableProduct = products.find(sp => sp.code == p.code);
    
                if (selectableProduct) {
                    eventItem.currency = selectableProduct.price?.amount?.currency?.toUpperCase();
                    eventItem.price = selectableProduct.price?.amount?.value;
                    eventItem.quantity = p.seats;
                    eventItems.push(eventItem);
                } else {
                    console.error("Unable to retrieve the selectable product for " + p.code);
                }
            });
    
            eventParams["items"] = eventItems;
        }

        eventParams.affiliation = this.getAffiliation(orderForm.subscriptionType);
        eventParams.currency = 'CAD';

        if (orderForm?.googlePlaceId) {
            eventParams.location_id = orderForm.googlePlaceId;
        }
    }

    private getAffiliation(subscriptionType: SubscriptionType) {
        if (subscriptionType == SubscriptionType.organization) {
            return "portal-organizations";
        }

        return "portal-individuals";
    }
}
