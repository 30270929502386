import { Injectable } from '@angular/core';
import { ApiRoutes, CreateQuotationResponse, RoutesServer, SubscriptionPlan } from '@applogic/model';
import { ApiDirectoryService } from '../services/api-directory.service';
import { cleanupData } from '../core/form-group-def';
import { JsonSerializer } from '@uon/model';

const CREATE_QUOTATION_RESPONSE_SERIALIZER = new JsonSerializer(CreateQuotationResponse);

@Injectable({
    providedIn: 'root'
})
export class RequestQuotationService {

    constructor(private dirService: ApiDirectoryService) { }

    /**
     * CREATE QUOTATION
     * @param data 
     */
    createQuotation(data: any): Promise<CreateQuotationResponse> {

        return new Promise((resolve, rejects) => {
            this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Quotation, ``, cleanupData(data), { withCredentials: true }).subscribe(res => {
                resolve(CREATE_QUOTATION_RESPONSE_SERIALIZER.deserialize(res));

            }, (error) => {

                rejects(error);

            })
        });
    }
}
