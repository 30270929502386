import { Model, Member } from "@uon/model";




@Model()
export class CurrencyAmount {

    @Member()
    value: number;

    @Member()
    currency: string;

    @Member()
    stripePriceId: string;

    constructor(value?: number, currency?: string) {
        this.value = value;
        this.currency = currency;
    }

    toString() {
        return `${(this.value / 100).toFixed(2)} ${this.currency}`; 
    }

}