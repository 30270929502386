<ng-content select="CommonListRendererOptionsComponent"></ng-content>


<ng-content select="app-common-list-renderer-templates"></ng-content>

<div *ngIf="list"
     [class]="'app-common-list--renderer-component app-common-list--' + style + '-style ' + (_options.tableComponentClasses?.join(' ') || '')">
    <app-error-message-area [isAdmin]="isAdmin"
                            [error]="list.errorObject"></app-error-message-area>
    
    <ng-content select="app-common-list-toolbar"></ng-content>

    <ng-container [ngSwitch]="displayMode">
        <app-common-list-default-display-mode *ngSwitchCase="CommonListDisplayMode.Default"
                                              [list]="list"
                                              [parent]="this"
                                              [templatesOptions]="templatesOptions"
                                              [isAdmin]="isAdmin"
                                              [contentOptions]="contentOptions"
                                              [options]="_options"></app-common-list-default-display-mode>
        <app-common-list-card-display-mode *ngSwitchCase="CommonListDisplayMode.Card"
                                           [list]="list"
                                           [parent]="this"
                                           [templatesOptions]="templatesOptions"
                                           [isAdmin]="isAdmin"
                                           [contentOptions]="contentOptions"
                                           [options]="_options"></app-common-list-card-display-mode>
    </ng-container>
</div>

<mat-menu #actionMatMenu="matMenu"
          overlapTrigger="false"
          xPosition="before">

    <ng-template matMenuContent
                 let-row="row">
        <ng-content [value]="row"
                    select="[menuitems]"></ng-content>
        <div *ngIf="templatesOptions?.actionMenuTemplate">
            <ng-template [ngTemplateOutlet]="templatesOptions?.actionMenuTemplate"
                         [ngTemplateOutletContext]="{$implicit: row}"></ng-template>
        </div>
        <!--
        <button mat-menu-item (click)="openSuggestionDetailsDialog(suggestion)">
            <mat-icon color="accent">info</mat-icon>
            <span>Show Details</span>
        </button>
        -->
    </ng-template>
</mat-menu>