<app-dialog [icon]="icon">
    <app-dialog-title>{{title}}</app-dialog-title>
    <app-dialog-message>{{message}}</app-dialog-message>
    <app-dialog-content>
        <div class="export-form">
            <br />
            <span>{{selectMessage}}</span>
            <br />
            <span class="export-buttons">
                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('csv')">
                    <span>
                        CSV
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('xlsx')">
                    <span>
                        XLSX
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('ods')">
                    <span>
                        ODS
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('html')">
                    <span>
                        HTML
                    </span>
                </button>

                <button mat-stroked-button
                        color="primary"
                        (click)="exportResultTable('json')">
                    <span>
                        JSON
                    </span>
                </button>
            </span>
        </div>
    </app-dialog-content>
</app-dialog>