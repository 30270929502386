import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoadingService } from '../services/loading.interceptor';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';
import { ApplogicUtils } from '../services/applogic-utils';


/**
 * AuthInterceptor class to intercept response informations related to the authentification.
 * 
 * This intercepter the header "x-auth-token-expires" that require to update the token expiration date.
 * This also intercept the 401 Unauthorized so that it redirect to the login page.
 * 
 * The AuthInterceptor cannot depends on AuthService since AuthService depends on the HttpHandler
 * and the HttpHandler depends on HttpInterceptors.
 * So that's why there are static methods in the AuthService. See Jira [AEP-904] for more informations.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector,
        private loading: LoadingService,
        private router: Router,
        private ls: LocalStorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {

                    try {
                        const expires_str = event.headers.get('x-auth-token-expires')
                        if (expires_str) {
                            let date = new Date(expires_str);
                            AuthService.updateExpiresStatic(this.ls, date.getTime());
                        }
                    } catch (ex) {
                        console.error(ex);

                        // Display some informations of the request and response that initiate the error.
                        let debug = "Request:";
                        debug += "\n\tMethod: " + request?.method;
                        debug += "\n\tUrl: " + request?.url;
                        debug += "\nResponse:";
                        debug += "\n\tStatus: " + event?.status;
                        debug += "\n\tstatusText: " + event?.statusText;

                        console.error("Previous error related request: " + debug);

                        setTimeout(() => {
                        location.reload();
                        }, 5000);
                    }
                }

                return event;
            }),
            catchError((err: any) => {
                this.loading.stopLoading("http");

                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        // Clear the user authentication otherwise there is no use to
                        // redirect to the login page since it will redirect it again here. See AEP-123
                        AuthService.clearStatic(this.ls);

                        const url = err.url;

                        // Reload the current url to reevaluate the current route.
                        ApplogicUtils.reloadCurrentUrl(this.router);
                    }
                }

                return throwError(err);
            })
        );

    }

}