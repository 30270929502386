import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dev-site-warning',
    templateUrl: './dev-site-warning.component.html',
    styleUrls: ['./dev-site-warning.component.scss']
})
export class DevSiteWarningComponent implements OnInit {

    isInviteOnlyServer: boolean = false;
    isInviteOnlyRedirect: string = "https://id.appligogiques.com";

    constructor(private languageService: LanguageService) { }

    ngOnInit(): void {
        if(environment.inviteMode?.enabled) {
            let splitted = location.href.split("/");

            let lang = this.languageService.currentLanguageCode;

            if(environment.multiLanguages)
            {
                this.isInviteOnlyRedirect = "https://" + environment.inviteMode.redirectDomain + "/" + lang + "/" + splitted.slice(4).join("/");
            }
            else {
                this.isInviteOnlyRedirect = "https://" + environment.inviteMode.redirectDomain + "/" + lang + "/" + splitted.slice(3).join("/");
            }
            
            this.isInviteOnlyServer = true;
        }
    }

}
