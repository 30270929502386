<app-dialog icon="payment">
    <app-dialog-title i18n="@@order-stripe-payement-dialog-title">Payment</app-dialog-title>
    <app-dialog-content>
        <app-stripe-payment-panel [clientSecret]="clientSecret"
                                  [subscriptionId]="subscriptionId"></app-stripe-payment-panel>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="accent"
            [disabled]="!paymentPanel?.valid"
            (click)="pay()">
        <span i18n="@@order-stripe-payement-dialog-pay-button">Pay</span>
    </button>
</app-dialog>