<app-common-list-renderer [isAdmin]="true"
                          [list]="list">
    <renderer-templates [templates]="[subscriptionStatusTemplate, productsTemplate, tokensTemplate, tokensLeftTemplate, autoRenewTemplate]">
        <app-subscription-card-template></app-subscription-card-template>
    </renderer-templates>
    <app-common-list-toolbar [alignLeft]="true">
        <button mat-stroked-button
                color="accent"
                (click)="purchaseSubscription()"
                i18n="@@subscription-purchase-button"
                common-list-toolbar-option>
            Purchase a subscription
        </button>
    </app-common-list-toolbar>

    <button *ngIf="selectedMenuActionItem && canCancelSubscription(selectedMenuActionItem)"
            mat-menu-item
            (click)="cancelConfirmationDialog(selectedMenuActionItem)"
            menuitems>
        <mat-icon color="warn">close</mat-icon>
        <span i18n="@@common-cancel-subscription">Cancel subscription</span>
    </button>

    <div *ngIf="selectedMenuActionItem && isOwner(selectedMenuActionItem) && selectedMenuActionItem.stripeDetails?.subsId && (checkSubscriptionExpire(selectedMenuActionItem.expiresOn) ==  false) ">
        <button mat-menu-item
                (click)="updatePaymentMethod(selectedMenuActionItem)">
            <mat-icon color="warn">replay</mat-icon>
            <span i18n="@@subscription-card-menu-update-payment-method">Update payment method</span>
        </button>

    </div>

    <!-- <button mat-menu-item
            *ngIf="selectedMenuActionItem && checkSubscriptionExpire(selectedMenuActionItem.expiresOn) ==  true"
            menuitems>
        <mat-icon color="warn">replay</mat-icon>
        <span i18n="@@reactive-subscription-button">Reactivate subscription</span>
    </button> -->

    <app-subscription-mode-panel [subscription]="selectedMenuActionItem"
                                 *ngIf="selectedMenuActionItem && canChangeAllocationMode(selectedMenuActionItem)"
                                 menuitems></app-subscription-mode-panel>

</app-common-list-renderer>

<ng-template #productsTemplate
             let-data>
    <div class="products">
        <div *ngFor="let grant of data.row.grants"
             class="product-line">
            <app-shape type="square"
                       scale="extra-small"
                       [imageUrl]="productService.getGameIcon(grant.productShortCode)"></app-shape>
            <span *ngIf="!compactRow">{{productService.getGameName(grant.productShortCode)}}</span>
        </div>
    </div>
</ng-template>

<ng-template #tokensTemplate
             let-data>
    <div class="products">
        <div *ngFor="let grant of data.row.grants"
             class="product-line">
            <span>{{getTokensUsage(data.row, grant)}}</span>
        </div>
    </div>
</ng-template>

<ng-template #tokensLeftTemplate
             let-data>
    <div class="products">
        <div *ngFor="let grant of data.row.grants"
             class="seat-line">
            <div>{{data.row._freeSeats[grant.productShortCode.toLowerCase()] || 0}}</div>
        </div>
    </div>
</ng-template>

<ng-template #subscriptionStatusTemplate
             let-data>
    <span *ngFor="let state of [ApplogicUtils.getSubscriptionState(data.row)]"
          [ngClass]="'chip ' + state.className">
        {{state.label}}
    </span>
</ng-template>

<ng-template #autoRenewTemplate
             let-data>
    <app-subscription-validity-label [subscription]="data.row"
                                     [showNumber]="false"
                                     displayMode="auto-renew"></app-subscription-validity-label>
</ng-template>