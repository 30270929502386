<div class="custom-form-field custom-form-radio-select-field">
    <mat-label *ngIf="field.label"
               (contextmenu)="showDebugs($event)">{{field.label}}</mat-label>

    <mat-radio-group [formControl]="field.control">
        <mat-radio-button class="radio-button"
                          [value]="option.value"
                          [disabled]="data.disabled"
                          (change)="onFieldRadioSelectChanged($event)"
                          *ngFor="let option of data.options">
            {{option.label}}
        </mat-radio-button>
    </mat-radio-group>
    <mat-error *ngIf="field.control.invalid">
        {{ formService.getFieldErrorMessage(field.control, data.typeKey || field.key) }}
    </mat-error>
</div>