import { Model,ID, Member, ArrayMember } from "@uon/model";
import { SubscriptionPlan, SubscriptionType } from "../subscription/subscription.model";

export enum PromoCodeType {
    General = 'general',
    Specific = 'specific'
}

@Model()
export class PromoCodeConstraints {   

    @ArrayMember(String)
    type?: SubscriptionType[];

    @ArrayMember(String)
    plan?: SubscriptionPlan[];

}

@Model()
export class PromoCodeContext {   

    @Member()
    type: SubscriptionType;

    @Member()
    plan: SubscriptionPlan;

}

@Model()
export class PromoCode {

    @ID()
    id: string;
    
    @Member()
    code: string;

    @Member()
    type: PromoCodeType;

    @Member()
    validFrom: Date;

    @Member()
    validUntil: Date;

    @Member()
    discount: number;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    usedCount: number;

    @Member()
    constraints: PromoCodeConstraints;

    @Member()
    style: string;

    @Member()
    stripeCouponCode: string;

    @ArrayMember(String)
    alias: string[];
}

// Generated when a promo code use is initiated.
@Model()
export class PromoCodeTicket {

    @Member()
    code: string;

    @Member()
    discount: number;

    @Member()
    style: string;

    @Member()
    constraints: PromoCodeConstraints;

    checkConstraints(context: PromoCodeContext, validateExistingContraintsOnly: boolean = false) {
        if(!this.constraints) return true;

        let isValid: boolean = true;

        const constraints1 = JSON.parse(JSON.stringify(this.constraints));
        const constraints2 = JSON.parse(JSON.stringify(context));

        
        for(const key of Object.keys(constraints1)) {
            const val: string = constraints2[key];

            if(!val) {
                if(validateExistingContraintsOnly) {
                    continue;
                }
                isValid = false;
                break;
            }

            const constraint: string[] = constraints1[key];
            if(constraint.indexOf(val) == -1) {
                isValid = false;
                break;
            }
        }

        return isValid;
    }
}

