import { OrganizationTitle } from "../org/organization.model";
import { Address } from "../common/address.model";
import { RenewOption, Subscription, SubscriptionPlan, SubscriptionType } from "../subscription/subscription.model";
import { SelectedProduct } from "./selected-product";
import { Quotation } from "../quotation/quotation.model";
import { SubscriptionInvoice } from "../subscription/subscription-invoice.model";
import { PriceTable, TableProduct } from "./price-table";
import { OrderMode } from "./order-mode";
import { PromoCodeTicket } from "../product/promo-code.model";
import { ArrayMember, Member, Model } from "@uon/model";


@Model()
export class OrderForm {

    @Member()
    sessionId: string;
    
    // The order mode. Use when communicating with the backend API.
    @Member()
    mode: OrderMode;

    @Member()
    subscriptionType: SubscriptionType;

    @Member()
    subscriptionPlan: SubscriptionPlan;

    @Member()
    autorenew: boolean;

    @ArrayMember(SelectedProduct)
    products: SelectedProduct[];

    @Member()
    address: Address;

    @Member()
    googlePlaceId: string;

    @Member()
    lang: string;

    @Member()
    card_token: string;

    @Member()
    promoCode: string;

    @Member()
    promoCodeTicket: PromoCodeTicket;

    // Informations needed if subscriptionType is SubscriptionType.organization.
    @Member()
    organizationId: string; // Optional. Only when organization type must not be modified.

    @Member()
    organizationName: string;

    @Member()
    organizationTitle: OrganizationTitle;

    @Member()
    organizationOtherTitle: string;

    // Informations need for the quotation.
    @Member()
    userFullName: string;

    @Member()
    userEmail: string;

    // Informations need for the teacher trial. (When subscription plan == SubscriptionPlan.trialClassroom).
    @Member()
    classroomName: string;

    // Linked entities
    @Member()
    quotationId: string;

    @Member()
    invoiceId: string;

    @Member()
    invoice: SubscriptionInvoice;

    @Member()
    quotation: Quotation;

    @Member()
    subscriptionId: string;

    @Member()
    subscription: Subscription;

    @Member()
    currency: string = "CAD";

    // Returned by the server when option is activated in the HTTP GET route /order/form
    @Member()
    priceTable: PriceTable;

    /**
     * Save to metadata for stripe.
     * 
     * Stripe only allow string and one level of data.
    */
    public saveToMetadata(data: any): void {

        if(!this.currency) this.currency = "CAD";

        data.currency = this.currency;
        data.subscriptionPlan = this.subscriptionPlan;
        data.subscriptionType = this.subscriptionType;

        data.address_countryCode = this.address.country;
        data.address_zipcode = this.address.zipcode;
        data.address_line1 = this.address.line1;
        data.address_line2 = this.address.line2;
        data.address_state = this.address.state;
        data.address_stateCode = this.address.stateCode;
        data.address_city = this.address.city;
        data.address_street = this.address.street;

        data.autorenew = this.autorenew ? "true" : "false";
        data.lang = this.lang;

        data.organizationId = this.organizationId;
        data.organizationName = this.organizationName;
        data.organizationTitle = this.organizationTitle;
        data.organizationOtherTitle = this.organizationOtherTitle;

        if(this.googlePlaceId) data.googlePlaceId = this.googlePlaceId;

        if(this.classroomName) data.classroomName = this.classroomName;

        if(this.invoiceId) data.invoiceId = this.invoiceId;
        if(this.quotationId) data.quotationId = this.quotationId;
        if(this.subscriptionId) data.subscriptionId = this.subscriptionId;

        if(this.card_token) data.card_token = this.card_token;

        SelectedProduct.SaveProducts(this.products, data);
    }

    public loadFromMetadata(data: any): void {
        if(data.currency) {
            this.currency = data.currency;
        } else {
            this.currency = "CAD";
        }

        this.subscriptionPlan = data.subscriptionPlan;
        this.subscriptionType = data.subscriptionType;

        this.address = Model.New(Address, {});
        this.address.country = data.address_countryCode;
        this.address.zipcode = data.address_zipcode;
        this.address.line1 = data.address_line1;
        this.address.line2 = data.address_line2;
        this.address.state = data.address_state;
        this.address.stateCode = data.address_stateCode;
        this.address.city = data.address_city;
        this.address.street = data.address_street

        this.organizationId = data.organizationId;
        this.organizationName = data.organizationName;
        this.organizationTitle = data.organizationTitle;
        this.organizationOtherTitle = data.organizationOtherTitle;

        this.lang = data.lang;

        if(data.googlePlaceId) this.googlePlaceId = data.googlePlaceId;
        if(data.classroomName) this.classroomName = data.classroomName;

        if(data.invoiceId) this.invoiceId = data.invoiceId;
        if(data.quotationId) this.quotationId = data.quotationId;
        if(data.subscriptionId) this.subscriptionId = data.subscriptionId;

        if(data.card_token) this.card_token = data.card_token;

        if(data.autorenew == "true") {
            this.autorenew = true;
        } else if(data.autorenew == "false") {
            this.autorenew = false;
        } else {
            this.autorenew = true;
        }
         
        this.products = SelectedProduct.LoadProducts(data);
    }

    public loadFromQuotation(quotation: Quotation): void {
        this.subscriptionPlan = quotation.plan;
        this.subscriptionType = SubscriptionType.organization;

        this.address = Model.New(Address, {});
        this.address.set(quotation.organization.address);

        if(quotation.org) {
            this.organizationId = quotation.org.id;
        }
        
        this.organizationName = quotation.organization.name;
        this.organizationTitle = quotation.organization.title;
        this.organizationOtherTitle = quotation.organization.otherTitle;

        this.quotationId = quotation.id;
        
        this.products = [];
        quotation.priceTable.products.forEach((p) => {
            let selectedProduct = new SelectedProduct();
            selectedProduct.code = p.productCode;
            selectedProduct.seats = p.seats;
            selectedProduct.subscriptionPlan = quotation.plan;
            selectedProduct.subscriptionType = this.subscriptionType;
            selectedProduct.stripePriceId = p.stripePriceId;
            this.products.push(selectedProduct);
        });

        this.currency = quotation.priceTable.currency;

        this.quotation = quotation;
    }

    /**
     * Load from a SubscriptionInvoice
     * 
     * The "subscription" and "organization" properties must be deferenced.
     * Otherwise some informations will not be filled.
     * 
     * @param {SubscriptionInvoice} invoice The invoice.
     */
    public loadFromInvoice(invoice: SubscriptionInvoice): void {
        this.subscriptionPlan = invoice.subscriptionPlan;
        this.subscriptionType = invoice.subscriptionType;

        this.autorenew = invoice.autorenew;

        if(invoice.billingAddress)
        {
            this.address = Model.New(Address, {});            
            this.address.set(invoice.billingAddress);
        }
        else if(invoice.organization.billingAddress)
        {
            this.address = Model.New(Address, {});            
            this.address.set(invoice.organization.billingAddress);
        }

        this.organizationId = invoice.organization.id;
        this.organizationName = invoice.organization.name;

        this.invoiceId = invoice.id;
        
        this.products = [];
        invoice.priceTable.products.forEach((p) => {
            let selectedProduct = new SelectedProduct();
            selectedProduct.code = p.productCode;
            selectedProduct.seats = p.seats;
            selectedProduct.subscriptionPlan = this.subscriptionPlan;
            selectedProduct.subscriptionType = this.subscriptionType;
            selectedProduct.stripePriceId = p.stripePriceId;
            this.products.push(selectedProduct);
        });

        this.invoice = invoice;
    }

    /**
     * Load from a Subscription
     * 
     * The "subscription" and "organization" properties must be deferenced.
     * Otherwise some informations will not be filled.
     * 
     * @param {Subscription} subscription The invoice.
     */
    public loadFromSubscription(subscription: Subscription): void {
        this.subscriptionPlan = subscription.plan;
        this.subscriptionType = subscription.type;

        this.autorenew = subscription.renewOption != RenewOption.DoNothing;

        if(subscription.organization?.billingAddress)
        {
            this.address = Model.New(Address, {});            
            this.address.set(subscription.organization.billingAddress);
        }

        this.organizationId = subscription.organization?.id;
        this.organizationName = subscription.organization?.name;
        
        this.products = [];

        this.subscriptionId = subscription.id;
        this.subscription = subscription;
    }
}