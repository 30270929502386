<div class="apply-promocode"
     *ngIf="!isPromoCodeApplied">
    <mat-form-field appearance="outline"
                    floatLabel="always">

        <mat-label i18n="@@quotation-summary-promocode-label">Promocode</mat-label>

        <input type="text"
               name="promocode"
               [(ngModel)]="promoCodeValue"
               (ngModelChange)="onChangePromoCode()"
               matInput>

    </mat-form-field>
    <button mat-flat-button
            color="primary"
            i18n="@@request-summary-common-apply-button"
            [disabled]="!promoCodeValue?.length"
            (click)="applyPromo()">
        Apply
    </button>
</div>
<div class="applied-promo-code"
     *ngIf="isPromoCodeApplied">
    <span>{{promoCodeValue}}</span>
    <div class="remove-icon">
        <mat-icon (click)="removePromoCode()">
            clear
        </mat-icon>
    </div>
</div>
<div class="error-container"
     *ngIf="promoCodeErrorMessage.length">

    {{ promoCodeErrorMessage }}

</div>