import { Model, ID, Member } from '@uon/model';
import { Subscription, SubscriptionPlan, SubscriptionType } from '../subscription/subscription.model';
import { User } from '../user/user.model';
import { Organization } from '../org/organization.model';
import { BillingAddress } from '../org/address.model';
import { PriceTable } from '../order/price-table';
import { Quotation } from '../quotation/quotation.model';

export enum SubscriptionInvoiceState
{
    Due = 0,
    Paid = 1,
    PendingPayment = 2,
    Cancelled = 3
}

@Model()
export class SubscriptionInvoice {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    paidOn: Date;

    // The unique identifier to be displayed.
    @Member()
    invoiceNo: string;

    // The user that create this invoice.
    @Member()
    user: User;
   
    // The organization that own this invoice.
    @Member()
    organization: Organization;

    @Member()
    billingAddress: BillingAddress;

    @Member()
    subscriptionPlan: SubscriptionPlan;

    @Member()
    subscriptionType: SubscriptionType;

    @Member()
    priceTable: PriceTable;

    // Reference to the subscription once created.
    @Member()
    subscription: Subscription;

    @Member()
    state: SubscriptionInvoiceState;

    @Member()
    stripeInvoiceId: string;

    // This option will only serve the for a unpaid invoice.
    @Member()
    autorenew: boolean;

    // To track the quotation that was used to create this subscription.
    @Member()
    quotation: Quotation;
}