import { Component, Input } from '@angular/core';
import { ICommonList } from '../../common-list.interface';
import { CommonListRendererTemplatesComponent } from '../../common-list-renderer-templates/common-list-renderer-templates.component';
import { CommonListRendererOptionsComponent } from '../../common-list-renderer-options/common-list-renderer-options.component';
import { CommonListRenderOptions } from '../../common-list-render-options';
import { CommonListRendererComponent } from '../../common-list-renderer/common-list-renderer.component';


@Component({
    template: ''
})
export class CommonListCommonDisplayModeComponent {
    _list: ICommonList;

    @Input()
    set list(l: ICommonList) {
        if(this._list != l) {
            this._list = l;

            // if(this._list && !this._options && this._list.rendererOptions) {
            //     this.setOptions(this._list.rendererOptions);
            // }
        }
    }

    get list() {
        return this._list;
    }

    @Input()
    parent: CommonListRendererComponent;

    @Input()
    templatesOptions: CommonListRendererTemplatesComponent<any>;

    @Input()
    isAdmin: boolean = false;

    @Input()
    searchPlaceholder: string;

    @Input()
    contentOptions: CommonListRendererOptionsComponent;

    @Input()
    options: CommonListRenderOptions;
}
