<app-dialog icon="people"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@add-seats-allocation-dialog-title">Add member</app-dialog-title>
    <app-dialog-content>
        <hr class="topline">

        <p style="text-align: justify;font-size: 12px;"
           i18n="@@add-seats-allocation-dialog-message">
            Enter the name or email address of a member of the organization in the field below to find that person in the list. Select a member by checking the box next to his name.
        </p>

        <app-users-selection [orgId]="orgId"
                             [singleUserSelect]="true"
                             #usersSelection></app-users-selection>

    </app-dialog-content>
    <button mat-stroked-button
            matDialogClose
            appDialogAction
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            [disabled]="usersSelection?.collaboratorAdd.length == 0 || loading.isLoading || (loading.loadingChange | async)"
            color="accent"
            (click)="addSeatsAllocation()">

        <span i18n="@@collab-model-save-button">Save</span>

    </button>
</app-dialog>