import { Component, Input } from '@angular/core';
import { Classroom } from '@applogic/model';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CardRowData } from 'src/app/core/common-list/display-mode/common-list-card-display-mode/common-list-card-display-mode.component';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-classroom-card',
    templateUrl: './classroom-card.component.html',
    styleUrl: './classroom-card.component.scss'
})
export class ClassroomCardComponent {

    ApplogicUtils = ApplogicUtils;

    @Input()
    data: CardRowData<Classroom>;

    @Input()
    showActions: boolean = false;
    
}
