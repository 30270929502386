import { Model, ID, Member } from "@uon/model";


@Model()
export class KnowledgebaseFeedback {


    @ID()
    id: string;

    @Member()
    articleId: string;

    @Member()
    helpful: boolean;

    @Member()
    comment: string;

    @Member()
    userId: string;

    @Member()
    createdOn: Date;
}