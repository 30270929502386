import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormService } from 'src/app/services/form.service';

export enum ForgotPasswordPageStep {
    RequestForm = 'request-form',
    RequestSent = 'request-sent',
    UpdateForm = 'update-form',
    UpdateSent = 'update-sent'
}

@Component({
    selector: 'app-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {

    Step = ForgotPasswordPageStep;
    protected _step: ForgotPasswordPageStep;

    errorObject: any;

    get hasToken() {
        return !!this.route.snapshot.params.token;
    }

    get validToken() {
        return this.route.snapshot.data.summary != null;
    }

    showEmail = "";

    constructor(readonly loading: LoadingService,
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public formService: FormService) {


    }

    ngOnInit(): void {
        const token = this.route.snapshot.params.token;

        if (token && token.startsWith("test-")) {
            this.initTestMode(token);
        }
        else {
            if (this.hasToken) {
                if (!this.validToken) {
                    this.errorObject = {
                        userMessage: $localize`:@@password-reset-invalid-token-message:This password request form has either expired or does not exist.`
                    };
                }
                else {
                    this._step = ForgotPasswordPageStep.UpdateForm;
                }
            }
            else {
                this._step = ForgotPasswordPageStep.RequestForm;
            }
        }
    }

    initTestMode(token: string) {
        this._step = token.substring(5) as ForgotPasswordPageStep;
    }

    submitEmailForm(formData: UntypedFormGroup) {
        this.errorObject = undefined;

        this.auth.submitPasswordResetRequest(formData.value.email).subscribe({
            next: () => {
                this._step = ForgotPasswordPageStep.RequestSent;
                this.showEmail = formData.value.email;
            },
            error: (err) => {
                this.errorObject = err;
            }
        });

    }

    submitPasswordForm(formData: UntypedFormGroup) {
        this.errorObject = undefined;

        this.auth.submitPasswordReset(this.route.snapshot.params.token, formData.value.password).subscribe({
            next: () => {
                this._step = ForgotPasswordPageStep.UpdateSent;
            },
            error: (err) => {
                this.errorObject = err;
            }
        })
    }

}
