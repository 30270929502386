import { Member, Model } from "@uon/model";

@Model()
export class ChecklistTrialClassroom {

    @Member()
    classroomId: string;

    @Member()
    inviteStudent: boolean;

    @Member() 
    followProgress: boolean;

    // Dictionary of products that have been tried.
    @Member() 
    tryProducts: any;

    @Member()
    subscribed: boolean;

    public static FromData(data: any): ChecklistTrialClassroom
    {
        let result = Model.New(ChecklistTrialClassroom, {
            classroomId: data.classroomId,
            inviteStudent: data.inviteStudent,
            followProgress: data.followProgress,
            tryProducts: data.tryProducts,
            subscribed: data.subscribed
        });

        return result;
    }

    public ToData(): any {
        let result: any = {};
        result.classroomId = this.classroomId;
        result.inviteStudent = this.inviteStudent;
        result.followProgress = this.followProgress;
        result.tryProducts = this.tryProducts;
        result.subscribed = this.subscribed;
        return result; 
    }


}