import { ID, Model, Member, ArrayMember } from "@uon/model";
import { ProductLink } from "./product-link.model";
import { ProductSku } from "./sku.model";
import { StoreLink } from "./store-link.model";


/**
 * The Product model.
 * 
 * Note: Must update the siteVersion in the angular site when updating or adding a product.
 */
@Model()
export class Product {

    @ID()
    id: string;

    @Member()
    name: string;

    @Member()
    description: string;
   
    @Member()
    imageUrl: string;

    @Member()
    language: string;

    @Member()
    shortCode: string;

    @ArrayMember(ProductSku)
    skus: ProductSku[];

    @ArrayMember(Product)
    variations: Product[];

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    localizedName: object;

    @Member()
    localizedShortName: object;

    @ArrayMember(ProductLink)
    links: ProductLink[];

    @ArrayMember(String)
    scopes: string[];

    currentLocalizedName: string;

    currentLocalizedShortName: string;
}