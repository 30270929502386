<app-dialog icon="warning"
            iconColor="warn">
    <app-dialog-title i18n="@@common-strong-warning-title">WARNING!</app-dialog-title>
    <app-dialog-content>
        <div i18n="@@subscription-cancel-dialog--message">
            Are you sure you want to cancel this subscription?
        </div>
    </app-dialog-content>
    <button mat-stroked-button
            matDialogClose
            i18n="@@common-cancel"
            appDialogAction>
        Cancel
    </button>
    <button mat-flat-button
            color="warn"
            (click)="cancelSubscription()"
            [disabled]="loading.isLoading || (loading.loadingChange | async)"
            i18n="@@subscription-cancel-dialog--confirm"
            appDialogAction>
        Yes, Confirm
    </button>
</app-dialog>