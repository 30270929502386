import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map } from 'rxjs/operators';
import { AngularUtils } from 'src/app/services/angular-utils';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

interface PdfViewerDialogData {
    title: string,
    pdfUrl?: any,
    pdfLink?: string
}

@Component({
    selector: 'app-pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent implements OnInit {

    title: string;
    pdfSrc: any;
    pdfUrl: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: PdfViewerDialogData,
        private mdDialogRef: MatDialogRef<PdfViewerDialogComponent>,
        private sanitizer: DomSanitizer,
        private http: HttpClient) {

        this.title = data.title;

        if(data.pdfLink) {
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(data.pdfLink);
        }
        else if (data.pdfUrl.startsWith("http")) {
            this.downloadPDF(data.pdfUrl).subscribe((pdfBlob: any) => {
                if(pdfBlob) {
                    const blobUrl = URL.createObjectURL(pdfBlob);
                    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
                }
            });
        } else {
            const blob = AngularUtils.base64ToBlob(data.pdfUrl, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        }
    }

    ngOnInit(): void {
    }

    downloadPDF(url: string) {
        return this.http.get(url, { withCredentials: true }).pipe(
            map((res: any) => {
                return new Blob([res.blob()], { type: 'application/pdf' });
            }),
            catchError((err) => {
                console.log(err);
                return undefined;
            })
        );
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: PdfViewerDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<PdfViewerDialogComponent, PdfViewerDialogData, void>(dialogService, dialog, PdfViewerDialogComponent, {
            data,
            width: '100vw',
            maxWidth: '800px',
            height: '90%',
            panelClass: 'app-dialog--full-content'
        }, config);
    }


}
