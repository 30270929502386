<app-dialog>
    <app-dialog-stepper [currentStep]="currentStep"
                        [stepCount]="3"
                        (onPrevClick)="prevStep()"
                        (onShowDebugs)="showDebugs($event)"></app-dialog-stepper>
    <app-dialog-title *ngIf="!homework"
                      style="display: flex; align-items: center">
        <span i18n="@@classroom-homework-create-homework-title">Create Homework</span>
    </app-dialog-title>
    <app-dialog-title *ngIf="homework"><span i18n="@@classroom-homework-edit-homework-title">Edit Homework</span></app-dialog-title>
    <app-dialog-content class="homework-form">
        <div class="error-container"
             *ngIf="showError">
            {{ showError }}
        </div>

        <div class="app-dialog-step"
             [ngClass]="{'hidden-field': currentStep != 1}">
            <app-form-builder-renderer [form]="step1Form"></app-form-builder-renderer>
        </div>
        <div class="app-dialog-step"
             [ngClass]="{'hidden-field': currentStep != 2}">

            <app-form-builder-renderer [form]="step2Form"
                                       [templates]="[studentListTemplate]"></app-form-builder-renderer>

            <div>
                <div class="topsubsection">
                    <mat-label i18n="@@classroom-homework-wordsyntax-options-title">
                        Options for the syntax generator
                    </mat-label>
                    <div>
                        <mat-label i18n="@@classroom-homework-wordsyntax-options-desc">These options make it possible to
                            orient
                            the
                            choice of answers.</mat-label>
                    </div>
                </div>

                <div class="checkbox-list">
                    <mat-checkbox class="checkbox-input"
                                  [checked]="wordSyntaxOptions.auditiveConfusions"
                                  (change)="wordSyntaxOptions.auditiveConfusions = $event.checked; changed = true;">
                        <span i18n="@@classroom-homework-wordsyntax-options-auditive-confusions">Auditive Confusions</span>
                    </mat-checkbox>
                    <mat-checkbox class="checkbox-input"
                                  [checked]="wordSyntaxOptions.visualConfusions"
                                  (change)="wordSyntaxOptions.visualConfusions = $event.checked; changed = true;">
                        <span i18n="@@classroom-homework-wordsyntax-options-visual-confusions">Visual Confusions</span>
                    </mat-checkbox>
                    <mat-checkbox class="checkbox-input"
                                  [checked]="wordSyntaxOptions.doubleConsonant"
                                  (change)="wordSyntaxOptions.doubleConsonant = $event.checked; changed = true;">
                        <span i18n="@@classroom-homework-wordsyntax-options-double-consonant">Double Consonant</span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="step"
             [ngClass]="{'hidden-field': currentStep != 3}">
            <mat-label i18n="@@classroom-homework-words-list-title">List of words</mat-label>
            <app-form-builder-renderer [form]="step3Form"
                                       [templates]="[eqolWordListTemplate, manualWordListTemplate]"></app-form-builder-renderer>
        </div>
    </app-dialog-content>
    <button *ngIf="currentStep == 1"
            mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel"
            (contextmenu)="showDebugs($event)">
        Cancel
    </button>
    <button *ngIf="currentStep > 1"
            mat-stroked-button
            appDialogAction
            i18n="@@common-back"
            (contextmenu)="showDebugs($event)"
            (click)="prevStep()">Back</button>
    <button *ngIf="(currentStep == 1) || (currentStep == 2)"
            mat-stroked-button
            appDialogAction
            [disabled]="step1Form.invalid || ((currentStep == 2) && step2Form.invalid)"
            i18n="@@common-next"
            (contextmenu)="showDebugs($event)"
            (click)="nextStep()">
        Next
    </button>
    <button *ngIf="(currentStep == 3) && !homework"
            mat-flat-button
            appDialogAction
            color="accent"
            [disabled]="step3Form.invalid"
            (click)="createHomework()">
        <span i18n="@@classroom-homework-create-dialog-create-button">Create</span>
    </button>
    <button *ngIf="(currentStep == 3) && homework"
            mat-flat-button
            appDialogAction
            color="accent"
            [disabled]="step3Form.invalid || !changed"
            (click)="updateHomework()">
        <span i18n="@@classroom-homework-create-dialog-update-button">Update</span>
    </button>
    <app-dialog-footer class="flex-column">
        <div class="flex-row">
            <mat-label class="secondary-text"
                       i18n="@@common-games">Games</mat-label>
            <span class="secondary-text">:</span>
            <!-- <mat-label class="checkbox-value-label secondary-text">{{productService.getGameName('msm')}}</mat-label> -->
            <mat-label class="secondary-text"
                       i18n="@@classroom-homework-only-msm-support">Only Words Without Worries (from version 1.16.0)
                support the homeworks.
            </mat-label>
        </div>
    </app-dialog-footer>
</app-dialog>

<ng-template #eqolWordListTemplate>
    <div *ngIf="!homework">
        <mat-label i18n="@@classroom-homework-eqol-list-desc">To create lists, go to the <a class="box-links"
               (click)="goToEQOL()">ÉQOL</a> section.</mat-label>
    </div>

    <div *ngIf="homework">
        <a class="box-links"
           (click)="goToEQOL(eqolListId)">{{getEqolListName(eqolListId)}}</a>
    </div>
</ng-template>

<ng-template #manualWordListTemplate>
    <div id="list-of-words-section">
        <app-create-word-list-panel (onListChanged)="onWordsListChanged($event)"
                                    [words]="words"></app-create-word-list-panel>
    </div>
</ng-template>

<ng-template #studentListTemplate>
    <app-student-list [displayForSelection]="true"
                      [students]="classroom?.students || []"
                      (onSelectionChanged)="onStudentsSelectionChanged($event)"></app-student-list>
</ng-template>