import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Organization, OrganizationRole } from '@applogic/model';
import { ValidateEmailList } from 'src/app/classroom/classroom-page/classroom-page.component';
import { OrganizationService } from 'src/app/organization/organization.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { LoadingService } from 'src/app/services/loading.interceptor';

interface InviteMemberDialogData {
     organization: Organization
}

@Component({
     selector: 'app-invite-member-dialog',
     templateUrl: './invite-member-dialog.component.html',
     styleUrl: './invite-member-dialog.component.scss'
})
export class InviteMemberDialogComponent {
    ApplogicUtils = ApplogicUtils;
    OrganizationRoles = Object.values(OrganizationRole);
    
     errorObject: any;

     inviteForm = new UntypedFormGroup({
          emails: new UntypedFormControl('', [Validators.required, ValidateEmailList]),
          role: new UntypedFormControl('', [Validators.required])
     });

     constructor(
          @Inject(MAT_DIALOG_DATA) public data: InviteMemberDialogData,
          private mdDialogRef: MatDialogRef<InviteMemberDialogComponent, void>,
          private orgService: OrganizationService,
          readonly loading: LoadingService
     ) {
     }

     public static createDialog(dialogService: DialogService, dialog: MatDialog, data: InviteMemberDialogData, config: AppDialogConfig = {}) {
          return new AppDialogRef<InviteMemberDialogComponent, InviteMemberDialogData, void>(dialogService, dialog, InviteMemberDialogComponent, {
               data,
               maxWidth: "600px",
          }, config);
     }

     submitInviteForm() {

          this.errorObject = null;

          const form = this.inviteForm.value;
          const emails = form.emails.split(/\r?\n/).filter(e => e.trim() != '')

          this.orgService.sendInvites(this.data.organization.id, form.role, emails)
               .subscribe((invs) => {

                    this.mdDialogRef.close();

               }, (err) => {
                    this.errorObject = err;
               });
     }
}
