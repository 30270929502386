import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {  
    }
}
