<div class="seat-token-container">
    <div *ngIf="displayType == 'normal'"  class="seat-token-label">{{ toNumber(allocation?.usedSeats[this.productCode]) }}</div>
    <div *ngIf="(displayType == 'counters') && counter1"  class="seat-token-label">{{ toNumber(counter1[this.productCode]) }}</div>

    <div *ngIf="displayType == 'dot-only'" [matTooltip]="getTooltips()" matTooltipClass="seat-tooltip">•</div>
    <div *ngIf="displayType != 'dot-only'" class="seat-token-background" [matTooltip]="getTooltips()" matTooltipClass="seat-tooltip" [ngStyle]="backgroundStyle" [class.is-invitations]="type === 'invitations'">

    </div>
    <div *ngIf="(displayType == 'counters') && counter2"  class="seat-token-label">{{ toNumber(counter2[this.productCode]) }}</div>
    <div *ngIf="displayType == 'normal'"  class="seat-token-label">{{ toNumber(allocation?.freeSeats[this.productCode]) }}</div>
    <div *ngIf="displayType == 'label-only'"  class="seat-token-label">{{label}}</div>
    <div *ngIf="displayType == 'free-only'"  class="seat-token-label">{{ toNumber(allocation?.freeSeats[this.productCode]) }}</div>
</div>