import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrderForm, SubscriptionPlan, SubscriptionType } from '@applogic/model';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-order-success-screen',
    templateUrl: './order-success-screen.component.html',
    styleUrl: './order-success-screen.component.scss'
})
export class OrderSuccessScreenComponent {

    type: 'quotation'|'subscription'|'trial-classroom'|'trial-organization' = 'subscription';

    protected _form: OrderForm;
    
    @Input()
    set form(form: OrderForm) {
        this._form = form;

        if(form?.quotationId) {
            this.type = 'quotation';
        }
        else {
            this.type = 'subscription';

            if(form.subscriptionPlan == SubscriptionPlan.trialClassroom) {
                this.type = 'trial-classroom';
            }
            else if(form.subscriptionPlan == SubscriptionPlan.trialOrganization) {
                this.type = 'trial-organization';
            }
        }
    }

    get form() {
        return this._form;
    }

    constructor(
        private router: Router,
        protected productService: ProductService
    ) {

    }

    accessTheGames() {
        if (this.form.subscriptionType == SubscriptionType.organization) {
            this.router.navigate(['/onboarding/subscription'], {
                queryParams: {
                    navigateTo: "/subscriptions"
                },
                replaceUrl: true
            });
        }
        else {
            this.router.navigate(['/games'], {
                replaceUrl: true
            });
        }
    }
}
