import { Component, HostBinding, Input } from '@angular/core';
import { LabelType } from 'src/app/core/label/label.component';

@Component({
    selector: 'page-header-card-extended-title',
    templateUrl: './page-header-card-extended-title.component.html',
    styleUrl: './page-header-card-extended-title.component.scss'
})
export class PageHeaderCardExtendedTitleComponent {

    @HostBinding('class') get getHostClass() {
        return 'page-header-card-extended-title design-card-title';
    }

    @Input()
    titleType: LabelType = LabelType.Ellipsis;
    
    @Input()
    count: number;

    getLabelWidth() {
        switch(this.titleType) {
            case LabelType.Ellipsis:
                return 'min-content';
            case LabelType.Dynamic:
                return '100%';
        }
    }
}
