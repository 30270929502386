import { Model, ID, Member, ArrayMember, MinLength, MaxLength, ValidateEmail } from '@uon/model';
import { SubscriptionSeat } from '../subscription/subscription-seat.model';
import { FieldValidations } from '../common/field.validations';
import { User } from './user.model';


@Model()
export class Student {

    @ID()
    id: string;

    @Member({
        validators: FieldValidations.fullName.getValidators(false)
    })
    fullName: string;

     /**
     * Shortcut to student's first name
     */
    get firstName() { return this.fullName.split(' ')[0]; }

    /**
     * Unique student username
     */
    @Member({
        validators: FieldValidations.profileName.getValidators(false)
    })
    username: string;

    @Member({
        validators: FieldValidations.studentPassword.getValidators(false)
    })
    password: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    deletedBy: User;
    
    /**
     * 
     */
    @Member()
    expiresOn: Date;

    @Member()
    lastSeenOn: Date;

    /**
     * 
     * @param _id 
     */
    constructor(_id?: string) {
        this.id = _id;
    }

    // Temporary attribute to be assigned dynamically if needed.
    public subscriptionSeats: SubscriptionSeat[];

}