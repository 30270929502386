import { Injectable } from '@angular/core';
import { AnalyticsEvent, ApiRoutes, RoutesServer } from '@applogic/model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiDirectoryService } from './api-directory.service';
import { LanguageService } from './language.service';
import { LocalStorageService } from './local-storage.service';
import * as uuid from 'uuid';

declare var gtag: any;

const LOCAL_STORAGE_ANALYTICS_KEY = 'applogic/analytics/cid';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    isGAEnabled: boolean = false;
    isAPEnabled: boolean = false;
    verbose: boolean = false;
    key: string = "";
    clientId: string;
    sessionId: string;
    gtagInitialized: boolean = false;

    constructor(private languageService: LanguageService,
        private dirService: ApiDirectoryService,
        private ls: LocalStorageService) {

        let analytics: any = this.ls.get(LOCAL_STORAGE_ANALYTICS_KEY);
        if (!analytics?.cid) {
            analytics = {
                cid: "nogtag-" + uuid.v4()
            }
            ls.set(LOCAL_STORAGE_ANALYTICS_KEY, analytics);
        }
        this.clientId = analytics?.cid;
        this.sessionId = "nogtag-" + uuid.v4();

        if(environment.googleAnalytics)
        {
            if(environment.googleAnalytics.key)
            {
                this.key = environment.googleAnalytics.key;
                this.isGAEnabled = true;
            }

            this.isAPEnabled = true;

            if(environment.googleAnalytics.verbose != undefined)
            {
                this.verbose = environment.googleAnalytics.verbose;
            }
        }

        if(this.isGAEnabled) {
            try {
                gtag('get', this.key, 'client_id', (client_id) => {
                    this.clientId = client_id;
                    if(analytics?.cid != client_id) {
                        analytics.cid = client_id;
                        ls.set(LOCAL_STORAGE_ANALYTICS_KEY, analytics);
                    }
                    if(this.verbose) {
                        console.log("[Analytics] Client Id is " + this.clientId);
                    }
                });

                gtag('get', this.key, 'session_id', (session_id) => {
                    this.sessionId = session_id;
                    this.gtagInitialized = true;
                    if(this.verbose) {
                        console.log("[Analytics] Session Id is " + session_id);
                    }
                });
            }
            catch(err) {
                console.error(err);
            }
        }
    }


    sendPageView(path: string) {
        if(this.verbose) {
            console.log("[Analytics] sendPageView('" + path + "')");
        }

        if(!this.isGAEnabled) {
            return;
        }

        setTimeout(() => {
            this.sendEvent('page_view', {
                page_title: path,  // The document.title was replace par the computed path as a workaround since GA4 doesn't show the path and since the portal doesn't have a title per page.
                page_location: window.location.href,
                page_path: path,
                send_to: this.key
            });
        }, 500);
    }

    sendEvent(name: string, value: any, opts?: any) {
        if(value) {
            value.language_code = this.languageService.currentLanguageCode;
        }

        if(this.verbose) {
            console.log("[Analytics] sendEvent('" + name + "', '" + JSON.stringify(value) + "', " + JSON.stringify(opts) + ")");
        }

        if(this.isGAEnabled) {
            try
            {
                gtag('event', name, value);
            }
            catch(ex)
            {
                console.error(ex);
            }
        }

        if(this.isAPEnabled) {
            try
            {
                if(!this.sessionId) {
                    console.error("Session id is not set already");
                }

                const evt = new AnalyticsEvent();
                evt.name = name;
                evt.params = value;
                evt.clientId = this.clientId;
                evt.sessionId = this.sessionId;
                evt.source = "portal";
                if(!this.gtagInitialized) {
                    evt.nogtag = true;
                }
                this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Analytics, `/event`, evt, { withCredentials: true }).pipe(
                    map((r: any) => {
                        return r;
                    })
                ).subscribe((r) => {
                });
            }
            catch(ex)
            {
                console.error(ex);
            }
        }
    }

    /**
     * This is the new type of tag with GA4.
     * 
     * See: https://support.google.com/tagmanager/answer/9442095
     */
    sendConfig(value: any, opts?: any) {
        if(this.verbose) {
            console.log("[Analytics] sendConfig('" + JSON.stringify(value) + "', " + JSON.stringify(opts) + ") using key " + this.key);
        }

        if(!this.isGAEnabled) {
            return;
        }
        
        try
        {
            gtag('config', this.key, value);
            if(value && (value.user_id != undefined)) {
                gtag('set', 'user_properties', { 
                    'crm_id' : value.user_id 
                });
            }
        }
        catch(ex)
        {
            console.error(ex);
        }
    }
}
