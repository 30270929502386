@switch(style) {
@case('bar') {
<div *ngFor="let stepIdx of _steps"
     [ngClass]="{'step-bar-common': true, 'step-bar-completed': isStepComplete(stepIdx), 'step-bar-notcompleted': !isStepComplete(stepIdx)}"
     (click)="onStepClick(stepIdx)">
</div>
}
@case('dot') {
<div *ngFor="let stepIdx of _steps"
     [ngClass]="{'step-dot-common': true, 'step-dot-completed': isStepComplete(stepIdx), 'step-dot-notcompleted': !isStepComplete(stepIdx)}"
     (click)="onStepClick(stepIdx)">
</div>
}
}