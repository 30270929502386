import { Component, Input } from '@angular/core';
import { OrganizationRole } from '@applogic/model';
import { ApplogicUtils } from 'src/app/services/applogic-utils';


@Component({
    selector: 'app-organization-role-card',
    templateUrl: './organization-role-card.component.html',
    styleUrl: './organization-role-card.component.scss'
})
export class OrganizationRoleCardComponent {

    ApplogicUtils = ApplogicUtils;
    OrganizationRole = OrganizationRole;

    @Input()
    role: OrganizationRole;

    @Input()
    readonly: boolean = false;
}
