<span *ngIf="showNumber"
      class="subscription-no">{{ subscription.subNo }}</span>
<ng-container [ngSwitch]="getState()">
    <div *ngSwitchCase="LabelState.SubscriptionWillRenew"
         class="subscription-validity-label"
         i18n="@@automatic-subscription-date">
        Subscription will automatically renew on <strong matTooltip="{{ subscription.expiresOn | date:'long'}}">{{ subscription.expiresOn | date}}</strong>
    </div>
    <div *ngSwitchCase="LabelState.SubscriptionWillExpire"
         class="subscription-validity-label"
         i18n="@@automatic-subscription-cancel-message">
        Subscription valid until <strong matTooltip="{{ subscription.expiresOn | date:'long'}}">{{ subscription.expiresOn | date}}</strong>
    </div>
    <div *ngSwitchCase="LabelState.TokensWillRenew"
         class="subscription-validity-label"
         i18n="@@automatic-subscription-date-tokens">
        Tokens will automatically renew on <strong matTooltip="{{ subscription.expiresOn | date:'long'}}">{{ subscription.expiresOn | date}}</strong>
    </div>
    <div *ngSwitchCase="LabelState.TokensWillExpire"
         class="subscription-validity-label"
         i18n="@@automatic-subscription-cancel-message-tokens">
        Tokens valid until <strong matTooltip="{{ subscription.expiresOn | date:'long'}}">{{ subscription.expiresOn | date}}</strong>
    </div>
    <div *ngSwitchCase="LabelState.AutoRenewWillRenew"
         class="subscription-validity-label"
         i18n="@@automatic-subscription-date-auto-renew">
        <strong matTooltip="{{ subscription.expiresOn | date:'long'}}">{{ subscription.expiresOn | date}}</strong>
    </div>
    <div *ngSwitchCase="LabelState.AutoRenewWillExpire"
         class="subscription-validity-label"
         i18n="@@automatic-subscription-cancel-message-auto-renew">
        Expires on <strong matTooltip="{{ subscription.expiresOn | date:'long'}}">{{ subscription.expiresOn | date}}</strong>
    </div>
</ng-container>