<ng-container [ngSwitch]="style">
    <div *ngSwitchCase="'hidden'">

    </div>
    <div *ngSwitchCase="'big'">
        <ng-template *ngIf="showHeader"
                     [ngTemplateOutlet]="headerTemplate"></ng-template>
        <mat-button-toggle-group class="big-currency-selection toggle-expanded-rounded-buttons"
                                 name="fontStyle"
                                 aria-label="Font Style"
                                 [(ngModel)]="currencyCode">
            <mat-button-toggle class="toggle-item"
                               *ngFor="let c of currencies"
                               [value]="c.code"
                               (change)="setCurrency(c, $event);">{{c | apCurrencyCodeSymbol}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="currency-selection"
         *ngSwitchDefault="">
        <ng-template *ngIf="showHeader"
                     [ngTemplateOutlet]="headerTemplate"></ng-template>
        <div class="currency"
             *ngFor="let c of currencies">
            <a *ngIf="currency != c"
               href=""
               (click)="setCurrency(c,$event)">{{c.code}}</a>
            <span *ngIf="currency == c"
                  stye="text-decoration: underline">{{c.code}}</span>
        </div>
    </div>
</ng-container>


<ng-template #headerTemplate>
    <p i18n="@@unified-payment-step2-currency-selection">Choose the billing currency</p>
    <p class="chosen-currency-label"
       *ngIf="currency?.code=='CAD'"
       i18n="@@unified-payment-step2-currency-selection-details-cad">You have chosen to pay in <span class="selected-currency-long-text">Canadian dollars ($ CAD)</span></p>
    <p class="chosen-currency-label"
       *ngIf="currency?.code=='USD'"
       i18n="@@unified-payment-step2-currency-selection-details-usd">You have chosen to pay in <span class="selected-currency-long-text">US dollars ($ USD)</span></p>
    <p class="chosen-currency-label"
       *ngIf="currency?.code=='EUR'"
       i18n="@@unified-payment-step2-currency-selection-details-eur">You have chosen to pay in <span class="selected-currency-long-text">Euros (€ EUR)</span></p>
</ng-template>