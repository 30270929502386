import { Directive, Input, Optional, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { CommonListRendererTemplatesComponent } from '../common-list-renderer-templates.component';

@Directive({
    selector: '[card-template1],card-template1'
})
export class CardTemplateDirective {
    
    constructor(@Optional() private parent: CommonListRendererTemplatesComponent<any>, public template: TemplateRef<any>) {
        if (!this.parent) {
            console.error('The component using directive cardTemplate must be used as a child of the component CommonListRendererTemplatesComponent');
        }
        else {
            parent.cardTemplate = template;
        }
    }

}
