import { Model, Member } from "@uon/model";
import { Organization, OrganizationType } from "./organization.model";
import { BillingAddress } from "./address.model";

@Model()
export class FindOrgQuery {

    @Member()
    nearestName?: string;

    @Member()
    orgId?: string; 
    
    @Member()
    userId?: string;
}

@Model()
export class FindOrgResponse {

    @Member()
    org: Organization;
}

@Model()
export class GetOrgResponse {

    @Member()
    org: Organization;

    @Member()
    classroomCount: number;

    @Member()
    studentCount: number;
}

@Model()
export class UpdateOrgQuery {

    @Member()
    name: string;

    @Member()
    type: OrganizationType;

    @Member()
    typeOther: string;

    @Member()
    billingAddress: BillingAddress;
}
