import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductLink } from '@applogic/model';
import { AnalyticsEventsService } from 'src/app/services/analytics-events.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { LanguageService } from 'src/app/services/language.service';
import { NavigationService } from 'src/app/services/navigation.service';


@Component({
    selector: 'app-product-store-links',
    templateUrl: './product-store-links.component.html',
    styleUrl: './product-store-links.component.scss'
})
export class ProductStoreLinksComponent {
    
    @Input()
    showPlayButton: boolean = false;

    @Input()
    product: Product;

    @Input()
    section: string;

    @Input()
    layout: 'icons'|'list' = 'icons';
    
    constructor(
        protected languageService: LanguageService,
        protected analyticsEventsService: AnalyticsEventsService,
        protected router: Router,
        private navigationService: NavigationService,
        private analyticsService: AnalyticsService
    ) {
        
    }

    playButtonClick(event: any) {
        this.navigationService.navigateToGamePage(this.product, this.section, {}, event);
    }

    onLinkClick(link: ProductLink) {
        const product = this.product;
        
        let eventParams: any = link.analytics;
        if(!eventParams) eventParams = {};

        eventParams.product_code = product.shortCode.toLowerCase();
        
        if(link.key == "windows_installer")
        {
            eventParams.target_os = "windows";
        }
        else if(link.key == "macos_installer")
        {
            eventParams.target_os = "macos";
        }

        if(link.type == "store")
        {
            eventParams.store = link.label.default;
        }

        this.analyticsService.sendEvent("portal_" + link.type + "_button", eventParams);
    }
}
