import { Component, ContentChildren, ElementRef, HostListener, QueryList, ViewChild } from '@angular/core';
import { CommonListActionItemComponent } from '../common-list-action-item/common-list-action-item.component';
import { AngularUtils } from 'src/app/services/angular-utils';

@Component({
    selector: 'app-common-list-action-toolbar',
    templateUrl: './common-list-action-toolbar.component.html',
    styleUrl: './common-list-action-toolbar.component.scss'
})
export class CommonListActionToolbarComponent {

    // Use the ResizeObserver for a more presize resize events an avoid having the incorrect container size.
    private resizeObserver: ResizeObserver;

    @ContentChildren(CommonListActionItemComponent)
    items: QueryList<CommonListActionItemComponent>;

    @ViewChild('buttonContainer') buttonContainer: ElementRef<HTMLElement>;

    overflowItems: CommonListActionItemComponent[] = [];

    overflowIndex: number = -1;

    gap: number;

    // @HostListener('window:resize', ['$event'])
    // onResize(event) {
    //     this.updateButtons();
    // }
    
    ngAfterViewInit() {
        this.items.changes.subscribe(() => {
            this.updateButtons();
        });
        setTimeout(() => {
            this.updateButtons();
        });

        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                this.updateVisibleItems();
            }
        });
        this.resizeObserver.observe(this.buttonContainer.nativeElement);
    }

    ngOnDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private updateButtons() {
        requestAnimationFrame(() => {
            this.updateVisibleItems();
        });
    }

    private updateVisibleItems() {
        const containerWidth = this.buttonContainer.nativeElement.offsetWidth - 1;

        let totalWidth = 0;
        let overflowIndex = -1;

        const children = this.buttonContainer.nativeElement.children;   

        if(this.gap == undefined) {
            const styles = window.getComputedStyle(this.buttonContainer.nativeElement);

            this.gap = AngularUtils.parseCssValue(styles.gap, this.buttonContainer.nativeElement);
            this.gap = isNaN(this.gap) ? undefined : this.gap;
        }
        
        const gap = (this.gap || 0) + 1; // +1 since  all value used are rounded values and sometime a button is wrapped and visible in a second line.

        for(let i=0; i<children.length; i++) {
            const child = children[i] as HTMLElement;
            if(i > 0) {
                totalWidth += gap;
            }
            totalWidth += child.offsetWidth;
            if (totalWidth > containerWidth) {
                if(overflowIndex === -1) {
                    overflowIndex = i;
                }
                child.style.visibility = 'hidden';
                child.style.position = 'absolute';
            }
            else if(overflowIndex == -1) {
                child.style.visibility = 'visible';
                child.style.position = 'relative';
            }
        }

        this.overflowItems = [];


        this.overflowIndex = overflowIndex;

        this.populateOverflowItems();
    }

    private populateOverflowItems() {
        if (this.overflowIndex === -1) {
            this.overflowItems = [];
        } else {
            this.overflowItems = this.items.toArray().slice(this.overflowIndex);
        }
    }

    protected onItemClick(item: CommonListActionItemComponent, event: any) {
        item.click.emit(event);
    }
}
