<div appearance="outline"
     floatLabel="always"
     class="custom-form-field custom-form-right-select-field one-line-field">
    <div class="field-line">
        <mat-label *ngIf="field.label"
                   (contextmenu)="showDebugs($event)">{{field.label}}</mat-label>
        <span class="spacer"></span>
        <mat-form-field appearance="outline"
                        floatLabel="always"
                        subscriptSizing="dynamic">
            <mat-select [formControl]="field.control"
                        (selectionChange)="onFieldSelectChanged($event)">
                <mat-option [value]="option.value"
                            *ngFor="let option of data.options">{{option.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-error *ngIf="field.control.invalid">
        {{ formService.getFieldErrorMessage(field.control, data.typeKey || field.key) }}
    </mat-error>
</div>