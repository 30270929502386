import { ArrayMember, ID, Member, Model } from "@uon/model";
import { WordListWord } from "../wordlist/wordlist-word";
import { WordSyntaxOptions } from "../common/wordsyntax-options";
import { ClassroomHomeworkStudent } from "./classroom-homework-student.model";
import { User } from "../user/user.model";


export enum ClassroomHomeworkWordsSource {
    EQOL = "eqol",
    Manual = "manual"
}

@Model()
export class ClassroomHomework {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    isEnabled: boolean;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    deletedBy: User;
    
    @Member()
    label: string;

    @Member()
    desc: string;

    @Member()
    classroomId: string;

    @Member()
    wordsSource: ClassroomHomeworkWordsSource;

    @Member()
    eqolListId: string;

    @ArrayMember(WordListWord)
    words: WordListWord[];

    @Member()
    classroomShared: boolean;

    @ArrayMember(ClassroomHomeworkStudent)
    students: ClassroomHomeworkStudent[];

    @Member()
    wordsyntaxOptions: WordSyntaxOptions;

    @Member()
    lang: string;

}