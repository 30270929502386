import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { GameProgressSelectableColumn } from './game-progress-selectable-column';
import { ApiRoutes, GameProgressAppActivity, GameProgressionQuery, GameProgressListInfo, RoutesServer } from '@applogic/model';
import * as moment from "moment-timezone";
import { ApiDirectoryService } from '../services/api-directory.service';
import { CommonListColumn } from '../core/common-list/common-list-column';

// To increment when app word lists or activities changed.
const PROGRESS_DATA_VERSION = "V1.3";


@Injectable({
  providedIn: 'root'
})
export class ProgressionTableService {

  constructor(private localService: LocalStorageService,
    private dirService: ApiDirectoryService) { }

    /**
     * Get the game progression.
     */
     getProgressGameDetails(gamedata: GameProgressionQuery) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.GameData, `/progress`, gamedata, {
            withCredentials: true,
        });
    }
    
    /**
     * Get the app activities.
     * 
     * @param {string} productCode The product code.
     * @param {string} languageType The language code.
     * @param {boolean} force True to ignore the cache and retrieve the full list again.
     * 
     * @returns {GameProgressAppActivity[]} Return the app activities.
     */
     getAppActivities(productCode: string, languageType: string, force: boolean = false): Promise<GameProgressAppActivity[]> {
        return new Promise((resolve, rejects) => {
            let type: string = productCode.toUpperCase() + "_" + languageType.toUpperCase() + "_ACTIVITIES_V3" + "_" + PROGRESS_DATA_VERSION;

            let game_activity = this.localService.get(type);

            if (force || !game_activity) {
                this.dirService.serverGet(RoutesServer.Api, ApiRoutes.GameData, `/activities/${productCode}/${languageType}`,
                        { withCredentials: true }
                    )
                    .subscribe((response) => {
                        let responseData: any = response;
                        this.localService.set(type, responseData);
                        resolve(responseData as GameProgressAppActivity[]);
                    }, (error) => {
                        rejects(error);
                    });
            } else {
                resolve(game_activity as GameProgressAppActivity[]);
            }
        });
    }

    /**
     * Get the app words lists.
     * 
     * @param {string} productCode The product code.
     * @param {string} languageType The language code.
     * @param {boolean} force True to ignore the cache and retrieve the full list again.
     * 
     * @returns {GameProgressListInfo[]} Return the app word lists.
     */
    getAppWordLists(gameType: string, languageType: string, force: boolean = false): Promise<GameProgressListInfo[]> {
        return new Promise((resolve, rejects) => {


            let type: string = gameType.toUpperCase() + "_" + languageType.toUpperCase() + "_APPWORDLISTS_V7" + "_" + PROGRESS_DATA_VERSION;

            let getGameList = this.localService.get(type);

            if (force || !getGameList) {
                this.dirService.serverGet(RoutesServer.Api, ApiRoutes.GameData, `/appwordlists/${gameType}/${languageType}`,
                        { withCredentials: true }
                    )
                    .subscribe((response) => {
                        let responseData: any = response;
                        responseData.map(a => {
                            if(a._id) a.id = a._id
                        });

                        this.localService.set(type, responseData);

                        resolve(responseData as GameProgressListInfo[]);
                    }, (error) => {
                        rejects(error);
                    });
            } else {
                resolve(getGameList as GameProgressListInfo[]);
            }
        });
    }

    /**
     * Get the homework words lists.
     * 
     * @param {string} classromId Classroom ID.
     * @param {string} languageType The language code.
     * @param {boolean} force True to ignore the cache and retrieve the full list again.
     * 
     * @returns {GameProgressListInfo[]} Return the app word lists.
     */
     getHomeworkWordLists(classromId: string, languageType: string, force: boolean = false): Promise<GameProgressListInfo[]> {
        return new Promise((resolve, rejects) => {

            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.GameData, `/homework-wordlists/${classromId}/${languageType}`,
                    { withCredentials: true }
                )
                .subscribe((response) => {
                    let responseData: any = response;
                    
                    responseData.added.map(a => a._id = a.id);

                    resolve(responseData.added as GameProgressListInfo[]);
                }, (error) => {
                    rejects(error);
                });
        });
    }

    /**
     * Get the key to store and restore the columns state.
     * 
     * @param {string} currentGame The game product code.
     * @param {string} currentLanguage The language code.
     *
     * @returns {string} The key
     */
     private getColumnsStateKey(currentGame: string, currentLanguage: string): string {
        return currentGame.toUpperCase() + "_" + currentLanguage.toUpperCase() + "_SELECTED_COLUMNS";
    }

    /**
     * Save the columns selected state.
     * 
     * @param {string} currentGame The game product code.
     * @param {string} currentLanguage The language code.
     * @param {GameProgressSelectableColumn[]} columns The columns list.
     */
    saveColumnsState(currentGame: string, currentLanguage: string, columns: GameProgressSelectableColumn[]) {
        let selected: any = {};

        columns.forEach((column: GameProgressSelectableColumn) => {
            if (column.isSelectedValue) {
                selected[column.key] = true;
            }
        });

        const type = this.getColumnsStateKey(currentGame, currentLanguage);

        this.localService.set(type, selected);
        this.loadColumnsState(currentGame, currentLanguage, columns);
    }

    /**
     * Load the columns selected state.
     * 
     * @param {string} currentGame The game product code.
     * @param {string} currentLanguage The language code.
     * @param {GameProgressSelectableColumn[]} columns The columns list.
     */
    loadColumnsState(currentGame: string, currentLanguage: string, columns: GameProgressSelectableColumn[]) {
        let selected: any = {};

        const type = this.getColumnsStateKey(currentGame, currentLanguage);
        selected = this.localService.get(type);

        if (!selected) return;

        columns.forEach((column: GameProgressSelectableColumn) => {
            if(column.isSelectable)
            {
                if (selected[column.key]) {
                    column.isSelectedValue = true;
                } else {
                    column.isSelectedValue = false;
                }
            }
        });
    }

    /**
     * Load the columns selected state.
     * 
     * @param {string} currentGame The game product code.
     * @param {string} currentLanguage The language code.
     * @param {GameProgressSelectableColumn[]} columns The columns list.
     */
    loadColumnsState2(currentGame: string, currentLanguage: string, columns: CommonListColumn[]) {
        let selected: any = {};

        const type = this.getColumnsStateKey(currentGame, currentLanguage);
        selected = this.localService.get(type);

        if (!selected) return;

        columns.forEach((column: CommonListColumn) => {
            if (column.selecteable) {
                if (selected[column.key]) {
                    column.selected = true;
                } else {
                    column.selected = false;
                }
            }
        });
    }

    /**
     * Group list of items by key.
     * 
     * @param list List of items to group by.
     * @param keyGetter Callback to retrieve the key of the item.
     * 
     * @returns {Map} Returns the list of items grouped by key.
     */
     groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    /**
     * Get daily dates between two dates.
     * 
     * @param startDate 
     * @param stopDate
     * 
     * @returns An array of dates for each day.
    */
     getDailyDatesColumns(startDate, stopDate): GameProgressSelectableColumn[] {
        var dateArray: GameProgressSelectableColumn[] = [];

        let column: GameProgressSelectableColumn = new GameProgressSelectableColumn();
        column.header = true;
        column.isSelectedValue = true;
        column.isSelectable = false;
        column.key = "student_name";
        dateArray.push(column);

        let currentDate = moment(startDate);
        let endDate = moment(stopDate);
        while (currentDate <= endDate) {
            column = new GameProgressSelectableColumn();
            column.isSelectedValue = true;
            column.key = moment(currentDate).format("D/M/YY");
            column.id = column.key;
            column.name = moment(currentDate).format("YYYY-MM-DD");
            dateArray.push(column);
            
            currentDate = moment(currentDate).add(1, "days");
        }
        return dateArray;
    }
}
