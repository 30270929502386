import { ArrayMember, ID, Member, Model } from "@uon/model";
import { OnboardingHistoryEntry } from "./history-entry";
import { OnboardingUserState } from "./user-state";
import { User } from "../user/user.model";



@Model()
export class OnboardingUser {

    @ID() 
    id: string;

    @Member() 
    userId: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    nextEvaluation: Date;

    // The current user state.
    @Member() 
    state: OnboardingUserState;

    @ArrayMember(OnboardingHistoryEntry)
    stateHistory: OnboardingHistoryEntry[];

    // For other internal state data.
    @Member() 
    metadata: any;

    // Flags for things that are achieved.
    @Member() 
    flags: any;

    @Member() 
    statistics: any;

    isDirty: boolean = false;

    @Member() 
    user: User;
}