import { ArrayMember, ID, Member, Model } from "@uon/model";
import { ContentAsset } from "../content/content-asset.model";

@Model()
export class EmailTemplateRef {

    @Member()
    webflowId: string;

    @Member()
    mailchimpId: string;
}

@Model()
export class EmailTemplateDates {

    @Member()
    webflow: Date;

    @Member()
    mailchimp: Date;
}

export enum EmailTemplateType {
    FullPage = "full-page",
    Component = "component"
}

@Model()
export class EmailTemplateInfo {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    type: EmailTemplateType;

    @Member()
    key: string;

    @Member()
    name: string;

    @Member()
    body: string;

    @Member()
    subject: string;
    
    @Member()
    lang: string;

    @Member()
    isEnabled: boolean;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    references: EmailTemplateRef;

    @Member()
    dates: EmailTemplateDates;

    @ArrayMember(String)
    usedComponents: string[];

    @Member()
    parentTemplateId: string;
}

@Model()
export class EmailTemplateAsset extends ContentAsset {

    constructor() {
        super();
    }

    @Member()
    mailchimp: string;

    @ArrayMember(EmailTemplateInfo)
    usedTemplates: EmailTemplateInfo[];
}

@Model()
export class EmailTemplateSyling {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    type: string;

    @Member()
    styles: any;


    generateText() {
        let stylesResult: string = "";

        if(this.styles) {
            for(const key of Object.keys(this.styles)) {
                stylesResult += "." + key + " {\n";
                const styles = this.styles[key];

                for(const styleKey of Object.keys(styles)) {
                    stylesResult += "\t" + styleKey + ": " + styles[styleKey] + ";\n";
                }
                stylesResult += "}\n";
            }
        }

        return stylesResult;
    }
}