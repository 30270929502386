import { Member, Model } from "@uon/model";

@Model()
export class OnboardingAction {
    @Member() 
    type: string;

    @Member() 
    params: object;

}