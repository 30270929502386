<mat-card class="design-card card-background"
          [ngClass]="getCard1Class()"
          [ngStyle]="getStyles()"
          [appearance]="appearance"
          matRipple>
    <mat-card-content class="design-card--content-container">
        <ng-content select="*"></ng-content>
    </mat-card-content>
</mat-card>
<mat-card class="design-card card-background"
id="page-header-right-card"
          *ngIf="secondCard"
          [ngClass]="getCard2Class()"
          [ngStyle]="getCard2Styles()"
          [appearance]="appearance"
          matRipple>
    <mat-card-content class="design-card--content-container">
        <ng-content select="[card2-content]"></ng-content>
    </mat-card-content>
</mat-card>