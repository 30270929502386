import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { ToolbarUserAvatarComponent } from './toolbar-user-avatar/toolbar-user-avatar.component';
import { BaseLoginRegisterFormComponent } from './base-login-register-form/base-login-register-form.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { CoreModule } from '../core/core.module';
import { SetPlaceholderPipe } from './set-placeholder.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserCardComponent } from './user-card/user-card.component';
import { SocialModule } from '../social/social.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
   declarations: [
      BaseLoginRegisterFormComponent,
      RegisterFormComponent,
      SetPlaceholderPipe,
      ToolbarUserAvatarComponent,
      UserAvatarComponent,
      UserCardComponent,
   ],
   imports: [
      CommonModule,
      CoreModule,
      SocialModule,
      FormsModule,
      ReactiveFormsModule,
      NgbTooltipModule
   ],
   exports: [
      BaseLoginRegisterFormComponent,
      RegisterFormComponent,
      SetPlaceholderPipe,
      ToolbarUserAvatarComponent,
      UserAvatarComponent,
      UserCardComponent,
   ]
})
export class AuthUiModule { }
