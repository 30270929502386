import { Component } from '@angular/core';
import { CustomFormBuilder } from 'src/app/core/form-builder/custom-form-builder';
import { CustomFormUtils } from 'src/app/core/form-builder/custom-form-utils';

export interface IndividualBillingForm {
    country: string;
    zipcode: string;
}

@Component({
    selector: 'app-individual-billing-form',
    templateUrl: './individual-billing-form.component.html',
    styleUrl: './individual-billing-form.component.scss'
})
export class IndividualBillingFormComponent {

    protected billingForm = new CustomFormBuilder();

    get valid() {
        return this.billingForm.formGroup.valid;
    }

    get formGroup() {
        return this.billingForm.formGroup;
    }

    get value(): IndividualBillingForm {
        return this.billingForm.value;
    }

    constructor() {
        CustomFormUtils.SetCountryZipcodeFields(this.billingForm);
    }

    patch(data: any) {
        this.billingForm.patch(data);
    }

    patchChanged(data: any) {
        this.billingForm.patchChanged(data);
    }
}
