<app-common-list-renderer [isAdmin]="true"
                          [list]="list">
    <renderer-templates [templates]="[productSeatCellTemplate]"
                        [templateColumns]="[productSeatColumnTemplate, tokenFooterTemplate]"></renderer-templates>
    <app-common-list-toolbar>
        <div table-option>
            <button mat-flat-button
                    color="primary"
                    (click)="inviteNewMember()">
                <span i18n="@@subscription-seats-allocation-list--invite-new-member-button">
                    Invite a new member
                </span>
            </button>
        </div>    
    </app-common-list-toolbar>
</app-common-list-renderer>

<ng-template #tokenFooterTemplate
             let-data>
    {{freeSeats[data.column.parameters.productCode]}}
</ng-template>

<ng-template #productSeatCellTemplate
             let-data>
    <app-quantity-input [showControls]="false"
                        [quantity]="data.row.seats"
                        [counterKey]="data.column.parameters.productCode"
                        [min]="undefined"
                        [max]="undefined"
                        [center]="true"
                        (onQuantityChanged)="onQuantityChanged($event, data.row, data.column.parameters.product)"
                        *ngIf="data.row.selected"></app-quantity-input>
</ng-template>

<ng-template #productSeatColumnTemplate
             let-data>
    <app-seat-token [displayType]="'icon-only'"
                    [productCode]="data?.column.parameters.productCode"></app-seat-token>
</ng-template>