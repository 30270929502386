import { Model, Member } from "@uon/model";
import { CurrencyAmount } from "./currency.model";




@Model()
export class TaxAmount {


    @Member()
    name: string;

    @Member()
    rate: number;

    @Member()
    amount: CurrencyAmount;

    constructor(name?: string, rate?: number, amount?: CurrencyAmount) {

        this.name = name;
        this.rate = rate;
        this.amount = amount;
        
    }

}