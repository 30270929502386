import { Model, Member, ArrayMember } from "@uon/model";
import { OnboardingForm, OnboardingFormState } from "./onboarding-form.model";

@Model()
export class OnboardingFormGetResponse {

    @Member()
    form: OnboardingForm;
}

@Model()
export class OnboardingFormUpdate {

    @Member()
    state: OnboardingFormState;
    
    @Member()
    step: string;

    @Member()
    data: any;
}

@Model()
export class OnboardingFormUpdateResponse {

    @Member()
    form: OnboardingForm;
}

@Model()
export class OnboardingFormDeleteResponse {

    @Member()
    form: OnboardingForm;
}

@Model()
export class OnboardingFormProcessResponse {

    @Member()
    form: OnboardingForm;

    @ArrayMember(String)
    errorMessages: string[];
}