import { Injectable } from '@angular/core';
import { ApiRoutes, CommonQueryFilter, CommonQueryResponse, Quotation, RoutesServer } from '@applogic/model';
import { LanguageService } from './language.service';
import { JsonSerializer } from '@uon/model';
import { ApiDirectoryService } from './api-directory.service';

const QUOTATION_SERIALIZER = new JsonSerializer(Quotation);

@Injectable({
    providedIn: 'root'
})
export class QuotationService {


    constructor(private languageService: LanguageService,
        private dirService: ApiDirectoryService) { }

    getQuotations(filter?: CommonQueryFilter): Promise<CommonQueryResponse<Quotation>> {
        let str = filter ? filter.toString2() : '';

        return new Promise((resolve, reject) => {
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Quotation, str, { withCredentials: true }).subscribe((res: any) => {
                let response = new CommonQueryResponse<Quotation>();
                response.count = res.count;
                response.result = res.result.map(r => QUOTATION_SERIALIZER.deserialize(r, true));
                resolve(response);
            }, (error) => {
                reject(error);
            })
        })
    }

    getQuotationPDf(quotationNo){
        const lang = this.languageService.currentLanguageCode;
        return new Promise ((resolve,reject)=>{
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Quotation, `/${quotationNo.trim()}/pdf/${lang}`, 
            { withCredentials: true }).subscribe(response => {
                resolve(response);    
                
            }, (error)=>{

                reject(error);

            })
        })
    }
}
