import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organization, OrganizationType, User } from '@applogic/model';
import { AccountService } from 'src/app/account/account.service';

@Component({
    selector: 'app-orgs-selection',
    templateUrl: './orgs-selection.component.html',
    styleUrls: ['./orgs-selection.component.scss']
})
export class OrgsSelectionComponent implements OnInit {
    _currentOrg: Organization;
    _userRole: User;

    @Input()
    set currentOrg(val: Organization) {
        if(this._currentOrg != val) {
            this._currentOrg = val;
            this.currentOrgChange.emit(val);
        }
    }

    get currentOrg() {
        return this._currentOrg;
    }

    @Output()
    currentOrgChange: EventEmitter<Organization> = new EventEmitter<Organization>();


    @Input()
    set userRole(val: User) {
        if(this._userRole != val) {
            this._userRole = val;
            this.userChange.emit(val);
        }
    }

    get userRole() {
        return this._userRole;
    }

    @Output()
    userChange: EventEmitter<User> = new EventEmitter<User>();


    private initialOrg: Organization;



    @Input()
    message: string = "";

    orgsListSupplied: boolean = false;

    public selectableOrgs: Organization[] = [];
    public otherOrgs: Organization[] = [];

    constructor(readonly account: AccountService) {
        if (this.account.currentOrg && (this.account.currentOrg.type != OrganizationType.Trials)) {
            this.initialOrg = this.account.currentOrg;
        }

        if (this.account.currentProfile) {
            this.account.currentProfile.orgs.forEach((o) => {
                if (this.account.canCreateClassroomForOrg(o)) {
                    if (!this.initialOrg) {
                        this.initialOrg = o;
                    }
                    else if (this.initialOrg != o) {
                        this.otherOrgs.push(o);
                    }

                    this.selectableOrgs.push(o);
                }
            });
        }
    }

    ngOnInit(): void {
        if(this.orgsListSupplied) return;
        setTimeout(() => {
            if (this.currentOrg) {
                this.otherOrgs = this.selectableOrgs.filter(o => o != this.currentOrg);
                if (this.account.currentOrg != this.currentOrg) this.account.setCurrentOrg(this.currentOrg);
            } else if (this.initialOrg) {
                this.currentOrg = this.initialOrg;
            }
        });
    }

    setCurrentOrg(org: Organization) {
        this.otherOrgs = this.selectableOrgs.filter(o => o != org);
        this.currentOrg = org;
        this.account.setCurrentOrg(org)
    }
}
