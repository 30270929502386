import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrgAllocationsResponse, Organization, SubAllocationsResponse, SubscriptionAllocation, SubscriptionGrant, User } from '@applogic/model';
import { SubscriptionModeDialogComponent } from 'src/app/account/subscriptions/subscription-mode-dialog/subscription-mode-dialog.component';
import { ChecklistPopoverComponent } from 'src/app/onboarding-ui/checklist-popover/checklist-popover.component';
import { DialogService } from 'src/app/services/dialog.service';
import { TransferSeatsDialogComponent } from '../transfer-seats-dialog/transfer-seats-dialog.component';
import { ProductService } from 'src/app/services/product.service';
import { MessageBoxDialogComponent } from 'src/app/core/message-box-dialog/message-box-dialog.component';
import { MessageBoxButton } from 'src/app/core/message-box-dialog/messagebox-button';
import { MessageBoxIcon } from 'src/app/core/message-box-dialog/messagebox-icon';
import { MessageBoxResult } from 'src/app/core/message-box-dialog/messagebox-result';
import { MessageBoxSettings } from 'src/app/core/message-box-dialog/messagebox-settings';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';
import { SeatService } from 'src/app/services/seat.service';
import { AddSeatsAllocationDialogComponent } from '../add-seats-allocation-dialog/add-seats-allocation-dialog.component';


@Component({
    selector: 'app-org-seats-allocation-card',
    templateUrl: './org-seats-allocation-card.component.html',
    styleUrl: './org-seats-allocation-card.component.scss'
})
export class OrgSeatsAllocationCardComponent {

    @Input()
    organization: Organization;
    
    @Input()
    orgAllocs: OrgAllocationsResponse;
    
    @Input()
    subAlloc: SubAllocationsResponse;

    constructor(
        private dialogService: DialogService,
        private dialog: MatDialog,
        private productService: ProductService,
        private seatService: SeatService,
        private errorDialogService: HandleErrorDialogService) {

    }

    transferSeatsForSubscription(subAllocation: SubAllocationsResponse) {
        let sourceAllocation = subAllocation.getDefaultAlloc();

        TransferSeatsDialogComponent.createDialog(this.dialogService, this.dialog, {
            subAlloc: subAllocation,
            sourceAllocation
        }).show().then((allocation) => {
            // A new allocation may have been created to make sur user informations is gathered.
            this.refreshUserFromNewAllocation(allocation);
        });
    }

    onConfigureClick(subAlloc: SubAllocationsResponse) {
        ChecklistPopoverComponent.closeAll();

        SubscriptionModeDialogComponent.createDialog(this.dialogService, this.dialog, {
            subscription: subAlloc.subscription,
            subAlloc
        }).show().then(() => {

        });
    }

    /**
     * Refresh user from new allocation. Because the user may not be in the list of users yet.
     * 
     * @param allocation 
     */
    refreshUserFromNewAllocation(allocation: SubscriptionAllocation) {
        if (allocation.filter.userId) {
            let userId = allocation.filter.userId;
            let user = this.orgAllocs.users.find((u) => u.id == userId);
            if (!user && this.organization.staffMembers) {
                let staff = this.organization.staffMembers.find((s) => s.user.id == userId);
                if (staff) {
                    this.orgAllocs.users.push(staff.user);
                }
                else {
                    console.error("User " + userId + " not in the organization: " + JSON.stringify(this.organization));
                }
            }
            else if (!user) {
                console.error("User " + userId + " not in the organization: " + JSON.stringify(this.organization));
            }
        }
    }

    getSubDesc(allocation: SubAllocationsResponse, grant: SubscriptionGrant) {
        let result: string = "";

        let productName: string = this.productService.getGameName(grant.productShortCode);

        result += $localize`:@@org-seats-allocation-card-subscription-line1:This subscription includes [count] [productname] token(s).`.replace("[count]", grant.seats.toString()).replace("[productname]", productName);

        result += "<br/>";

        let usedSeats: number = allocation.usedSeats[grant.productShortCode.toLowerCase()];

        result += $localize`:@@org-seats-allocation-card-subscription-line2:[count] token(s) have been used.`.replace("[count]", usedSeats.toString());



        return result;
    }

    transferSeats(subAllocation: SubAllocationsResponse, allocation: SubscriptionAllocation) {
        let user: User;

        if (allocation.filter.userId) {
            user = this.orgAllocs.users.find(a => a.id == allocation.filter.userId);
        }

        let sourceAllocation = subAllocation.getDefaultAlloc();

        TransferSeatsDialogComponent.createDialog(this.dialogService, this.dialog, {
            subAlloc: subAllocation,
            sourceAllocation,
            targetAllocation: allocation,
            user
        }).show();
    }

    removeAllocation(subAllocation: SubAllocationsResponse, allocation: SubscriptionAllocation) {
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@seat-allocation-section-remove-allocation-message:Are you sure you want to delete the allocation?`
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
        ];

        MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings).show().then((result) => {
            if (result == MessageBoxResult.OK) {
                this.seatService.removeAllocation(allocation.id).subscribe((result) => {
                    subAllocation.removeAllocation(allocation);
                }, (err) => {
                    this.errorDialogService.openErrorDialog(undefined, err, false);
                });
            }
        });
    }

    addAllocation(subAllocation: SubAllocationsResponse) {
        AddSeatsAllocationDialogComponent.createDialog(this.dialogService, this.dialog, {
            orgId: this.organization.id,
            subscription: subAllocation.subscription
        }).show().then((allocation) => {
            this.refreshUserFromNewAllocation(allocation);
            subAllocation.allocations.push(allocation);
        });
    }
}
