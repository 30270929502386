import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassroomsListComponent } from './classrooms-list/classrooms-list.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { ClassroomRoleChipComponent } from './classroom-role-chip/classroom-role-chip.component';
import { ClassroomCardComponent } from './classroom-card/classroom-card.component';
import { ClassroomCardTemplateComponent } from './classroom-card-template/classroom-card-template.component';



@NgModule({
    declarations: [
        ClassroomCardComponent,
        ClassroomCardTemplateComponent,
        ClassroomsListComponent,
        ClassroomRoleChipComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        RouterModule,
    ],
    exports: [
        ClassroomsListComponent,
        ClassroomRoleChipComponent,
    ]
})
export class ClassroomUiModule { }
