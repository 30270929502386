import { Member, Model } from "@uon/model";

@Model()
export class OnboardingStateStats {

    @Member()
    userCount: number;

    @Member()
    accessOrgSub: number;
}

@Model()
export class OnboardingStatsResponse {

    @Member()
    perState: {[stateCode: string]: OnboardingStateStats};
}