import { Pipe, PipeTransform } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { map } from 'rxjs/operators';
import { GameProgressSelectableColumn } from "./game-progress-selectable-column";
const LOCAL_STORAGE_GAME_ACTIVITY_KEY_FR = "game/activities/fr";
const LOCAL_STORAGE_GAME_ACTIVITY_KEY_EN = "game/activities/en";
const LOCAL_STORAGE_GAME_WORDLIST_KEY_MSM_FR = "game/wordlist/msm/fr";
const LOCAL_STORAGE_GAME_WORDLIST_KEY_MSM_EN = "game/wordlist/msm/en";
@Pipe({
    name: "colHeaderFilter",
    pure: true,
})
export class ColHeaderFilterPipe implements PipeTransform {
    constructor() { }

    transform(columns: GameProgressSelectableColumn[], include?: boolean): any {
        if( (include === undefined) || include) {
            return columns.filter(a => a.header);
        }
        
        return columns.filter(a => !a.header);
    }
}
