import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { merge, of } from 'rxjs';
import { distinct, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators'
import { ActivatedRoute, Router, NavigationEnd, RouterStateSnapshot, Params, ActivationEnd, NavigationStart } from '@angular/router';
import { NavigationService } from './services/navigation.service';
import { LoadingService } from './services/loading.interceptor';
import { FADE_IN_OUT } from './animations/fade';
import { AccountService } from './account/account.service';
import { AuthService } from './auth/auth.service';
import { ROUTER_TRANSITION } from './app.animation';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from './services/analytics.service';
import { Utils } from '@applogic/model';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [FADE_IN_OUT, ROUTER_TRANSITION]
})
export class AppComponent implements OnInit {

    @ViewChild("contents", { static: true })
    contents: ElementRef<HTMLElement>;
    isAuthenticated: boolean;

    currentRoutePaths = [];


    constructor(public account: AccountService,
        readonly nav: NavigationService,
        private activatedRoute: ActivatedRoute,
        readonly auth: AuthService,
        private router: Router,
        public loading: LoadingService,
        private matIconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private analytics: AnalyticsService,
        private popoverConfig: NgbPopoverConfig) {
            popoverConfig.openDelay = 500;
            popoverConfig.animation = true;
            popoverConfig.container = "body";
            popoverConfig.triggers = "mouseenter:mouseleave:click";
        /*
        this.matIconRegistry.addSvgIconLiteral(
            `homework`,
            sanitizer.bypassSecurityTrustHtml(`assets/images/homework.svg`)
            );
            */

        this.matIconRegistry.addSvgIcon(
            `homework`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/images/homework.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `stripe-blurple`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/images/stripe-blurple.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `google`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/google.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `facebook`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/facebook.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-home`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/home--rocket-launch.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-game`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/game--smart-toy.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-organization`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/organization--other_houses.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-class`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/class--school.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-billing`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/billing--receipt_long.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-account`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/account--account_circle.svg`)
        );

        
        this.matIconRegistry.addSvgIcon(
            `menu-subscription`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/subscription--card_membership.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `menu-eqol`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/eqol--history_edu.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `logout`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/logout.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `precision-manufacturing`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/precision_manufacturing_24dp.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `email-campaign`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu/admin/email-campaign.svg`)
        );

        this.matIconRegistry.addSvgIcon(
            `content-copy`,
            sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/content_copy_24dp.svg`)
        );

        
        
    }


    ngOnInit() {
        //close menu on route change from menu
        merge(
            of(this.activatedRoute.snapshot.data),
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
        ).subscribe((data: any) => {

            this.nav.onNavigate(data);
            setTimeout(() => {
                this.contents.nativeElement.scrollTo(0, 0);
            });
        })

        this.router.events.subscribe((event: any) => {

            if (event instanceof NavigationStart) {
                this.currentRoutePaths = [];
				
				// [AEP-1108] Fix webgl game not working correctly because it use the .com domain for the API. So must be redirected to the .com.
                if(window.location.hostname.endsWith(".ca")) {
                    if (Utils.isSafari(navigator.userAgent, navigator.vendor)) {
                        const newUrl = "https://id.appligogiques.com" + window.location.pathname + window.location.search + window.location.hash;
                        if(window.location.search && (window.location.search.indexOf("dontredirect=true") != -1))
                        {
                            console.log("Redirection Url: " + newUrl);
                        }
                        else
                        {
                            window.location.replace(newUrl);
                        }
                    }                        
                }
            }


            if (event instanceof ActivationEnd) {
                let path = event.snapshot.routeConfig.path;
                if (path && (this.currentRoutePaths.indexOf(path) == -1)) {
                    this.currentRoutePaths.push(path);
                }
            }

            if (event instanceof NavigationEnd) {
                let paths = this.currentRoutePaths.reverse();
                let pageName: string = "";
                if (paths) {
                    paths.forEach((subpath: string) => {
                        subpath.split("/").forEach((p: string) => {
                            if (pageName.length > 0) pageName += "_";

                            if (p.startsWith(":")) {
                                p = p.substring(1);
                            }

                            pageName += p;

                        });
                    });

                    pageName = pageName.replace("s_slug_id", "_view");

                    if (pageName.trim().length == 0) {
                        pageName = "Dashboard";
                    }
                }
                else {
                    pageName = "root";
                }

                this.analytics.sendPageView(pageName);
            }
        });
    }
}
