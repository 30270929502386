<app-dialog icon="edit">
    <app-dialog-title i18n="@@common-list-columns-dialog-title">Column selection</app-dialog-title>
    <app-dialog-content class="columns-container">
        <div *ngFor="let column of columns"
             [hidden]="!column.label">
            <mat-checkbox (change)="onColumnCheck(column, $event)"
                          class="small-checkbox"
                          [disabled]="!column.selecteable"
                          [checked]="selected[column.columnKey]">
                {{column.label}}
            </mat-checkbox>
        </div>
    </app-dialog-content>
    <button mat-flat-button
            color="accent"
            (click)="reset()"
            i18n="@@common-reset-default-button"
            appDialogAction>
        Reset Default
    </button>
    <button mat-flat-button
            (click)="cancel()"
            appDialogAction>
        <span i18n="@@common-cancel"> Cancel </span>
    </button>
    <button mat-flat-button
            color="accent"
            (click)="apply()"
            appDialogAction>
        <span i18n="@@common-apply-button"> Apply </span>
    </button>
</app-dialog>