import { Directive } from '@angular/core';

@Directive({
    selector: '[app-dialog-message],[appDialogMessage],app-dialog-message'
})
export class DialogMessageDirective {

    constructor() { }

}
