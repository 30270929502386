import { ArrayMember, ID, Member, Model } from "@uon/model";
import { EmailSentStatus } from "../email/email-sent-status";

@Model()
export class UserEmailDeliveryClickInfo {
    @Member()
    timestamp: Date;

    @Member()
    ipAddress: string;

    @Member()
    userAgent: string;

    @Member()
    link: string;

    @Member()
    linkTags: any;
}

@Model()
export class UserEmailDeliveryOpenInfo {
    @Member()
    timestamp: Date;

    @Member()
    ipAddress: string;

    @Member()
    userAgent: string;
}

@Model()
export class UserEmailDeliveryStats {
    @Member()
    clicked: number;

    @ArrayMember(UserEmailDeliveryClickInfo)
    clicks: UserEmailDeliveryClickInfo[];

    @Member()
    open: UserEmailDeliveryOpenInfo;
}

@Model()
export class UserEmailDelivery {

    @ID()
    id: string;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    // The userId.
    // Can be empty if the email was sent to a specific email (ex.: for system notifications).
    @Member()
    userId: string;

    // The email where is was sent.
    // The email may differ to the current user email if it was changed.
    @Member()
    email: string;

    @Member()
    lang: string;

    @Member()
    delivered: boolean;

    @Member()
    status: EmailSentStatus;

    @Member()
    statusMessage: string;

    @Member()
    statusData: any;

    @Member()
    templateKey: string;

    @Member()
    settings: any;

    @Member()
    serverType: string;

    @Member()
    serverResponse: any;

    @Member()
    messageId: string;

    @Member()
    errorLogId: string;

    @Member()
    stats: UserEmailDeliveryStats;

    @Member()
    campaignId: string;
}


export type UserEmailDeliveryOpenStats = {
    state: "not-sent" | "open" | "open-click" | "not-open",
    count: number
}[];

export type UserEmailDeliveryStatusStats = {
    status: EmailSentStatus,
    count: number
}[];