
import { Model, ID, Member, NumberMember, ArrayMember } from '@uon/model';
import { User } from '../user/user.model';


@Model()
export class Translation {

    @Member()
    language: string;

    @Member()
    value: string;
}

@Model()
export class TranslationNote {

    @Member()
    category: string;

    @Member()
    value: string;

    @Member()
    userId: string;

    @Member()
    updatedOn: Date;

    @Member()
    createdOn: Date;

}

/**
 * Final output
 */
@Model()
export class TranslationUnit {

    @ID()
    id: string;

    @Member()
    key: string;

    @Member()
    product: string;

    @Member()
    version: number;

    @Member()
    meaning: string;

    @Member()
    description: string;

    @ArrayMember(TranslationNote)
    notes: TranslationNote[];

    @Member()
    source: string

    @Member()
    language: string;

    @ArrayMember(Translation)
    translations: Translation[];

    @Member()
    updatedOn: Date;

    constructor(_id?: string) {
        this.id = _id;
    }
}


export enum TranslationSessionStatus {
    InProgress = 1,
    InReview = 2,
    Approved = 3,
    Published = 4
}

@Model()
export class TranslationSession {

    @ID()
    id: string;

    @Member()
    translatedBy: User;

    @Member()
    startedOn: Date;

    @Member()
    approvedBy: User;

    @Member()
    approvedOn: Date;

    @Member()
    publishedBy: User;

    @Member()
    publishedOn: Date;

    @Member()
    product: string;

    @Member()
    sourceLanguage: string;

    @Member()
    targetLanguage: string;

    @Member()
    status: TranslationSessionStatus;

}


export enum TranslationSessionUnitStatus {
    Pending = 1,
    RequiresRevision = 2,
    RequiresApproval = 3,
    Approved = 4
}

@Model()
export class TranslationSessionUnit {

    @ID()
    id: string;

    @Member()
    sessionId: string;

    @Member()
    unitId: string;

    @Member()
    key: string;

    @Member()
    version: number;

    @Member()
    source: string

    @Member()
    target: string

    @Member()
    status: TranslationSessionUnitStatus;

    @ArrayMember(TranslationNote)
    notes: TranslationNote[];

    @Member()
    approvedBy: User;

    @Member()
    updatedOn: Date;

}









