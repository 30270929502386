import { ArrayMember, ID, Member, Model, Required } from "@uon/model";

@Model()
export class PaymentMethod {

    @ID() 
    id: string;

    @Member() 
    label: string;

    @Member() 
    brand: string;

    @Member() 
    last4: string;

    @Member() 
    exp_year: number;

    @Member() 
    exp_month: number;

}