import {
    Component,
    OnInit,
    ViewContainerRef,
} from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute } from "@angular/router";
import { User, LegacyLicense, FieldValidationType, UserProfileResponse, ModelToObject } from "@applogic/model";
import { AccountService } from "../account.service";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { FormService } from "src/app/services/form.service";
import { LoadingService } from "src/app/services/loading.interceptor";
import { FADE_IN_OUT } from "src/app/animations/fade";
import { KnowledgeBaseService, ToolTipinfo } from '../../services/knowledge-base.service';
import { ProductService } from 'src/app/services/product.service';
import { LanguageService } from 'src/app/services/language.service';
import { CustomFormBuilder } from "src/app/core/form-builder/custom-form-builder";
import { GetFormGroupDirtyValues } from "src/app/services/angular-utils";
import { Model } from "@uon/model";
import { ToastrService } from "ngx-toastr";
import { CustomFormUtils } from "src/app/core/form-builder/custom-form-utils";


@Component({
    selector: "app-account-page",
    templateUrl: "./account-page.component.html",
    styleUrls: ["./account-page.component.scss"],
    animations: [FADE_IN_OUT]
})
export class AccountPageComponent implements OnInit {
    accountTooltipArr: ToolTipinfo[];
    profile: UserProfileResponse;

    licenses: LegacyLicense[] = [];

    emailPreferencesForm: UntypedFormGroup = new UntypedFormGroup({});

    profileForm = new CustomFormBuilder();
    settingsForm = new CustomFormBuilder();

    errorObject: any;
    
    passwordChanged: boolean = false;

    constructor(
        public auth: AuthService,
        readonly knowledgeBaseService: KnowledgeBaseService,
        readonly formService: FormService,
        readonly loadingService: LoadingService,
        private route: ActivatedRoute,
        private accountService: AccountService,
        readonly viewContainerRef: ViewContainerRef,
        readonly products: ProductService,
        readonly language: LanguageService,
        private toastr: ToastrService
    ) {
        const form = this.profileForm;
        form.formService = this.formService;

        form.beginGroup("", {
            label: $localize`:@@common-your-information: Your Information `
        });

        this.profileForm.addFormControl("fullName", FieldValidationType.FullName);
        this.profileForm.addFormControl("email", FieldValidationType.Email);
        this.profileForm.addFormControl("password", FieldValidationType.Password, {
            isRequired: false,
            placeholder: "***********",
            hidePlaceholderOnFocus: true
        });

        form.beginGroup("address", {
            orientation: "horizontal"
        });

        this.profileForm.addCountry("country", {
            label: $localize`:@@common-address-country:Country`,
            isRequired: false
        });

        this.profileForm.addInput("zipcode", {
            label: $localize`:@@common-address-zipcode:ZIP Code`
        });
        
        this.settingsForm.beginGroup("", {
            label: $localize`:@@common-communication-settings: Communication Settings `
        });

        this.settingsForm.beginGroup("", {
            orientation: 'horizontal'
        });
        
        CustomFormUtils.AddLanguageField(this.settingsForm, this.language);

        this.settingsForm.addHeadingText("", {
            headingText: ""
        });
        this.settingsForm.endGroup();
        
        this.settingsForm.addFormGroupTemplate("emailPreferences", 0);

        
    }

    ngOnInit(): void {
        this.profile = this.route.snapshot.data.user;

        const address = this.profile.user.address;
        this.profileForm.formGroup.patchValue({
            fullName: this.profile.user.fullName,
            email: this.profile.user.email,
            ...address && {
                address: ModelToObject(address)
            }
        });

        this.settingsForm.formGroup.patchValue({
            emailPreferences: this.profile.user.emailPreferences,
            lang: this.profile.user.lang
        })
        this.accountService.getLegacyLicenses().subscribe((l) => {
            this.licenses = l
        })
        this.getTooltipData();
    }

    getTooltipData() {

        this.knowledgeBaseService.getTooltipData('account')
            .subscribe((res: ToolTipinfo[]) => {

                this.accountTooltipArr = res;
                this.knowledgeBaseService.addGeneralExplation(res);

            }
        );
    }

    saveChanges() {
        this.errorObject = undefined;

        const form1 = GetFormGroupDirtyValues(this.profileForm.formGroup);
        const form2 = this.settingsForm.formGroup.dirty ? this.settingsForm.formGroup.value : {};
        // build a user object with just the id and the changed fields
        const user = Model.Assign(Model.New(User, {id: this.profile.user.id}), form1, form2);

        // update user
        this.accountService.updateAccount(user).subscribe(
            res => {
                this.profileForm.formGroup.markAsPristine();
                this.settingsForm.formGroup.markAsPristine();
                this.toastr.show($localize`:@@common-account-updated:The account has been successfully updated`, "", {
                    positionClass: 'toast-bottom-right',
                    timeOut: 2000,
                    enableHtml: true
                });
                if(user.lang && (this.language.currentLanguageCode != user.lang)) {
                    this.language.changeLanguage(user.lang);
                }
            },
            err => {
                this.errorObject = err;
                this.profileForm.setError(err);
                this.settingsForm.setError(err);
            }
        );
    }
}
