<app-dialog [errorObject]="errorObject">
    <app-dialog-stepper [currentStep]="currentStep"
                        [stepCount]="2"
                        (onPrevClick)="prevStep()"
                        (onShowDebugs)="showDebugs($event)"></app-dialog-stepper>
    <app-dialog-title i18n="@@my-classrooms-create-title">Classroom creation</app-dialog-title>
    <app-dialog-content>
        <form [formGroup]="classroomForm1"
              class="form-columns">
            <div class="app-dialog-step"
                 [ngClass]="{'hidden-field': currentStep != 1}">
                <mat-form-field appearance="outline"
                                floatLabel="always">
                    <mat-label i18n="@@my-classrooms-create-form-name-label">Your classroom's name</mat-label>
                    <input matInput
                           formControlName="name"
                           i18n-placeholder="@@my-classrooms-create-name-placeholder"
                           placeholder="Type in a name..."
                           autocomplete="off"
                           required>
                    <mat-error *ngIf="classroomForm1.controls.name.invalid">
                        {{ getInfoNameErrorMessage(classroomForm1.controls.name) }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="app-dialog-step"
                 [ngClass]="{'hidden-field': currentStep != 2}">
                <app-orgs-selection [(currentOrg)]="currentOrg">
                    <span i18n="@@create-classroom-dialog--organization-selection-message"
                          org-select-text>
                          Select or create the organization in which the class will be created.
                    </span>
                </app-orgs-selection>
            </div>
        </form>
    </app-dialog-content>
    <button *ngIf="currentStep == 1"
            mat-stroked-button
            appDialogAction
            matDialogClose>
        <span i18n="@@common-cancel"> Cancel </span>
    </button>
    <button *ngIf="currentStep > 1"
            mat-stroked-button
            appDialogAction
            i18n="@@common-back"
            (contextmenu)="showDebugs($event)"
            (click)="prevStep()">Back</button>
    <button *ngIf="currentStep == 1"
            mat-flat-button
            appDialogAction
            color="primary"
            [disabled]="!classroomForm1.valid || loading.isLoading || (loading.loadingChange | async)"
            (click)="nexStep()">
        <span i18n="@@common-next"> Next </span>
    </button>
    <button *ngIf="currentStep == 2"
            mat-flat-button
            appDialogAction
            color="accent"
            [disabled]="!currentOrg || !classroomForm1.valid || loading.isLoading || (loading.loadingChange | async)"
            (click)="submitClassroomForm()">
        <span i18n="@@common-create-button">Create</span>
    </button>
</app-dialog>