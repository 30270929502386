import { Validators } from "@angular/forms";
import { CustomFormBuilder } from "./custom-form-builder";
import { OrganizationTitle, OrganizationType } from "@applogic/model";
import { CustomFormField } from "./custom-form-field";
import { LanguageService } from "src/app/services/language.service";


export interface AddressFields {
    country: CustomFormField,
    line1: CustomFormField,
    line2: CustomFormField,
    city: CustomFormField,
    state: CustomFormField,
    zipcode: CustomFormField,
}

export interface CountryZipcodeFields {
    country: CustomFormField,
    zipcode: CustomFormField,
}

export class CustomFormUtils {

    public static SetAddressFields(form: CustomFormBuilder): AddressFields {

        const line1Field = form.addInput("line1", {
            label: $localize`:@@common-address-line1:Address Line 1`,
            validators: [Validators.required]
        });
        const line2Field = form.addInput("line2", {
            label: $localize`:@@common-address-line2:Address Line 2`
        });

        form.beginGroup("", {
            orientation: "horizontal"
        });

        const stateField = form.addInput("state", {
            label: $localize`:@@common-address-org-state:State or province`,
            validators: [Validators.required]
        });

        const cityField = form.addInput("city", {
            label: $localize`:@@common-address-city:City`,
            validators: [Validators.required]
        });

        form.endGroup();

        const { country, zipcode } = CustomFormUtils.SetCountryZipcodeFields(form);

        country.control.valueChanges.subscribe((country) => {
            if (!country) {
                stateField.label = $localize`:@@common-address-org-state:State or province`;
            }
            else if (country == "ca") {
                stateField.label = $localize`:@@common-address-province:Province`;
            }
            else {
                stateField.label = $localize`:@@common-address-state:State`;
            }
        });

        return  {
            country: country,
            line1: line1Field,
            line2: line2Field,
            city: cityField,
            state: stateField,
            zipcode: zipcode
        };
    }

    public static SetCountryZipcodeFields(form: CustomFormBuilder): CountryZipcodeFields {
        form.beginGroup("", {
            orientation: "horizontal"
        });

        const countryField = form.addCountry("country", {
            isRequired: true
        });

        const zipcodeField = form.addInput("zipcode", {
            label: $localize`:@@common-address-zipcode:ZIP Code`,
            validators: [Validators.required]
        });

        form.endGroup();

        countryField.control.valueChanges.subscribe((country) => {
            if (!country) {

            }
            else if (country == "ca") {
                zipcodeField.label = $localize`:@@common-address-postal-code:Postal Code`;
            }
            else {
                zipcodeField.label = $localize`:@@common-address-zipcode:ZIP Code`;
            }
        });

        return {
            country: countryField,
            zipcode: zipcodeField
        }
    }

    public static AddOrgTitleField(form: CustomFormBuilder) {
        
        const titleField = form.addSelect("title", {
            label: $localize`:@@tell-us-form-org-role-label:Your role in this organization`,
            options: [{
                value: "principal",
                label: $localize`:@@common-org-title-principal: Principal `
            }, {
                value: "secretary",
                label: $localize`:@@common-org-title-secretary: Secretary `
            }, {
                value: "teacher",
                label: $localize`:@@common-org-title-teacher: Teacher `
            }, {
                value: "councillor",
                label: $localize`:@@common-org-title-councillor:Councillor`
            }, {
                value: "professional",
                label: $localize`:@@common-org-title-professional: Education professional `
            }, {
                value: "remedial",
                label: $localize`:@@common-org-title-remedial: Speech Language Pathologist `
            }, {
                value: "other",
                label: $localize`:@@common-org-title-other: Other `
            }],
            validators: [Validators.required]
        });

        const otherTitleField = form.addInput("otherTitle", {
            label: $localize`:@@tell-us-form-org-role-enter-by-user-label:Enter the role in this organization`,
            validators: [Validators.required]
        });

        otherTitleField.hidden = true;
        otherTitleField.setDisableValidators(true);

        titleField.control.valueChanges.subscribe((v) => {
            if(v == OrganizationTitle.Other) {
                otherTitleField.setDisableValidators(true);
                otherTitleField.hidden = false;
            }
            else {
                otherTitleField.setDisableValidators(false);
                otherTitleField.hidden = true;
            }
        })

        return form;
    }

    public static AddOrgTypeField(form: CustomFormBuilder) {

        const orgTypeField = form.addSelect("type", {
            label: $localize`:@@common-org-type:Organization Type`,
            options: [
                {
                    value: OrganizationType.School,
                    label: $localize`:@@common-school:School`
                },
                {
                    value: OrganizationType.SchoolBoard,
                    label: $localize`:@@common-schoolboard:School Board`
                },
                {
                    value: OrganizationType.ServiceCenter,
                    label: $localize`:@@common-service:Service center`
                },
                {
                    value: OrganizationType.ServiceDistrict,
                    label: $localize`:@@common-school-district:School district`
                },
                {
                    value: OrganizationType.Other,
                    label: $localize`:@@common-other:Other`
                }
            ]
        });

        const orgTypeOtherField = form.addInput("typeOther", {
            label: $localize`:@@common-org-type-name:Organization Type Name`,
            validators: [Validators.required],
            trimSpaces: true
        });

        orgTypeOtherField.setDisableValidators(true);
        orgTypeOtherField.hidden = true;

        orgTypeField.control.valueChanges.subscribe((type) => {
            orgTypeOtherField.hidden = type != OrganizationType.Other;
            orgTypeOtherField.setDisableValidators(!orgTypeOtherField.hidden);
        });

        return {
            type: orgTypeField,
            typeOther: orgTypeOtherField
        }
    }
    
    public static AddLanguageField(form: CustomFormBuilder, languageService: LanguageService) {

        const languageField = form.addSelect("lang", {
            label: $localize`:@@common-communication-language:Language of communication`,
            options: languageService.languages.map((code) => {
                return {
                    label: languageService.getLanguageName(code),
                    value: code
                }
            })
        });

        return languageField;
    }

    public static AddEmailField(form: CustomFormBuilder, isRequired: boolean = true) {

        const validators = [Validators.email];
        if(isRequired) {
            validators.push(Validators.required);
        }

        const emailField = form.addInput("email", {
            label: $localize`:@@common-email: Email `,
            validators
        });

        return emailField;
    }
}