<app-design-card class="org-seats-allocation-card"
                 [clickable]="false">
    <design-card-header class="flex-column">
        <div class="flex-row flex-default-gap flex-wrap"
             style="align-items: center;">
            <span class="subscription-no">{{ subAlloc.subscription.subNo }}</span>
            <app-subscription-mode-chip [mode]="subAlloc.subscription.allocationMode"></app-subscription-mode-chip>
            <app-subscription-validity-label class="secondary-text"
                                             [subscription]="subAlloc.subscription"
                                             [showNumber]="false">
            </app-subscription-validity-label>
        </div>
    </design-card-header>
    <design-card-footer class="flex-column flex-default-gap flex-wrap child-full-width-distributed-row">
        <div>
            <app-checklist-popover [key]="'sub-' + subAlloc?.subscription?.subNo + '-change-mode-popover'"
                                   checklistItem="setTokenMode"
                                   checklistType="organization"
                                   placement="left-top">
                <p i18n="@@checklist-admin-popover-set-token-mode"
                   popupcontent>Start by clicking here to set the token distribution mode.</p>
            </app-checklist-popover>
            <button mat-flat-button
                    (click)="onConfigureClick(subAlloc)">
                <label i18n="@@common-configure-subscription">Configure subscription</label>
            </button>
        </div>
        <div>
            <app-checklist-popover [key]="'sub-' + subAlloc?.subscription?.subNo + '-allocate-tokens'"
                                   checklistItem="allocateTokens"
                                   checklistType="organization"
                                   placement="left-top">
                <p i18n="@@common-click-here"
                   popupcontent>Click Here</p>
            </app-checklist-popover>
            <button *ngIf="subAlloc.subscription.allocationMode == 'manual'"
                    mat-stroked-button
                    color="accent"
                    (click)="transferSeatsForSubscription(subAlloc)"
                    i18n="@@seat-allocation-section-assign-remove-tokens-button"
                    class="mr-35 header-card-button">Assign/Remove tokens</button>
        </div>
    </design-card-footer>
    <design-card-content class="flex-column flex-default-gap">
        <div class="subscription-sub-info-line"
             *ngFor="let g of subAlloc.subscription.grants">
            <app-seat-token [productCode]="g.productShortCode.toLowerCase()"
                            [displayType]="'icon-only'">
            </app-seat-token>
            <div class="allocation-info-desc-line"
                 *ngIf="g.productShortCode.toLowerCase() as productcode"
                 [innerHtml]="getSubDesc(subAlloc, g)">
            </div>
        </div>

        <app-allocation-list-panel [orgAllocs]="orgAllocs"
                                   [subAlloc]="subAlloc"
                                   [contextMenu]="memberOptionsMenu"></app-allocation-list-panel>
    </design-card-content>
</app-design-card>


<mat-menu #memberOptionsMenu="matMenu"
          overlapTrigger="true"
          xPosition="before">
    <ng-template matMenuContent
                 let-member="member">

        <button mat-menu-item
                (click)="transferSeats(member.subAlloc, member.alloc)">
            <mat-icon color="primary">refresh</mat-icon>
            <span i18n="@@seat-allocation-section-transfer-seats">Send tokens to this member</span>
        </button>

        <button mat-menu-item
                (click)="removeAllocation(member.subAlloc, member.alloc)">
            <mat-icon color="warn">close</mat-icon>
            <span i18n="@@seat-allocation-section-remove-allocation">Remove member</span>
        </button>

    </ng-template>
</mat-menu>