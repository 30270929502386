import { Member, Model } from "@uon/model";
import { SubscriptionAllocationMode } from "../subscription/subscription-allocation-mode";

@Model()
export class ChecklistTrialOrganization {

    @Member()
    subNo: string;

    @Member()
    subscriptionId: string;

    @Member()
    allocationMode: SubscriptionAllocationMode;

    @Member()
    orgId: string;

    @Member()
    setTokenMode: boolean;

    @Member()
    inviteMembers: boolean;

    @Member() 
    allocateTokens: boolean;

    @Member() 
    createQuotation: boolean;

    @Member() 
    createInvoice: boolean;

    @Member() 
    payInvoice: boolean;

    public static FromData(data: any): ChecklistTrialOrganization
    {
        let result = Model.New(ChecklistTrialOrganization, {
            subscriptionId: data.subscriptionId,
            allocationMode: data.allocationMode,
            subNo: data.subNo,
            orgId: data.orgId,
            setTokenMode: data.setTokenMode,
            inviteMembers: data.inviteMembers,
            allocateTokens: data.allocateTokens,
            createQuotation: data.createQuotation,
            createInvoice: data.createInvoice,
            payInvoice: data.payInvoice
        });

        return result;
    }

    public ToData(): any {
        let result: any = {};
        result.subscriptionId = this.subscriptionId;
        result.allocationMode = this.allocationMode;
        result.subNo = this.subNo;
        result.orgId = this.orgId;
        result.setTokenMode = this.setTokenMode;
        result.inviteMembers = this.inviteMembers;
        result.allocateTokens = this.allocateTokens;
        result.createQuotation = this.createQuotation;
        result.createInvoice = this.createInvoice;
        result.payInvoice = this.payInvoice;
        return result; 
    }


}