import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, Input, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';

interface BreakpointInfo {
    key: string,
    scale: number;
    width?: number;
    height?: number;
}

@Component({
    selector: 'app-container-scaler',
    templateUrl: './container-scaler.component.html',
    styleUrl: './container-scaler.component.scss'
})
export class ContainerScalerComponent {

    @Input()
    width: number;

    @Input()
    height: number;

    @Input()
    widthUnits: string = "px";

    @Input()
    heightUnits: string = "px";

    @Input()
    scale: number = 1;

    private _currentBreakpoint: BreakpointInfo;
    private _currentWidth: number;
    private _currentHeight: number;

    getHostStyles() {
        const styles: any = {
        };

        if (this._currentWidth) {
            this.setFixedStyles(styles, 'width', this._currentWidth + this.widthUnits);
        }

        if (this._currentHeight) {
            this.setFixedStyles(styles, 'height', this._currentHeight + this.heightUnits);
        }

        return styles;
    }

    getStyles() {
        const styles = {

        };

        if ((this._currentHeight || this._currentWidth) && this.scale) {
            if (this._currentWidth) {
                this.setFixedStyles(styles, 'width', this._currentWidth / this.scale + this.widthUnits);
            }

            if (this._currentHeight) {
                this.setFixedStyles(styles, 'height', this._currentHeight / this.scale + this.heightUnits);
            }

            styles['transform'] = "scale(" + this.scale + ")";
            styles['transform-origin'] = "top left";
        }

        return styles;
    }

    private _breakpointSub: Subscription;

    private _allBreakpoints: BreakpointInfo[] = [
        {
            key: Breakpoints.XSmall,
            scale: 0.85
        },
        {
            key: Breakpoints.Small,
            scale: 1
        },
        {
            key: Breakpoints.Medium,
            scale: 1
        },
        {
            key: Breakpoints.Large,
            scale: 1
        },
        {
            key: Breakpoints.XLarge,
            scale: 1
        }
    ];


    @Input()
    set sizeXSmall(sizes: { width: number, height: number }) {
        let b = this._allBreakpoints.find(b => b.key == Breakpoints.XSmall);

        b.width = sizes.width;
        b.height = sizes.height;
    }

    @Input()
    set sizeSmall(sizes: { width: number, height: number }) {
        let b = this._allBreakpoints.find(b => b.key == Breakpoints.Small);

        b.width = sizes.width;
        b.height = sizes.height;

        b = this._allBreakpoints.find(b => b.key == Breakpoints.XSmall);
        if (!b?.width) {
            b.width = sizes.width;
            b.height = sizes.height;
        }
    }

    private ngZone: NgZone;
    readonly breakpoints: BreakpointObserver;
    protected ref: ChangeDetectorRef;
    private elementRef: ElementRef;

    constructor(injector: Injector) {
        this.ngZone = injector.get(NgZone);
        this.breakpoints = injector.get(BreakpointObserver);
        this.ref = injector.get(ChangeDetectorRef);
        this.elementRef = injector.get(ElementRef);
    }

    ngOnInit() {
        this._currentWidth = this.width;
        this._currentHeight = this.height;

        this._breakpointSub = this.breakpoints
            .observe(this._allBreakpoints.map(b => b.key))
            .subscribe(result => {

                // Trouver le breakpoint actif le plus large
                const activeBreakpoints = this._allBreakpoints.filter(bp => result.breakpoints[bp.key]);
                const largestActiveBreakpoint = activeBreakpoints[activeBreakpoints.length - 1];

                if (largestActiveBreakpoint) {
                    this.updateBreakpoint(largestActiveBreakpoint);
                }
            });
    }

    ngOnDestroy() {
        if (this._breakpointSub) {
            this._breakpointSub.unsubscribe();
        }
    }

    setFixedStyles(obj: any, type: 'height' | 'width', val: string) {
        obj[type] = val;
        obj["min-" + type] = val;
        obj["max-" + type] = val;
    }

    private refreshBreakpoint(force: boolean = false) {
        this.ngZone.run(() => {
            for (const b of this._allBreakpoints) {
                if (this.breakpoints.isMatched(b.key)) {
                    this.updateBreakpoint(b);
                }
            }
        });
    }
    
    private updateBreakpoint(breakpoint: BreakpointInfo, force: boolean = false) {
        if (!force && (breakpoint?.key == this._currentBreakpoint?.key)) {
            return;
        }

        this.scale = breakpoint.scale;
        this._currentBreakpoint = breakpoint;


        if (breakpoint.width) {
            this._currentWidth = breakpoint.width;
        }
        else {
            this._currentWidth = this.width;
        }

        if (breakpoint.height) {
            this._currentHeight = breakpoint.height;
        }
        else {
            this._currentHeight = this.height;
        }

        const hostStyles = this.getHostStyles();
        for (const key of Object.keys(hostStyles)) {
            this.elementRef.nativeElement.style[key] = hostStyles[key];
        }

        if(force) {
            this.ref.detectChanges();
        }
    }
}
