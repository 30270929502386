// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    configName: '', // To be set by the AppConfigService.
    production: false,
    multiLanguages: true,

    serverKey: "dev",
    serverType: "dev",
    apiDirectoryServiceUrl: "https://appligogiques-web.s3.amazonaws.com/DirectoryService.json",

    // apiBaseUrl: 'http://localhost:8080',
    // authBaseUrl: 'http://localhost:8081',

    googleMapApiKey: 'AIzaSyCL6AxpPkp0u_tgSW6sKNdxAS2-12y-AFM',
    stripeApiKey: 'pk_test_325jMniPg5PQKjCzF1xwJWNW',
    apiBaseUrl: 'https://api.dev.applogic.ai',
    authBaseUrl: 'https://auth.dev.applogic.ai',
    googleAnalytics: {
        key: 'G-GDLPFZLK90',
        verbose: true
    },
    siteVersion: "2.0.0",
    inviteMode: {
        enabled: true,
        redirectDomain: "id.appligogiques.com"
    },

    googleIdentityService: {
        enabled: true,
        frontendClientId: "878692877219-bv09usa7vrf70q52urr5g7njddgkk2rn.apps.googleusercontent.com"
    },
    theme: {
        showToggle: true,
        default: 'light-theme'
    },
    email: {
        assetsBaseUrl: "https://appligogiques-web.s3.amazonaws.com/emails/"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
