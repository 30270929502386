import { Model, ID, Member, ArrayMember } from "@uon/model";

let keys = ["identifier", "version", "lang", "platform", "promo", "code"];


@Model()
export class NewsTargetAudience {

    @ArrayMember(String)
    productCodes: string[];

    @ArrayMember(String)
    bundleIds: string[];

    @ArrayMember(String)
    buildVersions: string[];

    @ArrayMember(String)
    releases: string[];

    @ArrayMember(String)
    platforms: string[];

    @ArrayMember(String)
    newsVersion: string[];

    @Member()
    promoBuild: number;

    @Member()
    lang: string;
}


@Model()
export class NewsItem {

    @ID()
    id: string;

    @Member()
    title: string;

    @Member()
    content: string;

    @Member()
    audience: NewsTargetAudience;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;
}