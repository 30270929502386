<mat-card appearance="outlined" class="general-explanation-block" *ngIf="content">

    <h4 class="heading">{{section}} general explanation</h4>

    <mat-card-content>

        <p [innerHtml]="content"></p>

    </mat-card-content>

</mat-card>