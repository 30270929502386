<table class="import-students-table">
    <tbody>
        <tr>
            <td class="column-header"
                i18n="@@common-student-fullname"> Student’s name </td>
            <td class="column-header"
                i18n="@@common-username"> Profile name </td>
            <td class="column-header"
                i18n="@@common-password"> Password </td>
            <td class="column-header warning-column">
                <div *ngIf="invalidCount > 0">
                    <mat-icon color="warn">warning</mat-icon>
                </div>
            </td>
        </tr>
        <tr *ngFor="let entry of entries; let i = index">
            <td [ngClass]="{'cell-warning': entry.warnings && entry.warnings['fullName']}">
                <input type="text"
                       [display]="entry.warning && entry.warnings && entry.warnings['fullName'] ? true : false"
                       [tooltip]="entry.warnings ? entry.warnings['fullName'] : ''"
                       [(ngModel)]="entry.fullName"
                       (input)="onFullNameChange(entry, i, $event)"
                       autocomplete="off"
                       (paste)="onPaste(entry, i, $event)"
                       matInput>
            </td>
            <td [ngClass]="{'cell-warning': entry.warnings && entry.warnings['username']}">
                <input type="text"
                       [display]="entry.warning && entry.warnings && entry.warnings['username'] ? true : false"
                       [tooltip]="entry.warnings ? entry.warnings['username'] : ''"
                       [(ngModel)]="entry.username"
                       (input)="onUserNameChange(entry, i, $event)"
                       autocomplete="off"
                       (paste)="onPaste(entry, i, $event)"
                       matInput>
            </td>
            <td [ngClass]="{'cell-warning': entry.warnings && entry.warnings['password']}">
                <input type="text"
                       [display]="entry.warning && entry.warnings && entry.warnings['password'] ? true : false"
                       [tooltip]="entry.warnings ? entry.warnings['password'] : ''"
                       [(ngModel)]="entry.password"
                       (input)="onPasswordChange(entry, i, $event)"
                       autocomplete="off"
                       (paste)="onPaste(entry, i, $event)"
                       matInput>
            </td>
            <td *ngIf="entry.warning">
                <div [tooltip]="entry.warning">
                    <mat-icon color="warn">warning</mat-icon>
                </div>
            </td>
        </tr>
    </tbody>
</table>