<div class="progress-block"
     *ngIf="isloading > 0">
    <mat-progress-bar class="progress-bar"
                      mode="indeterminate">
    </mat-progress-bar>
</div>

<mat-tab-group *ngIf="seasons && (seasons.length > 1)"
               [selectedIndex]="seasons.indexOf(selectedSeason)"
               [@.disabled]="true"
               animationDuration="0ms"
               class="custom-tabs"
               (selectedTabChange)="onSeasonTabChanged($event);">
    <mat-tab *ngFor="let season of seasons">
        <ng-template mat-tab-label>
            <div i18n="@@common-season">Season</div>&nbsp;{{season}}
        </ng-template>
    </mat-tab>
</mat-tab-group>

<div class="progress-table-container"
     [hidden]="isloading != 0">
    <div class="progress-data-by-button">

        <mat-chip-listbox #chipList
                       *ngIf=" isloading == 0">

            <mat-chip-option *ngFor="let column of selectedColumns | colHeaderFilter:false"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="removeProgressGameChip(column)"
                      [style.background-color]="column.color_code">
                {{column.name}}
                <mat-icon matChipRemove
                          *ngIf="removable">
                    cancel
                </mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>

        <div class="three-dot-button"
             *ngIf=" isloading == 0">
            <button mat-stroked-button
                    color="accent"
                    (click)="openMSMDailogBox()"
                    style="margin-right: 10px;">
                <span i18n="@@msm-game-list">Games List</span>
            </button>

        </div>

    </div>

    <div class="activity-Data"
        [hidden]="isSelectedDate != false">
         <app-msm-activity-list [selectedSeason]="selectedSeason"></app-msm-activity-list>
    </div>
    <div class="date-section"
         [hidden]="isSelectedDate != true || isloading != 0">
        <div class="top-section compact-input">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@msm-date-range-lable">
                    Enter a date range
                </mat-label>
                <input matInput
                       placeholder="Start date - End date"
                       [(ngModel)]="dateTimeRange"
                       [selectMode]="'range'"
                       [owlDateTimeTrigger]="dtRange1"
                       [owlDateTime]="dtRange1"
                       [max]="currentDate"
                       (ngModelChange)="onDateRangeChange($event)"
                       autocomplete="off">
                <mat-icon matSuffix
                          (click)="dtRange1.open()"
                          class="icon-calendar">
                    event
                </mat-icon>
                <owl-date-time [pickerType]="'calendar'"
                               #dtRange1>
                </owl-date-time>
            </mat-form-field>


        </div>

        <app-msm-date-list [selectedSeason]="selectedSeason"></app-msm-date-list>
    </div>
</div>