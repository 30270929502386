

import { Model, ID, Member, ArrayMember } from '@uon/model';
import { Student } from '../user/student.model';
import { User } from '../user/user.model';
import { Organization } from './organization.model';
import { ClassroomStudentInvite } from './invite.model';

@Model()
export class ClassroomCollaborator {

    @Member()
    user: User;

    @Member()
    addedOn: Date;
}

@Model()
export class ClassroomLoginConfig {

    @Member()
    code: string;

    @Member()
    createdOn: Date;
}

export enum ClassroomRole {
    Owner = 'owner',
    Collaborator = 'collaborator',
    Admin = 'admin'
}


@Model()
export class Classroom {

    @ID()
    id: string;

    @Member()
    name: string;

    @Member()
    slug: string;

    @Member()
    grade: string;

    @Member()
    owner: User;

    @ArrayMember(Student)
    students: Student[];

    @Member()
    organization: Organization;

    @Member()
    studentInvitation: ClassroomStudentInvite;

    @ArrayMember(ClassroomCollaborator)
    collaborators: ClassroomCollaborator[];

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    isDeleted: boolean;

    @Member()
    deletedAt: Date;

    @Member()
    deletedBy: User;

    @Member()
    login: ClassroomLoginConfig;
    
    // Denormalised data.
    @Member()
    _studentsCount: number;

    @Member()
    _currentUserRole: ClassroomRole;
}