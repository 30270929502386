<div *ngIf="formInputStyle" class="compact-input">
    <mat-form-field appearance="outline"
                    style="width: 100%;"
                    subscriptSizing="dynamic">
        <input #searchInput
               [placeholder]="' 🔍' + _placeholder"
               matInput>
    </mat-form-field>
</div>
<ng-container *ngIf="!formInputStyle">
    <input #searchInput
           style="width: 100%"
           [placeholder]="_placeholder">
</ng-container>