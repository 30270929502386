import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopUpMessageDialogComponent } from '../core/error-pop-up-message-dialog/error-pop-up-message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorDialogService {

    constructor(private matDialog:MatDialog) { }

    openErrorDialog(userMessage: string, errorObject: any, navigateRoot: boolean){
        const ref = this.matDialog.open( ErrorPopUpMessageDialogComponent,
            {
                panelClass:"error-pop-up-message",
                data: {
                    userMessage,
                    errorObject,
                    navigateRoot
                }
            })
    }
}