import { Injector } from "@uon/core";
import { ValidationFailure } from "@uon/model";
import { CompareDate, LocaleDateStringToDate } from "./date.utils";

export function MaxArraySize(maxLength: number) {
    const func = (model: any, key: string, val: any, injector?: Injector) => {
        if ( (val !== undefined) && Array.isArray(val)) {
            if(val.length > maxLength) {
                throw new ValidationFailure(MaxArraySize, key, val, {
                    msg: `above maximum elements`,
                    maxLength: maxLength,
                    length: val.length
                });
            }
        }

        return val;
    };
    return func;
}

export function MinArraySize(minLength: number) {
    const func = (model: any, key: string, val: any, injector?: Injector) => {
        if ( (val !== undefined) && Array.isArray(val)) {
            if(val.length < minLength) {
                throw new ValidationFailure(MinArraySize, key, val, {
                    msg: `below minimum elements`,
                    maxLength: minLength,
                    length: val.length
                });
            }
        }

        return val;
    };
    return func;
}

export function MinDate(date: Date|"now", utc: boolean = true) {
    const func = (model: any, key: string, val: any, injector?: Injector) => {
        if (val !== undefined) {
            let dateValue: Date;

            if(val instanceof Date) {
                dateValue = val;
            }
            else {
                try {
                    // If string is not explicitly a UTC data reprensation, the date must be converted to a locale date.
                    if(!val.endsWith("Z")) {
                        dateValue = LocaleDateStringToDate(val);
                    }
                    else {
                        dateValue = new Date(val);
                    }
                }
                catch(ex) {

                }
            }

            if(dateValue && (dateValue instanceof Date)) {
                let minDate: Date;

                if(date == "now") {
                    minDate = new Date();
                }
                else {
                    minDate = date;
                }

                const result = CompareDate(dateValue, minDate, utc);
                if(result == -1) {
                    throw new ValidationFailure(MinDate, key, val, {
                        msg: `below minimum date`,
                        date,
                        minDate,
                        value: val,
                        dateValue,
                        result
                    });
                }
            }
            else {
                throw new ValidationFailure(MinDate, key, val, {
                    msg: `not a date`,
                    value: val,
                });
            }
        }

        return val;
    };
    return func;
}

