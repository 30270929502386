<mat-tab-group class="app-mat-tab-group centered-tab-group medium full-width"
               mat-stretch-tabs="false">
    <mat-tab class="form-tab"
             bodyClass="app-tab--medium"
             [label]="tab.label"
             *ngFor="let tab of data.tabs">
        <app-simple-chart type="bar"
                          [style.height]="'400px'"
                          [data]="tab.data"
                          [options]="tab.options"
                          [showLegend]="true"></app-simple-chart>
    </mat-tab>
</mat-tab-group>