import { Component, Input } from '@angular/core';
import { MMOChip } from '../mmo-game-progress/mmo-game-progress.model';

@Component({
    selector: 'app-mmo-tooltip',
    templateUrl: './mmo-tooltip.component.html',
    styleUrl: './mmo-tooltip.component.scss'
})
export class MmoTooltipComponent {

    protected _chip: MMOChip;

    @Input()
    set chip(chip: MMOChip) {
        this._chip = chip;
        this.completedlevels = chip.completedlevels.sort(function (a, b) {
            return a - b;
        });

        this.levels = chip.levels.sort(function (a, b) {
            return a - b;
        });
    }

    @Input()
    context: 'activity'|'date';

    protected completedlevels: number[];
    protected levels: number[];
}
