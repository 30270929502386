import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Classroom, ClassroomHomework, ClassroomHomeworkStudent, ClassroomHomeworkWordsSource, FieldValidationType, SearchList, Student, WordListWord, WordSyntaxOptions } from '@applogic/model';
import { ClassroomService } from 'src/app/classroom/classroom.service';
import { EqolService } from 'src/app/eqol/eqol.service';
import { FormService } from 'src/app/services/form.service';
import { LanguageInfo, LanguageService } from 'src/app/services/language.service';
import { HomeworkService } from '../homework.service';
import { environment } from 'src/environments/environment';
import { ProductService } from 'src/app/services/product.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CreateWordListPanelComponent } from '../create-word-list-panel/create-word-list-panel.component';
import { AngularUtils } from 'src/app/services/angular-utils';
import { StudentListComponent } from 'src/app/student/student-list/student-list.component';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { CustomFormBuilder } from 'src/app/core/form-builder/custom-form-builder';
import { ApplogicUtils } from 'src/app/services/applogic-utils';
import { CustomFormField } from 'src/app/core/form-builder/custom-form-field';
import { Model } from '@uon/model';

interface HomeworkCreateDialogData {
    classroom: Classroom,
    // If provided, the dialog will be opened for updating an existing homework instead of creating a new one.
    homework?: ClassroomHomework
}

@Component({
    selector: 'app-homework-create-dialog',
    templateUrl: './homework-create-dialog.component.html',
    styleUrls: ['./homework-create-dialog.component.scss']
})
export class HomeworkCreateDialogComponent implements OnInit, AfterViewInit {

    ApplogicUtils = ApplogicUtils;

    step1Form = new CustomFormBuilder();
    step2Form = new CustomFormBuilder();
    step3Form = new CustomFormBuilder();

    showError: string;

    homework: ClassroomHomework;
    classroom: Classroom;

    eqolLists: SearchList[] = [];

    isEnabled: boolean;
    classroomShared: boolean;

    wordSyntaxOptions: WordSyntaxOptions;

    eqolListId: string;

    changed: boolean = false;

    words: WordListWord[] = [];

    languages: LanguageInfo[] = [];
    selectedLanguage: string = "fr";

    wordsSource: ClassroomHomeworkWordsSource = ClassroomHomeworkWordsSource.EQOL;

    @ViewChild(StudentListComponent, { static: false })
    studentList: StudentListComponent;

    @ViewChild(CreateWordListPanelComponent, { static: false })
    wordListPanel: CreateWordListPanelComponent;

    public WordsSource = ClassroomHomeworkWordsSource;

    protected currentStep: number = 1;

    private _eqolSelectField: CustomFormField;
    private _wordsSourceField: CustomFormField;
    private _langField: CustomFormField;
    private _eqolWordListField: CustomFormField;
    private _manualWordListField: CustomFormField;
    private _studentsSelectionField: CustomFormField;
    private _classroomSharedField: CustomFormField;



    constructor(@Inject(MAT_DIALOG_DATA) public data: HomeworkCreateDialogData,
        private mdDialogRef: MatDialogRef<HomeworkCreateDialogComponent, ClassroomHomework>,
        private homeworkService: HomeworkService,
        private eqolService: EqolService,
        private classroomService: ClassroomService,
        public formService: FormService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private languageService: LanguageService,
        public productService: ProductService) {
        this.languages = this.languageService.getLanguagesInfo();

        this.initControls();

        this.bindControls();

    }

    initControls() {
        this.step1Form.formService = this.formService;
        this.step1Form.addFormControl("label", FieldValidationType.HomeworkLabel, {
            isRequired: true
        });
        this.step1Form.addFormControl("desc", FieldValidationType.HomeworkDesc, {
            isRequired: false,
            data: {
                rows: 5
            }
        });

        this._wordsSourceField = this.step3Form.addSelect("wordsSource", {
            style: "radio",
            options: [
                {
                    value: ClassroomHomeworkWordsSource.EQOL,
                    label: $localize`:@@classroom-homework-words-list-eqol-type:Link to an EQOL list`
                },
                {
                    value: ClassroomHomeworkWordsSource.Manual,
                    label: $localize`:@@classroom-homework-words-list-manual-type:Enter words manually`
                }
            ]
        });

        this._eqolWordListField = this.step3Form.addFormGroupTemplate("", 0);
        this._manualWordListField = this.step3Form.addFormGroupTemplate("", 1);

        this._eqolSelectField = this.step3Form.addSelect("eqolListId", {
            options: []
        });

        this.step2Form.addToggle("isEnabled", {
            label: $localize`:@@classroom-homework-access-options:Access Options`,
            hint: $localize`:@@classroom-homework-isenabled-property-description:If list is enabled, the list will be shown to people that have access to it.`
        });

        this._classroomSharedField = this.step2Form.addToggle("classroomShared", {
            label: $localize`:@@classroom-homework-classroom-shared:Shared with all students`
        });

        this._studentsSelectionField = this.step2Form.addFormGroupTemplate("", 0);

        this.step2Form.addSeparator();

        this._langField = this.step2Form.addSelect("lang", {
            label: $localize`:@@common-language: Language `,
            options: this.languages.map(l => {
                return {
                    value: l.code,
                    label: l.label
                };
            }),
            style: 'right',
            validators: [Validators.required]
        });

        this.step2Form.addSeparator();
    }

    bindControls() {
        this._classroomSharedField.control.valueChanges.subscribe((v) => {
            this._studentsSelectionField.hidden = v;
        });

        this._wordsSourceField.control.valueChanges.subscribe((v) => {
            this.wordsSource = v;
            this._eqolWordListField.hidden = v != ClassroomHomeworkWordsSource.EQOL;
            this._manualWordListField.hidden = !this._eqolWordListField.hidden;
            this._eqolSelectField.hidden = v != ClassroomHomeworkWordsSource.EQOL;
            if (v == ClassroomHomeworkWordsSource.EQOL) {
                this._eqolSelectField.setDisableValidators(false);
            }
            else {
                this._eqolSelectField.setDisableValidators(true);
            }
        });

        this._langField.control.valueChanges.subscribe((v) => {
            this._wordsSourceField.hidden = v != "fr";

            if (v != this.selectedLanguage) {
                this.selectedLanguage = v;
                this.changed = true;
                if (this.wordsSource == ClassroomHomeworkWordsSource.EQOL) {
                    if (v != "fr") {
                        this._wordsSourceField.control.setValue(ClassroomHomeworkWordsSource.Manual);
                    }
                }
            }
        });
    }


    ngOnInit(): void {
        this.homework = this.data.homework;
        this.classroom = this.data.classroom;

        let label: string = this.homework ? this.homework.label : '';
        let desc: string = this.homework ? this.homework.desc : '';
        let eqolListId: string = this.homework?.eqolListId;
        this.eqolListId = eqolListId;
        this.classroomShared = this.homework ? this.homework.classroomShared : true;
        this.isEnabled = this.homework ? this.homework.isEnabled : true;
        this.step2Form.patch({
            isEnabled: this.isEnabled
        });
        this.wordSyntaxOptions = new WordSyntaxOptions();
        if (this.homework) {
            this.setHomework(this.homework);
        }
        else {
            this.setHomework(Model.New(ClassroomHomework, {

            }));
        }

        this.classroomService.getEqolLists(this.classroom).subscribe((result) => {
            this.eqolLists = result;
            this._eqolSelectField.data.options = result.map(e => {
                return {
                    value: e.id,
                    label: e.name
                };
            })
        });
    }

    ngAfterViewInit() {
        // Have to wait for ngAfterViewInit() to get the reference to the "studentList" but
        // we can't modify it yet otherwise it will throw the following error: https://angular.io/errors/NG0100
        setTimeout(() => {
            const items = this.homework?.students?.map((s) => {
                return this.classroom.students.find(cs => cs.id == s.studentId);
            }).filter(s => s) || [];
            this.studentList.selectItems(items);
        });

        this.step1Form.formGroup.valueChanges.subscribe(() => {
            this.changed = true;
        });
        this.step2Form.formGroup.valueChanges.subscribe(() => {
            this.changed = true;
        });
        this.step3Form.formGroup.valueChanges.subscribe(() => {
            this.changed = true;
        });
    }

    /**
     * Handle page visibility change events
     */
    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange() {
        if (this.homework) return;

        switch (document.visibilityState) {
            case "hidden":

                break;
            case "visible":
                this.eqolListId = this._eqolSelectField.control.value;

                this.classroomService.getEqolLists(this.classroom).subscribe((result) => {
                    this.eqolLists = result;
                    this.cdr.detectChanges();

                    // Unselect the eqol list if it was removed.
                    if (!this.eqolLists.find(l => l.id == this.eqolListId)) {
                        this.eqolListId = undefined;
                    }

                    this.step3Form.patch({
                        eqolListId: this.eqolListId
                    });

                    // The following line is required. Otherwise angular throw the following error:
                    // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value for 'mat-form-field-should-float': 'false'. Current value: 'true'.
                    this.cdr.detectChanges();
                });
                break;
        }
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: HomeworkCreateDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<HomeworkCreateDialogComponent, HomeworkCreateDialogData, ClassroomHomework>(dialogService, dialog, HomeworkCreateDialogComponent, {
            data,
        }, config);
    }

    private setHomework(homework: ClassroomHomework) {
        this.step1Form.patch({
            label: homework.label || "",
            desc: homework.desc || ""
        });
        this.step2Form.patch({
            isEnabled: homework.isEnabled ?? true,
            lang: homework.lang || this.languageService.currentLanguageCode,
            classroomShared: homework.classroomShared ?? true
        });
        this.step3Form.patch({
            ...homework.eqolListId && { eqolListId: homework.eqolListId },
            wordsSource: homework.wordsSource ?? ClassroomHomeworkWordsSource.Manual
        });

        if (homework.wordsyntaxOptions) {
            this.wordSyntaxOptions = homework.wordsyntaxOptions.clone();
        }

        if (homework.words) {
            // Generate a clone of the list of words to avoid modifying the homework directly.
            this.words = JSON.parse(JSON.stringify(homework.words));
        }
    }

    private generateHomework() {
        let homework: ClassroomHomework = Model.New(ClassroomHomework, {

        });
        if (this.homework?.id) homework.id = this.homework.id;
        homework.classroomId = this.classroom.id;

        Model.Assign(homework, this.step1Form.value, this.step2Form.value, this.step3Form.value);

        if (this.wordListPanel) {
            homework.words = this.wordListPanel.getWords();
        }
        homework.wordsyntaxOptions = this.wordSyntaxOptions.clone();

        if (this.studentList.selection.selected.length > 0) {
            let students: ClassroomHomeworkStudent[] = [];
            this.studentList.selection.selected.map((s) => {
                if (!s) return;

                let chs: ClassroomHomeworkStudent;

                if (this.homework && this.homework.students) {
                    chs = this.homework.students.find((hs) => hs.studentId === s.id);
                }

                if (!chs) {
                    chs = new ClassroomHomeworkStudent();
                    chs.studentId = s.id;
                }

                students.push(chs);
            });
            homework.students = students;
        } else {
            homework.students = [];
        }

        return homework;
    }

    createHomework() {
        if (this.wordsSource == ClassroomHomeworkWordsSource.Manual) {
            this.wordListPanel.validate();
            if (!this.wordListPanel.isValid) {

                return;
            }
        }

        let homework: ClassroomHomework = this.generateHomework();

        this.homeworkService.createHomework(homework).subscribe((newHomework) => {
            this.homework = newHomework;
            this.mdDialogRef.close(this.homework);
        },
            err => {
                if (err.error) {
                    this.showError = err.error.message;
                } else {
                    this.showError = err.message;
                }
            });
    }

    updateHomework() {
        if (this.wordsSource == ClassroomHomeworkWordsSource.Manual) {
            this.wordListPanel.validate();
            if (!this.wordListPanel.isValid) {
                let el = document.getElementById("list-of-words-section");
                el.scrollIntoView({ behavior: 'smooth' });
                return;
            }
        }

        let homework: ClassroomHomework = this.generateHomework();

        this.homeworkService.updateHomework(homework, this.homework).subscribe((updatedHomework) => {
            this.homework.label = updatedHomework.label;
            this.homework.desc = updatedHomework.desc;
            this.homework.isEnabled = updatedHomework.isEnabled;
            this.homework.classroomShared = updatedHomework.classroomShared;
            this.homework.students = updatedHomework.students;
            this.homework.updatedOn = updatedHomework.updatedOn;
            this.homework.eqolListId = updatedHomework.eqolListId;
            this.homework.wordsyntaxOptions = updatedHomework.wordsyntaxOptions;
            this.homework.lang = updatedHomework.lang;
            this.homework.wordsSource = updatedHomework.wordsSource;
            this.homework.words = updatedHomework.words;
            this.mdDialogRef.close(this.homework);
        },
            err => {
                if (err.error) {
                    this.showError = err.error.message;
                } else {
                    this.showError = err.message;
                }
            });
    }

    goToEQOL(eqolListId?: string) {
        const short_locale = environment.production
            ? '/' + this.languageService.currentLanguageCode
            : '';

        let url = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}${short_locale}/eqol`;
        if (eqolListId) {
            url += "?search=" + eqolListId;
        }
        window.open(url, '_eqol');
    }

    getEqolListName(eqolListId: string): string {
        let list = this.eqolLists.find(l => l.id == eqolListId);

        if (list) {
            return list.name;
        }

        return "";
    }

    onStudentsSelectionChanged(selection: SelectionModel<Student>) {
        this.changed = true;
    }

    onWordsListChanged(c: CreateWordListPanelComponent) {
        this.changed = true;
    }

    showDebugs($event) {
        AngularUtils.showFormState("Step1Form:", this.step1Form.formGroup);
        AngularUtils.showFormState("Step2Form:", this.step2Form.formGroup);
        AngularUtils.showFormState("Step3Form:", this.step3Form.formGroup);
        console.log("WordListPane:" + this.wordListPanel?.isValid);
        console.log("Changed:" + this.changed);
    }

    prevStep() {
        this.currentStep--;
    }

    nextStep() {
        this.currentStep++;
    }
}
