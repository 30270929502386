import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Product, Student, SubscriptionSeat } from '@applogic/model';
import { CommonColumnHeaderType, CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-student-list',
    templateUrl: './student-list.component.html',
    styleUrls: ['./student-list.component.scss']
})
export class StudentListComponent extends CommonListComponent<Student> implements OnInit {

    protected _students: Student[] = [];

    products: Product[];

    @Input()
    displayForSelection: boolean = false;

    @Input() actionTemplate;

	@Input()
    set students(val: Student[]) {
        if(this._students != val) {
            this._students = val || [];
            this.setPreloadItems(this._students);
            this.studentsChange.emit(val);
        }
    }

    get students() {
        return this._students;
    }

    @Output()
    studentsChange: EventEmitter<Student[]> = new EventEmitter<Student[]>();
    
    constructor(private injector: Injector, private productService: ProductService) {
        super(injector);

        // Set default values
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 15, 20];

        this.supportCompactMode = true;

        this.rendererOptions.showSelectColumnsActionHeader = false;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.productService.getProducts().then((response) => {
            this.initColumns(response);
            this.refreshItems("Init Columns");
        });
    }

    initColumns(products: Product[]) {
        this.products = products;
        this.updateColumns(true);

        let key = 'applogic/classroom/student-list';

        if(this.displayForSelection) {
            key += "-selection";
        }

        this.initPersistentKey(key);

        this.addColumn("", "select", true, true, CommonColumnType.Selection);
        this.addColumn("", "avatar", true, true, CommonColumnType.Template, {
            isSortable: false,
            templateCellIdx: 1,
            fixedWidth: "32px"
        });

        if(this.compactRow) {
            this.addColumn($localize`:"@@common-student-name: Student’s name `, "compactName", true, true, CommonColumnType.Template, {
                templateCellIdx: 2
            });
        }
        else {
            this.addColumn($localize`:"@@common-student-name: Student’s name `, "fullName", true, true);
            this.addColumn($localize`:"@@common-username: Profile name `, "username", true, true);
        }

        if(!this.displayForSelection)
        {
            if(!this.compactRow) {
                this.addColumn($localize`:"@@common-tokens:Tokens`, "tokens", true, true, CommonColumnType.Void, {
                    isSortable: false,
                    fixedWidth: "45px",
                    cellStyles: {
                        'margin-right': "0px"
                    }
                });
            }

            for (const product of products) {
                const columnKey = 'product_' + product.shortCode.toLowerCase();
                this.addColumn(product.shortCode, columnKey, true, true, CommonColumnType.Template, {
                    isSortable: false,
                    fixedWidth: "45px",
                    cellStyles: {
                        'margin-right': "0px"
                    },
                    columnHeaderType: CommonColumnHeaderType.Template
                }).parameters = {
                    productCode: product.shortCode.toLowerCase()
                };
            }
        }
        else {
            this.addColumn($localize`:"@@common-tokens:Tokens`, "tokens", true, true, CommonColumnType.Template, {
                isSortable: false,
                templateCellIdx: 3,
                fixedWidth: (this.products.length * (40 + 2)) + "px"
            });
        }

        if(this.actionTemplate) {
            this.addColumn("", "actions", false, true, CommonColumnType.Action);
        }

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;

        this.setFullSourceItems(this.students, start, count, sort, search);
    }

    public getSeats(seats: SubscriptionSeat[], productCode: string) {
        if(!seats) {
            return [];
        }

        let result: SubscriptionSeat[] = [];

        for(let i=0; i<seats.length; i++) {
            let seat = seats[i];
            if(seat.productId == productCode) {
                result.push(seat);
            }
        }

        return result;
    }

    onCompactModeChanged() {
        if(this.products) {
            this.initColumns(this.products);
        }
    }
}
