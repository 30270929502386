import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page/login-page.component';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent, LoginFormHeaderComponent, LoginFormFooterComponent } from './login-form/login-form.component';
import { MatMenuModule } from '@angular/material/menu';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { RouterModule } from '@angular/router';
import { RegisterPageComponent } from './register-page/register-page.component';
import { InstitutionalWelcomePageComponent } from './institutional-welcome-page/institutional-welcome-page.component';
import { AuthAndAccountModule } from '../shared/auth-and-account/auth-and-account.module';
import { UnsubscribeEmailPageComponent } from './unsubscribe-email-page/unsubscribe-email-page.component';
import { RecommendComponent } from './recommend/recommend.component';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { DividedPageComponent } from './divided-page/divided-page.component';
import { SocialModule } from '../social/social.module';
import { SsoRedirectPageComponent } from './sso-redirect-page/sso-redirect-page.component';
import { DashboardUiModule } from '../dashboard-ui/dashboard-ui.module';
import { InstitutionalWelcomeOptionCardComponent } from './institutional-welcome-option-card/institutional-welcome-option-card.component';

@NgModule({
    declarations: [
        LoginPageComponent,
        LoginFormComponent,
        LoginFormHeaderComponent,
        LoginFormFooterComponent,
        ForgotPasswordPageComponent,
        RegisterPageComponent,
        InstitutionalWelcomeOptionCardComponent,
        InstitutionalWelcomePageComponent,
        UnsubscribeEmailPageComponent,
        RecommendComponent,
        DividedPageComponent,
        SsoRedirectPageComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        AuthAndAccountModule,
        SocialModule,
        AuthUiModule,
        DashboardUiModule,
    ],
    exports: [
        LoginFormComponent,
        LoginFormHeaderComponent,
        LoginFormFooterComponent,
    ]
})
export class AuthModule { }
