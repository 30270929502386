import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { Classroom, OrganizationMember, OrganizationRole, User } from '@applogic/model';
import { ClassroomService } from '../../classroom.service';
import { OrganizationService } from 'src/app/organization/organization.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LOCAL_STORAGE_USER_KEY } from 'src/app/auth/auth.service';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { MessageBoxDialogComponent } from 'src/app/core/message-box-dialog/message-box-dialog.component';
import { MessageBoxResult } from 'src/app/core/message-box-dialog/messagebox-result';
import { MessageBoxButton } from 'src/app/core/message-box-dialog/messagebox-button';
import { MessageBoxSettings } from 'src/app/core/message-box-dialog/messagebox-settings';
import { MessageBoxIcon } from 'src/app/core/message-box-dialog/messagebox-icon';
import { Observable, firstValueFrom } from 'rxjs';
import { InviteCollaboratorDialogComponent } from '../invite-collaborator-dialog/invite-collaborator-dialog.component';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
    selector: 'app-collaborator-list',
    templateUrl: './collaborator-list.component.html',
    styleUrls: ['./collaborator-list.component.scss', '../classroom-page-common.scss']
})
export class CollaboratorListComponent extends CommonListComponent<User> implements OnInit {

    @Input()
    classroom: Classroom;

    collaborators: User[];
    invitedListCollaborators: User[];

    @Input()
    showTransferOwnerShipOption: boolean;

    @Input()
    invited: boolean = false;

    currentUserId: string;
    currentUser: any;

    isAdmin: boolean = false;
    isOwner: boolean = false;

    constructor(
        private injector: Injector,
        private classroomService: ClassroomService,
        private orgService: OrganizationService,
        private localStorageService: LocalStorageService,
        readonly loading: LoadingService,
        private notificationService: NotificationService
    ) {
        super(injector);

        // Set default values
        this.pageSize = 5;
        this.pageSizeDefault = 5;
        this.pageSizeOptions = [5, 10, 15, 20];

        this.supportCompactMode = true;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initColumns();

        this.getUser();
        this.refreshCollaborators();
    }

    initColumns() {
        this.updateColumns(true);

        let key: string;

        if (!this.invited) {
            key = 'applogic/classroom/collaborator-list';
        }
        else {
            key = 'applogic/classroom/invited-collaborator-list';
        }

        this.initPersistentKey(key);

        this.addColumn("", "avatar", true, true, CommonColumnType.Template, {
            isSortable: false,
            templateCellIdx: 0,
            fixedWidth: "32px"
        });
        const nameColumn = this.addColumn($localize`:@@common-name:Name`, "fullName", true, !this.compactRow, CommonColumnType.Text);
        const emailColumn = this.addColumn($localize`:@@common-email: Email `, "email", true, !this.compactRow, CommonColumnType.Text);

        if(this.compactRow) {
            this.addColumn(nameColumn.label + " / " + emailColumn.label, "compactName", true, true, CommonColumnType.MergeColumns, {
                mergeColumns: [nameColumn, emailColumn]
            });
        }

        this.addColumn($localize`:@@common-role:Role`, "role", true, true, CommonColumnType.Template, {
            isSortable: false,
            templateCellIdx: 1,
            fixedWidth: "96px"
        });

        this.addColumn("", "actions", false, true, CommonColumnType.Action);

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        this.errorObject = undefined;

        if (this.invited) {
            this.classroomService
                .getClassroomSentInviteCollaborators(this.classroom.id)
                .subscribe((c) => {
                    this.invitedListCollaborators = c;
                    this.setFullSourceItems(this.invitedListCollaborators, start, count, sort, search);
                });
        }
        else {
            this.classroomService
                .getClassroomCollaborators(this.classroom.id)
                .subscribe((c) => {
                    this.collaborators = c;
                    this.setFullSourceItems(this.collaborators, start, count, sort, search);
                });
        }
    }

    isUserOwner(userId: string) {
        return this.classroom.owner && (this.classroom.owner.id == userId);
    }

    setCurrentUserId(collaborator) {

    }

    resendInvite(collaboratorId: string) {
        this.classroomService
            .resendCollaboratorsInvite(this.classroom.id, collaboratorId)
            .subscribe({
                next: (val) => {
                    this.notificationService.notify({
                        message: $localize`:@@collaborator-invite-success-notification: Invitation successfully sent `,
                        style: 'success'
                    });

                },
                error: (err) => {
                    console.error(err);
                    this.notificationService.notify({
                        message: $localize`:@@collaborator-invite-failed-notification: Send invitation failed `,
                        style: 'error'
                    });
                }
            });
    }

    deleteInvite(collaborator: User) {
        this.classroomService
            .removeCollaboratorsInvite(this.classroom.id, collaborator.id)
            .subscribe((val) => {
                this.removeItem(collaborator)
            });
    }

    refreshCollaborators() {
        if (this.classroom.organization != null) {
            this.orgService.getMember(this.classroom.organization.id, this.currentUserId).subscribe((member) => {
                this.refreshCurrentUser(member);
            });
        }

        this.reloadItems();
    }

    openCollaboratorDialog() {
        let getCollaborators: Observable<User[]>;
        
        if (!this.invited) {
            getCollaborators = this.classroomService
                .getClassroomSentInviteCollaborators(this.classroom.id);
        }
        else {
            getCollaborators = this.classroomService
                .getClassroomCollaborators(this.classroom.id);
        }

        getCollaborators.subscribe({
            next: (res) => {
                if(!this.invited) {
                    this.invitedListCollaborators = res;
                }
                else {
                    this.collaborators = res;
                }

                InviteCollaboratorDialogComponent.createDialog(this.dialogService, this.dialog, {
                    classroom: this.classroom,
                    excludedUsers: [...this.collaborators, ...this.invitedListCollaborators]
                }).show().then((val) => {
                    if (val) {
                        this.refreshCollaborators();
                    }
                });
            },
            error: (err) => {
                this.errorObject = err;
            }
        });
    }

    openRevokeAccessDialog(collaborator: any) {
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@remove-collaborator-confirm-text: You're about to remove <strong>${collaborator.fullName}</strong>'s from the class. `
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@common-confirm-remove-label: Yes, Remove `, "warn", "flat"),
        ];

        settings.validateBeforeCallback = async (result) => {
            if (result == MessageBoxResult.OK) {
                try {
                    this.classroomService
                        .removeCollaborator(this.classroom.id, collaborator.id)
                        .subscribe((val) => {
                            this.refreshCollaborators();
                        });
                }
                catch (ex) {
                    return ex;
                }
            }
        };

        MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings).show();
    }

    // @collaborator
    // Tranfer Ownner Ship Dialog  Function
    openChangeOwnerShip(collaborator: any) {
        let settings = new MessageBoxSettings();
        settings.title = $localize`:@@common-strong-warning-title:WARNING!`;
        settings.messages = [
            $localize`:@@transfer-ownership-role-confirm-text: Are you sure you want to transfer the administration of this class to <strong>${collaborator.fullName}</strong>? Afterwards you will keep a collaborator role. `,
            $localize`:@@transfer-ownership-role-confirm-text-second: Click on <strong>Transfer role</strong> to proceed, or <strong>Cancel</strong> if you've changed your mind. `
        ];
        settings.icon = new MessageBoxIcon("warning", "warn");
        settings.buttons = [
            new MessageBoxButton(MessageBoxResult.Cancel),
            new MessageBoxButton(MessageBoxResult.OK, $localize`:@@transfer-ownership-role-action-button: Transfer role `, "warn", "flat"),
        ];

        settings.validateBeforeCallback = async (result) => {
            if (result == MessageBoxResult.OK) {
                try {
                    await firstValueFrom(this.classroomService.changeOwnerCollaborator(this.classroom.id, collaborator.id));

                    const classroomId = this.classroom.id;

                    // TODO: WTF? Retrieving the entire classrooms and all students and all collaborators only to use the "owner" field!?
                    const response = await firstValueFrom(this.classroomService.getClassroom(classroomId));
                    if (response.classroom.owner) {
                        this.classroom.owner = response.classroom.owner;
                    }
                    else {
                        this.classroom.owner = null;
                    }
                    this.refreshCollaborators();
                    this.getUser();
                }
                catch (ex) {
                    return ex;
                }
            }
        };

        MessageBoxDialogComponent.createDialog(this.dialogService, this.dialog, settings).show();
    }

    canAccessCollabContextualMenu(userId: string): boolean {
        return (this.showTransferOwnerShipOption == true) &&
            // Owner cannot access collaborator contextual menu. Except organization admin and only if not also the owner of the classroom.
            (!this.isUserOwner(userId) || (this.isAdmin && !this.isOwner)) &&
            // Only admin can remove or transfer ownership to itself.
            ((userId != this.currentUserId) || this.isAdmin);
    }

    getUser() {
        this.currentUser = this.localStorageService.get(LOCAL_STORAGE_USER_KEY);
        if (this.currentUser) {
            this.currentUserId = this.currentUser.id;
        }
    }

    refreshCurrentUser(member: OrganizationMember) {

        if (this.classroom.owner) {
            this.isOwner = this.classroom.owner.id == this.currentUser.id;
        }
        else {
            this.isOwner = false;
        }

        let isAdmin: boolean = false;
        if (member) {
            if (member.role == OrganizationRole.Administrator) {
                isAdmin = true;
            }
        }

        if (this.isAdmin != isAdmin) {
            this.isAdmin = isAdmin;
        }

        if (this.isOwner || this.isAdmin) {
            this.showTransferOwnerShipOption = true;
        } else {
            this.showTransferOwnerShipOption = false;
        }
    }

    canShowAction(row: User) {

        if (this.canAccessCollabContextualMenu(row.id)) {
            return true;
        }

        return false;
    }

    onCompactModeChanged() {
        this.initColumns();
    }
}
