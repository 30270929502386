import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RenewOption, Subscription } from '@applogic/model';
import { LoadingService } from 'src/app/services/loading.interceptor';
import { AccountService } from '../../account.service';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { HandleErrorDialogService } from 'src/app/services/handle-error-dialog.service';

interface SubscriptionCancelDialogData {
    subscription: Subscription
}

/**
 * The cancel dialog to cancel a subscription.
 */
@Component({
    selector: 'app-subscription-cancel-dialog',
    templateUrl: './subscription-cancel-dialog.component.html',
    styleUrls: ['./subscription-cancel-dialog.component.scss']
})
export class SubscriptionCancelDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: SubscriptionCancelDialogData,
        private mdDialogRef: MatDialogRef<SubscriptionCancelDialogComponent>,
        readonly loading: LoadingService,
        private accountService: AccountService,
        private errorDialogService: HandleErrorDialogService) {

    }

    ngOnInit(): void {
    }

    /**
     * confirm the cancel button  call cancel the subscription api 
     * @param sub 
     */
    cancelSubscription() {
        const sub = this.data.subscription;
        this.accountService.cancelSubscription(sub).then(res => {
            this.mdDialogRef.close();
            sub.renewOption = RenewOption.DoNothing
        }).catch(err => {
            this.errorDialogService.openErrorDialog(undefined, err, false);
        })
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: SubscriptionCancelDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<SubscriptionCancelDialogComponent, SubscriptionCancelDialogData>(dialogService, dialog, SubscriptionCancelDialogComponent, {
            data
        }, config);
    }
}
