<mat-form-field [appearance]="data.appearance || 'outline'"
                [floatLabel]="data.floatLabel || 'always'"
                [subscriptSizing]="data.subscriptSizing || 'fixed'"
                class="custom-form-field custom-form-select-field">
    <mat-label *ngIf="field.label"
               (contextmenu)="showDebugs($event)">{{field.label}}</mat-label>
    <mat-select [formControl]="field.control"
                (selectionChange)="onFieldSelectChanged($event)"
                #select>
        <mat-option *ngIf="data.searchEnabled">
            <ngx-mat-select-search i18n-placeholderLabel="@@common-search-bar-placeholder"
                                   placeholderLabel="Search..."
                                   i18n-noEntriesFoundLabel="@@search-no-result"
                                   noEntriesFoundLabel=" No result "
                                   [formControl]="filterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="option.value"
                    *ngFor="let option of getOptions()">{{option.label}}</mat-option>
    </mat-select>
    <mat-error *ngIf="field.control.invalid">
        {{ formService.getFieldErrorMessage(field.control, data.typeKey || field.key) }}
    </mat-error>
</mat-form-field>