import { Directive, HostBinding } from '@angular/core';
import { PageComponent, PageType } from '../page.component';

@Directive({
    selector: '[app-page-content1],[appPageContent1],app-page-content1'
})
export class PageContentDirective {

    @HostBinding('class') get getHostClass() {
        switch(this.page.type) {
            case PageType.Normal:
                return 'app-page--normal-content';
            case PageType.Centered:
                return 'app-page--centered-content';
            case PageType.Full:
                return 'app-page--full-content';
            case PageType.CenteredFullWidth:
                return 'app-page--centered-full-width-content';
        }
    }

    constructor(private page: PageComponent) {
        
    }

}
