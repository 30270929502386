<app-dialog [errorObject]="errorObject" [adminSection]="data.isAdmin">
    <app-dialog-title i18n="@@common-change-member-role-modal">Change Member Role</app-dialog-title>
    <app-dialog-content>
        <button mat-menu-item
                *ngFor="let role of OrganizationRoles"
                [class.active-role]="data.currentRole === role"
                (click)="changeRole(role)">
            <mat-icon>person</mat-icon>
            <span>
                {{ ApplogicUtils.getOrganizationRoleLabel(role) }}
            </span>
        </button>
    </app-dialog-content>>
</app-dialog>