import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsSelectComponent } from './products-select/products-select.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CoreModule } from '../core/core.module';
import { CurrencySelectComponent } from './currency-select/currency-select.component';
import { PriceTableComponent } from './price-table/price-table.component';
import { ProductsPlanCardSelectComponent } from './products-plan-card-select/products-plan-card-select.component';
import { PriceLabelComponent } from './price-label/price-label.component';
import { ProductNamePipe } from './product-name.pipe';
import { ProductIconComponent } from './product-icon/product-icon.component';
import { ProductsOrderCardComponent } from './products-order-card/products-order-card.component';
import { MatChipsModule } from '@angular/material/chips';
import { ProductOrderCardComponent } from './product-order-card/product-order-card.component';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProductStoreLinksComponent } from './product-store-links/product-store-links.component';
import { RouterModule } from '@angular/router';
import { CreditCardsComponent } from './credit-cards/credit-cards.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const EXPORT_COMPONENTS = [
    CreditCardsComponent,
    CurrencySelectComponent,
    PriceLabelComponent,
    PriceTableComponent,
    ProductIconComponent,
    ProductNamePipe,
    ProductsSelectComponent,
    ProductOrderCardComponent,
    ProductsOrderCardComponent,
    ProductsPlanCardSelectComponent,
    ProductStoreLinksComponent,
]

@NgModule({
    declarations: [
        ...EXPORT_COMPONENTS,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        RouterModule,
        MatButtonToggleModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatListModule,
        MatSlideToggleModule,
        NgbModule,
    ],
    exports: [
        ...EXPORT_COMPONENTS,
    ]
})
export class ProductModule {

}
