import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Organization, User } from '@applogic/model';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';

export interface OrgsSelectionOptions {
    // Only admin can use this parameter.
    userRole?: User;
    org?: Organization;
}

export interface OrgsSelectionDialogData {
    message?: string,
    options?: OrgsSelectionOptions
}

@Component({
    selector: 'app-orgs-selection-dialog',
    templateUrl: './orgs-selection-dialog.component.html',
    styleUrls: ['./orgs-selection-dialog.component.scss']
})
export class OrgsSelectionDialogComponent implements OnInit {
    _currentOrg: Organization;

    @Input()
    set currentOrg(val: Organization) {
        if(this._currentOrg != val) {
            this._currentOrg = val;
            this.currentOrgChange.emit(val);
        }
    }

    get currentOrg() {
      return this._currentOrg;
    }

    @Input()
    message: string;
    
    @Output()
    currentOrgChange: EventEmitter<Organization> = new EventEmitter<Organization>();

    selectedOrganization: Organization;

    userRole: User;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrgsSelectionDialogData,
        private mdDialogRef: MatDialogRef<OrgsSelectionDialogComponent, Organization>) {
        if(this.data.message) this.message = this.data.message;
        const options = data.options || {};
        this.userRole = options.userRole;
        if(options.org) {
            this.currentOrg = options.org;
        }
    }

    ngOnInit(): void {
    }

    public static createDialog(dialogService: DialogService, dialog: MatDialog, data: OrgsSelectionDialogData, config: AppDialogConfig = {}) {
        return new AppDialogRef<OrgsSelectionDialogComponent, OrgsSelectionDialogData, Organization>(dialogService, dialog, OrgsSelectionDialogComponent, {
            data,
            maxWidth: "1200px"
        }, config);
    }

    onSelect() {
        this.selectedOrganization = this.currentOrg;
        this.mdDialogRef.close(this.selectedOrganization);
    }
}
