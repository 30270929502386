import { trigger, transition, group, query, style, animate, stagger } from '@angular/animations';

/**
 * This transition is used to add fade animation when you refresh or enter in the web application.
 */
export const ROUTER_TRANSITION = trigger('routerTransition', [
    transition('* => *', [

        group([
            /* query(':enter', [
                 style({ opacity: 0 })
             ], { optional: true }),*/
            query(':enter .card', [
                style({ opacity: 0 })
            ], { optional: true }),

        ]),


        group([

            /* query(':leave', [
                 style({ opacity: 1 }),
                 animate('0.25s', style({ opacity: 0 }))
             ],
                 { optional: true }
             ),*/
            query(':leave app-toolbar .title', [
                style({ transform: 'translateY(0px)' }),
                animate('0.25s ease-in',
                    style({ transform: 'translateY(10px)', opacity: 0 })),
            ], { optional: true }),
        ]),


        group([
            /* query(':enter', [
                 style({ opacity: 0 }),
                 animate('0.25s', style({ opacity: 1 }))
             ], { optional: true }),*/
            query(':enter app-toolbar .title', [
                style({ transform: 'translateY(-10px)', opacity: 0 }),
                animate('0.25s ease-out',
                    style({ transform: 'translateY(0px)', opacity: 1 })),
            ], { optional: true }),

            /* query(':enter app-toolbar .extra-bar', [
                 style({ height: 0 }),
                 animate('0.25s', style({ height: '*' }))
             ], { optional: true }),
 */
            query(':enter .card', stagger(200, [
                style({ transform: 'translateY(-20px)' }),
                animate('0.25s ease-out',
                    style({ transform: 'translateY(0px)', opacity: 1 })),
            ]), { optional: true })
        ]),


    ])
]);