<app-dialog icon="people"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@assign-students-to-product-tokens-title">Assign tokens for classroom's students</app-dialog-title>
    <app-dialog-content *ngIf="!displayResult">
        <span i18n="@@assign-students-to-product-tokens">One token for each student, until the tokens are used
            up.</span>

        <br *ngIf="userAllocations.subscriptions?.length > 1" />
        <span *ngIf="userAllocations.subscriptions?.length > 1"
              i18n="@@assign-students-to-product-tokens-multi-subscriptions">The tokens with the longest validity period
            are used first.</span>

        <div matDialogContent>
            <app-assign-tokens-panel [userAllocations]="userAllocations"
                                     [multiSelect]="true"></app-assign-tokens-panel>
        </div>
    </app-dialog-content>
    <button *ngIf="!displayResult"
            appDialogAction
            mat-stroked-button
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button *ngIf="!displayResult"
            appDialogAction
            mat-flat-button
            color="accent"
            [disabled]="!tokensPanel?.atLeastOneChecked || (tokensPanel?.processing > 0)"
            (click)="assignTokens()">
        <span i18n="@@assign-tokens-button">Assign</span>
    </button>
    <app-dialog-content *ngIf="displayResult">
        <div class="product-result-section"
             *ngFor="let productCode of assignedProducts">
            <app-seat-token [displayType]="'icon-only'"
                            [productCode]="productCode">
            </app-seat-token>
            <div class="product-result-desc">{{getProductResult(productCode)}}</div>
        </div>

        <div *ngIf="!allAssigned"
             i18n="@@assign-students-to-product-tokens-result-line3">Please contact the organization administrator to request tokens.</div>
    </app-dialog-content>
    <button *ngIf="displayResult"
            appDialogAction
            mat-flat-button
            (click)="onClose()"
            color="accent">
        <span i18n="@@common-understand">Understood</span>
    </button>
</app-dialog>