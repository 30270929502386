<button mat-icon-button
        *ngIf="list.hasAudio(column.key, getRowValue(column, row), row) && checkAudio(column.key, getRowValue(column, row), row, !parent._options.autoCheckAudio, getListData().updatedAudio)"
        (mouseenter)="checkAudio(column.key, getRowValue(column, row), row, false, getListData().updatedAudio)"
        (click)="playAudio(column.key, getRowValue(column, row), row)">
    <mat-icon>
        play_circle_outline
    </mat-icon>
</button>
<div *ngIf="list.hasAudio(column.key, getRowValue(column, row), row) && !checkAudio(column.key, getRowValue(column, row), row, !parent._options.autoCheckAudio, getListData().updatedAudio)"
     (click)="playAudio(column.key, getRowValue(column, row), row)">
    <mat-icon [ngStyle]="{'color':'grey'}">
        play_circle_outline
    </mat-icon>
</div>