import { Component, Input } from '@angular/core';
import { Organization, OrganizationMember, User } from '@applogic/model';
import { CardTemplateComponent } from 'src/app/core/common-list/common-list-renderer-templates/components/card-template/card-template.component';


@Component({
    selector: 'app-organization-member-card-template',
    templateUrl: './organization-member-card-template.component.html',
    styleUrl: './organization-member-card-template.component.scss'
})
export class OrganizationMemberCardTemplateComponent extends CardTemplateComponent<OrganizationMember> {
    
    @Input()
    organization: Organization;

}
