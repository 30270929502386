import { MessageBoxButton } from "./messagebox-button";
import { MessageBoxIcon } from "./messagebox-icon";
import { MessageBoxResult } from "./messagebox-result";

export class MessageBoxSettings {
    title: string;
    messages: string[];
    icon: MessageBoxIcon;
    buttons: MessageBoxButton[];

    // If returned value is not undefined, the dialog will not close and display the error.
    validateBeforeCallback?: (result: MessageBoxResult) => Promise<any>;
}