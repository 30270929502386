<div class="app-property-list-container">
    <div class="list-item subscription-item"
         *ngFor="let sub of licenses">

        <div class="grant-info-block">
            <div class="sub-grant">

                <img [attr.src]="productService.getGameIcon(sub.productCode + '_2019')"
                     alt="">
                <span>
                    {{ productService.getGameName(sub.productCode + '_2019') }}<br>
                    <small class="secondary-text"
                           i18n="@@org-sub-purchased-on">
                        Purchased on {{ sub.createdOn | date }}
                    </small>

                </span>

            </div>

        </div>

        <div class="grant-info-block">


            <a mat-flat-button
               download
               [attr.href]="productService.getDownloadLink(sub.productCode + '_2019', 'win')"
               color="primary"
               style="margin: 8px">Windows</a>
            <a mat-flat-button
               download
               [attr.href]="productService.getDownloadLink(sub.productCode + '_2019', 'mac')"
               color="primary"
               style="margin: 8px">Mac OS X</a>
        </div>

    </div>
</div>