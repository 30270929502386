import { Component, Input } from '@angular/core';
import { AbstractCustomFormFieldComponent } from '../abstract-custom-form-field/abstract-custom-form-field.component';


export interface HeadingTextFieldConfig {
    headingText: string
}

@Component({
  selector: 'app-custom-form-heading-text-field',
  templateUrl: './custom-form-heading-text-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-heading-text-field.component.scss']
})
export class CustomFormHeadingTextFieldComponent extends AbstractCustomFormFieldComponent {

    @Input()
    data: HeadingTextFieldConfig;
    
}
