import { Component } from '@angular/core';
import { Organization } from '@applogic/model';
import { CardTemplateComponent } from 'src/app/core/common-list/common-list-renderer-templates/components/card-template/card-template.component';


@Component({
    selector: 'app-organization-card-template',
    templateUrl: './organization-card-template.component.html',
    styleUrl: './organization-card-template.component.scss'
})
export class OrganizationCardTemplateComponent extends CardTemplateComponent<Organization> {

}
