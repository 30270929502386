import { Directive } from '@angular/core';

@Directive({
    selector: '[app-dialog-title],[appDialogTitle],app-dialog-title'
})
export class DialogTitleDirective {

    constructor() { }

}
