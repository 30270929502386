import { EqolCategoryInfo } from './category-info';

export class EqolColumnInfo {
    readonly key: string;
    readonly label: string;
    public category?: EqolCategoryInfo = null;
    public data?: any = {};
    public group?: number = 0;      // Used for sub grouping the columns.

    public databaseField: string = "";

    // Function to return value from server returned data.
    public getFormattedValueCallback?: (column: EqolColumnInfo, data: any) => any;
    public getValueCallback?: (column: EqolColumnInfo, data: any) => any;

    constructor(key: string, label: string) {
        this.key = key;
        this.label = label;
    }

    public getValue(data: any) : any
    {
        if(this.getValueCallback)
        {
            return this.getValueCallback(this, data);
        }

        return data[this.key];
    }

    public getFormattedValue(data: any) : any
    {
        if(this.getFormattedValueCallback)
        {
            return this.getFormattedValueCallback(this, data);
        }
        else if(this.getValueCallback)
        {
            return this.getValueCallback(this, data);
        }

        return data[this.key];
    }
}
