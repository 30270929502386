import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { StudentInvitePageComponent } from './invite/student-invite-page/student-invite-page.component';
import { StaffMemberInvitePageComponent } from './invite/staff-member-invite-page/staff-member-invite-page.component';
import { ForgotPasswordPageComponent } from './auth/forgot-password-page/forgot-password-page.component';
import { PasswordResetSummaryResolverService } from './auth/auth.service';
import { DashboardLayoutComponent } from './dashboard/dashboard-layout/dashboard-layout.component';
import { NotFoundPageComponent } from './not-found/not-found-page/not-found-page.component';
import { UserSelfResolverService } from './account/account.service';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { InstitutionalWelcomePageComponent } from './auth/institutional-welcome-page/institutional-welcome-page.component';
import { NotAuthGuard } from './auth/not-auth.guard';
import { CollaboratorInvitePageComponent } from './invite/collaborator-invite-page/collaborator-invite-page.component'
import { UnsubscribeEmailPageComponent } from './auth/unsubscribe-email-page/unsubscribe-email-page.component';
import { RecommendComponent } from './auth/recommend/recommend.component';
import { ProductsResolverService } from './product/products.resolve';
import { StudentListPrintPageComponent } from './student/student-list-print-page/student-list-print-page.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { ClassroomResolverService } from './classroom/classroom.service';
import { SsoRedirectPageComponent } from './auth/sso-redirect-page/sso-redirect-page.component';


const routes: Routes = [
    {
        path: 'welcome',
        component: InstitutionalWelcomePageComponent
    },

    {
        path: 'login',
        canActivate: [NotAuthGuard],
        component: LoginPageComponent
    },

    {
        path: 'sso/redirect',
        component: SsoRedirectPageComponent
    },

    {
        path: 'register',
        canActivate: [NotAuthGuard],
        component: RegisterPageComponent
    },
    
    {
        path: 'recommend',
        component: RecommendComponent
    },

    {
        path: 'unsubscribe/:token',
        component: UnsubscribeEmailPageComponent
    },

    {
        path: '',
        loadChildren: () =>
            import(
                "./dashboard/dashboard.module"
            ).then((m) => m.DashboardModule),
    },

    {
        path: 'password-reset',
        canActivate: [NotAuthGuard],
        component: ForgotPasswordPageComponent
    },
    {
        path: 'password-reset/:token',
        canActivate: [NotAuthGuard],
        component: ForgotPasswordPageComponent,
        resolve: {
            summary: PasswordResetSummaryResolverService
        }
    },

    {
        path: 'student-invite/:token',
        component: StudentInvitePageComponent,
        resolve: {
            products: ProductsResolverService
        }
    },

    {
        path: 'staff-invite/:token',
        component: StaffMemberInvitePageComponent,
        resolve: {
            products: ProductsResolverService
        }
    },
    {
        path: 'collaborator-invite/:token',
        component: CollaboratorInvitePageComponent,
        resolve: {
            products: ProductsResolverService
        }
    },

    {
        path: 'order',
        loadChildren: () =>
            import(
                "./order/order.module"
            ).then((m) => m.OrderModule),
        resolve: {
            products: ProductsResolverService
        }
    },

    {
        path: 'games',
        loadChildren: () =>
            import(
                "./games/games.module"
            ).then((m) => m.GamesModule),
        resolve: {
            products: ProductsResolverService
        }
    },

    {
        path: 'onboarding',
        loadChildren: () =>
            import(
                "./onboarding/onboarding.module"
            ).then((m) => m.OnboardingModule)
    },

    {
        path: 'wordlist',
        loadChildren: () =>
            import(
                "./wordlist/word-list.module"
            ).then((m) => m.WordListModule),
    },

    {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
            {
                path: 'classrooms/:id/students',
                component: StudentListPrintPageComponent,
                resolve: {
                    classroom: ClassroomResolverService,
                    products: ProductsResolverService
                }
            }
        ]
    },
    
    {
        path: '**',
        component: DashboardLayoutComponent,
        children: [
            {
                path: '',
                component: NotFoundPageComponent,
                resolve: {
                    user: UserSelfResolverService
                }
            }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
