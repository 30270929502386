<app-page i18n-title="@@billing-page--title"
          title="Billing">
    <app-page-header2-card appDefaultPageHeaderCard
                           bgImageUrl="assets/headers/billing.jpg">
        <page-header-card-extended-title [count]="invoiceList?.count || 0">
            <app-title i18n="@@billing-page--title">Billing</app-title>
        </page-header-card-extended-title>
        <design-card-subtitle i18n="@@billing-page--subtitle">
            Manage your invoices.
        </design-card-subtitle>
    </app-page-header2-card>
    <app-page-content>
        <app-subscription-invoice-list #invoiceList></app-subscription-invoice-list>
    </app-page-content>
</app-page>