<app-page type="centered">
    <app-page-content class="app-form-container">
        <div *ngIf="isLoading"
             style="display: flex; justify-content: center; width: 100%"
             @fadeInCollapseOut>

            <mat-progress-spinner mode="indeterminate"
                                  diameter="64">
            </mat-progress-spinner>

        </div>

        <app-design-card class="invite-card"
                         appearance="raised"
                         [clickable]="false">
            <design-card-content [ngSwitch]="_step">
                <div *ngSwitchCase="Step.Login">
                    <ng-template [ngTemplateOutlet]="loginTemplate"></ng-template>
                </div>

                <div *ngSwitchCase="Step.Accept">
                    <ng-template [ngTemplateOutlet]="acceptTemplate"></ng-template>
                </div>

                <div *ngSwitchCase="Step.Error">
                    <ng-template [ngTemplateOutlet]="errorTemplate"></ng-template>
                </div>
            </design-card-content>
        </app-design-card>
    </app-page-content>
</app-page>

<ng-template #loginTemplate>
    <p>
        <span i18n="@@collaborator-invite-signin-label">
            Please sign in with your account to accept invitation
        </span>

    </p>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <app-user-card [user]="inviteSummary.invite.invited"></app-user-card>

    <div class="login-form-margin">
        <app-base-login-register-form [readonlyEmailIfProvided]="true"
                                      [email]="inviteSummary.invite.email"
                                      [formType]="'login-hidden-email'"
                                      #commonForm></app-base-login-register-form>
    </div>

    <div class="app-form-actions"
         style="align-items: flex-end;">

        <span class="spacer"></span>

        <button mat-flat-button
                color="primary"
                [disabled]="commonForm.baseLoginRegisterForm.invalid || loading.isLoading || (loading.loadingChange | async)"
                (click)="submitLoginForm(commonForm.baseLoginRegisterForm)">

            <span i18n="@@collaborator-invite-signin-submit-label">
                Sign in & Accept
            </span>



        </button>
    </div>
</ng-template>

<ng-template #acceptTemplate>
    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <h2 i18n="@@collaborator-invite-title">
        You are invited to join the classroom!
    </h2>

    <p>
        <strong title="{{inviteSummary.invite.invitedBy.email}}">{{inviteSummary.invite.invitedBy.fullName}}</strong>
        <span i18n="@@student-invite-form-context-text"> has invited you to join the class </span>
        <strong>{{inviteSummary.classroom.name}}</strong>
    </p>

    <p i18n="@@student-invite-form-context-instructions">
        To join, simply click on 'Accept Invitation' below.
    </p>

    <div class="app-form-actions">

        <span class="spacer"></span>

        <button mat-flat-button
                color="primary"
                (click)="acceptInvite()">

            <span i18n="@@student-invite-form-submit-label">
                Accept Invitation
            </span>

        </button>

    </div>
</ng-template>

<ng-template #errorTemplate>
    <div class="header"
         style="justify-content: center;">
        <mat-icon color="warn">warning</mat-icon>
    </div>

    <app-error-message-area [error]="errorObject"></app-error-message-area>

    <small i18n="@@common-invitation-lookup-error-hint">
        Please contact the person who gave you the link. They may be able to generate a new invitation.
    </small>
</ng-template>