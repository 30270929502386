<app-dialog [errorObject]="errorObject">
    <app-dialog-title>{{title}}</app-dialog-title>
    <app-dialog-content>
        <app-address-form [allowSearch]="true"
                          [formData]="data.address"
                          #addressForm></app-address-form>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose=""
            i18n="@@common-cancel"> Cancel </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            (click)="save(addressForm.formValue)"
            [disabled]="addressForm.invalid || loading.isLoading || (loading.loadingChange | async)"
            i18n="@@common-save">Save</button>
</app-dialog>