import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AnalyticsEventsService } from './analytics-events.service';
import { Product } from '@applogic/model';
import { OnboardingService } from '../onboarding-ui/onboarding.service';
import { ApplogicUtils } from './applogic-utils';


@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    readonly menuOpened;
    private breakpointSub: Subscription;

    readonly menuMode = new BehaviorSubject<string>("side");

    private _hideMenu: boolean = false;

    private _isLargeEnough: boolean = true;

    public get isLargeEnough() {
        return this._isLargeEnough;
    }
    public get hideMenu() {
        return this._hideMenu;
    }

    canOpen() {
        return this.authService.isLoggedIn() && !this._hideMenu;
    }
    
    constructor(
        private router: Router,
        readonly breakpoints: BreakpointObserver,
        private authService: AuthService,
        private analyticsEventsService: AnalyticsEventsService,
        private onboardingService: OnboardingService
    ) {
        this.menuOpened = new BehaviorSubject<boolean>(this.canOpen());
        this.breakpointSub = this.breakpoints.observe('(min-width: 1200px)')
            .subscribe((match) => {
                if(match.matches) {
                    this._isLargeEnough = true;
                    if(!this.menuOpened.value) {
                        this.menuOpened.next(this.canOpen());
                    }
                    if(this.menuMode.value != "side") {
                        this.menuMode.next("side");
                    }
                }
                else {
                    this._isLargeEnough = false;
                    if(this.menuOpened.value) {
                        this.menuOpened.next(false);
                    }
                    if(this.menuMode.value != "over") {
                        this.menuMode.next("over");
                    }
                }
            });
    }

    ngOnDestroy() {
        if (this.breakpointSub) this.breakpointSub.unsubscribe();
    }

    onNavigate(routeData: any) {
        if(routeData.hideSideBar) {
            this._hideMenu = true;
        }
        else {
            this._hideMenu = false;
        }
        
        if(!this.canOpen()) {
            if(this.menuOpened.value) {
                this.menuOpened.next(false);
            }
        } else if(!this._isLargeEnough) {
            if(this.menuOpened.value) {
                this.menuOpened.next(false);
            }
        }

        // Should always be visible when placed to the side.
        else if(this._isLargeEnough) {
            if(!this.menuOpened.value) {
                this.menuOpened.next(true);
            }
        }
    }

    /**
     * The interaction with the menu may change the state of the menu.
     */
    onOpenedChange(state: boolean) {
        if(this.menuOpened.value != state) {
            this.menuOpened.next(state);
        }
    }

    toggleMenu() {
        if(this._isLargeEnough) {
            if(!this.menuOpened.value) {
                this.menuOpened.next(true);
            }
            return;
        }
        this.menuOpened.next(!this.menuOpened.getValue());
    }

    reloadCurrentUrl() {
        ApplogicUtils.reloadCurrentUrl(this.router);
    }

    navigateToGamePage(product: Product, section: string, data: any, event?: any) {
        if(event) {
            event.stopPropagation();
        }

        this.analyticsEventsService.sendPlayButtonClick(product.shortCode, section);

        this.onboardingService.notifyPlayGame(product.shortCode, data);

        this.router.navigate(['/games/' + product.shortCode.toLowerCase()]);
    }
}
