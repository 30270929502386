<ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'dynamic'"
         class="dynamic-text-container"
         appDynamicFontSize
         [minFontSize]="minFontSize"
         [maxFontSize]="maxFontSize">
         <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
    </div>
    <div *ngSwitchCase="'ellipsis'"
         class="ellipsis-text-container">
        <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
    </div>
    <ng-container *ngSwitchDefault>
        <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
    </ng-container>
</ng-container>


<ng-template #contentTemplate>
    <ng-content select="*"></ng-content>
</ng-template>