import { ID, Model, Member, ArrayMember } from "@uon/model";
import { ProductLinkType } from "./product-link-type";



@Model()
export class ProductLink {

    @ArrayMember(String)
    appScopes: string[];

    @Member()
    key: string;

    @Member()
    type: ProductLinkType;

    @Member()
    label: {[key: string]: string};

    @Member()
    url: {[key: string]: string};

    @Member()
    image: {[key: string]: string};

    @Member()
    button: { [key: string]: string };

    @Member()
    icon: { [key: string]: string };

    @Member()
    data: any;

    @Member()
    analytics: any;
}