import { Model, ID, Member, ArrayMember, MinLength, MaxLength, ValidateEmail } from '@uon/model';




@Model()
export class ProfileGameData {

    @ID()
    id: string;

    @Member()
    profileId: string;

    @Member()
    gameId: string;

    @Member()
    key: string;

    @Member()
    value: object;

    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;



}

