import { Component, Input } from '@angular/core';
import { LegacyLicense, Product } from '@applogic/model';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-license-list',
    templateUrl: './license-list.component.html',
    styleUrl: './license-list.component.scss'
})
export class LicenseListComponent {
    
    @Input()
    licenses: LegacyLicense[] = [];

    constructor(readonly productService: ProductService, ) {

    }

}
