<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[wordsCellTemplate]"></renderer-templates>
    <app-common-list-toolbar></app-common-list-toolbar>
</app-common-list-renderer>
<ng-template #wordsCellTemplate
             let-data>
    <div (mouseenter)="setCurrentColumn(data.row)"
         (click)="setCurrentColumn(data.row)">
        <app-help-icon type="popover"
                       [template]="wordsTooltipTemplate"></app-help-icon>
    </div>
</ng-template>

<ng-template #wordsTooltipTemplate>
    <app-columns-selection-word-list-tooltip [column]="_columnTooltip"></app-columns-selection-word-list-tooltip>
</ng-template>