import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, Organization, OrganizationMember, Classroom, CreateTrialSubscriptionResponse, LegacyLicense, Subscription, OrderForm, OrganizationType, OrganizationRole, ApiRoutes, RoutesServer, Address, UserProfileResponse, UserRole } from '@applogic/model';
import { JsonSerializer, Model } from '@uon/model';
import { AuthService } from '../auth/auth.service';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { throwError } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { ApiDirectoryService } from '../services/api-directory.service';


const SUB_SERIALIZER = new JsonSerializer(Subscription);
const LICENSE_SERIALIZER = new JsonSerializer(LegacyLicense);
const ADDRESS_SERIALIZER = new JsonSerializer(Address);
const USER_PROFILE_SERIALIZER = new JsonSerializer(UserProfileResponse);
const CREATE_TRIAL_SUBSCRIPTION_RESPONSE_SERIALIZER = new JsonSerializer(CreateTrialSubscriptionResponse);

const LOCAL_STORAGE_ORG_KEY = 'applogic/account/current_org';


@Injectable({
    providedIn: 'root'
})
export class AccountService {



    currentProfile: UserProfileResponse;
    currentOrg: Organization;

    constructor(private auth: AuthService,
        private ls: LocalStorageService,
        private navigationService: NavigationService,
        private dirService: ApiDirectoryService) {

        const org = this.ls.get(LOCAL_STORAGE_ORG_KEY);
        if (org?.isDeleted) {
            localStorage.removeItem(LOCAL_STORAGE_ORG_KEY);
        }
        this.currentOrg = org && Model.New(Organization, org);

    }

    get otherOrgs() {
        let orgs = this.currentProfile.orgs.filter((o) => { return o.id != this.currentOrg.id });
        return orgs;
    }

    get isAdministrator() {
        return this.currentProfile?.user.roles && this.currentProfile?.user.roles.indexOf(UserRole.Admin) > -1;
    }

    get isTranslator() {
        return this.currentProfile?.user.roles && this.currentProfile?.user.roles.indexOf(UserRole.Translator) > -1;
    }

    get isWordListEditor() {
        return this.currentProfile?.user.roles && this.currentProfile?.user.roles.indexOf(UserRole.WordListEditor) > -1;
    }

    setCurrentOrg(org: Organization) {
        this.currentOrg = org;

        if (!org) {
            this.ls.remove(LOCAL_STORAGE_ORG_KEY);
        }
        else {
            this.ls.set(LOCAL_STORAGE_ORG_KEY, org);
        }

    }



    getUserProfile(): Observable<UserProfileResponse> {
        if (this.auth.user) {
            const id = this.auth.user.id;
            return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Users, `/${id}/profile`, { withCredentials: true }).pipe(
                catchError((err) => {

                    if (err.status === 403) {
                        console.error("Self Forbidden? Resetting the current authentification.");

                        // Clear the current authentication.
                        this.auth.clear();

                        // Reload the current url to reevaluate the current route.
                        this.navigationService.reloadCurrentUrl();
                    }

                    return throwError(err);
                }),
                map((r: any) => {
                    this.currentProfile = USER_PROFILE_SERIALIZER.deserialize(r, true);

                    this.currentProfile.classroomCount = this.currentProfile.classroomCount || 0;
                    this.currentProfile.studentCount = this.currentProfile.studentCount || 0;

                    if (this.currentOrg && !this.currentProfile.orgs.some(o => o.id === this.currentOrg.id)) {

                        this.setCurrentOrg(this.currentProfile.orgs.length == 0
                            ? null
                            : this.currentProfile.orgs[0]
                        );

                    }

                    if (!this.currentOrg) {
                        this.setCurrentOrg(this.currentProfile.orgs[0]);
                    }

                    return this.currentProfile;
                })

            );

        }
    }

    getSelfRole(org: Organization) {
        // In case this is an embedded object, get the organization from the profile
        if (!org.staffMembers) {
            let orgs = this.currentProfile?.orgs;
            if (orgs) {
                org = orgs.find(o => o.id == org.id);

                if (!org || !org.staffMembers) return undefined;
            } else {
                return undefined;
            }
        }
        let self: OrganizationMember = org.staffMembers.find(s => s.user.id === this.auth.user.id);

        return self.role;
    }

    updateAccount(user: User) {
        return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Users, `/${user.id}`, user, { withCredentials: true });
    }

    getAddress() {
        const id = this.auth.user.id;
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Users, `/${id}/address`, { withCredentials: true }).pipe(
            map((r: any) => {
                let address: Address = undefined;
                if(r.address) {
                    address = ADDRESS_SERIALIZER.deserialize(r.address, true);
                }

                return address;
            })
        );
    }


    getSubscriptions(): Observable<{ subscriptions: Subscription[], licenses: LegacyLicense[] }> {
        const id = this.auth.user.id;
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Users, `/${id}/subscriptions`, { withCredentials: true }).pipe(
            map((r: any) => {
                return {
                    subscriptions: r.subscriptions.map(s => SUB_SERIALIZER.deserialize(s, true)),
                    licenses: r.licenses.map(l => LICENSE_SERIALIZER.deserialize(l, true))
                }
            })
        );
    }

    getLegacyLicenses() {
        const serializer = new JsonSerializer(LegacyLicense);
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.License, '', { withCredentials: true }).pipe(
            map((r: any[]) => r.map(l => serializer.deserialize(l, true)))
        );

    }

    createTrialSubscription(order: OrderForm) {

        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Subscription, '/trial', order, { withCredentials: true }).pipe(
            map((r: any) => {
                return CREATE_TRIAL_SUBSCRIPTION_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    createTeacherTrialSubscription(order: OrderForm) {
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Subscription, '/trial-classroom', order, { withCredentials: true }).pipe(
            map((r: any) => {
                return CREATE_TRIAL_SUBSCRIPTION_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    createAdminTrialSubscription(order: OrderForm) {

        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.Subscription, `/trial-org`, order, { withCredentials: true }).pipe(
            map((r: any) => {
                return CREATE_TRIAL_SUBSCRIPTION_RESPONSE_SERIALIZER.deserialize(r, true);
            })
        );
    }

    /**
     * cancel subscription api
     * @param sub 
     */
    cancelSubscription(sub: Subscription) {
        return new Promise((resolve, rejects) => {
            return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Subscription, `/cancel/${sub.id}`, {}, { withCredentials: true }).subscribe({
                next: (response) => {
                resolve(response)
                },
                error: (err) => {
                    rejects(err)
                }
            })
        })
    }

    canCreateClassroomForOrg(org: Organization) {
        if (org.type != OrganizationType.Trials) {
            return true;
        }
        else if (org.staffMembers) {
            let member = org.staffMembers.find(m => m.user?.id == this.auth.user?.id);
            if (member && (member.role == OrganizationRole.Administrator)) {
                return true;
            }
        }

        return false;
    }

    // Only admin can create classroom of an organization of type "Trials".
    canCreateClassroom() {
        let orgs = this.currentProfile?.orgs;
        if (!orgs) return false;

        for (let i = 0; i < orgs.length; i++) {
            let org = orgs[i];

            if (this.canCreateClassroomForOrg(org)) {
                return true;
            }
        }

        return false;
    }

    setCurrency(currencyCode: string, userId?: string) {
        if (!userId) userId = this.auth?.user?.id;
        if (!userId) return undefined;

        return new Promise((resolve, reject) => {
            return this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Users, `/${userId}/currency/${currencyCode}`, {}, { withCredentials: true }).subscribe((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            })
        })
    }

    getCurrency(userId?: string) {
        if (!userId) userId = this.auth?.user?.id;
        if (!userId) return Promise.resolve(undefined);

        return new Promise((resolve, reject) => {
            this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Users, `/${userId}/currency`, { withCredentials: true }).pipe(
                map((r: any) => {
                    const currency: string = r.currency;

                    return currency;
                })).subscribe((currency) => {
                    resolve(currency);
                }, (ex) => {
                    resolve(undefined);
                })
        });
    }

    getProfile(id: string, full: boolean = false) {
        let query: string = full ? "?full=true" : "";

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Users, `/${id}/profile` + query, { withCredentials: true }).pipe(
            map((r: any) => {
                return USER_PROFILE_SERIALIZER.deserialize(r, true);
            })

        );
    }
}


@Injectable({
    providedIn: 'root'
})
export class UserSelfResolverService  {

    constructor(private account: AccountService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserProfileResponse> {

        const self = this.account.getUserProfile();

        if(!self) {
            return self;
        }

        return self.pipe(map((r) => {
            if(r.onboardingForms && (r.onboardingForms.length > 0)) {
                let destinationRoute = r.onboardingForms[0].type.route;
                if(destinationRoute && !state.url.startsWith(destinationRoute) && !state.url.startsWith("/administration") && !state.url.startsWith("/developer") && !state.url.startsWith("/order")) {
                    let wantedUrl = state.url;

                    let returnUrl: string;
        
                    if (wantedUrl && (wantedUrl != '/')) {
                        returnUrl  = encodeURIComponent(wantedUrl);
                    }
                    this.router.navigate([destinationRoute], {
                        ...returnUrl && {
                            queryParams: {
                                navigateTo: returnUrl
                            }
                        }
                    });
                }
            }
            return r;
        }));
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserSubscriptionsResolverService  {

    constructor(private account: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ subscriptions: Subscription[], licenses: LegacyLicense[] }> {

        return this.account.getSubscriptions();
    }
}


