import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { WordListWord } from '@applogic/model';

interface WordContainer {
    word: WordListWord;
    width: number;
}

@Component({
    selector: 'app-create-word-list-panel',
    templateUrl: './create-word-list-panel.component.html',
    styleUrls: ['./create-word-list-panel.component.scss']
})
export class CreateWordListPanelComponent implements OnInit {

    @Output() public onListChanged:EventEmitter<CreateWordListPanelComponent> = new EventEmitter();
    
    @ViewChild('hiddenText') textEl: ElementRef;
    
    containers: WordContainer[] = [];

    hiddenTextValue: string = "";

    wordsInitialized: boolean = false;
    inputsInitialized: boolean = false;

    readonly minInputWordWidth: number = 32;

    @ViewChildren('wordInput') inputs: QueryList<ElementRef>;
    
    @Input()
    words: WordListWord[];

    public isValid: boolean = false;
    public wasValidedOnce: boolean = false;

    errorObject: any;

    constructor(private changeDetectorRef: ChangeDetectorRef) {

    }

    ngOnInit(): void {
        if(!this.words) {
            this.words = [];

            let word: WordListWord;

            word = new WordListWord();
            word.text = "pain";
            this.words.push(word);

            word = new WordListWord();
            word.text = "ciel";
            this.words.push(word);

            word = new WordListWord();
            word.text = "institutionnelle";
            this.words.push(word);
        } else {
            if(this.words && (this.words.length > 0)) {
                this.validate();
            }
        }

        this.containers = [];
        this.words.forEach((w) => {
            let container: WordContainer = {
                word: w,
                width: 64
            };
            this.containers.push(container);
        });

        // Set all words width sequentially since the function can only set one at once.
        this.containers.reduce((p, c) => p.then(() => {
            return this.setWidth(c);
        }), Promise.resolve()).then(() => {
            this.wordsInitialized = true;
        });
    }

    public ngAfterViewInit(): void
    {
        this.inputs.changes.subscribe((comps: QueryList <ElementRef>) =>
        {
            // The first event is for the initialization.
            if(this.inputsInitialized) {
                setTimeout(() => {
                    this.inputs.last?.nativeElement?.focus();
                });
            } else {
                this.inputsInitialized = true;
            }
        });
    }

    onWordPropertyChanged(event: any, word: any, propertyName: string) {

    }

    onInput(event: any, container: WordContainer) {
        this.setWidth(container);
        
        if(!this.isValid && this.wasValidedOnce) {
            this.validate();
        }

        this.onListChanged.emit(this);
    }

    onBlur(event: any, container: WordContainer) {
        this.validate();
    }

    alphaSort() {
        this.containers.sort(function (a, b) {
            return (a.word.text > b.word.text) ? 1 : -1;
        });

        this.words.sort(function (a, b) {
            return (a.text > b.text) ? 1 : -1;
        });

        this.onListChanged.emit(this);
    }

    addNewWord(event: any) {
        this.addWord("");
    }

    private addWord(text: string) {
        console.log("Add word: " + text);
        const container: WordContainer = {word: new WordListWord(), width: 64};
        container.word.text = text;

        if(text) container.word.text = text;

        this.setWidth(container).then(() => {
            setTimeout(() => {
                this.containers.push(container);
                this.words.push(container.word);
                this.onListChanged.emit(this);
            });
        });
    }

    removeWord(event: any, container: WordContainer) {
        let index = this.containers.indexOf(container, 0);
        if (index > -1) {
            this.containers.splice(index, 1);
        }

        index = this.words.indexOf(container.word, 0);
        if (index > -1) {
            this.words.splice(index, 1);
        }

        this.validate();
        
        this.onListChanged.emit(this);
    }

    setWidth(container: WordContainer) {
        let promise = new Promise<void>((resolve, reject) => {
            if(container.word.text.length > 0)
            {
                this.hiddenTextValue = container.word.text;
            }
            
            setTimeout(() => {
                if(container.word.text.length > 0) {
                    container.width = Math.max(this.minInputWordWidth, this.textEl.nativeElement.offsetWidth);
                }
                else {
                    container.width = this.minInputWordWidth;
                }

                resolve();
            });
        });

        return promise;
    }

    getWords() {
        return this.containers.map(c => {
            c.word.text = c.word.text.trim();
            return c.word;
        });
    }

    validate() {
        this.wasValidedOnce = true;
        if(this.words && (this.words.length > 0)) {
            const wordsDict: any = {};

            for(let i=0; i<this.words.length; i++) {
                const word = this.words[i];
                if(word.text.trim().length == 0) {
                    this.isValid = false;
                    this.errorObject = { userMessage: $localize`:@@create-word-list-panel-component-empty-word-error:there should be no empty words`};
                    return;
                }

                if(wordsDict[word.text]) {
                    this.isValid = false;
                    this.errorObject = { userMessage:$localize`:@@create-word-list-panel-component-duplicate-word-error:the same word cannot be repeated`};
                    return;
                }
                else {
                    wordsDict[word.text] = true;
                }
            }

            this.errorObject = undefined;
            this.isValid = true;
            return;
        }

        this.errorObject = { userMessage: $localize`:@@create-word-list-panel-component-empty-list-error:the word list must not be empty`};
        this.isValid = false;
    }

    onPaste(container: WordContainer, event: any) {
        let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
        let pastedText: string = clipboardData.getData('text');
        if(!pastedText) return;

        pastedText = pastedText.trim();
        if(pastedText.indexOf(" ") != -1) {
            const wordsToAdd: string[] = [];
            const words = pastedText.split(" ");

            for(let i=0; i<words.length; i++) {
                const word: string = words[i].trim();
                if((word.length > 0) && wordsToAdd.indexOf(word) == -1) {
                    wordsToAdd.push(word);
                }
            }

            if(wordsToAdd.length > 0) {
                container.word.text = wordsToAdd[0];
                for(let i=1; i<wordsToAdd.length; i++) {
                    const word: string = wordsToAdd[i];
                    this.addWord(word);
                }
                return false;
            }
        }
        // if(pastedText.ind)
    }
}
