import { Component, Input, ViewEncapsulation, signal } from '@angular/core';

import { DynamicMaterialColorPalette, DynamicMaterialColorService } from '../dynamic-material-color.service';
import { Subscription } from 'rxjs';

export const DEFAULT_COLOR = '#8714fa';


@Component({
    selector: 'app-dynamic-material-color-selector',
    templateUrl: './dynamic-material-color-selector.component.html',
    styleUrl: './dynamic-material-color-selector.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class DynamicMaterialColorSelectorComponent {

    

    color = signal(DEFAULT_COLOR);

    @Input()
    palette: DynamicMaterialColorPalette = DynamicMaterialColorPalette.Primary;

    private presetChangedSubscription: Subscription;

    constructor(private dynamicMaterialColorService: DynamicMaterialColorService) {
        this.presetChangedSubscription = dynamicMaterialColorService.onPresetChanged.subscribe((preset) => {
            this.updateColor();
        });
    }

    ngOnInit() {
        this.updateColor();
    }

    ngOnDestroy() {
        if(this.presetChangedSubscription) {
            this.presetChangedSubscription.unsubscribe();
            this.presetChangedSubscription = undefined;
        }
    }

    updateColor() {
        this.color.set(this.dynamicMaterialColorService.getColor(this.palette));
    }

    onColorChange(event: string) {
        this.dynamicMaterialColorService.themeFromSelectedColor(this.color() || DEFAULT_COLOR, this.palette);
    }

}
