<mat-form-field [appearance]="data.appearance || 'outline'"
                [floatLabel]="data.floatLabel || 'always'"
                [subscriptSizing]="data.subscriptSizing || 'fixed'"
                class="custom-form-field custom-form-input-field">
    <mat-label *ngIf="field.label">{{field.label}}</mat-label>

    <textarea [type]="field.data.inputType"
              [formControl]="field.control"
              [autocomplete]="data.autocomplete || 'off'"
              [placeholder]="data.placeholder || ''"
              (focus)="onInputFocus()"
              (focusout)="onInputFocusOut()"
              (change)="onInputChange($event)"
              (contextmenu)="showDebugs($event)"
              [rows]="field.data.rows || 3"
              matInput></textarea>
    <mat-error *ngIf="field.control.invalid">
        {{ formService.getFieldErrorMessage(field.control, data.typeKey || field.key) }}
    </mat-error>
</mat-form-field>