import { Injectable, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';

import { ApiDirectoryServer, ApiRoutes, RegisterSSOQuery, RegisterUserForm, RoutesServer, SSORedirectQuery, SSORedirectResponse, User, UserEmailPreferences, UserServiceType, ValidationFieldError } from '@applogic/model';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Params, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { AnalyticsService } from '../services/analytics.service';
import { ApiDirectoryService } from '../services/api-directory.service';
import { SocialLoginData } from '../social/social-login-data';
import { AngularUtils } from '../services/angular-utils';
import { JsonSerializer, Model } from '@uon/model';

export const LOCAL_STORAGE_USER_KEY = 'applogic/auth/current_user';
const LOCAL_STORAGE_EXPIRES_KEY = 'applogic/auth/expires';

const SSO_REDIRECT_RESPONSE_SERIALIZER = new JsonSerializer(SSORedirectResponse);


@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {

    private static _user: User;
    private static analyticsUserSet: boolean = false;
    private static _expires: number;

    private static _instance: AuthService;

    onUserChange: EventEmitter<User> = new EventEmitter();

    constructor(
        private http: HttpClient,
        private ls: LocalStorageService,
        private router: Router,
        private languageService: LanguageService,
        private analyticsService: AnalyticsService,
        private dirService: ApiDirectoryService,
        private activatedRoute: ActivatedRoute) {

        AuthService._instance = this;

        AuthService._expires = this.ls.get(LOCAL_STORAGE_EXPIRES_KEY) || 0;

        const user = this.ls.get(LOCAL_STORAGE_USER_KEY);
        this.setUser(user && Model.New(User, user));
        this.setAnalyticsUser(this.user);
        this.activatedRoute.queryParams.subscribe((p: Params) => {
            this.checkLoginToken(p);
        });


        if (this.user) {
            var splittedUrl = window.location.href.split('/');
            if (splittedUrl.length > 3) {
                var lang: string = splittedUrl[3];

                if (this.languageService.getLanguageName(lang) != undefined) {
                    if (environment.multiLanguages && (lang != this.user.lang)) {
                        this.dirService.serverPatch(RoutesServer.Api, ApiRoutes.Users, `/${this.user.id}/lang/${lang}`, {}, { withCredentials: true }).subscribe(() => {
                        });
                        this.user.lang = lang;
                        this.updateUser();
                    }
                }
            }
        }

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.setAnalyticsUser(this.user);

                let evt: NavigationStart = event as NavigationStart;
            }

            if (event instanceof NavigationEnd) {
                let evt: NavigationEnd = event as NavigationEnd;
            }
        });
        this.ngOnInit(); // Because it is not called on a service.
    }

    ngOnInit(): void {
        this.onUserChange.subscribe(user => {
            if (user) {
                if (!this.languageService.isLocked()) {
                    this.languageService.changeLanguage(user.lang);
                }
            }
        });
    }

    ngOnDestroy() {
        this.onUserChange.unsubscribe();
    }

    /**
     * Access to the currently logged in user
     */
    get user() {
        return AuthService._user;
    }

    /**
     * 
     */
    get expires() {
        return AuthService._expires;
    }

    isLoggedIn(): boolean {

        if (!this.user) return false;

        if (Date.now() > this.expires) return false;

        return true;
    }

    getPasswordResetSummary(token: string) {

        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.AccountRecovery, `/password-reset/${token}`, { withCredentials: true });
    }

    refreshToken() {

        return this.dirService.serverGet(RoutesServer.Auth, ApiRoutes.Signin, `/refresh`, { withCredentials: true });
    }

    submitPasswordResetRequest(email: string) {

        const payload = {
            email: email,
            lang: this.languageService.currentLanguageCode
        };

        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.AccountRecovery, `/password-reset`, payload, { withCredentials: true }).pipe(
            catchError((err) => {
                if (err.status === 404) {
                    err.userMessage = $localize`:@@login-form-email-not-associated-with-any-account:There’s no account associated with this email address.`;
                }

                return throwError(err);
            })
        );
    }

    submitPasswordReset(token: string, payload: any) {
        const sendData = {
            password: payload,
            confirmPassword: payload
        }
        return this.dirService.serverPost(RoutesServer.Api, ApiRoutes.AccountRecovery, `/password-reset/${token}`, sendData, { withCredentials: true });
    }


    /**
     * Try registering a new user
     * 
     * @param {any} user The user to register.
     * @param {string} context (Optional) The context of use. Used for debugging.
     */
    tryRegister(query: RegisterUserForm, context?: string) {
        
        query.lang = this.languageService.currentLanguageCode;
        query.context = context;

        const source = this.activatedRoute.snapshot.queryParamMap.get("source");
        if(source) {
            query.source = source;
        }

        return this.dirService.serverPost(RoutesServer.Auth, ApiRoutes.Signin, `/register`,
            query,
            { withCredentials: true })
            .pipe(
                map((val: any) => {

                    if (val.type === 'auth') {
                        this.assignFromResponse(val);
                        this.analyticsService.sendEvent("sign_up", {
                            method: "Portal",
                            context: query.context
                        });
                        this.onUserChange.emit(val.result.user);
                    }

                    return val;
                }), catchError((error) => {
                    const errorMessage = this.localizeErrorMessage(error, query.context, 'register');
                    return throwError(errorMessage);
                })
            );

    }

    /**
     * Try registering a new user using SSO.
     * 
     * @param {any} user The user to register.
     * @param {string} context (Optional) The context of use. Used for debugging.
     */
    tryRegisterSSO(query: RegisterSSOQuery, context?: string) {
        
        if(query.serviceType != UserServiceType.Google) {
            throw throwError(() => new Error("Unsupported service type '" + query.serviceType + "' with tryLoginService function."));  
        }

        const source = this.activatedRoute.snapshot.queryParamMap.get("source");
        if(source) {
            query.source = source;
        }

        query.lang = this.languageService.currentLanguageCode;
        query.context = context;

        return this.dirService.serverPost(RoutesServer.Auth, ApiRoutes.Signin, `/register_sso`,
            query,
            { withCredentials: true })
            .pipe(
                map((val: any) => {

                    if (val.type === 'auth') {
                        this.assignFromResponse(val);
                        this.analyticsService.sendEvent("sign_up", {
                            method: "Portal",
                            context: query.context
                        });
                        this.onUserChange.emit(val.result.user);
                    }

                    return val;
                }), catchError((error) => {
                    const errorMessage = this.localizeErrorMessage(error, query.context, 'register');
                    return throwError(errorMessage);
                })
            );
    }

    /**
     * Check if an email exists.
     * 
     * @param {string} email The user email to check.
     * 
     * @returns {Observable<boolean>} Returns an observable that returns true if the email exists.
     */
    checkEmailExist(email: string): Observable<boolean> {
        if(AngularUtils.isDevMode()) {
            var err = new Error();
            console.log("checkEmailExist stack trace " + err.stack);
        }

        const encoded_email = encodeURIComponent(email);
        return this.dirService.serverGet(RoutesServer.Auth, ApiRoutes.Signin, `/checkEmailExist?email=${encoded_email}`, { withCredentials: true })
            .pipe(
                map((response: any) => {
                    return response?.exists;
                }),
                catchError(err => {
                    const STATUS_CODE = err?.error?.code;
                    if (STATUS_CODE === 403 || STATUS_CODE === 404) {

                    }
                    return throwError(err);
                })
            );

    }

    /**
     * Get the list of servers where user can authenticate.
     * 
     * @param {any} data 
     * @param {boolean} firstOnly Only retrieve the first server.
     * 
     * @returns {{id: string, fullName: string, server: ApiDirectoryServer}[]} The list of servers where user can authenticate.
     */
    async getAuthenticateServers(data: any, firstOnly: boolean, serviceType: UserServiceType) {
        

        let result: {id: string, fullName: string, server: ApiDirectoryServer}[] = [];

        let ds = await this.dirService.getList();

        // Build the list of servers setting the current server first.
        let servers: ApiDirectoryServer[] = [];
        servers.push(ds.currentServer);
        ds.servers.forEach((s) => {
            if ((s.key == ds.currentServer.key)) return;
            servers.push(s);
        });

        for (let i = 0; i < servers.length; i++) {
            const server = servers[i];
            
            if( (server.type == environment.serverType) )
            {
                const apiUrl: string = this.dirService.filterHost(server.auth.host);
                await this.dirService.serverPost(RoutesServer.Auth, ApiRoutes.Signin, `/authenticateUser`, {serviceType, token: data}, { withCredentials: true }).toPromise().then((data: any) => {
                    if(data.result) {
                        result.push({
                            id: data.result.id,
                            fullName: data.result.fullName,
                            server
                        });
                    }
                });
                if(firstOnly && (result.length > 0) ) {
                    break;
                }
            }
        }

        return result;
    }

    /**
     * Get the authentification for user email.
     * 
     * Can be used to check if a user can login.
     * Also useful for login in two step (email and password in a separate view).
     * 
     * @param {string} email
     */
    getAuthenticateUserEmail(email: string) {
        const encoded_email = encodeURIComponent(email)
        return this.dirService.serverGet(RoutesServer.Auth, ApiRoutes.Signin, `/authenticateUserEmail?email=${encoded_email}`, { withCredentials: true })
            .pipe(
                map((val: any) => {
                    return val;
                })
            );

    }

    /**
     * Get the authentification for user.
     * 
     * Can be used to check if a user can login.
     * Also useful for login in two step (email and password in a separate view).
     * 
     * @param {any} data
     * @param {UserServiceType} serviceType
     * 
     */
     getAuthenticateUser(data: any, serviceType: UserServiceType) {
        return this.dirService.serverPost(RoutesServer.Auth, ApiRoutes.Signin, `/authenticateUser`, {serviceType, data}, { withCredentials: true })
            .pipe(
                map((val: any) => {
                    return val;
                })
            );
    }

    /**
     * Try getting an authentication token from the backend
     * 
     * @param {string} username The user email.
     * @param {string} password The user password.
     * @param {string} context (Optional) The context of use. Used for debugging.
     */
    tryLogin(username: string, password: string, context?: string, server?: ApiDirectoryServer) {

        const baseUrl = this.dirService.getRoute(RoutesServer.Auth);
        let apiUrl: string = server ? this.dirService.filterHost(server.auth.host) : baseUrl;

        if(apiUrl != baseUrl) {

            return new Observable((observer) => {
                
                this.authenticateNewToken(username, password, context, server).subscribe((token) => {
                    let portalUrl = server.portal.host + "/" + this.languageService.currentLanguageCode + "/?logintoken=" + token;
                    
                    location.href = portalUrl;
                }, (error) => {
                    // location.href =  server.portal.host + "/" + this.languageService.currentLanguageCode + "/";
                    const errorMessage = this.localizeErrorMessage(error, context, 'login');
                    observer.error(errorMessage);
                    return throwError(errorMessage);
                });
            });
        }

        return this.http.post(`${apiUrl}/signin`,
            { username, password, context },
            { withCredentials: true })
            .pipe(
                map((val: any) => {
                    if (val.type === 'auth') {
                        this.assignFromResponse(val);
                        this.analyticsService.sendEvent("login", {
                            method: "Portal",
                            context: context,
                            user_type: 'user'
                        });
                        this.onUserChange.emit(val.result.user);
                    }

                    return val;
                }), catchError((error) => {
                    const errorMessage = this.localizeErrorMessage(error, context, 'login');
                    return throwError(errorMessage);
                })
            );

    }

    /**
     * Try getting an authentication token from the backend
     * 
     * @param {string} username The user email.
     * @param {string} password The user password.
     * @param {string} context (Optional) The context of use. Used for debugging.
     */
     tryLoginToken(loginToken: string, context?: string, server?: ApiDirectoryServer) {
        const baseUrl = this.dirService.getRoute(RoutesServer.Auth);
        let apiUrl: string = server ? this.dirService.filterHost(server.auth.host) : baseUrl;

        return this.http.post(`${apiUrl}/signin/usetoken`,
            { token: loginToken, context },
            { withCredentials: true })
            .pipe(
                map((val: any) => {

                    if (val.type === 'auth') {
                        this.assignFromResponse(val);
                        this.analyticsService.sendEvent("login", {
                            method: "Portal",
                            context: context,
                            user_type: 'user'
                        });
                        this.onUserChange.emit(val.result.user);
                    }

                    return val;
                }), catchError((error) => {
                    const errorMessage = this.localizeErrorMessage(error, context, 'login');
                    return throwError(errorMessage);
                })
            );

    }

    resolveAuthenticateServer(login: SocialLoginData) {
        return new Promise((resolve, rejects) => {
            this.getAuthenticateServers(login.data, true, login.type).then((servers) => {
                if (servers && servers.length) {
                    resolve(servers[0]);
                } else {
                    rejects({
                        status: 404,
                        userMessage: $localize`:@@login-form-email-not-associated-with-any-account:There’s no account associated with this email address.`
                    });
                }
            }).catch((err) => {
                rejects(err);
            }); 
        });
    }

    tryLoginServiceServerResolve(login: SocialLoginData) {
        return new Promise((resolve, rejects) => {
            this.getAuthenticateServers(login.data, true, login.type).then((servers) => {
                if (servers && (servers.length > 0)) {
                    const firstServer = servers[0];
                    if (environment.serverType != "prod") {
                        console.log("Selected server: " + JSON.stringify(firstServer));
                    }

                    this.clear();

                    this.tryLoginService(login, "site login", firstServer.server).subscribe((res) => {
                        resolve(undefined);
                    }, (ex) => {
                        rejects(ex);
                    });
                } else {
                    rejects({
                        userMessage: $localize`:@@login-form-email-not-associated-with-any-account:There’s no account associated with this email address.`
                    });
                }
            }).catch((err) => {
                rejects(err);
            }); 
        });
    }

    /**
     * Try getting an authentication from google SSO token.
     * 
     * @param {any} data The data returned by Google.
     * @param {string} context (Optional) The context of use. Used for debugging.
     * @param {ApiDirectoryServer} server (Optional) The server to use.
     */
    tryLoginService(login: SocialLoginData, context?: string, server?: ApiDirectoryServer) {
        const baseUrl = this.dirService.getRoute(RoutesServer.Auth);
        let apiUrl: string = server ? this.dirService.filterHost(server.auth.host) : baseUrl;

        if(login.type != UserServiceType.Google) {
            throw throwError("Unsupported service type '" + login.type + "' with tryLoginService function.");  
        }

        return this.http.post(`${apiUrl}/signin/google`,
            { token: login.data, context },
            { withCredentials: true })
            .pipe(
                map((val: any) => {
                    if (val.type === 'auth') {
                        this.assignFromResponse(val);
                        this.analyticsService.sendEvent("login", {
                            method: "Google",
                            context: context,
                            user_type: 'user'
                        });
                        this.onUserChange.emit(val.result.user);
                    }

                    return val;
                }), catchError((error) => {
                    if(error.status == 404) {
                     error.userMessage = $localize`:@@login-form-email-not-associated-with-any-account:There’s no account associated with this email address.`;
                    }
                    else {
                        this.localizeErrorMessage(error, context, 'login');
                    }
                    return throwError(error);
                })
            );
    }

    /**
     * Login as another user.
     * 
     * @param {User} user User
     */
    impersonate(user: User, context: string) {

        const apiUrl = this.dirService.getRoute(RoutesServer.Auth);

        return this.http.post(`${apiUrl}/signin/impersonate`,
            { username: user.email },
            { withCredentials: true })
            .pipe(
                map((val: any) => {
                    if (val.type === 'auth') {
                        this.assignFromResponse(val);
                        this.analyticsService.sendEvent("login", {
                            method: "Impersonate",
                            context,
                            user_type: 'user'
                        });
                        this.onUserChange.emit(val.result.user);
                    }

                    return val;
                }), catchError((error) => {
                    const errorMessage = this.localizeErrorMessage(error, context, 'login');
                    return throwError(errorMessage);
                })
            );
    }

    /**
     * Generate a new authentication token.
     * 
     * @param {string} username The user email.
     * @param {string} password The user password.
     * @param {string} context (Optional) The context of use. Used for debugging.
     */
     authenticateNewToken(username: string, password: string, context?: string, server?: ApiDirectoryServer): Observable<string> {
        const baseUrl = this.dirService.getRoute(RoutesServer.Auth);
        let apiUrl: string = server ? this.dirService.filterHost(server.auth.host) : baseUrl;

        return this.http.post(`${apiUrl}/signin/newtoken`,
            { username, password, context },
            { withCredentials: true })
            .pipe(
                map((val: any) => {

                    if (val.type === 'auth') {
                        return val.token;
                    }

                    return undefined;
                }), catchError((error) => {
                    const errorMessage = this.localizeErrorMessage(error, context, 'login');
                    return throwError(errorMessage);
                })
            );

    }

    /**
     * Logout
     * @param username 
     * @param password 
     */
    logout() {

        return this.dirService.serverDelete(RoutesServer.Auth, ApiRoutes.Signin, ``,
            { withCredentials: true })
            .pipe(
                map((val) => {
                    this.clear();
                    return val;
                })
            );

    }

    clear() {
        this.ls.clear();
        AuthService._user = null;
        this.ls.remove(LOCAL_STORAGE_USER_KEY);

        AuthService._expires = 0;
        this.ls.remove(LOCAL_STORAGE_EXPIRES_KEY);
    }

    public static clearStatic(ls: LocalStorageService) {
        ls.clear();
        this._user = null;
        ls.remove(LOCAL_STORAGE_USER_KEY);

        this._expires = 0;
        ls.remove(LOCAL_STORAGE_EXPIRES_KEY);

        if(!AuthService._instance) {
            console.error("The AuthService was not instantiated. (clearStatic)");
        }
    }


    updateExpires(val: number) {
        AuthService._expires = val;
        this.ls.set(LOCAL_STORAGE_EXPIRES_KEY, val);
    }

    public static updateExpiresStatic(ls: LocalStorageService, val: number) {
        AuthService._expires = val;
        ls.set(LOCAL_STORAGE_EXPIRES_KEY, val);

        if(!AuthService._instance) {
            console.error("The AuthService was not instantiated. (updateExpiresStatic)");
        }
    }

    testFunction() {
        
    }

    updateUser() {
        this.ls.set(LOCAL_STORAGE_USER_KEY, AuthService._user);
    }

    assignFromResponse(val: any) {

        this.setUser(Model.New(User, val.result.user));
        AuthService._expires = val.result.expires;

        this.ls.set(LOCAL_STORAGE_USER_KEY, AuthService._user);
        this.ls.set(LOCAL_STORAGE_EXPIRES_KEY, AuthService._expires);
    }

    /**
     * this function return the error localize message
     * @param err 
     */
    localizeErrorMessage(err: any, context: string, type: string) {
        if(err?.error?.type == "body") {
            const errors: ValidationFieldError[] = err.error.errors;

            const oldFormatErrors: any = {};
            errors.map(e => {
                const key = e.path.join('.');

                const errorMessages: string[] = [];
                for(const key of Object.keys(e.errors)) {
                    errorMessages.push(e.errors[key]);
                }
                oldFormatErrors[key] = errorMessages;
            });

            Object.assign(err.error, oldFormatErrors);
        }

        if (err?.error?.email && err.error.email[0] === "email already registered") {
            err.userMessage = $localize`:@@form-field-email-already-exists:Email already exists.`;
        } else if (err?.error?.inviteCode) {
            err.userMessage = $localize`:@@form-field-invalid-invite-code:Invalid invite code.`;
        } else if (err.status === 401) {
            if (context == 'site-login') {
                err.userMessage = $localize`:@@login-form-password-incorrect-error:Please try again or click on Forgot my password to reset it.`;
            }
            else {
                let lang = this.languageService.currentLanguageCode;
                let url: string;
                if(environment.multiLanguages)
                {
                    url = "/" + lang + "/password-reset";
                }
                else {
                    url = "/password-reset";
                }

                
                err.userMessage = $localize`:@@staff-invite-login-error-message:Incorrect password. <a href="${url}">Did you forget your password?</a>`;
            }
        } else {
            if(type == 'login') {
                err.userMessage = $localize`:@@form-field-login-internal-error:If you see this, refresh your browser page and re-enter your information. If the problem persists, <a href="mailto:info@appligogiques.com">please contact us</a>.`;
            }
            else {
                err.userMessage = $localize`:@@form-field-internal-error:Internal error. Please try again. If the problem persists, please contact us via email <a href="mailto:info@appligogiques.com">info@appligogiques.com</a>.`;
            }
        }

        return err;
    }

    /**
     * Update email preferences
     * 
     * @param {UserEmailPreferences} prefs User email preferences. 
     * @param {string} token Token to allow the update.
     */
    updateEmailPreferences(prefs: UserEmailPreferences, token: string) {
        return new Promise((resolve, rejects) => {
            const routeUrl: string = this.dirService.getRoute(RoutesServer.Auth, ApiRoutes.Signin, "/subscription");
            this.http.post(`${routeUrl}`, { token, prefs }, { withCredentials: true, observe: 'response' }).subscribe({
                next: response => {
                    resolve(response)
                },
                error: (err) => {
                    err.userMessage = this.getEmailPreferencesErrorMessage(err.status);
                    rejects(err);
                }
            })
        })
    }

    /**
     * Get email preferences
     * 
     * @param {string} token Token to allow the update.
     */
    getEmailPreferences(token: string) {
        return new Promise((resolve, rejects) => {
            this.dirService.observeRoute(RoutesServer.Auth, ApiRoutes.Signin, `/subscription?token=${token}`).subscribe({
                next: (routeUrl) => {
                    this.http.get(`${routeUrl}`, { withCredentials: true, observe: 'response' }).subscribe({
                        next: response => {
                            resolve(response.body);
                        },
                        error: (err) => {
                            err.userMessage = this.getEmailPreferencesErrorMessage(err.status);
                            rejects(err);
                        }
                    })
                },
                error: (ex) => {
                    rejects(ex);
                }
            });
        })
    }

    getEmailPreferencesErrorMessage(code: number): string {
        let result: string;

        if (code == 410) {
            result = $localize`:@@auth-service-email-preferences-token-expired:Token Expired`;
        } else if (code == 400) {
            result = $localize`:@@auth-service-email-preferences-invalid-token:Invalid Token`;
        } else if (code == 404) {
            result = $localize`:@@auth-service-email-preferences-user-not-found:User not found`;
        }
        else {
            result = $localize`:@@auth-service-email-preferences-unknown:Unknown Error`;
        }

        return result;
    }

    /**
     * Navigate to the login page.
     * 
     * @param {string} url (Optional) The url where we came from (or try to go).
    */
    navigateToLogin(url?: string) {
        if (!url) {
            url = location.href;

        }

        if(url.startsWith("https:") || url.startsWith("http:")) {
            let splitted = url.split("/");

            if (environment.multiLanguages) {
                url = splitted.slice(4).join("/");
            }
            else {
                url = splitted.slice(3).join("/");
            }
        }

        if (!(url.startsWith(environment.authBaseUrl + '/signin?') || (url == environment.authBaseUrl + '/signin'))) {
            let loginUrl = '/login';

            let wantedUrl = url;

            if (url.startsWith(environment.authBaseUrl)) {
                wantedUrl = url.substring(environment.authBaseUrl.length);
            }

            if (wantedUrl && (wantedUrl != '/')) {
                loginUrl += "?navigateTo=" + encodeURIComponent(wantedUrl);
            }

            this.router.navigateByUrl(loginUrl);
        }
    }

    async checkLoginToken(p: Params) {
        let promise = new Promise<boolean>((resolve, reject) => {
            let loginToken: string = p["logintoken"];
            if(loginToken) {
                // Remove the logintoken parameter but keep other existing parameters.
                let np = JSON.parse(JSON.stringify(p));
                delete np["logintoken"];

                // AngularUtils.removeRouteQueryParam("logintoken", this.router, this.activatedRoute);

                // Get the current path without the parameters.
                let url = this.router.url.substr(0, this.router.url.indexOf("?"));
                
                this.tryLoginToken(loginToken, "Login Token").subscribe(() => {
                    resolve(true);
                    
                    this.router.navigate([url], { queryParams: np, replaceUrl: true });
                    
                }, (err) => {
                    resolve(true);
                    console.error(err);
                    this.router.navigate([url], { queryParams: np, replaceUrl: true });
                });
            } else {
                resolve(false);
            }
        });

        return promise;
    }

    private setUser(user: User) {
        AuthService._user = user;
        AuthService.analyticsUserSet = false;
    }

    private setAnalyticsUser(user: User) {
        if(AuthService.analyticsUserSet) return;
        AuthService.analyticsUserSet = true;

        // Must set the user_id again on page reload and when login.
        // See: https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
        // "each page where you include your Analytics tag"
        if(AuthService._user) {
            this.analyticsService.sendConfig({
                user_id: AuthService._user.id
            });
        }
        else {
            /*
            this.analyticsService.sendConfig({
                user_id: null
            });
            */
        }
    }

    SSORedirect(query: SSORedirectQuery) {
        return this.dirService.serverPost(RoutesServer.Auth, ApiRoutes.Signin, `/sso/redirect`,
        query,
        { withCredentials: true })
        .pipe(
            map((val: any) => {
                return SSO_REDIRECT_RESPONSE_SERIALIZER.deserialize(val, true);
            }), catchError((error) => {
                // const errorMessage = this.localizeErrorMessage(error, context, 'login');
                return throwError(() => error);
            })
        );
    }
}


@Injectable({
    providedIn: 'root'
})
export class PasswordResetSummaryResolverService  {

    constructor(private auth: AuthService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.auth.getPasswordResetSummary(route.params.token).pipe(
            catchError(err => of(null))
        );
    }
}