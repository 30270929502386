import { Directive, Input, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: '[appDynamicFontSize]'
})
export class DynamicFontSizeDirective {
    @Input() minFontSize: number = 4;
    @Input() maxFontSize: number = 20;

    protected _minSizeReached: boolean = false;

    // Use the ResizeObserver for a more presize resize events an avoid having the incorrect container size.
    private resizeObserver: ResizeObserver;

    constructor(private el: ElementRef) { }

    private adjustFontSize() {
        let fontSize = this.maxFontSize;
        const element = this.el.nativeElement;

        const startingFontSize = element.style.fontSize;

        element.style['white-space'] = "nowrap"
        element.style.fontSize = `${fontSize}px`;

        this._minSizeReached = false;

        while (element.scrollWidth > element.offsetWidth && fontSize > 1) {
            fontSize--;
            element.style.fontSize = `${fontSize}px`;

            if (fontSize <= this.minFontSize) {
                this._minSizeReached = true;
                break;
            }
        }

        if (this._minSizeReached) {
            element.style['white-space'] = "pre-wrap";
            element.style['line-height'] = element.style.fontSize;
        }
        else {
            delete element.style['white-space'];
        }

        if (startingFontSize != element.style.fontSize) {
            // console.log("## font size changed from " + startingFontSize + " to " + element.style.fontSize + " element width: " + element.scrollWidth);

        }
    }

    ngAfterViewInit() {
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                this.adjustFontSize();
            }
        });
        this.resizeObserver.observe(this.el.nativeElement);
    }

    ngOnDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }
}