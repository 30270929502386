<app-dialog icon="person">
    <app-dialog-title i18n="@@subscription-invoice-edit-dialog-title">Modify the invoice</app-dialog-title>
    <app-dialog-content>
        <app-products-order-card [subscriptionType]="invoice?.subscriptionType"
                             (onReady)="onProductSelectionReady()"
                             (onUpdate)="onProductsUpdate($event)"></app-products-order-card>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            (click)="save()">
        <span i18n="@@common-save">Save</span>
    </button>
</app-dialog>