import { Model, ID, Member, ArrayMember } from "@uon/model";

import { TranslatedValue } from '../common/translated.model';

@Model()
export class KnowledgebaseTooltip {


    @ID()
    id: string;

    @Member()
    section: string;

    @Member()
    key: string;

    @Member()
    content: string;


    @ArrayMember(TranslatedValue)
    contentTranslations: TranslatedValue<string>[];
}