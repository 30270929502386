import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { CountryService } from 'src/app/services/country.service';
import { Address, Utils } from '@applogic/model';
import PlaceResult = google.maps.places.PlaceResult;
import { GoogleMapsService } from 'src/app/address-ui/google-maps.service';
import { SelectCountriesOptionComponent } from '../../core/select-countries-option/select-countries-option.component';
import { CustomFormBuilder } from 'src/app/core/form-builder/custom-form-builder';
import { CustomFormUtils } from 'src/app/core/form-builder/custom-form-utils';
import { AddressNameInputResult } from '../address-name-input/address-name-input.component';
import { MatFormFieldAppearance, FloatLabelType, SubscriptSizing } from '@angular/material/form-field';


@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

    @Input() formData: any;

    @Input() allowSearch: boolean = true;

    @ViewChild('search', { static: true }) searchElementRef: ElementRef;

    @ViewChild(SelectCountriesOptionComponent, { static: true }) selectCountry: SelectCountriesOptionComponent;
    
    @Input()
    appearance: MatFormFieldAppearance = "outline";
    
    @Input()
    floatLabel: FloatLabelType = "always";

    @Input()
    subscriptSizing: SubscriptSizing = "fixed";
    
    addressForm = new CustomFormBuilder();

    get formValue() {
        return this.addressForm.formGroup.value;
    }

    get invalid() {
        return this.addressForm.formGroup.invalid;
    }

    constructor(readonly formService: FormService,
      private _formBuilder: UntypedFormBuilder,
      public countryService:CountryService,
      public googleAddressService:GoogleMapsService)
    {
        CustomFormUtils.SetAddressFields(this.addressForm);
    }

    ngOnInit(): void {
        if(this.formData) {
            this.addressForm.formGroup.reset(this.formData);
        }
    }
    
    onAutocompleteSelected(result: PlaceResult) {
        const ADDRESS_FORMAT: Address = Utils.getAddressFormattedData(result);

        this.setAddress(ADDRESS_FORMAT);
       
        this.searchElementRef.nativeElement.value = result?.name;   
    }

    public setAddress(address: Address) {
        this.addressForm.formGroup.patchValue({
            line1: address.street,
            line2: "",
            city: address.city,
            state: address.state,
            country: address.country.toLowerCase(),
            zipcode: address.zipcode,
        });

        this.formData = this.addressForm.formGroup.value;

        this.selectCountry.showSelectedData();
    }


    onSearchChange(event) {
        this.searchElementRef.nativeElement.value = event.target.value;
    }

    onAddressSelect(result: AddressNameInputResult) {
        this.setAddress(result.address);
    }
}
