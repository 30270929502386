import { ArrayMember, ID, Member, Model } from "@uon/model";
import { User } from "../user/user.model";


export enum TranslationPreload {
    Download = 0,
    Builtin = 1,
    Both = 2
};

@Model()
export class AppTranslationUnitOptions {
    @Member()
    maxChars: number;

    @ArrayMember(String)
    hints: string[];

}

@Model()
export class AppTranslationUnit {

    @ID()
    id: string;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    deletedOn: Date;

    @Member()
    deletedBy: User;

    @Member()
    key: string;

    @Member()
    fr: string;

    @Member()
    en: string;

    @Member()
    es: string;

    @Member()
    desc: string;

    @Member()
    notes: string;

    @Member()
    options: AppTranslationUnitOptions;

    // The user id of the last modifier.
    @Member()
    lastEditUserId: string;

    @Member()
    preload: TranslationPreload;
}

@Model()
export class AppTranslationUnitHistory {

    @ID()
    id: string;
    
    @Member()
    unitId: string;

    @Member()
    userId: string;

    @Member()
    comment: string;

    @Member()
    createdOn: Date;
    
    @ArrayMember(String)
    keys: string[];
    
    @Member()
    oldValues: {[key: string]: any};

    @Member()
    newValues: {[key: string]: any};
}

@Model()
export class AppTranslationSession {

    @ID()
    id: string;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    userId: string;
}

@Model()
export class AppTranslationSessionUnit {

    @ID()
    id: string;
    
    @Member()
    createdOn: Date;

    @Member()
    updatedOn: Date;

    @Member()
    collectionKey: string;

    @Member()
    collectionId: string;

}