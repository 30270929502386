<div [formGroup]="_currentGroup.formGroup"
     class="app-form-container flex-column flex-default-gap"
     [ngClass]="{'first-group-container': isFirst}">
    <div class="custom-form--group-title"
        *ngIf="_currentGroup.label">
        {{_currentGroup.label}}
    </div>

    <div class="fields-container-parent"
         [ngClass]="{'group-horizontal': _currentGroup.data.orientation == 'horizontal'}">
        <div class="fields-container-for-ng-content child-full-width-distributed-row"
             [ngClass]="{'group-horizontal': _currentGroup.data.orientation == 'horizontal'}">
            <ng-content select="*"></ng-content>
        </div>
        <div class="fields-container"
             [ngClass]="{'group-horizontal': _currentGroup.data.orientation == 'horizontal'}">
            @for (field of _currentGroup.fields; track field; let i = $index) {
            <div class="custom-form-field-container"
                 [ngClass]="{'hidden-field': field.hidden}">
                <div [ngSwitch]="field.type"
                     [ngClass]="{'first-mat-form-field-with-floating-label': field.useMatFormField && isFirst && (i == 0)}">
                    <hr *ngSwitchCase="FieldType.Separator"
                        class="topline">
                    <app-custom-form-input-field *ngSwitchCase="FieldType.Input"
                                                 class="custom-form-field"
                                                 [parent]="this"
                                                 [field]="field"
                                                 [data]="field.data"></app-custom-form-input-field>
                    <app-custom-form-text-area-field *ngSwitchCase="FieldType.TextArea"
                                                     class="custom-form-field"
                                                     [parent]="this"
                                                     [field]="field"
                                                     [data]="field.data"></app-custom-form-text-area-field>
                    <app-custom-form-select-field *ngSwitchCase="FieldType.Select"
                                                  class="custom-form-field"
                                                  [parent]="this"
                                                  [field]="field"
                                                  [data]="field.data"></app-custom-form-select-field>
                    <app-custom-form-right-select-field *ngSwitchCase="FieldType.RightSelect"
                                                        class="custom-form-field"
                                                        [parent]="this"
                                                        [field]="field"
                                                        [data]="field.data"></app-custom-form-right-select-field>
                    <app-custom-form-radio-select-field *ngSwitchCase="FieldType.RadioSelect"
                                                        class="custom-form-field"
                                                        [parent]="this"
                                                        [field]="field"
                                                        [data]="field.data">
                    </app-custom-form-radio-select-field>
                    <app-custom-form-toggle-field *ngSwitchCase="FieldType.Toggle"
                                                  class="custom-form-field"
                                                  [parent]="this"
                                                  [field]="field"
                                                  [data]="field.data">
                    </app-custom-form-toggle-field>
                    <app-form-builder-renderer *ngSwitchCase="FieldType.Group"
                                               [group]="field"
                                               [form]="field.form"
                                               [templates]="templates"
                                               [isFirst]="false"></app-form-builder-renderer>
                    <app-select-countries-option *ngSwitchCase="FieldType.Country"
                                                 [selectFormControl]="field.control"
                                                 [isRequired]="field.data.isRequired"
                                                 [appearance]="field.data.appearance || 'outline'"
                                                 [floatLabel]="field.data.floatLabel || 'always'"
                                                 [subscriptSizing]="field.data.subscriptSizing || 'fixed'"
                                                 (onChangeSelectedCountry)="setCountryValue(field, $event)">
                    </app-select-countries-option>
                    <ng-template *ngSwitchCase="FieldType.FormGroupTemplate"
                                 [ngTemplateOutlet]="templates[field.data?.templateIdx || 0]"
                                 [ngTemplateOutletContext]="{$implicit: {field}}"></ng-template>
                    <app-custom-form-heading-text-field *ngSwitchCase="FieldType.HeadingText"
                                                        class="custom-form-field"
                                                        [parent]="this"
                                                        [field]="field"
                                                        [data]="field.data"></app-custom-form-heading-text-field>
                </div>
            </div>
            }
        </div>
    </div>

</div>