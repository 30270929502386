import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency } from '@applogic/model';
import { AccountService } from 'src/app/account/account.service';
import { ProductService } from 'src/app/services/product.service';
import { getCurrencySymbol } from '@angular/common';

@Component({
    selector: 'app-currency-select',
    templateUrl: './currency-select.component.html',
    styleUrls: ['./currency-select.component.scss']
})
export class CurrencySelectComponent implements OnInit {

    @Input()
    style: string;

    @Input()
    showHeader: boolean = false;

    _currency: Currency;

    currencies: Currency[] = [];

    currencyCode: string;

	@Input()
    set currency(currency: Currency) {
        if(this._currency != currency) {
            this._currency = currency;
            if(currency) this.currencyCode = currency.code;
            this.currencyChange.emit(currency);
        }
    }

    get currency() {
      return this._currency;
    }

    @Output()
    currencyChange: EventEmitter<Currency> = new EventEmitter<Currency>();

    
    constructor(private productService: ProductService, private accountService: AccountService) { }

    ngOnInit(): void {
        this.productService.getCurrencies().subscribe((response) => {
            this.currencies = response.result;
            this.getDefaultCurrency().then((currency) => {
                this.currency = currency;
            })
        });
    }

    async getDefaultCurrency() {
        if(this._currency) {
            const idx = this.currencies.findIndex(a => a.code == this._currency.code);
            if(idx != -1) {
                return this.currencies[idx];
            }
        }
        else {
            let idx: number = -1;

            // User the user preferred currency.
            let userCurrency = await this.accountService.getCurrency();
            if(userCurrency) {
                idx = this.currencies.findIndex(a => a.code == userCurrency);
            }

            // Was asked to not set currency based on the domain: see AEP-1094
            // Use the domain name related currency.
            // if(idx == -1) {
            //     if(window.location.hostname.endsWith(".com")) {
            //         idx = this.currencies.findIndex(a => a.code == "USD");
            //     }
            //     else if(window.location.hostname.endsWith(".ca")) {
            //         idx = this.currencies.findIndex(a => a.code == "CAD");
            //     }
            //     else {
            //         idx = -1;
            //     }
            // }

            // Use the global default currency.
            if(idx == -1) {
                idx = this.currencies.findIndex(a => a.code == "CAD");
            }

            if(idx != -1) {
                return this.currencies[idx];
            }

            // Use the first enabled currency.
            if(this.currencies.length > 0) {
                return this.currencies[0];
            }
        }

        return undefined;
    }

    setCurrency(currency: Currency, event?: any) {
        this.currency = currency;
        this.currencyCode = currency?.code;
        this.accountService.setCurrency(currency.code);
    }

    getCurrencySymbol(code: string) {
        return getCurrencySymbol(code, 'narrow');
    }
}
