import { Model, Member } from "@uon/model";
import { QuotationOrganization } from "./quotation.model";

@Model()
export class OrganizationBillingForm {

    @Member()
    fullName: string;

    @Member()
    email: string;

    @Member()
    org: QuotationOrganization;

    @Member()
    orgId: string;
}
