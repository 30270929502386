import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Address } from '@applogic/model';
import { AppDialogRef } from 'src/app/services/app-dialog-ref';
import { AppDialogConfig, DialogService } from 'src/app/services/dialog.service';
import { LoadingService } from 'src/app/services/loading.interceptor';

interface AddressDialogData {
     type: 'billing',
     address: Address,
     callback?: any
}

@Component({
     selector: 'app-address-dialog',
     templateUrl: './address-dialog.component.html',
     styleUrl: './address-dialog.component.scss'
})
export class AddressDialogComponent {

     title: string;

     errorObject: any;

     constructor(
          @Inject(MAT_DIALOG_DATA) protected data: any,
          private mdDialogRef: MatDialogRef<AddressDialogData>,
          protected loading: LoadingService
     ) {
          if(data.type == 'billing') {
               this.title = $localize`:@@common-billing-address:Billing Address`;
          }
     }

     public static createDialog(dialogService: DialogService, dialog: MatDialog, data: AddressDialogData, config: AppDialogConfig = {}) {
          return new AppDialogRef<AddressDialogComponent, AddressDialogData, Address>(dialogService, dialog, AddressDialogComponent, {
               data,
               maxWidth: "600px"
          }, config);
     }

     save(data: Address) {
          if(this.data.callback) {
               this.triggerCallback(data);
          }
          else {
               this.mdDialogRef.close(data);
          }
     }

     async triggerCallback(data: Address) {
          try {
               await this.data.callback(data);
               this.mdDialogRef.close(data);
          }
          catch(ex) {
               this.errorObject = ex;
          }
     }
}
