import { Component, Input } from '@angular/core';
import { SubscriptionAllocationMode } from '@applogic/model';
import { ApplogicUtils } from 'src/app/services/applogic-utils';

@Component({
    selector: 'app-subscription-mode-chip',
    templateUrl: './subscription-mode-chip.component.html',
    styleUrl: './subscription-mode-chip.component.scss'
})
export class SubscriptionModeChipComponent {
    ApplogicUtils = ApplogicUtils;

    @Input()
    mode: SubscriptionAllocationMode;

    @Input()
    showColor: boolean = true;
    
    getClasses() {
        return 'chip ' + 'subscription-mode-' + this.mode + "-chip";
    }
}
