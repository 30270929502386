import { MessageBoxResult } from "./messagebox-result";

export class MessageBoxButton {

    constructor(result: MessageBoxResult, label: string = "", color?: string, type?: string) {
        if(!label) {
            if(result == MessageBoxResult.Cancel) {
                label = $localize`:@@common-cancel: Cancel `;
            }
        }

        this.result = result;
        this.label = label;
        this.color = color;
        this.type = type;
    }

    result: MessageBoxResult;
    label: string;
    color: string;
    type: string; // flat, stroke
}