import { Component, HostBinding, Injector, Input } from '@angular/core';
import { ContainerScalerComponent } from '../container-scaler/container-scaler.component';
import { MatCardAppearance } from '@angular/material/card';


export enum CardDesignType {
    None = 'none',
    Red = 'red',
    Blue = 'blue',
    Green = 'green'
}

@Component({
    selector: 'app-design-card',
    templateUrl: './design-card.component.html',
    styleUrl: './design-card.component.scss'
})
export class DesignCardComponent extends ContainerScalerComponent {

    @Input()
    appearance: MatCardAppearance = 'outlined';

    @Input()
    clickable: boolean = true;

    @Input()
    type: CardDesignType = CardDesignType.None;

    @Input()
    bgImageUrl: string;

    @HostBinding('class') get getHostClass() {
        let classes: string[] = [];
        if (!this.clickable) {
            classes.push('not-clickable-card');
        }
        else {
            classes.push('clickable-card');
        }

        classes.push("card-design-" + this.type);

        return classes.join(" ");
    }

    // Méthode pour déterminer les classes à appliquer
    getClass() {
        return {
            ['card-design-' + this.type]: true
        };
    }

    getStyles() {
        const result = super.getStyles();
        result['background-image'] = this.bgImageUrl ? "url('" + this.bgImageUrl + "')" : "none";
        return result;
    }

    constructor(injector: Injector) {

        super(injector);

    }
}
