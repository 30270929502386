import { Component, Input } from '@angular/core';
import { CommonListColumn } from '../../common-list-column';
import { CardRowData } from '../../display-mode/common-list-card-display-mode/common-list-card-display-mode.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-common-list-card-element-renderer',
    templateUrl: './common-list-card-element-renderer.component.html',
    styleUrl: './common-list-card-element-renderer.component.scss'
})
export class CommonListCardElementRendererComponent<T> {
    
    _cardData: CardRowData<T>;

    private _listSubscriptions: Subscription[] = [];

    @Input()
    set cardData(data: CardRowData<T>) {
        this._cardData = data;

        const list = data.list;
        for(const s of this._listSubscriptions) {
            s.unsubscribe();
        }
        this._listSubscriptions = [];

        if(list) {
            this._listSubscriptions.push(list.onColumnsUpdate.subscribe((columns) => {
                this.updateColumn();
            }));
        }

        this.updateColumn();
    }

    _columnKey: string;

    @Input()
    set columnKey(t: string) {
        this._columnKey = t;
        this.updateColumn();
    }

    @Input()
    alwaysVisible: boolean = true;

    _column: CommonListColumn;

    updateColumn() {
        if(this._cardData?.list && this._columnKey) {
            this._column = this._cardData.list.columns.find(a => a.columnKey == this._columnKey);
        }
        
        if(!this._column) {
            this._column = {} as any;
        }
    }
}