<app-common-list-renderer [list]="list">
    <renderer-templates [templates]="[cellTemplate]"
                        [templateColumns]="[]"></renderer-templates>
</app-common-list-renderer>


<ng-template #cellTemplate
             let-data>
    <div class="progress-table-activity-cell">
        <div *ngFor="let chip of MMOGameProgressUtils.getActivityChips(data.row.games, data.column, selectedSeason);let j=index;"
             (mouseenter)="setCurrentChip(chip)"
             (click)="setCurrentChip(chip)">
            <div class="level-stages"
                 [style.borderColor]="chip.color_code"
                 [ngStyle]="{'background-color':chip.isPlay ? chip.color_code : '#ffffff' }"
                 [ngClass]="{'win-true': chip.isPlay === true} "
                 [ngbPopover]="popOver"
                 popoverClass="app-popover--plain-tooltip"
                 (mousewheel)="p.close(false)"
                 #p="ngbPopover">
                <span class="show-number"
                      [ngClass]="{'cup-chip': chip.subtype == 'cup'}">{{chip.label}}</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #popOver>
    <app-mmo-tooltip context='activity'
                     [chip]="currentChip"></app-mmo-tooltip>
</ng-template>