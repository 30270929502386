<app-dialog icon="people"
            [errorObject]="errorObject">
    <app-dialog-title i18n="@@transfer-seats-dialog-title">Assign/Remove tokens</app-dialog-title>
    <app-dialog-content>
        <div *ngIf="showUserSelection">
            <app-users-selection [orgId]="subscription.organization?.id"
                                 (onUserChange)="onUserChange($event)"
                                 [singleUserSelect]="true"></app-users-selection>
        </div>

        <div class="header1"
             *ngIf="user && !showUserSelection"
             i18n="@@transfer-seats-dialog-to-member">
            To {{user.fullName}}
        </div>

        <hr *ngIf="subscription && target"
            class="topline">

        <div *ngIf="subscription && target">
            <app-subscription-validity-label [subscription]="subscription"
                                             displayMode="tokens">
            </app-subscription-validity-label>
        </div>

        <div *ngIf="target">
            <div class="product-line"
                 *ngFor="let product of products">
                <app-seat-token [productCode]="product.shortCode.toLowerCase()"
                                [displayType]="'icon-only'"></app-seat-token>
                <div class="product-line-content">
                    <div class="product-name">{{ productService.getGameName(product.shortCode) }}</div>
                    <div class="product-line-content-attribution-line">
                        <div class="product-line-send-before"
                             i18n="@@transfer-seats-dialog-send-line-prefix"> Send </div>
                        <app-quantity-input class="quantity-input"
                                            (onQuantityChanged)="onQuantityChanged($event)"
                                            [quantity]="quantity"
                                            [counterKey]="product.shortCode.toLowerCase()"
                                            [min]="target.freeSeats"
                                            [max]="source.freeSeats"
                                            [style]="'flat'">
                        </app-quantity-input>
                        <div class="product-line-send-after"
                             i18n="@@transfer-seats-dialog-send-line-suffix"> tokens out of
                            {{source.freeSeats[product.shortCode.toLowerCase()]}} available</div>
                    </div>
                </div>
            </div>
        </div>
    </app-dialog-content>
    <button mat-stroked-button
            matDialogClose
            appDialogAction
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            [disabled]="!user || !wasQuantitySet"
            color="accent"
            (click)="sendSeats()">
        <span i18n="@@transfer-seats-dialog-submit-button">Submit</span>
    </button>
</app-dialog>