import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserEmailPreferences } from '@applogic/model';
import { Model } from '@uon/model';

@Component({
    selector: 'app-user-email-prefs',
    templateUrl: './user-email-prefs.component.html',
    styleUrls: ['./user-email-prefs.component.scss']
})
export class UserEmailPrefsComponent implements OnInit {

    @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
    @Input() showTitle: boolean = true;
    @Output() onFormChangedEvent: EventEmitter<any> = new EventEmitter();
    @Input() emailPreferences: UserEmailPreferences;
    
    isDisabled: boolean = true;

    useCheckbox: boolean = false;

    constructor() { }

    ngOnInit(): void {
        if(!this.emailPreferences) {
            this.emailPreferences = Model.New(UserEmailPreferences, {});
            this.emailPreferences.setAll();
        }
        else {
            this.emailPreferences.setUndefinedToDefault();
        }
        
        this.formGroup.addControl('opt', new UntypedFormControl(this.emailPreferences.opt, []));
        this.formGroup.addControl('newsletters', new UntypedFormControl(this.emailPreferences.newsletters, []));
        this.formGroup.addControl('gettingStarted', new UntypedFormControl(this.emailPreferences.gettingStarted, []));

        this.updateControlStates();
    }

    updateControlStates() {
        if(this.formGroup.controls.opt.value) {
            this.formGroup.controls.newsletters.enable();
            this.formGroup.controls.gettingStarted.enable(); 
        }
        else {
            this.formGroup.controls.newsletters.disable();
            this.formGroup.controls.gettingStarted.disable();    
        }
    }

    onFormChanged(): void {
        this.updateControlStates();
        this.onFormChangedEvent.emit({});
    }

}
