import { Model, Member, ArrayMember } from "@uon/model";
import { AppRemoteDebugMessage, AppRemoteDebugSession } from "./app-remote-debug.model";


@Model()
export class AppRemoteDebugSessionCreateQuery {


    @Member()
    productCode: string;

    @Member()
    installId: string;

    @Member()
    context: any;

}


@Model()
export class AppRemoteDebugSessionCreateResponse {

    @Member()
    sessionId: string;

    @Member()
    lastUpdate: number;
}

@Model()
export class AppRemoteDebugSessionListResponse {

    @ArrayMember(AppRemoteDebugSession)
    sessions: AppRemoteDebugSession[];

}

@Model()
export class AppRemoteDebugMessageForm {

    @Member()
    key: string;

    @Member()
    data: any;
}

@Model()
export class AppRemoteDebugSessionSyncQuery {

    @ArrayMember(AppRemoteDebugMessageForm)
    messages: AppRemoteDebugMessageForm[];

    @Member()
    lastUpdate: number;

}


@Model()
export class AppRemoteDebugSessionSyncResponse {

    @ArrayMember(AppRemoteDebugMessage)
    messages: AppRemoteDebugMessage[];

    @Member()
    lastUpdate: number;

}