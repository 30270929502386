import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApiDirectoryService } from './api-directory.service';
import { ApiRoutes, RoutesServer } from '@applogic/model';
import { LanguageService } from './language.service';

export interface ToolTipinfo {
    content?: string;
    key?: string;
    id?: string;
    section?: string;
}

@Injectable({
    providedIn: 'root'
})
export class KnowledgeBaseService {

    @Output() updateGeneralExplanation: EventEmitter<any> = new EventEmitter();
    constructor(
        private languageService: LanguageService,
        private dirService: ApiDirectoryService
    ) { }

    getTooltipData(sectionName: string) {
        const LOCALE = this.languageService.currentLanguageCode;
        return this.dirService.serverGet(RoutesServer.Api, ApiRoutes.Knowledgebase, `/tooltip/${sectionName}?lang=${LOCALE}`, { withCredentials: true });
    }

    getToolTipMessage(list: any[], key) {
        if (!list || !list.length) {
            return null;
        }

        let tooltip_message = null;
        list.filter((item: ToolTipinfo) => {
            if (item.key === key) {
                tooltip_message = item.content;
            }
        });

        return tooltip_message;
    }

    addGeneralExplation(data) {
        this.updateGeneralExplanation.emit(data);
    }

}
