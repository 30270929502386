
// Here is IPA to X-SAMPA conversion table.
// Doesn't have all X-SAMPA symbols.
// There is a table in wikipedia: https://en.wikipedia.org/wiki/X-SAMPA
// The stucture is:
//  {IPA Symbol}, {X-Sampa}, {Conversion Direction}, {Type of character}
// {Conversion Direction}:
//    0: The conversion is disabled.
//    1: Convert IPA to X-Sampa only.
//    2: Convert X-Sampa to IPA only.
//    3: Convert X-Sampa and IPA in both direction.
// {Type of character}:
//    1: Letters
//    2: Symbols
//    3: Diacritics
// TODO: v\ and P have the same. Must modify the regex to find both.
// Note: Since change was previously made without trace for the reason, I have
//       correct the table and put any different with the previous version in comment
//       with the prefix "Previous:"
//       Also the 'ɑ̃' should be converted to 'A~' and not 'A_~' otherwise we will need a converter that return all //       possibles values as 'A_~' and 'A~' is valid and represent both the character 'ɑ̃'.
//       But still 'A_~' can be converted to 'ɑ̃'.
type IPASampa = [string, string, number, number];

const equiv: IPASampa[] = [
    // Lower case symbols
    ['ɓ', 'b_<', 3, 1],
    ['ɖ', 'd`',  3, 1],
    ['ɗ', 'd_<', 3, 1],
    ['ɠ', 'g_<', 3, 1],
    ['ɡ', 'g',   3, 1], // Previous: 'g', 'g'
    ['ɦ', 'h\\', 3, 1],
    ['ʝ', 'j\\', 3, 1], // Previous: ipa symbol ɟ and ʝ
    ['ɺ', 'l\\', 3, 1],
    ['ɭ', 'l`',  3, 1],
    ['ɳ', 'n`',  3, 1],
    ['ɸ', 'p\\', 3, 1],
    ['ɽ', 'r`',  3, 1],
    ['ɹ', 'r\\', 3, 1],
    ['ɻ', 'r\\`',3, 1],
    ['ʂ', 's`',  3, 1],
    ['ɕ', 's\\', 3, 1],
    ['ʈ', 't`',  3, 1],
    ['ʋ', 'v\\', 3, 1], // Previous: Added
    ['ɧ', 'x\\', 3, 1],
    ['ʐ', 'z`',  3, 1], // Previous: sampa s`
    ['ʑ', 'z\\', 3, 1],


    // Capital symbols
    ['ɑ', 'A',   3, 1],
    ['β', 'B',   3, 1],
    ['ʙ', 'B\\', 3, 1],
    ['ç', 'C',   3, 1],
    ['ð', 'D',   3, 1],
    ['ɛ', 'E',   3, 1],
    ['ɱ', 'F',   3, 1],
    ['ɣ', 'G',   3, 1], // Previous: 'ŋ'/*'ɣ'*/
    ['ɢ', 'G\\', 3, 1],
    ['ʛ', 'G\_<',3, 1],
    ['ɥ', 'H',   3, 1],
    ['ʜ', 'H\\', 3, 1],
    ['ɪ', 'I',   3, 1],
    ['ᵻ', 'I\\', 3, 1],
    ['ɲ', 'J',   3, 1], // Previous: Added
    ['ɟ', 'J\\', 3, 1], // Previous: Added
    ['ʄ', 'J\_<',3, 1], // Previous: 'ʄ', 'j\_<'
    ['ɬ', 'K',   3, 1],
    ['ɮ', 'K\\', 3, 1],
    ['ʎ', 'L',   3, 1],
    ['ʟ', 'L\\', 3, 1],
    ['ɯ', 'M',   3, 1],
    ['ɰ', 'M\\', 3, 1],
    ['ŋ', 'N',   3, 1], // Previous: IPA symbol ɲ
    ['ɴ', 'N\\', 3, 1],
    ['ɔ', 'O',   3, 1],
    ['ʘ', 'O\\', 3, 1],
    ['ʋ', 'P',   3, 1], // Previous: Added
    ['ɒ', 'Q',   3, 1],
    ['ʁ', 'R',   3, 1],
    ['ʀ', 'R\\', 3, 1], // Previous: 'ʀ', 'R'
    ['ʃ', 'S',   3, 1],
    ['θ', 'T',   3, 1],
    ['ʊ', 'U',   3, 1],
    ['ᵿ', 'U\\', 3, 1], // Previous: Added
    ['ʌ', 'V',   3, 1],
    ['ʍ', 'W',   3, 1],
    ['χ', 'X',   3, 1],
    ['ħ', 'X\\', 3, 1],
    ['ʏ', 'Y',   3, 1],
    ['ʒ', 'Z',   3, 1],

    
    // Other symbols
    ['.', '.',  3, 2], // Previous: 'ˑ', '.',
    ['ˈ', '"',  3, 2],
    ['ˌ', '%',  3, 2],
    ['ʲ', '_j', 3, 2],
    ['ʲ', '\'', 3, 2], // Previous: Added
    ['ː', ':',  3, 2],
    ['ˑ', ':\\',3, 2],
    // We want to keep the '-' here.
    ['-', '',   0, 2], // Previous:Added
    ['ə', '@',  3, 2], // Previous: 'ə', '°',
    ['ɘ', '@\\',3, 2],
    ['ɚ', '@`', 3, 2],
    ['æ', '{',  3, 2],
    ['ʉ', '}',  3, 2],
    ['ɨ', '1',  3, 2],
    ['ø', '2',  3, 2],
    ['ɜ', '3',  3, 2],
    ['ɞ', '3\\',3, 2],
    ['ɾ', '4',  3, 2],
    ['ɫ', '5',  3, 2], // Previous:Added
    ['ɐ', '6',  3, 2],
    ['ɤ', '7',  3, 2],
    ['ɵ', '8',  3, 2], // Previous: 'ɥ', '8'
    ['œ', '9',  3, 2],
    ['ɶ', '&',  3, 2],
    ['ʔ', '?',  3, 2],
    ['ʕ', '?\\',3, 2],
    ['ʢ', '<\\',3, 2],
    ['ʡ', '>\\',3, 2],
    ['ꜛ', '^',  3, 2], // Previous: Added
    ['ꜜ', '!',  3, 2], // Previous: Added
    ['ǃ', '!\\',3, 2],
    ['|', '|',  3, 2],  // Previous: Added
    ['ǀ', '|\\',3, 2],
    ['‖', '||', 3, 2],     // Previous: Added
    ['ǁ', '|\\|\\', 3, 2], // Previous: Added
    ['ǂ', '=\\',3, 2],
    ['‿', '-\\',3, 2], // Previous: Added


    // Diacritics
    // Note: Add the 'e' before some accent to see it correctly.
    [' ̈', '_"', 3, 3],
    ['̟', '_+',  3, 3],
    ['̠', '_-',  3, 3],
    ['ˇ', '_/', 3, 3],
    ['̥', '_0',  3, 3],
    ['̩', '=',   3, 3],
    ['̩', '_=',  3, 3],
    ['ʼ', '_>', 3, 3],
    ['ˤ', '_?\\',3,3],
    ['ˆ', '_\\',3, 3],
    ['̯', '_^',  3, 3],
    ['̚', '_}',  3, 3],
    // Skip '`' since the symbol is not copiable.
    ['̃', '~',   3, 3],
    ['̃', '_~',  2, 3],
    ['̘', '_A',  3, 3],
    ['̺', '_a',  3, 3],
    ['̏', '_B',  3, 3],
    ['᷅', '_B_L',3, 3],
    ['̜', '_c',  3, 3],
    ['̪', '_d',  3, 3],
    ['̴', '_e',  3, 3],
    ['↘', '<F>',3, 3],
    ['̂', '_F',  3, 3],
    ['ˠ', '_G', 3, 3],
    ['́', '_H',  3, 3],
    ['᷄', '_H_T',3, 3],
    ['ʰ', '_h', 3, 3],
    // 'ʲ', '_j', already added in the others symbols section.
    // ʲ', '\'', already added in the others symbols section.
    ['̰', '_k',  3, 3],
    ['̀', '_L',  3, 3],
    ['ˡ', '_l', 3, 3],
    ['̄', '_M',  3, 3],
    ['̻', '_m',  3, 3],
    ['̼', '_N',  3, 3],
    ['ⁿ', '_n', 3, 3],
    ['̹', '_O',  3, 3],
    ['̞', '_o',  3, 3],
    ['̙', '_q',  3, 3],
    ['↗', '<R>',3, 3], // Previous: Added
    ['̌', '_R',  3, 3],
    ['᷈', '_R_F',3, 3],
    ['̝', '_r',  3, 3],
    ['̋', '_T',  3, 3],
    ['̤', '_t',  3, 3],
    ['̬', '_v',  3, 3],
    ['ʷ', '_w', 3, 3],
    ['̆', '_X',  3, 3],
    ['̽', '_x',  3, 3],

    // '\ɛ\̃', '5', // Previous: Removed
    // '\ɔ\̃', '§', // Previous: Removed
    // '\ɑ\̃', '@', // Previous: Removed
    // '\œ\̃', '1', // Previous: Removed
    
];

const equivForRegEx: IPASampa[] = new Array();

// Because in a search we need to exclude any ending accents. See the bug AEP-373 in Jira.
let diacriticsXSampaRegexExclude : string = "";

// Sort pair by priority.
// Ex.: With xsampa: "B\\" and "B", "B\\" must be evaluated first because it contains "B" inside.
let changes: number = 1;
let maxIter: number = 5;
while( (maxIter > 0) && (changes > 0) ) {
    maxIter--;
    changes = 0;
    for (var i = 0; i < equiv.length; i++) {
        let c1: IPASampa = equiv[i];
        let v1: string = c1[1];
        
        for (var j = 0; j < equiv.length; j++) {
            let c2: IPASampa = equiv[j];
            let v2: string = c2[1];

            // If v1 inside v2, v2 must be first.
            if(v2.indexOf(v1) && (v1 != v2))
            {
                let swap: IPASampa = equiv[i];

                equiv[i] = equiv[j];

                equiv[j] = swap;

                changes++;
            }
        }
    }
}

// Replace strings for the regex replace.
for (var i = 0; i < equiv.length; i++) {
    let c: IPASampa = equiv[i];
    let newc: IPASampa = [c[0], c[1], c[2], c[3]];

    for (var j = 0; j < 2; j++) {
        let val: string = j==0 ? newc[0] : newc[1];
        val = val.replace(/\\/g, '\\\\');
        val = val.replace(/\?/g, '\\?');
        val = val.replace(/\|/g, '\\|');
        val = val.replace(/\+/g, '\\+');
        val = val.replace(/\-/g, '\\-');
        val = val.replace(/\{/g, '\\{');
        val = val.replace(/\}/g, '\\}');
        val = val.replace(/\=/g, '\\=');
        val = val.replace(/\</g, '\\<');
        val = val.replace(/\>/g, '\\>');
        val = val.replace(/\./g, '\\.');
        val = val.replace(/\,/g, '\\,');
        val = val.replace(/\:/g, '\\:');
        val = val.replace(/\;/g, '\\;');
        val = val.replace(/\^/g, '\\^');
        newc[j] = val;
    }

    if(c[3] == 3) {
        if(diacriticsXSampaRegexExclude.length > 0) diacriticsXSampaRegexExclude += '|';
        diacriticsXSampaRegexExclude += newc[1];
    }

    equivForRegEx.push(newc);
}

diacriticsXSampaRegexExclude = "(?!" + diacriticsXSampaRegexExclude + ")";

export class StringUtils {

    
    static Sampa2Ipa(tin: string) {
        let res = tin;

        for (let i = 0; i < equivForRegEx.length; i++) {
            let c: IPASampa = equivForRegEx[i];
            if(c[2] & 2) {
                let reg = new RegExp(c[1], 'g');
                res = res.replace(reg, equiv[i][0]);
            }
        }

        return res;
    }

    static Ipa2Sampa(tin: string) {
        let res = tin;

        for (var i=0; i<equivForRegEx.length; i++) {
            let c: IPASampa = equivForRegEx[i];
            if(c[2] & 1) {
			    let reg = new RegExp (c[0], 'g');
                res = res.replace(reg, equiv[i][1]);
            }
        }

		return res;
    }

    static Sampa2IpaAssociation(tin: string) {
        let res = tin;

        let splitLvl1 = tin.split(".");


        for (let i = 0; i < splitLvl1.length; i++) {
            let splitLvl2 = splitLvl1[i].split("-");

            if(splitLvl2.length > 1) {
                splitLvl2[1] = StringUtils.Sampa2Ipa(splitLvl2[1]);
                splitLvl1[i] = splitLvl2.join("-");
            }
        }

        res = splitLvl1.join(".");

        return res;
    }

    static Ipa2SampaAssociation(tin: string) {
        let res = tin;

        let splitLvl1 = tin.split(".");


        for (let i = 0; i < splitLvl1.length; i++) {
            let splitLvl2 = splitLvl1[i].split("-");

            if(splitLvl2.length > 1) {
                
                splitLvl2[1] = StringUtils.Ipa2Sampa(splitLvl2[1]);
                splitLvl1[i] = splitLvl2.join("-");
            }
        }

        res = splitLvl1.join(".");

        return res;
    }

    static Base64Encode(str: string) {

        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode(Number('0x' + p1));
            }));
    }

    static Base64Decode(str: string) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    static getDiacriticsXSampaRegexExcludes(): string {
        return diacriticsXSampaRegexExclude;
    }

    static SlugToSnakeCase(slug: string): string {
        return slug.replace(/-/g, '_');
    }

    static QueryObjectValue(keys: string|string[], data: any) {
        
        if(!Array.isArray(keys)) {
            keys = keys.split('.');
        }

        for (let i = 0; i < keys.length; i++) {
            if(data) {
                const key = keys[i];
                data = data[key];
            } else {
                break;
            }
        }

        return data;
    }

    static ParseVariables(str: string, data: any, parseCallback: (key: string) => string = undefined) {
        let output: string = "";
        let levelBracket: number = 0;
        let strInsideBracket: string = "";

        for(let i=0; i<str.length; i++) {
            const c = str.charAt(i);

            if(c == '{') {
                levelBracket += 1;

                if(levelBracket > 1) {
                    strInsideBracket += c;
                }
            }
            else if(c == '}') {
                levelBracket -= 1;

                if(levelBracket > 0) {
                    strInsideBracket += c;
                }
                else {
                    let v: string;

                    if(parseCallback) {
                        v = parseCallback(strInsideBracket);
                    }

                    if(v === undefined) {
                        v = StringUtils.QueryObjectValue(strInsideBracket, data);
                    }

                    if(v !== undefined) {
                        output += v;
                    }
                    else {
                        output += "{" + strInsideBracket + "}";
                    }
                    strInsideBracket = "";
                }
            }
            else if(levelBracket > 0) {
                strInsideBracket += c;
            }
            else {
                output += c;
            }
        }

        return output;
    }
    static async ParseVariablesAsync(str: string, data: any, parseCallback: (key: string) => Promise<string> = undefined, options?: {opening: string, ending: string, includeBackets?: boolean}) {
        if(!options) {
            options = {opening: "{", ending: "}"};
        }

        let output: string = "";
        let levelBracket: number = 0;
        let strInsideBracket: string = "";
        let insideBracket: boolean = false;

        const opening = options.opening;
        const ending = options.ending;
        const openingLen = opening.length;
        const endingLen = ending.length;
        const maxLen = openingLen > endingLen ? openingLen : endingLen;

        for(let i=0; i<str.length; i++) {
            let c: string;
            let next: string;
            c = str.charAt(i);

            if(maxLen == 1) {    
                next = c;
            }
            else {
                next = str.substring(i, i+maxLen);
            }   

            if(next.startsWith(opening)) {
                levelBracket += 1;
                if(openingLen > 1) {
                    i += openingLen - 1;
                }

                if(levelBracket == 1) {
                    insideBracket = true;
                }
                else if(levelBracket > 1) {
                    strInsideBracket += next;
                }
            }
            else if(insideBracket && next.startsWith(ending)) {
                levelBracket -= 1;
                if(endingLen > 1) {
                    i += endingLen - 1;
                }

                if(levelBracket > 0) {
                    strInsideBracket += next;
                }
                else if (insideBracket && levelBracket === 0) {
                    insideBracket = false;

                    let v: string;

                    if(parseCallback) {
                        v = await parseCallback(strInsideBracket);
                    }

                    if(v === undefined) {
                        v = StringUtils.QueryObjectValue(strInsideBracket, data);
                    }

                    if(v !== undefined) {
                        if(options.includeBackets) {
                            output += opening + v + ending;
                        }
                        else {
                            output += v;
                        }
                    }
                    else {
                        output += opening + strInsideBracket + ending;
                    }
                    strInsideBracket = "";
                }
            }
            else if(insideBracket && (levelBracket > 0)) {
                strInsideBracket += c;
            }
            else {
                output += c;
            }
        }

        return output;
    }
};