
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Type, Unpack, Include, PropertyNamesNotOfType } from "@uon/core";
import {
	ArrayMember,
	FindModelAnnotation,
	GetModelMembers,
	JsonSerializer, MaxLength,
	Member, MinLength, Prohibited,
	Required, ValidateEmail, ValidateMongoId, ValidatePattern, ValidatePhone,
	ValidationFailure,
	ValidationResult,
	Validator as UonValidator
} from "@uon/model";
import { Injector } from "@uon/core";
export interface Validator {
    (model: any, key: string, val: any, injector?: Injector): any;
    _forceValidation?: boolean;
    _fieldValidators?: {
        [k: string]: Validator[];
    };
}

// import { LocaleString, Length, LengthRange, Weight, Money, TimeDurationRange, TimeDuration, SupportedLanguages, Locale } from "public-schemas";

export declare type IncludeIf<M, T, U, N> = M extends T ? U : N;

// typing magic
export type FormArrayFieldTypes<T> =
    IncludeIf<T, /* Length | LengthRange | TimeDuration | TimeDurationRange | Weight | Money | */ number | string | Date | boolean,
        UntypedFormControl,
        Include<T, object, T extends { id: string } ? UntypedFormControl : UntypedFormGroup>
    >;

export type FormGroupFieldTypes<T, M = Pick<T, PropertyNamesNotOfType<T, Function>>> = {
    [K in keyof M]:
    IncludeIf<M[K], any[],
        UntypedFormArray,
        IncludeIf<M[K], number | string | Date | boolean,
            UntypedFormControl,
            Include<M[K], object, M[K] extends { id: string } ? UntypedFormControl : UntypedFormGroup>
        >
    >
}

export type FormArrayFor<T> = UntypedFormArray;
export type FormGroupFor<T> = UntypedFormGroup;

export type FormArrayElementFactory<T, FE> = (value?: T) => FE;

export interface FormArrayDef<FE extends AbstractControl> {
    formArray: UntypedFormArray;
    factory: FormArrayElementFactory<FE['value'], FE>;
}

export type FormArrayDefFor<T> = FormArrayDef<FormArrayFieldTypes<T>>;


export interface FormGroupModelDefOptions {
    /**
     * An existing formgroup to use
     */
    formGroup?: UntypedFormGroup;

    /**
     * If set, only keeps validators on the provided languages on LocaleString
     */
    filterLocales?: string[];
};


interface FormGroupModelDefGenOptions {
    extraFieldValidators?: { [k: string]: UonValidator[] }
}


const UNIT_TYPES: Type<any>[] = [];

export class FormGroupModelDef<T> {

    private _serializer: JsonSerializer<T> = new JsonSerializer(this._type);
    private _arrayElementFactoryMap: Map<UntypedFormArray, FormArrayElementFactory<any, any>> = new Map();

    private _formGroup: UntypedFormGroup = this._generateFormGroup(this._type, this._options);

    get formGroup() {
        return this._formGroup;
    }

    constructor(private _type: Type<T>, private _options: FormGroupModelDefOptions = {}) {
        this._serializer = new JsonSerializer(_type);
    }

    get value() {
        return this._serializer.deserialize(this._formGroup.value, true);
    }

    get rawvalue() {
        return this._serializer.deserialize(this._formGroup.getRawValue(), true);
    }


    reset(value?: { [K in keyof T]?: any }) {
        this._formGroup.reset();

        // reset doesnt clear form arrays, we want to do that.
        for (let [fa, f] of this._arrayElementFactoryMap) {
            fa.clear();
        }

        if (value) {
            this._patchGroupValue(this._formGroup, value);
        }

        this._formGroup.markAsPristine();
    }

    patchValue(value: { [K in keyof T]?: any },
			   markAsDirty: boolean = false,
			   emitEvent: boolean = true) {
        this._patchGroupValue(this._formGroup, value, markAsDirty, emitEvent);
    }

    getFormArrayDef<FE extends AbstractControl>(formArray: UntypedFormArray): FormArrayDef<FE> {
        if (!this._arrayElementFactoryMap.has(formArray)) {
            throw new Error('Form Array not part of the form');
        }

        const factory = this._arrayElementFactoryMap.get(formArray)!;


        return {
            formArray,
            factory
        }
    }

    replaceArrayFactoryFor<FE extends AbstractControl>(formArray: UntypedFormArray, factory: FormArrayElementFactory<any, any>) {
        this._arrayElementFactoryMap.set(formArray, factory);
    }


    private _patchGroupValue(group: UntypedFormGroup, value: { [k: string]: any },
							 markAsDirty: boolean = false,
							 emitEvent: boolean = true) {
        for (let k in value) {

            if (group.controls[k]) {
                let control = group.controls[k];
                if (control instanceof UntypedFormControl) {
                    control.setValue(value[k], {emitEvent: emitEvent});
                }
                else if (control instanceof UntypedFormGroup) {
                    this._patchGroupValue(control, value[k], markAsDirty, emitEvent)
                }
                else if (control instanceof UntypedFormArray) {
                    // remove all controls
                    control.clear();

                    let arr_value = value[k]
                    if (!Array.isArray(arr_value)) {
                        arr_value = [];
                    }

                    for (let v of arr_value) {
                        let factory = this._arrayElementFactoryMap.get(control)!;
                        let arr_control = factory(v);
                        control.push(arr_control);
                    }

                }

                if (markAsDirty) {
                    control.markAsDirty();
                }
            }

        }
    }



    private _generateFormGroup<S, SR extends { [K in keyof S]: AbstractControl }>(
        type: Type<S>,
        options: FormGroupModelDefGenOptions & FormGroupModelDefOptions = {}
    ) {

        let form_group = options.formGroup || new UntypedFormGroup(<SR>{});
        options.formGroup = null!;

        const model = FindModelAnnotation(type);
        const members = GetModelMembers(model);

        for (let member of members) {

            let key = member.key as string & keyof SR;

            if (member instanceof ArrayMember) {
                let existing = form_group.get(key) as UntypedFormArray;

                let control = existing || new UntypedFormArray([], {
                    validators: member.validators?.map(v => WrapValidator(member.key, v)) || []
                });

                form_group.addControl(key, control, { emitEvent: false });

                // we have to wrap field in factory
                let factory = (value: any) => {
                    let sub_control = this._generateMemberControl(member, options);

                    if (sub_control instanceof UntypedFormGroup) {
                        this._patchGroupValue(sub_control, value)
                    }
                    else {
                        sub_control.setValue(value);
                    }

                    return sub_control;
                };

                this._arrayElementFactoryMap.set(control, factory);

            }
            else {
                let control = this._generateMemberControl(member, options);
                form_group.addControl(key, control, { emitEvent: false });
            }
        }

        return form_group;

    }


    private _generateMemberControl(member: Member, options: FormGroupModelDefGenOptions & FormGroupModelDefOptions = {}) {

        const existing = options.formGroup?.get(member.key) as UntypedFormControl;

        if (member.model) {

            // persistent models get a simple form control
            if (member.model.id) {

                return existing || new UntypedFormControl(undefined, member.validators?.map(v => WrapValidator(member.key, v)) || []);
            }
            // special case for units
            else if (UNIT_TYPES.includes(member.type!)) {
                let validators: any[] = [];
                member.validators?.forEach((v) => {
                    // if (v._fieldValidators) {
                    //     for (let i in v._fieldValidators) {
                    //         validators.push(...v._fieldValidators[i].map((v: any) => WrapUnitValidator(member.key, i, v)))
                    //     }
                    // }
                    // else {
                    //     validators.push(WrapValidator(member.key, v));
                    // }
                    validators.push(WrapValidator(member.key, v));
                });

                return existing || new UntypedFormControl(undefined, validators);
            }
            // embedded models get a form group
            else {

                // to have ModelValidator work in angular forms we have to extract the 'extra' validators
                let field_validators: { [k: string]: UonValidator[] } = {};
                member.validators?.forEach((v) => {
                    // if (v._fieldValidators) {
                    //     for (let i in v._fieldValidators) {
                    //         field_validators[i] = field_validators[i] || [];
                    //         field_validators[i].push(...v._fieldValidators[i]);
                    //     }
                    // }
                });

                const sub_form_group = this._generateFormGroup(member.type as Type<any>, {
                    extraFieldValidators: field_validators,
                    filterLocales: options.filterLocales,
                    formGroup: existing as unknown as UntypedFormGroup
                });

                // // filter out undesired languages
                // if (member.type == LocaleString && options.filterLocales) {
                //     for (let k in sub_form_group.controls) {
                //         if (!options.filterLocales.includes(k)) {
                //             //instead of removing control, we just remove validators
                //             sub_form_group.controls[k].clearValidators();
                //         }
                //     }
                // }

                return sub_form_group;

            }

        }
        else {
            let validators: UonValidator[] = member.validators || [];
            if (options?.extraFieldValidators && options.extraFieldValidators[member.key]) {
                validators = validators.concat(options.extraFieldValidators[member.key]);
            }

            return existing || new UntypedFormControl(undefined, validators.map(v => WrapValidator(member.key, v)))
        }

    }
}





// FIXME when building, function names get lost due to code minimization, for now keep a list of known validator names
const UON_VALIDATOR_NAME_MAP = new Map<string, string>([
	[Required.name, 'Required'],
	[MinLength.name, 'MinLength'],
	[MaxLength.name, 'MaxLength'],
	[ValidateEmail.name, 'ValidateEmail'],
	[ValidateMongoId.name, 'ValidateMongoId'],
	[ValidatePattern.name, 'ValidatePattern'],
//	[ValidateOneOf.name, 'ValidateOneOf'],
	[ValidatePhone.name, 'ValidatePhone'],
	[Prohibited.name, 'Prohibited'],
]);

export function WrapValidator(key: string, func: UonValidator) {

    return (control: AbstractControl) => {

        try {
            if ((typeof control.value !== 'undefined'
                && control.value !== null
                && control.value !== '')
                /*|| func._forceValidation*/) {

                func(null, key, control.value);
            }
        }
        catch (err) {
            let vf = err as ValidationFailure;


            return { [UON_VALIDATOR_NAME_MAP.get(vf.validator.name)!]: true };
        }
        return null;
    }

}

function WrapUnitValidator(key: string, subkey: string, func: UonValidator) {

    return (control: AbstractControl) => {

        try {
            if ((control.value !== null && control.value !== undefined) /*|| func._forceValidation*/) {
                func(null, key, control.value?.[subkey]);
            }
        }
        catch (err) {
            let vf = err as ValidationFailure;
            return { [UON_VALIDATOR_NAME_MAP.get(vf.validator.name)!]: true };
        }
        return null;
    }

}


export function MoveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
        const current = formArray.at(i + dir);
        formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
}

export function cleanupNullValues(obj: any, parent: string = "") {
    if (Array.isArray(obj)) {
    }
    else if(typeof obj === 'object') {
        if(obj !== null) {
            for(const key of Object.keys(obj)) {
                console.log(parent + key + ": " + obj[key])
                if(obj[key] == null) {
                    delete obj[key];
                }
                else {
                    cleanupNullValues(obj[key], parent + "." + key);
                }
            }
        }
    }
    else {

    }
}

export function cleanupData(obj: any) {
    const data = JSON.parse(JSON.stringify(obj));

    cleanupNullValues(data);

    return data;
}