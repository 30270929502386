import { Component } from '@angular/core';
import { CustomFormSelectFieldComponent } from '../custom-form-select-field/custom-form-select-field.component';
import { FormService } from 'src/app/services/form.service';

@Component({
    selector: 'app-custom-form-right-select-field',
    templateUrl: './custom-form-right-select-field.component.html',
    styleUrls: ['../../form-builder-renderer/form-builder-renderer.component.scss', './custom-form-right-select-field.component.scss']
})
export class CustomFormRightSelectFieldComponent extends CustomFormSelectFieldComponent {


    constructor(formService: FormService) {
        super(formService);
    }
}
