import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { AddSeatsAllocationDialogComponent } from './add-seats-allocation-dialog/add-seats-allocation-dialog.component';
import { AllocationCountPipe } from './allocation-count.pipe';
import { AllocationFilterPipe } from './allocation-filter.pipe';
import { AllocationListPanelComponent } from './allocation-list-panel/allocation-list-panel.component';
import { OrgSeatsAllocationPanelComponent } from './org-seats-allocation-panel/org-seats-allocation-panel.component';
import { SeatTokenComponent } from './seat-token/seat-token.component';
import { SubscriptionAllocationComponent } from './subscription-allocation/subscription-allocation.component';
import { TransferSeatsDialogComponent } from './transfer-seats-dialog/transfer-seats-dialog.component';
import { UserSeatsAllocationPanelComponent } from './user-seats-allocation-panel/user-seats-allocation-panel.component';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AccountModule } from '../account/account.module';
import { AuthUiModule } from '../auth-ui/auth-ui.module';
import { OnboardingUiModule } from '../onboarding-ui/onboarding-ui.module';
import { SubscriptionSeatsAllocationListComponent } from './subscription-seats-allocation-list/subscription-seats-allocation-list.component';
import { ClassroomSeatsAllocationListComponent } from './classroom-seats-allocation-list/classroom-seats-allocation-list.component';
import { OrganizationUiModule } from '../organization-ui/organization-ui.module';
import { OrgSeatsAllocationCardComponent } from './org-seats-allocation-card/org-seats-allocation-card.component';
import { SubscriptionUiModule } from '../subscription-ui/subscription-ui.module';

const EXPORTS_COMPONENTS = [
    ClassroomSeatsAllocationListComponent,
    OrgSeatsAllocationCardComponent,
    OrgSeatsAllocationPanelComponent,
    SeatTokenComponent,
    SubscriptionAllocationComponent,
    SubscriptionSeatsAllocationListComponent,
    UserSeatsAllocationPanelComponent,
];

@NgModule({
    declarations: [
        AddSeatsAllocationDialogComponent,
        AllocationCountPipe,
        AllocationFilterPipe,
        AllocationListPanelComponent,
        TransferSeatsDialogComponent,
        ...EXPORTS_COMPONENTS
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        CdkTableModule,
        ReactiveFormsModule,
        OrganizationUiModule,
        AuthUiModule,
        AccountModule,
        OnboardingUiModule,
        FormsModule,
        SubscriptionUiModule,
    ],
    exports: [
        ...EXPORTS_COMPONENTS
    ]
})
export class SeatUiModule { }
